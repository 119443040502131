import React, { useState, useRef, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { deleteErrorAcknowledged, deleteProject, updateProject, selectCurrentTeam, saveProjectOpeningMark, removesProjectOpeningMark, saveToPinnedProjects, updateLocalProject, setProjectToCopyId } from 'app/horizonDataSlice';
import IconButton from 'components/IconButton';
import ActivityIndicator from 'components/ActivityIndicator';
import { AlertDialog } from 'components/poppers/Dialog';
import { motion } from 'framer-motion';
import moment from 'moment';
import styles from './ProjectItem.module.css';
import { homeTexts } from 'utils/appTexts';
import CascadingSelectionPopper from 'components/poppers/CascadingSelectionPopper';
const texts = homeTexts.projectItem;

export default function ProjectItem({ data, itemIndex, refToAttech }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const lang = useSelector(selectUiLanguage);
    const currentTeam = useSelector(selectCurrentTeam)
    const [itemHovered, setItemHovered] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [indicateActivity, setActivity] = useState(false);
    const archiveMode = false;

    const targetLocation = useCallback(() => {

        if (data.survey) {
            dispatch(saveProjectOpeningMark({ teamId: currentTeam.id, project: data, route: '/survey' }))
            return `/survey/${data.id}`;
        }

        if (data.notebook) {
            dispatch(saveProjectOpeningMark({ teamId: currentTeam.id, project: data, route: '/notebook' }))
            return `/notebook/${data.id}`;
        }

        if (data.bucket) {
            return `/results/${data.id}`;
        }

        console.error("Project has §no notebook, bucket nor survey.");

        return '/home'
    }, [data, currentTeam, dispatch])

    const handleCardClick = useCallback((e) => {
        e.stopPropagation();
        if (isFocused) {
            history.push(targetLocation());
        } else {
            setIsFocused(true)
        }
    }, [isFocused, history, targetLocation])

    const handlePin = useCallback((e) => {
        dispatch(saveToPinnedProjects({ teamId: currentTeam.id, project: data, saveOrRemove: data.isPinned ? 'remove' : 'save' }))
    }, [data, currentTeam, dispatch])

    const labelMarks = useMemo(() => {
        if (!data.labels || !currentTeam || !currentTeam.labels) {
            return null
        }
        return (
            [...new Set(data.labels)].map((labelId, i) => {
                const labelData = currentTeam.labels.find(l => l.id === labelId);
                if (labelData) {
                    return <div
                        className={styles.labelPart}
                        key={`${labelId}_${data.id}`}
                        style={{ backgroundColor: labelData.color }}
                    >
                    </div>
                }
                return null
            })
        )
    }, [data, currentTeam])

    const bottomSentence = useMemo(() => {
        if (data.lastOpeningDate) {
            var date = new Date(data.lastOpeningDate);
            const today = new Date()
            const yesterday = new Date(today); yesterday.setDate(yesterday.getDate() - 1)

            if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
                return texts.bottomSentence.today[lang]
            } else if (date.getDate() === yesterday.getDate() && date.getMonth() === yesterday.getMonth() && date.getFullYear() === yesterday.getFullYear()) {
                return texts.bottomSentence.yesterday[lang]
            } else {
                return `${texts.bottomSentence.lastDate[lang]} ${moment(date).format('DD/MM/YYYY')}`;
            }
        }
    }, [data, lang])

    var sourceSplit = data.sourceName.split('___')
    var sourceNameToShow = sourceSplit.length === 2 ? sourceSplit[1] : data.sourceName
    return (
        <motion.div
            ref={refToAttech}
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1, transition: { type: "spring", bounce: 0.25 } }}
            whileHover={{ y: -2 }}
            className={styles.cardCont}
            tabIndex={itemIndex}
            onClick={handleCardClick}
            onMouseEnter={() => { setItemHovered(true) }}
            onMouseLeave={() => { setItemHovered(false) }}
            onBlur={() => {
                setIsFocused(false);
                setItemHovered(false);
            }}
        >

            <div className={styles.labelsLine}> {labelMarks} </div>

            <div className={styles.topSection} style={{ height: archiveMode ? 155 : '', }}>
                <div className={styles.cardTitle}>
                    <div title={data.name.length < 30 ? null : data.name}>
                        <span style={{ marginInlineEnd: 8 }}>{data.icon}</span>
                        {data.name.length < 30 ? data.name : data.name.substring(0, 30) + '...'}
                    </div>
                    <IconButton
                        name={data.isPinned ? 'pinned' : 'unpinned'}
                        theme='Plain'
                        // colorSet='Secondary'
                        iconStyle={{
                            opacity: (itemHovered || data.isPinned) ? 1 : 0,
                            fill: data.isPinned ? 'var(--color-type-medium-emphasis)' : 'rgba(0,0,0,0.3)'
                        }}
                        onClick={handlePin}
                    />
                </div>
                <p title={sourceNameToShow.length < 30 ? null : sourceNameToShow} className={styles.detailTitle} style={{ paddingInlineStart: '5px' }}>
                    <span style={{ marginInlineStart: 6, display: 'inline' }} />
                    {sourceNameToShow.length < 30 ? sourceNameToShow : sourceNameToShow.substring(0, 30) + '...'}
                </p>
            </div>
            <div className={styles.bottomSection} style={{ paddingTop: archiveMode ? 0 : '' }}>
                <p> {bottomSentence} </p>
                <ProjectOptions
                    itemHovered={itemHovered}
                    data={data}
                    onActivity={(isActive) => setActivity(isActive)}
                />
            </div>
            {indicateActivity && <div className={styles.activityBlock}> <ActivityIndicator size={24} /> </div>}
        </motion.div>
    )
}

const optionsTexts = homeTexts.projectItem.options;
const ProjectOptions = ({ itemHovered, data, onActivity }) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const currentTeam = useSelector(selectCurrentTeam)
    const [selectionPopperActive, setSelectionPopperActive] = useState(false);
    const isArchiveMode = useSelector(state => state.horizonData.showArchive);
    const deleteProjectError = useSelector(state => state.horizonData.deleteProjectError);
    const [newLabels, setNewLabels] = useState(null);
    const opBtnRef = useRef(null);

    const deleteProjectErrorText = useMemo(() => {
        if (deleteProjectError) return deleteProjectError.error;
        return ''
    }, [deleteProjectError]);

    const options = useMemo(() => {

        if (isArchiveMode) return [
            {
                key: 'restore',
                label: optionsTexts.restore[lang],
                cb: () => {
                    dispatch(updateProject({ projectId: data.id, data: { isArchived: false } }))
                    onActivity(true);
                }
            },
            {
                key: 'delete',
                label: optionsTexts.delete[lang],
                cb: () => {
                    setDeletionDialogActive(true);
                }
            }
        ]

        var optArray = [
            {
                key: 'duplicate',
                label: optionsTexts.duplicate[lang]
            },
            {
                key: 'archive',
                label: optionsTexts.archive[lang]
            },
            // { key: 'editLabels', label: optionsTexts.editLabels[ lang ], cb: () => console.log( "todo: Labels") },
            // { key: 'showDetails', label: optionsTexts.showDetails[ lang ], cb: () => console.log( "todo: Show") },
            // { key: 'share', label: optionsTexts.share[ lang ], cb: () => console.log( "todo: Share") },
        ];

        if (currentTeam.labels) {
            optArray.push({
                key: 'labels',
                label: optionsTexts.editLabels[lang],
                subOptions: currentTeam.labels.map(l => ({
                    key: l.id,
                    label: <span><span style={{ color: data.labels && data.labels.indexOf(l.id) >= 0 ? l.color : 'var( --color-background-dark )' }}>⬤</span><span> {l.name}</span></span>,
                }))
            });
        }

        return optArray

    }, [isArchiveMode, lang, dispatch, currentTeam, data, onActivity])

    const [deletionDialogActive, setDeletionDialogActive] = useState(false);


    const archiveProjectHandle = useCallback((projectId) => {
        dispatch(saveToPinnedProjects({ teamId: currentTeam.id, project: { id: projectId }, saveOrRemove: 'remove' }))
        dispatch(removesProjectOpeningMark({ teamId: currentTeam.id, project: { id: projectId } }))
        dispatch(updateProject({ projectId: projectId, data: { isArchived: true } }))
        onActivity(true);
    }, [dispatch, currentTeam, onActivity])

    const duplicateProjectHandle = useCallback((projectId) => {
        dispatch(setProjectToCopyId(projectId))
    }, [dispatch])

    const restoreProjectHandle = useCallback((projectId) => {
        dispatch(updateProject({ projectId: projectId, data: { isArchived: false } }))
        onActivity(true);
    }, [dispatch, onActivity])


    const deleteProjectHandle = useCallback((projectId) => {
        setDeletionDialogActive(true);
    }, [])

    const toggleLabelInProjectHandle = useCallback((projectId, labelId) => {
        var newLocalLabels
        if (data.labels && data.labels.indexOf(labelId) >= 0) {
            newLocalLabels = data.labels.filter(l => l !== labelId)
        } else {
            newLocalLabels = data.labels ? data.labels.concat([labelId]) : [labelId]
        }

        dispatch(updateLocalProject({ projectId: projectId, teamId: currentTeam.id, data: { labels: newLocalLabels } }))
        setNewLabels(newLocalLabels)
    }, [dispatch, currentTeam, data])


    const approveDelete = useCallback(() => {

        onActivity(true);
        dispatch(deleteProject(data.sourceName));

    }, [onActivity, data, dispatch])

    const dismissHandle = useCallback(() => {
        if (newLabels) {
            dispatch(updateProject({ ignoreFeedback: true, projectId: data.id, data: { labels: { disconnectAll: true } } }))
            setTimeout(() => {
                dispatch(updateProject({ ignoreFeedback: true, projectId: data.id, data: { labels: { connect: newLabels.map(labelId => { return { id: '' + labelId } }) } } }))
            }, 500)
        }
        setNewLabels(null)
        setSelectionPopperActive(false)
    }, [newLabels, dispatch, data])

    return (
        <>
            <div className={styles.optionButton} ref={opBtnRef}>
                <IconButton
                    name='options_horizontal'
                    theme='Plain'
                    size='m'
                    colorSet='Grayscale'
                    bgStyle={{
                        margin: 0,
                        opacity: itemHovered ? 1 : 0,
                    }}
                    onClick={() => setSelectionPopperActive(true)}
                />
            </div>

            <CascadingSelectionPopper
                referenceElement={opBtnRef.current}
                isActive={selectionPopperActive}
                options={options}
                onDismiss={dismissHandle}
                onSelect={(opKey) => {
                    if (opKey === 'archive') {
                        archiveProjectHandle(data.id)
                        setSelectionPopperActive(false)
                    }
                    if (opKey === 'duplicate') {
                        duplicateProjectHandle(data.id)
                        setSelectionPopperActive(false)
                    }
                    if (opKey === 'restore') {
                        restoreProjectHandle(data.id)
                        setSelectionPopperActive(false)
                    } else if (opKey === 'delete') {
                        deleteProjectHandle(data.id)
                        setSelectionPopperActive(false)
                    } else if (opKey.indexOf('labels.') === 0) {
                        toggleLabelInProjectHandle(data.id, opKey.split('.')[1])
                    }
                }}
            />

            {/* DELETE VALIDATION ALERT */}
            <AlertDialog
                isActive={deletionDialogActive}
                title={texts.deleteValidationAlert.title[lang]}
                message={texts.deleteValidationAlert.message[lang]}
                actions={[
                    {
                        label: texts.deleteValidationAlert.cancel[lang],
                        callback: () => { },
                        theme: 'Plain'
                    },
                    {
                        label: texts.deleteValidationAlert.delete[lang],
                        callback: approveDelete,
                        theme: 'Plain'
                    },
                ]}
                onClose={() => setDeletionDialogActive(false)}
            />

            {/* DELETE ERROR */}
            <AlertDialog
                isActive={deleteProjectError && deleteProjectError.sourceName === data.sourceName}
                title={texts.deleteErrorAlert.title[lang]}
                message={texts.deleteErrorAlert.message[lang] + deleteProjectErrorText}
                actions={[
                    {
                        label: texts.deleteErrorAlert.okay[lang],
                        callback: () => { },
                        theme: 'Plain'
                    }
                ]}
                onClose={() => {
                    dispatch(deleteErrorAcknowledged());
                    onActivity(false);
                }}
            />
        </>
    )

}