import React, { useEffect, useMemo, useState } from 'react';
import styles from './SurveyEditorProperties.module.css';
import SurveySettings from './survey_settings/SurveySettings';
import PageSettings from './PageSettings';
import PublishAndExport from './publish_and_export/PublishAndExport';
import LogicProperties from './LogicProperties';
import MediaSettings from './media_settings/MediaSettings';
import { useSelector } from 'react-redux';
import { getNested } from 'utils/miscHelpers';
import MilgoPublishAndExport from 'features/milgo_survey/milgo_survey_publish_and_export/MilgoPublishAndExport';
import { useRouteMatch } from 'react-router-dom';
import { paths } from 'app/App';

export const modes = {
    COLLAPSED: 'collapsed',
    SURVEY: 'survey',
    PAGE: 'page',
    PUBLISH_AND_EXPORT: 'publish_and_export',
    LOGIC: 'logic',
    MEDIA: 'media',
};

const comps = {
    collapsed: SurveySettings,
    survey: SurveySettings,
    page: PageSettings,
    publish_and_export: PublishAndExport,
    logic: LogicProperties,
    media: MediaSettings,
};

export default function SurveyEditorProperties() {
    const mode = useSelector(
        (state) => state.surveyEditor.present.display.propertiesMode
    );

    const { path } = useRouteMatch();

    const Context = useMemo(() => {
        if (mode === modes.PUBLISH_AND_EXPORT && path === paths.milgo_survey) {
            return MilgoPublishAndExport;
        } else return comps[mode];
    }, [mode, path]);

    const [isInitialFetch, setIsInitialFetch] = useState(true);

    if (isInitialFetch) {
        return <LoadingSurvey onLoaded={() => setIsInitialFetch(false)} />;
    }

    return (
        <div>
            <Context />
        </div>
    );
}

const LoadingSurvey = ({ onLoaded }) => {
    const remoteSurveyFetchStatus = useSelector(
        (state) => state.surveyEditor.present.remoteSurveyFetchStatus
    );
    const isConnectingToServer = useSelector((state) =>
        getNested(
            state,
            'surveyEditor',
            'present',
            'remoteSurveyData',
            'isConnecting'
        )
    );

    useEffect(() => {
        if (remoteSurveyFetchStatus === 'succeeded' && !isConnectingToServer) {
            onLoaded();
        }
    }, [remoteSurveyFetchStatus, isConnectingToServer, onLoaded]);

    return <div className={styles.loaderCont} />;
};
