import React, { useEffect, useState } from 'react';
import styles from './SurveyEditorContent.module.css';
import { useSelector } from 'react-redux';
import ContentListItem from './ContentListItem';
import SurveyEditorNoPages from './SurveyEditorNoPages';
import ActivityIndicator from 'components/ActivityIndicator';
import { getNested } from 'utils/miscHelpers';

export default function SurveyEditorContentList () {

    const pagesOrder = useSelector( state => state.surveyEditor.present.survey.content.pagesOrder );

    const [isInitialFetch, setIsInitialFetch] = useState( true );

    if( isInitialFetch ) {
        return <LoadingSurvey onLoaded={ () => setIsInitialFetch( false ) }/>
    }

    if( !pagesOrder || pagesOrder.length < 1 ) {
        return (
            <SurveyEditorNoPages/>
        )
    }

    return (
        <div className={ styles.pageItemsListCont} >
            <List data={ pagesOrder }/>
        </div>
    )
}

const List = ( {data} ) => {

    if( !data || data.length < 1 ) return null;

    return data.map( (id,i) => (
        <ContentListItem id={ id } index={ i } key={ 'item_' + id }/>
    ))

}

const LoadingSurvey = ( {onLoaded}) => {

    const remoteSurveyFetchStatus = useSelector( state => state.surveyEditor.present.remoteSurveyFetchStatus );
    const isConnectingToServer = useSelector(state => getNested(state, 'surveyEditor', 'present', 'remoteSurveyData', 'isConnecting'));

    useEffect( () => {

        if( remoteSurveyFetchStatus === 'succeeded' && !isConnectingToServer) {
            onLoaded();
        }

    }, [remoteSurveyFetchStatus, isConnectingToServer, onLoaded])

    return <div className={ styles.loaderCont }><ActivityIndicator size={24}/></div>
}
  