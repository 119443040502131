/* PAGE LOGIC STRUCTURE:
logic: {
    onPageEnter: [
      {
        title: { "en": "logic_item1" },
        condition: {
          isAlways: true | false,
          rules: { "==": ["arg_a_id", "arg_b_id"] },
          args: {
            "arg_a_id": {
              type: "surveyVariables",
              variable: "var_id"
            },
            "arg_b_id": {
              type: "userInput",
              page: "page_id",
              inputType: "answer" | "time_span",
              item: "item_id" // when page has different answer for each item ( i.e SCALE pages )
            },
            "arg_c_id": {
              type: "constant",
              variableType: "number" | "string" | "boolean" | "list"
              value: value
            },
          }
        },
        actions: [
          { id: "action_id_a", type: "SET_NEXT_PAGE, page: "page_id" },
          { id: "action_id_b", type: "STOP_AND_GOTO, page: "page_id" },
          { id: "action_id_c", type: "SET_VAR", "varName": "", operator: "+=", arg: { type: (argumentsTypes), ... } }
        ]
      }
    ],
    onPageExit: [
      {
        ...
        isNavigationLogic: true | false
        navigationData: {
          sourcePage: "pageId",
          sourcePageItem: "pageItemId",
          targetPage: "pageId"
        }
      }
    ], 
}

NOTE: During editing, "actions" are saved in the redux slice the following way:

"actions": {
  order: [ id_a, id_b ],
  data: {
    id_a: { type: '', ... },
    id_b: { type: '', ... },
  }
}

*/

export const variablesTypes = {
    NUMBER: 'number',
    STRING: 'string',
    BOOLEAN: 'boolean',
    ARRAY: 'array',
    URL_PARAM: 'url_param',
    DATASOURCE: 'datasource', // for milgo cycles. added 2.2023
};

export const pageLogicPhases = {
    ON_PAGE_ENTER: 'onPageEnter',
    ON_PAGE_EXIT: 'onPageExit',
};

////////////////
// CONDITIONS //
////////////////

export const argumentsTypes = {
    SURVEY_VARIABLES: {
        key: 'surveyVariables',
        data: {
            variable: 'var_id',
        },
    },
    USER_INPUT: {
        key: 'userInput',
        data: {
            page: 'page_id',
            inputType: 'answer' | 'time_span',
            item: 'item_id', // when page has different answer for each item ( i.e SCALE pages )
        },
    },
    CONSTANT: {
        key: 'constant',
        data: {
            variableType: '', // "number" | "string" | "boolean" | "list"
            value: '',
        },
    },
    RANDOM: {
        key: 'random',
    },
};

export const userInputTypes = {
    TIME_SPAN: 'time_span',
    ANSWER: 'answer',
    ANSWER_TEXT: 'answer_text',
    ANSWER_INDEX: 'answer_index',
};

export const comparisonOperators = {
    '==': 'eq',
    '!=': 'ne',
    '>': 'gt',
    '>=': 'ge',
    '<': 'lt',
    '<=': 'le',
    in: 'in',
};

export const assignmentOperators = {
    '=': 'assign',
    '+=': 'addAssign',
    '-=': 'subAssign',
    '*=': 'mulAssign',
    '/=': 'divAssign',
    '%=': 'modAssign',
    '**=': 'expAssign',
};

export const logicOperators = {
    AND: 'and',
    OR: 'or',
};

/////////////
// ACTIONS //
/////////////

export const actionsTypes = {
    STOP_AND_GOTO: {
        key: 'stopAndGoto',
        availableOnPhases: [pageLogicPhases.ON_PAGE_EXIT],
        data: {
            page: '', // page ID
        },
    },
    SET_NEXT_PAGE: {
        key: 'setNextPage',
        availableOnPhases: [pageLogicPhases.ON_PAGE_EXIT],
        data: {
            page: '', // page ID
        },
    },
    STOP_AND_SKIP_PAGE: {
        key: 'stopAndSkipPage',
        availableOnPhases: [pageLogicPhases.ON_PAGE_ENTER],
        gotoOptions: {
            NEXT_IN_ORDER: 'nextInOrder',
            TARGET_PAGE: 'targetPage',
        },
        data: {
            gotoOption: '', // nextInOrder | anyPage
            page: '', // page ID
        },
    },
    SET_VAR: {
        key: 'setVar',
        availableOnPhases: [
            pageLogicPhases.ON_PAGE_ENTER,
            pageLogicPhases.ON_PAGE_EXIT,
        ],
        data: {
            variable: '', // survey variable id
            operator: '', // assignment operator (i.e '*=' )
            arg: {
                type: '', // argumentsType
                // ...data ( depends on argument type )
            },
        },
    },
    REDIRECT_TO_URL: {
        key: 'redirectToUrl',
        availableOnPhases: [pageLogicPhases.ON_PAGE_EXIT],
        data: {
            url: '',
        },
    },
    CONSOLE_LOG: {
        key: 'consoleLog',
        availableOnPhases: [
            pageLogicPhases.ON_PAGE_ENTER,
            pageLogicPhases.ON_PAGE_EXIT,
        ],
        data: {
            arg: {
                type: '', // argumentsType
                // ...data ( depends on argument type )
            },
        },
    },
};
