import React from 'react'
import Block from './page/Block';
import Page from './page/Page';

import { useSelector } from 'react-redux';

/**
 * 
 * @param {{
 * id: string // item id
 * index: integer // item index
 * parentBlock: object{id,index} // incase this content list is inside block, pass the block info into it's children
 * }} param 
 */
export default function ContentListItem ({ id, index, parentBlock }) {
  	
	const pageData = useSelector( state => {
		
		if( !id ) return null;
		return state.surveyEditor.present.survey.content.pagesData[ id ];

	});

    if ( !pageData ) {
      return null;
    }

	//// RENDER ////
	
	if( pageData.type === 'BLOCK') return (
        
        <Block id={ id } index={ index } data={ pageData } />
    
    )

    return (

        <Page id={ id } index={ index } data={ pageData } parentBlock={ parentBlock } />

    )
}
  