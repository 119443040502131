import React, { useRef, useState } from 'react';
import styles from './Notebook.module.css';
import Button from 'components/Button';
import { ReactComponent as BlankIllustration } from 'assets/illustrations/blank_notebook.svg';

import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { notebookTexts } from 'utils/appTexts';
import NewSectionPopper from './notebook_components/NewSectionPopper';

const texts = notebookTexts.content;

export default function NotebookContent() {

    const lang = useSelector( selectUiLanguage );

    const [isPopperActive, setIsPopperActive] = useState( false );

    const btnRef = useRef();

    return (
        <div className={ styles.blankNotebookCont}>
            <BlankIllustration height={256}/>
            <h3 className={ styles.blankMessage }>
                { texts.blankMessage[ lang ] }
            </h3>
            <div ref={ btnRef }>
                <Button
                    label={ texts.addSectionBtn[ lang ] }
                    iconBefore='plus'
                    onClick={ () => setIsPopperActive( true ) }
                />
            </div>
            <NewSectionPopper
                referenceElement={ btnRef.current }
                isActive={ isPopperActive }
                targetIndex={ 0 }
                onDismiss={ () => setIsPopperActive( false ) }
                onSelect={ () => setIsPopperActive( false ) }
            />
        </div>
    )
}