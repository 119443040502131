import { selectUiLanguage } from 'app/preferencesSlice';
import { AlertDialog } from 'components/poppers/Dialog';
import { restoreSurvey } from 'features/survey_editor/surveyEditorSlice';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { surveyEditorTexts } from 'utils/appTexts';

const texts = surveyEditorTexts.properties.publishAndExport;

const restoreAlertTexts = texts.restoreAlert;

export default function RestoreAlert({ isActive, surveyData, onClose }) {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);

    const handleRestore = useCallback(() => {
        dispatch(restoreSurvey(surveyData));
    }, [dispatch, surveyData]);

    const alertActions = useMemo(() => {
        return [
            {
                label: restoreAlertTexts.actions.cancel[lang],
                theme: 'Outlined',
                callback: () => {},
            },
            {
                label: restoreAlertTexts.actions.approve[lang],
                theme: 'Outlined',
                callback: handleRestore,
            },
        ];
    }, [handleRestore, lang]);

    return (
        <>
            <AlertDialog
                isActive={isActive}
                title={restoreAlertTexts.title[lang]}
                message={restoreAlertTexts.message[lang]}
                actions={alertActions}
                onClose={onClose}
            />
        </>
    );
}
