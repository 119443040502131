import React, { useCallback, useEffect, useState } from "react";
import styles from "./MediaSettings.module.css";
import MediaLibrary from "components/media_library/MediaLibrary";
import Button from "components/Button";

import { surveyEditorTexts } from "utils/appTexts";
import { useDispatch, useSelector } from "react-redux";
import { selectUiLanguage } from "app/preferencesSlice";
import {
    initMedia,
    mediaChanged,
} from "features/survey_editor/surveyEditorSlice";

const texts = surveyEditorTexts.properties.media;

const GlobalMediaSettings = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const [isLibraryOpen, setIsLibraryOpen] = useState(false);
    const surveyId = useSelector(
        (state) => state.surveyEditor.present.surveyId
    );
    const media = useSelector(
        (state) => state.surveyEditor.present.survey.media
    );

    useEffect(() => {
        if (!media) dispatch(initMedia());
    }, [media, dispatch]);

    const handleMediaChange = useCallback(
        (state) => {
            dispatch(mediaChanged(state));
        },
        [dispatch]
    );

    return (
        <>
            <div className={styles.surveyMediaCont}>
                <Button
                    label={texts.surveyMediaTitle[lang]}
                    iconBefore={"media"}
                    bgStyle={{ width: "100%" }}
                    theme="Outlined"
                    onClick={() => setIsLibraryOpen(true)}
                />
            </div>

            <MediaLibrary
                storageId={surveyId}
                isActive={isLibraryOpen}
                mediaFiles={media?.files}
                mediaFilesArchive={media?.archive}
                onClose={() => setIsLibraryOpen(false)}
                onChange={handleMediaChange}
            />
        </>
    );
};

export default GlobalMediaSettings;
