import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles  from './ColumnSelector.module.css';

import Button from 'components/Button';
import Modal from 'components/poppers/Modal';
import PopperPanel from 'components/poppers/PopperPanel';
import Checkbox from 'components/Checkbox';
import IconButton from 'components/IconButton';

import { useSelector, useDispatch } from 'react-redux';
import { recordsFilterUpdated } from 'app/reportSlice';
import { selectUiLanguage } from 'app/preferencesSlice';

import { resultsTexts } from 'utils/appTexts';


const texts = resultsTexts.columnPopper;

export default function ColumnSelector( {lang, recordsData, onChangesDone }) {

    const spBtnRef = useRef( null );
    const columns = useSelector( state => state.report.recordsFilterData.desiredKeys );
    const dataKeys = useSelector(state => state.report.dataKeys);

    const [togglePopperActive, setTogglePopperActive] = useState( false );
    const [options, setOptions] = useState( [] );
    const [changesMade, setChangesMade] = useState( false );


    const dispatch = useDispatch();

    const updateFilter = useCallback( ( cols ) => {

        dispatch( recordsFilterUpdated( {
            type: "desiredKeys",
            data: cols
        }))
    }, [dispatch]);

    useEffect( () => {
        if( recordsData ) {
            const cols = [];

            for( let i=0; i<recordsData.keysRow.length; i++ ) {
                cols.push( {
                    key: recordsData.keysRow[ i ],
                    isChecked: true
                })
            }

            updateFilter( cols );

        } 
    }, [recordsData, updateFilter])

    useEffect( () => {
        
        if( columns ) {

            const copiedCols = columns.map( col => ({...col}) );
            setOptions( copiedCols );
        }

    }, [columns])



    const handleModifications = useCallback( ( key, state, index ) => {

        const updatedOptions = [...options];

        updatedOptions[ index ].isChecked = state;

        setOptions( updatedOptions );
        setChangesMade( true );

    }, [options])

    const handleToggleAll = useCallback( ( isChecked ) => {

        const updatedOptions = [...options];

        updatedOptions.forEach( op => op.isChecked = isChecked );

        setOptions( updatedOptions );
        setChangesMade( true );

    }, [options])

    const handleVirtualVars = useCallback ( ( isChecked ) => {

        const updatedOptions = [...options];
        
        updatedOptions.forEach( op => {
            const isVirtual = dataKeys[ op.key ] && dataKeys[ op.key ].originType === 'VIRTUAL';
            op.isChecked = ( isVirtual && isChecked ) || ( !isVirtual && !isChecked )
        });

        setOptions( updatedOptions );
        setChangesMade( true );


    }, [dataKeys, options])

    const apply = useCallback( () => {

        updateFilter( options );
        setChangesMade( false );

    }, [updateFilter, options])

    const handleFinished = useCallback( () => {

        setTogglePopperActive( false );

        if( changesMade ) {
            // allow togglePopper to finish it's transition animation before applying the data update that might have impact on rendering performance:
            // setTimeout( apply, 300 )

        }
        
    }, [changesMade])


    return [
        <div ref={spBtnRef} key="column_selection_button_wrapper"> {/*div wrapper for SelectionPopper reference*/}
            <Button 
                label={ resultsTexts.columnDropDown[ lang ] } 
                theme='Outlined'
                size={'m'} 
                colorSet='Grayscale'
                bgStyle={{margin: 0}}
                onClick={ ()=> setTogglePopperActive( true ) }
                iconBefore='columns'
                iconAfter='arrow_down'
            />
        </div>,

        <ColumnOptionsPopper 
            key="column_selection_poper"
            referenceElement={ spBtnRef.current }
            isActive={ togglePopperActive }
            options={ options }
            onDismiss={ handleFinished }
            onToggle={ handleModifications }
            onToggleAll={ handleToggleAll }
            onToggleVirtualVars={ handleVirtualVars }
            changesMade={ changesMade }
            onApply={ apply }
            // onExit={ handleExit } // on transition animation complete
        />

    ]
}

function ColumnOptionsPopper({ 
    referenceElement, 
    isActive, 
    changesMade,
    options, 
    onToggle, 
    onToggleAll = () => {},
    onToggleVirtualVars,
    onApply,
    onDismiss, 
    onExit
}) {
    
    const lang = useSelector( selectUiLanguage );
    
    const [virtualVarsChecked, setVirtualVarsChecked] = useState( false );

    const handleToggle = useCallback( ( key, state, index ) => {

        if( onToggle ) {
            onToggle( key, state, index );
        } else {
            console.error( "onSelect callback is not defined for a SelectionPopper instance.");
        }

    }, [onToggle] )

    const handleVirtualVars = useCallback( () => {

        setVirtualVarsChecked( !virtualVarsChecked )
        onToggleVirtualVars( !virtualVarsChecked );

    },[virtualVarsChecked, onToggleVirtualVars])

    return (
        <Modal 
            isActive={ isActive }
            onClose={ onDismiss }
            bgBlur={ "low" }
        >

            <PopperPanel referenceElement={ referenceElement } onExit={ onExit }>
                <div className={  styles.topCont}>
                    <div style={{display:'flex'}}>
                        <IconButton
                            name='check_all'
                            theme='Plain'
                            tooltip={ texts.checkAll[ lang ] }
                            onClick={ () => onToggleAll( true ) }
                            />
                        <IconButton
                            name='check_none'
                            theme='Plain'
                            tooltip={ texts.checkNone[ lang ] }
                            onClick={ () => onToggleAll( false ) }
                        />
                        <Button
                            label={ texts.toggleVirtualVars[ lang ] }
                            theme='Plain'
                            iconBefore={ virtualVarsChecked ? 'vi' : 'minus' }
                            onClick={ handleVirtualVars }
                        />
                    </div>
                    <IconButton
                        name='refresh'
                        theme={ changesMade ? 'Full' : 'Plain' }
                        tooltip={ texts.apply[ lang ] }
                        onClick={ () => onApply() }
                    />
                </div>
                <div className={ styles.listContainer}>
                    <ul className={ styles.list}>
                        {
                            options && options.map( (op,i) => {

                                return (
                                    <ListItem
                                        key = { op.key + i }
                                        label = { op.key }
                                        isChecked = { op.isChecked }
                                        onToggle = { (state) => handleToggle( op.key, state, i ) }
                                    />
                                )  
                                    

                            })
                        }
                    </ul> 
                </div>
            </PopperPanel>
        </Modal>
    )
}

const ListItem = ({label, onToggle, isChecked}) => {

    const handleClick = useCallback(( e ) => {
        e.stopPropagation();
        onToggle( !isChecked );
    }, [onToggle, isChecked]);

    return (
        <li className={ styles.listItem }
            onClick={handleClick}
        >
            <Checkbox
                isChecked={ isChecked }
                onToggle={ onToggle }
                style={ {pointerEvents: 'none'} }
            />
            <div className={ styles.listGap }/>
            {label}
        </li>
    )
}