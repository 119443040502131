import React from 'react';
import Modal from '../../components/poppers/Modal';
import PopperPanel from '../../components/poppers/PopperPanel';
import styles from './PermissionPopper.module.css';
import Icon from '../../components/Icon';
import TextInput from '../../components/TextInput';
import { homeTexts } from '../../utils/appTexts';
import Button from '../../components/Button';


export default function PermissionPopper({lang, isActive, referenceElement, onDismiss, onApply}) {

    const notFoundError = false;

    return (
        <Modal 
            isActive={ false }
            onClose={ onDismiss }
            bgBlur={ "low" }
        >
            <PopperPanel 
                referenceElement={ referenceElement }
            >
                <div className={ styles.mainCont }>
                    <p className={ styles.title }> { homeTexts.permissionsPopper.editTitle[ lang ] } </p>
                    <div id={ styles.userItemCont }>
                        <UserItem />
                        <UserItem />
                        <UserItem />
                        <UserItem />
                        <UserItem />
                        <UserItem />
                    </div>
                    <div id={ styles.bottomCont }>
                        <TextInput
                            name='email'
                            theme='Outlined'
                            // label={ Search }
                            fieldStyle={{ height: 30 }}
                            placeholder = { homeTexts.permissionsPopper.search[ lang ] }
                        />
                        <div id={ styles.gap } />
                        <Button 
                            label = { homeTexts.permissionsPopper.add[ lang ] }
                            theme = 'Full'
                            size = 's'
                            colorSet = 'Primary'
                        />
                    </div>

                    {/* error message - email not found */}
                    {notFoundError &&
                        <p id={ styles.errorMessage }> 
                            The email you entered is not valid
                        </p>
                    }
                    
                </div>
                
            </PopperPanel>
        </Modal>
    )

}

/* user item - TODO: add user image functionality */
function UserItem({ 
    image, 
    userName = "User Name"
}) {
    return (
        <div id={styles.itemCont}>
            <div id={styles.imageCont}>
                { !image &&
                    <Icon 
                        name="avatar" 
                        style = {{margin: 0}}
                        size = {32}
                    />
                }
            </div>
            <p class={ styles.labelTitle }> {userName} </p>
        </div>
    )
}

