import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useHistory,
    useRouteMatch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PageNotFound from 'features/page_not_found/PageNotFound';
import TopBar from 'features/top_bar/TopBar';
import UserSettings from 'features/users/UserSettings';
import Home from 'features/home/Home';
import SurveyEditor from 'features/survey_editor/SurveyEditor';
import Distribution from 'features/distribution/Distribution';
import Insights from 'features/insights/Insights';
import Analyses from 'features/analyses/Analyses';
import Results from 'features/results/Results';
import Notebook from 'features/notebook/Notebook';
import Login from 'features/login/Login';
import Tags from 'features/tags/Tags';
import CompCatalogue from 'components/dev/CompCatalogue';
import AuthenticationLoader from 'features/login/AuthenticationLoader';

import { LocalStorage, SessionStorage } from '../utils/cacheManager';

import styles from './App.module.css';

// Selectors
import { selectUiLanguage } from './preferencesSlice';

// Actions
import { loadUserPrefs } from './preferencesSlice';
import { authenticateUser } from '../features/users/usersSlice';
import MilgoSurveyApp from 'features/milgo_survey/MilgoSurveyApp';

export const paths = {
    not_found: '/404',
    login: '/login',
    home: '/home',
    notebook: '/notebook/:projectId',
    survey: '/survey/:projectId',
    distribution: '/distribution/:projectId',
    insights: '/insights/:projectId',
    analyses: '/analyses/:projectId',
    results: '/results/:projectId',
    tag: '/tags/:tagIds',
    milgo_survey: '/milgo-survey/:resourceId',

    compsCatalogue: '/compcatalogue/', // @DEV
};

console.log(`v ${process.env.REACT_APP_VERSION}`);

function App() {
    const userData = useSelector((state) => state.users.mainUser);
    const uiLanguage = useSelector(selectUiLanguage);
    const appUiDirection = uiLanguage === 'en' ? 'ltr' : 'rtl';

    const [isAuthenticating, setIsAuthenticating] = useState(false);

    const dispatch = useDispatch();

    // hardcoded logout:
    // LocalStorage.Delete( LocalStorage.keys.TOKEN );

    useEffect(() => {
        // User authentication

        if (userData.isLoggedIn) {
            dispatch(loadUserPrefs());

            if (isAuthenticating) setIsAuthenticating(false);
        } else {
            const token = LocalStorage.Read(LocalStorage.keys.TOKEN);

            if (token) {
                dispatch(authenticateUser(token));
                setIsAuthenticating(true);
            }

            if (isAuthenticating && userData.loginStatus === 'failed') {
                setIsAuthenticating(false);
            }
        }
    }, [userData, isAuthenticating, dispatch]);

    function MainAppPrivateRoute({ children, ...rest }) {
        const history = useHistory();

        const targetLocation = history.location.pathname;
        SessionStorage.Save(SessionStorage.keys.LAST_PAGE_PATH, targetLocation);

        return (
            <Route
                {...rest}
                render={({ location }) =>
                    userData.isLoggedIn ? (
                        children
                    ) : isAuthenticating ? null : (
                        <Redirect
                            to={{
                                pathname: paths.login,
                                state: { from: location },
                            }}
                        />
                    )
                }
            />
        );
    }

    function LoginRoute({ ...props }) {
        const lastLocation = SessionStorage.Read(
            SessionStorage.keys.LAST_PAGE_PATH
        );

        return (
            <Route
                {...props}
                render={({ location }) => {
                    console.log(userData.isLoggedIn, location.pathname);
                    return userData.isLoggedIn ? (
                        <Redirect
                            to={{
                                pathname: lastLocation
                                    ? lastLocation
                                    : paths.home,
                                state: { from: location },
                            }}
                        />
                    ) : isAuthenticating ? null : (
                        <Login />
                    );
                }}
            />
        );
    }

    if (isAuthenticating) return <AuthenticationLoader />;

    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={paths.milgo_survey}
                    component={MilgoSurveyApp}
                />
                <Route path={paths.not_found} component={PageNotFound} />
                <LoginRoute exact path={paths.login} />

                <MainAppPrivateRoute>
                    <div
                        className={styles.app_root}
                        style={{ direction: appUiDirection }}
                    >
                        <Route
                            exact
                            path={Object.values(paths)}
                            component={TopBar}
                        />
                        <UserSettings />

                        <div className={styles.main_area}>
                            <Switch>
                                <Route
                                    exact
                                    path={paths.home}
                                    component={Home}
                                />
                                <Route
                                    exact
                                    path={paths.notebook}
                                    component={Notebook}
                                />
                                <Route
                                    exact
                                    path={paths.survey}
                                    component={SurveyEditor}
                                />
                                <Route
                                    exact
                                    path={paths.distribution}
                                    component={Distribution}
                                />
                                <Route
                                    exact
                                    path={paths.insights}
                                    component={Insights}
                                />
                                <Route
                                    exact
                                    path={paths.analyses}
                                    component={Analyses}
                                />
                                <Route
                                    exact
                                    path={paths.results}
                                    component={Results}
                                />
                                <Route
                                    exact
                                    path={paths.tag}
                                    component={Tags}
                                />

                                {/* @TEMP */}
                                <Route
                                    exact
                                    path={paths.compsCatalogue}
                                    component={CompCatalogue}
                                />

                                <Redirect to={paths.home} />
                            </Switch>
                        </div>
                    </div>
                </MainAppPrivateRoute>

                <Redirect to={paths.not_found} />
            </Switch>
        </Router>
    );
}

export default App;
