import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateSurvey } from '../surveyEditorSlice';
import debounce from 'debounce';
import { getNested } from 'utils/miscHelpers';

const DEBOUNCE_SAVE_DELAY_MS = 1000;

export default function SurveyAutoSave({ surveyId }) {

    const dispatch = useDispatch();
    const localChangesMade = useSelector(state => state.surveyEditor.present.localChangesMade);
    const remoteSurveyUpdateStatus = useSelector(state => state.surveyEditor.present.remoteSurveyUpdateStatus);
    const isLockedByAnother = useSelector(state => getNested(state, 'surveyEditor', 'present', 'remoteSurveyData', 'isLocked'));

    const [updatePromise, setUpdatePromise] = useState();

    const debouncedSave = useMemo(() =>
        debounce(() => {
            const promise = dispatch(updateSurvey(surveyId));
            setUpdatePromise(promise);

        }, DEBOUNCE_SAVE_DELAY_MS),
        [dispatch, surveyId]);

    useEffect(() => {

        if (
            remoteSurveyUpdateStatus !== 'loading' &&
            localChangesMade &&
            !isLockedByAnother
        ) {

            debouncedSave();
        }

    }, [debouncedSave, remoteSurveyUpdateStatus, localChangesMade, isLockedByAnother])

    useEffect(() => {

        // Make sure there are no scheduled executions when unmounted:

        return () => {
            debouncedSave.clear();
            if (updatePromise) {
                updatePromise.abort();
                // console.log( 'Abording survey update promise')
            }
        }

    }, [surveyId, debouncedSave, updatePromise])

    return null;

}
