import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export default function useParseBackup({ validateSourceName = true } = {}) {
    const currentSourceName = useSelector(
        (state) => state.surveyEditor.present.survey.sourceName
    );

    const parser = useCallback(
        (data) => {
            try {
                const regex = /"lastEdit":\s*\d{13}/g;
                const refreshedText = data.replaceAll(
                    regex,
                    `"lastEdit": ${Date.now()}`
                );

                const newData = JSON.parse(refreshedText);

                if (
                    validateSourceName &&
                    (!newData.sourceName ||
                        newData.sourceName !== currentSourceName)
                ) {
                    throw new Error(
                        'sourceName does not match the current survey.'
                    );
                }

                return newData;
            } catch (err) {
                alert('Reading data from backup failed. ' + err);
            }
        },
        [currentSourceName]
    );

    return parser;
}
