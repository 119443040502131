import React, { useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import tooltipStyles from './Tooltip.module.css';

import { useSelector } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';

/**
 * @TODO...
 * @param {bool} display Should display tooltip or not (usualy should be set to true on mouse hover)
 * @param {object} referenceElement Reference dom element 
 * @param {string} label The tooltip text
 */
export default function Tooltip ({display, referenceElement, label}) {

  const lang = useSelector( selectUiLanguage ); 
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    strategy: 'fixed',
    modifiers: [
        { 
            name: 'arrow', 
            options: { 
                element: arrowElement,
            } 
        },
        {
            name: 'offset',
            options: {
                offset: [0,8]
            }
        },
        {
            name: 'flip',
            enabled: true,
        }
    ],
  });

  const [isActive, setActive ]= useState( false );

  useEffect( ()=>{
    
    // In case the app ui language changes (hence the layout changes to 'rtl'), 
    // the tooltip position needs to be updated:
    if( update ) update();

    
  },[lang, display, update])
  
  useEffect( () => {

    if( display ) {
      const activationTimer = setTimeout( () => setActive( true), 400 );
      return( () => clearTimeout(activationTimer));
    } else {
      setActive( false );
    }

  }, [display])

  return (
    <>
    {
      isActive &&
        <div ref={setPopperElement} className={ tooltipStyles.tooltip } style={ styles.popper } {...attributes.popper }>
          <span className={ tooltipStyles.label }>{ label }</span>
          <div ref={setArrowElement} className={ tooltipStyles.arrow } style={{...styles.arrow}} />
        </div>
    }
    </>
  );
};