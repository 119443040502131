import React from 'react'
import { AlertDialog } from 'components/poppers/Dialog';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { analysesTexts } from 'utils/appTexts';

const msg = analysesTexts.cardTexts.noTitleAlert;

const items = msg.items;

export default function AnalysisNoTitleAlert({ isActive, onClose, item }) {

    const lang = useSelector( selectUiLanguage );

    return (
        <AlertDialog
            isActive={ isActive }
            title={msg.title[lang]}
            message={msg.body[lang].replace( '{item}', items[ item ][ lang ])}
            actions={
                [{
                    label: msg.okayAction[ lang ],
                    callback: () => {} 
                }]
            }
            onClose={ onClose }
        />

    )
}