import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import styles from './LogicActionsStyles.module.css';
import { Draggable } from 'react-beautiful-dnd';
import IconButton from 'components/IconButton';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import Button from 'components/Button';
import GotoPageSelection from '../GotoPageSelection';
import SelectionPopper from 'components/poppers/SelectionPopper';
import AssignmentOperator, {
    dataTypes as assignmentDataTypes,
} from './AssignmentOperator';
import ArgumentSetup from '../ArgumentSetup';
import TextInput, { TextTypes } from 'components/TextInput';

import { useDispatch, useSelector } from 'react-redux';
import {
    logicItemActionChanged,
    logicItemActionDeleted,
} from 'features/survey_editor/surveyEditorSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import { actionsTypes, variablesTypes } from '../LogicConfiguration';
import { isUrlLegal } from 'utils/validation';
import Checkbox from 'components/Checkbox';

const texts = surveyEditorTexts.properties.logic.itemEditor.actions;

const fieldStyles = {
    bg: {
        height: 32,
        padding: '4px 6px',
    },
    cont: {
        margin: 4,
    },
    label: {
        font: 'var(--font-btn)',
    },
};

export default function LogicActionItem({ logicItemId, index, actionId }) {
    const dispatch = useDispatch();
    const logicItemData = useSelector(
        (state) =>
            state.surveyEditor.present.survey.logic.logicItemsData[logicItemId]
    );

    const [isHovered, setIsHovered] = useState(false);

    const actionData = useMemo(() => {
        return logicItemData.actions.data[actionId];
    }, [logicItemData, actionId]);

    const typeOptions = useMemo(() => {
        const phase = logicItemData.phase;
        const types = [
            { key: 'placeholder', label: texts.actionTypePlaceholder['en'] },
        ];
        Object.values(actionsTypes).forEach((actionType) => {
            if (actionType.availableOnPhases.includes(phase)) {
                types.push({
                    key: actionType.key,
                    label: texts.actionTypes[actionType.key]['en'],
                });
            }
        });
        return types;
    }, [logicItemData]);

    const handleDelete = useCallback(() => {
        dispatch(logicItemActionDeleted({ logicItemId, actionId }));
    }, [logicItemId, actionId, dispatch]);

    const handleTypeChange = useCallback(
        (selected) => {
            if (selected.key === 'placeholder') return;

            dispatch(
                logicItemActionChanged({
                    logicItemId,
                    actionId,
                    prop: 'type',
                    value: selected.key,
                })
            );
        },
        [logicItemId, actionId, dispatch]
    );

    return (
        <Draggable key={actionId} draggableId={actionId} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={styles.actionItemCont}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <div
                        className={styles.dragHandle}
                        style={{
                            opacity: isHovered ? 1 : 0.2,
                        }}
                        {...provided.dragHandleProps}
                        tabIndex="-1"
                    />
                    <span className={styles.itemNumberLabel}>{index + 1}</span>
                    <div className={styles.actionItemContent}>
                        <DropDown
                            bgStyle={fieldStyles.bg}
                            contStyle={fieldStyles.cont}
                            labelStyle={fieldStyles.label}
                            options={typeOptions}
                            value={actionData.type || 'placeholder'}
                            onChange={handleTypeChange}
                            dir="ltr"
                        />
                        <ActionArgsModule
                            isHovered={isHovered}
                            logicItemId={logicItemId}
                            logicItemData={logicItemData}
                            actionId={actionId}
                            actionData={actionData}
                        />
                    </div>
                    <IconButton
                        name="delete"
                        tooltip={texts.deleteBtnTooltip['en']}
                        theme="Plain"
                        size="s"
                        colorSet="Grayscale"
                        bgStyle={{
                            opacity: isHovered ? 1 : 0.2,
                        }}
                        onClick={handleDelete}
                    />
                </div>
            )}
        </Draggable>
    );
}

const ActionArgsModule = ({
    isHovered,
    logicItemId,
    logicItemData,
    actionId,
    actionData,
}) => {
    const dispatch = useDispatch();

    const handleArgsChange = useCallback(
        (newData) => {
            dispatch(
                logicItemActionChanged({
                    logicItemId,
                    actionId,
                    data: newData,
                })
            );
        },
        [logicItemId, actionId, dispatch]
    );

    const Module = useMemo(() => {
        switch (actionData.type) {
            case actionsTypes.STOP_AND_GOTO.key:
                return StopAndGotoModule;
            case actionsTypes.SET_NEXT_PAGE.key:
                return SetNextPageModule;
            case actionsTypes.STOP_AND_SKIP_PAGE.key:
                return StopAndSkipPageModule;
            case actionsTypes.SET_VAR.key:
                return SetVarModule;
            case actionsTypes.REDIRECT_TO_URL.key:
                return RedirectToUrlModule;
            case actionsTypes.CONSOLE_LOG.key:
                return ConsoleLogModule;

            default:
                return null;
        }
    }, [actionData]);

    return (
        <>
            {actionData.type && (
                <Icon
                    name={'right_arrow'}
                    color="--color-type-medium-emphasis"
                    style={{ margin: '0 8px' }}
                    size={16}
                />
            )}
            {Module && (
                <Module
                    lang={'en'}
                    isHovered={isHovered}
                    logicItemData={logicItemData}
                    actionData={actionData}
                    onChange={handleArgsChange}
                />
            )}
        </>
    );
};

const StopAndGotoModule = ({ lang, logicItemData, actionData, onChange }) => {
    return (
        <PageSelection
            lang={lang}
            forbiddedPages={[logicItemData.pageId]}
            value={actionData.page}
            onChange={onChange}
        />
    );
};

const SetNextPageModule = ({ lang, logicItemData, actionData, onChange }) => {
    return (
        <PageSelection
            lang={lang}
            forbiddedPages={[logicItemData.pageId]}
            value={actionData.page}
            onChange={onChange}
        />
    );
};

const StopAndSkipPageModule = ({
    lang,
    logicItemData,
    actionData,
    onChange,
}) => {
    const gotoOptions = useMemo(() => {
        const optionTypes = actionsTypes.STOP_AND_SKIP_PAGE.gotoOptions;
        return [
            {
                key: optionTypes.NEXT_IN_ORDER,
                label: texts.modules.gotoOptions.nextInOrder[lang],
            },
            {
                key: optionTypes.TARGET_PAGE,
                label: texts.modules.gotoOptions.targetPage[lang],
            },
        ];
    }, [lang]);

    const handleOptionChange = useCallback(
        (selected) => {
            onChange({ gotoOption: selected.key });
        },
        [onChange]
    );

    // Set initial option:
    useEffect(() => {
        if (!actionData.gotoOption) {
            onChange({
                gotoOption:
                    actionsTypes.STOP_AND_SKIP_PAGE.gotoOptions.NEXT_IN_ORDER,
            });
        }
    }, [onChange, actionData]);

    return (
        <>
            <DropDown
                bgStyle={fieldStyles.bg}
                contStyle={fieldStyles.cont}
                labelStyle={fieldStyles.label}
                options={gotoOptions}
                value={actionData.gotoOption}
                onChange={handleOptionChange}
                dir="ltr"
            />
            {actionData.gotoOption &&
                actionData.gotoOption ===
                    actionsTypes.STOP_AND_SKIP_PAGE.gotoOptions.TARGET_PAGE && (
                    <>
                        :
                        <PageSelection
                            lang={lang}
                            forbiddedPages={[logicItemData.pageId]}
                            value={actionData.page}
                            onChange={onChange}
                        />
                    </>
                )}
        </>
    );
};

const SetVarModule = ({
    lang,
    isHovered,
    logicItemData,
    actionData,
    onChange,
}) => {
    const btnRef = useRef();
    const [isPopperActive, setPopperActive] = useState(false);
    const surveyVariables = useSelector(
        (state) => state.surveyEditor.present.survey.logic.variables
    );

    const variable = useMemo(() => {
        if (actionData.variable) {
            return surveyVariables.find((x) => x.id === actionData.variable);
        }
        return null;
    }, [actionData, surveyVariables]);

    const varLabel = useMemo(() => {
        if (variable) {
            return variable.name;
        }

        return '';
    }, [variable]);

    const varOptions = useMemo(() => {
        return surveyVariables.map((v) => (
            <span key={v.id} style={{ font: 'var(--font-code)' }}>
                {' '}
                {v.name}{' '}
            </span>
        ));
    }, [surveyVariables]);

    const assignmentType = useMemo(() => {
        if (variable) {
            switch (variable.type) {
                case variablesTypes.NUMBER:
                    return assignmentDataTypes.NUMBER;
                case variablesTypes.STRING:
                    return assignmentDataTypes.STRING;
                case variablesTypes.BOOLEAN:
                    return assignmentDataTypes.BOOLEAN;
                case variablesTypes.ARRAY:
                    return assignmentDataTypes.ARRAY;
                case variablesTypes.URL_PARAM:
                    return assignmentDataTypes.STRING;
                case variablesTypes.DATASOURCE:
                    return assignmentDataTypes.STRING;
                default:
                    break;
            }
        }
        return assignmentDataTypes.BOOLEAN;
    }, [variable]);

    const handleVarSelection = useCallback(
        (selected) => {
            setPopperActive(false);
            onChange({ variable: selected });
        },
        [onChange]
    );

    const handleOperatorChange = useCallback(
        (operator) => {
            onChange({ operator });
        },
        [onChange]
    );

    const handleArgTypeChange = useCallback(
        (typeData) => {
            onChange({ arg: typeData });
        },
        [onChange]
    );

    const handleArgValueChange = useCallback(
        (value) => {
            const newArgData = {
                ...actionData.arg,
                value,
            };

            onChange({ arg: newArgData });
        },
        [actionData, onChange]
    );

    return (
        <>
            <div ref={btnRef}>
                <Button
                    label={
                        varLabel || texts.modules.variableSelectionLabel[lang]
                    }
                    theme="Plain"
                    colorSet="Grayscale"
                    bgStyle={{
                        height: 32,
                        padding: 8,
                    }}
                    labelStyle={{
                        font: variable
                            ? 'var(--font-code)'
                            : 'var(--font-body)',
                        marginBottom: variable ? 4 : 0,
                        color: variable
                            ? 'var(--color-type-high-emphasis)'
                            : 'var(--color-type-low-emphasis)',
                    }}
                    onClick={() => setPopperActive(true)}
                />
            </div>

            {variable && (
                <>
                    <AssignmentOperator
                        operator={actionData.operator}
                        dataType={assignmentType}
                        onChange={handleOperatorChange}
                    />
                    <ArgumentSetup
                        key={'arg_0'}
                        argInx={0}
                        args={[actionData.arg, null]}
                        isHovered={isHovered}
                        onSelectType={handleArgTypeChange}
                        onValueChange={handleArgValueChange}
                    />
                </>
            )}

            <SelectionPopper
                referenceElement={btnRef.current}
                isActive={isPopperActive}
                onSelect={handleVarSelection}
                onDismiss={() => setPopperActive(false)}
                dir="ltr"
            >
                {varOptions}
            </SelectionPopper>
        </>
    );
};
const RedirectToUrlModule = ({ lang, logicItemData, actionData, onChange }) => {
    const warning = useMemo(() => {
        if (actionData.url && !isUrlLegal(actionData.url)) {
            return texts.modules.redirectToUrlOption.invalidUrlWarning[lang];
        }

        return null;
    }, [actionData, lang]);

    const handleChange = useCallback(
        (url) => {
            onChange({ url });
        },
        [onChange]
    );

    const handleNewTabChange = useCallback(
        (isChecked) => {
            onChange({ openInNewTab: isChecked });
        },
        [onChange]
    );

    return (
        <div className={styles.moduleCont}>
            <TextInput
                type={TextTypes.TEXT}
                className={styles.argInput}
                fieldStyle={{
                    height: fieldStyles.bg.height,
                    padding: fieldStyles.bg.padding,
                    font: 'var( --font-code )',
                }}
                contStyle={{
                    marginBottom: fieldStyles.cont.margin,
                    boxSizing: 'border-box',
                }}
                value={actionData.url || ''}
                onChange={(e) => handleChange(e.target.value)}
                warning={warning}
            />
            <Checkbox
                label={
                    texts.modules.redirectToUrlOption.openInNewTabLabel[lang]
                }
                isChecked={actionData.openInNewTab}
                onChange={handleNewTabChange}
            />
        </div>
    );
};
const ConsoleLogModule = ({
    lang,
    isHovered,
    logicItemData,
    actionData,
    onChange,
}) => {
    const handleArgTypeChange = useCallback(
        (typeData) => {
            onChange({ arg: typeData });
        },
        [onChange]
    );

    const handleArgValueChange = useCallback(
        (value) => {
            const newArgData = {
                ...actionData.arg,
                value,
            };

            onChange({ arg: newArgData });
        },
        [actionData, onChange]
    );

    return (
        <ArgumentSetup
            key={'arg_0'}
            argInx={0}
            args={[actionData.arg, null]}
            isHovered={isHovered}
            onSelectType={handleArgTypeChange}
            onValueChange={handleArgValueChange}
        />
    );
};

const PageSelection = ({ lang, forbiddedPages, value, onChange }) => {
    const btnRef = useRef();
    const [isPopperActive, setPopperActive] = useState(false);
    const pagesData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData
    );

    const pageLabel = useMemo(() => {
        if (value && pagesData[value]) {
            return pagesData[value].key;
        }

        return '';
    }, [pagesData, value]);

    const handleSelect = useCallback(
        (selected) => {
            onChange({ page: selected });
            setPopperActive(false);
        },
        [onChange]
    );

    return (
        <>
            <div ref={btnRef}>
                <Button
                    label={pageLabel || texts.modules.pageSelectionLabel[lang]}
                    theme="Plain"
                    colorSet="Grayscale"
                    bgStyle={{
                        height: 32,
                        padding: 8,
                        margin: 4,
                    }}
                    labelStyle={{
                        font: value ? 'var(--font-code)' : 'var(--font-body)',
                        marginBottom: value ? 4 : 0,
                        color: value
                            ? 'var(--color-type-high-emphasis)'
                            : 'var(--color-type-low-emphasis)',
                    }}
                    // iconAfter='chevron_down_arrow'
                    onClick={() => setPopperActive(true)}
                />
            </div>
            <GotoPageSelection
                referenceElement={btnRef.current}
                isActive={isPopperActive}
                forbiddenPages={forbiddedPages}
                onSelect={handleSelect}
                onDismiss={() => setPopperActive(false)}
                dir="ltr"
            />
        </>
    );
};
