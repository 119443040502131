import React, { useCallback, useEffect, useMemo } from 'react';
import propsStyles from '../SurveyEditorProperties.module.css';
import Checkbox from 'components/Checkbox';
import Dropdown from 'components/DropDown';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { selectPageProperty, pageContentChanged } from 'features/survey_editor/surveyEditorSlice';
import { scaleSecondaryButtonInitialData, surveyLanguages } from 'features/survey_editor/surveyEditorConfiguration';
import { surveyEditorTexts } from 'utils/appTexts';

const generalTexts = surveyEditorTexts.properties.pageSettings;
const texts = generalTexts.settingsModules.scale;


export default function ScaleSettingsModule({settings, updateSettings}) {

    const lang = useSelector( selectUiLanguage );
    const generalSettings = settings.general;
    
    return (
        <>
            <ScaleSize lang={ lang } settings={ generalSettings } updateSettings={ updateSettings }/>
            <CustomScaleValues lang={ lang } settings={ generalSettings } updateSettings={ updateSettings }/>
            <RandomizeSettings lang={ lang } settings={ generalSettings } updateSettings={ updateSettings }/>
            <SecondaryButton lang={ lang } settings={ generalSettings } updateSettings={ updateSettings }/>
        </>
    )
}

const ScaleSize = ({lang, settings, updateSettings}) => {

    const { scaleSize } = settings;

    const dispatch = useDispatch();
    const currentPage = useSelector( state => state.surveyEditor.present.display.currentPage );

    const scaleData = useSelector( state => {
        if( !currentPage ) return null;
        return selectPageProperty( state, currentPage, "scaleData" );
    });

    const scaleSizeOptions = useMemo( () => {
        const options = [];
        for( let i=2; i<8; i++ ) options.push( {key:i, label: i.toString()} );
        return options;
    }, [])

    const handleScaleSize = useCallback( ( sizeOption ) => {
        const size = sizeOption.key;
        let newScaleData = Array.from( scaleData );

        if( newScaleData.length > size ) {

            newScaleData = newScaleData.slice( 0, size  );

        } else {

            for( let i = newScaleData.length; i < size; i++ ) {
                const item = {
                    key: "scale_" + (i + 1),
                    text: {}
                };
                
                Object.values( surveyLanguages ).forEach( lang => {
                    item.text[ lang.key ] = (i+1).toString();
                })
                newScaleData.push( item );
            }
        }

        // console.log( newScaleData );

        dispatch( pageContentChanged( {id: currentPage, prop: 'scaleData', value: newScaleData} ) );
        updateSettings( {prop: 'scaleSize', value: size} );

    }, [currentPage, scaleData, updateSettings, dispatch])

    return(
        <Dropdown
            className={ propsStyles.dropdown }
            label={ texts.scaleSize[ lang ]}
            options={ scaleSizeOptions }
            value={ scaleSize }
            onChange={ handleScaleSize }
        />
    )
}

const CustomScaleValues = ({lang, settings, updateSettings}) => {

    const { scaleSize, customScaleValues } = settings;

    const dispatch = useDispatch();
    const currentPage = useSelector( state => state.surveyEditor.present.display.currentPage );

    const handleCustomScaleValues = useCallback( ( isChecked ) => {

        if( !isChecked ) {
            // reset scale values:
            const newScaleData = [];

            for( let i = 0; i < scaleSize; i++ ) {
                const item = {
                    key: "scale_" + (i + 1),
                    text: {}
                };

                Object.values( surveyLanguages ).forEach( lang => {
                    item.text[ lang.key ] = (i+1).toString();
                })
                newScaleData.push( item );
            }

            dispatch( pageContentChanged( {id: currentPage, prop: 'scaleData', value: newScaleData} ) );
        }

        updateSettings( {prop: 'customScaleValues', value: isChecked} );
    }, [currentPage, updateSettings, scaleSize, dispatch]);

    return(
        <Checkbox
            size='s'
            className={ propsStyles.checkbox }
            label={ texts.customScaleValues[ lang ] }
            isChecked={ customScaleValues }
            onChange={ handleCustomScaleValues }
            disabled={ false } 
        />
    )
}

const RandomizeSettings = ({ lang, settings, updateSettings }) => {

    
    const { randomizeItems, randomDrawCount } = settings;
    
    const currentPage = useSelector( state => state.surveyEditor.present.display.currentPage );
    const pageData = useSelector( state => {
		
		if( !currentPage ) return null;
		return state.surveyEditor.present.survey.content.pagesData[ currentPage ];

	});

    const enableRandomSettings = useMemo( () => {

        return randomizeItems && pageData.itemsOrder && pageData.itemsOrder.length > 1

    }, [randomizeItems, pageData])

    const drawCountOptions = useMemo( () => {

        if( !pageData.itemsOrder || pageData.itemsOrder.length === 0 ) return [];

        const options = [];
        for( let i=1; i < pageData.itemsOrder.length; i++ ) {
            options.push( { key: i, label: i })
        }
        options.push( { key: -1, label: texts.drawAll[ lang ] } );

        return options;

    }, [pageData, lang]);

    const handleDrawCount = useCallback( ( {key,label} ) => {

        updateSettings( {prop: 'randomDrawCount', value: key} );

    }, [updateSettings]);

    useEffect( () => {

        if( pageData && pageData.itemsOrder && randomDrawCount > pageData.itemsOrder.length ) {
            // items have probably been deleted. set randomDrawCount to 'all':
            updateSettings( {prop: 'randomDrawCount', value: -1} )
        }

    }, [pageData, randomDrawCount, updateSettings])

    return (
        <>
            <Checkbox
                size='s'
                className={ propsStyles.checkbox }
                label={ generalTexts.randomizeItems[ lang ] }
                isChecked={ randomizeItems }
                onChange={ isChecked => updateSettings( {prop: 'randomizeItems', value: isChecked} ) }
                disabled={ !(pageData && pageData.itemsOrder.length > 1 )  }
            />
            {
                enableRandomSettings &&
                <Dropdown
                    className={ propsStyles.dropdown }
                    label={ texts.randomDrawCount[ lang ] }
                    options={ drawCountOptions }
                    value={ randomDrawCount }
                    onChange={ handleDrawCount }
                />
            }
        </>        
    )
}

const SecondaryButton = ({lang, settings, updateSettings}) => {

    const { showSecondaryButton } = settings;
    const dispatch = useDispatch();
    const currentPage = useSelector( state => state.surveyEditor.present.display.currentPage );

    const handleChange = useCallback( (isChecked) => {
        updateSettings( {prop: 'showSecondaryButton', value: isChecked} );

        if( isChecked ) {
            dispatch( pageContentChanged( {id: currentPage, prop: 'secondaryButtonData', value: scaleSecondaryButtonInitialData} ) );
        } else {
            dispatch( pageContentChanged( {id: currentPage, prop: 'secondaryButtonData', value: null} ) );
        }

    }, [currentPage, updateSettings, dispatch])

    return (
        <Checkbox
            size='s'
            className={ propsStyles.checkbox }
            label={ texts.showSecondaryButton[ lang ] }
            isChecked={ showSecondaryButton }
            onChange={ handleChange }
            // disabled={  }
        />
    )
}