import React, { useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SidePanelButton from './SidePanelButton';
import Button from '../../components/Button';
import SelectionPopper from '../../components/poppers/SelectionPopper';
import { selectUiLanguage } from '../../app/preferencesSlice';
import { setCurrentTeam, selectCurrentTeamLabels, setCurrentLabelId, setShowArchive, selectCurrentTeam } from '../../app/horizonDataSlice';
import styles from './Home.module.css';
import { homeTexts } from '../../utils/appTexts';

export default function HomeSidePanel(props) {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const teams = useSelector(state => state.horizonData.teams);
    const currentTeam = useSelector(selectCurrentTeam);
    const labels = useSelector(selectCurrentTeamLabels);
    const currentLabel = useSelector(state => state.horizonData.currentLabelId);
    const showArchive = useSelector(state => state.horizonData.showArchive);
    const [selectionPopperActive, setSelectionPopperActive] = useState(false);
    const spBtnRef = useRef(null);
    const [returnToHome, setReturnToHome] = useState(false)

    const setTeam = useCallback((option) => {
        dispatch(setCurrentTeam(option.key));
        setSelectionPopperActive(false);

    }, [dispatch])
    const setLabel = useCallback((labelId) => {
        dispatch(setCurrentLabelId(labelId));
    }, [dispatch])
    const handleHome = useCallback(() => {
        dispatch(setCurrentLabelId(''));
        dispatch(setShowArchive(false))
    }, [dispatch])
    const handleArchive = useCallback(() => {
        setReturnToHome(false)
        if (!showArchive && currentLabel === '') {
            setReturnToHome(true)
            dispatch(setCurrentLabelId('all'));
        }
        if (showArchive && returnToHome) {
            dispatch(setCurrentLabelId(''));
        }
        dispatch(setShowArchive(!showArchive))
    }, [showArchive, currentLabel, returnToHome, dispatch])
    
    const LabelsList = () => {
        return <div id={styles.labelsList}>
            {labels && labels.map(label =>
                <SidePanelButton
                    key={label.id}
                    label={label.name}
                    isSelected={label.id === currentLabel}
                    onClick={() => setLabel(label.id)}
                    showOptions={false}
                    color={!showArchive ? label.color : currentLabel === label.id ? label.color : '#AAAAAA'}
                    bgStyle={showArchive && currentLabel !== label.id ? { color: 'grey' } : null}
                />
            )
            }
        </div>
    }

    if (!currentTeam) {
        return  <div className={styles.sidePanel} />
    }

    return (
        <div className={styles.sidePanel}>
            <div className={styles.sectionContainer}>
                <div className={styles.sideBarSectionA}>
                    <div ref={spBtnRef}> {/*div wrapper for SelectionPopper reference*/}
                        <Button
                            label={currentTeam.name}
                            theme="Plain"
                            onClick={() => setSelectionPopperActive(true)}
                            iconAfter={Object.values(teams).length > 1 ? "small_arrow_down" : ""}
                            colorSet='Grayscale'
                            size='l'
                            bgStyle={{
                                font: 'var( --font-headline-h2 )',
                                color: 'var( --color-type-medium-emphasis )',
                                width: '100%',
                                height: 64,
                                margin: 0,
                                paddingLeft: 12,
                                paddingRight: 12,
                                justifyContent: 'space-between',
                                borderBottom: 'var( --border-light )',
                                borderRadius: 0,
                                pointerEvents: Object.values(teams).length > 1 ? 'auto' : 'none',
                            }}
                            labelStyle={{
                                textAlign: 'left',
                                font: 'var( --font-headline-h2 )',
                            }}
                            iconStyle={{
                                width: 18
                            }}
                        />
                    </div>

                    <SelectionPopper
                        referenceElement={spBtnRef.current}
                        isActive={selectionPopperActive}
                        options={Object.values(teams).map(t => { return { key: t.id, label: t.name } })}
                        onDismiss={() => setSelectionPopperActive(false)}
                        onSelect={setTeam}
                    />

                    <SidePanelButton
                        label={homeTexts.sidePanel.home[lang]}
                        icnName='home'
                        showOptions={false}
                        bgStyle={{ margin: '20px 0px 4px 0px' }}
                        color='--color-type-medium-emphasis'
                        isSelected={currentLabel === ''}
                        onClick={handleHome}
                    />
                    <p className={styles.labelHeadline}> {homeTexts.sidePanel.labelsHeadline[lang]} </p>
                    <SidePanelButton
                        label={homeTexts.sidePanel.allLabels[lang]}
                        isSelected={currentLabel === 'all'}
                        onClick={() => setLabel('all')}
                        showOptions={false}
                        color={!showArchive ? 'black' : currentLabel === 'all' ? 'black' : '#AAAAAA'}
                        bgStyle={showArchive && currentLabel !== 'all' ? { color: 'grey' } : null}
                    />
                    <LabelsList />
                    {/* <Button 
                        label = { homeTexts.sidePanel.addLabel[ lang ] }
                        theme = 'Outlined'
                        size = 'm'
                        colorSet = 'Grayscale'
                        bgStyle={{ margin: '4px 0px 4px 0px' }}
                    /> */}
                    <SidePanelButton
                        label={homeTexts.sidePanel.archive[lang]}
                        icnName={showArchive ? 'x' : 'archive'}
                        showOptions={false}
                        bgStyle={{ margin: '0px 0px 0px 0px' }}
                        color='--color-type-medium-emphasis'
                        onClick={handleArchive}
                    />
                </div>

                <div className={styles.sideBarSectionB}>
                    <Button
                        label={homeTexts.sidePanel.highlights[lang]}
                        theme={'Outlined'}
                        size='m'
                        colorSet='Grayscale'
                        bgStyle={{ margin: '4px 0px 4px 0px' }}
                        disabled={true}
                    />
                    <Button
                        label={homeTexts.sidePanel.tags[lang]}
                        theme={'Outlined'}
                        size='m'
                        colorSet='Grayscale'
                        bgStyle={{ margin: '4px 0px 4px 0px' }}
                        disabled={true}
                    />
                </div>
            </div>
        </div>
    )
}