import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './AnalysesFieldsModules.module.css';
import inputStyles from 'components/InputStyles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReport } from 'app/reportSlice';
import TextInput from 'components/TextInput';
import ColorPicker from 'components/ColorPicker';


const dataTypes = {
    STRING: 'STRING',
    NUMBER: 'NUMBER',
    COLOR: 'COLOR'
}

export default function KeyValuesAndDataFieldModule({ project, data, value, handleChange, texts, lang }) {

    const dispatch = useDispatch();
    const allVariables = useSelector( state => state.analyses.newAnalysis.variables );
    const isReproduced = useSelector( state => state.analyses.newAnalysis.isReproduced );

    const dataKeys = useSelector(state => {

        const dataKeys = state.report.dataKeys;

        if (!dataKeys) {
            if (state.report.fetchStatus === 'idle') {

                dispatch(fetchReport(project.sourceName));
            }
            return null
        }

        return dataKeys

    })

    const [currentKey, setCurrentKey] = useState( null );

    const currentKeyPrefs = useMemo ( () => {

        if (dataKeys && currentKey ) {

            let currentKeyPrefs = dataKeys[currentKey];
            if (!currentKeyPrefs && currentKey.key) {
                // Reference field is type of KEY_NAME_AND_VALUES
                currentKeyPrefs = dataKeys[currentKey.key];
            }

            return currentKeyPrefs;
        }

        return null;

    }, [currentKey, dataKeys])

    const isCategorial = useMemo( () => {

        if ( !currentKeyPrefs ) return false;
        return (
            currentKeyPrefs.valueDescriptions &&
            (
                currentKeyPrefs.type === 'STRING' || 
                currentKeyPrefs.originType === 'SCALE_BUNDLE' || 
                currentKeyPrefs.originType === 'MULTIPLE_CHOICE_BUNDLE'
            )
        ) 

    }, [currentKeyPrefs])

    const isVirtualBool = useMemo( () => {

        if ( !currentKeyPrefs ) return false;
        return (
            currentKeyPrefs.originType === 'VIRTUAL' && 
            currentKeyPrefs.type === 'BOOL' 
        )

    }, [currentKeyPrefs])

    const values = useMemo(() => {

        if ( isCategorial ) {

            // If the reference field type is KEY_NAME_AND_VALUES, use only the selected values:
            if( currentKey.values ) return currentKey.values;

            return Object.keys( currentKeyPrefs.valueDescriptions );
        }

        if( isVirtualBool ) {
            return ['true','false']
        }

        return [];

    }, [currentKey, isCategorial, isVirtualBool, currentKeyPrefs])

    const descriptions = useMemo(() => {

        if (isCategorial) {

            return values.map( v => currentKeyPrefs.valueDescriptions[ v ]);
            
        }
        return [];

    }, [isCategorial, currentKeyPrefs, values])

    const Input = useMemo(() => {

        switch (data.dataType) {
            case dataTypes.STRING: return StringInput;
            case dataTypes.NUMBER: return NumberInput;
            case dataTypes.COLOR: return ColorInput;
            default:
                return StringInput;
        }

    }, [data])

    const valuesData = useMemo(() => allVariables[data.key], [allVariables, data]);

    const isLtr = useMemo(() => lang === 'en', [lang]);

    useEffect( () => {

        if( !currentKey && isReproduced ) {
            setCurrentKey( allVariables[ data.ref ] )
            return;
        }

        // if the current reference key changes, remove previous data:
        if( currentKey !== allVariables[ data.ref ] ) {

            handleChange( null );
            setCurrentKey( allVariables[ data.ref ] )
        }

    }, [allVariables, isReproduced, currentKey, data, handleChange])

    useEffect( () => {

        if( !valuesData && values ) {

            // Assign initial values:

            if ( data.dataType === dataTypes.STRING ) {

                if( Object.keys( descriptions ).length > 0 ) {

                    // Use value descriptions as the initial text:
    
                    const result = {};
                    values.forEach( (v,i) => result[v] = descriptions[i] );
    
                    handleChange( result );
                    return;
                }

                if( isVirtualBool ) {
                    
                    const result = {
                        'true': 'true',
                        'false': 'false'
                    };
    
                    handleChange( result );
                    return;
                }
            }

            
            if ( data.dataType === dataTypes.COLOR ) {

                const colors = data.defaultColors;

                if( colors && colors.length > 0 ) {

                    const result = {};
                    values.forEach( (v,i) => {
                        result[v] = colors[i] ? colors[i] : '#000000'
                    })

                    handleChange( result );
                }

                return;
            }

        }

    }, [ handleChange, data, values, descriptions, valuesData, isVirtualBool] )


    const handleValueChange = useCallback((key, value) => {

        const result = valuesData ? { ...valuesData } : {};
        result[key] = value;
        // console.log(value, result);

        handleChange(result);

    }, [handleChange, valuesData])

    return (
        <>
            {
                (values && Object.keys( values ).length > 0) ?
                    <>
                        <label className={inputStyles.label}>{data.label ? data.label[lang] : ''}</label>
                        {
                            values.map((k, i) => {

                                return (

                                    <div
                                        key={k + i}
                                        className={styles.spreadedKeysCont}
                                    >
                                        <label
                                            className={styles.spreadedKeysLabel}
                                            style={{
                                                textAlign: isLtr ? 'right' : 'left'
                                            }}
                                        >
                                            { k }
                                        </label>
                                        <div style={{ minWidth: 6 }} />
                                        <Input 
                                            value={ valuesData ? valuesData[k] : null } 
                                            onChange={ (value) => handleValueChange(k, value) } 
                                        />
                                    </div>
                                )
                            })
                        }
                    </>
                    :
                    null
            }
        </>
    )
}

const StringInput = ({ value, onChange }) => {

    const [tempValue, setTempValue] = useState(value);

    useEffect( () => setTempValue( value ), [value]);

    const applyChanges = useCallback(() => {

        onChange(tempValue);

    }, [tempValue, onChange])

    return (
        <TextInput
            type='text'
            placeholder={'...'}
            value={tempValue || ""}
            onChange={(e) => setTempValue(e.target.value)}
            onBlur={() => applyChanges()}
            onReturn={() => applyChanges()}
        />
    )
}

const NumberInput = ({ value, onChange }) => {

    const [tempValue, setTempValue] = useState(value);

    const applyChanges = useCallback(() => {

        onChange(tempValue);

    }, [tempValue, onChange])

    return (
        <TextInput
            type='number'
            placeholder={'#'}
            value={(tempValue !== null && tempValue !== undefined) ? tempValue : ""}
            onChange={(e) => setTempValue(e.target.value)}
            onBlur={() => applyChanges()}
            onReturn={() => applyChanges()}
        />
    )
}

const ColorInput = ({ value, onChange }) => {

    return (
        <ColorPicker
            value={value || '#000000'}
            onChange={onChange}
            className={ styles.colorInput }
        />
    )
}