import Button from 'components/Button';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { surveyEditorTexts } from 'utils/appTexts';
import styles from './PublishAndExport.module.css';
import RestoreAlert from './RestoreAlert';
import useParseBackup from './useParseBackup';

const texts = surveyEditorTexts.properties.publishAndExport;

export default function SurveyVersions({ lang }) {
    const versions = useSelector(
        (state) => state.surveyEditor.present.surveyVersions
    );

    if (versions)
        return (
            <>
                <p className={styles.versionsSectionLabel}>
                    {texts.versionsSectionLabel[lang]}
                </p>
                <div className={styles.versionsCont}>
                    {versions.urls &&
                        Object.keys(versions.urls)
                            .reverse()
                            .map((date) => (
                                <VersionItem
                                    key={date}
                                    lang={lang}
                                    date={date}
                                    url={versions.urls[date]}
                                    isCurrent={versions.current === date}
                                    onSelect={() => {
                                        console.log(
                                            'TODO: load survey: ' + date
                                        );
                                    }}
                                />
                            ))}
                </div>
            </>
        );

    return null;
}

function formatDate(date) {
    let [_date, _time] = date.split('_');
    _date = _date.replaceAll('-', '/');
    _time = _time.slice(0, 5);
    _time = _time.replaceAll('-', ':');
    return `${_date} \xa0${_time}`;
}

const VersionItem = ({ lang, date, url, isCurrent, onSelect }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [restoreAlertActive, setRestoreAlertActive] = useState(false);
    const [versionJson, setVersionJson] = useState(null);

    const backupParser = useParseBackup();

    const IsCurrent = () => {
        if (isCurrent)
            return (
                <>
                    <Icon name="vi" color="--color-primary-medium" size={16} />
                    <div className={styles.gap} />
                </>
            );
        else return <div style={{ width: 24 }} />;
    };

    const handleCopyUrl = useCallback(() => {
        navigator.clipboard.writeText(url).then(
            function () {
                alert(`Copied ${url}.`);
            },
            function () {
                alert(`Failed to copy ${url}`);
            }
        );
    }, [url]);

    const handleRestoreButton = useCallback(async () => {
        await fetch(url, { method: 'GET' })
            .then((result) => {
                if (!result.ok) {
                    throw result;
                }
                return result.json();
            })
            .then((json) => {
                const surveyData = backupParser(JSON.stringify(json));
                if (surveyData) {
                    setVersionJson(surveyData);
                    setRestoreAlertActive(true);
                }
            })
            .catch((err) => {
                alert('Failed to fetch version data. ' + err);
            });
    }, [url, backupParser]);

    return (
        <>
            <div
                className={styles.versionItemCont}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onFocus={() => setIsHovered(true)}
                onBlur={() => setIsHovered(false)}
            >
                <IsCurrent />
                <div className={styles.dateLabel}> {formatDate(date)} </div>

                {isHovered && (
                    <>
                        <IconButton
                            name="link"
                            theme="Plain"
                            size="s"
                            tooltip={texts.copyVersionUrl[lang]}
                            onClick={handleCopyUrl}
                        />
                        <div className={styles.gap} />
                        <Button
                            label={texts.loadVersionBtn[lang]}
                            theme="Outlined"
                            size="s"
                            onClick={handleRestoreButton}
                        />
                    </>
                )}
            </div>
            <RestoreAlert
                isActive={restoreAlertActive}
                surveyData={versionJson}
                onClose={() => setRestoreAlertActive(false)}
            />
        </>
    );
};
