import React, { useCallback, useEffect, useState } from 'react';

import NotebookSection from './notebook_components/NotebookSection';
import BlankNotebook from './BlankNotebook';
import ActivityIndicator from 'components/ActivityIndicator';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { sectionsReordered } from './notebookSlice';


export default function NotebookContent() {

    const dispatch = useDispatch();
    const sectionsOrder = useSelector( state => state.notebook.editor.sectionsOrder );
    const remoteDataFetchStatus = useSelector( state => state.notebook.remoteDataFetchStatus );

    const [isInitialFetch, setIsInitialFetch] = useState( true );

    useEffect( () => {

        if( isInitialFetch && remoteDataFetchStatus !== 'idle' && remoteDataFetchStatus !== 'loading' ) {
            setIsInitialFetch( false );
        }

    }, [isInitialFetch,remoteDataFetchStatus])

    const handleDragEnd = useCallback( ( result ) => {

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }
        
        dispatch( sectionsReordered( {
            startIndex: result.source.index, 
            endIndex: result.destination.index
        } ) );

    }, [dispatch])

    if( isInitialFetch ) { return <ActivityIndicator size={ 24 }/> }

    if( !sectionsOrder || sectionsOrder.length < 1 ) { return <BlankNotebook/> }

    return (
        <>
            <DragDropContext 
                onDragEnd={ handleDragEnd } 
            >
                <Droppable droppableId="notebook_sections_list" type="ROOT">
                    {
                        (provided, snapshot) => (
                            <div 
                                ref={ provided.innerRef } 
                                { ...provided.droppableProps } 
                            >                               
                                {
                                    sectionsOrder.map( (s,i) => (
                                        <NotebookSection key={s} id={s} index={ i }/>
                                    ))
                                }
                                { provided.placeholder }
                            </div>
                        )
                    }
                </Droppable>
            </DragDropContext>

        </> 
    )
}
