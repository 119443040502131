import React, { useCallback, useMemo } from 'react';
import Snackbar, { types as SnackbarTypes } from 'components/poppers/Snackbar';
import { useDispatch, useSelector } from 'react-redux';

import { resultsTexts } from 'utils/appTexts';
import { messageRemoved } from 'app/reportSlice';
import { selectUiLanguage } from 'app/preferencesSlice';

const texts = resultsTexts.virtualVarEditor.messages;

export const Types = {
    ILLEGAL_KEY_CHARACTER: {
        message: ( lang, args ) => texts.illegalKeyCharacter[ lang ],
        type: SnackbarTypes.WARNING,
        timeout: 6,
        action: ( lang ) => ({ 
            label: texts.actions.gotIt[ lang ],
            callback: () => {}
        })
        
    }
}

export default function VirtualVarsMessages () {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const message = useSelector( state => state.report.virtualVars.editor.message );

    const config = useMemo( () => {
        return ( message ? Types[ message.type ] : null );
    }, [message] )

    const handleOnClose = useCallback( () => {
        
        dispatch( messageRemoved() );
        
    },[dispatch])
    
    if( !message ) return null;
        
    return (
        <>
            <Snackbar
                key={ message.id }
                message={ config.message( lang, message.args )}
                type={ config.type }
                timeout={ config.timeout }
                index={ 0 }
                onClose={ handleOnClose }
                action={ config.action( lang ) }
            />
        </>
    )
}