import React, { useState, useRef, useMemo } from "react";
import Button from "./Button";
import MultipleSelectionPopper from "./poppers/MultipleSelectionPopper";
import styles from "./InputStyles.module.css";

/**
 *
 * @param {{
 * className: string, // css class name for the container element
 * label: string,
 * options: array<object>, { key, label, isSelected }
 * placeholder: string, // String to display when there are no selected options.
 * theme: enum, // See Button themes
 * colorSet: enum, // See Button colorSets
 * dir,
 * contStyle: object
 * bgStyle: object
 * labelStyle: object
 * isLoading: boolean // Show loader at the bottom of the list
 * onChange: function, // Returns { key, label }; if options are passed as DOM children, the return value is only a key.
 * }}
 */
export default function MultipleSelect({
    className,
    label,
    options,
    children,
    placeholder,
    theme = "Outlined",
    colorSet = "Field",
    dir = "auto",
    contStyle,
    bgStyle,
    labelStyle,
    isLoading,
    onChange,
}) {
    const spBtnRef = useRef();
    const [selectionPopperActive, setSelectionPopperActive] = useState(false);

    const selected = useMemo(() => {
        if (!options && !children) return null;
        const selectedList = [];

        if (options) {
            options.forEach((op) => {
                if (op.isSelected) selectedList.push(op.label);
            });
        }

        if (children) {
            React.Children.forEach(children, (c, i) => {
                if (c.props && c.props.isSelected) {
                    selectedList.push(c.props.label);
                }
            });
        }

        if (selectedList.length < 1) return "...";

        return selectedList.join(", ");
    }, [options, children]);

    function handleChange(op) {
        if (onChange) {
            onChange(op);
        }
    }

    return (
        <div className={className} style={contStyle}>
            {label && <label className={styles.label}>{label}</label>}
            <div ref={spBtnRef}>
                {" "}
                {/*div wrapper for SelectionPopper reference*/}
                <Button
                    label={selected || placeholder}
                    theme={theme}
                    iconAfter={"arrow_down"}
                    colorSet={colorSet}
                    size="m"
                    bgStyle={{
                        boxSizing: "border-box",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "6px 14px",
                        height: 40,
                        ...bgStyle,
                    }}
                    labelStyle={{
                        font: "var( --font-body-medium)",
                        color: `var( --color-type-${
                            selected ? "high" : "low"
                        }-emphasis)`,
                        flex: 1,
                        textAlign: "start",
                        ...labelStyle,
                    }}
                    onClick={() => setSelectionPopperActive(true)}
                />
            </div>

            <MultipleSelectionPopper
                referenceElement={spBtnRef.current}
                isActive={selectionPopperActive}
                options={options}
                children={children}
                onDismiss={() => setSelectionPopperActive(false)}
                isLoading={isLoading}
                onSelect={handleChange}
                dir={dir}
            />
        </div>
    );
}

/**
 * Use SelectionItem to wrap up custom children. It's required because we need to pass on to MultipleSelectionPopper information such as 'isSelected' through the props
 * @param {{
 * label,
 * isSelected,
 * children,
 * }} params
 */
export function SelectionItem({ label, isSelected, children }) {
    return <span>{children}</span>;
}
