export const getSurveyClientUrl = () => {

    let url = 'https://hrzn.app/';

    const hostname = window.location.hostname;
    
    if (hostname.indexOf('kayma-horizon-staging.herokuapp.com') === 0) {
        // STAGING
        url = 'https://hrzn.app/'
    }

    if (hostname.indexOf('kayma-horizon.herokuapp.com') === 0) {
        // PRODUCTION
        url = 'https://hrzn.app/'
    }

    if (process.env.NODE_ENV === 'development') {
        // LOCAL
        url = 'http://localhost:3006/'
    }

    return url;
}