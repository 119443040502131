import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNotebook } from 'features/notebook/notebookSlice';

import debounce from 'debounce';

const DEBOUNCE_FETCH_DELAY_MS = 1000;

/**
 * This service is responsible for synchronizing the notebook with remote changes.
 */
export default function NotebookSynchronizer( {notebookId} ) {

    const dispatch = useDispatch();
    const remoteDataFetchStatus = useSelector( state => state.notebook.remoteDataFetchStatus );
    const remoteDataUpdaetStatus = useSelector( state => state.notebook.remoteDataUpdaetStatus );
    const isEditing = useSelector( state => state.notebook.editor.isEditing );


    const debouncedFetch = useMemo( () =>

        debounce( () => {
            dispatch( fetchNotebook( notebookId ) );
            
        }, DEBOUNCE_FETCH_DELAY_MS),
    [notebookId, dispatch]);


    useEffect( () => {

        if( remoteDataFetchStatus !== 'loading' && remoteDataUpdaetStatus !== 'loading' && !isEditing) {

            // Keep executing fetch calls as long as the client is not busy saving local changes or already fetching.
            debouncedFetch();
        }

    }, [remoteDataFetchStatus, remoteDataUpdaetStatus, isEditing, debouncedFetch] )

    useEffect( () => {

        // Make sure there are no scheduled executions when unmounted:

        return () => {
            debouncedFetch.clear();
        }

    }, [debouncedFetch])

    return null;

}

