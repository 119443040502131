import React, { useCallback, useMemo, useRef, useState } from 'react';
import styles from './ConditionBuilder.module.css';
import AddConditionItem from './AddConditionItem';
import IconButton from 'components/IconButton';
import SelectionPopper from 'components/poppers/SelectionPopper';
import LogicOperator from './LogicalOperator';
import ConditionRule from './ConditionRule';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { surveyEditorTexts } from 'utils/appTexts';
import { logicOperators } from '../LogicConfiguration';
import { motion } from 'framer-motion';
import { logicItemConditionArgChanged, logicItemConditionArgRemoved } from 'features/survey_editor/surveyEditorSlice';

const texts = surveyEditorTexts.properties.logic.itemEditor.condition;

export default function ConditionGroup({logicItemId, isMain = false, args, rules, onChange, onDelete, onUngroup}) {

    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState( false );

    const [operator, items] = useMemo( () => {

        return Object.entries( rules )[ 0 ];

    }, [rules])

    ///////////  
    // UTILS //
    ///////////

    const handleChangeOperator = useCallback( (operator) => {

        onChange( { [operator]: items } );

    }, [items, onChange])

    const handleAdd = useCallback( (item) => {

        const newItems = Array.from( items );
        
        if( item === 'group' ) {
            newItems.push( { 'and': [] } )
        }

        if( item === 'rule' ) {
            const arg_1_id = uuidv4();
            const arg_2_id = uuidv4();

            newItems.push({ 
                '==': [
                    {'var': arg_1_id },
                    {'var': arg_2_id }
                ]   
            })

            dispatch( logicItemConditionArgChanged( { itemId: logicItemId, argId: arg_1_id } ) );
            dispatch( logicItemConditionArgChanged( { itemId: logicItemId, argId: arg_2_id } ) );
        }
        
        onChange( { [operator]: newItems } );

    }, [logicItemId, operator, items, onChange, dispatch])

    const handleDelete = useCallback( ( inx ) => {

        const newItems = Array.from( items );
        const removed = newItems.splice( inx, 1 );
        const args = Object.values( removed[ 0 ] )[ 0 ];
        args.forEach( a => {
            dispatch( logicItemConditionArgRemoved( { itemId: logicItemId, argId: a.var } ) )
        })
        onChange( {[operator]: newItems })

    }, [logicItemId, operator, items, onChange, dispatch])

    const handleUngroup = useCallback( ( inx, group ) => {

        const groupRules = Object.values( group )[0];
        const newItems = Array.from( items );
        newItems.splice( inx, 1, ...groupRules );
        onChange( {[operator]: newItems })

    }, [operator, items, onChange])

    const handleChildChange = useCallback( ( inx, childRule ) => {

        const newItems = Array.from( items );
        newItems[ inx ] = childRule;

        onChange( { [operator]: newItems } );

    }, [operator, items, onChange]);

    const handleArgChange = useCallback( ( argData ) => {

        dispatch( logicItemConditionArgChanged( {
            itemId: logicItemId, 
            argId: argData.id, 
            data: argData
        } ) );

    }, [ logicItemId, dispatch ])

    ////////////  
    // RENDER //
    ////////////

    const children = useMemo( () => {

        return items.map( (item, i) => {

            const itemOperator = Object.keys( item )[ 0 ];
            if( Object.values( logicOperators ).includes( itemOperator ) ) {
                return (
                    <ConditionGroup
                        key={ 'group_' + i }
                        logicItemId={ logicItemId }
                        args={ args }
                        rules={ item }
                        onChange={ newRule => handleChildChange( i, newRule ) }
                        onDelete={ () => handleDelete( i ) }
                        onUngroup={ () => handleUngroup( i, item ) }
                    />
                )
            } else return (
                <ConditionRule
                    key={ 'rule_' + i }
                    args={ args }
                    rule={ item }
                    onChange={ newRule => handleChildChange( i, newRule ) }
                    onArgChange={ handleArgChange }
                    onDelete={ () => handleDelete( i ) }
                />
            )
        })

    }, [logicItemId, items, args, handleDelete, handleChildChange, handleArgChange, handleUngroup])

    return (
        <motion.div 
            className={ styles.groupCont }
            onMouseEnter={ () => setIsHovered( true ) }
            onMouseLeave={ () => setIsHovered( false ) }
            onBlur={ () => setIsHovered( false ) }

            initial={{scale: 0.9, opacity: 0, y: 10}}
            animate={{scale: 1, opacity: 1, y: 0}}
            transition={{ duration: 0.2, ease: "easeOut" }}
        >
            <div className={ styles.groupHeader }>
                <LogicOperator 
                    operator={ operator }
                    isDisabled={ !items || items.length <= 1 }
                    onChange={ handleChangeOperator }
                />
                {
                    !isMain &&
                    <GroupOptions 
                        isHovered={ isHovered }
                        onUngroup={ onUngroup }
                        onDeleteAll={ onDelete }
                    />
                }
            </div>

            {children}

            <AddConditionItem 
                display={ isHovered }
                onAdd={ handleAdd }
            />

        </motion.div>
    )
}

const GroupOptions = ({isHovered, onDeleteAll, onUngroup}) => {

    const btnRef = useRef();
    const [isPopperActive, setIsPopperActive] = useState( false );

    const options = useMemo( () => {

        return [
            {
                key: 'ungroup', 
                label: texts.groupOptions.ungroup[ 'en' ], 
                cb: onUngroup
            },
            {
                key: 'delete_all', 
                label: texts.groupOptions.deleteAll[ 'en' ], 
                cb: onDeleteAll
            },
        ]

    }, [onDeleteAll, onUngroup])

    const handleSelect = useCallback( (selected) => {

        selected.cb();
        setIsPopperActive( false )

    }, [])

    return (
        <>
            <div ref={ btnRef }>
                <IconButton
                    name='options_horizontal'
                    tooltip={ texts.groupOptions.btnTooltip[ 'en' ] }
                    theme='Plain'
                    colorSet='Grayscale'
					iconStyle={{width: 18, height: 18}}
                    bgStyle={{
                        minWidth: 32, minHeight: 32,
                        opacity: isHovered ? 1 : 0.3
                    }}
                    onClick={ () => setIsPopperActive( true ) }
                />
            </div>
            <SelectionPopper
                referenceElement={ btnRef.current }
                isActive={ isPopperActive }
                options={ options }
                onDismiss={ () => setIsPopperActive( false ) }
                onSelect={ handleSelect }
            />
        </>
    )
}