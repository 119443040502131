import React, {useRef } from 'react';
import styles from './RichTextStyles.module.css';

export function findDynamicTextEntities(contentBlock, callback, contentState) {

    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'DYNAMIC_TEXT'
            );
        },
        callback
    );
}

export const DynamicTextEntityComp = (props) => {

    const contRef = useRef();

    return (
        <>
            <span 
                ref={ contRef } 
                className={ styles.dynamicTextDecorator }
            >
                {props.children}
            </span>
        </>
    );
};