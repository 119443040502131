
import React from 'react';
import {Line} from 'react-chartjs-2';




export default function InsightsGraph({ stepsCounts }) {

    const graphData = (canvas) => {
    
      const ctx = canvas.getContext("2d")
      const gradient = ctx.createLinearGradient(0, 0, 0, 350);
      gradient.addColorStop(0, 'rgba(72, 119, 255, 0.7)');
      gradient.addColorStop(1, 'rgba(214, 224, 255, 0)');

      return {
    
        labels: stepsCounts.map( (s,i) => `s.${i+1}`),
        datasets: [
          {
            lineTension: 0.0,
            backgroundColor: gradient,
            borderColor: '#4877FF',
            borderWidth: 3,
            data: stepsCounts,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: '#4877FF',
            pointBackgroundColor: 'rgba( 72, 119, 255, 0 )',
            pointHoverBorderWidth: 0.00001,
            pointRadius: 10,
            pointBorderWidth: 0.00001,
          }
        ]
    
      }
      
    }
  
    return (
    
        <Line
          data={graphData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            legend:{
              display: false,
              position:'right'
            },
            scales: {
              yAxes: [{
                  gridLines: {
                      display: false
                  },
                  ticks: {
                      stepSize: 100
                  }
              }]
            },
            tooltips: {
              titleFontSize: 14,
              bodyFontSize: 14,
              xPadding: 12,
              yPadding: 8,
              displayColors: false,
            }
          }}
        />
     
    );
  
}