import React, { useCallback, useMemo, useState } from 'react';
import styles from './ScalePageModule.module.css';
import { Editable } from 'components/Editable';
import IconButton from 'components/IconButton';
import TextInput from 'components/TextInput';
import PageEditableItemsList from './page_module_components/PageEditableItemsList';

import { useSelector, useDispatch } from 'react-redux';
import { selectPageProperty, pageTextContentChanged, pageContentChanged, messageAdded } from 'features/survey_editor/surveyEditorSlice';
import { selectUiLanguage } from 'app/preferencesSlice';
import { textDirection } from 'utils/stylingTools';
import { isKeyLegal } from 'utils/validation';
import { surveyEditorTexts } from 'utils/appTexts';
import RichTextInput, { getRichText } from 'components/rich_text/RichTextInput';

const texts = surveyEditorTexts.content.pageModules.scale;

export default function ScalePageModule( {pageId, pageHovered} ) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );
    const legend = useSelector( state => selectPageProperty( state, pageId, 'subtitle' ) );
    const pageSettings = useSelector( state => selectPageProperty( state, pageId, 'settings' ) );
    const { customScaleValues, showSecondaryButton } = pageSettings.general;

    const legendPlaceholder = useMemo( () => {

        return texts.placeholder[ surveyLang ].replace( '{low}', 1 ).replace( '{high}', pageSettings.general.scaleSize );

    }, [surveyLang, pageSettings])

    const handleTextChange = useCallback( ( prop, value ) => {

        dispatch( pageTextContentChanged( {id: pageId, prop, value, lang: surveyLang }) );

    }, [pageId, surveyLang, dispatch] )

    const handleLegendFocus = useCallback( () => {

        if( !legend[ surveyLang ] ) {
            handleTextChange( 'subtitle', getRichText( legendPlaceholder ) )
        }

    }, [legend, surveyLang, legendPlaceholder, handleTextChange])

    return (
        <>

            <RichTextInput
                key={ 'subtitle_' + surveyLang }
                className = { styles.legend }
                value = { legend[ surveyLang ] || "" }
                placeholder = { legendPlaceholder }
                textAlignment={ textDirection( lang ).align }
                onChange = { value => handleTextChange( 'subtitle', value ) }
                onFocus={ handleLegendFocus }

            />
            <PageEditableItemsList pageId={ pageId } pageHovered={ pageHovered }/>
            { customScaleValues && 
                <>
                    <ScaleValues pageId={ pageId } surveyLang={ surveyLang } isHovered={ pageHovered }/>
                    {
                        showSecondaryButton &&
                        <SecondaryButton pageId={ pageId } surveyLang={ surveyLang } isHovered={ pageHovered }/>
                    }
                </>
            }
        </>
    )
}

const ScaleValues = ({ pageId, surveyLang, isHovered }) => {

    const dispatch = useDispatch();
    const scaleData = useSelector( state => {
        
        // Add warning if there's a duplicated key:

        let scaleData = selectPageProperty( state, pageId, 'scaleData' );
        scaleData = scaleData.map( (item,i) => {

            let alreadyExists = false;

            for( let j=0; j< scaleData.length; j++){
                if( j !== i && scaleData[ j ].key === item.key ) {
                    alreadyExists = true;
                    break;
                }
            }

            return ( {
                warning: alreadyExists,
                ...item
            } )
        })

        return scaleData;

    });

    function handleScaleValueChange( inx, key, text ) {
        const newValues = scaleData.map( item => ({
            key: item.key,
            text: {...item.text}
        }));

        if( text !== null ) {
            newValues[ inx ].text[ surveyLang ] = text;
        }

        if( key !== null ) {
            newValues[ inx ].key = key;
        }

        dispatch( pageContentChanged( {id: pageId, prop: 'scaleData', value: newValues } ) );
    }

    return (
        <div 
            className={ styles.scaleValuesCont } 
        >
            {
                scaleData.map( (item, inx) => (

                    <ScaleValueInput 
                        key={ inx }
                        pageId={ pageId } 
                        surveyLang={ surveyLang } 
                        itemData={ scaleData[ inx ] }
                        inx={ inx }
                        isHovered={ isHovered }
                        onChange={ (k,t) => handleScaleValueChange( inx, k, t ) }
                    />

                ))
            }
        </div>
    )
}

const ScaleValueInput = ({ surveyLang, itemData, isHovered, onChange }) => {

    const dispatch = useDispatch();
    const text = itemData.text[ surveyLang ];
    const [isEditingKey, setIsEditingKey] = useState( false );
    const lang = useSelector( selectUiLanguage );

    function handleKeyChange( k ) {

        const isLegalKey = isKeyLegal( k );

        if( isLegalKey ) {

            onChange( k, null )
            
        } else {
            dispatch( messageAdded( { type: 'ILLEGAL_KEY_CHARACTER', arg: ""}) )
        }

    }

    const Warning = () => {
        if( itemData.warning ) {
             return (
                 <IconButton
                     name="warning"
                     theme="Plain"
                     colorSet="Grayscale"
                     size="s"
                     tabIndex="-1"
                     tooltip={ texts.keyAlreadyExists[ lang ] }
                 />
             )
        } else {
            return null
        }
     }

    return (
        <div className={ styles.scaleValueInputCont } 
            style={{width: text.length + "ch"}}
        >
                <TextInput
                    value={ text }
                    fieldStyle={{ textAlign: 'center', padding: 4, }}
                    onChange={ e => onChange( null, e.target.value )}
                />

                <div className={ styles.itemKeyCont}>
                    <Warning/>
                    <Editable
                        className={ styles.itemKey }
                        value={ itemData.key }
                        style={{ 
                            visibility: (isHovered || isEditingKey) ? 'visible' : 'hidden',
                            color: itemData.warning ? 'var( --color-error-medium)' : 'var( --color-type-low-emphasis )',
                        }}
                        onChange={ k => handleKeyChange( k )}
                        onFocus={ () => setIsEditingKey( true ) }
                        onBlur={ () => setIsEditingKey( false )}
                    />
                </div>
                
        </div>
    )
}

const SecondaryButton = ({ pageId, surveyLang, isHovered }) => {

    const dispatch = useDispatch();
    const btnData = useSelector( state => selectPageProperty( state, pageId, 'secondaryButtonData' ) );

    const handleChange = useCallback( ( key, text ) => {
        const newBtnData = {
            key: btnData.key,
            text: {...btnData.text}
        };

        if( text !== null ) {
            newBtnData.text[ surveyLang ] = text;
        }

        if( key !== null ) {
            newBtnData.key = key;
        }

        dispatch( pageContentChanged( {id: pageId, prop: 'secondaryButtonData', value: newBtnData } ) );
        
    }, [btnData, pageId, surveyLang, dispatch])

    return (
        <div className={ styles.secondaryButtonCont }>
            {/* <label className={ inputStyles.label }> Secondary button </label> */}
            <ScaleValueInput
                key={ 'secondary_button' }
                pageId={ pageId } 
                surveyLang={ surveyLang } 
                itemData={ btnData }
                inx={ -1 }
                isHovered={ isHovered }
                onChange={ handleChange }
            />
        </div>
    )
}