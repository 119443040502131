import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { SessionStorage } from 'utils/cacheManager';
import * as Api from 'api/api';

// fetch status: 'idle' | 'loading' | 'succeeded' | 'failed'
const initialState = {

    sectionsTemplatesFetchStatus: 'idle',
    notebookSections: null, // predefined section templates
    notebookTemplates: null, // predefined section templates collection

    remoteDataFetchStatus: 'idle',
    remoteDataUpdaetStatus: 'idle',
    metadataUpdaetStatus: 'idle',
    
    remoteData: {
        
    },
    editor: {
        isEditing: false,
        localChangesMade: false,
        localChangesTimestamp: 0,
        sectionsOrder: [], 
        sectionsData: {} // sections data edited by the user
    },
    display: {
        pageDirection: null
    }
}

////////////////////
// Async actions: //
////////////////////
export const fetchNotebook = createAsyncThunk(
    'notebook/fetchNotebook',
    async ( notebookId, thunkApi) => {

        const clientKey = getClientKey();

        const response = await Api.FetchNotebook( notebookId, clientKey );

        if( response.error ) {
            console.warn( "Error while fetching notebook content. Details: ", response.error );
            return thunkApi.rejectWithValue( response.error.statusText );
        } else {
            return response.data;
        }
    }
)

export const updateNotebook = createAsyncThunk(
    'notebook/updateNotebook',
    async (notebookId, thunkApi) => {

        const clientKey = getClientKey();
        const state = thunkApi.getState();
        const notebookJson = packNotebookJson( state );

        const response = await Api.UpdateNotebook( notebookId, notebookJson, clientKey );

        if( response.error ) {
            console.warn( "Error while updating notebook content. Details: ", response.error );
            return thunkApi.rejectWithValue( response.error.statusText );
        } else {
            return response.data;
        }
    }
)

export const updateNotebookMetadata = createAsyncThunk(
    'notebook/updateNotebookMetadata',
    async (args, thunkApi) => {

        const { notebookId, prop, value } = args;

        const response = await Api.UpdateNotebookMetadata( notebookId, prop, value );

        if( response.error ) {
            console.warn( "Error while updating notebook metadata. Details: ", response.error );
            return thunkApi.rejectWithValue( response.error.statusText );
        } else {
            return response.data;
        }
    }
)

export const fetchSectionsTemplates = createAsyncThunk(
    'notebook/fetchSectionsTemplates',
    async (args, thunkApi) => {

        const state = thunkApi.getState();
        const teamId = state.horizonData.currentTeamId;

        const response = await Api.FetchNotebookSectionsTemplates( teamId );

        if( response.error ) {
            console.warn( "Error while fetching notebook sections and templates. Details: ", response.error );
            return thunkApi.rejectWithValue( response.error.statusText );
        } else {
            return response.data;
        }
    }
)

////////////////////
////// Utils ///////
////////////////////

function getClientKey() {

    let clientKey = SessionStorage.Read( SessionStorage.keys.CLIENT_KEY );
    if( !clientKey ) {
        clientKey = 'client_' + uuidv4();
        SessionStorage.Save( SessionStorage.keys.CLIENT_KEY, clientKey );
    }

    return clientKey;
}

function handleLocalChanges( state ) {

    state.editor.localChangesMade = true;
    state.editor.localChangesTimestamp = Date.now();

}

function packNotebookJson( state ) {

    const { sectionsOrder, sectionsData } = state.notebook.editor;
    const packed = {};
    packed.sections = {};

    sectionsOrder.forEach( s => {
        packed.sections[ s ] = sectionsData[ s ];
    })

    packed.display = { ...state.notebook.display };

    return JSON.stringify( packed, null, 4 );
}

function parseRemoteNotebook( state, json ) {

    let obj = {}
    try {
        obj = JSON.parse( json );
    } catch (e) { console.error(e) }
    
    const sections = obj.sections;

    if( sections ) {

        const sectionsOrder = [];
        const sectionsData = {};
    
        Object.entries( sections ).forEach( ([id,data],i) => {
    
            sectionsOrder.push( id );
            sectionsData[ id ] = data;
            
        })
    
        state.editor.sectionsOrder = sectionsOrder;
        state.editor.sectionsData = sectionsData;

    }
    
    if( obj.display ) {

        state.display = { ...obj.display };
    }
}

function newSection( state, sectionId, targetIndex ) {

    const section = state.notebookSections.find( x => x.id === sectionId );
    const id = uuidv4();
    const newSection = {
        title: section.name,
        content: section.template
    }

    state.editor.sectionsOrder.splice( targetIndex, 0, id );
    state.editor.sectionsData[ id ] = newSection;
}


/////////////////////
////// Slice ////////
/////////////////////
const notebookSlice = createSlice({
    name: 'notebook',
    initialState,
    reducers: {

        resetNotebook: state => initialState,
        sectionAdded: (state,action) => {

            const { sectionId, targetIndex } = action.payload;

            newSection( state, sectionId, targetIndex );
            handleLocalChanges( state );

        },

        templateAdded: (state,action) => {

            const { templateId, targetIndex } = action.payload;
            const template = state.notebookTemplates.find( x => x.id === templateId  );

            if( template ) {
                template.sections.forEach( (s, i) => {

                    newSection( state, s.id, targetIndex + i );

                })
            }

            handleLocalChanges( state );

        },

        sectionsReordered: ( state, action ) => {

            const { startIndex, endIndex } = action.payload;
            
            const result = Array.from( state.editor.sectionsOrder );
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            state.editor.sectionsOrder = result;

            handleLocalChanges( state );
        },

        sectionDeleted: (state,action) => {

            const id = action.payload;

            const inx = state.editor.sectionsOrder.indexOf( id );
            state.editor.sectionsOrder.splice( inx, 1 );

            delete state.editor.sectionsData[ id ];
            handleLocalChanges( state );

        },

        editorFocused: (state, action) => {

            const isFocused = action.payload;

            state.editor.isEditing = isFocused;

        },

        sectionChanged: (state,action) => {

            const { sectionId, prop, value } = action.payload;

            state.editor.sectionsData[ sectionId ][ prop ] = value;
            handleLocalChanges( state );

        },

        displayChanged: (state,action) => {

            const { prop, value } = action.payload;

            state.display[ prop ] = value;

            if( state.remoteDataFetchStatus !== 'idle' ) {
                handleLocalChanges( state );
            }
        }
    },
    extraReducers: {
        
        // FETCH SECTIONS TEMPLATES //
        [fetchSectionsTemplates.pending]: (state, action) => {
            state.sectionsTemplatesFetchStatus = 'loading';
        },
        [fetchSectionsTemplates.fulfilled]: (state, action) => {
            state.sectionsTemplatesFetchStatus = 'succeeded';
            const data = action.payload;
            if( data ) {
                state.notebookSections = data.sections;
                state.notebookTemplates = data.templates;
            }
        },
        [fetchSectionsTemplates.rejected]: (state, action) => {
            state.sectionsTemplatesFetchStatus = 'failed';
        },

        // FETCH NOTEBOOK //
        [fetchNotebook.pending]: state => {
            state.remoteDataFetchStatus = 'loading';
        },
        [fetchNotebook.fulfilled]: (state, action) => {
            state.remoteDataFetchStatus = 'succeeded';
            state.editor.localChangesMade = false;
            if( state.editor.remoteDataUpdaetStatus !== 'loading' ) {

                // Set new notebook data only in we're not currently saving local changes.
                const data = action.payload;

                if( data ) {
                    
                    if( JSON.stringify( state.remoteData ) === JSON.stringify( data.notebook ) ) {
                        // Nothing to update...
                        return;
                    }

                    state.remoteData = data.notebook;

                    const isLatest = data.notebook.updatedAt > state.editor.localChangesTimestamp;

                    if( isLatest ) {
                        if( data.notebook.json ) parseRemoteNotebook( state, data.notebook.json );
                        state.editor.localChangesTimestamp = data.notebook.updatedAt;
                    }
                }

            }

        },
        [fetchNotebook.rejected]: state => {
            state.remoteDataFetchStatus = 'failed';
        },

        // UPDATE NOTEBOOK //
        [updateNotebook.pending]: state => {
            state.remoteDataUpdaetStatus = 'loading';
        },
        [updateNotebook.fulfilled]: (state, action) => {
            state.remoteDataUpdaetStatus = 'succeeded';
            state.editor.localChangesMade = false;

        },
        [updateNotebook.rejected]: state => {
            state.remoteDataUpdaetStatus = 'failed';
        },

        // UPDATE METADATA //
        [updateNotebookMetadata.pending]: state => {
            state.metadataUpdaetStatus = 'loading';
        },
        [updateNotebookMetadata.fulfilled]: (state, action) => {
            state.metadataUpdaetStatus = 'succeeded';
            console.log( "notebook metadata updated: ", action.payload );

        },
        [updateNotebookMetadata.rejected]: state => {
            state.metadataUpdaetStatus = 'failed';
        },
    }
})

export const {
    resetNotebook,
    sectionAdded,
    templateAdded,
    sectionsReordered,
    sectionDeleted,
    editorFocused,
    sectionChanged,
    displayChanged
} = notebookSlice.actions;

export default notebookSlice.reducer;