import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './MediaSettings.module.css';
import inputStyles from 'components/InputStyles.module.css';
import produce from 'immer';
import { surveyEditorTexts } from 'utils/appTexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { mediaSettings as initMediaSettings } from 'features/survey_editor/surveyEditorConfiguration';
import { pageContentChanged } from 'features/survey_editor/surveyEditorSlice';
import DropDown from 'components/DropDown';
import Checkbox from 'components/Checkbox';

const texts = surveyEditorTexts.properties.media.pageMediaSettings;

const PageMediaSettings = () => {
    const lang = useSelector(selectUiLanguage);
    const dispatch = useDispatch();

    const currentPage = useSelector(
        (state) => state.surveyEditor.present.display.currentPage
    );
    const pageData = useSelector(
        (state) =>
            state.surveyEditor.present.survey.content.pagesData[currentPage]
    );
    const pageMedia = useSelector((state) => {
        const media = state.surveyEditor.present.survey.media;
        if (pageData && media && media.files) {
            return media.files.find((f) => f.id === pageData.media);
        }
    });

    const [pageSettings, mediaSettings] = useMemo(() => {
        return [pageData?.settings, pageData?.settings?.media];
    }, [pageData]);

    useEffect(() => {
        if (pageSettings && !mediaSettings) {
            // Init page media settings:
            const newSettings = produce(pageSettings, (s) => {
                s.media = initMediaSettings;
            });

            dispatch(
                pageContentChanged({
                    id: currentPage,
                    prop: 'settings',
                    value: newSettings,
                })
            );
        }
    }, [currentPage, pageSettings, mediaSettings, dispatch]);

    const updateSettings = useCallback(
        (prop, value) => {
            if (pageSettings && mediaSettings) {
                const newSettings = produce(pageSettings, (s) => {
                    s.media[prop] = value;
                });

                dispatch(
                    pageContentChanged({
                        id: currentPage,
                        prop: 'settings',
                        value: newSettings,
                    })
                );
            }
        },
        [currentPage, pageSettings, mediaSettings, dispatch]
    );

    if (!pageMedia || !mediaSettings) return null;

    return (
        <>
            {/* WIDTH SETTINGS */}
            {pageMedia.type !== 'lottie' && (
                <>
                    <label
                        className={inputStyles.label}
                        style={{
                            marginTop: 'var(--space-medium)',
                            marginBottom: 0,
                            marginInlineEnd: 8,
                            lineHeight: 'normal',
                        }}
                    >
                        {texts.width[lang]}
                    </label>
                    <div className={styles.radioOptionsCont}>
                        <input
                            type="radio"
                            name="widthSetting"
                            id="content"
                            checked={mediaSettings.width === 'content'}
                            onChange={(e) =>
                                updateSettings('width', e.target.id)
                            }
                        />
                        <label
                            htmlFor="content"
                            style={{ marginInlineEnd: 12 }}
                        >
                            {texts.content[lang]}
                        </label>
                        <input
                            type="radio"
                            name="widthSetting"
                            id="full"
                            checked={mediaSettings.width === 'full'}
                            onChange={(e) =>
                                updateSettings('width', e.target.id)
                            }
                        />
                        <label htmlFor="full">{texts.full[lang]}</label>
                    </div>
                </>
            )}
            {/* HEIGHT SETTINGS */}
            <DropDown
                label={texts.heightLimit[lang]}
                value={mediaSettings.heightLimit}
                contStyle={{
                    marginBottom: 'var(--space-medium)',
                    marginTop:
                        pageMedia.type === 'lottie'
                            ? 'var(--space-medium)'
                            : 'unset',
                }}
                options={[
                    { key: 'auto', label: 'auto' },
                    { key: '10vh', label: '10%' },
                    { key: '20vh', label: '20%' },
                    { key: '30vh', label: '30%' },
                    { key: '40vh', label: '40%' },
                    { key: '50vh', label: '50%' },
                    { key: '60vh', label: '60%' },
                    { key: '70vh', label: '70%' },
                    { key: '80vh', label: '80%' },
                    { key: '90vh', label: '90%' },
                    { key: '100vh', label: '100%' },
                ]}
                onChange={(op) => updateSettings('heightLimit', op.key)}
            />

            {/* FRAME POSITIONING SETTINGS */}
            {pageMedia.type !== 'lottie' && (
                <>
                    <label
                        className={inputStyles.label}
                        style={{
                            marginBottom: 0,
                            marginInlineEnd: 8,
                            lineHeight: 'normal',
                        }}
                    >
                        {texts.framePositioning[lang]}
                    </label>
                    <div className={styles.radioOptionsCont}>
                        <input
                            type="radio"
                            name="framePositioningSetting"
                            id="top"
                            checked={mediaSettings.framePositioning === 'top'}
                            onChange={(e) =>
                                updateSettings('framePositioning', e.target.id)
                            }
                        />
                        <label htmlFor="top" style={{ marginInlineEnd: 12 }}>
                            {texts.top[lang]}
                        </label>
                        <input
                            type="radio"
                            name="framePositioningSetting"
                            id="center"
                            checked={
                                mediaSettings.framePositioning === 'center'
                            }
                            onChange={(e) =>
                                updateSettings('framePositioning', e.target.id)
                            }
                        />
                        <label htmlFor="center" style={{ marginInlineEnd: 12 }}>
                            {texts.center[lang]}
                        </label>
                        <input
                            type="radio"
                            name="framePositioningSetting"
                            id="bottom"
                            checked={
                                mediaSettings.framePositioning === 'bottom'
                            }
                            onChange={(e) =>
                                updateSettings('framePositioning', e.target.id)
                            }
                        />
                        <label htmlFor="bottom">{texts.bottom[lang]}</label>
                    </div>
                </>
            )}

            {/* ZOOM SETTINGS */}
            {pageMedia.type !== 'lottie' && (
                <Checkbox
                    label={texts.allowZoom[lang]}
                    isChecked={mediaSettings.allowZoom}
                    onChange={(c) => updateSettings('allowZoom', c)}
                />
            )}

            {/* ANIMATION SETTINGS */}
            {pageMedia.type === 'lottie' && (
                <div className={styles.radioOptionsCont}>
                    <label
                        className={inputStyles.label}
                        style={{
                            marginBottom: 0,
                            marginInlineEnd: 8,
                            lineHeight: 'normal',
                        }}
                    >
                        {texts.lottiePlayMode[lang] + ':'}
                    </label>
                    <input
                        type="radio"
                        name="lottiePlayMode"
                        id="once"
                        checked={mediaSettings.lottiePlayMode === 'once'}
                        onChange={(e) =>
                            updateSettings('lottiePlayMode', e.target.id)
                        }
                    />
                    <label htmlFor="once" style={{ marginInlineEnd: 12 }}>
                        {texts.once[lang]}
                    </label>
                    <input
                        type="radio"
                        name="lottiePlayMode"
                        id="loop"
                        checked={mediaSettings.lottiePlayMode === 'loop'}
                        onChange={(e) =>
                            updateSettings('lottiePlayMode', e.target.id)
                        }
                    />
                    <label htmlFor="loop">{texts.loop[lang]}</label>
                </div>
            )}
        </>
    );
};

export default PageMediaSettings;
