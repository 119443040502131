// Beyond this amount, a field won't select all values by default, even if 'selectAllByDefault' is enabled.
export const selectAllLimitation = 40;

/**
 * 
 * @param {object} fieldSettings // Method field settings
 * @param {object} keyMetadata // Data key meta from report
 */
export function validateDataKeyType( fieldSettings, keyMetadata ) {

    // Determin if the key data type is relevant according to the field 'validKeys' settings

    const validTypes = fieldSettings.validTypes;

    if( !validTypes || Object.keys( validTypes ).length < 1 ) {
        
        // In case there are no settings, all types are valid...
        return true;
    }

    const vt = validTypes[ keyMetadata.type ];

    if( vt ) {

        if( Array.isArray( vt ) ) {
    
            let isOriginTypeValid = false;

            vt.forEach( t => {
                if( keyMetadata.originType === t ) {
                    isOriginTypeValid = true;
                }
            })

            return isOriginTypeValid;
        
        }

        return true;
    }

    return false;

}

export function checkBulkScriptName( name ) {

    const strArr = name.split( '_' );

    if( strArr[ 0 ] === 'BULK' ) {
        return strArr[ 1 ];
    }

    return  null;
}

export function prepareDescriptiveBulkForSubmission( dataKeys, varsData, callback ) {

    const variables = varsData[ 'dv_BulkVarNames' ];
    const colors = [ "#6202EE","#FB8006","#FFC108","#795548","#5D9431","#1E88E5","#D81B60","#A1A0A0","#2A9871","#FFA285","#D885FF","#A6AD7D","#76D8D2","#A45F0F"];

    const dataCollection = variables.map( v => {

        const meta = dataKeys[ v ];
        const valueDescriptions = meta.valueDescriptions;

        const { misc_filterBy, misc_correctBy, caption, vis_showYvaluesAsPercent } = varsData;
        let vis_named_by_label_dv_colors,vis_named_by_label_dv_labels;
        
        if( valueDescriptions ) {
            
            vis_named_by_label_dv_colors = {};
            vis_named_by_label_dv_labels = {};

            Object.entries( valueDescriptions ).forEach( ([key,value], i) => {
                vis_named_by_label_dv_colors[ key ] = colors[ i ];
                vis_named_by_label_dv_labels[ key ] = value;
            })
        }
        
        return {
            dv_isPercentageVar: false,
            dv_VarNameChosenValues: {                
                key: v,
                values: valueDescriptions ? Object.keys( valueDescriptions ) : null
            },
            vis_graphTitle: meta.title,
            caption,
            vis_showYvaluesAsPercent,
            vis_named_by_label_dv_colors,
            vis_named_by_label_dv_labels,
            misc_filterBy,
            misc_correctBy
        };
        
    })
    
    callback( dataCollection )

}