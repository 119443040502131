import React, { useCallback, useMemo } from 'react';
import propStyles from './PageModulesStyles.module.css';
import styles from './OpenEndedPageModule.module.css';
import DropDown from 'components/DropDown';
import TextInput from 'components/TextInput';
import RichTextInput from 'components/rich_text/RichTextInput';
import { useSelector, useDispatch } from 'react-redux';
import { selectPageProperty, pageContentChanged, pageTextContentChanged } from 'features/survey_editor/surveyEditorSlice';
import { selectUiLanguage } from 'app/preferencesSlice';
import { textDirection } from 'utils/stylingTools';

import { surveyEditorTexts } from 'utils/appTexts';

const texts = surveyEditorTexts.content.pageModules.openEnded;

export const inputTypes = {
    TEXT: "text",
    MULTILINE: "multiline",
    EMAIL: "email"
}

export default function OpenEndedPageModule( {pageId, pageHovered} ) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );
    const inputType = useSelector( state => selectPageProperty( state, pageId, "inputType" ) );
    const minCharacters = useSelector( state => selectPageProperty( state, pageId, "minCharacters" ) );
    const placeholder = useSelector( state => selectPageProperty( state, pageId, "placeholder" ) );
    const subtitle = useSelector( state => selectPageProperty( state, pageId, "subtitle" ) );
    const pageSettings = useSelector( state => selectPageProperty( state, pageId, "settings" ) );

    const showSubtitle = pageSettings.general.showSubtitle;

    const inputTypeOptions = useMemo( () => {
        return Object.values( inputTypes ).map( type => ({ key: type, label: texts.inputTypes[ type ][ lang ] }) )
    }, [lang])

    const handleChange = useCallback( ( prop, value ) => {

        dispatch( pageContentChanged( {id: pageId, prop, value }) );

    }, [pageId, dispatch] )

    const handleTextChange = useCallback( ( prop, value ) => {

        dispatch( pageTextContentChanged( {id: pageId, prop, value, lang: surveyLang }) );

    }, [pageId, surveyLang, dispatch] )

    return (
        <>
            { showSubtitle &&            
                <RichTextInput
                    key={ 'subtitle_' + surveyLang }
                    className = { propStyles.subtitle }
                    value = { subtitle[ surveyLang ] || '' }
                    placeholder = { texts.subtitlePlaceholder[ lang ] }
                    textAlignment={ textDirection( lang ).align }
                    onChange = { value => handleTextChange( 'subtitle', value ) }
                />
            }
            <div className={ styles.inputCont }>
                <DropDown
                    label={ texts.inputTypeLabel[ lang ] }
                    options={ inputTypeOptions }
                    value={ inputType }
                    theme="Outlined"
                    colorSet="Grayscale"
                    contStyle={{width: 150}}
                    onChange={ op => handleChange( "inputType", op.key ) }
                />
                <div className={ styles.gap }/>
                <TextInput 
                    type="number"
                    label={ texts.minCharactersLabel[ lang ] }
                    value={ minCharacters }
                    theme="Outlined"    
                    contStyle={{maxWidth: 80}}
                    onChange={ e => handleChange( "minCharacters", e.target.value ) }
                />
                <div className={ styles.gap }/>
                <TextInput 
                    type="text"
                    placeholder={ texts.placeholderHint[ lang ]}
                    label={ texts.placeholderLabel[ lang ] }
                    value={ placeholder[ surveyLang ] || "" }
                    fieldStyle={{
                        direction: textDirection( surveyLang ).direction,
                        textAlign: textDirection( lang ).align,
                    }}
                    theme="Outlined"    
                    // contStyle={{maxWidth: 80}}
                    onChange={ e => handleTextChange( "placeholder", e.target.value ) }
                />
            </div>
        </>
    )
}