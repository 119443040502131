import React, { useEffect, useState } from 'react';
import styles from './SurveyEditorMain.module.css';

import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import { getNested } from 'utils/miscHelpers';

export default function EditingLock() {
    const lang = useSelector(selectUiLanguage);

    const fetchStatus = useSelector(
        (state) => state.surveyEditor.present.remoteSurveyFetchStatus
    );
    // const updateStatus = useSelector(state => state.surveyEditor.present.remoteSurveyUpdateStatus);
    const isLockedByAnother = useSelector((state) =>
        getNested(
            state,
            'surveyEditor',
            'present',
            'remoteSurveyData',
            'isLocked'
        )
    );

    const editorName = useSelector((state) =>
        getNested(
            state,
            'surveyEditor',
            'present',
            'remoteSurveyData',
            'editorName'
        )
    );
    const isConnectingToServer = useSelector((state) =>
        getNested(
            state,
            'surveyEditor',
            'present',
            'remoteSurveyData',
            'isConnecting'
        )
    );

    const [lockState, setLockState] = useState({ isLocked: false });
    const [isInitialFetch, setIsInitialFetch] = useState(true);

    useEffect(() => {
        if (fetchStatus === 'succeeded' && !isConnectingToServer) {
            setIsInitialFetch(false);
        }
    }, [fetchStatus, isConnectingToServer]);

    useEffect(() => {
        if (isLockedByAnother) {
            const isLocked = true;
            const message = `${surveyEditorTexts.anotherClientIsEditing[
                lang
            ].replace('{name}', editorName)} ☕️`;
            setLockState({ isLocked, message });
            return;
        }

        if (isConnectingToServer && !isInitialFetch) {
            const isLocked = true;
            const message = `${surveyEditorTexts.serverConnectionIssues[lang]} 🌩`;
            setLockState({ isLocked, message });
            return;
        }

        // 17.8.21 note: Socket api doesn't return error on fetch. we only know if it 'isConnecting'.
        //               this is why we can't use 'failed' to determin offline connection, hence the following code has been commented out:

        // if (updateStatus === 'failed' && fetchStatus === 'failed') {
        //     const isLocked = true;
        //     const message = `${surveyEditorTexts.serverConnectionIssues[lang]} 🌩`
        //     setLockState({ isLocked, message })
        // }

        // if (fetchStatus === 'succeeded' || updateStatus === 'succeeded') {
        //     setLockState({ isLocked: false, message: '' })
        // }

        if (!isLockedByAnother && !isConnectingToServer && !isInitialFetch) {
            setLockState({ isLocked: false, message: '' });
        }
    }, [
        isLockedByAnother,
        isConnectingToServer,
        isInitialFetch,
        editorName,
        lang,
    ]);

    return (
        <>
            {lockState.isLocked && (
                <div className={styles.lockContainer}>
                    <div className={styles.lockPanel}>{lockState.message}</div>
                </div>
            )}
        </>
    );
}
