import { useCallback, useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { diff_match_patch as DiffMatchPatch } from 'utils/diff_match_patch_uncompressed';
import { useDebounce } from 'use-debounce';

export const SocketStatus = {
    DISCONNECTED: 'disconnected',
    CONNECTED: 'connected',
};

// export type LastEdit = { lockedUntilTs: number; editorName: string; editorEmail: string; socketId: string };
// type UserData = {
// 	id: string;
// 	fullName: string;
// };

// type UseSocketsReturnType = {
//     connect: (resourceId: string) => void,
//     disconnect: () => void,
//     socketStatus: SocketStatus,
//     currentResourceId: string | null,
//     error: string,
//     loading: boolean | null,
//     isSynced: boolean,
// };

//  interface IuseSocketsProps {
//      socketServerUrl: string;
//      userData: UserData | null;
//      lastEdit: LastEdit | null;
//      setLastEdit: (lastEditData: LastEdit | null) => void;
//      isLockedByAnother: boolean;
//      setIsLockedByAnother: (isLocke: boolean) => void;
//      document: object | null;
//      initialDocumentData: object;
//      setDocument: (documentData: object) => void;
//      showLogs?: boolean;
//  }

const DMP = new DiffMatchPatch();

const useSockets = ({
    socketServerUrl,
    userData,
    lastEdit,
    setLastEdit,
    isLockedByAnother,
    setIsLockedByAnother,
    document,
    initialDocumentData,
    setDocument,
    onServerSendFullJson,
    showLogs = true,
}) => {
    const [debouncedDocument] = useDebounce(document, 200);

    const socket = useRef(null);
    const [socketStatus, setSocketStatus] = useState(SocketStatus.DISCONNECTED);
    const [currentResourceId, setCurrentResourceId] = useState(null); // string | null
    const [loading, setLoading] = useState(null); // boolean | null
    const [error, setError] = useState('');
    const [isSynced, setIsSynced] = useState(false);

    const lastJsonSent = useRef('');
    const lastJsonReceived = useRef('');
    const unlockTimeout = useRef(null);
    // This random key will be used to verify that our
    // last changes have been synced with the database.
    const lastFeedbackKey = useRef(null);

    function logMsg(
        text, // string
        color = '#A9A9A9', //string
        isError // ?: boolean
    ) {
        if (!showLogs) return;
        if (isError) console.error(`⌁ ${text}`);
        else console.log(`%c⌁ ${text}`, `color: ${color}`);
    }

    const connect = useCallback(
        (resourceId, sessionToken) => {
            logMsg('Connecting to socket', '#3CB371');
            setLoading(true);
            setError('');
            socket.current = io(socketServerUrl, {
                auth: {
                    resourceId,
                    sessionToken,
                },
            });
            setCurrentResourceId(resourceId);
            setSocketStatus('connected');
        },
        [socketServerUrl]
    );

    const disconnect = useCallback(() => {
        logMsg('Disconnecting socket', '#FF8C00');
        setLoading(false);
        setError('');
        socket.current?.disconnect();
        socket.current = null;
        setSocketStatus('disconnected');
    }, []);

    //////////////////
    // INBOUND DATA //
    //////////////////

    // Receiving the full document data upon connecting to the socket
    const handleServerSendFullJson = useCallback((data) => {
        setLastEdit(data.lastEdit);
        setIsSynced(true);

        lastJsonSent.current = data.jsonStr;
        lastJsonReceived.current = data.jsonStr;

        setLoading(false);

        try {
            if (data.jsonStr !== '{}') {
                const documentData = JSON.parse(data.jsonStr);
                onServerSendFullJson?.(documentData);
                setDocument(documentData);
            } else {
                //Here we create an initial document Json
                setDocument(initialDocumentData);
            }
        } catch (err) {
            logMsg(
                `Error while parsing document json @handleServerSendFullJson: ${err}`,
                '',
                true
            );
        }
    }, []);

    // Receiving changes made by other users
    const handleServerChangedJson = useCallback(({ patches, lastEdit }) => {
        if (!lastJsonReceived.current) return;
        setLastEdit(lastEdit);
        setError('');

        const [recievedJsonStr] = DMP.patch_apply(
            patches,
            lastJsonReceived.current
        );

        try {
            let recievedDocument = JSON.parse(recievedJsonStr);
            lastJsonReceived.current = recievedJsonStr;
            setDocument(recievedDocument);
            setIsSynced(true);
        } catch (err) {
            logMsg(
                `Error while parsing document json @handleServerChangedJson: ${err}`,
                '',
                true
            );
        }
    }, []);

    const handleServerSendError = useCallback(({ message, type }) => {
        setLoading(false);
        logMsg(`Socket error, details: ${message}`, '', true);
        setError(type);
    }, []);

    const handleSocketSync = ({ feedbackKey }) => {
        if (feedbackKey === lastFeedbackKey.current) {
            setIsSynced(true);
            setError('');
        }
    };

    // Init socket events
    useEffect(() => {
        if (socketStatus !== 'connected') {
            return;
        }

        socket.current?.on('server_send_full_json', handleServerSendFullJson);
        socket.current?.on('server_changed_json', handleServerChangedJson);
        socket.current?.on('server_send_error', handleServerSendError);
        socket.current?.on('connect_error', handleServerSendError);
        socket.current?.on('socket_synced', handleSocketSync);

        // socket.current?.on('disconnect', () => console.log('$$$ disconnected'));
        // socket.current?.on('connect', () => console.log('### connected'));

        return () => {
            socket.current?.off(
                'server_send_full_json',
                handleServerSendFullJson
            );
            socket.current?.off('server_changed_json', handleServerChangedJson);
            socket.current?.off('server_send_error', handleServerSendError);
            socket.current?.on('connect_error', handleServerSendError);
            socket.current?.off('socket_synced', handleSocketSync);
        };
    }, [socketStatus]);

    // Handle document lock due to another user's editing
    useEffect(() => {
        if (
            lastEdit &&
            lastEdit.lockedUntilTs > 0 &&
            lastEdit.socketId !== socket.current?.id
        ) {
            // Make sure the editor is not this user, in case the socket has been reconnected and got a new id
            if (
                userData?.fullName &&
                userData.fullName === lastEdit.editorName
            ) {
                setIsLockedByAnother(false);
                return;
            }

            setIsLockedByAnother(true);
            setIsSynced(false);
            logMsg(
                `Document is locked. Current editor: ${lastEdit.editorName}`,
                '#A52A2A'
            );

            if (unlockTimeout.current !== null)
                clearTimeout(unlockTimeout.current);
            const timeUntilUnlock = lastEdit.lockedUntilTs - Date.now();
            unlockTimeout.current = setTimeout(() => {
                setIsLockedByAnother(false);
                setLastEdit(null);
                setIsSynced(true);
                logMsg('Document unlocked for editing', '#6495ED');
            }, timeUntilUnlock);
        }
    }, [lastEdit, userData]);

    ///////////////////
    // OUTBOUND DATA //
    ///////////////////

    // Sending local changes to the server
    const handleUpdateServer = useCallback((documentData) => {
        const documentString = JSON.stringify(documentData);

        if (lastJsonReceived.current === documentString) {
            // Nothing to update...
            return;
        }

        const patches = DMP.patch_make(lastJsonSent.current, documentString);

        lastFeedbackKey.current = Math.round(Math.random() * 100000).toString();
        socket.current?.emit('client_changed_json', {
            jsonStrDiff: patches,
            feedbackKey: lastFeedbackKey.current,
        });

        setIsSynced(false);

        lastJsonSent.current = documentString;
        // Also update lastJsonReceived to the latest document state:
        lastJsonReceived.current = documentString;
    }, []);

    useEffect(() => {
        if (socketStatus !== 'connected') return;
        if (isLockedByAnother) return;

        // Cancle if trying to update before initial data arrvied:
        if (!lastJsonSent.current) return;

        handleUpdateServer(debouncedDocument);
    }, [socketStatus, isLockedByAnother, debouncedDocument]);

    ////////////////////

    return {
        connect,
        disconnect,
        socketStatus,
        currentResourceId,
        error,
        loading,
        isSynced,
    };
};

export default useSockets;
