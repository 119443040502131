import React, { useState, useRef, useEffect, useCallback } from 'react';
// import styles from './CompCatalogue.module.css';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import Button from 'components/Button';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import { AlertDialog } from 'components/poppers/Dialog';
import SelectionPopper from 'components/poppers/SelectionPopper';
import MultipleSelect from 'components/MultipleSelect';
import ColorPicker from 'components/ColorPicker';
import Chip from 'components/Chip';
import Switch from 'components/Switch';
import Snackbar, { types as SnackbarTypes } from 'components/poppers/Snackbar';
import MediaLibrary from 'components/media_library/MediaLibrary';
import RichTextInput from 'components/rich_text/RichTextInput';
import { useHistory } from 'react-router-dom';
import Dev_RichText from './Dev_RichText';
// import ActivityIndicator from 'components/ActivityIndicator';

export default function CompCatalogue(props) {
    const rowContStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '16px 0',
    };
    const lang = useSelector(selectUiLanguage);
    const spBtnRef = useRef(null);

    const [dialogActive, setDialogActive] = useState(false);
    const [selectionPopperActive, setSelectionPopperActive] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            history.push('/404');
        }
    }, [history]);

    const [isChecked, setIsChecked] = useState(false);
    return (
        <div
            style={{
                padding: 20,
                width: 'calc(100%-20px)',
                height: '100%',
                backgroundColor: '#FAFAFA',
            }}
        >
            <div style={rowContStyle}>
                <MediaLibraryExample />
            </div>
            <Switch
                label="Switch"
                isChecked={isChecked}
                onChange={setIsChecked}
                disabled={false}
            />

            <div
                style={{
                    position: 'relative',
                    width: 400,
                    height: 200,
                    backgroundColor: 'white',
                    margin: 30,
                }}
            >
                <Dev_RichText />
            </div>

            <AlertDialog
                isActive={dialogActive}
                style={{
                    // backgroundColor:'red',
                    width: 400,
                    // height: 150
                }}
                title="Alert Dialog Example"
                message={
                    <>
                        {' '}
                        Astronomers report the detection of phosphine, <br /> a
                        possible signature of organic life, in the atmosphere of
                        Venus
                    </>
                }
                actions={[
                    {
                        label: 'Action A',
                        callback: () => console.log('A choosen'),
                    },
                    {
                        label: 'Action B',
                        callback: () => console.log('B choosen'),
                    },
                    {
                        label: 'Action C',
                        callback: () => console.log('C choosen'),
                        theme: 'Full',
                    },
                ]}
                onClose={() => setDialogActive(false)}
            >
                {' '}
                Some additional content{' '}
            </AlertDialog>

            <div style={rowContStyle}>
                <Icon name="avatar" style={{ margin: 16 }} size={16} />
                <Icon
                    name="bold"
                    style={{ margin: 16 }}
                    size={24}
                    color="#FE55E3"
                />
                <Icon
                    name="help"
                    style={{ margin: 16 }}
                    size={32}
                    color="--color-primary-medium"
                />
                <Icon
                    name="link"
                    style={{ margin: 16 }}
                    size={48}
                    color="--color-secondary-medium"
                />
                <Icon
                    name="search"
                    style={{ margin: 16 }}
                    size={64}
                    color="--color-error"
                />
                <Icon
                    name="avatar"
                    style={{ margin: 16 }}
                    size={72}
                    color="--color-primary-dark"
                />
                <Icon
                    name="delete"
                    style={{ margin: 16 }}
                    size={72}
                    color="--color-primary-dark"
                />
            </div>

            <div id="buttons-cont" style={rowContStyle}>
                <Button
                    label="Hello"
                    tooltip="Say Hello"
                    theme="Full"
                    colorSet="Primary"
                    // disabled={ true }
                    onClick={() => console.log('Button clicked')}
                />
                <Button
                    label={lang === 'en' ? 'Icon Before' : 'אייקון לפני'}
                    tooltip="Outlined theme"
                    theme="Outlined"
                    colorSet="Primary"
                    iconBefore="avatar"
                    // disabled={ true }
                    onClick={() => console.log('Button clicked')}
                />
                <Button
                    label="Plain Theme"
                    tooltip="Icon after"
                    theme="Plain"
                    colorSet="Primary"
                    iconAfter="link"
                    // disabled={ true }
                    onClick={() => console.log('Button clicked')}
                />
                <Button
                    label="Beware"
                    tooltip="Small size"
                    theme="Outlined"
                    size="s"
                    colorSet="Error"
                    onClick={() => console.log('Button clicked')}
                />
                <Button
                    label="CustomColor"
                    tooltip="Large size"
                    theme="Full"
                    size="l"
                    colorSet="CustomColors"
                    onClick={() => console.log('Button clicked')}
                />
                <Button
                    label="CustomStyle"
                    theme="Full"
                    colorSet="Secondary"
                    bgStyle={{ borderRadius: 30, padding: '8px 14px 8px 12px' }}
                    labelStyle={{
                        fontSize: 12,
                        fontStyle: 'italic',
                        textTransform: 'uppercase',
                    }}
                    onClick={() => console.log('Button clicked')}
                />
                <Button
                    label="Disabled"
                    theme="Full"
                    colorSet="Primary"
                    disabled={true}
                    onClick={() => console.log('Button clicked')}
                />
                <IconButton
                    name="bold"
                    tooltip="Some functionality..."
                    theme="Full"
                    size="m"
                    colorSet="Grayscale"
                    onClick={() => console.log('Button clicked')}
                />
                <IconButton
                    name="search"
                    tooltip="Some functionality..."
                    theme="Outlined"
                    size="m"
                    colorSet="Grayscale"
                    onClick={() => console.log('Button clicked')}
                />
                <IconButton
                    name="help"
                    tooltip="Some functionality..."
                    theme="Plain"
                    size="m"
                    colorSet="Grayscale"
                    onClick={() => console.log('Button clicked')}
                />

                <IconButton
                    name="search"
                    tooltip="Some functionality..."
                    theme="Plain"
                    size="s"
                    colorSet="Primary"
                    onClick={() => console.log('Button clicked')}
                />
                <IconButton
                    name="search"
                    tooltip="Some functionality..."
                    theme="Plain"
                    size="m"
                    colorSet="CustomColors"
                    onClick={() => console.log('Button clicked')}
                />
                <IconButton
                    name="search"
                    tooltip="Some functionality..."
                    theme="Plain"
                    size="l"
                    colorSet="Error"
                    onClick={() => console.log('Button clicked')}
                />
            </div>

            <div id="poppers-cont" style={rowContStyle}>
                <Button
                    label="Open Dialog Alert"
                    theme="Full"
                    colorSet="Secondary"
                    onClick={() => setDialogActive(true)}
                />
                <div ref={spBtnRef} style={{ margin: 6 }}>
                    {' '}
                    {/*div wrapper for SelectionPopper reference*/}
                    <Button
                        label="Open Selection Popper"
                        theme="Full"
                        bgStyle={{ margin: 0 }}
                        onClick={() => setSelectionPopperActive(true)}
                    />
                </div>
            </div>

            <SelectionPopper
                referenceElement={spBtnRef.current}
                isActive={selectionPopperActive}
                options={[
                    'car',
                    'truck',
                    'bicycle',
                    'apple',
                    'house',
                    'kid',
                    'bomb',
                    'fish',
                    'goal',
                    'big',
                    'happy',
                    'sad',
                    'resturant',
                ]}
                onDismiss={() => setSelectionPopperActive(false)}
                onSelect={(op) => {
                    console.log(op + ' selected');
                    setSelectionPopperActive(false);
                }}
            />

            <MultipleSelect
                label="Multiple Select"
                contStyle={{ width: 200 }}
                placeholder="Options..."
                options={[
                    { key: 'a', label: 'Option a', isSelected: false },
                    { key: 'b', label: 'Option b', isSelected: true },
                    { key: 'c', label: 'Option c', isSelected: false },
                    { key: 'd', label: 'Option d', isSelected: true },
                    { key: 'e', label: 'Option e', isSelected: false },
                ]}
            />

            <div id="chips-cont" style={rowContStyle}>
                <Chip
                    label="chip"
                    iconName="x"
                    // onClick = { () => console.log( "chip click " ) }
                />
            </div>

            <div id="color-picker" style={rowContStyle}>
                <ColorPicker
                    label={'color'}
                    onChange={(col) => console.log('selected color: ', col)}
                />
                <ColorPicker
                    label={'custom palette color'}
                    value={'#b4923d'}
                    presets={[
                        '#da8680',
                        '#933800',
                        '#29a9cb',
                        '#84fc1c',
                        '#89c80c',
                        '#6f59fb',
                    ]}
                    onChange={(col) => console.log('selected color: ', col)}
                />
            </div>

            <div id="snackbar" style={rowContStyle}>
                <SnackbarExample />
            </div>
        </div>
    );
}

const SnackbarExample = () => {
    const [isActive, setIsActive] = useState(false);

    return (
        <>
            <Button
                label="Open a Snackbar"
                theme="Full"
                onClick={() => setIsActive(true)}
            />
            {isActive && (
                <Snackbar
                    message={"I'm a snackbar message"}
                    type={SnackbarTypes.WARNING}
                    timeout={5}
                    action={{
                        label: 'done',
                        callback: () => {
                            setIsActive(false);
                            console.log('done action on snackbar');
                        },
                    }}
                    onClose={() => {
                        setIsActive(false);
                        console.log('snackbar close');
                    }}
                />
            )}
        </>
    );
};

const MediaLibraryExample = () => {
    const [isActive, setIsActive] = useState(false);
    const [mediaFiles, setMediaFiles] = useState([]);
    const [mediaFilesArchive, setMediaFilesArchive] = useState([]);

    const handleMediaChange = useCallback(({ files, archive }) => {
        if (files) setMediaFiles(files);
        if (archive) setMediaFilesArchive(archive);
    }, []);

    return (
        <>
            <Button
                label="Open Media Library"
                theme="Full"
                onClick={() => setIsActive(true)}
            />
            {isActive && (
                <MediaLibrary
                    isActive={isActive}
                    onClose={() => setIsActive(false)}
                    storageId="61cc2dead884fa11371f2656" // media_dev_tests project
                    mediaFiles={mediaFiles}
                    mediaFilesArchive={mediaFilesArchive}
                    onChange={handleMediaChange}
                />
            )}
        </>
    );
};
