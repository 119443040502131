import React, { useCallback, useEffect, useMemo } from 'react';
import styles from  './Analyses.module.css';
import IconButton from 'components/IconButton';

import { useDispatch, useSelector } from 'react-redux';
import { analysisVariableChanged } from './analysesSlice';
import { selectUiLanguage } from 'app/preferencesSlice';

import NumberFieldModule from './field_modules/NumberFieldModule';
import StringFieldModule from './field_modules/StringFieldModule';
import SelectFieldModule from './field_modules/SelectFieldModule';
import ColorFieldModule from './field_modules/ColorFieldModule';
import KeyNameFieldModule from './field_modules/KeyNameFieldModule';
import KeyValuesFieldModule from './field_modules/KeyValuesFieldModule';
import KeyValuesAndDataFieldModule from './field_modules/KeyValuesAndDataFieldModule';
import DateFieldModule from './field_modules/DateFieldModule';
import CheckboxFieldModule from './field_modules/CheckboxFieldModule';
import KeyNameAndValuesFieldModule from './field_modules/KeyNameAndValuesFieldModule';
import CorrectionSettingsFieldModule from './field_modules/CorrectionSettingsFieldModule';
import FilteringSettingsFieldModule from './field_modules/FilteringSettingsFieldModule';

import { analysesTexts } from 'utils/appTexts';
const texts = analysesTexts.sidePanel;

const modules = {
    "NUMBER": NumberFieldModule ,
    "STRING": StringFieldModule ,
    "SELECT": SelectFieldModule ,
    "COLOR": ColorFieldModule ,
    "KEY_NAME": KeyNameFieldModule ,
    "KEY_VALUES": KeyValuesFieldModule ,
    "KEY_VALUES_AND_DATA": KeyValuesAndDataFieldModule,
    "DATE": DateFieldModule ,
    "CHECKBOX": CheckboxFieldModule,
    "KEY_NAME_AND_VALUES": KeyNameAndValuesFieldModule,
    "CORRECTION_SETTINGS": CorrectionSettingsFieldModule,
    "FILTERING_SETTINGS": FilteringSettingsFieldModule,
}

export default function NewAnalysisField ( {project, data} ) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const value = useSelector( state => state.analyses.newAnalysis.variables[ data.key ] );

    const Module = useMemo( () => {
        
        if( !modules[ data.type ] ) return ModuleError;
        
        return modules[ data.type ];

    }, [data]);

    useEffect( () => {

        if (value === undefined && data.defaultValue !== undefined) {

            dispatch( analysisVariableChanged( {key: data.key, value: data.defaultValue }) )
        }

    }, [value, data, dispatch])

    const handleChange = useCallback( ( value ) => {

        dispatch( analysisVariableChanged( {key: data.key, value }) )

    }, [ data.key, dispatch ])


    return (
        <div className={ styles.varFieldCont }>
            { 
                data.mandatory &&
                <div className={ styles.mandatoryIndicator }>
                    <IconButton
                        name='asterix_bullet'
                        theme='Plain'
                        size='xs'
                        tooltip={ texts.requiredField[ lang ]}
                        iconStyle={{width:10, height:10}}
                    />
                </div>
            }
            <Module 
                project={ project }
                data={ data } 
                value={ value } 
                handleChange={ handleChange } 
                texts={ texts }
                lang={ lang }
            />
            {
                data.tooltip &&
                <div className={ styles.tooltipBtn }>
                    <IconButton
                        name='help'
                        theme='Plain'
                        size='s'
                        tooltip={ data.tooltip[ lang ] }
                        iconStyle={{width:18, height:18}}
                        bgStyle={{ cursor: 'help' }}
                    />
                </div>
            }
        </div>
    )
}

const ModuleError = ({data}) => {
    return (
        <h4 style={{color: 'var(--color-error-medium)'}}>"{ data.type }" is not valid.</h4>
    )
}