import { useEffect, useState } from "react";

const timeLimit = 60 * 15; // seconds * minutes

/**
 * Inactivity Detector makes sure the browser tab doesn't put on hold for too long, and if so, reloads the window to prevent communication issues.
 */
export default function InactivityDetector() {
    const [lastVisit, setLastVisit] = useState();

    useEffect(() => {
        function handleBlur(e) {
            setLastVisit(Date.now());
        }

        function handleFocus(e) {
            if (lastVisit) {
                if (Date.now() - lastVisit > timeLimit * 1000) {
                    console.warn(
                        "There wasn't any interaction lately. Reloading window."
                    );
                    window.location.reload();
                }
            }
            setLastVisit(null);
        }

        window.addEventListener("blur", handleBlur);
        window.addEventListener("focus", handleFocus);

        return () => {
            window.removeEventListener("blur", handleBlur);
            window.removeEventListener("focus", handleFocus);
        };
    }, [lastVisit]);

    return null;
}
