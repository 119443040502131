import React, { useRef, useState } from 'react';
import styles from './PageStatsItem.module.css';
import IconButton from '../../components/IconButton';
import PageStatsRow from './PageStatsRow';
import Button from '../../components/Button';
import SelectionPopper from '../../components/poppers/SelectionPopper';

import { useSelector } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';
import { insightsTexts } from '../../utils/appTexts';

export default function PageStatsItem({stepIndex, fields, fieldsOptions, fieldsData, stepsCounts, onAddField, onRemoveField, onAddStep, onDelete }) 
{
    const [ itemHovered, setItemHovered ] = useState( false );
    const [ fieldsSelectionActive, setFieldsSelectionActive ] = useState( false );

    const keySelectionBtnRef = useRef();

    const lang = useSelector( selectUiLanguage );

    function addField(key) {

        onAddField( key );
        setFieldsSelectionActive( false );

    }

    function getDropOffRate() {

        const prevCount = stepIndex === 0 ? stepsCounts[0] : stepsCounts[ stepIndex - 1 ];
        const thisCount = stepsCounts[ stepIndex ]
        let rate = 100 * ( prevCount - thisCount ) / prevCount;
        rate = Math.round( rate * 100 ) / 100;

        return `${rate}%`;
    }

    function getDropOffRatio() {

        const prevCount = stepIndex === 0 ? stepsCounts[0] : stepsCounts[ stepIndex - 1 ];
        const thisCount = stepsCounts[ stepIndex ]

        return `${thisCount}/${prevCount}`;
    }

    function getRate() {

        const prevCount = stepIndex === 0 ? stepsCounts[0] : stepsCounts[ stepIndex - 1 ];
        const thisCount = stepsCounts[ stepIndex ]
        let rate = 100 * ( prevCount - thisCount ) / prevCount;
        rate = Math.round( rate * 100 ) / 100;
        return rate;
    }

    

    return (   
        <div className={ styles.pageMainContainer }
        onMouseEnter={ () => {setItemHovered( true )}}
        onMouseLeave={ () => {setItemHovered( false )}}
        >

            
                <IconButton
                    name = 'delete'
                    tooltip = { insightsTexts.deleteBtnTooltip[ lang ] }
                    theme = 'Plain'
                    colorSet = 'Grayscale'
                    size = 's'
                    onClick = { onDelete }
                    bgStyle={{ 
                        alignSelf: 'flex-start',  
                        margin: '8px 8px 0px 0px',
                        opacity: itemHovered ? 1 : 0,
                    }}
                />
            
            <div className={ styles.numberContainer }>
            <div className={ styles.number }>{stepIndex+1}</div>
            </div>
            <div className={ styles.pagestatsContainer }>
                { fields && fields.map( (field,i) => {
                    return (
                        <PageStatsRow 
                            key = { field + i }
                            fieldKey = { field }
                            data = { fieldsData[ field ] }
                            onRemove = { () => onRemoveField( i ) }
                        />
                    )
                    })
                }
                { fieldsOptions.length > 0 &&
                    <div style = {{alignSelf: 'flex-start'}} ref = { keySelectionBtnRef } >
                        {fields.length !== 0 ?
                            <Button 
                                label = { insightsTexts.addBtn[ lang ] }
                                theme = 'Plain'
                                size = 'm'
                                colorSet = 'Grayscale'
                                iconBefore = 'plus'
                                onClick = { () => setFieldsSelectionActive( true ) }
                                bgStyle={{ 
                                    alignSelf: 'flex-start', 
                                    borderRadius: 50,
                                    margin: '6px 24px 0px 24px',
                                    // padding: '6px 12px 6px 12px'
                            }}
                            /> :
                            <Button 
                            label = { insightsTexts.newKeyBtn[ lang ] }
                            theme = 'Full'
                            size = 'm'
                            colorSet = 'Primary'
                            iconBefore = 'plus'
                            onClick = { () => setFieldsSelectionActive( true ) }
                            bgStyle={{ 
                                alignSelf: 'flex-start', 
                                width: 'none',
                                borderRadius: 50,
                                margin: '6px 24px 0px 24px',
                                // padding: '6px 12px 6px 12px',
                                minWidth: 170
                            }}
                            /> 
                        }
                    </div>   
                }
            </div>
            <div className={ styles.dropOffContainer }>
                { fields.length !== 0 &&
                    <div    
                        className={ styles.dropOff } 
                        style={ getRate() > 15 ? 
                            { backgroundColor: 'var( --color-error-light )', color: 'var( --color-error-medium )' } 
                            : 
                            { backgroundColor: 'none' }
                        }
                        >
                            <div id={ styles.dropOffRateCont }>
                                {getDropOffRate()}
                            </div>
                            <div id={ styles.dropOffRatioCont }>
                                {getDropOffRatio()}
                            </div>
                        </div>
                }
                <IconButton 
                    name = 'plus'
                    tooltip = 'Add new step'
                    theme = 'Outlined'
                    size = 'm'
                    colorSet = 'Grayscale'
                    onClick = { onAddStep }
                    bgStyle={{
                        alignSelf: 'flex-start',  
                        backgroundColor: 'var(--color-background-light)',
                        opacity: itemHovered ? 1 : 0,
                        position: 'absolute',
                        bottom: -52,
                        marginLeft: 60
                    }}
                />
            </div>
            <SelectionPopper 
                referenceElement={ keySelectionBtnRef.current }
                isActive={ fieldsSelectionActive }
                onDismiss={ () => setFieldsSelectionActive( false ) }
                onSelect={ (key) => addField( key ) }
                options={ fieldsOptions }
                placement={ lang === 'en' ? "right" : "left" }
            />
        </div>
    );
}

