import React, { useMemo } from 'react';
import styles from './InputStyles.module.css';
import Icon from './Icon';
import IconButton from 'components/IconButton';
import ActivityIndicator from './ActivityIndicator';

export const TextTypes = {
    TEXT: 'text',
    EMAIL: 'email',
    PASSWORD: 'password',
    NUMBER: 'number',
};

/**
 *
 * @param {{
 * type: enum, // Use a value from TextTypes
 * label: string, // Label to display by the text field
 * name: string, // The data name (usually a key)
 * value: string, // Current value
 * placeholder: string, // Field placeholder
 * theme: string, // Style theme ( FullDark | FullLight | Outlined | BorderBottom )
 * tabIndex: number,
 * numberSettings: {min, max, step},
 * onChange: function, // Use event.target.value to get the text value
 * onReturn: function, // Callback for handling keybaord 'Enter' press
 * * onPressArrowUp: function, // Callback for handling keybaord 'Up' press
 * * onPressArrowDown: function, // Callback for handling keybaord 'Down' press
 * * onFocus: function, // Callback for handling focus event
 * onBlur: function, // Callback for handling blur event
 * dir: string, // Text align direction
 * className: css, // css class of the container
 * contStyle: object, // Inline styling for the component container
 * fieldStyle: object, // Inline styling for the input field
 * labelStyle: object // Inline styling for the label
 * iconBefore: string // Name of the icon
 * iconStyle: object // Inline styling for the icon
 * warning: string // Show warning icon + tooltip
 * isLoading: boolean // Show activity indicator
 * isValid: boolean // Show checkmark
 * disabled: boolean // Is disabled
 * }}
 */
export default function TextInput({
    type = TextTypes.TEXT,
    theme = 'Outlined',
    label,
    name,
    value,
    dir = 'auto',
    className,
    contStyle,
    fieldStyle,
    labelStyle,
    tabIndex,
    numberSettings,
    onChange,
    onReturn,
    onPressArrowUp,
    onPressArrowDown,
    onFocus,
    onBlur,
    placeholder = '',
    iconBefore,
    iconStyle,
    warning,
    isLoading,
    isValid,
    disabled = false,
}) {
    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            if (onReturn) onReturn();
            event.target.blur();
        }
        if (event.key === 'Escape') {
            event.target.blur();
        }
        if (event.key === 'Up' || event.key === 'ArrowUp') {
            if (onPressArrowUp) onPressArrowUp();
        }
        if (event.key === 'Down' || event.key === 'ArrowDown') {
            if (onPressArrowDown) onPressArrowDown();
        }
    }

    const fieldsStyles = useMemo(
        () => ({
            FullDark: {
                backgroundColor: 'var( --color-background-hover )',
                border: 'none',

                ...fieldStyle,
            },
            FullLight: {
                backgroundColor: 'white',
                border: 'none',

                ...fieldStyle,
            },
            Outlined: {
                backgroundColor: 'white',
                border: 'var( --border-main )',
                padding: '6px 12px',

                ...fieldStyle,
            },
            BorderBottom: {
                backgroundColor: 'transparent',
                border: 'none',
                borderRadius: 0,
                borderBottom: 'var( --border-main )',

                ...fieldStyle,
            },
        }),
        [fieldStyle]
    );

    const fieldsThemeStyle = {
        ...fieldsStyles[theme],
        color: warning ? 'var( --color-error-medium )' : 'unset',
    };

    return (
        <div className={`${styles.mainCont} ${className}`} style={contStyle}>
            {label && (
                <label
                    className={styles.label}
                    htmlFor={name}
                    style={labelStyle}
                >
                    {label}
                </label>
            )}
            <div className={styles.fieldCont} dir={dir}>
                <input
                    id={name}
                    type={type}
                    className={`${styles.input} ${
                        iconBefore ? styles.withIconBefore : ''
                    }`}
                    dir={dir}
                    value={value}
                    placeholder={placeholder}
                    style={fieldsThemeStyle}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    tabIndex={tabIndex}
                    disabled={disabled}
                    min={numberSettings ? numberSettings.min : null}
                    max={numberSettings ? numberSettings.max : null}
                    step={numberSettings ? numberSettings.step : null}
                    autoComplete="off"
                ></input>
                <div className={styles.iconsCont}>
                    {iconBefore && (
                        <>
                            <Icon name={iconBefore} style={iconStyle} />
                        </>
                    )}
                </div>
                <Warning warning={warning} />
                <Loading isLoading={isLoading} />
                <IsValid isValid={isValid} />
            </div>
        </div>
    );
}

const Warning = ({ warning }) => {
    if (warning) {
        return (
            <div className={styles.indicationCont}>
                <IconButton
                    name="warning"
                    theme="Plain"
                    colorSet="Grayscale"
                    size="s"
                    tooltip={warning}
                />
            </div>
        );
    } else {
        return null;
    }
};

const Loading = ({ isLoading }) => {
    if (isLoading) {
        return (
            <div className={styles.indicationCont}>
                <ActivityIndicator
                    size={16}
                    color={'var(--color-type-low-emphasis)'}
                />
            </div>
        );
    } else {
        return null;
    }
};

const IsValid = ({ isValid }) => {
    if (isValid) {
        return (
            <div className={styles.indicationCont}>
                <Icon name="vi" size={16} />
            </div>
        );
    } else {
        return null;
    }
};
