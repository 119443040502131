import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DropDown from 'components/DropDown';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import { surveySettingsChanged } from 'features/survey_editor/surveyEditorSlice';
import IconButton from 'components/IconButton';
import { AlertDialog } from 'components/poppers/Dialog';

const texts = surveyEditorTexts.properties.surveySettings.platformLimition;

const PlatformLimitation = () => {
    const lang = useSelector(selectUiLanguage);
    const dispatch = useDispatch();
    const platformLimitation = useSelector(
        (state) =>
            state.surveyEditor.present.survey.settings.general
                .platformLimitation
    );

    const platformOptions = useMemo(
        () => [
            { key: 'none', label: texts.options.none[lang] },
            { key: 'desktop_only', label: texts.options.desktop_only[lang] },
            { key: 'mobile_only', label: texts.options.mobile_only[lang] },
            { key: 'force_mobile', label: texts.options.force_mobile[lang] },
        ],
        [lang]
    );

    const [isHelpActive, setIsHelpActive] = useState(false);

    const handlePlatformLimitationChange = useCallback(
        (op) => {
            dispatch(
                surveySettingsChanged({
                    prop: 'platformLimitation',
                    value: op.key,
                })
            );
        },
        [dispatch]
    );

    useEffect(() => {
        if (!platformLimitation)
            handlePlatformLimitationChange({ key: 'none' });
    }, [platformLimitation, handlePlatformLimitationChange]);

    return (
        <>
            <IconButton
                name="help"
                theme="Plain"
                size="s"
                bgStyle={{
                    marginInlineStart: 'calc( 100% - 24px)',
                    marginBottom: -4,
                }}
                tooltip={texts.help.helpTooltip[lang]}
                onClick={() => setIsHelpActive(true)}
            />
            <DropDown
                label={texts.title[lang]}
                options={platformOptions}
                value={platformLimitation}
                onChange={handlePlatformLimitationChange}
            />
            <AlertDialog
                isActive={isHelpActive}
                title={texts.help.title[lang]}
                actions={[
                    {
                        label: texts.help.gotItAction[lang],
                        theme: 'Plain',
                    },
                ]}
                onClose={() => setIsHelpActive(false)}
            >
                <div style={{ maxWidth: 400 }}>
                    <p>{texts.help.preface[lang]}</p>
                    <br />
                    <p>
                        <strong>{texts.options.none[lang]}</strong>
                        {` - `}
                        <span>{texts.help.noneExplanation[lang]}</span>
                    </p>
                    <br />
                    <p>
                        <strong>{texts.options.desktop_only[lang]}</strong>
                        {` - `}
                        <span>{texts.help.desktopOnlyExplanation[lang]}</span>
                    </p>
                    <br />
                    <p>
                        <strong>{texts.options.mobile_only[lang]}</strong>
                        {` - `}
                        <span>{texts.help.mobileOnlyExplanation[lang]}</span>
                    </p>
                    <br />
                    <p>
                        <strong>{texts.options.force_mobile[lang]}</strong>
                        {` - `}
                        <span>{texts.help.forceMobileExplanation[lang]}</span>
                    </p>
                    <br />
                </div>
            </AlertDialog>
        </>
    );
};

export default PlatformLimitation;
