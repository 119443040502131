import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import styles from "./AnalysisCardTagsSection.module.css";
import Modal from "components/poppers/Modal";
import PopperPanel from 'components/poppers/PopperPanel';
import Button from 'components/Button';
import Chip from 'components/Chip';
import { Editable } from 'components/Editable';
import ActivityIndicator from 'components/ActivityIndicator';

import AnalysisNoTitleAlert from './AnalysisNoTitleAlert';
import { useSelector } from 'react-redux';
import { analysesTexts } from 'utils/appTexts';

const texts = analysesTexts.tagsSection;
const NEW_TAG_ID = '#@!NEW_TAG_ID!@#'

export default function AnalysisCardTagsSection({ analysisData, lang, isHovered, onRemoveTag, onConnectTag, onCreateTag }) {
    const addTagBtnRef = useRef();
    const currentTeam = useSelector(state => state.horizonData.currentTeam);
    const tags = useMemo( () => analysisData.tags ? analysisData.tags : [] , [analysisData]);
    const [searchAreaActive, setSearchAreaActive] = useState(false);
    const [noTitleAlertActive, setNotTitleAlertActive ] = useState( false );
    const [currentlySavingTagNames, setCurrentlySavingTagNames] = useState([]);
    const [currentlyDeletingTagIds, setCurrentlyDeletingTagIds] = useState([]);



    const handleAddTag = useCallback( () => {
        if( analysisData.name ) {
            setSearchAreaActive( true )
        } else {
            setNotTitleAlertActive( true )
        }
    }, [analysisData])

    const handleRemoveTag = useCallback( (tagId) => {
        var newCurrentlyDeletingTagIds = currentlyDeletingTagIds
        newCurrentlyDeletingTagIds.push(tagId)
        setCurrentlyDeletingTagIds(newCurrentlyDeletingTagIds)
        onRemoveTag(tagId)
    }, [currentlyDeletingTagIds, onRemoveTag])

    const addTagToCurrentlySavingArray = useCallback( (obj) => {
        var newCurrentlySavingTagNames = currentlySavingTagNames
        if (obj.name) {
            newCurrentlySavingTagNames.push(obj.name)
        } else if (obj.id) {
            const tag = currentTeam.tags.find(t=>t.id === obj.id)
            if (tag) { newCurrentlySavingTagNames.push(tag.name) }
            if (currentlyDeletingTagIds.indexOf(obj.id) > -1) {
                setCurrentlyDeletingTagIds(currentlyDeletingTagIds.splice(currentlyDeletingTagIds.indexOf(obj.id) + 1))
            }
        }
        setCurrentlySavingTagNames(newCurrentlySavingTagNames)
    }, [currentlySavingTagNames, currentTeam, currentlyDeletingTagIds])
    
    
    return (
        <div ref={addTagBtnRef} id="chips-cont" className={ styles.rowContStyle }>
            { tags.map((tag, i) => {
                if (currentlyDeletingTagIds.indexOf(tag.id) > -1) {
                    return null
                }
                return (
                    <Tag
                        key={tag.id + "_" + i}
                        tagData={ tag }
                        onRemoveTag={handleRemoveTag}
                    />
                )
            })}
            { currentlySavingTagNames !== [] && currentlySavingTagNames.map(s => {
                if (tags.find(t=>t.name === s)) {
                    return null
                }
                return <Tag 
                    key={s}
                    tagData={{name: s, id: s}}
                    hideX={true}
                />
            })}


            { searchAreaActive ? 
                <TagSearchArea 
                    lang={ lang } 
                    currentTeam={ currentTeam } 
                    onConnectTag={ onConnectTag } 
                    onCreateTag={ onCreateTag } 
                    onDone={ () => setSearchAreaActive( false ) }
                    addTagToCurrentlySavingArray={ addTagToCurrentlySavingArray}
                    setSearchAreaActive={ setSearchAreaActive}
                />
                :
                <AddTagButton
                    lang={ lang }
                    isHovered={ isHovered }
                    onClick={handleAddTag} 
                />
            }
            <AnalysisNoTitleAlert
                isActive={ noTitleAlertActive }
                item={ 'tag' }
                onClose={ () => setNotTitleAlertActive( false) }
            />
        </div>
    )
}

const Tag = ({ tagData, onClick, onRemoveTag, hideX }) => {
    return (
        <div className={ styles.tagContainer }>
            <Chip
                bgStyle={{ height: 14 }}
                label={`${tagData.name}`}
                iconName={ hideX ? '' : 'x' }
                onClick={onClick}
                onDelete={() => {onRemoveTag(tagData.id)}}
                onDoubleClick={()=>{ window.open(`/tags/${tagData.id}`, "_blank").focus(); }}
                buttonActive={true}
            />
        </div>
    )
}

const AddTagButton = ({ lang, onClick, isHovered }) => {
    return (
        <Button
            label={texts.addTag[lang]}
            onClick={onClick}
            theme='Plain'
            colorSet='Grayscale'
            bgStyle={{
                height: 26,
                borderRadius: 13,
                padding: '4px 8px',
                opacity: isHovered ? 1 : 0.2
            }}
            iconBefore={'plus'}
            buttonActive={false}
        />
    )
}

const TagSearchArea = ({ lang, currentTeam, onConnectTag, onCreateTag, onDone, addTagToCurrentlySavingArray, setSearchAreaActive }) => {
    const inputRef = useRef();
    const [showPopper, setShowPopper] = useState(false);
    const [queryString, setQueryString] = useState("");
    const [filteredTags, setFilteredTags] = useState([]);
    const [filteredTagIds, setFilteredTagIds] = useState([]);
    const [highlightedTagId, setHighlightedTagId] = useState("");
    const [selectedTagId, setSelectedTagId] = useState("");

    useEffect(() => {
        if (filteredTags.length > 0 && (highlightedTagId === ''  || highlightedTagId === NEW_TAG_ID || filteredTagIds.indexOf(highlightedTagId) === -1)) {
            setHighlightedTagId(filteredTags[0].id)
        }
    }, [highlightedTagId, filteredTags, filteredTagIds])

    useEffect(() => {
        if (queryString === '') {
            setShowPopper(false)
            setFilteredTags([])
            setHighlightedTagId('')
        } else if (queryString.length > 0 && currentTeam) {
            var resTags = currentTeam.tags.filter(t => t.name.indexOf(queryString) >= 0)
            if (!resTags.find(t => t.name === queryString)) {
                resTags.push({ name: NEW_TAG_ID, id: NEW_TAG_ID })
            }
            setFilteredTags(resTags)
            setFilteredTagIds(resTags.map(p => p.id))
            setShowPopper(true);
        } else if (!currentTeam) {
            setShowPopper(true);
        }
    }, [queryString, currentTeam]);

    useEffect(() => {
        if (queryString !== "" && selectedTagId !== '' && selectedTagId !== null) {
            if (selectedTagId === NEW_TAG_ID) {
                onCreateTag(queryString, currentTeam.id)
                addTagToCurrentlySavingArray({name: queryString})
            } else {
                onConnectTag(selectedTagId)
                addTagToCurrentlySavingArray({id: selectedTagId})
            }
            setShowPopper(false);
            setQueryString("")
            setTimeout(() => { 
                onDone();
            }, 310)
            setTimeout(()=>{
                setSearchAreaActive(true)
            }, 500)
        }
    }, [selectedTagId, queryString, currentTeam, onDone, onCreateTag, onConnectTag, addTagToCurrentlySavingArray, setSearchAreaActive]);

    const handleInputChange = useCallback((op) => {
        setQueryString(op ? op : "")
    }, []);

    const handleInputBlur = useCallback(() => {
        setShowPopper(false)
        if (queryString === '') {
            setTimeout(() => { 
                onDone();
            }, 310)
        }
    }, [queryString, onDone])

    const handleInputArrowUp = useCallback(() => {
        const index = filteredTagIds.indexOf(highlightedTagId)
        if (index > 0) { setHighlightedTagId(filteredTagIds[index - 1]) }
    }, [highlightedTagId, filteredTagIds])

    const handleInputArrowDown = useCallback(() => {
        const index = filteredTagIds.indexOf(highlightedTagId)
        if (index < filteredTagIds.length - 1) { setHighlightedTagId(filteredTagIds[index + 1]) }
    }, [highlightedTagId, filteredTagIds])

    const handleTagHightlighted = useCallback((tag) => {
        if (tag.id) { setHighlightedTagId(tag.id) }
    }, [])

    const handleTagSelected = useCallback((tag) => {
        if (tag.id) { setSelectedTagId(tag.id) }
    }, [])

    const handleInputEnter = useCallback(() => {
        if (highlightedTagId !== '') { setSelectedTagId(highlightedTagId) }
    }, [highlightedTagId])

    return (
        <>
            <div ref={inputRef} className={styles.inputCont}>
                <Editable
                    autoFocus={true}
                    placeholder={texts.writeTag[lang]}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onPressArrowUp={handleInputArrowUp}
                    onPressArrowDown={handleInputArrowDown}
                    onReturn={handleInputEnter}
                />
            </div>
            <Modal
                isActive={showPopper}
                bgBlur={"low"}
                blockBgClicks={false}
                onClose={ () => setShowPopper( false )}
            >
                <PopperPanel referenceElement={inputRef.current} >
                    {
                        filteredTags.length > 0 ?
                            <div className={styles.listContainer}>
                                <ul className={styles.list}>
                                    {
                                        filteredTags.map((tag, i) => {
                                            return (
                                                <TagSearchItem
                                                    key={tag.id}
                                                    tag={tag}
                                                    queryString={queryString}
                                                    lang={lang}
                                                    isHighlight={tag.id === highlightedTagId}
                                                    onTagSelected={handleTagSelected}
                                                    onTagHighlight={handleTagHightlighted}
                                                />
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            :
                            <div className={styles.inputMessage}> <ActivityIndicator size={ 18 }/> </div>
                    }
                </PopperPanel>
            </Modal>
        </>
    )
}

function TagSearchItem({ tag, lang, queryString, onTagSelected, onTagHighlight, isHighlight }) {
    const itemRef = useRef();

    useEffect(() => {
        if (isHighlight) itemRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }, [isHighlight, itemRef])

    return (
        <li
            className={isHighlight ? styles.listItemHighlight : styles.listItem}
            onClick={() => { onTagSelected(tag) }}
            onMouseEnter={() => { onTagHighlight(tag) }}
            ref={itemRef}
        >
            {tag.id !== NEW_TAG_ID ?
                <span className={styles.nameSpan}>
                    {tag.name.split(queryString).map((part, i) => {
                        return (
                            <span key={"a_" + tag.id + "_" + i}>
                                {i !== 0 && <strong>{queryString}</strong>}
                                {part}
                            </span>)
                    })
                    } </span>
                :
                <span>
                    {texts.createTag[lang].split("{query}").join(queryString)}
                </span>
            }
        </li>
    )
}
