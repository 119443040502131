import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './RichTextStyles.module.css';

import { EditorState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';

import PopperPanel from 'components/poppers/PopperPanel';
import TextInput from 'components/TextInput';
import Modal from 'components/poppers/Modal';
import Button from 'components/Button';
import DropDown from 'components/DropDown';

import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { componentsTexts } from 'utils/appTexts';
import IconButton from 'components/IconButton';

const texts = componentsTexts.richText.linkEntityEditor;

export default function LinkEntityEditor({
    isActive,
    referenceElement,
    entity,
    editorState,
    onModify,
    onClose,
}) {
    const lang = useSelector(selectUiLanguage);

    const [urlValue, setUrlValue] = useState('');
    const [targetWindow, setTargetWindow] = useState('_blank');
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (entity && entity.data) {
            const { url, targetWindow, title } = entity.data;
            setUrlValue(url);
            setTargetWindow(targetWindow);
            setTitle(title);
        }
    }, [entity]);

    const targetWindowsOptions = useMemo(() => {
        return [
            { key: '_blank', label: texts.targetWindowOptions.blank[lang] },
            { key: '_self', label: texts.targetWindowOptions.self[lang] },
            { key: '_webview', label: texts.targetWindowOptions.webview[lang] },
        ];
    }, [lang]);

    const handleClose = useCallback(() => {
        setUrlValue('');
        setTargetWindow('_blank');
        setTitle('');
        if (onClose) onClose();
    }, [onClose]);

    const handleDone = useCallback(() => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            {
                url: urlValue,
                targetWindow,
                title,
            }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
        });

        onModify(
            RichUtils.toggleLink(
                newEditorState,
                newEditorState.getSelection(),
                entityKey
            )
        );

        handleClose();
    }, [editorState, urlValue, targetWindow, title, onModify, handleClose]);

    const handleRemove = useCallback(() => {
        const selection = editorState.getSelection();

        if (!selection.isCollapsed()) {
            onModify(RichUtils.toggleLink(editorState, selection, null));
        }

        handleClose();
    }, [editorState, onModify, handleClose]);

    const handleOpenLink = useCallback(() => {
        window.open(urlValue);
    }, [urlValue]);

    return (
        <Modal isActive={isActive} onClose={handleClose} bgBlur={'low'}>
            <PopperPanel
                referenceElement={referenceElement}
                panelStyle={{
                    width: 400,
                    padding: 16,
                    backgroundColor: 'var(--color-background-mild)',
                }}
            >
                <TextInput
                    label={texts.linkLable[lang]}
                    value={urlValue || ''}
                    onChange={(e) => setUrlValue(e.target.value)}
                    onReturn={handleDone}
                />
                <DropDown
                    label={texts.targetWindowLabel[lang]}
                    options={targetWindowsOptions}
                    contStyle={{ width: '100%', marginTop: 8 }}
                    value={targetWindow}
                    onChange={(selected) => setTargetWindow(selected.key)}
                />
                {targetWindow === '_webview' && (
                    <TextInput
                        label={texts.webviewTitle[lang]}
                        contStyle={{ width: '100%', marginTop: 8 }}
                        value={title || ''}
                        onChange={(e) => setTitle(e.target.value)}
                        onReturn={handleDone}
                    />
                )}

                <div className={styles.entityEditorFooter}>
                    <IconButton
                        name="external_link"
                        theme="Plain"
                        tooltip={texts.openUrlTooltip[lang]}
                        onClick={handleOpenLink}
                    />
                    <div>
                        <Button
                            label={texts.removeBtn[lang]}
                            onClick={handleRemove}
                            bgStyle={{ display: 'inline-block' }}
                            theme="Outlined"
                        />
                        <Button
                            label={texts.applyBtn[lang]}
                            bgStyle={{
                                display: 'inline-block',
                                marginInlineStart: 6,
                            }}
                            onClick={handleDone}
                        />
                    </div>
                </div>
            </PopperPanel>
        </Modal>
    );
}
