import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MultipleSelect from 'components/MultipleSelect';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReport } from 'app/reportSlice';
import { selectAllLimitation } from '../analysesUtils';


export default function KeyValuesFieldModule ({project, data, value, handleChange, texts, lang}) {

    const dispatch = useDispatch();
    const allVariables = useSelector( state => state.analyses.newAnalysis.variables );

    const selectedKey = useMemo( () => allVariables[data.ref], [allVariables,data]);

    const dataKeys = useSelector(state => {

        const dataKeys = state.report.dataKeys;

        if (!dataKeys) {
            if (state.report.fetchStatus === 'idle') {

                dispatch(fetchReport(project.sourceName));
            }
            return null
        }

        return dataKeys

    })

    const isCategorial = useMemo ( () => {

        if( !dataKeys || !selectedKey ) return null;

        const meta = dataKeys[ selectedKey ];
        return (             
            (meta.type === 'STRING' || 
            meta.originType === 'SCALE_BUNDLE' ||
            meta.originType === 'MULTIPLE_CHOICE_BUNDLE') &&
            meta.valueDescriptions 
        )

    }, [ dataKeys, selectedKey ])

    const valuesOptions = useMemo ( () => {

        if( dataKeys && selectedKey ) {

            if( !isCategorial ) return [];

            const meta = dataKeys[ selectedKey ];
            const isScale = meta.originType === 'SCALE_ITEM' || meta.originType === 'SCALE_BUNDLE';
            const values = meta.valueDescriptions;
            const options = Object.keys( values ).map( k => (
                {
                    key: k, 
                    label: isScale ? values[ k ] : k, 
                    isSelected: value ? value.includes( k ) : false
                }) 
            )

            return options;
        }

        return []

    }, [ dataKeys, selectedKey, isCategorial, value ] )

    const [currentKey, setCurrentKey] = useState( null );

    useEffect( () => {

        // if the current reference key changes, remove previous data:
        if( currentKey !== selectedKey ) {
            handleChange( null );
            setCurrentKey( selectedKey )
        }

    }, [selectedKey, currentKey, data, handleChange])

    useEffect( () => {

        if( data.selectAllByDefault && isCategorial && dataKeys && selectedKey && !value ) {

            // Select all values by default:

            const values = Object.keys( dataKeys[ selectedKey ].valueDescriptions );
            if( values.length <= selectAllLimitation ){
                handleChange( values )
            }
        }

    }, [data, isCategorial, dataKeys, selectedKey, value, handleChange])

    const handleValueSelection = useCallback( ( {key, isSelected} ) => {

        const newValues = value ? Array.from( value ) : [];

        if ( newValues.indexOf( key ) >= 0 ) {

            newValues.splice( newValues.indexOf( key ), 1 );

        } else {
            newValues.push( key )
        }

        handleChange( newValues );

    }, [value, handleChange])

    if( !isCategorial ) return null;

    return (
        
        <MultipleSelect
            label={ data.label ? data.label[ lang ] : '' }
            options={ valuesOptions }
            placeholder={ data.placeholder ? data.placeholder[ lang ] : "..." }
            onChange={ handleValueSelection }
        />
    )
}