import { selectUiLanguage } from 'app/preferencesSlice';
import Button from 'components/Button';
import DownloadBackup from 'features/survey_editor/properties_area/publish_and_export/DownloadBackup';
import RestoreFromFile from 'features/survey_editor/properties_area/publish_and_export/RestoreFromFile';
import {
    useCallback, useState
} from 'react';
import { useSelector } from 'react-redux';
import { surveyEditorTexts } from 'utils/appTexts';
import propGeneralStyles from 'features/survey_editor/properties_area/SurveyEditorProperties.module.css';
import TextInput from 'components/TextInput';
import MilgoSurveyVersions from './MilgoSurveyVersions';
import styles from './MilgoPublishAndExportStyles.module.css';

const texts = surveyEditorTexts.properties.publishAndExport;

const milgoServerUrl = process.env.REACT_APP_MILGO_SERVER_URL ?? 'http://localhost:3000';

export default function MilgoPublishAndExport() {
    const lang = useSelector(selectUiLanguage);
    const surveyId = useSelector(state => state.surveyEditor.present.surveyId);
    const localChangesMade = useSelector(state => state.surveyEditor.present.localChangesMade);
    const token = useSelector(state => state.users.milgoUser.token);
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState('');
    const [feedback, setFeedback] = useState({ color: '', text: '' })
    const [versionsKey, setVersionsKey] = useState(Math.random().toString())

    const canPublish = token && surveyId && !localChangesMade && description; // TODO

    const handlePublish = useCallback(async () => {
        // TODO
        const body = { resourceId: surveyId, sessionToken: token, description }
        setIsLoading(true);
        const response = await fetch(`${milgoServerUrl}/publish-resource`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        })
        if (response.status === 200) {
            setFeedback({ color: 'var(--color-success)', text: `✅ ${texts.milgoPublishing.feedbackSuccess[lang]}` })
            // Force rerender of version to refetch the updated list.
            setVersionsKey(Math.random().toString());
        } else {
            setFeedback({ color: 'var(--color-error-medium)', text: `❌ ${texts.milgoPublishing.feedbackError[lang]}` })
        }
        setTimeout(() => setFeedback({ color: '', text: '' }), 3000)
        setIsLoading(false);
        setDescription('');
    }, [surveyId, description, token])

    return (
        <div className={propGeneralStyles.mainCont}>
            <h4 className={propGeneralStyles.modeLabel}>
                {texts.title[lang]}
            </h4>
            <TextInput
                dir={lang === 'en' ? 'ltr' : 'rtl'}
                placeholder={texts.milgoPublishing.descriptionPlaceholder[lang]}
                value={description}
                onChange={({ target }) => setDescription(target.value)}
                contStyle={{ marginBottom: 8 }}
            />
            <Button
                label={description ? texts.publishBtn[lang] : texts.milgoPublishing.noDescriptionPublishBtn[lang]}
                bgStyle={{ width: '100%' }}
                onClick={handlePublish}
                indicateActivity={isLoading}
                disabled={!canPublish}
            />
            {feedback.text && <label style={{ display: 'block', marginTop: 8, color: feedback.color }}>{feedback.text}</label>}
            <MilgoSurveyVersions key={versionsKey} lang={lang} />

            <div className={styles.bottomStiky}>
                <div className={propGeneralStyles.separationLine} />

                <DownloadBackup lang={lang} />
                <RestoreFromFile lang={lang} />
            </div>
        </div>
    )
}