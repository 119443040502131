/**
 * Get item inside a nested object.
 * @param {object} obj 
 * @param  {...any} args // level1, level2, level3 ...
 * @returns the topmost item, or returns undefined if any item on any level doesn't exists.
 */
 export function getNested( obj, ...args ) {
    return args.reduce( (obj, level) => obj && obj[ level ], obj )
}

/**
 * Usage example: downloadFile(jsonData, 'json.txt', 'text/plain');
 * @param {any} content 
 * @param {string} fileName 
 * @param {string} contentType 
 */
export function downloadFile(content, fileName, contentType) {
    const a = document.createElement("a");
    const file = new Blob([content], {type: contentType});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}
