import IconButton from "components/IconButton";
import React, { useCallback, useState } from "react";
import styles from "./Results.module.css";
import { resultsTexts } from "utils/appTexts";
import { useDispatch, useSelector } from "react-redux";
import { selectUiLanguage } from "app/preferencesSlice";
import Button from "components/Button";
import { AlertDialog } from "components/poppers/Dialog";
import { HideRecord } from "api/api";
import { AnimatePresence, motion } from "framer-motion";
import Snackbar from "components/poppers/Snackbar";
import { fetchRecords } from "app/reportSlice";

export default function ResultaRecordsSelectionOptions({
    project,
    selectedRows,
    onDeselect,
}) {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const [deletionAlertActive, setDeletionAlertActive] = useState(false);
    const [status, setStatus] = useState({
        type: "",
        message: "",
        action: null,
        onClose: null,
    });

    const handleDelete = useCallback(async () => {
        if (selectedRows?.length > 0) {
            const promises = [];
            for (let i = 0; i < selectedRows.length; i++) {
                const hidePromise = new Promise(async (resolve, reject) => {
                    const response = await HideRecord(selectedRows[i]);
                    if (!response || response.error) reject(2);
                    if (response.data) {
                        if (response.data.status === "Error") {
                            reject(response.data.errorCode);
                        }
                        if (response.data.status === "OK") {
                            resolve("OK");
                        }

                        if (process.env.NODE_ENV === "development") {
                            console.log(response);
                        }
                    }
                });
                promises.push(hidePromise);

                /*
                HideRecord response:
                {status: “OK”}
                {status: “Error”, errorCode:1, errorMsg: “You are not an owner”}
                {status: “Error”, errorCode:2, errorMsg: “Internal Error”}
                */
            }

            Promise.all(promises)
                .then((responses) => {
                    setStatus({
                        type: "success",
                        message: resultsTexts.deleteRecordsSuccess[lang],
                        onClose: () => {
                            setStatus({});
                            onDeselect();
                            dispatch(fetchRecords(project.sourceName));
                        },
                    });
                })
                .catch((error) => {
                    setStatus({
                        type: "error",
                        message: resultsTexts.deleteRecordsError[error][lang],
                        action:
                            error === 2
                                ? {
                                      label: resultsTexts.deleteRecordsError
                                          .action[lang],
                                      callback: () => {
                                          setStatus({});
                                          handleDelete();
                                      },
                                  }
                                : null,
                        onClose: () => {
                            setStatus({});
                        },
                    });
                });
        }
    }, [selectedRows, lang, onDeselect, dispatch, project]);

    return (
        <>
            <AnimatePresence>
                {selectedRows?.length > 0 && (
                    <motion.div
                        className={styles.recordsSelectionOptionsCont}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                    >
                        <IconButton
                            name="x"
                            theme="Plain"
                            onClick={onDeselect}
                        />
                        <span className={styles.numberOfSelectedRows}>
                            {resultsTexts.numberOfSelectedRows[lang].replace(
                                "{n}",
                                selectedRows.length
                            )}
                        </span>
                        <Button
                            label={resultsTexts.deleteRecordsButton[lang]}
                            iconBefore={"delete"}
                            theme={"Plain"}
                            bgStyle={{ padding: "6px 12px" }}
                            onClick={() => setDeletionAlertActive(true)}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
            <AlertDialog
                isActive={deletionAlertActive}
                title={resultsTexts.deleteRecordsDialog.title[lang]}
                message={resultsTexts.deleteRecordsDialog.message[lang]}
                onClose={() => setDeletionAlertActive(false)}
                actions={[
                    { label: resultsTexts.deleteRecordsDialog.cancel[lang] },
                    {
                        label: resultsTexts.deleteRecordsDialog.remove[lang],
                        callback: handleDelete,
                    },
                ]}
            />
            {status.type && (
                <Snackbar
                    timeout={status.type === "error" ? 5 : 2}
                    type={status.type}
                    message={status.message}
                    action={status.action}
                    onClose={status.onClose}
                />
            )}
        </>
    );
}
