import React from 'react';
import propsStyles from '../SurveyEditorProperties.module.css';
import Checkbox from 'components/Checkbox';
import TextInput from 'components/TextInput';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';


import { surveyEditorTexts } from 'utils/appTexts';
import { inputTypes } from 'features/survey_editor/content_area/page/page_modules/NumberPageModule';
import { selectPageProperty } from 'features/survey_editor/surveyEditorSlice';

const generalTexts = surveyEditorTexts.properties.pageSettings;
const texts = generalTexts.settingsModules.numberModule;

export default function NumberSettingsModule({settings, updateSettings}) {

    const lang = useSelector( selectUiLanguage );
	const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );
    const currentPage = useSelector( state => state.surveyEditor.present.display.currentPage );
    const inputType = useSelector( state => selectPageProperty( state, currentPage, 'inputType' ) );

    if( !settings ) return null;
    
    const generalSettings = settings.general;
    const { showSubtitle, unit, useCustomRangeLabels } = generalSettings;

    function handleUnitChange( e ) {
        const unitDict = {...unit};
        unitDict[ surveyLang ] = e.target.value;
        updateSettings( {prop: 'unit', value: unitDict} );
    }

    return (
        <>
            <Checkbox
                size='s'
                className={ propsStyles.checkbox }
                label={ generalTexts.showSubtitleLabel[ lang ] }
                isChecked={ showSubtitle }
                onChange={ isChecked => updateSettings( {prop: 'showSubtitle', value: isChecked} ) }
            />
            {
                inputType ===  inputTypes.SLIDER &&
                <>
                    {/* <div className={ propsStyles.separationLine }/> */}
                    <TextInput
                        label={ texts.unitLabel[ lang ] }
                        value={ unit[ surveyLang ] || '' }
                        placeholder={ texts.unitPlaceholder[ lang ]}
                        onChange={ handleUnitChange }
                        contStyle={{marginBottom: 12}}
                    />
                    <Checkbox
                        size='s'
                        className={ propsStyles.checkbox }
                        label={ texts.useCustomRangeLabels[ lang ] }
                        isChecked={ useCustomRangeLabels }
                        onChange={ isChecked => updateSettings( {prop: 'useCustomRangeLabels', value: isChecked} ) }
                    />
                </>
            }
        </>
    )
}