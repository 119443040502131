import React, { useState, useRef } from 'react';

import styles from './SurveyEditorContent.module.css';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import NewPagePopper from './page/NewPagePopper';
import Button from 'components/Button';
import { surveyEditorTexts } from 'utils/appTexts';
import Emoji from 'components/Emoji';

export default function SurveyEditorNoPages () {

    const lang = useSelector( selectUiLanguage );

    const newPageBtnRef = useRef();
    const [isPopperActive, setIsPopperActive] = useState( false );

    return (
        <div className={ styles.noPagesCont} >
            <h2 className={ styles.noPageText }>
                <Emoji symbol={'👷'} style={{margin: '0 16px'}}/>
                { surveyEditorTexts.content.noPagesDescription[ lang ] }
            </h2>
            <div ref={ newPageBtnRef } style={{padding: '6px 0'}}>
                <Button
                    label={ surveyEditorTexts.content.noPagesNewBtn[ lang ] }
                    size= 'l'
                    theme= 'Outlined'
                    onClick={ () => setIsPopperActive( true ) }
                    disabled={ isPopperActive }
                />
            </div>

            <NewPagePopper 
                referenceElement={ newPageBtnRef.current } 
                isActive={ isPopperActive }
                targetIndex={ 0 }
                onDismiss={ () => setIsPopperActive( false ) }
                onSelect={ () => setIsPopperActive( false ) }
            />

        </div>
    )
}