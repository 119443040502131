import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logicItemActionChanged, logicItemConditionArgChanged, logicItemDeleted, pageItemPropChanged } from '../surveyEditorSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import { getNested } from 'utils/miscHelpers';

const alertMsg = surveyEditorTexts.messages.referenceInLogicAlert;

export function useReferenceInLogic( queryId ) {

    const dispatch = useDispatch();

    const logicItemsData = useSelector( state => getNested( state, 'surveyEditor', 'present', 'survey', 'logic', 'logicItemsData' ) );
    const pagesData = useSelector( state => state.surveyEditor.present.survey.content.pagesData );

    const getRefs = useCallback( () => {
        const refs = [];

        if( logicItemsData ) {

            Object.entries( logicItemsData ).forEach( ([itemId, data]) => {
                
                // Search inside condition:
                Object.entries( data.condition.args ).forEach( ([argId, argData]) => {
    
                    const dataString = JSON.stringify( argData );
                    if( dataString.includes( queryId ) ) {
                        refs.push( {
                            itemId,
                            foundIn: 'condition',
                            argId,
                            cleanupCB: () => {
                                dispatch( logicItemConditionArgChanged( {
                                    itemId,
                                    argId, 
                                    data: {}
                                }))
                            }
                        })
                    }
                })
    
                if( data.actions && data.actions.data ) {
    
                    // Search inside actions:
                    Object.entries( data.actions.data ).forEach( ([actionId, actionData]) => {
        
                        const dataString = JSON.stringify( actionData );
                        if( dataString.includes( queryId ) ) {
                            refs.push( {
                                itemId,
                                foundIn: 'actions',
                                actionId,
                                cleanupCB: () => {
                                    dispatch( logicItemActionChanged( {
                                        logicItemId: itemId,
                                        actionId, 
                                        data: {}
                                    }))
                                }
                            })
                        }
                    })
                }
    
                if( data.isNavigationLogic ) {
    
                    // Search inside navigation data:
                    const dataString = JSON.stringify( data.navigationData );
                    if( dataString.includes( queryId ) ) {
                        refs.push( {
                            itemId,
                            foundIn: 'navigationData',
                            cleanupCB: () => {
                                dispatch( logicItemDeleted( itemId ) );
                                dispatch( pageItemPropChanged( {
                                    pageId: data.navigationData.sourcePage, 
                                    itemId: data.navigationData.sourcePageItem, 
                                    prop: 'navigation',
                                    data: null
                                } ) );
                            }
                        })
                    }
                }
            } )
            
        }

        Object.entries( pagesData ).forEach( ([pageId, pageData]) => {
            const pageItems = pageData.itemsData;
            if( pageItems && Object.entries( pageItems ).length > 0 ) {
                
                Object.entries( pageItems ).forEach( ([itemId, itemData]) => {
                    if( itemData.hidingCondition ) {
                        // Search inside hiding condition:
                        const dataString = JSON.stringify( itemData.hidingCondition );
                        if( dataString.includes( queryId ) ) {
                            refs.push( {
                                pageId,
                                itemId,
                                foundIn: 'hidingCondition',
                                cleanupCB: () => {
                                    dispatch( pageItemPropChanged( {
                                        pageId, 
                                        itemId, 
                                        prop: 'hidingCondition', 
                                        data: null
                                    } ) );
                                }
                            })
                        }
                    }
                })
                
            }
        })


        return refs;
    }, [pagesData, logicItemsData, queryId, dispatch])

    return getRefs;
}

export const deletionAlert = (lang) => ({
    title: alertMsg.title[ lang ],
    message: alertMsg.message[ lang ],
    actions:[ 
        { label: alertMsg.actions.deleteAnyway[ lang ], theme: 'Plain' },
        { label: alertMsg.actions.cancel[ lang ], theme: 'Plain' }
    ]
})