import React from 'react';
import NotebookMain from './NotebookMain';
import NotebookServices from './notebook_services/NotebookServices';

export default function Notebook() {
    
    return (
        <>
            <NotebookMain/>
            <NotebookServices/>
        </>
    )
}