import React, { useMemo, useRef, useState } from 'react';
import styles from './VirtualVarsEditor.module.css';
import IconButton from 'components/IconButton';
import Modal from 'components/poppers/Modal';
import PopperPanel from 'components/poppers/PopperPanel';
import SelectionPopper from 'components/poppers/SelectionPopper';
import { useSelector } from 'react-redux';
import { resultsTexts } from 'utils/appTexts';
import { selectUiLanguage } from 'app/preferencesSlice';

const texts = resultsTexts.virtualVarEditor.formula;

export default function OperatorsToolbar ({onInsert}) {

    const lang = useSelector( selectUiLanguage );

    return (
        <div className={ styles.formulaInputToolbar}>
            <Arithmatic lang={ lang } onChoose={ onInsert }/>
            <Logic lang={ lang } onChoose={ onInsert }/>
            <MathMethods lang={ lang } onChoose={ onInsert }/>
        </div>
    )
}

const Arithmatic = ({lang, onChoose}) => {

    const btnRef = useRef();
    const [isPopperActive, setIsPopperActive] = useState( false );

    const options = useMemo( () => {
        return ['+','-','*','/','**','%']
    },[])

    return (
        <>
            <div ref={ btnRef }>
                <IconButton 
                    name='arithmetic_operators' 
                    theme='Plain'
                    tooltip={ texts.toolbar.arithmeticTooltip[ lang ]}
                    onClick={ () => setIsPopperActive( true ) }
                />
            </div>

            <Modal 
                isActive={ isPopperActive }
                onClose={ () => setIsPopperActive( false ) }
                bgBlur={ "low" }
            >
                <PopperPanel 
                    referenceElement={ btnRef.current }
                    panelStyle={{ padding: 'var(--space-small'}}    
                >
                    {
                        isPopperActive &&
                        <div className={ styles.pickerCont }>
                            { options.map( op => {
                                return (
                                    <button 
                                        key={op} 
                                        className={ styles.operatorBtn }
                                        onClick={ () => {
                                            onChoose( op );
                                            setIsPopperActive( false );
                                        }}
                                    >
                                        {op}
                                    </button>
                                )
                            }) }  
                        </div>
                    }
                </PopperPanel>
            </Modal>
        </>
    )
}

const Logic = ({lang, onChoose}) => {

    const btnRef = useRef();
    const [isPopperActive, setIsPopperActive] = useState( false );

    const options = useMemo( () => {
        return ['&&','||','>','<','==','>=', '<=', 'true', 'false' ]
    },[])

    return (
        <>
            <div ref={ btnRef }>
                <IconButton 
                    name='logic_operators' 
                    theme='Plain'
                    tooltip={ texts.toolbar.logicTooltip[ lang ]}
                    onClick={ () => setIsPopperActive( true )}
                />
            </div>

            <Modal 
                isActive={ isPopperActive }
                onClose={ () => setIsPopperActive( false ) }
                bgBlur={ "low" }
            >
                <PopperPanel 
                    referenceElement={ btnRef.current }
                    panelStyle={{ padding: 'var(--space-small'}}    
                >
                    {
                        isPopperActive &&
                        <div className={ styles.pickerCont }>
                            { options.map( op => {
                                return (
                                    <button 
                                        key={op} 
                                        className={ styles.operatorBtn }
                                        onClick={ () => {
                                            onChoose( op );
                                            setIsPopperActive( false );
                                        }}
                                    >
                                        {op}
                                    </button>
                                )
                            }) }  
                        </div>
                    }
                </PopperPanel>
            </Modal>
        </>
    )
}

const mathMethods = [
{ method: "Math.abs(x)", description: "Returns the absolute value of x." },
{ method: "Math.acos(x)", description: "Returns the arccosine of x." },
{ method: "Math.acosh(x)", description: "Returns the hyperbolic arccosine of x." },
{ method: "Math.asin(x)", description: "Returns the arcsine of x." },
{ method: "Math.asinh(x)", description: "Returns the hyperbolic arcsine of a number." },
{ method: "Math.atan(x)", description: "Returns the arctangent of x." },
{ method: "Math.atanh(x)", description: "Returns the hyperbolic arctangent of x." },
{ method: "Math.atan2(y, x)", description: "Returns the arctangent of the quotient of its arguments." },
{ method: "Math.cbrt(x)", description: "Returns the cube root of x." },
{ method: "Math.ceil(x)", description: "Returns the smallest integer greater than or equal to x." },
{ method: "Math.clz32(x)", description: "Returns the number of leading zeroes of the 32-bit integer x." },
{ method: "Math.cos(x)", description: "Returns the cosine of x." },
{ method: "Math.cosh(x)", description: "Returns the hyperbolic cosine of x." },
{ method: "Math.exp(x)", description: "Returns Ex, where x is the argument, and E is Euler's constant (2.718…, the base of the natural logarithm)." },
{ method: "Math.expm1(x)", description: "Returns subtracting 1 from exp(x)." },
{ method: "Math.floor(x)", description: "Returns the largest integer less than or equal to x." },
{ method: "Math.fround(x)", description: "Returns the nearest single precision float representation of x." },
{ method: "Math.hypot([x[, y[, …]]])", description: "Returns the square root of the sum of squares of its arguments." },
{ method: "Math.imul(x, y)", description: "Returns the result of the 32-bit integer multiplication of x and y." },
{ method: "Math.log(x)", description: "Returns the natural logarithm (㏒e; also, ㏑) of x." },
{ method: "Math.log1p(x)", description: "Returns the natural logarithm (㏒e; also ㏑) of 1 + x for the number x." },
{ method: "Math.log10(x)", description: "Returns the base-10 logarithm of x." },
{ method: "Math.log2(x)", description: "Returns the base-2 logarithm of x." },
{ method: "Math.max([x[, y[, …]]])", description: "Returns the largest of zero or more numbers." },
{ method: "Math.min([x[, y[, …]]])", description: "Returns the smallest of zero or more numbers." },
{ method: "Math.pow(x, y)", description: "Returns base x to the exponent power y (that is, xy)." },
{ method: "Math.random()", description: "Returns a pseudo-random number between 0 and 1." },
{ method: "Math.round(x)", description: "Returns the value of the number x rounded to the nearest integer." },
{ method: "Math.sign(x)", description: "Returns the sign of the x, indicating whether x is positive, negative, or zero." },
{ method: "Math.sin(x)", description: "Returns the sine of x." },
{ method: "Math.sinh(x)", description: "Returns the hyperbolic sine of x." },
{ method: "Math.sqrt(x)", description: "Returns the positive square root of x." },
{ method: "Math.tan(x)", description: "Returns the tangent of x." },
{ method: "Math.tanh(x)", description: "Returns the hyperbolic tangent of x." },
{ method: "Math.trunc(x)", description: "Returns the integer portion of x, removing any fractional digits." },
]

const MathMethods = ({lang, onChoose}) => {

    const btnRef = useRef();
    const [isPopperActive, setIsPopperActive] = useState( false );

    return (
        <>
            <div ref={ btnRef }>
                <IconButton 
                    name='math_methods' 
                    theme='Plain'
                    tooltip={ texts.toolbar.mathTooltip[ lang ]}
                    onClick={ () => setIsPopperActive( true )}
                />
            </div>

            <SelectionPopper
                referenceElement={ btnRef.current }
                isActive={ isPopperActive }
                onSelect={ op => {
                    onChoose( op );
                    setIsPopperActive( false );
                }}
                onDismiss={ () => setIsPopperActive( false ) }
            >
                {
                    mathMethods.map( m => (
                        <span key={ m.method } className={ styles.mathMethodName }> 
                            {m.method}
                            <span className={ styles.mathMethodDescription }>
                                {` - ${m.description}`}
                            </span>
                        </span>
                    ))
                }
            </SelectionPopper>
        </>
    )
}