import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './RichTextStyles.module.css';

import DynamicTextEntityEditor from './DynamicTextEntityEditor';
import LinkEntityEditor from './LinkEntityEditor';
import IconButton from 'components/IconButton';

import { EditorState, Modifier, RichUtils } from 'draft-js';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { componentsTexts } from 'utils/appTexts';
import PopperPanel from 'components/poppers/PopperPanel';
import Modal from 'components/poppers/Modal';
import { findEntity, selectWholeEntity } from './RichTextUtils';
import Button from 'components/Button';
import TextColorPicker from './TextColorPicker';

const texts = componentsTexts.richText.richTextToolbar;

export default function RichTextToolbar({ wrapper, editorState, onModify }) {
    const lang = useSelector(selectUiLanguage);

    const selectionState = useMemo(() => {
        if (editorState) {
            return editorState.getSelection();
        }
    }, [editorState]);

    // const [pointerPhase, setPointerPhase] = useState( '' );

    const [currentElement, setCurrentElement] = useState();
    const [isToolbarActive, setIsToolbarActive] = useState();

    const [currentEntity, setCurrentEntity] = useState(null);

    const getCurrentNode = useCallback(() => {
        const selection = window.getSelection();
        if (selection.anchorNode) {
            return selection.anchorNode.parentElement;
        }
        return null;
    }, []);

    // Handle toolbar activation:
    useEffect(() => {
        if (!currentEntity && !window.getSelection().isCollapsed) {
            const element = getCurrentNode();

            if (element) {
                setIsToolbarActive(true);
                setCurrentElement(element);
            }
        }
    }, [selectionState, getCurrentNode, currentEntity]);

    // Search for an existing entity in current selection. If not found, return false.
    const findAndSetEntity = useCallback(() => {
        const entity = findEntity(editorState);

        if (entity) {
            const element = getCurrentNode();
            if (element) setCurrentElement(element);

            // Make sure the whole block is selected:
            const newStateWithEntitySelection = selectWholeEntity(editorState);
            onModify(newStateWithEntitySelection);

            setCurrentEntity(entity);
            setIsToolbarActive(false);

            return true;
        }

        return false;
    }, [editorState, getCurrentNode, onModify]);

    // Handle wrapper double click:
    const handleDoubleClick = useCallback(() => {
        findAndSetEntity();
    }, [findAndSetEntity]);

    // const handlePointerDown = useCallback( () => setPointerPhase( 'down'), []);
    // const handlePointerUp = useCallback( () => setPointerPhase( 'up'), []);

    // Listen to Editor wrapper events:
    useEffect(() => {
        if (wrapper) {
            wrapper.addEventListener('dblclick', handleDoubleClick);
            // wrapper.addEventListener( 'pointerdown', handlePointerDown );
            // wrapper.addEventListener( 'pointerup', handlePointerUp );
        }

        // Clear listenters:
        return () => {
            if (wrapper) {
                wrapper.removeEventListener('dblclick', handleDoubleClick);
                // wrapper.removeEventListener( 'pointerdown', handlePointerDown );
                // wrapper.removeEventListener( 'pointerup', handlePointerUp );
            }
        };
    }, [wrapper, handleDoubleClick /* , handlePointerDown, handlePointerUp */]);

    const handleBold = useCallback(() => {
        onModify(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
        setIsToolbarActive(false);
    }, [editorState, onModify]);

    const handleLinkButton = useCallback(() => {
        if (!findAndSetEntity()) {
            setCurrentEntity({ type: 'LINK' });
            setIsToolbarActive(false);
        }
    }, [findAndSetEntity]);

    const handleDynamicTextButton = useCallback(() => {
        if (!findAndSetEntity()) {
            setCurrentEntity({ type: 'DYNAMIC_TEXT' });
            setIsToolbarActive(false);
        }
    }, [findAndSetEntity]);

    return (
        <>
            <Modal
                isActive={isToolbarActive}
                bgBlur="none"
                blockBgClicks={false}
                onClose={() => setIsToolbarActive(false)}
            >
                <PopperPanel
                    referenceElement={currentElement}
                    panelStyle={{
                        zIndex: 2,
                        width: 'fit-content',
                        padding: '2px 6px',
                        borderRadius: 'var(--corner-radius-large)',
                    }}
                >
                    <div className={styles.toolbarCont}>
                        <IconButton
                            name="bold"
                            tooltip={texts.boldTooltip[lang]}
                            theme="Plain"
                            iconStyle={{ width: 20, height: 20 }}
                            bgStyle={{ minWidth: 32, minHeight: 32 }}
                            colorSet="Grayscale"
                            onClick={handleBold}
                        />

                        <IconButton
                            name="link"
                            tooltip={texts.linkTooltip[lang]}
                            theme="Plain"
                            iconStyle={{ width: 20, height: 20 }}
                            bgStyle={{ minWidth: 32, minHeight: 32 }}
                            colorSet="Grayscale"
                            onClick={handleLinkButton}
                        />

                        <IconButton
                            name="dynamic_text"
                            tooltip={texts.dynamicTextTooltip[lang]}
                            theme="Plain"
                            iconStyle={{ width: 20, height: 20 }}
                            bgStyle={{ minWidth: 32, minHeight: 32 }}
                            colorSet="Grayscale"
                            onClick={handleDynamicTextButton}
                        />

                        <TextColorPicker
                            editorState={editorState}
                            onModify={onModify}
                        />
                    </div>
                </PopperPanel>
            </Modal>

            <LinkEntityEditor
                referenceElement={currentElement}
                isActive={currentEntity && currentEntity.type === 'LINK'}
                entity={currentEntity}
                onClose={() => {
                    setCurrentEntity(null);
                    setIsToolbarActive(false);
                }}
                editorState={editorState}
                onModify={onModify}
            />
            <DynamicTextEntityEditor
                referenceElement={currentElement}
                isActive={
                    currentEntity && currentEntity.type === 'DYNAMIC_TEXT'
                }
                entity={currentEntity}
                onClose={() => {
                    setCurrentEntity(null);
                    setIsToolbarActive(false);
                }}
                editorState={editorState}
                onModify={onModify}
            />
        </>
    );
}
