import React, { useCallback, useMemo } from 'react';
import styles from './SurveyEditorProperties.module.css';

import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import {
    selectPageProperty,
    pageContentChanged,
    getPageNumber,
} from 'features/survey_editor/surveyEditorSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import { pageTypesConfig } from 'features/survey_editor/surveyEditorConfiguration';
import PageKey from './shared_page_settings/PageKey';
import RepeatedInput from './shared_page_settings/RepeatedInputSettings';
import ContinueButton from './shared_page_settings/ContinueButtonSettings';

const texts = surveyEditorTexts.properties.pageSettings;

export default function PageSettings() {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const currentPage = useSelector(
        (state) => state.surveyEditor.present.display.currentPage
    );
    const pageType = useSelector((state) =>
        selectPageProperty(state, currentPage, 'type')
    );
    const parentBlock = useSelector((state) =>
        selectPageProperty(state, currentPage, 'parentBlock')
    );

    const settings = useSelector((state) => {
        if (!currentPage) return null;
        return selectPageProperty(state, currentPage, 'settings');
    });
    const itemNumber = useSelector((state) =>
        getPageNumber(state, currentPage, parentBlock)
    );

    ///////////
    // UTILS //
    ///////////

    const updateSettings = useCallback(
        ({ data, prop, value }) => {
            const newSettings = JSON.parse(JSON.stringify(settings));

            if (data) {
                newSettings.general = Object.assign(newSettings.general, data);
            }

            if (prop) {
                newSettings.general[prop] = value;
            }

            dispatch(
                pageContentChanged({
                    id: currentPage,
                    prop: 'settings',
                    value: newSettings,
                })
            );
        },
        [currentPage, settings, dispatch]
    );

    ////////////
    // RENDER //
    ////////////
    const title = useMemo(() => {
        return texts[pageType === 'BLOCK' ? 'blockTitle' : 'pageTitle'][
            lang
        ].replace('{n}', itemNumber);
    }, [pageType, itemNumber, lang]);

    const bgStyle = useMemo(() => {
        if (currentPage && pageType) {
            const pageTypeColor = pageTypesConfig[pageType].color + '1a';

            return {
                backgroundColor: pageTypeColor,
            };
        }

        return {
            backgroundColor: 'var( --color-background-mild )',
        };
    }, [currentPage, pageType]);

    if (!currentPage || !pageType) {
        return (
            <div className={styles.mainCont}>
                <p className={styles.modeLabel}>{texts.noPage[lang]}</p>
            </div>
        );
    }

    const SettingsModule = pageTypesConfig[pageType].pageSettingsModule;

    return (
        <div className={styles.mainCont} style={bgStyle}>
            <h4 className={styles.modeLabel}>{title}</h4>
            <PageKey lang={lang} currentPage={currentPage} />
            <RepeatedInput
                lang={lang}
                settings={settings}
                currentPage={currentPage}
                updateSettings={updateSettings}
            />
            <div className={styles.separationLine} />

            <SettingsModule
                key={'settings_' + currentPage}
                settings={settings}
                updateSettings={updateSettings}
            />
            <div className={styles.separationLine} />

            <ContinueButton
                lang={lang}
                settings={settings}
                currentPage={currentPage}
                updateSettings={updateSettings}
            />
        </div>
    );
}
