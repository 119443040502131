import { EditorState, Modifier } from 'draft-js';

/** Search for an existing entity in current selection. If not found, return false. */
export function findEntity(editorState) {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const entityKey = currentContentBlock.getEntityAt(start);
    if (entityKey) {
        const entity = currentContent.getEntity(entityKey);

        return entity;
    }

    return false;
}

export function selectWholeEntity(editorState) {
    const selection = editorState.getSelection();

    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const prevOffset = startOffset - 1;
    const block = editorState.getCurrentContent().getBlockForKey(startKey);
    const characterList = block.getCharacterList();
    const prevChar = characterList.get(prevOffset);
    const nextChar = characterList.get(startOffset);

    if (!prevChar || !nextChar) {
        return editorState;
    }

    const prevEntity = prevChar.getEntity();
    const nextEntity = nextChar.getEntity();
    const entity = prevEntity === nextEntity && prevEntity;

    if (!entity) {
        return editorState;
    }

    let finalPrevOffset = prevOffset;
    let finalNextOffset = startOffset;

    while (finalPrevOffset > 0) {
        finalPrevOffset--;
        const char = characterList.get(finalPrevOffset);
        if (char.getEntity() !== entity) {
            finalPrevOffset++;
            break;
        }
    }

    const blockLength = block.getLength();
    while (finalNextOffset < blockLength) {
        finalNextOffset++;
        const char = characterList.get(finalNextOffset);
        if (!char || char.getEntity() !== entity) {
            break;
        }
    }
    // console.log( finalNextOffset );
    return EditorState.forceSelection(
        editorState,
        selection.merge({
            anchorOffset: finalPrevOffset,
            focusOffset: finalNextOffset,
        })
    );
}
