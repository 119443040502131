import React, { useEffect } from 'react';
import moduleStyles from './PopperPanel.module.css';

import { usePopper } from 'react-popper';
import { motion, AnimatePresence, usePresence } from 'framer-motion';
import { useState } from 'react';

import { useSelector } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';

/**
 *
 * @param {{
 * referenceElement: DOM element
 * placement: string // where to place the popper relative to reference ('bottom-start', 'bottom-end', 'right', 'left',...)
 * panelStyle: object // custom panel style
 * offset: array // https://popper.js.org/docs/v2/modifiers/offset/
 * onExit: funciton //
 * transitionDuration: number
 * }} param0
 */
export default function PopperPanel({
    referenceElement,
    placement,
    boundary,
    useAltBoundary,
    panelStyle,
    offset = [0, 4],
    onExit,
    transitionDuration = 0.05,
    children,
}) {
    const lang = useSelector(selectUiLanguage);

    const [ref, setRef] = useState(document.getElementById('root'));

    const [isPresent, safeToRemove] = usePresence();

    const [panelWidth, calcPanelWidth] = useState(100);
    const [popperElement, setPopperElement] = useState(null);

    const { styles, attributes, update } = usePopper(ref, popperElement, {
        placement: placement
            ? placement
            : lang === 'en'
            ? 'bottom-start'
            : 'bottom-end',
        strategy: 'absolute',
        modifiers: [
            {
                name: 'offset',
                options: { offset },
            },
            {
                name: 'flip',
                enabled: true,
                options: {
                    boundary: boundary ? boundary : 'clippingParents',
                    altBoundary: useAltBoundary,
                },
            },
            {
                name: 'preventOverflow',
                enabled: true,
                options: {
                    boundary: boundary ? boundary : 'clippingParents',
                    escapeWithReference: true,
                    padding: 8,
                },
            },
        ],
    });

    useEffect(() => {
        if (referenceElement) {
            setRef(referenceElement);
            const width = referenceElement.offsetWidth;

            calcPanelWidth(width);
        }

        if (update) {
            update();
        }
    }, [lang, isPresent, referenceElement, update]);

    const contStyle = {
        minWidth: panelWidth,
        ...styles.popper,
    };

    useEffect(() => {
        let saveToRemoveTimeout = null;

        if (!isPresent) {
            saveToRemoveTimeout = setTimeout(() => {
                if (onExit) onExit();
                safeToRemove();
            }, (0.1 + transitionDuration) * 1000);
        }

        return function cleanup() {
            if (saveToRemoveTimeout) {
                clearTimeout(saveToRemoveTimeout);
            }
        };
    }, [isPresent, onExit, transitionDuration, safeToRemove]);

    // Update popper position on panel element size change:
    useEffect(() => {
        if (!popperElement) return;

        const resizeObserver = new ResizeObserver((entries) => {
            if (update) {
                update();
            }
        });

        resizeObserver.observe(popperElement);

        return () => resizeObserver.disconnect;
    }, [popperElement, update]);

    const panelMotionVarients = {
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0.9 },
    };

    return (
        <AnimatePresence>
            {isPresent && (
                <div
                    ref={setPopperElement}
                    style={contStyle}
                    {...attributes.popper}
                >
                    <motion.div
                        className={moduleStyles.poperPanel}
                        style={panelStyle}
                        variants={panelMotionVarients}
                        animate={'visible'}
                        exit={'hidden'}
                        transition={{
                            duration: transitionDuration,
                            ease: 'easeOut',
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onMouseDown={(e) => e.stopPropagation()}
                        onMouseUp={(e) => e.stopPropagation()}
                        // onPointerDown={ e => e.stopPropagation() }
                        // onPointerUp={(e) => e.stopPropagation()}
                    >
                        {children}
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
}
