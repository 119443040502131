import { pageContentChanged } from "features/survey_editor/surveyEditorSlice";
import produce from "immer";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useSafeToRemoveMedia = () => {
    const pagesData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData
    );

    const isSafe = useCallback(
        (fileId) => {
            let _isSafe = true;
            Object.values(pagesData).forEach((v) => {
                if (v.media === fileId) {
                    _isSafe = false;
                    console.warn(
                        "Trying to archive a media that is in use in page " +
                            v.key
                    );
                }
            });

            return _isSafe;
        },
        [pagesData]
    );

    return isSafe;
};

export const useRemoveMediaFromAllPages = () => {
    const dispatch = useDispatch();
    const pagesData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData
    );

    const remover = useCallback(
        (fileId) => {
            Object.entries(pagesData).forEach(([id, data]) => {
                if (data.media === fileId) {
                    const newSettings = produce(data.settings, (s) => {
                        s.media = null;
                    });

                    dispatch(
                        pageContentChanged({
                            id,
                            prop: "settings",
                            value: newSettings,
                        })
                    );
                    dispatch(
                        pageContentChanged({
                            id,
                            prop: "media",
                            value: null,
                        })
                    );
                }
            });
        },
        [pagesData, dispatch]
    );

    return remover;
};
