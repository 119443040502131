import React, { useState, useEffect, useRef, useMemo } from 'react';
import Button from './Button';
import SelectionPopper from './poppers/SelectionPopper';
import styles from './InputStyles.module.css';

/**
 *
 * @param {{
 * options: [{key: "",label: ""}], // options list can be provided via 'options' property, or through array of JSX children.
 * className: string, // css class name for the container element
 * label: string,
 * value: string, // selected value key
 * theme: enum, // See Button themes
 * colorSet: enum, // See Button colorSets
 * dir: string
 * contStyle: object,
 * bgStyle: object,
 * labelStyle: object,
 * onChange: function, // Returns { key, label }; if options are passed as DOM children, the return value is only a key.
 * disabled: boolean,
 * }}
 */
export default function DropDown({
    className,
    label,
    options,
    children,
    value,
    theme = 'Outlined',
    colorSet = 'Field',
    dir = 'auto',
    contStyle,
    bgStyle,
    labelStyle,
    onChange,
    disabled,
}) {
    const spBtnRef = useRef();
    const [selectionPopperActive, setSelectionPopperActive] = useState(false);
    const [current, setCurrent] = useState(null);

    const currentValueLabel = useMemo(() => {
        if (!current) return '...';

        if (current.label) return current.label;
        if (current.props && current.props.label) return current.props.label;
    }, [current]);

    useEffect(() => {
        if (value) {
            let current;

            if (options) {
                // List is provided through options

                current = options.find((x) => x.key === value);
            }

            if (children) {
                // List is provided through JSX children

                React.Children.forEach(children, (c, i) => {
                    if (c.key === value) current = c;
                });
            }

            if (current) setCurrent(current);
        }
    }, [current, options, value, children]);

    function handleChange(op) {
        if (onChange) {
            onChange(op);
        }

        setSelectionPopperActive(false);
    }

    return (
        <div className={className} style={contStyle}>
            {label && <label className={styles.label}>{label}</label>}
            <div ref={spBtnRef}>
                {' '}
                {/*div wrapper for SelectionPopper reference*/}
                <Button
                    label={currentValueLabel}
                    theme={theme}
                    iconAfter={'arrow_down'}
                    colorSet={colorSet}
                    size="m"
                    bgStyle={{
                        boxSizing: 'border-box',
                        width: '100%',
                        justifyContent: 'space-between',
                        padding: '6px 14px',
                        height: 40,
                        // backgroundColor: 'white' ,
                        ...bgStyle,
                    }}
                    labelStyle={{
                        font: 'var( --font-body-medium)',
                        color: `var( --color-type-${
                            disabled ? 'low' : 'high'
                        }-emphasis)`,
                        flex: 1,
                        textAlign: 'start',
                        ...labelStyle,
                    }}
                    onClick={() => setSelectionPopperActive(true)}
                    disabled={disabled}
                />
            </div>

            <SelectionPopper
                referenceElement={spBtnRef.current}
                isActive={selectionPopperActive}
                options={options}
                children={children}
                onDismiss={() => setSelectionPopperActive(false)}
                onSelect={handleChange}
                dir={dir}
            />
        </div>
    );
}
