import { selectUiLanguage } from 'app/preferencesSlice';
import { motion } from 'framer-motion';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './Switch.module.css';

const sizes = {
    'm': 24
}

export default function Switch({
    label,
    labelStyle,
    className,
    size = sizes.m,
    contStyle,
    isChecked, 
    onChange,
    disabled = false
}) {

    const lang = useSelector( selectUiLanguage );
    const direction = useMemo( () => lang === 'en' ? 1 : -1, [lang] );

    const bgColor = useMemo( () => {
        return isChecked ? 'var(--color-primary-medium)' : 'var(--color-background-medium)'
    }, [isChecked])


    const handleClick = useCallback( () => {

        if( onChange ) {
            onChange( !isChecked )
        }

    }, [onChange, isChecked])

    return (
        <div className={ `${styles.container} ${className}` } style={ contStyle }>
            <div 
                className={ styles.box } 
                style={{
                    height: size,
                    width: size * 2,
                    borderRadius: size ,
                    backgroundColor: bgColor, 
                    pointerEvents: disabled ? 'none' : 'auto',
                    opacity: disabled ? 0.3 : 1
                }}
                onClick={ handleClick }
            >
                
                <motion.div 
                    className={ styles.knob }
                    style={{
                        height: size,
                        width: size,
                        borderRadius: size * 0.5
                    }}
                    animate={{x: isChecked ? direction*size : 0}}
                    transition={{duration: 0.2, ease: 'easeOut'}}
                />

            </div>
            { label &&
                <p className={ styles.label } style={ labelStyle }>{ label }</p>
            }
        </div>
    )
}