import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
// import styles from './RichTextStyles.module.css';

import {
    Editor,
    EditorState,
    ContentState,
    RichUtils,
    CompositeDecorator,
    convertToRaw,
    convertFromRaw,
    convertFromHTML,
} from 'draft-js';
import 'draft-js/dist/Draft.css';

import RichTextToolbar from './RichTextToolbar';
// import RichTextCopyPaste from './RichTextCopyPaste';
import { LinkEntityComp, findLinkEntities } from './LinkEntityDecorator';
import {
    DynamicTextEntityComp,
    findDynamicTextEntities,
} from './DynamicTextEntityDecorator';
import { findEntity, selectWholeEntity } from './RichTextUtils';
import { textColors } from 'features/survey_editor/surveyEditorConfiguration';
import { placeholderDecorator } from './PlaceholderTextDecorator';

const decorator = new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: LinkEntityComp,
    },
    {
        strategy: findDynamicTextEntities,
        component: DynamicTextEntityComp,
    },
    placeholderDecorator,
]);

/**
 * Rich text input component
 * @param {{
 * className {string}
 * style {object}
 * textAlignment {enum} // 'left' | 'center' | 'right'
 * value {object} // Raw content { blocks, entityMap }
 * placeholder {string}
 * onChange {function}
 * onFocus {function}
 * onBlur {function}
 * onReturn {function} //  (e: SyntheticKeyboardEvent, editorState: EditorState )
 * onKeyDown {function} // (e: SyntheticKeyboardEvent)
 * }}
 */
const RichTextInput = React.forwardRef(
    (
        {
            className,
            style,
            textAlignment,
            value,
            placeholder,
            onChange,
            onFocus,
            onBlur,
            onReturn,
            onKeyDown,
        },
        ref
    ) => {
        const wrapperRef = useRef();
        /*
    const surveyEditorHistoryIndex = useSelector( state => state.surveyEditor.index );
    const surveyEditorHistoryLimit = useSelector( state => state.surveyEditor.limit );
*/
        const lastEdit = useRef();
        const lastContent = useRef();
        const [editorState, setEditorState] = useState();

        const contentState = useMemo(
            () => editorState?.getCurrentContent(),
            [editorState]
        );

        ///////////////////////////////////////////////
        // Sync internal state with external content //
        ///////////////////////////////////////////////

        useEffect(() => {
            if (contentState) {
                const raw = convertToRaw(contentState);
                const rawString = JSON.stringify(raw);
                if (rawString !== lastContent.current) {
                    const timestamp = Date.now();
                    raw.lastEdit = timestamp;
                    onChange(raw);
                    lastContent.current = rawString;
                    lastEdit.current = raw.lastEdit;
                }
            }
        }, [contentState]);

        useEffect(() => {
            if (value) {
                if (typeof value === 'string') {
                    const blocksFromHTML = convertFromHTML(value);
                    const rawContent = ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap
                    );
                    setEditorState(
                        EditorState.createWithContent(rawContent, decorator)
                    );
                    return;
                }

                if (value.lastEdit !== lastEdit.current) {
                    try {
                        const newContent = convertFromRaw(value);
                        setEditorState(
                            EditorState.createWithContent(newContent, decorator)
                        );
                        lastEdit.current = value.lastEdit;
                        const raw = { ...value };
                        delete raw.lastEdit;
                        lastContent.current = JSON.stringify(raw);
                    } catch (error) {
                        console.warn(
                            "Can't convert rich text from raw data. Details: ",
                            error
                        );
                        setEditorState(EditorState.createEmpty(decorator));
                    }
                }
            } else if (!lastEdit.current) {
                setEditorState(EditorState.createEmpty(decorator));
            }
        }, [value]);

        ////////////////////
        // Event handlers //
        ////////////////////

        const handleKeyCommand = useCallback((command, editorState) => {
            // console.log(command);
            if (['bold'].includes(command)) {
                let state = editorState;
                if (findEntity(editorState)) {
                    state = selectWholeEntity(editorState);
                }
                const newState = RichUtils.handleKeyCommand(state, command);
                if (newState) {
                    setEditorState(newState);
                    return 'handled';
                }
            }

            return 'not-handled';
        }, []);

        // const handleBeforeInput = useCallback((chars, editorState) => {
        // console.log(chars);
        // @TODO: detect '<var_name>'
        // }, []);

        // const handlePastedText = useCallback((chars, html, editorState) => {

        // }, []);

        if (!editorState) return null;

        return (
            <>
                <div ref={wrapperRef} className={className} style={style}>
                    <Editor
                        ref={ref}
                        textAlignment={textAlignment}
                        placeholder={placeholder}
                        editorState={editorState}
                        customStyleMap={textColors} // should extend text colors with more syltes in the future
                        handleKeyCommand={handleKeyCommand}
                        onChange={setEditorState}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        handleReturn={onReturn}
                        keyBindingFn={onKeyDown}
                        // handleBeforeInput={handleBeforeInput}
                        // handlePastedText={handlePastedText}
                    />
                </div>

                <RichTextToolbar
                    wrapper={wrapperRef.current}
                    editorState={editorState}
                    onModify={setEditorState}
                />

                {/* <RichTextCopyPaste
                editorState={ editorState }
                onModify={ setEditorState }
            /> */}
            </>
        );
    }
);

export default RichTextInput;

/**
 *
 * @param {object} txt
 * @param {string} defaultTxt
 */
export function getRawText(txt, defaultTxt = 'untitled') {
    if (!txt) return 'untitled';

    if (typeof txt === 'string') return txt;

    // RichText:
    if (typeof txt === 'object' && txt.blocks) {
        try {
            let text = '';
            txt.blocks.forEach((b) => (text += `${b.text} `));
            if (!text) return defaultTxt;
            return text;
        } catch (error) {
            console.warn(
                'Could not get text from raw content object. Details: \n',
                error
            );
        }
    }

    return defaultTxt;
}

/**
 * Use this function to get an initial raw text data without using the DraftJS editor.
 * @param {string} txt
 * @returns DraftJS raw text data.
 */
export function getRichText(txt) {
    const blocksFromHTML = convertFromHTML(txt);
    const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    );
    const richText = {
        lastEdit: Date.now(),
        ...convertToRaw(content),
    };

    return richText;
}
