import IconButton from 'components/IconButton';
import styles from './MilgoSurveyTopBar.module.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { surveyEditorTexts } from 'utils/appTexts';

import { selectUiLanguage } from 'app/preferencesSlice';
import { userPanelActivation } from 'features/users/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getTopicResources, milgoAdminUrl } from './milgo-topic-resources-api';
import { LocalStorage } from 'utils/cacheManager';
import { useParams } from 'react-router-dom';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';

export default function MilgoSurveyTopBar(props) {
    const userData = useSelector((state) => state.users.milgoUser);
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);

    return (
        <div className={styles.rootCont}>
            <h2
                style={{
                    marginBottom: 0,
                    paddingInlineEnd: 16,
                    borderInlineEnd: 'var(--border-light)',
                }}
            >
                {surveyEditorTexts.appName[lang]}
            </h2>
            <TopicContext />
            <div
                className={styles.barEndCont}
                style={{ marginInlineStart: 'auto' }}
            >
                <IconButton
                    name="avatar"
                    theme="Outlined"
                    size="m"
                    colorSet="Grayscale"
                    bgStyle={{
                        width: 32,
                        height: 32,
                        minWidth: 32,
                        minHeight: 32,
                        padding: 0,
                        borderWidth: 0,
                    }}
                    iconStyle={{
                        width: 34,
                        height: 34,
                        margin: -1,
                    }}
                    imageUrl={userData.image ? userData.image.publicUrl : null}
                    onClick={() => dispatch(userPanelActivation(true))}
                />
            </div>
        </div>
    );
}

const storyEditorUrl =
    process.env.REACT_APP_MILGO_STORY_EDITOR_URL ?? 'http://localhost:4005';
const surveyEditorUrl =
    process.env.REACT_APP_MILGO_SURVEY_EDITOR_URL ?? 'http://localhost:4008';

const TopicContext = () => {
    const survey = useSelector((state) => state.surveyEditor.present?.survey);
    const { resourceId } = useParams();
    const sessionToken = LocalStorage.Read(LocalStorage.keys.MILGO_TOKEN);

    const [topicData, setTopicData] = useState({});

    const handleFetch = useCallback(async () => {
        if (!resourceId || !sessionToken) return;
        const result = await getTopicResources(resourceId, sessionToken);
        setTopicData(result);
    }, [resourceId, sessionToken]);

    const openURL = (id) => {
        if (
            !topicData.topic?.resources ||
            topicData.topic.resources.length === 0
        )
            return;
        const type = topicData.topic.resources.find((r) => r.id === id).type;
        if (!type) return;
        const url = {
            story: `${storyEditorUrl}/${id}`,
            survey: `${surveyEditorUrl}/${id}`,
        }[type];
        window.open(url, '_blank');
    };

    useEffect(() => {
        handleFetch();
    }, [handleFetch]);

    const surveyName = useMemo(() => {
        let name = '';
        if (survey?.surveyName) {
            const langNames = Object.values(survey.surveyName);
            let i = 0;
            while (i < langNames.length && name === '') {
                if (langNames[0] !== '') {
                    name = langNames[0];
                }
                i++;
            }
        }
        return name;
    }, [survey]);

    console.log(surveyName, topicData);
    if (topicData.status !== 'ok' || !topicData.topic)
        return (
            <div className={styles.logoSection}>
                <h2 style={{ marginBottom: 0 }}>{surveyName}</h2>
            </div>
        );

    return (
        <div className={styles.logoSection}>
            <a
                href={`${milgoAdminUrl}/new-topics/${topicData?.topic?.id}`}
                target="_blank"
                rel="noreferrer"
                className={styles.topicLink}
            >
                {topicData.topic?.title}
            </a>
            <span style={{ fontWeight: 400 }}>/</span>
            {topicData.topic.resources?.length > 1 ? (
                <DropDown
                    theme={'Plain'}
                    bgStyle={{ padding: '4px 8px' }}
                    labelStyle={{
                        font: 'var(--font-headline-h2)',
                        fontWeight: 400,
                    }}
                    value={resourceId}
                    options={topicData.topic.resources?.map((r) => ({
                        key: r.id,
                        label: r.name,
                    }))}
                    onChange={(rKey) => openURL(rKey)}
                >
                    {topicData.topic.resources
                        .filter((r) => r.id !== resourceId)
                        .map((r) => (
                            <React.Fragment key={r.id}>
                                <span>{r.name}</span>
                                <label className={styles.resourceTypeLabel}>
                                    {r.type}
                                    <Icon name={'external_link'} size={12} />
                                </label>
                            </React.Fragment>
                        ))}
                </DropDown>
            ) : (
                <h2 style={{ marginBottom: 0 }}>{surveyName}</h2>
            )}
        </div>
    );
};
