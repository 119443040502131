import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './DropdownListPageModule.module.css';
import pageStyles from './PageModulesStyles.module.css';
import PageEditableItemsList, { types as listTypes } from './page_module_components/PageEditableItemsList';
import FileInput from 'components/FileInput';
import RichTextInput from 'components/rich_text/RichTextInput';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import { AlertDialog } from 'components/poppers/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import { selectPageProperty, pageTextContentChanged, batchAddItemsToPage, pageContentChanged } from 'features/survey_editor/surveyEditorSlice';
import { selectUiLanguage } from 'app/preferencesSlice';
import { textDirection } from 'utils/stylingTools';
import { csvToObj } from 'utils/csvToObj';

import { surveyEditorTexts } from 'utils/appTexts';

const texts = surveyEditorTexts.content.pageModules.dropdownList;

export default function DropdownListPageModule( {pageId, pageHovered} ) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );
    const subtitle = useSelector( state => selectPageProperty( state, pageId, "subtitle" ) );
    const listTitle = useSelector( state => selectPageProperty( state, pageId, "listTitle" ) );
    const pageSettings = useSelector( state => selectPageProperty( state, pageId, "settings" ) );

    const showSubtitle = pageSettings.general.showSubtitle;

    const [helpDialogueOpen, setHelpDialogueOpen] = useState( false );

    const fileReader = useMemo( () => new FileReader(), []);


    // Handle CSV load:
    useEffect( () => {

        function handleCSV( e ) {
            const content =  e.target.result;
            const convertionResult = csvToObj( content );
            if( convertionResult.data ) {
                dispatch( batchAddItemsToPage( { pageId, list: convertionResult.data } ) )
            }

            if( convertionResult.error ) {
                alert( "Failed to process CSV data. Details: " + convertionResult.error );
            }
        }

        fileReader.addEventListener( 'load', handleCSV );
        return () => fileReader.removeEventListener( 'load', handleCSV );
        

    }, [pageId, fileReader, dispatch])

    const handleChange = useCallback( ( prop, value ) => {

        dispatch( pageTextContentChanged( {id: pageId, prop, value, lang: surveyLang }) );

    }, [pageId, surveyLang, dispatch])

    const handleDeleteAll = useCallback( () => {

        dispatch( pageContentChanged( {id: pageId, prop: 'itemsOrder', value: []}))
        dispatch( pageContentChanged( {id: pageId, prop: 'itemsData', value: {}}))

    }, [pageId, dispatch])

    const handleFile = useCallback( (files) => {

        const csv = files[0];
        fileReader.readAsText( csv );

    }, [fileReader] )

    return (
        <>
            { showSubtitle &&            
                <RichTextInput
                    key={ 'subtitle_' + surveyLang }
                    className = { pageStyles.subtitle }
                    value = { subtitle[ surveyLang ] || '' }
                    placeholder = { texts.subtitlePlaceholder[ lang ] }
                    textAlignment={ textDirection( lang ).align }
                    onChange = { value => handleChange( 'subtitle', value ) }
                />
            }
            <TextInput 
                type="text"
                placeholder={ texts.listNamePlaceholderHint[ lang ]}
                label={ texts.listNamePlaceholderLabel[ lang ] }
                value={ listTitle[ surveyLang ] || '' }
                fieldStyle={{
                    direction: textDirection( surveyLang ).direction,
                    textAlign: textDirection( lang ).align,
                }}
                theme="Outlined"    
                contStyle={{marginBottom: 16}}
                onChange={ e => handleChange( "listTitle", e.target.value ) }
            />
            
            <div className={ styles.listToolbar }>
                <FileInput
                    // label={ texts.listFromFileLabel[ lang ] }
                    className={ styles.fileInputCont }
                    accept='text/csv'
                    onSelect={ handleFile }
                    multiple={ false }
                    showFileName={ false }
                    buttonSize={ 'm' }
                    buttonTheme={ 'Plain' }
                    textOnButton={ texts.addItemsFromFileBtn[ lang ] }
                />
                <IconButton
                    name='help'
                    theme='Plain'
                    onClick={ () => setHelpDialogueOpen( true ) }
                />
                <Button
                    label={ texts.deleteAllBtn[ lang ] }
                    onClick={ handleDeleteAll }
                    theme='Plain'
                    size='m'
                    // disabled={ } // @TODO: disable if list is empty
                />
            </div>

            <PageEditableItemsList 
                pageId={ pageId } 
                pageHovered={ pageHovered }
                type={ listTypes.VIRTUALIZED }
                isRichText={ false }
            /> 
           
           <AlertDialog 
                isActive={ helpDialogueOpen }
                style={{
                    width: 420, 
                }}
                title={ texts.helpDialog.title[ lang ]}
                message={""}
                actions={ [
                    {label: texts.helpDialog.action[ lang ], callback: () => {} }                
                ]}
                onClose={ () => setHelpDialogueOpen( false ) }    
            > 
                <ol> 
                    <li key='1'>{ texts.helpDialog.message.step1[ lang ] }</li>
                    <li key='2'>{ texts.helpDialog.message.step2[ lang ] }</li>
                    <li key='3'>{ texts.helpDialog.message.step3[ lang ] }</li>
                    <li key='4'>{ texts.helpDialog.message.step4[ lang ] }</li>
                    <li key='5'>{ texts.helpDialog.message.step5[ lang ] }</li>
                </ol>
                <a 
                    href='https://docs.google.com/spreadsheets/d/1VIEdsIMDniU4Z1J_tboJDfLXkFDMDnkPhFD64SQuzts/edit?usp=sharing'
                    target='_blank'
                    rel="noreferrer"
                >
                    { texts.helpDialog.file[ lang ] }
                </a> 
            </AlertDialog>
        </>
    )
}