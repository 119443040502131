import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Evaluate } from "features/results/virtual_vars/FormulaEvaluation";
import { resultsTexts } from "utils/appTexts";
import { v4 as uuidv4 } from "uuid";
import * as Api from "../api/api";

const virtualVarsTexts = resultsTexts.virtualVarEditor;

const initialState = {
    fetchStatus: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: null,
    bucketId: null,
    sampleRecordsCount: null,
    totalRecordsCount: null,
    dataKeys: null,
    dataStats: null,

    //Insights:
    insightsPreferences: {
        insightsPrefsFetchStatus: "idle",
        insightsPrefstUpdateStatus: "idle",

        rawPrefsData: {},

        funnelData: {
            funnelFields: {},
            initialFunnel: [],
        },
    },

    //Results:
    results: [],
    fetchRecordsStatus: "idle",
    recordsData: null,
    recordsFilterData: {
        params: {
            filterUnfinished: false,
        },
    },

    //Virtual Variables:
    virtualVars: {
        fetchVarsSettingsStatus: "idle",
        updateVarsSettingsStatus: "idle",
        varsSettings: {},
        editor: {
            varData: {
                varType: "NUMERIC",
                key: "",
                title: "",
                subtitle: "",
                formula: "",
                categories: [],
                args: {},
            },
            isValid: false,
            keyWarning: null,
            output: "",
            selectedCategory: -1,
            varToEdit: null,
            message: null,
        },
    },
};

////////////////////
// Async actions: //
////////////////////

export const fetchReport = createAsyncThunk(
    "report/fetchReport",
    async (sourceName, thunkApi) => {
        const response = await Api.GetReport(sourceName);

        if (response.error) {
            console.warn(
                "Error while fetching report. Details: ",
                response.error
            );
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            return response.data;
        }
    }
);

export const fetchInsightsPrefs = createAsyncThunk(
    "report/fetchInsightsPrefs",
    async (sourceName, thunkApi) => {
        const response = await Api.GetBucketInsightsPrefs(sourceName);

        if (response.error) {
            console.warn(
                "Error while fetching fetchInsightsPrefs. Details: ",
                response.error
            );
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            return response.data;
        }
    }
);

export const updateInsightPrefs = createAsyncThunk(
    "report/updateInsightPrefs",
    async (actionData, thunkApi) => {
        const { bucketId, prefs } = actionData;

        const response = await Api.UpdateBucketInsightsPrefs(bucketId, prefs);

        if (response.error) {
            console.warn(
                "Error while updating funnels. Details: ",
                response.error
            );
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            return response.data;
        }
    }
);

export const fetchRecords = createAsyncThunk(
    "report/fetchRecords",
    async (sourceName, thunkApi) => {
        const state = thunkApi.getState();
        const filterData = state.report.recordsFilterData;

        const response = await Api.GetRecords(sourceName, filterData);

        if (response.error) {
            console.warn(
                "Error while fetching records. Details: ",
                response.error
            );
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            return response.data;
        }
    }
);

export const fetchVirtualVarsSettings = createAsyncThunk(
    "report/fetchVirtualVarsSettings",
    async (bucketId, thunkApi) => {
        const response = await Api.GetVirtualVarsSettings(bucketId);

        if (response.error) {
            console.warn(
                "Error while fetching virtual settings. Details: ",
                response.error
            );
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            return response.data;
        }
    }
);

export const updateVirtualVars = createAsyncThunk(
    "report/updateVirtualVars",
    async (bucketId, thunkApi) => {
        const state = thunkApi.getState();
        let varsData = state.report.virtualVars.varsSettings;
        varsData = JSON.stringify(varsData, null, 4);

        const response = await Api.UpdateVirtualVars(bucketId, varsData);

        if (response.error) {
            console.warn(
                "Error while updating virtual vars. Details: ",
                response.error
            );
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            return response.data;
        }
    }
);

////////////////////
////// Utils ///////
////////////////////

function proccessReportData(state, report) {
    // console.log( report );

    state.bucketId = report.bucket.id;
    state.totalRecordsCount = report.totalRecordsCount;
    state.sampleRecordsCount = report.sampleRecordsCount;

    const metaFieldsTypes = ["SurveyCompletionTime", "SurveySubmissionDate"];
    const funnelFields = {};

    // const dataKeysObj = JSON.parse( report.dataKeys );
    const dataKeysObj = report.bucket.keysPreferences;
    state.dataKeys = dataKeysObj;

    // const dataStatsObj = JSON.parse( report.dataStats );
    const dataStatsObj = report.dataStats;
    state.dataStats = dataStatsObj;

    const results = [];
    let initialFunnel = [];

    for (const [key, data] of Object.entries(dataKeysObj)) {
        const stats = dataStatsObj[key];
        if (
            data.originType &&
            !metaFieldsTypes.includes(data.originType) &&
            stats !== undefined
        ) {
            const funnelData = { ...data };
            // console.log(stats.times)
            if (stats.times !== undefined) {
                funnelData.time = stats.times
                    ? calculateMedianTime(stats.times)
                    : null;
            }

            funnelFields[key] = funnelData;

            const originInx = data.originIndex;
            if (originInx % 1 === 0) {
                if (initialFunnel[originInx]) {
                    initialFunnel[originInx].push(key);
                } else {
                    initialFunnel[originInx] = [key];
                }
            }

            const resultData = { ...data };
            resultData.key = key;
            resultData.respondents = stats.count;
            resultData.answers = stats.answers;
            results.push(resultData);
        }
    }

    // Filter out non-existing field indexes:
    initialFunnel = initialFunnel.filter((step) => {
        return step !== null;
    });

    // Sort steps by fields indexes:
    initialFunnel.forEach((step) => {
        if (step.length > 0) {
            step.sort((a, b) => {
                return dataKeysObj[a].originIndex - dataKeysObj[b].originIndex;
            });
        }
    });

    state.insightsPreferences.funnelData.funnelFields = funnelFields;
    state.insightsPreferences.funnelData.initialFunnel = initialFunnel;

    results.sort((a, b) => a.originIndex < b.originIndex);

    state.results = results;
}

function calculateMedianTime(times) {
    const allValues = [];
    for (const [key, value] of Object.entries(times)) {
        for (let i = 0; i < value; i++) {
            allValues.push(Number.parseInt(key));
        }
    }

    allValues.sort((a, b) => a < b);
    const midPoint = Math.round(allValues.length * 0.5);

    return allValues[midPoint];
}

function validateVirtualVarEditor(state) {
    const editor = state.virtualVars.editor;
    const dataKeys = state.dataKeys;
    const data = editor.varData;
    let isValid = true;

    if (!data.key) isValid = false;
    if (!data.title) isValid = false;

    if (!editor.varToEdit && dataKeys) {
        let alreadyExists = false;
        Object.keys(dataKeys).forEach((k) => {
            if (data.key && data.key === k) {
                alreadyExists = true;
            }
        });

        if (alreadyExists) {
            isValid = false;
            editor.keyWarning = virtualVarsTexts.warnings.varKeyAlreadyExists;
        } else {
            editor.keyWarning = null;
        }
    } else {
        editor.keyWarning = null;
    }

    if (data.varType === "CATEGORICAL") {
        if (data.categories.length === 0) isValid = false;

        data.categories.forEach((c, i) => {
            if (!c.value) isValid = false;
            if (!c.description) isValid = false;

            const isCurrent = editor.selectedCategory === i;
            const evaluation = Evaluate(data.varType, c.formula, data.args);
            if (evaluation.error) {
                isValid = false;
                if (isCurrent) editor.output = evaluation.error;
            } else if (isCurrent) {
                editor.output = evaluation.output;
            }

            // Warn if categories share the same value:

            let alreadyExist = false;

            for (let j = 0; j < data.categories.length; j++) {
                if (i !== j && c.value === data.categories[j].value) {
                    alreadyExist = true;
                    break;
                }
            }

            if (alreadyExist) {
                data.categories[i].warning =
                    virtualVarsTexts.warnings.categoryValueAlreadyExists;
            } else {
                delete data.categories[i].warning;
            }
        });
    } else {
        const evaluation = Evaluate(data.varType, data.formula, data.args);
        if (evaluation.error) {
            isValid = false;
            editor.output = evaluation.error;
        } else {
            editor.output = evaluation.output;
        }
    }

    // Warn if args share the same name:
    if (data.args && Object.keys(data.args).length > 0) {
        const args = Object.entries(data.args);

        for (let i = 0; i < args.length; i++) {
            let alreadyExist = false;
            for (let j = 0; j < args.length; j++) {
                if (i !== j && args[i][1].name === args[j][1].name) {
                    alreadyExist = true;
                    break;
                }
            }

            if (alreadyExist) {
                data.args[args[i][0]].warning =
                    virtualVarsTexts.warnings.argNameAlreadyExists;
                // break;
            } else {
                delete data.args[args[i][0]].warning;
            }
        }
    }

    state.virtualVars.editor.isValid = isValid;
}

/////////////////////
////// Slice ////////
/////////////////////
const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {
        resetReport: (state) => initialState,
        recordsFilterUpdated: (state, action) => {
            const updateData = action.payload;
            state.recordsFilterData[updateData.type] = updateData.data;
        },

        ///////////////////
        // Virtual Vars: //
        ///////////////////

        virtualVarsEditoReset: (state) => {
            state.virtualVars.editor = initialState.virtualVars.editor;
        },
        virtualVarsEditorChanged: (state, action) => {
            const { prop, value } = action.payload;

            state.virtualVars.editor[prop] = value;

            validateVirtualVarEditor(state);
        },
        virtualVarChanged: (state, action) => {
            const { prop, value } = action.payload;

            state.virtualVars.editor.varData[prop] = value;

            validateVirtualVarEditor(state);
        },
        virtualVarCategoryCreated: (state, action) => {
            const categories = state.virtualVars.editor.varData.categories;

            const newCategory = {
                value: "category" + categories.length,
                description: "",
                formula: "",
                args: {},
            };

            state.virtualVars.editor.varData.categories.push(newCategory);
            state.virtualVars.editor.selectedCategory = categories.length - 1;

            validateVirtualVarEditor(state);
        },
        virtualVarCategoryChanged: (state, action) => {
            const { index, prop, value } = action.payload;

            state.virtualVars.editor.varData.categories[index][prop] = value;

            validateVirtualVarEditor(state);
        },
        virtualVarCategoryDeleted: (state, action) => {
            const index = action.payload;
            const categories = state.virtualVars.editor.varData.categories;
            categories.splice(index, 1);

            state.virtualVars.editor.selectedCategory = categories.length - 1;

            validateVirtualVarEditor(state);
        },
        argAdded: (state, action) => {
            const args = state.virtualVars.editor.varData.args;

            const newName = "arg" + Object.keys(args).length;
            const id = uuidv4();

            const newArg = {
                name: newName,
                key: "",
                nullValue: "0",
                defaultValue: 0,
                valuesDict: {},
                testingValue: 0,
            };

            args[id] = newArg;

            validateVirtualVarEditor(state);
        },
        argChanged: (state, action) => {
            const { id, prop, value } = action.payload;

            const target = state.virtualVars.editor.varData;

            if (prop === "name") {
                const currentName = target.args[id].name;

                if (target.varType === "CATEGORICAL") {
                    target.categories.forEach((c) => {
                        c.formula = c.formula.replaceAll(
                            "${" + currentName + "}",
                            "${" + value + "}"
                        );
                    });
                } else {
                    target.formula = target.formula.replaceAll(
                        "${" + currentName + "}",
                        "${" + value + "}"
                    );
                }
            }

            target.args[id][prop] = value;

            validateVirtualVarEditor(state);
        },
        argDeleted: (state, action) => {
            const { id } = action.payload;
            const args = state.virtualVars.editor.varData.args;

            delete args[id];

            validateVirtualVarEditor(state);
        },
        argAddedToFormula: (state, action) => {
            const { name } = action.payload;
            const categoryInx = state.virtualVars.editor.selectedCategory;
            let target;
            if (categoryInx > -1) {
                target =
                    state.virtualVars.editor.varData.categories[categoryInx];
            } else {
                target = state.virtualVars.editor.varData;
            }

            let f = target.formula;
            f += "${" + name + "}";

            target.formula = f;

            validateVirtualVarEditor(state);
        },
        virtualVarDeleted: (state, action) => {
            const key = action.payload;

            delete state.virtualVars.varsSettings[key];
        },
        packVirtualVarsSettings: (state, action) => {
            const varsSettings = state.virtualVars.varsSettings;
            const editorVarData = state.virtualVars.editor.varData;

            try {
                const packed = JSON.parse(JSON.stringify(editorVarData));
                const args = {};
                Object.values(packed.args).forEach((arg) => {
                    const name = arg.name;
                    args[name] = { ...arg };
                    delete args[name].name;
                    delete args[name].testingValue;
                });

                packed.args = { ...args };

                if (packed.varType === "CATEGORICAL") {
                    for (let i = 0; i < packed.categories.length; i++) {
                        packed.categories[i].args = { ...args };
                    }
                    delete packed.args;
                    delete packed.formula;
                } else {
                    delete packed.categories;
                }

                let newSettings = { ...varsSettings };
                newSettings[packed.key] = packed;
                delete newSettings[packed.key].key;

                state.virtualVars.varsSettings = newSettings;
            } catch (err) {
                console.error(err);
            }
        },
        unpackVirtualVarSettings: (state, action) => {
            const varKey = action.payload;
            const settings = state.virtualVars.varsSettings[varKey];

            const varData = state.virtualVars.editor.varData;
            varData.key = varKey;
            varData.varType = settings.varType;
            varData.title = settings.title;
            varData.subtitle = settings.subtitle;
            varData.formula = settings.formula;

            let originalArgs = settings.args;

            if (settings.varType === "CATEGORICAL") {
                originalArgs = settings.categories[0].args;

                const categories = [];
                settings.categories.forEach((c) => {
                    const category = { ...c };
                    delete category.args;
                    categories.push(category);
                });

                varData.categories = categories;
            }

            if (originalArgs) {
                const args = {};

                Object.entries(originalArgs).forEach(([key, value], i) => {
                    const id = uuidv4();
                    const obj = {
                        name: key,
                        testingValue: 0,
                        ...value,
                    };

                    args[id] = obj;
                });

                varData.args = args;
            }
        },

        messageAdded: (state, action) => {
            state.virtualVars.editor.message = action.payload;
        },
        messageRemoved: (state, action) => {
            state.virtualVars.editor.message = null;
        },
    },
    extraReducers: {
        // Fetch Report:
        [fetchReport.pending]: (state, action) => {
            state.fetchStatus = "loading";
        },
        [fetchReport.fulfilled]: (state, action) => {
            state.fetchStatus = "succeeded";
            const data = action.payload;
            if (data) {
                // console.log( data );
                proccessReportData(state, data);
            }
        },
        [fetchReport.rejected]: (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.payload;
        },

        // Fetch Insights Prefrences:
        [fetchInsightsPrefs.pending]: (state, action) => {
            state.insightsPreferences.insightsPrefsFetchStatus = "loading";
        },
        [fetchInsightsPrefs.fulfilled]: (state, action) => {
            const prefs = state.insightsPreferences;
            prefs.insightsPrefsFetchStatus = "succeeded";
            const data = action.payload;
            if (data) {
                try {
                    const insightsPrefs = JSON.parse(
                        data.Bucket.insightsPreferences
                    );
                    prefs.rawPrefsData = insightsPrefs;
                } catch (e) {
                    console.error(e);
                }
            }
        },
        [fetchInsightsPrefs.rejected]: (state, action) => {
            state.insightsPreferences.insightsPrefsFetchStatus = "failed";
        },

        // Update Insights Prefrences:
        [updateInsightPrefs.pending]: (state, action) => {
            state.insightsPreferences.insightsPrefstUpdateStatus = "loading";
        },
        [updateInsightPrefs.fulfilled]: (state, action) => {
            console.log("Updated insights prefrences successfully");

            const prefs = state.insightsPreferences;
            prefs.insightsPrefsFetchStatus = "succeeded";
            const data = action.payload;
            if (data) {
                const insightsPrefs = JSON.parse(
                    data.updateBucket.insightsPreferences
                );
                prefs.rawPrefsData = insightsPrefs;
            }
        },
        [updateInsightPrefs.rejected]: (state, action) => {
            state.insightsPreferences.insightsPrefstUpdateStatus = "failed";
        },

        // Fetch Records:
        [fetchRecords.pending]: (state, action) => {
            state.fetchRecordsStatus = "loading";
        },
        [fetchRecords.fulfilled]: (state, action) => {
            state.fetchRecordsStatus = "succeeded";
            const data = action.payload;

            if (data) {
                state.recordsData = data;
            }
        },
        [fetchRecords.rejected]: (state, action) => {
            state.fetchRecordsStatus = "failed";
        },

        // Fetch VirtualVars:
        [fetchVirtualVarsSettings.pending]: (state, action) => {
            state.virtualVars.fetchVarsSettingsStatus = "loading";
        },
        [fetchVirtualVarsSettings.fulfilled]: (state, action) => {
            state.virtualVars.fetchVarsSettingsStatus = "succeeded";
            const data = action.payload;

            if (data) {
                const settings = JSON.parse(data.Bucket.virtualVarsSettings);
                state.virtualVars.varsSettings = settings;
            }
        },
        [fetchVirtualVarsSettings.rejected]: (state, action) => {
            state.virtualVars.fetchVarsSettingsStatus = "failed";
        },

        // Update VirtualVars:
        [updateVirtualVars.pending]: (state, action) => {
            state.virtualVars.updateVarsSettingsStatus = "loading";
        },
        [updateVirtualVars.fulfilled]: (state, action) => {
            state.virtualVars.updateVarsSettingsStatus = "succeeded";
            const data = action.payload;

            if (data) {
                console.log("Virtual Vars Update", data);
                // const settings = JSON.parse( data.updateBucket.virtualVarsSettings );
                // state.virtualVars.varsSettings = settings;
            }
        },
        [updateVirtualVars.rejected]: (state, action) => {
            state.virtualVars.updateVarsSettingsStatus = "failed";
        },
    },
});

export const {
    resetReport,
    recordsFilterUpdated,
    virtualVarsEditoReset,
    virtualVarsEditorChanged,
    virtualVarChanged,
    virtualVarCategoryCreated,
    virtualVarCategoryChanged,
    virtualVarCategoryDeleted,
    argAdded,
    argChanged,
    argDeleted,
    argAddedToFormula,
    virtualVarDeleted,
    packVirtualVarsSettings,
    unpackVirtualVarSettings,
    messageAdded,
    messageRemoved,
} = reportSlice.actions;

export default reportSlice.reducer;
