import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import styles from "./SearchProjectsBox.module.css";
import TextInput from "components/TextInput";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { selectUiLanguage } from "app/preferencesSlice";
import { homeTexts } from "utils/appTexts";
import { SearchProjectsInTeams } from "api/api";
import PopperPanel from "components/poppers/PopperPanel";
import Modal from "components/poppers/Modal";
import debounce from 'debounce';
import { resetFetchStatus } from "app/horizonDataSlice";

const texts = homeTexts.search;

// To Fix: 
// 1) Debounce 
// 3) Routing

export default function SearchProjectsBox() {

    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const currentTeamId = useSelector((state) => state.horizonData.currentTeamId);
    
    const inputRef = useRef();
    const history = useHistory();
    
    const [projects, setProjects] = useState([])
    const [projectIds, setProjectIds] = useState([])
    const [selectedProjectId, setSelectedProjectId] = useState('')
    const [stringQuery, setStringQuery] = useState('')
    const [highlightedProjectId, setHighlightedProjectId] = useState('')
    const [inputInFocus, setInputInFocus] = useState(false)
    const [isSearching, setIsSearching] = useState( false );

    const debouncedSearch = useMemo( () => 
        debounce( async ( teamId, query ) => {
            const res = await SearchProjectsInTeams(teamId, query);
            setIsSearching( false );
            if (res && res.data && res.data.projects && res.data.projects.length > 0) {
                setProjects(res.data.projects)
                setProjectIds(res.data.projects.map(p => p._id))
            } else {
                setProjects([])
                setProjectIds([])
            }
        }, 500 ), []
    )

    useEffect( () => {

        dispatch( resetFetchStatus( 'fetchProjectByIdStatus' ) );

    }, [dispatch])

    useEffect( () => {

        if ( projects.length > 0 && highlightedProjectId === '') {
            setHighlightedProjectId(projects[0]._id)
        }

    }, [highlightedProjectId, projects])

    useEffect( () => {
        if (stringQuery === '') {
            setProjects([])
            setHighlightedProjectId('')
        } else if (currentTeamId && stringQuery.length > 3) {
            setIsSearching( true );
            debouncedSearch( currentTeamId, stringQuery );
        }

        return () => debouncedSearch.clear();

    }, [stringQuery, currentTeamId, debouncedSearch ]);

    useEffect(()=>{
        if (selectedProjectId !== '' && selectedProjectId !== null) {
            setInputInFocus( false );
            setTimeout( () => {
                history.push( `/results/${selectedProjectId}` ); 
            }, 300)
        }
    }, [selectedProjectId, history]);
    
    const handleInputChange = useCallback( (op) => {
        setStringQuery(op.target ? op.target.value : "")
    }, []);

    const handleInputBlur = useCallback( () => {
        setTimeout( () => setInputInFocus(false), 50 )
    }, [])

    const handleInputFocus = useCallback( () => {
        setInputInFocus(true)
    }, [])

    const handleInputClick = useCallback( () => {
        setInputInFocus(true)
    }, [])

    const handleInputArrowUp = useCallback( () => {
        const index = projectIds.indexOf(highlightedProjectId)
        if (index > 0 ) {
            setHighlightedProjectId(projectIds[index - 1])
        }
    }, [projectIds, highlightedProjectId])

    const handleInputArrowDown = useCallback( () => {
        const index = projectIds.indexOf(highlightedProjectId)
        if (index < projectIds.length - 1 ) {
            setHighlightedProjectId(projectIds[index + 1])
        }
    }, [projectIds, highlightedProjectId])

    const handleProjectHightlighted = useCallback( (project) => {
        if (project._id) {
            setHighlightedProjectId(project._id)
        }
    }, [])
    
    const handleProjectSelected = useCallback( (project) => {
        if (project._id) {
            setSelectedProjectId(project._id)
        }
    }, [])

    const handleInputEnter = useCallback( () => {
        if (highlightedProjectId !== '') {
            setSelectedProjectId(highlightedProjectId)
        }
    }, [highlightedProjectId])

    return (
        <>
            <div ref={inputRef} className={ styles.inputCont }>
                <TextInput
                    name="search"
                    theme="BorderBottom"
                    dir={lang === "en" ? "ltr" : "rtl"}
                    contStyle={{ width: '100%' }}
                    fieldStyle={{ padding: '28px 0', paddingInlineStart: 48 }}
                    placeholder={texts.placeholder[lang]}
                    iconBefore='search'
                    iconStyle={{fill: 'var(--color-type-low-emphasis)'}}
                    labelStyle={{ font: 'var( ----font-btn )' }}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onFocus={handleInputFocus}
                    onClick={handleInputClick}
                    onPressArrowUp={handleInputArrowUp}
                    onPressArrowDown={handleInputArrowDown}
                    onReturn={handleInputEnter}
                />
            </div>

            <Modal 
                isActive={inputInFocus} 
                bgBlur={"low"} 
                onClose={()=>{}}
                blockBgClicks={ false }
            >
                <PopperPanel referenceElement={inputRef.current} >
                    {
                        projects.length > 0 ?
                        <div className={styles.listContainer}>
                            <ul className={styles.list}>
                                {
                                    projects.map((project, i) => {
                                        return (
                                            <ProjectItem
                                                project={project}
                                                stringQuery={stringQuery}
                                                key={project._id}
                                                isHighlight={project._id === highlightedProjectId}
                                                onProjectSelected={handleProjectSelected}
                                                onProjectHighlight={handleProjectHightlighted}
                                            />
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        :
                        <p className={ styles.inputMessage }> 
                        {
                            isSearching ? '...' :
                            stringQuery.length < 4 ?
                            texts.noQuaryMsg[ lang ] :
                            texts.noResultsMsg[ lang ]
                        }
                        </p>
                    }
                </PopperPanel>
            </Modal>
        </>
    );
}

function ProjectItem({ project, stringQuery, onProjectSelected, onProjectHighlight, onMouseEnter, isHighlight }) {
    
    const itemRef = useRef();
    
    useEffect(()=>{
        if (isHighlight) itemRef.current.scrollIntoView({behavior: "smooth", block: "nearest"})
    }, [isHighlight, itemRef])

    return (
        <li
            className={isHighlight ? styles.listItemHighlight : styles.listItem}
            onClick={() => {onProjectSelected(project)}}
            onMouseEnter={() => {onProjectHighlight(project)}}
            ref={itemRef}
        >
            <span className={styles.iconSpan}> {project.icon} </span>
            <span className={styles.nameSpan}> {
                project.name.split(stringQuery).map((part, i) => {
                    return (
                        <span key={"a_" + project._id + "_" + i}>
                        {i !== 0 && <strong>{stringQuery}</strong>}
                        {part}
                        </span>)
                })
            } </span>
            <span className={styles.sourceNameSpan}> {
                project.sourceName.split(stringQuery).map((part, i) => {
                    return (
                        <span key={"b_" + project._id + "_" + i}>
                        {i !== 0 && <strong>{stringQuery}</strong>}
                        {part}
                    </span>)
                })
            } </span>
        </li>
    )
}