import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { milgoUser } from 'features/milgo_survey/milgoReduxActions';
import * as Api from '../../api/api';
import { LocalStorage, SessionStorage } from '../../utils/cacheManager';

const initialState = {
    mainUser: {
        isLoggedIn: false,
        token: null,
        id: null,
        name: null,
        email: null,
        image: null,
        loginStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        loginError: '',
    },
    milgoUser: milgoUser.initState,
    userPanelActive: false,
};

export const authenticateUser = createAsyncThunk(
    'users/authenticateUser',
    async (token, thunkApi) => {
        Api.SetToken(token);

        // @TEMP read data from cache instead of waiting for the server. (Use only during development)
        // const cachedData = SessionStorage.Read(SessionStorage.keys.USER_DATA);
        // if (cachedData) {
        //     return cachedData;
        // }

        const response = await Api.AuthenticateUser();

        if (response.error) {
            console.error(
                'Error while validating user. Details: ',
                response.error
            );
            return thunkApi.rejectWithValue(response.error.statusText);
        } else if (response.data.user) {
            SessionStorage.Save(SessionStorage.keys.USER_DATA, response.data);
            return { token: token, userData: response.data.user };
        } else {
            console.error('User authentication resoponded with null item.');
            LocalStorage.Delete(LocalStorage.keys.TOKEN);
            return thunkApi.rejectWithValue('TOKEN_NOT_VALID');
        }
    }
);

export const login = createAsyncThunk(
    'users/login',
    async (loginData, thunkApi) => {
        const response = await Api.Login(loginData.email, loginData.password);

        if (response.error) {
            console.error('Error while login. Details: ', response.error);
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            return response.data;
        }
    }
);

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        userPanelActivation: (state, action) => {
            state.userPanelActive = action.payload;
        },
        resetMainUser: (state, action) => {
            state.mainUser = { ...initialState.mainUser };
        },
        ...milgoUser.actions,
    },
    extraReducers: {
        [login.pending]: (state, action) => {
            state.mainUser.loginStatus = 'loading';
            state.mainUser.loginError = '';
        },
        [login.fulfilled]: (state, action) => {
            const data = action.payload;
            if (data && data.userData) {
                state.mainUser.loginStatus = 'succeeded';

                state.mainUser.token = data.userData.token;
                state.mainUser.id = data.userData.item.id;
                state.mainUser.name = data.userData.item.name;
                state.mainUser.email = data.userData.item.email;
                state.mainUser.image = data.userData.item.image;

                state.mainUser.isLoggedIn = true;

                LocalStorage.Save(LocalStorage.keys.TOKEN, data.userData.token);

                Api.SetToken(data.userData.token);
            } else {
                state.mainUser.loginStatus = 'failed';
                state.mainUser.loginError = 'failed_to_authenticate';
            }
        },
        [login.rejected]: (state, action) => {
            state.mainUser.loginStatus = 'failed';
            state.mainUser.loginError = action.payload;
        },

        [authenticateUser.pending]: (state, action) => {},
        [authenticateUser.fulfilled]: (state, action) => {
            const { userData, token } = action.payload;

            state.mainUser.token = token;
            state.mainUser.id = userData.id;
            state.mainUser.name = userData.name;
            state.mainUser.image = userData.image;
            state.mainUser.email = userData.email;

            state.mainUser.isLoggedIn = true;
        },
        [authenticateUser.rejected]: (state, action) => {
            state.mainUser.loginStatus = 'failed';
            console.log(action.payload);
        },
    },
});

export const { userPanelActivation, resetMainUser } = usersSlice.actions;
export default usersSlice.reducer;
