import React from 'react';
import ProjectItem from './ProjectItem';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';
import styles from './QuickAccessProjectsRow.module.css';
import { homeTexts } from '../../utils/appTexts';

export default function QuickAccessProjectsRow({ projects }) {
    const lang = useSelector(selectUiLanguage);

    return (
        <div className={styles.mainCont}>
            <p className={styles.labelHeadline}> {homeTexts.projectsView.quickAccess[lang]} </p>
            <div className={styles.rowCont}>
                {projects && projects.map(project => {
                    return <ProjectItem
                        key={project.id}
                        data={project} 
                        itemIndex={0}
                    />
                })}
            </div>
        </div>
    )
}