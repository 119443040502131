import React, { useMemo } from 'react';
import styles from './ActivityIndicator.module.css';
import { motion } from 'framer-motion';

/**
 * 
 * @param {number} size Size in pixels of loader  
 * @param {color} color  
 * @param {object} style Inline style  
 */
export default function ActivityIndicator({size = 64, color = 'black', style}) {

    const transition = useMemo( () => ({ 
        duration: 3, 
        times: [0, 0.25, 0.5, 0.75, 1], 
        loop: Infinity, 
        ease: ['linear','easeOut','linear', 'easeOut' ]
    }), [])


    const bw = useMemo( () => { 

        let s;
        if( size <= 16 ) s = 1;
        else if( size <= 32 ) s = 2;
        else if( size <= 64 ) s = 3;
        else s = 4;
        return s;

    }, [size]);

    return (

        <motion.div 
            className={ styles.mainCont }
            animate={{ 
                rotate: [0,90,180,270,360],
                scale: [1,0.95,1,0.95,1]
            }}
            transition={ transition }
            style={{ width: size, height: size, ...style}}
        >
            <div className={ styles.circle } style={{ borderColor :color, borderWidth: bw}}/>
            <div className={ styles.midLine } style={{ backgroundColor:color, height: bw, top: `calc( 50% - ${bw*0.5}px)`}}/>
            <motion.div
                className={ styles.fill }
                animate={{ 
                    scaleY: [1,-1,1,-1,1],
                }}
                transition={ transition }
                style={{ backgroundColor:color}}
            />
        </motion.div>

    )
}