import React, { useCallback, useRef, useState } from 'react';
import styles from './NotebookSection.module.css';
import IconButton from 'components/IconButton';
import NewSectionPopper from './NewSectionPopper';
import SectionOptions from './SectionOptions';
import CollapseButton from 'components/CollapseButton';
import { Editable } from 'components/Editable';

import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { notebookTexts } from 'utils/appTexts';
import { Editor } from '@tinymce/tinymce-react';
import { editorFocused, sectionChanged } from '../notebookSlice';
import { Draggable } from 'react-beautiful-dnd';

const texts = notebookTexts.content.section;

export default function NotebookSection({id, index}) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const isLocked = useSelector( state => state.notebook.remoteData.isLocked );
    const data = useSelector( state => {
        if( !id ) return null; 
        return state.notebook.editor.sectionsData[id]
    });

    const [isHovered, setIsHovered] = useState( false );
    const [isCollapsed, setIsCollapsed] = useState( false );

    const handleEdit = useCallback( (content, editor) => {
        
        if( !isLocked ) {            
            dispatch( sectionChanged( {sectionId:id, prop: 'content', value: content } ) );
        }

    },[dispatch, id, isLocked])

    return (
        <Draggable draggableId={id} index={index} onBeforeCapture={ ()=> {console.log("before")}}>
            {(provided, snapshot) => (
                <div 
                    ref={ provided.innerRef } 
                    { ...provided.draggableProps }
                    className={ styles.sectionCont }
                    onMouseEnter={ () => setIsHovered( true )}
                    onMouseLeave={ () => setIsHovered( false )}
                    onBlur={ () => setIsHovered( false )}
                >
                    <div className={ styles.preToolsCont}>
                        <div 
                            className={ styles.dragHandle } 
                            style={{visibility: isHovered ? 'unset' : 'hidden'}}
                            {...provided.dragHandleProps}
                            tabIndex="-1" // exclued from tab focus
                        />
                        <CollapseButton
                            isCollapsed={ isCollapsed }
                            display={ isHovered }
                            onToggle={ () => setIsCollapsed( !isCollapsed ) }
                        />
                    </div>

                    <Header id={ id } data={ data } isHovered={ isHovered } lang={ lang }/>
                    {
                        !isCollapsed &&
                        <div className={ styles.editorCont }>
                            <Editor 
                                apiKey="ay5zehwgcz9v6ecka2c7i9wuzc8nclzai43i5fr9thlh34bv"
                                value={ data.content }
                                initialValue={ data.content }
                                inline={ true }
                                init={{
                                    setup: (editor) => {
                                        editor.on('keydown', e => {
                                            if( e.key === 'Tab' ) { e.preventDefault() }
                                        })
                                    },
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap preview',
                                        // 'searchreplace visualblocks code fullscreen',
                                        // 'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        `formatselect | bold italic underline backcolor | link image |
                                        alignleft aligncenter alignright alignjustify |
                                        bullist numlist outdent indent | removeformat`,
                                    contextmenu: 'link | copy cut | bold',
                                    block_formats: `${texts.styleFormats.normalText[ lang ]}=p;${texts.styleFormats.heading[ lang ]}=h3`,
                                    color_cols: 4,
                                    color_map: [
                                        "#D0E3FF", "col1",
                                        "#D6F9DE", "col2",
                                        "#FFE4D1", "col3",
                                        "#E8D6FF", "col4",
                                        "#FFE1E1", "col5",
                                        "#CBF9F3", "col6",
                                        "#FFEEBB", "col7",
                                        "#C8C8Cd", "col8",
                                    ]
                                }}
                                onEditorChange={handleEdit}
                                onFocus={ () => dispatch( editorFocused( true ) ) }
                                onBlur={ () => dispatch( editorFocused( false ) ) }
                            />
                        </div>
                    }
                    <Footer 
                        isHovered={ isHovered } 
                        isCollapsed={ isCollapsed }
                        index={ index }
                        onSectionAdded={ () => setIsHovered( false )}
                    />
                </div> 
            )}
        </Draggable>
    )
}

const Header = ({id, data, isHovered, lang}) => {

    const dispatch = useDispatch();

    const handleTitleChange = useCallback( (value) => {

        dispatch( sectionChanged({ sectionId: id, prop: 'title', value }))

    }, [dispatch, id])

    return (            
        <div className={ styles.headerCont }>
            <Editable 
                className={ styles.sectionTitle }
                placeholder={ texts.titlePlaceholder[ lang ]}
                value={ data.title || "" }
                onChange={ handleTitleChange }
            />
            {
                <SectionOptions
                    id={ id }
                    display={ isHovered }
                    onDone={ () => {} }
                />
            }
        </div>
    )
}

const Footer = ({isHovered, isCollapsed, index, onSectionAdded}) => {

    const addBtnRef = useRef()
    const [addPopperActive, setAddPopperActive] = useState( false );

    const handleSelect = useCallback( () => {
        setAddPopperActive( false )
        onSectionAdded();
    }, [onSectionAdded])

    return (
        <div 
            className={ styles.footerCont }
            style={{
                marginBottom: isCollapsed ? 12 : 24
            }}
        >
            {
                isHovered && 
                <div 
                    ref={ addBtnRef }
                    className={ styles.newSectionBtnCont }
                >
                    <IconButton
                        name="plus"
                        theme="Outlined"
                        onClick={ () => setAddPopperActive( true ) }
                    />
                </div>
            }
            <NewSectionPopper
                referenceElement={ addBtnRef.current }
                isActive={ addPopperActive }
                targetIndex={ index + 1 }
                onSelect={ handleSelect }
                onDismiss={ () => setAddPopperActive( false ) }
            />

        </div>
    )
}