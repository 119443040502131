import React from 'react';
import styles from './PageNotFound.module.css';
import { pageNotFound } from '../../utils/appTexts';
import Emoji from '../../components/Emoji';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';

export default function PageNotFound() {

    const lang = useSelector( selectUiLanguage );
    const history = useHistory()

    return (
        <div id={ styles.pageNotFoundContainer }>
            <h1>
                <Emoji symbol="🧘🏼‍♂️" style={{fontSize: 64}}/>
                
            </h1>
            <h1>
                { pageNotFound.title[ lang ] }
            </h1>
            <Button
                label={ pageNotFound.goHomeBtn[ lang ]}
                theme='Plain'
                onClick={ () => history.push( '/home' ) }
            />
        </div>
    )
}