import React, { useMemo, useCallback } from 'react';
import propsStyles from '../SurveyEditorProperties.module.css';
import Checkbox from 'components/Checkbox';
import Dropdown from 'components/DropDown';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import {
    openItemAddedToPage,
    pageItemDeleted,
    selectPageProperty,
} from 'features/survey_editor/surveyEditorSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import { OPEN_ITEM } from 'features/survey_editor/surveyEditorConfiguration';

const generalTexts = surveyEditorTexts.properties.pageSettings;
const texts = generalTexts.settingsModules.multipleChoice;

export default function MultipleChoiceSettingsModule({
    settings,
    updateSettings,
}) {
    const lang = useSelector(selectUiLanguage);
    const dispatch = useDispatch();
    const currentPage = useSelector(
        (state) => state.surveyEditor.present.display.currentPage
    );
    const itemsOrder = useSelector((state) => {
        if (!currentPage) return null;
        return selectPageProperty(state, currentPage, 'itemsOrder');
    });

    const {
        showSubtitle,
        minChoices,
        choicesLimit,
        randomizeItems,
        autoContinue,
        showOpenItem,
        // centeredAnswers,
        isNumeric,
    } = useMemo(() => {
        if (!settings) return {};

        return { ...settings.general };
    }, [settings]);

    const minChoicesOptions = useMemo(() => {
        const minOptions = [{ key: '0', label: '0' }];
        if (itemsOrder) {
            for (let i = 1; i < itemsOrder.length; i++) {
                const op = { key: i.toString(), label: i.toString() };
                minOptions.push(op);
            }
        }

        return minOptions;
    }, [itemsOrder]);

    const choicesLimitOptions = useMemo(() => {
        let min = 1;
        if (minChoices) {
            min = minChoices;
        }

        const limitOptions = [];
        if (itemsOrder) {
            for (let i = min; i < itemsOrder.length; i++) {
                const op = { key: i.toString(), label: i.toString() };
                limitOptions.push(op);
            }
        }

        limitOptions.push({ key: '-1', label: texts.noLimit[lang] });
        return limitOptions;
    }, [itemsOrder, minChoices, lang]);

    const handleMinChoicesSelection = useCallback(
        (op) => {
            if (choicesLimit && choicesLimit < op.key && choicesLimit !== -1) {
                updateSettings({
                    data: {
                        minChoices: parseInt(op.key),
                        choicesLimit: parseInt(op.key),
                    },
                });
            } else {
                updateSettings({ prop: 'minChoices', value: parseInt(op.key) });
            }
        },
        [updateSettings, choicesLimit]
    );

    const handleChoicesLimitSelection = useCallback(
        (op) => {
            const data = {};

            if (parseInt(op.key) !== 1) {
                // Disable auto continue if more then 1 choice is allowed.
                data.autoContinue = false;
            }

            data.choicesLimit = parseInt(op.key);

            updateSettings({ data });
        },
        [updateSettings]
    );

    const handleOpenItem = useCallback(
        (isChecked) => {
            updateSettings({ prop: 'showOpenItem', value: isChecked });

            if (isChecked) {
                dispatch(openItemAddedToPage({ pageId: currentPage }));
            } else {
                dispatch(
                    pageItemDeleted({ pageId: currentPage, itemId: OPEN_ITEM })
                );
            }
        },
        [updateSettings, currentPage, dispatch]
    );

    if (!settings) return null;

    return (
        <>
            <p className={propsStyles.sectionsLabel}>
                {texts.ansCountSection[lang]}
            </p>
            <Dropdown
                label={texts.minChoices[lang]}
                options={minChoicesOptions}
                value={minChoices.toString()}
                contStyle={{ marginBottom: 12 }}
                onChange={handleMinChoicesSelection}
            />
            <Dropdown
                label={texts.choicesLimit[lang]}
                options={choicesLimitOptions}
                value={choicesLimit.toString()}
                contStyle={{ marginBottom: 12 }}
                onChange={handleChoicesLimitSelection}
            />
            <Checkbox
                size="s"
                className={propsStyles.checkbox}
                label={generalTexts.randomizeItems[lang]}
                isChecked={randomizeItems}
                onChange={(isChecked) =>
                    updateSettings({ prop: 'randomizeItems', value: isChecked })
                }
            />
            <div className={propsStyles.separationLine} />
            <Checkbox
                size="s"
                className={propsStyles.checkbox}
                label={generalTexts.showSubtitleLabel[lang]}
                isChecked={showSubtitle}
                onChange={(isChecked) =>
                    updateSettings({ prop: 'showSubtitle', value: isChecked })
                }
            />
            <Checkbox
                size="s"
                className={propsStyles.checkbox}
                label={texts.autoContinue[lang]}
                isChecked={autoContinue}
                disabled={choicesLimit !== 1}
                onChange={(isChecked) =>
                    updateSettings({ prop: 'autoContinue', value: isChecked })
                }
            />
            <Checkbox
                size="s"
                className={propsStyles.checkbox}
                label={texts.showOpenItem[lang]}
                isChecked={showOpenItem}
                onChange={handleOpenItem}
            />
            <Checkbox
                size="s"
                className={propsStyles.checkbox}
                label={texts.isNumeric[lang]}
                isChecked={isNumeric}
                onChange={(isChecked) =>
                    updateSettings({ prop: 'isNumeric', value: isChecked })
                }
            />
            {/* @TODO: Remove after Pakadim experiment:
                <Checkbox
                    size="s"
                    className={propsStyles.checkbox}
                    label={texts.centeredAnswers[lang]}
                    isChecked={centeredAnswers}
                    onChange={(isChecked) =>
                        updateSettings({
                            prop: 'centeredAnswers',
                            value: isChecked,
                        })
                    }
                />
                */}
        </>
    );
}
