import React, { useCallback, useRef, useState } from 'react';
import styles from './ConditionBuilder.module.css';
import IconButton from 'components/IconButton';
import SelectionPopper from 'components/poppers/SelectionPopper';
import { surveyEditorTexts } from 'utils/appTexts';

const texts = surveyEditorTexts.properties.logic.itemEditor.condition;

export default function AddConditionItem({ display = true, onAdd }) {

    const btnRef = useRef();

    const [isPopperActive, setIsPopperActive] = useState( false );

    const handleAdd = useCallback( (selected) => {

        onAdd( selected );
        setIsPopperActive( false );

    }, [onAdd])

    return (
        <>
            <div ref={ btnRef } className={ styles.addFragmentBtnCont }>
                <IconButton
                    name='plus'
                    tooltip={ texts.addFragmentTooltip[ 'en' ] }
                    size='s'
                    bgStyle={{
                        opacity: display ? 1 : 0.3
                    }}
                    onClick={ () => setIsPopperActive( true ) }
                />
            </div>
            <SelectionPopper
                isActive={ isPopperActive }
                referenceElement={ btnRef.current }
                onDismiss={ () => setIsPopperActive( false ) }
                onSelect={ handleAdd }
            >
                <span 
                    key='rule'
                    className={ styles.fragmentSelection }
                >
                    <span className={ styles.ruleIcon }>==</span> 
                    { texts.rule[ 'en' ] } 
                </span>
                <span 
                    key='group'
                    className={ styles.fragmentSelection }
                >
                    <span className={ styles.groupIcon}>—</span> 
                    { texts.group[ 'en' ] } 
                </span>

            </SelectionPopper>
        </>
    )
}