import Button from 'components/Button';
import moment from 'moment';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { surveyEditorTexts } from 'utils/appTexts';
import { downloadFile } from 'utils/miscHelpers';

const texts = surveyEditorTexts.properties.publishAndExport;

export default function DownloadBackup({ lang }) {
    const survey = useSelector((state) => state.surveyEditor.present.survey);

    const handleDownload = useCallback(() => {
        const formatedDate = moment(new Date()).format('DD-MM-YYYY_HH-mm-ss');
        const fileName = `${survey.sourceName} ${formatedDate}.hrz`;

        downloadFile(JSON.stringify(survey, null, 4), fileName, 'text/plain');
    }, [survey]);

    return (
        <Button
            label={texts.downloadBackupBtn[lang]}
            iconBefore="download"
            theme="Outlined"
            bgStyle={{
                marginTop: 8,
                width: '100%',
            }}
            onClick={handleDownload}
        />
    );
}
