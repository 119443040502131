import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import styles from './RichTextStyles.module.css';

import { EditorState, Modifier } from 'draft-js';
import 'draft-js/dist/Draft.css';

import PopperPanel from 'components/poppers/PopperPanel';
import Modal from 'components/poppers/Modal';
import Button from 'components/Button';
import DropDown from 'components/DropDown';
import TextInput from 'components/TextInput';
import IconButton from 'components/IconButton';
import ConditionRule from 'features/survey_editor/logic/condition_comps/ConditionRule';
import SelectionPopper from 'components/poppers/SelectionPopper';

import { getNested } from 'utils/miscHelpers';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { selectUiLanguage } from 'app/preferencesSlice';
import { componentsTexts } from 'utils/appTexts';
import { textDirection } from 'utils/stylingTools';

const texts = componentsTexts.richText.dynamicTextEntityEditor;

/* See documentation in DynamicText in survey-client source */
const types = {
    SIMPLE: 'simple',
    CONDITIONAL: 'conditional',
};

const underline = '1px solid var(--color-primary-medium)';
const noUnderline = '1px solid transparent';
const activeColor = 'var(--color-primary-medium)';
const inactiveColor = 'var(--color-type-medium-emphasis)';

export default function DynamicTextEntityEditor({
    isActive,
    referenceElement,
    entity,
    editorState,
    onModify,
    onClose,
}) {
    const lang = useSelector(selectUiLanguage);
    const [type, setType] = useState(types.SIMPLE);

    const data = useMemo(() => {
        if (entity && entity.data) return entity.data;
        return {};
    }, [entity]);

    useEffect(() => {
        if (data.type) {
            setType(data.type);
        }
    }, [data.type]);

    const Module = useMemo(() => {
        if (type === types.SIMPLE) return SimpleModule;
        if (type === types.CONDITIONAL) return ConditionalModule;

        return <></>;
    }, [type]);

    const handleModify = useCallback(
        (contentStateWithEntity) => {
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const contentStateWithDynamicText = Modifier.applyEntity(
                contentStateWithEntity,
                editorState.getSelection(),
                entityKey
            );
            const newEditorState = EditorState.push(
                editorState,
                contentStateWithDynamicText,
                'apply-entity'
            );
            onModify(newEditorState);

            onClose();
        },
        [onModify, onClose, editorState]
    );

    const handleRemove = useCallback(() => {
        const selection = editorState.getSelection();

        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const newContentState = Modifier.applyEntity(
                contentState,
                selection,
                null
            );
            const newEditorState = EditorState.push(
                editorState,
                newContentState,
                'apply-entity'
            );

            onModify(newEditorState);
        }

        setType(types.SIMPLE);
        onClose();
    }, [editorState, onModify, onClose]);

    return (
        <Modal isActive={isActive} onClose={onClose} bgBlur={'low'}>
            <PopperPanel
                key={type}
                referenceElement={referenceElement}
                panelStyle={{
                    width: type === types.CONDITIONAL ? 'fit-content' : 300,
                    maxWidth: '80vw',
                    maxHeight: '60vh',
                    padding: 16,
                    backgroundColor: 'var(--color-background-mild)',
                }}
            >
                <div className={styles.dtEditorHeader}>
                    <Button
                        label={texts.modes.simple.modeName[lang]}
                        theme="Plain"
                        size="s"
                        bgStyle={{
                            marginInlineEnd: 6,
                            borderRadius: 0,
                            borderBottom:
                                type === types.SIMPLE ? underline : noUnderline,
                        }}
                        labelStyle={{
                            color:
                                type === types.SIMPLE
                                    ? activeColor
                                    : inactiveColor,
                        }}
                        onClick={() => setType(types.SIMPLE)}
                    />
                    <Button
                        label={texts.modes.conditional.modeName[lang]}
                        size="s"
                        theme="Plain"
                        bgStyle={{
                            borderRadius: 0,
                            borderBottom:
                                type === types.CONDITIONAL
                                    ? underline
                                    : noUnderline,
                        }}
                        labelStyle={{
                            color:
                                type === types.CONDITIONAL
                                    ? activeColor
                                    : inactiveColor,
                        }}
                        onClick={() => setType(types.CONDITIONAL)}
                    />
                </div>

                <p className={styles.dtModeTip}>
                    {' '}
                    {texts.modes[type].tip[lang]}
                </p>

                <Module
                    lang={lang}
                    editorState={editorState}
                    data={data}
                    onModify={handleModify}
                    onRemove={handleRemove}
                    onClose={onClose}
                />
            </PopperPanel>
        </Modal>
    );
}

const SimpleModule = ({
    lang,
    editorState,
    data,
    onModify,
    onRemove,
    onClose,
}) => {
    const surveyVariables = useSelector((state) =>
        getNested(
            state,
            'surveyEditor',
            'present',
            'survey',
            'logic',
            'variables'
        )
    );
    const [selectedVar, setSelectedVar] = useState(null);

    const varOptions = useMemo(() => {
        if (surveyVariables && surveyVariables.length > 0) {
            return surveyVariables.map((v) => ({ key: v.id, label: v.name }));
        }

        return [{ key: 'NO_VARS', label: texts.noVars[lang] }];
    }, [surveyVariables, lang]);

    useEffect(() => {
        setSelectedVar(data.id);
    }, [data.id]);

    const handleDone = useCallback(() => {
        if (surveyVariables && selectedVar && selectedVar !== 'NO_VARS') {
            const varData = surveyVariables.find((x) => x.id === selectedVar);

            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity(
                'DYNAMIC_TEXT',
                'MUTABLE',
                {
                    type: types.SIMPLE,
                    id: varData.id,
                }
            );

            onModify(contentStateWithEntity);

            setSelectedVar(null);
        } else {
            onClose();
        }
    }, [editorState, selectedVar, surveyVariables, onModify, onClose]);

    const handleRemove = useCallback(() => {
        setSelectedVar(null);
        onRemove();
    }, [onRemove]);

    return (
        <>
            <DropDown
                label={texts.selectVarLabel[lang]}
                options={varOptions}
                contStyle={{ width: '100%' }}
                value={selectedVar}
                onChange={(selected) => setSelectedVar(selected.key)}
            />
            <div
                className={styles.entityEditorFooter}
                style={{ justifyContent: 'flex-end' }}
            >
                <Button
                    label={texts.removeBtn[lang]}
                    theme="Outlined"
                    onClick={handleRemove}
                />
                <Button
                    label={texts.applyBtn[lang]}
                    bgStyle={{ marginInlineStart: 6 }}
                    onClick={handleDone}
                />
            </div>
        </>
    );
};

const emptyConditionalTextItem = {
    rule: null,
    arg: null,
    text: '',
};

const conTexts = texts.modes.conditional;

const ConditionalModule = ({
    lang,
    editorState,
    data,
    onModify,
    onRemove,
    onClose,
}) => {
    const [items, setItems] = useState([]);
    const [defaultText, setDefaultText] = useState('');
    const [varToUseAsDefault, setVarToUseAsDefault] = useState(null);
    const [itemsListId, setItemsListId] = useState(Math.random());

    useEffect(() => {
        if (!data || Object.keys(data).length === 0) {
            const selectionState = editorState.getSelection();
            const anchorKey = selectionState.getAnchorKey();
            const currentContent = editorState.getCurrentContent();
            const currentContentBlock =
                currentContent.getBlockForKey(anchorKey);
            const start = selectionState.getStartOffset();
            const end = selectionState.getEndOffset();
            const selectedText = currentContentBlock
                .getText()
                .slice(start, end);
            setDefaultText(selectedText);
        }
    }, [editorState, data]);

    useEffect(() => {
        if (data) {
            if (data.items) {
                setItems(data.items);
            } else {
                setItems([emptyConditionalTextItem]);
            }

            if (data.defaultText) {
                setDefaultText(data.defaultText);
            }

            setVarToUseAsDefault(data.varToUseAsDefault);
        }
    }, [data]);

    const handleDone = useCallback(() => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'DYNAMIC_TEXT',
            'MUTABLE',
            {
                type: types.CONDITIONAL,
                items,
                defaultText,
                varToUseAsDefault,
            }
        );

        onModify(contentStateWithEntity);

        setDefaultText('');
        setVarToUseAsDefault(null);
    }, [editorState, items, defaultText, varToUseAsDefault, onModify]);

    const handleAddItem = useCallback(() => {
        const newItems = Array.from(items);
        newItems.push(emptyConditionalTextItem);
        setItems(newItems);
    }, [items]);

    const handleChangeItem = useCallback(
        (index, data) => {
            const newItems = Array.from(items);
            newItems[index] = data;
            setItems(newItems);
        },
        [items]
    );

    const handleRemoveItem = useCallback(
        (index) => {
            const newItems = Array.from(items);
            newItems.splice(index, 1);
            setItems(newItems);
            setItemsListId(Math.random()); // Force list refresh.
        },
        [items]
    );

    return (
        <>
            <div key={itemsListId} className={styles.conditionalItemsListCont}>
                {items &&
                    items.map((item, i) => (
                        <ConditionalTextItem
                            key={'conditionalTextItem_' + i}
                            index={i}
                            lang={lang}
                            data={item}
                            onChange={(data) => handleChangeItem(i, data)}
                            onDelete={() => handleRemoveItem(i)}
                        />
                    ))}
                <IconButton
                    name="plus"
                    size="s"
                    theme="Outlined"
                    tooltip={conTexts.addItemBtnTooltip[lang]}
                    onClick={handleAddItem}
                />
            </div>
            <DefaultText
                lang={lang}
                defaultText={defaultText}
                varToUseAsDefault={varToUseAsDefault}
                onSetDefaultText={setDefaultText}
                onSetVarAsDefault={setVarToUseAsDefault}
            />

            <div
                className={styles.entityEditorFooter}
                style={{ justifyContent: 'flex-end' }}
            >
                <Button
                    label={texts.removeBtn[lang]}
                    theme="Outlined"
                    onClick={onRemove}
                />
                <Button
                    label={texts.applyBtn[lang]}
                    bgStyle={{ marginInlineStart: 6 }}
                    onClick={handleDone}
                />
            </div>
        </>
    );
};

const DefaultText = ({
    lang,
    defaultText,
    varToUseAsDefault,
    onSetDefaultText,
    onSetVarAsDefault,
}) => {
    const surveyVariables = useSelector((state) =>
        getNested(
            state,
            'surveyEditor',
            'present',
            'survey',
            'logic',
            'variables'
        )
    );

    const optionsBtnRef = useRef();
    const [isOptionsActive, setIsOptionsActive] = useState(false);
    const [mode, setMode] = useState('text');

    const varOptions = useMemo(() => {
        if (!surveyVariables) return [{ key: 'undefined', label: '...' }];
        return surveyVariables.map((v) => (
            <span
                key={v.id}
                label={v.name}
                style={{ font: 'var(--font-code)' }}
            >
                {v.name}
            </span>
        ));
    }, [surveyVariables]);

    useEffect(() => {
        if (varToUseAsDefault) setMode('variable');
    }, [varToUseAsDefault]);

    const handleVarSelection = useCallback(
        (selected) => {
            onSetVarAsDefault(selected);
        },
        [onSetVarAsDefault]
    );

    const handleModeSelection = useCallback(
        (m) => {
            setMode(m.key);
            if (m.key === 'text') {
                onSetVarAsDefault(null);
            }
            setIsOptionsActive(false);
        },
        [onSetVarAsDefault]
    );

    return (
        <div className={styles.defaultDataCont}>
            {mode === 'variable' ? (
                <DropDown
                    label={conTexts.defaultVariableLabel[lang]}
                    value={varToUseAsDefault}
                    options={varOptions}
                    contStyle={{ width: '100%' }}
                    dir={textDirection(lang).direction}
                    onChange={handleVarSelection}
                >
                    {varOptions}
                </DropDown>
            ) : (
                <TextInput
                    label={conTexts.defaultTextLabel[lang]}
                    value={defaultText || ''}
                    dir={lang === 'en' ? 'ltr' : 'rtl'}
                    onChange={(e) => onSetDefaultText(e.target.value)}
                />
            )}
            <div ref={optionsBtnRef} style={{ marginInlineStart: 4 }}>
                <IconButton
                    name="options"
                    theme="Plain"
                    // size = 's'
                    colorSet="Grayscale"
                    onClick={() => setIsOptionsActive(true)}
                    tooltip={conTexts.defaultOptions.tooltip[lang]}
                    tabIndex="-1"
                />
            </div>

            <SelectionPopper
                referenceElement={optionsBtnRef.current}
                isActive={isOptionsActive}
                options={[
                    { key: 'text', label: conTexts.defaultOptions.text[lang] },
                    {
                        key: 'variable',
                        label: conTexts.defaultOptions.variable[lang],
                    },
                ]}
                onDismiss={() => {
                    setIsOptionsActive(false);
                }}
                onSelect={handleModeSelection}
            />
        </div>
    );
};

const ConditionalTextItem = ({ index, lang, data, onChange, onDelete }) => {
    const [rule, setRule] = useState(null);
    const [args, setArgs] = useState(null);
    const [text, setText] = useState('...');

    const [changesMade, setChangesMade] = useState(false);

    // Handle initial data:
    useEffect(() => {
        if (data) {
            if (text === '...' && data.text) setText(data.text);

            if (!args && data.args) {
                setArgs(data.args);
            }

            if (!rule) {
                if (data.rule) {
                    setRule(data.rule);
                } else {
                    const arg_1_id = uuidv4();
                    const arg_2_id = uuidv4();

                    setRule({
                        '==': [{ var: arg_1_id }, { var: arg_2_id }],
                    });

                    setArgs({
                        [arg_1_id]: {},
                        [arg_2_id]: {},
                    });

                    setChangesMade(true);
                }
            }
        }
    }, [data, text, rule, args]);

    // Respond to local changes
    useEffect(() => {
        if (changesMade) {
            onChange({
                rule,
                args,
                text,
            });

            setChangesMade(false);
        }
    }, [changesMade, rule, args, text, onChange]);

    const handleTextChange = useCallback((e) => {
        setText(e.target.value);
        setChangesMade(true);
    }, []);

    const handleRuleChange = useCallback((rule) => {
        setRule(rule);
        setChangesMade(true);
    }, []);

    const handleArgChange = useCallback(
        (arg) => {
            const newArgs = { ...args };
            newArgs[arg.id] = arg;
            setArgs(newArgs);
            setChangesMade(true);
        },
        [args]
    );

    return (
        <div className={styles.conditionalItemCont}>
            <div
                style={{
                    flex: 2,
                }}
            >
                <label className={styles.ifLabel}>
                    {index === 0
                        ? conTexts.ifLabel[lang]
                        : conTexts.elseIfLabel[lang]}
                </label>
                {rule && (
                    <ConditionRule
                        contStyle={{
                            width: 400,
                            border: 'none',
                            justifyContent: 'center',
                            margin: '4px 0',
                            padding: 0,
                        }}
                        showDelete={false}
                        rule={rule}
                        args={args}
                        onChange={handleRuleChange}
                        onArgChange={handleArgChange}
                    />
                )}
            </div>
            <TextInput
                label={conTexts.textLabel[lang]}
                contStyle={{
                    flex: 1,
                    margin: '0 12px',
                }}
                fieldStyle={{
                    height: 32,
                }}
                value={text}
                dir={lang === 'en' ? 'ltr' : 'rtl'}
                onChange={handleTextChange}
            />
            <IconButton
                name="delete"
                tooltip={conTexts.deleteItemBtnTooltip[lang]}
                theme="Plain"
                colorSet="Grayscale"
                size="s"
                onClick={onDelete}
            />
        </div>
    );
};
