import { useState, useEffect, useCallback } from 'react'

export function useKeyPress(targetKey, callback) {

    const [keyPressed, setKeyPressed] = useState(false);

    const downHandler = useCallback( ( event ) => {
        const { key, keyCode } = event;

        if (key === targetKey || keyCode === targetKey ) {
            setKeyPressed(true);
            if( callback ) callback( event );
        }
    },[callback, targetKey])

    const upHandler = useCallback( ( event ) => {
        const { key, keyCode } = event;
        if (key === targetKey || keyCode === targetKey ) {
            setKeyPressed(false);
        }
    }, [targetKey])


    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
        };
    }, [downHandler, upHandler]);

    return keyPressed;
}