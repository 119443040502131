import Button from 'components/Button';
import Modal from 'components/poppers/Modal';
import PopperPanel from 'components/poppers/PopperPanel';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import { textColors } from 'features/survey_editor/surveyEditorConfiguration';
import { useCallback, useMemo, useRef, useState } from 'react';
import styles from './RichTextStyles.module.css';
import { componentsTexts } from 'utils/appTexts';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';

const texts = componentsTexts.richText.richTextToolbar;

export default function TextColorPicker({
    onModify,

    editorState,
}) {
    const lang = useSelector(selectUiLanguage);
    const [isPickerActive, setIsPickerActive] = useState(false);
    const btnRef = useRef();

    const currentColor = useMemo(() => {
        const currentStyle = editorState.getCurrentInlineStyle();
        const color = currentStyle.find((x) => x.slice(0, 6) === 'color_');
        if (color) return textColors[color].color;
        return `var(--color-type-high-emphasis)`;
    }, [editorState]);

    const handlePick = useCallback(
        (colorKey) => {
            const selection = editorState.getSelection();

            // Let's just allow one color at a time. Turn off all active colors.
            const nextContentState = Object.keys(textColors).reduce(
                (contentState, color) => {
                    return Modifier.removeInlineStyle(
                        contentState,
                        selection,
                        color
                    );
                },
                editorState.getCurrentContent()
            );

            let newtEditorState = EditorState.push(
                editorState,
                nextContentState,
                'change-inline-style'
            );

            if (colorKey !== 'remove_color') {
                newtEditorState = RichUtils.toggleInlineStyle(
                    newtEditorState,
                    colorKey
                );
            }

            onModify(newtEditorState);

            // setIsPickerActive(false);
        },
        [editorState, onModify]
    );

    return (
        <>
            <Button
                ref={btnRef}
                label="●"
                tooltip={texts.textColorTooltip[lang]}
                theme="Plain"
                colorSet="Grayscale"
                bgStyle={{
                    minWidth: 32,
                    minHeight: 32,
                    borderRadius: 16,
                    padding: 0,
                }}
                labelStyle={{
                    fontSize: 26,
                    lineHeight: 1,
                    color: currentColor,
                }}
                onClick={() => setIsPickerActive(!isPickerActive)}
            />
            <Modal
                isActive={isPickerActive}
                onClose={() => setIsPickerActive(false)}
                bgBlur="low"
            >
                <PopperPanel
                    referenceElement={btnRef.current}
                    panelStyle={{
                        width: 'auto',
                        padding: 8,
                        backgroundColor: 'var(--color-background-mild)',
                    }}
                    placement="bottom"
                >
                    <div className={styles.pickerCont}>
                        <div
                            key={'remove'}
                            className={styles.colorBtn}
                            style={{
                                backgroundColor: `var(--color-type-high-emphasis)`,
                            }}
                            onClick={() => handlePick('remove_color')}
                        />
                        {Object.entries(textColors).map(([key, style]) => {
                            return (
                                <div
                                    key={key}
                                    className={styles.colorBtn}
                                    style={{ backgroundColor: style.color }}
                                    onClick={() => handlePick(key)}
                                />
                            );
                        })}
                    </div>
                </PopperPanel>
            </Modal>
        </>
    );
}
