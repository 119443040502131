import React, { useMemo, useCallback, useState, useEffect } from 'react';

import styles from '../SurveyEditorProperties.module.css';
import surveySettingsStyles from './SurveySettings.module.css';
import { surveyEditorTexts } from 'utils/appTexts';
import IconButton from 'components/IconButton';
import Checkbox from 'components/Checkbox';
import FileInput from 'components/FileInput';
import ActivityIndicator from 'components/ActivityIndicator';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import {
    surveySettingsChanged,
    surveyDebugChanged,
    localChangesMade,
} from '../../surveyEditorSlice';
import { UploadMedia } from 'api/api';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
const texts = surveyEditorTexts.properties.surveySettings;

const splashTexts = texts.splashSettings;
const SplashSettings = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const previewSplash = useSelector(
        (state) => state.surveyEditor.present.survey.debug.previewSplash
    );
    const splashSettings = useSelector(
        (state) => state.surveyEditor.present.survey.settings.general.splash
    );

    const surveyName = useSelector(
        (state) => state.surveyEditor.present.survey.surveyName
    );
    const surveyLang = useSelector(
        (state) => state.surveyEditor.present.display.currentLanguage
    );

    const handleSplashSettignsChange = useCallback(
        (key, value) => {
            const newSettings = splashSettings ? { ...splashSettings } : {};
            newSettings[key] = value;
            dispatch(
                surveySettingsChanged({ prop: 'splash', value: newSettings })
            );
        },
        [dispatch, splashSettings]
    );

    const handlePreview = useCallback(() => {
        const preview = !previewSplash;
        dispatch(
            surveyDebugChanged({ property: 'previewSplash', value: preview })
        );

        if (!preview) {
            // Make sure the survey is saved with the debug state set to false:
            dispatch(localChangesMade());
        }
    }, [dispatch, previewSplash]);

    return (
        <>
            <div className={surveySettingsStyles.splashHeaderCont}>
                <h4 className={styles.modeLabel} style={{ marginBottom: 0 }}>
                    {splashTexts.title[lang]}
                </h4>
                <IconButton
                    name="preview"
                    theme="Plain"
                    tooltip={splashTexts.previewSplash[lang]}
                    colorSet={previewSplash ? 'Primary' : 'Grayscale'}
                    onClick={handlePreview}
                />
            </div>

            <Checkbox
                label={splashTexts.useSplash[lang]}
                isChecked={splashSettings && splashSettings.use}
                onChange={(isChecked) =>
                    handleSplashSettignsChange('use', isChecked)
                }
            />

            <SplashText
                lang={lang}
                splashSettings={splashSettings}
                onSplashSettingsChange={handleSplashSettignsChange}
            />
            <LogoSettings
                lang={lang}
                splashSettings={splashSettings}
                onSplashSettingsChange={handleSplashSettignsChange}
            />
        </>
    );
};

export default SplashSettings;

const LogoSettings = ({ lang, splashSettings, onSplashSettingsChange }) => {
    const surveyId = useSelector(
        (state) => state.surveyEditor.present.surveyId
    );
    const [isUploadingLogo, setIsUplaodingLogo] = useState(false);

    const logoUrl = useMemo(() => {
        return splashSettings && splashSettings.logo && splashSettings.logo[0]
            ? splashSettings.logo[0]
            : null;
    }, [splashSettings]);

    const handleFileUpload = useCallback(
        async (files) => {
            const file = files[0];
            setIsUplaodingLogo(true);
            const url = await UploadMedia(file, surveyId).catch((err) =>
                alert(`Error: ${err}`)
            );
            if (url) {
                onSplashSettingsChange('logo', [url]);
            }

            setIsUplaodingLogo(false);
        },
        [surveyId, onSplashSettingsChange]
    );

    return (
        <div
            className={surveySettingsStyles.splashLogoCont}
            style={{
                opacity: isUploadingLogo ? 0.6 : 1,
                pointerEvents: isUploadingLogo ? 'none' : 'auto',
            }}
        >
            <p className={surveySettingsStyles.logoLabel}>
                {' '}
                {splashTexts.logoLabel[lang]}{' '}
            </p>
            {isUploadingLogo ? (
                <ActivityIndicator size={16} />
            ) : (
                <>
                    {logoUrl && (
                        <a
                            className={surveySettingsStyles.logoUrlLink}
                            href={logoUrl}
                        >
                            {' '}
                            {logoUrl}{' '}
                        </a>
                    )}
                    <div className={surveySettingsStyles.changeLogoBtnsCont}>
                        <FileInput
                            textOnButton={
                                splashTexts[
                                    logoUrl ? 'replaceLogo' : 'uplaodLogo'
                                ][lang]
                            }
                            className={surveySettingsStyles.logoFileInput}
                            buttonTheme="Outlined"
                            accept=".png,.svg"
                            showFileName={false}
                            onSelect={handleFileUpload}
                        />
                        {logoUrl && (
                            <Button
                                label={splashTexts.removeLogo[lang]}
                                iconBefore="delete"
                                theme="Plain"
                                colorSet="Grayscale"
                                onClick={() =>
                                    onSplashSettingsChange('logo', [])
                                }
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

const SplashText = ({ lang, splashSettings, onSplashSettingsChange }) => {
    const surveyLang = useSelector(
        (state) => state.surveyEditor.present.display.currentLanguage
    );
    const surveyName = useSelector(
        (state) => state.surveyEditor.present.survey.surveyName
    );

    useEffect(() => {
        if (!splashSettings.text) {
            onSplashSettingsChange('text', {
                [surveyLang]: surveyName?.[surveyLang] ?? 'untitled',
            });
        }
    }, [splashSettings, surveyName]);

    if (!splashSettings.text) return null;

    return (
        <TextInput
            label={texts.splashSettings.text[lang]}
            theme="BorderBottom"
            contStyle={{ marginTop: 12 }}
            value={splashSettings.text[surveyLang] || ''}
            onChange={(e) =>
                onSplashSettingsChange('text', {
                    ...splashSettings.text,
                    [surveyLang]: e.target.value,
                })
            }
        />
    );
};
