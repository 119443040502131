import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styles from  './Analyses.module.css';
import { analysesTexts } from 'utils/appTexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { 
    fetchMethods, 
    methodSelected, 
    submitNewAnalyses,
    analysisNameChanged
 } from './analysesSlice';
import DropDown from 'components/DropDown';
import Button from 'components/Button';
import Dialog from 'components/poppers/Dialog';
import TextInput from 'components/TextInput';
import NewAnalysisField from './NewAnalysisField';
import CollapseButton from 'components/CollapseButton';
import { checkBulkScriptName, prepareDescriptiveBulkForSubmission } from './analysesUtils';

const texts = analysesTexts.sidePanel;

export default function AnalysesSidePanel( {project, dir = 'ltr' }) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const methods = useSelector( state => state.analyses.methods );
    const selectedMethod = useSelector( state => state.analyses.selectedMethod ); 
    const newAnalysisName = useSelector( state => state.analyses.newAnalysis.name );
    const submittionStatus = useSelector( state => state.analyses.submittionStatus );

    // Fetch methods if not loaded yet:
    useEffect( () => {

        if( !methods ){

            dispatch( fetchMethods() );
        }

    }, [methods, dispatch] );

    // Map methods to dropdown options:
    const methodsOptions = useMemo( 
        () => {
            if( !methods ) return [];

            return methods.map( m => (
                {
                    key: m.id, 
                    label: m[ lang === 'en'? 'name' : 'hebrewName' ]
                }
            ))
        },
        [methods, lang]
    )

    function handleMethodChange ( selected ) {

        dispatch( methodSelected( selected.key ) );
        // dispatch( analysisNameChanged( { value: selected.label } ) );
    }


    if( !methods || !project || project === '404' ) return (
        <div className={ styles.sidePanelCont } dir={ dir }>
            <p className={ styles.sidePanelTitleLabel }>{ texts.loadingMethods[ lang ] }</p>
        </div>
    )

    return (
        <div className={ styles.sidePanelCont } dir={ dir }>
            <p className={ styles.sidePanelTitleLabel }>{ texts.sidePabelTitle[ lang ] }</p>
            <DropDown
                label={ texts.methodTypeLabel[ lang ]}
                value={ selectedMethod ? selectedMethod.id : null }
                options={ methodsOptions }
                onChange={ op => handleMethodChange( op ) }
            />
            
            <Description methodData={ selectedMethod } lang={ lang }/>
            <Variables 
                key={ selectedMethod ? selectedMethod.id : '' } // key must be unique so react will re-mount the component on method change.
                project={ project } 
                methodData={ selectedMethod } 
                lang={ lang } 
                submittionStatus={ submittionStatus }
            />

            <div className={ styles.sidePanelLine }/>

            { selectedMethod && 
                <TextInput
                    type='text'
                    label={ texts.nameInputLabel[ lang ] }
                    placeholder={ "..." }
                    value={ newAnalysisName }
                    onChange={ (e) => dispatch( analysisNameChanged( { value: e.target.value } )  ) }
                />
            }

            <Submission project={ project } selectedMethod={ selectedMethod } lang={ lang }/>

        </div>
    )
}

const Description = ({methodData, lang}) => {

    const [descriptionActive, setDescriptionActive] = useState( false );
    if( !methodData ) return null;

    return (
        <div className={ styles.descriptionCont }>
            <div className={ styles.shortDescription } dangerouslySetInnerHTML={{__html: 
                methodData[ lang === 'en' ? 'description' : 'hebrewDescription' ] 
            }}/>
            <Button
                label={ texts.methodDescriptionBtn[ lang ] }
                theme='Plain'
                size='s'
                onClick={ () =>setDescriptionActive( true ) }
            />

            <Dialog 
                isActive={ descriptionActive } 
                onClose={ () => setDescriptionActive( false ) }
                style={{ width: 800 }}
            >
                <div className={ styles.descriptionDialogCont} dangerouslySetInnerHTML={{__html: 
                    methodData[ lang === 'en' ? 'description' : 'hebrewDescription' ] 
                }}/>
            </Dialog>
        </div>
    )
}

const Variables = ({project, methodData, lang}) => {

    if( !methodData ) return null;

    const categories = JSON.parse( methodData.variablesSettings );

    return (
        <>
            {
                Object.keys( categories ).map( (categoryKey,i) => {
                    const categoryData = categories[ categoryKey ];

                    return (
                        <Category 
                            key={categoryKey+i} 
                            initiallyCollapsed={ categoryData.isCollapsed } 
                            title={ categoryData.title } 
                            lang={ lang }
                        >
                            {
                                categoryData.fields.map( (field,j) => (
                                    <NewAnalysisField 
                                        key={ field.key + j } 
                                        project={ project } 
                                        data={ field } 
                                    />
                                ))
                            }
                        </Category>
                )})
            }

        </>
    )

}

const Category = ({title, initiallyCollapsed, lang, children}) => {

    const [isCollapsed, setIsCollapsed] = useState( initiallyCollapsed );

    return (
        <>
            <div className={ styles.categoryTopCont }>
                <CollapseButton isCollapsed={ isCollapsed } onToggle={ t => setIsCollapsed( t ) }/>
                <div style={{width:4}}/>
                <h3 className={ styles.varCategoryTitle } onClick={ () => setIsCollapsed( !isCollapsed ) }>
                    { title[ lang ] }
                </h3>
            </div>
            <div style={{ display: isCollapsed ? 'none' : 'unset' }}>
            { children }
            </div>
        </>
    )

}


const Submission = ({project, selectedMethod, lang}) => {

    const dispatch = useDispatch();

    const variables = useSelector( state => state.analyses.newAnalysis.variables );
    const userId = useSelector( state => state.users.mainUser.id );
    const isValid = useSelector( state => state.analyses.newAnalysis.isValid );
    const submittionStatus = useSelector( state => state.analyses.submittionStatus );
    const newAnalysisName = useSelector( state => state.analyses.newAnalysis.name );
    const dataKeys = useSelector( state => state.report.dataKeys );

    const isTemplate = useMemo( () => project && project.isTemplate, [project] );

    const handleSubmission = useCallback( () => {


        const bulkMethodId = checkBulkScriptName( selectedMethod.scriptName );

        if( bulkMethodId ) {

            if( !dataKeys ) return; 

            prepareDescriptiveBulkForSubmission( dataKeys, variables, 
                ( finalVarDataCollection ) => {

                    const items = finalVarDataCollection.map( d => ({
                        userId, 
                        methodId: bulkMethodId, 
                        bucketId: project.bucket.id, 
                        variables: JSON.stringify( d ),
                        analysisName: `(${newAnalysisName}) ${d.dv_VarNameChosenValues.key}`,
                    }))

                    dispatch( submitNewAnalyses( items ) );
                } 
            )
            return;
        }

        dispatch( submitNewAnalyses( [{ 
            userId, 
            methodId: selectedMethod.id, 
            bucketId: project.bucket.id, 
            variables: JSON.stringify( variables ),
            analysisName: newAnalysisName,
            }] ) 
        );

    }, [dispatch, userId, selectedMethod, project, variables, newAnalysisName, dataKeys ])

    return (
        <>
            <Button
                label={ texts.submitNewBtn[ lang ] }
                iconAfter={ lang === 'en' ? 'right_arrow' : 'left_arrow' }
                bgStyle={{
                    width: '100%',
                    marginTop: 'var(--space-medium)',  
                    boxSizing: 'border-box' 
                }}
                disabled={ isTemplate || (!isValid && submittionStatus !== 'loading') }
                onClick={ handleSubmission }
            />

            {
                submittionStatus === 'loading' &&
                <div className={ styles.submissionLock }/>
            }
        </>
    )
}