import React from 'react';
import Button from './Button';

const bgSizes = {
    xs: 18,
    s: 24,
    m: 40,
    l: 64,
};

const iconSizes = {
    xs: 12,
    s: 16,
    m: 24,
    l: 32,
};

/**
 * Wrapper around a basic Button component.
 * Costumized for displaying only icons
 * @param {{
 * name: {string} // Icon name
 * tooltip: {string} // Any text to appear on hover
 * theme: {string} //  "Full" | "Outlined" | "Plain"
 * size: {string} //  "xs" | "s" | "ms" | "m" | "l"
 * colorSet: {string} //  See colorSets inside Button component
 * bgStyle: {string} // Custom background style
 * disabled: {bool} // Is the button disabled?
 * onClick: {function} // Custom text style
 * tabIndex: {integer} // Tab focus index
 * onMouseEnter: {function} // Custom text style
 * indicateActivity: {bool}
 * }} props
 */
const IconButton = React.forwardRef(
    (
        {
            name,
            tooltip,
            theme = 'Full',
            size = 'm',
            colorSet = 'Primary',
            bgStyle,
            iconStyle,
            disabled = false,
            onClick,
            tabIndex,
            onMouseEnter,
            indicateActivity,
            imageUrl,
        },
        ref
    ) => {
        const bgSize = bgSizes[size];
        const iconSize = iconSizes[size];

        const style = {
            minWidth: bgSize,
            minHeight: bgSize,
            padding: 0,
            borderRadius: '100%',
            ...bgStyle,
        };

        const icnStyle = {
            width: iconSize,
            height: iconSize,
            ...iconStyle,
        };

        return (
            <Button
                ref={ref}
                label=""
                tooltip={tooltip}
                theme={theme}
                size={size}
                colorSet={colorSet}
                iconBefore={name}
                bgStyle={style}
                iconStyle={icnStyle}
                disabled={disabled}
                onClick={onClick}
                tabIndex={tabIndex}
                indicateActivity={indicateActivity}
                imageUrl={imageUrl}
            />
        );
    }
);

export default IconButton;
