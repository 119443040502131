export const milgoSurveyReduxActions = {
    setIncomingSurveyData: (state, action) => {
        const data = action.payload;

        if (data) {
            state.survey = data;
            state.remoteSurveyFetchStatus = 'succeeded';

            state.remoteSurveyData = {
                ...state.remoteSurveyData,
                isConnecting: false,
                status: 'OK',
                survey: data,
            };
        }
    },
    setIsSynced: (state, action) => {
        state.localChangesMade = !action.payload;
    },
    setRemoteSurveyFetchStatus: (state, action) => {
        state.remoteSurveyFetchStatus = action.payload;
    },
    updateRemoteSurveyData: (state, action) => {
        const newData = { ...state.remoteSurveyData, ...action.payload };
        state.remoteSurveyData = newData;
    },
};

export const milgoUser = {
    initState: {
        token: null,
        id: null,
        fullName: null,
        email: null,
        isLoggedIn: false,
    },
    actions: {
        setMilgoUserData: (state, { payload }) => {
            if (typeof payload === 'object') {
                state.milgoUser = { ...state.milgoUser, ...payload };
            }
        },
    },
};
