import React, { useCallback, useMemo } from 'react';
import CascadingSelectionPopper from 'components/poppers/CascadingSelectionPopper';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { notebookTexts } from 'utils/appTexts';
import { sectionAdded, templateAdded } from '../notebookSlice';

const texts = notebookTexts.content;

/**
 * @param {{ referenceElement: object, // Node element as a reference for placing the popper.
 * isActive: boolean, // Is selection popper active?
 * targetIndex: integer // Where to add the new page item
 * onSelect: function, // On user select option
 * onDissmis: function, // On cancel selection
 * }}
 */
export default function NewSectionPopper({ 
    referenceElement, 
    isActive, 
    targetIndex,
    onSelect, 
    onDismiss,
}) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const sectionsTemplatesFetchStatus = useSelector( state => state.notebook.sectionsTemplatesFetchStatus );
    const sections = useSelector( state => state.notebook.notebookSections );
    const templates = useSelector( state => state.notebook.notebookTemplates );

    const options = useMemo( () => {

        if( sectionsTemplatesFetchStatus !== 'succeeded' ) {
            return [{key:'loading', label: texts.loading[ lang ]}]
        }

        if( sections && templates ) {

            const sectionsOptions = sections.map( s => ({
                key: s.id,
                label: s.name
            }))

            const allOptions = [
                {
                    key: 'templates', 
                    label: texts.templatesOption[ lang ], 
                    subOptions: templates.map( t => ({
                        key: t.id, 
                        label: t.name
                    }))
                },
                ...sectionsOptions
            ];
                
            return allOptions;
        }

        return [{key:'error', label: 'Error'}]

    }, [lang, sectionsTemplatesFetchStatus, sections, templates ])

    const handleSelect = useCallback( option => {

        if( option === 'loading' || option === 'error' ) return;
        
        const sectionId = option.split( '.' );

        if( sectionId.length > 1 ) {
            dispatch( templateAdded({templateId: sectionId[1], targetIndex}) );
        } else {
            dispatch( sectionAdded({sectionId: sectionId[0], targetIndex}) );
        }

        if( onSelect ) {
            onSelect( option );
        } else {
            console.error( "onSelect callback is not defined for a SelectionPopper instance.");
        }

        if( onDismiss ) {
            onDismiss();
        } else {
            console.error( "onDismiss callback is not defined for a SelectionPopper instance.");
        }

    }, [onSelect, onDismiss, targetIndex, dispatch]);

    return (
        <CascadingSelectionPopper
            referenceElement={ referenceElement }
            isActive={ isActive }
            options={ options }
            onSelect={ handleSelect }
            onDismiss={ onDismiss }
        />
    )
}
