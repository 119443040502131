import React, { useMemo, useState } from 'react';
import propsStyles from '../SurveyEditorProperties.module.css';
import Checkbox from 'components/Checkbox';
import { selectUiLanguage } from 'app/preferencesSlice';
import { useSelector } from 'react-redux';
import { surveyEditorTexts } from 'utils/appTexts';
import CollapseButton from 'components/CollapseButton';

const texts =
    surveyEditorTexts.properties.pageSettings.settingsModules.externalPage;
const advancedSettingsLabel =
    surveyEditorTexts.properties.pageSettings.advancedSettingsLabel;

export default function ExternalPageSettingsModule({
    settings,
    updateSettings,
}) {
    const lang = useSelector(selectUiLanguage);
    const [showAdvanced, setShowAdvanced] = useState(false);

    const { showContinueButton, allowCamera, allowMic } = useMemo(() => {
        if (!settings) return {};
        return { ...settings.general };
    }, [settings]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 12,
                }}
            >
                <CollapseButton
                    isCollapsed={!showAdvanced}
                    onToggle={(t) => setShowAdvanced(!t)}
                    style={{ marginInlineEnd: 4 }}
                />
                <label
                    style={{
                        font: 'var(--font-body-small)',
                        cursor: 'pointer',
                    }}
                    onClick={() => setShowAdvanced(!showAdvanced)}
                >
                    {advancedSettingsLabel[lang]}
                </label>
            </div>
            {showAdvanced && (
                <>
                    <p className={propsStyles.sectionsLabel}>
                        {texts.permissionsLabel[lang]}
                    </p>
                    <Checkbox
                        size="s"
                        className={propsStyles.checkbox}
                        label={texts.allowCamera[lang]}
                        isChecked={allowCamera}
                        onChange={(isChecked) =>
                            updateSettings({
                                prop: 'allowCamera',
                                value: isChecked,
                            })
                        }
                    />
                    <Checkbox
                        size="s"
                        className={propsStyles.checkbox}
                        label={texts.allowMic[lang]}
                        isChecked={allowMic}
                        onChange={(isChecked) =>
                            updateSettings({
                                prop: 'allowMic',
                                value: isChecked,
                            })
                        }
                    />
                </>
            )}
            <div className={propsStyles.separationLine} />
            <Checkbox
                size="s"
                className={propsStyles.checkbox}
                label={texts.showContinueButton[lang]}
                isChecked={showContinueButton}
                onChange={(isChecked) =>
                    updateSettings({
                        prop: 'showContinueButton',
                        value: isChecked,
                    })
                }
            />
        </>
    );
}
