import React, { useCallback, useMemo } from "react";
import Modal from "./Modal";
import PopperPanel from "./PopperPanel";
import moduleStyles from "./SelectionPopper.module.css";

/**
 * options list can be provided via 'options' property, or through array of JSX children.
 * @param {{
 * options: Array // Options to choose from. String OR Object{ key: "", label: "" }
 * children: Array // JSX children
 * referenceElement: object // Node element as a reference for placing the popper.
 * isActive: boolean // Is selection popper active?
 * onSelect: function // On user select option. if options are passed as DOM children, the return value is key. Otherwise it's the opiton.
 * onDismiss: function // On cancel selection
 * dir: string
 * }} params Options to choose from. String OR Object{ key: "", label: "" }
 */
export default function SelectionPopper({
    referenceElement,
    isActive,
    options,
    children,
    onSelect,
    onDismiss,
    dir = "auto",
}) {
    const placement = useMemo(() => {
        switch (dir) {
            case "ltr":
                return "bottom-start";
            case "rtl":
                return "bottom-end";
            default:
                return "";
        }
    }, [dir]);

    const handleSelect = useCallback(
        (option, index) => {
            if (onSelect) {
                onSelect(option, index);
            } else {
                console.error(
                    "onSelect callback is not defined for a SelectionPopper instance."
                );
            }
        },
        [onSelect]
    );

    const handleDismiss = useCallback(() => {
        if (onDismiss) {
            onDismiss();
        } else {
            console.error(
                "onDismiss callback is not defined for a SelectionPopper instance."
            );
        }
    }, [onDismiss]);

    return (
        <Modal isActive={isActive} onClose={handleDismiss} bgBlur={"low"}>
            <PopperPanel
                referenceElement={referenceElement}
                placement={placement}
            >
                <div className={moduleStyles.listContainer} dir={dir}>
                    <ul className={moduleStyles.list}>
                        {children
                            ? React.Children.map(children, (child, i) => {
                                  if (!child.key) return null;
                                  return (
                                      <ListItem
                                          key={child.key}
                                          onSelect={() =>
                                              handleSelect(child.key, i)
                                          }
                                      >
                                          {child}
                                      </ListItem>
                                  );
                              })
                            : options &&
                              options.map((op, i) => {
                                  if (typeof op === "string") {
                                      return (
                                          <ListItem
                                              key={op + i}
                                              label={op}
                                              onSelect={() =>
                                                  handleSelect(op, i)
                                              }
                                          />
                                      );
                                  }

                                  if (typeof op === "object") {
                                      return (
                                          <ListItem
                                              key={op.key}
                                              label={op.label}
                                              onSelect={() =>
                                                  handleSelect(op, i)
                                              }
                                          />
                                      );
                                  }

                                  return null;
                              })}
                    </ul>
                </div>
            </PopperPanel>
        </Modal>
    );
}

const ListItem = ({ children, label, onSelect }) => {
    const handleClick = useCallback(
        (e) => {
            e.stopPropagation();
            onSelect();
        },
        [onSelect]
    );

    if (children) {
        return (
            <li className={moduleStyles.listItem} onClick={handleClick}>
                {children}
            </li>
        );
    }

    return (
        <li className={moduleStyles.listItem} onClick={handleClick}>
            <span style={{ flex: 1 }}>{label}</span>
        </li>
    );
};
