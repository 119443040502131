import React, { useCallback } from 'react';
import TextInput from 'components/TextInput';
import { useSelector, useDispatch } from 'react-redux';
import { surveyNameChanged } from '../../surveyEditorSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import { selectUiLanguage } from 'app/preferencesSlice';
const texts = surveyEditorTexts.properties.surveySettings;

const SurveyName = () => {
    const lang = useSelector(selectUiLanguage);
    const dispatch = useDispatch();

    const surveyName = useSelector(
        (state) => state.surveyEditor.present.survey.surveyName
    );
    const surveyLang = useSelector(
        (state) => state.surveyEditor.present.display.currentLanguage
    );
    const handleNameChange = useCallback(
        (e) => {
            dispatch(
                surveyNameChanged({ lang: surveyLang, value: e.target.value })
            );
        },
        [surveyLang, dispatch]
    );
    return (
        <TextInput
            label={texts.surveyNameLabel[lang]}
            theme="BorderBottom"
            placeholder={texts.surveyNamePlaceholder[lang]}
            value={surveyName[surveyLang] || ''}
            onChange={handleNameChange}
        />
    );
};

export default SurveyName;
