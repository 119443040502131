import React, { useCallback } from 'react';
import styles from './Editable.module.css'
import TextareaAutosize from 'react-textarea-autosize';

export const Types = {
    TEXT: 'text',
    MULTILINE: 'multiline',
    NUMBER: 'number',
}

/**
 * 
 * @param {{
 *  type: enum // Types: { TEXT, MULTILINE, NUMBER }, 
 *  rows: number // MULTILINE only, 
 *  className: string //css module, 
 *  value: string // 
 *  placeholder: string // 
 *  style: object //
 *  onChange: function // returns value
 *  onKeyDown: function //
 *  onFocus: function //
 *  onBlur: function //
 *  tabIndex: integer //
 *  warning: string //
 * }} 
 */
export const Editable = React.forwardRef( ({
    type = Types.TEXT, 
    rows = 3,
    className,
    value,
    placeholder,
    style,
    onChange,
    onKeyDown,
    onPressArrowUp,
    onPressArrowDown,
    onReturn,
    onFocus,
    onBlur,
    onClick,
    tabIndex,
    autoFocus,
}, ref) => {

    const handleKeyDown = useCallback( (event) => {
        if( event.key === 'Enter' && !event.shiftKey ) {
            if( onReturn ) onReturn();
            event.target.blur();
        }
        if( event.key === 'Up' || event.key === 'ArrowUp' ) {
            if( onPressArrowUp ) onPressArrowUp();
        }
        if( event.key === 'Down' || event.key === 'ArrowDown' ) {
            if( onPressArrowDown ) onPressArrowDown();
        }
        if( event.key === 'Escape' ) {
            event.target.blur();
        }

        if( onKeyDown ) onKeyDown( event );

    }, [ onReturn, onPressArrowUp, onPressArrowDown, onKeyDown ])

    const handleOnChange = useCallback( (event) => {

        if( onChange ) {
            onChange( event.target.value );
        }
        
    }, [onChange])

    if( type === Types.MULTILINE ) {
        return (
                <TextareaAutosize
                    ref={ ref }
                    rows={ rows }
                    className={ [className, styles.editableTextArea].join(' ') }
                    value={ value }
                    placeholder={ placeholder }
                    style={ style }
                    onClick={ onClick }
                    onChange={ handleOnChange }
                    onKeyDown={ handleKeyDown }
                    onFocus={ onFocus }
                    onBlur={ onBlur }
                    tabIndex={ tabIndex }
                >
                </TextareaAutosize>
        )
    }

    return (
            <input
                ref={ ref }
                autoFocus = {autoFocus}
                size={ value ? value.length : placeholder? placeholder.length : 10 }
                type={ type }
                className={ [className, styles.editable].join(' ') }
                value={ value }
                placeholder={ placeholder }
                style={ style }
                onClick={ onClick }
                onChange={ handleOnChange }
                onKeyDown={ handleKeyDown }
                onFocus={ onFocus }
                onBlur={ onBlur }
                tabIndex={ tabIndex }
            >
            </input>
    )
})

