import React, { useMemo } from "react";
import styles from "./LogicStyles.module.css";
import propGeneralStyles from "features/survey_editor/properties_area/SurveyEditorProperties.module.css";
import PageLogicPhase from "./PageLogicPhase";
import { useSelector } from "react-redux";
import { pageTypesConfig } from "features/survey_editor/surveyEditorConfiguration";
import { getPageNumber, selectPageProperty } from "../surveyEditorSlice";
import { surveyEditorTexts } from "utils/appTexts";
import { selectUiLanguage } from "app/preferencesSlice";
import { pageLogicPhases } from "./LogicConfiguration";

const texts = surveyEditorTexts.properties.logic;

export default function PageLogicItemsList() {
    const lang = useSelector(selectUiLanguage);
    const currentPage = useSelector(
        (state) => state.surveyEditor.present.display.currentPage
    );
    const pageType = useSelector((state) =>
        selectPageProperty(state, currentPage, "type")
    );
    const parentBlock = useSelector((state) =>
        selectPageProperty(state, currentPage, "parentBlock")
    );
    const itemNumber = useSelector((state) =>
        getPageNumber(state, currentPage, parentBlock)
    );

    ////////////
    // RENDER //
    ////////////
    const title = useMemo(() => {
        return texts.pageLogicTitle[lang].replace("{n}", itemNumber);
    }, [itemNumber, lang]);

    const bgStyle = useMemo(() => {
        if (currentPage && pageType) {
            const pageTypeColor = pageTypesConfig[pageType].color + "1a";
            return { backgroundColor: pageTypeColor };
        }
        return { backgroundColor: "var( --color-background-mild )" };
    }, [currentPage, pageType]);

    if (!currentPage || !pageType || pageType === "BLOCK") return null;

    return (
        <div className={styles.pageLogicItemsListCont} style={bgStyle}>
            <h4 className={propGeneralStyles.modeLabel}>{title}</h4>
            <PageLogicPhase
                pageId={currentPage}
                phase={pageLogicPhases.ON_PAGE_ENTER}
            />
            <PageLogicPhase
                pageId={currentPage}
                phase={pageLogicPhases.ON_PAGE_EXIT}
            />
        </div>
    );
}
