import React, { useState } from 'react';
import Modal from '../../components/poppers/Modal';
import PopperPanel from '../../components/poppers/PopperPanel';
import Checkbox2 from '../../components/Checkbox';
import styles from './LabelsPopper.module.css';
import { homeTexts } from '../../utils/appTexts';


export default function LabelsPopper({lang, isActive, referenceElement, onDismiss, onApply}) {

    

    return (
        <Modal 
            isActive={ false }
            onClose={ onDismiss }
            bgBlur={ "low" }
        >
            <PopperPanel 
                referenceElement={ referenceElement }
            >
                <div className={ styles.labelsCont }>
                    <p className={ styles.title }> { homeTexts.editLabels[ lang ] } </p>
                    <LabelItem color="#ECE1FD"/>
                    <LabelItem color="#FFEEB8"/>
                    <LabelItem color="#FCE4EC"/>
                    <LabelItem color="#ECE1FD"/>
                    <LabelItem color="#ECE1FD"/>
                    <LabelItem color="#ECE1FD"/>
                </div>
                
            </PopperPanel>
        </Modal>
    )

}

function LabelItem( {color} ) {

    const [ isChecked, setIsChecked ] = useState(false);

    return (
        <div id={ styles.labelItemCont }>
            <Checkbox2 
                color={ color }
                onChange={ (value) => { setIsChecked (value)} }
            />
            <p class={ styles.labelTitle }
                style={{
                    color: isChecked ? 'var( --color-type-medium-emphasis )' : 'var(--color-type-low-emphasis)',
                }}
            >
                Lable A
            </p>
        </div>
    )
    
    
}