import Button from "components/Button";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { runMilgoQuery } from "../milgo-query";
import styles from './MilgoPublishAndExportStyles.module.css';
import { surveyEditorTexts } from 'utils/appTexts';
import useParseBackup from "features/survey_editor/properties_area/publish_and_export/useParseBackup";
import RestoreAlert from "features/survey_editor/properties_area/publish_and_export/RestoreAlert";
const texts = surveyEditorTexts.properties.publishAndExport;
// Version
// [{description: string, url: string, timestamp: number}]
const GET_VERSIONS = `
	query {
		resource(where: { id: "$id" }) {
			versions
		}
	}
`;

export default function MilgoSurveyVersions({ lang }) {
    const milgoToken = useSelector(state => state.users.milgoUser.token);
    const surveyId = useSelector(state => state.surveyEditor.present.surveyId);
    const [versions, setVersions] = useState([]);

    const [restoreAlertActive, setRestoreAlertActive] = useState(false);
    const [versionJson, setVersionJson] = useState(null);

    useEffect(() => {
        if (milgoToken && surveyId) {
            getVersions(milgoToken, surveyId, (versions) => {
                // Sort by newest:
                setVersions(versions.sort((a, b) => b.timestamp - a.timestamp))
            });
        }
    }, [surveyId, milgoToken])

    const backupParser = useParseBackup({ validateSourceName: false });

    const handleRestoreButton = useCallback(async (url) => {
        await fetch(url, { method: 'GET' })
            .then((result) => {
                if (!result.ok) {
                    throw result;
                }
                return result.json();
            })
            .then((json) => {
                const surveyData = backupParser(JSON.stringify(json));
                if (surveyData) {
                    setVersionJson(surveyData);
                    setRestoreAlertActive(true);
                }
            })
            .catch((err) => {
                alert('Failed to fetch version data. ' + err);
            });
    }, []);

    if (!versions || versions.length === 0) return null;

    return (
        <>
            <h4 className={styles.versionsSectionLabel}>{texts.versionsSectionLabel[lang]}</h4>
            <table className={styles.versionsTable}>
                <tbody>

                    {versions.map((version, i) => (
                        <tr key={i} className={styles.versionRowCont}>
                            <td className={styles.restoreBtnCell}><Button size='s' label={'restore'} theme="Plain" onClick={() => handleRestoreButton(version.url)} /></td>
                            <td className={styles.versionDate}>{new Date(version.timestamp).toLocaleString('he-IL')}</td>
                            <td className={styles.versionDescription}>{version.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <RestoreAlert
                isActive={restoreAlertActive}
                surveyData={versionJson}
                onClose={() => setRestoreAlertActive(false)}
            />
        </>
    )
}

async function getVersions(token, surveyId, onDone) {
    const result = await runMilgoQuery(token, GET_VERSIONS.replace('$id', surveyId))
    let versions = result?.data?.resource?.versions;
    onDone(versions)
}