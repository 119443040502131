import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { SessionStorage } from 'utils/cacheManager';
import {
    openItemDataConfig,
    pageTypesConfig,
    OPEN_ITEM,
} from './surveyEditorConfiguration';
import { modes as propertiesModes } from './properties_area/SurveyEditorProperties';
import { previewPanelModes } from './preview_area/SurveyPreviewFrame';
import { surveyEditorTexts } from 'utils/appTexts';
// import * as Api from 'api/api';
import * as SocketApi from 'api/socketApi';
import { getNested } from 'utils/miscHelpers';
import { milgoSurveyReduxActions } from 'features/milgo_survey/milgoReduxActions';

let ApiToUseWithSurveys = SocketApi;

export const initialState = {
    surveyId: '',
    remoteSurveyFetchStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    remoteSurveyData: null,
    autoSaveInitiated: false,
    remoteSurveyUpdateStatus: 'idle',
    localChangesMade: false,
    localChangesTimestamp: 0,
    surveyVersions: null,

    survey: {
        sourceName: '',
        surveyName: {},
        version: '',
        debug: {
            previewId: '',
            previewLanguage: undefined,
            currentItemId: undefined, // id of a selected item on page with multiple items
            previewSplash: false,
        },
        settings: {
            general: {
                color: '000', // either predefind pelette code (000-004) OR array of 2 colors [#000000,#000000]
                supportedLanguages: ['he'],
                fillingLimit: '0', // -1: no limit | 0: only once | 1: once a day | ...number of days between filling
                anonymousFilling: false,
                splash: {
                    use: true,
                    text: {},
                    logos: [], // array of img urls
                },
                hideProgressbar: false,
                platformLimitation: 'none', // 'none' | 'desktop_only' | 'mobile_only' | 'force_mobile'
            },
            platforms: {},
        },
        content: {
            pagesOrder: [],
            pagesData: {},
        },
        logic: {
            variables: [],
            logicItemsData: {},
        },
        media: {
            files: [],
            archive: [],
        },
    },

    display: {
        splitViewMode: 'NORMAL', // 'NORML' | 'COLLAPSED_PROPERTIES' | 'EXTENDED_PROPERTIES' | 'DESKTOP_VIEW' | 'MOBILE_VIEW'
        currentPage: '', // page id
        currentLogicItem: null,
        currentLanguage: 'he',
        propertiesMode: propertiesModes.SURVEY,
        previewPanelMode: previewPanelModes.MOBILE,
        message: null,
        alert: null,
        collapsedPages: {}, // Collapsed pages { pageid: true/false }
        isDraggingToReorder: false,
    },
};

////////////////////
// Async actions: //
////////////////////
export const fetchSurvey = createAsyncThunk(
    'surveyEditor/fetchSurvey',
    async (surveyId, thunkApi) => {
        const clientKey = getClientKey();

        const response = await ApiToUseWithSurveys.FetchSurvey(
            surveyId,
            clientKey
        );

        if (response.error) {
            console.warn(
                'Error while fetching survey data. Details: ',
                response.error
            );
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            return response.data;
        }
    }
);

export const updateSurvey = createAsyncThunk(
    'surveyEditor/updateSurvey',
    async (surveyId, thunkApi) => {
        const clientKey = getClientKey();
        const state = thunkApi.getState().surveyEditor.present;

        // TODO: Should think about a better way to do this...
        if (state.survey.content.pagesOrder.length === 0) {
            return thunkApi.rejectWithValue('Survey is empty..');
        }
        const response = await ApiToUseWithSurveys.UpdateSurvey(
            state.surveyId,
            JSON.stringify(state.survey),
            clientKey
        );

        if (response.error) {
            console.warn(
                'Error while updating remote survey. Details: ',
                response.error
            );
            return thunkApi.rejectWithValue(response.error);
        } else {
            return {
                data: response.data,
                timestamp: state.localChangesTimestamp,
            };
        }
    },
    {
        condition: (surveyId, thunkApi) => {
            const state = thunkApi.getState().surveyEditor.present;
            const survey = state.survey;

            if (!survey.sourceName) {
                console.warn('Survey is missing sourceName. Canceling udpate.');
                return false;
            }

            return true;
        },
    }
);

export const serverResyncSurvey = createAsyncThunk(
    'surveyEditor/serverResyncSurvey',
    async (surveyId, thunkApi) => {
        const clientKey = getClientKey();
        if (ApiToUseWithSurveys.ServerResyncSurvey) {
            await ApiToUseWithSurveys.ServerResyncSurvey(surveyId, clientKey);
        }
        return true;
    }
);

////////////////////
////// Utils ///////
////////////////////

export const SurveyEditorUndoableFilter = (
    action,
    currentState,
    previousHistory
) => {
    const filter =
        action.type !== 'horizonData/fetchProjectById/pending' &&
        action.type !== 'horizonData/fetchProjectById/fulfilled' &&
        action.type !== 'surveyEditor/fetchSurvey/fulfilled' &&
        action.type !== 'surveyEditor/fetchSurvey/pending' &&
        action.type !== 'surveyEditor/updateSurvey/pending' &&
        action.type !== 'surveyEditor/updateSurvey/fulfilled' &&
        action.type !== 'surveyEditor/surveyEditorReset' &&
        action.type !== 'surveyEditor/restoreFromCache' &&
        action.type !== 'surveyEditor/displayStateChanged' &&
        action.type !== 'surveyEditor/localChangesMade' &&
        action.type !== 'surveyEditor/surveyDebugChanged' &&
        action.type !== 'surveyEditor/messageAdded' &&
        action.type !== 'surveyEditor/alertAdded' &&
        action.type !== 'surveyEditor/pageContentCollapsed';

    // if( filter ) {

    //     console.log( previousHistory.index );
    //     console.log(action);
    // }

    return filter;
};

function getClientKey() {
    let clientKey = SessionStorage.Read(SessionStorage.keys.CLIENT_KEY);
    if (!clientKey) {
        clientKey = 'client_' + uuidv4();
        SessionStorage.Save(SessionStorage.keys.CLIENT_KEY, clientKey);
    }

    return clientKey;
}

function handleLocalChanges(state) {
    if (!state.autoSaveInitiated || state.remoteSurveyData.isLocked) {
        return;
    }
    state.localChangesMade = true;
    state.localChangesTimestamp = Date.now();
}

function resetBlockRandomWeights(state, blockId) {
    const block = state.survey.content.pagesData[blockId];
    const blockPages = block.pages;
    const weights = block.settings.general.randomizationWeights;

    const newWeights = { ...weights };

    // Remove non-existing old pages:
    Object.keys(newWeights).forEach((pageId) => {
        if (!blockPages.includes(pageId)) {
            delete newWeights[pageId];
        }
    });

    // Add new pages and reset old weights:
    blockPages.forEach((pageId) => {
        newWeights[pageId] = 1 / blockPages.length;
    });

    // console.log( newWeights );

    block.settings.general.randomizationWeights = newWeights;
}

/////////////////////
////// Slice ////////
/////////////////////
const surveyEditorSlice = createSlice({
    name: 'surveyEditor',
    initialState,
    reducers: {
        //////////////////////////////
        // General editor reducers: //
        //////////////////////////////

        surveyEditorReset: (state) => initialState,
        initiateAutoSave: (state) => {
            state.autoSaveInitiated = true;
        },
        surveyIdChanged: (state, action) => {
            state.surveyId = action.payload;
        },
        stateSnapshotCreated: (state) => {
            // This way we can synthetically create a state change and by doing so, adding the snapshot to the undo history.
            state.localChangesTimestamp = Date.now();
        },
        restoreFromCache: (state, action) => {
            // SessionStorage.Delete( SessionStorage.keys.SURVEY_EDITOR_DATA );

            const data = SessionStorage.Read(
                SessionStorage.keys.SURVEY_EDITOR_DATA
            );
            if (data) {
                state.display = data.display;
                state.display.message = null;
            }
        },
        restoreSurvey: (state, action) => {
            const surveyData = action.payload;
            state.survey = surveyData;
            handleLocalChanges(state);
        },
        sourceNameChanged: (state, action) => {
            state.survey.sourceName = action.payload;
            handleLocalChanges(state);
        },
        surveyVersionUpdated: (state, action) => {
            state.survey.version = action.payload;
            handleLocalChanges(state);
        },
        surveyNameChanged: (state, action) => {
            const { lang, value } = action.payload;
            state.survey.surveyName[lang] = value;
            handleLocalChanges(state);
        },
        surveySettingsChanged: (state, action) => {
            const { prop, value } = action.payload;

            state.survey.settings.general[prop] = value;
            handleLocalChanges(state);
        },
        surveyDebugChanged: (state, action) => {
            const { property, value } = action.payload;

            state.survey.debug[property] = value;
            // handleLocalChanges( state );
        },
        localChangesMade: (state, action) => {
            handleLocalChanges(state);
        },

        ///////////////////
        // Survey Pages: //
        ///////////////////

        pageAdded: (state, action) => {
            const { atIndex, type, lang, parentBlock } = action.payload;
            const surveyData = state.survey.content;
            const id = uuidv4();

            const newPage = {
                key:
                    (type === 'BLOCK' ? 'block_' : 'page_') +
                    new Date().getTime().toString(25),
                type: type,
                title: {},
                parentBlock,
                settings: {
                    general: {},
                },
                ...pageTypesConfig[type].data,
            };

            newPage.title[lang] = '';

            surveyData.pagesData[id] = newPage;

            if (parentBlock) {
                surveyData.pagesData[parentBlock].pages.splice(atIndex, 0, id);
                resetBlockRandomWeights(state, parentBlock);
            } else {
                surveyData.pagesOrder.splice(atIndex, 0, id);
            }

            state.display.currentPage = id;
            state.survey.debug.previewId = id;
            handleLocalChanges(state);
        },
        pagesReordered: (state, action) => {
            const { movingPageId, targetPageId, targetBlockId, placement } =
                action.payload;

            const surveyData = state.survey.content;
            const movingParentBlock =
                surveyData.pagesData[movingPageId].parentBlock;
            const targetParentBlock = targetPageId
                ? surveyData.pagesData[targetPageId].parentBlock
                : null;

            // Remove page from origin:
            if (movingParentBlock) {
                const originBlock = surveyData.pagesData[movingParentBlock];
                const originIndex = originBlock.pages.indexOf(movingPageId);
                originBlock.pages.splice(originIndex, 1);

                surveyData.pagesData[movingPageId].parentBlock = null;
                resetBlockRandomWeights(state, movingParentBlock);
            } else {
                const originIndex = surveyData.pagesOrder.indexOf(movingPageId);
                surveyData.pagesOrder.splice(originIndex, 1);
            }

            // Add page at target location:
            if (targetParentBlock) {
                const targetBlock = surveyData.pagesData[targetParentBlock];
                let targetIndex = targetBlock.pages.indexOf(targetPageId);
                if (placement === 'AFTER') targetIndex++;
                targetBlock.pages.splice(targetIndex, 0, movingPageId);

                surveyData.pagesData[movingPageId].parentBlock =
                    targetParentBlock;
                resetBlockRandomWeights(state, targetParentBlock);
            } else if (targetBlockId) {
                // Page moved directly into an empty block.
                surveyData.pagesData[targetBlockId].pages.push(movingPageId);
                surveyData.pagesData[movingPageId].parentBlock = targetBlockId;
                resetBlockRandomWeights(state, targetBlockId);
            } else {
                let targetIndex = surveyData.pagesOrder.indexOf(targetPageId);
                if (placement === 'AFTER') targetIndex++;
                surveyData.pagesOrder.splice(targetIndex, 0, movingPageId);
            }

            handleLocalChanges(state);
        },
        pageMovedOutOfBlock: (state, action) => {
            const { blockId, pageId, byDrag } = action.payload;
            const surveyData = state.survey.content;

            surveyData.pagesData[pageId].parentBlock = null;

            const block = surveyData.pagesData[blockId];
            const blockPages = Array.from(block.pages);
            const indexOfPage = blockPages.indexOf(pageId);
            const [removed] = blockPages.splice(indexOfPage, 1);
            block.pages = blockPages;

            const surveyPages = Array.from(surveyData.pagesOrder);

            const indexOfBlock = surveyData.pagesOrder.indexOf(blockId);
            let targetLocation = indexOfBlock;

            if (byDrag) {
                // Find target location outside of block by current dragged over item:
                const draggedOver = state.display.draggedOver.pageId;
                const draggedOverIndex =
                    surveyData.pagesOrder.indexOf(draggedOver);
                if (draggedOver && draggedOverIndex !== -1) {
                    // if dragged over page exists:
                    const placement = state.display.draggedOver.placement;
                    targetLocation =
                        draggedOverIndex + (placement === 'TOP' ? 0 : 1);
                }
            }

            surveyPages.splice(targetLocation, 0, removed);
            surveyData.pagesOrder = surveyPages;

            resetBlockRandomWeights(state, blockId);

            handleLocalChanges(state);
        },
        pageMovedIntoBlock: (state, action) => {
            const { blockId, pageId } = action.payload;
            const surveyData = state.survey.content;

            // Validate that items are of the correct type:
            if (
                surveyData.pagesData[blockId].type !== 'BLOCK' ||
                surveyData.pagesData[pageId].type === 'BLOCK'
            )
                return;

            surveyData.pagesData[pageId].parentBlock = blockId;

            const surveyPages = Array.from(surveyData.pagesOrder);
            const indexOfPage = surveyPages.indexOf(pageId);
            const [removed] = surveyPages.splice(indexOfPage, 1);
            surveyData.pagesOrder = surveyPages;

            const block = surveyData.pagesData[blockId];
            const blockPages = block.pages;

            // Find target location inside block by current dragged over item:
            let targetLocation = 0;
            const draggedOver = state.display.draggedOver.pageId;
            const draggedOverIndex = block.pages.indexOf(draggedOver);
            if (draggedOver && draggedOverIndex !== -1) {
                // if dragged over page exists:
                const placement = state.display.draggedOver.placement;
                targetLocation =
                    draggedOverIndex + (placement === 'TOP' ? 0 : 1);
            }

            blockPages.splice(targetLocation, 0, removed);
            block.pages = blockPages;

            resetBlockRandomWeights(state, blockId);

            handleLocalChanges(state);
        },
        pageMovedBetweenBlocks: (state, action) => {
            const {
                source, // {id,index}
                destination, // {id,index}
            } = action.payload;
            const surveyData = state.survey.content;

            const srcBlock = surveyData.pagesData[source.id];
            const srcBlockPages = Array.from(srcBlock.pages);
            const [removed] = srcBlockPages.splice(source.index, 1);
            srcBlock.pages = srcBlockPages;

            const destBlock = surveyData.pagesData[destination.id];
            const destBlockPages = destBlock.pages;
            destBlockPages.splice(destination.index, 0, removed);
            destBlock.pages = destBlockPages;

            resetBlockRandomWeights(state, source.id);
            resetBlockRandomWeights(state, destination.id);

            handleLocalChanges(state);
        },
        pageContentChanged: (state, action) => {
            const { id, prop, value } = action.payload;

            state.survey.content.pagesData[id][prop] = value;
            handleLocalChanges(state);
        },
        pageTextContentChanged: (state, action) => {
            const { id, prop, value, lang } = action.payload;

            try {
                state.survey.content.pagesData[id][prop][lang] = value;
                handleLocalChanges(state);
            } catch (error) {
                console.error(
                    'Error while trying to update page text. Details: ',
                    error
                );
            }
        },
        pageDuplicated: (state, action) => {
            const pageId = action.payload;
            const data = state.survey.content.pagesData[pageId];
            let newKey = data.key;
            // Handle key suffix:
            const keyParts = newKey.split('_');

            if (keyParts && keyParts.length > 0) {
                const last = keyParts[keyParts.length - 1];
                if (!isNaN(parseInt(last))) {
                    keyParts.splice(keyParts.length - 1, 1, parseInt(last) + 1);
                    newKey = keyParts.join('_');
                } else {
                    newKey += '_1';
                }
            }
            const newPageId = uuidv4();
            const newData = JSON.parse(JSON.stringify(data));
            newData.key = newKey;

            // Clear page logic:
            const enterItems = getNested(data, 'logic', 'onPageEnter');
            if (enterItems && enterItems.length > 0) {
                newData.logic.onPageEnter = []; // Remove existing logic items.
            }

            const exitItems = getNested(data, 'logic', 'onPageExit');
            if (exitItems && exitItems.length > 0) {
                newData.logic.onPageExit = []; // Remove existing logic items.
            }

            // Remove navigation information from items:
            if (
                newData.itemsData &&
                Object.keys(newData.itemsData).length > 0
            ) {
                Object.entries(newData.itemsData).forEach(
                    ([itemId, itemData]) => {
                        if (itemData.navigation) {
                            delete newData.itemsData[itemId].navigation;
                        }
                    }
                );
            }

            state.survey.content.pagesData[newPageId] = newData;

            if (newData.parentBlock) {
                const blockData =
                    state.survey.content.pagesData[newData.parentBlock];
                const pageIndex = blockData.pages.indexOf(pageId) + 1;
                blockData.pages.splice(pageIndex, 0, newPageId);
                resetBlockRandomWeights(state, newData.parentBlock);
            } else {
                const pageIndex =
                    state.survey.content.pagesOrder.indexOf(pageId) + 1;
                state.survey.content.pagesOrder.splice(pageIndex, 0, newPageId);
            }

            handleLocalChanges(state);
        },
        pageDeleted: (state, action) => {
            const { id, parentBlockId } = action.payload;
            const surveyData = state.survey.content;
            const pageData = state.survey.content.pagesData[id];

            // If page has logic items, delete them from survey logic data:
            const pageLogicItems = [];
            const enterItems = getNested(pageData, 'logic', 'onPageEnter');
            if (enterItems && enterItems.length > 0) {
                enterItems.forEach((itemId) => pageLogicItems.push(itemId));
            }
            const exitItems = getNested(pageData, 'logic', 'onPageExit');
            if (exitItems && exitItems.length > 0) {
                exitItems.forEach((itemId) => pageLogicItems.push(itemId));
            }
            pageLogicItems.forEach(
                (itemId) => delete state.survey.logic.logicItemsData[itemId]
            );

            state.display.currentPage = null;
            state.survey.debug.previewId = null;

            if (parentBlockId) {
                // Page is deleted within a block.

                const result = Array.from(
                    surveyData.pagesData[parentBlockId].pages
                );
                const removeInx = result.indexOf(id);
                result.splice(removeInx, 1);
                surveyData.pagesData[parentBlockId].pages = result;

                resetBlockRandomWeights(state, parentBlockId);
            } else {
                const result = Array.from(surveyData.pagesOrder);
                const removeInx = result.indexOf(id);
                result.splice(removeInx, 1);
                surveyData.pagesOrder = result;
            }

            delete surveyData.pagesData[id];

            handleLocalChanges(state);
        },

        /////////////////
        // Page Items: //
        /////////////////

        itemAddedToPage: (state, action) => {
            const { pageId, atIndex, lang, value } = action.payload;
            const pageData = state.survey.content.pagesData[pageId];

            const id = uuidv4();
            const text = {};
            text[lang] = value;

            const newItem = {
                id,
                key: 'item_' + new Date().getTime().toString(25),
                text: text,
            };

            if (pageData.itemsOrder) {
                pageData.itemsOrder.splice(atIndex, 0, id);
            } else {
                pageData.itemsOrder = [id];
                // console.log( "pageData.itemsOrder ", pageData.itemsOrder)
            }

            if (pageData.itemsData) {
                pageData.itemsData[id] = newItem;
            } else {
                pageData.itemsData = {};
                pageData.itemsData[id] = newItem;
            }

            handleLocalChanges(state);
        },
        openItemAddedToPage: (state, action) => {
            const { pageId } = action.payload;
            const pageData = state.survey.content.pagesData[pageId];

            if (!pageData.itemsData) {
                pageData.itemsData = {};
            }
            pageData.itemsData[OPEN_ITEM] = openItemDataConfig;

            handleLocalChanges(state);
        },
        batchAddItemsToPage: (state, action) => {
            const { pageId, list } = action.payload; // list: [{key:"", text: {en: "", he: "", ar: ""}},...]
            const pageData = state.survey.content.pagesData[pageId];

            if (!pageData.itemsOrder || !pageData.itemsData) {
                pageData.itemsOrder = [];
                pageData.itemsData = {};
            }

            for (let i = 0; i < list.length; i++) {
                const id = uuidv4();
                pageData.itemsOrder.push(id);
                pageData.itemsData[id] = list[i];
            }

            handleLocalChanges(state);
        },
        pageItemsReordered: (state, action) => {
            const { pageId, startIndex, endIndex } = action.payload;
            const surveyData = state.survey.content;

            const result = Array.from(surveyData.pagesData[pageId].itemsOrder);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            surveyData.pagesData[pageId].itemsOrder = result;

            handleLocalChanges(state);
        },
        pageItemTextChanged: (state, action) => {
            const { pageId, itemId, lang, value } = action.payload;
            const pageData = state.survey.content.pagesData[pageId];

            pageData.itemsData[itemId].text[lang] = value;

            handleLocalChanges(state);
        },
        pageItemKeyChanged: (state, action) => {
            const { pageId, itemId, value } = action.payload;
            const pageData = state.survey.content.pagesData[pageId];

            let alreadyExist = false;
            const items = Object.values(pageData.itemsData);
            for (let i = 0; i < items.length; i++) {
                if (items[i].key === value) {
                    alreadyExist = true;
                    break;
                }
            }

            pageData.itemsData[itemId].warning = alreadyExist
                ? surveyEditorTexts.content.warnings.itemKeyAlreadyExist
                : null;

            pageData.itemsData[itemId].key = value;

            handleLocalChanges(state);
        },
        pageItemPropChanged: (state, action) => {
            const { pageId, itemId, prop, data } = action.payload;
            const pageData = state.survey.content.pagesData[pageId];
            const itemData = pageData.itemsData[itemId];

            if (data && typeof data === 'object') {
                itemData[prop] = {
                    ...itemData[prop],
                    ...data,
                };
            } else {
                itemData[prop] = data;
            }

            handleLocalChanges(state);
        },
        pageItemDeleted: (state, action) => {
            const { pageId, itemId } = action.payload;
            const pageData = state.survey.content.pagesData[pageId];

            const result = Array.from(pageData.itemsOrder);
            const removeInx = result.indexOf(itemId);

            if (removeInx > -1) {
                // open item is not included in itemsOrder.
                result.splice(removeInx, 1);
                pageData.itemsOrder = result;
            }

            delete pageData.itemsData[itemId];

            if (itemId === OPEN_ITEM) {
                // also disable within settings:
                pageData.settings.general.showOpenItem = false;
            }

            handleLocalChanges(state);
        },

        ////////////////
        // Variables: //
        ////////////////

        variableAdded: (state, action) => {
            const { name, type } = action.payload;

            const newVar = {
                id: uuidv4(),
                name,
                type,
                value: '',
            };

            state.survey.logic.variables.push(newVar);
            handleLocalChanges(state);
        },
        variableChanged: (state, action) => {
            const { id, prop, value } = action.payload;

            const vars = state.survey.logic.variables;
            const inx = vars.findIndex((x) => x.id === id);
            if (inx > -1) {
                vars[inx][prop] = value;
            }
            handleLocalChanges(state);
        },
        variableDeleted: (state, action) => {
            const id = action.payload;

            const vars = state.survey.logic.variables;
            const inx = vars.findIndex((x) => x.id === id);
            if (inx > -1) {
                vars.splice(inx, 1);
            }
            handleLocalChanges(state);
        },

        ////////////
        // Logic: //
        ////////////
        logicItemAddedToPage: (state, action) => {
            const {
                pageId,
                phase,
                title,
                condition,
                actions,
                isNavigationLogic,
                navigationData,
            } = action.payload;
            const surveyData = state.survey.content;

            const itemId = uuidv4();

            const newLogicItem = {
                pageId,
                phase,
                title,
                condition,
                actions,
                isNavigationLogic,
                navigationData,
            };

            let logic = { ...surveyData.pagesData[pageId].logic };
            if (!logic) {
                logic = {};
            }

            if (!logic[phase]) {
                logic[phase] = [];
            }

            // Determine where to add the new item:
            if (isNavigationLogic) {
                // Place it at the end of the list:
                logic[phase].push(itemId);
            } else {
                // Find the first existing navigation item, and place the new item before if:
                const items = logic[phase];
                const navItems = [];
                Object.entries(state.survey.logic.logicItemsData).forEach(
                    ([id, data]) => {
                        if (items.includes(id) && data.isNavigationLogic) {
                            navItems.push(id);
                        }
                    }
                );
                const navItemsIndices = [];
                logic[phase].forEach((id, i) => {
                    if (navItems.includes(id)) {
                        navItemsIndices.push(i);
                    }
                });
                const targetInx = Math.min(...navItemsIndices);
                logic[phase].splice(targetInx, 0, itemId);
            }

            surveyData.pagesData[pageId].logic = logic;
            state.survey.logic.logicItemsData[itemId] = newLogicItem;

            if (isNavigationLogic) {
                // Update page item navigation data:
                const srcPage = surveyData.pagesData[navigationData.sourcePage];
                const srcItem =
                    srcPage.itemsData[navigationData.sourcePageItem];

                srcItem.navigation = {
                    logicItem: itemId,
                    targetPage: navigationData.targetPage,
                };
            } else {
                // Update display state:
                state.display.currentLogicItem = itemId;
                state.display.currentPage = pageId;
            }

            handleLocalChanges(state);
        },
        logicItemChanged: (state, action) => {
            const { id, prop, value } = action.payload;

            state.survey.logic.logicItemsData[id][prop] = value;

            handleLocalChanges(state);
        },
        logicItemReordered: (state, action) => {
            const { pageId, phase, sourceIndex, destinationIndex } =
                action.payload;
            const itemsOrder =
                state.survey.content.pagesData[pageId].logic[phase];
            const [target] = itemsOrder.splice(sourceIndex, 1);
            itemsOrder.splice(destinationIndex, 0, target);

            handleLocalChanges(state);
        },
        logicItemDeleted: (state, action) => {
            const id = action.payload;
            const surveyData = state.survey.content;

            const itemData = state.survey.logic.logicItemsData[id];
            const pageLogicItems =
                surveyData.pagesData[itemData.pageId].logic[itemData.phase];

            const itemInx = pageLogicItems.indexOf(id);
            if (itemInx > -1) {
                pageLogicItems.splice(itemInx, 1);
            }

            delete state.survey.logic.logicItemsData[id];

            if (id === state.display.currentLogicItem) {
                state.display.currentLogicItem = null;
            }

            handleLocalChanges(state);
        },
        setCurrentLogicItem: (state, action) => {
            const id = action.payload;
            state.display.currentLogicItem = id;

            SessionStorage.Save(SessionStorage.keys.SURVEY_EDITOR_DATA, state);
        },

        // CONDITIONS
        logicItemConditionChanged: (state, action) => {
            const { id, prop, value } = action.payload;

            state.survey.logic.logicItemsData[id].condition[prop] = value;

            handleLocalChanges(state);
        },
        logicItemConditionArgChanged: (state, action) => {
            const { itemId, argId, data, prop, value } = action.payload;

            const args =
                state.survey.logic.logicItemsData[itemId].condition.args;

            if (!args[argId]) {
                // If arg is not existing yet, create one:
                args[argId] = {};
            }

            if (data) {
                args[argId] = data;
            }

            if (prop) {
                args[argId][prop] = value;
            }

            handleLocalChanges(state);
        },
        logicItemConditionArgRemoved: (state, action) => {
            const { itemId, argId } = action.payload;

            delete state.survey.logic.logicItemsData[itemId].condition.args[
                argId
            ];

            handleLocalChanges(state);
        },

        // ACTIONS
        logicItemActionAdded: (state, action) => {
            const { logicItemId } = action.payload;
            const logicItemData =
                state.survey.logic.logicItemsData[logicItemId];
            let actions = logicItemData.actions;
            const newActionId = uuidv4();

            if (!actions || !actions.data || !actions.order) {
                actions = logicItemData.actions = {};
                actions.data = {};
                actions.order = [];
            }

            actions.data[newActionId] = {
                type: null,
            };
            actions.order.push(newActionId);
            handleLocalChanges(state);
        },
        logicItemActionDeleted: (state, action) => {
            const { logicItemId, actionId } = action.payload;
            const actions =
                state.survey.logic.logicItemsData[logicItemId].actions;
            const targetInx = actions.order.indexOf(actionId);
            if (targetInx > -1) {
                actions.order.splice(targetInx, 1);
            }
            delete actions.data[actionId];
            handleLocalChanges(state);
        },
        logicItemActionChanged: (state, action) => {
            const { logicItemId, actionId, data, prop, value } = action.payload;
            const actions =
                state.survey.logic.logicItemsData[logicItemId].actions;
            const targetAction = actions.data[actionId];

            if (data) {
                const newData = {
                    ...targetAction,
                    ...data,
                };

                actions.data[actionId] = newData;
            }

            if (prop) {
                targetAction[prop] = value;
            }

            handleLocalChanges(state);
        },
        logicItemActionReordered: (state, action) => {
            const { logicItemId, sourceIndex, destinationIndex } =
                action.payload;
            const actionsOrder =
                state.survey.logic.logicItemsData[logicItemId].actions.order;
            const [target] = actionsOrder.splice(sourceIndex, 1);
            actionsOrder.splice(destinationIndex, 0, target);

            handleLocalChanges(state);
        },

        ////////////
        // Media: //
        ////////////
        initMedia: (state) => {
            state.survey.media = { ...initialState.survey.media };
        },
        mediaChanged: (state, action) => {
            const { files, archive } = action.payload;
            if (files) state.survey.media.files = files;
            if (archive) state.survey.media.archive = archive;

            handleLocalChanges(state);
        },

        ////////////////////////////
        // Survey Editor Display: //
        ////////////////////////////

        displayStateChanged: (state, action) => {
            const { property, value, data } = action.payload;

            if (data) {
                state.display = {
                    ...state.display,
                    ...data,
                };
            } else if (property) {
                state.display[property] = value;
            }

            SessionStorage.Save(SessionStorage.keys.SURVEY_EDITOR_DATA, state);
        },
        displayLanguageChanged: (state, action) => {
            const lang = action.payload;
            state.display.currentLanguage = lang;
            state.survey.debug.previewLanguage = lang;

            handleLocalChanges(state);
            SessionStorage.Save(SessionStorage.keys.SURVEY_EDITOR_DATA, state);
        },
        pageContentCollapsed: (state, action) => {
            const { isCollapsed, pageId, all } = action.payload;
            if (pageId) {
                state.display.collapsedPages[pageId] = isCollapsed;
            }
            if (all) {
                Object.keys(state.survey.content.pagesData).forEach((id) => {
                    state.display.collapsedPages[id] = isCollapsed;
                });
            }
        },
        messageAdded: (state, action) => {
            state.display.message = action.payload;
        },
        messageRemoved: (state, action) => {
            state.display.message = null;
        },
        alertAdded: (state, action) => {
            state.display.alert = action.payload;
        },
        alertRemoved: (state, action) => {
            state.display.alert = null;
        },

        //////////////////
        // Milgo Survey //
        //////////////////
        ...milgoSurveyReduxActions,
    },
    extraReducers: {
        /////////////////
        // fetchSurvey //
        /////////////////
        [fetchSurvey.pending]: (state, action) => {
            state.remoteSurveyFetchStatus = 'loading';
        },
        [fetchSurvey.fulfilled]: (state, action) => {
            if (state.remoteSurveyFetchStatus === 'idle') {
                console.warn(
                    "fetchSurvey.fulfilled: Current status is 'idle', the state has been reset while waiting for response and this action is expired! Aborting."
                );
                state.remoteSurveyFetchStatus = 'failed';
                return;
            }

            state.remoteSurveyFetchStatus = 'succeeded';

            if (state.remoteSurveyUpdateStatus === 'loading') {
                console.warn(
                    '@fetchSurvey.fulfilled: Survey update is still loading. Abort.'
                );
                return;
            }

            const data = action.payload;

            if (data) {
                if (
                    JSON.stringify(state.remoteSurveyData) ===
                    JSON.stringify(data.survey)
                ) {
                    // Nothing to update...
                    return;
                }

                state.remoteSurveyData = data.survey;

                const isLatest =
                    data.survey.updatedAt > state.localChangesTimestamp;

                if (isLatest) {
                    if (data.survey.json) {
                        try {
                            const obj = JSON.parse(data.survey.json);
                            state.survey = obj;
                        } catch (e) {
                            console.error(e);
                        }
                        // console.log( obj );
                    }

                    state.localChangesTimestamp = data.survey.updatedAt;
                }

                if (data.survey.versions) {
                    try {
                        state.surveyVersions = JSON.parse(data.survey.versions);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        },
        [fetchSurvey.rejected]: (state, action) => {
            state.remoteSurveyFetchStatus = 'failed';
        },

        ///////////////////
        // updateSurvey: //
        ///////////////////

        [updateSurvey.pending]: (state, action) => {
            state.remoteSurveyUpdateStatus = 'loading';
        },
        [updateSurvey.fulfilled]: (state, action) => {
            state.remoteSurveyUpdateStatus = 'succeeded';
            const { timestamp } = action.payload;

            if (state.localChangesTimestamp <= timestamp) {
                // If no changes has made by the time it took the response to arrive, mark local changes as done.
                state.localChangesMade = false;
                state.localChangesTimestamp = Date.now();

                // console.log( 'marked as done' );
            }
        },
        [updateSurvey.rejected]: (state, action) => {
            state.remoteSurveyUpdateStatus = 'failed';
            state.localChangesMade = false;
        },
    },
});

export const {
    surveyEditorReset,
    initiateAutoSave,
    surveyIdChanged,
    stateSnapshotCreated,
    restoreFromCache,
    restoreSurvey,
    sourceNameChanged,
    surveyVersionUpdated,
    surveyNameChanged,
    surveySettingsChanged,
    surveyDebugChanged,
    localChangesMade,

    pagesReordered,
    pageAdded,
    pageContentChanged,
    pageTextContentChanged,
    pageDeleted,
    pageDuplicated,
    pageMovedOutOfBlock,
    pageMovedIntoBlock,
    pageMovedBetweenBlocks,

    itemAddedToPage,
    openItemAddedToPage,
    batchAddItemsToPage,
    pageItemsReordered,
    pageItemTextChanged,
    pageItemKeyChanged,
    pageItemPropChanged,
    pageItemDeleted,

    variableAdded,
    variableChanged,
    variableDeleted,

    logicItemAddedToPage,
    logicItemChanged,
    logicItemReordered,
    logicItemDeleted,
    setCurrentLogicItem,

    logicItemConditionChanged,
    logicItemConditionArgChanged,
    logicItemConditionArgRemoved,

    logicItemActionAdded,
    logicItemActionDeleted,
    logicItemActionChanged,
    logicItemActionReordered,

    initMedia,
    mediaChanged,

    displayStateChanged,
    displayLanguageChanged,
    pageContentCollapsed,
    messageAdded,
    messageRemoved,
    alertAdded,
    alertRemoved,
} = surveyEditorSlice.actions;

export default surveyEditorSlice.reducer;

export const selectPageProperty = (state, id, param) => {
    if (!state.surveyEditor.present.survey.content.pagesData[id]) return null;
    return state.surveyEditor.present.survey.content.pagesData[id][param];
};

export const getPageNumber = (state, currentPage, parentBlock) => {
    if (parentBlock) {
        const blockNum =
            state.surveyEditor.present.survey.content.pagesOrder.indexOf(
                parentBlock
            ) + 1;
        const pageNum =
            state.surveyEditor.present.survey.content.pagesData[
                parentBlock
            ].pages.indexOf(currentPage) + 1;

        return `${blockNum}.${pageNum}`;
    } else {
        return (
            state.surveyEditor.present.survey.content.pagesOrder.indexOf(
                currentPage
            ) + 1
        );
    }
};
