import { LocalStorage } from "utils/cacheManager"
import ActivityIndicator from 'components/ActivityIndicator';
import Button from 'components/Button';
import TextInput, { TextTypes } from 'components/TextInput';
import { useCallback, useEffect, useState } from 'react';
import styles from './MilgoLoginStyles.module.css';
import { useDispatch, useSelector } from "react-redux";
import { runMilgoQuery } from "../milgo-query";

const CURRENT_USER = `
	query {
		authenticatedItem {
			... on User {
				id
				fullName
                email
			}
		}
	}
`;

const LOGIN = `
	mutation {
		authenticateUserWithPassword(email: "$email", password: "$password") {
			... on UserAuthenticationWithPasswordSuccess {
				sessionToken
				item {
					id
					fullName
                    email
				}
			}
			... on UserAuthenticationWithPasswordFailure {
				errorMsg: message
			}
		}
	}
`;

export default function MilgoAuthentication({ children }) {

    const isLoggedIn = useSelector(state => state.users.milgoUser.isLoggedIn);
    const dispatch = useDispatch();

    const getCurrentUserData = useCallback(async (token) => {
        const result = await runMilgoQuery(token, CURRENT_USER);

        if (result.error) {
            alert(`Error on loading your user data. Please clear the LocalStorage and login again. Details: ${result.error}`)
        }
        if (result.data?.authenticatedItem) {
            const { id, fullName, email } = result.data.authenticatedItem;
            dispatch({ type: 'users/setMilgoUserData', payload: { id, fullName, email, token, isLoggedIn: true } })
        }
    }, [])

    const handleLoggedIn = useCallback(({ id, fullName, email, token }) => {
        LocalStorage.Save(LocalStorage.keys.MILGO_TOKEN, token);
        dispatch({ type: 'users/setMilgoUserData', payload: { id, fullName, email, token, isLoggedIn: true } })
    }, [])

    useEffect(() => {
        const milgoToken = LocalStorage.Read(LocalStorage.keys.MILGO_TOKEN);
        if (milgoToken) {
            dispatch({ type: 'users/setMilgoUserData', payload: { isLoggedIn: true } })
            getCurrentUserData(milgoToken)
        }
    }, [])

    if (!isLoggedIn) {
        return <MilgoLogin onLogedIn={handleLoggedIn} />
    }

    return (<>{children}</>)
}



function MilgoLogin({ onLogedIn }) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const [error, setError] = useState("");


    const handleLogin = useCallback(async () => {
        setError('')
        setIsloading(true)
        const result = await runMilgoQuery(null, LOGIN.replace('$email', email).replace('$password', password));
        setIsloading(false)
        if (result.error) {
            setError(result.error)
            return;
        }
        if (result.data?.authenticateUserWithPassword) {
            if (result.data?.authenticateUserWithPassword.errorMsg) {
                setError(result.data.authenticateUserWithPassword.errorMsg)
                return;
            }

            const { id, fullName, email } = result.data.authenticateUserWithPassword.item;
            onLogedIn({
                id, fullName, email, token: result.data.authenticateUserWithPassword.sessionToken
            })
        }

    }, [email, password])

    return (<div className={styles.login_root}>
        <form className={styles.formPanel} style={{ pointerEvents: isLoading ? 'none' : 'auto', opacity: isLoading ? 0.6 : 1 }}>
            <img className={styles.logo} src="https://s3.eu-central-1.amazonaws.com/media.milgo.io/l941n4bkja1c0ef0alkh.svg" alt="milgo_logo" />
            <TextInput type={TextTypes.EMAIL} placeholder='email' value={email ?? ''} onChange={({ target }) => setEmail(target.value)} />
            <TextInput type={TextTypes.PASSWORD} placeholder='password' value={password ?? ''} onChange={({ target }) => setPassword(target.value)} onReturn={() => { if (email && password) handleLogin() }} />
            <Button label="Submit" onClick={handleLogin} disabled={!email || !password} indicateActivity={isLoading} />
            {isLoading && <ActivityIndicator size={24} style={{ marginTop: 8 }} />}
            {error && <p className={styles.error}>{error}</p>}
        </form>
    </div>)
}