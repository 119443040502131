import React from 'react';
import styles from './Chip.module.css';
import IconButton from './IconButton';
import Icon from './Icon'
import { GetColor } from '../utils/stylingTools';

// @ TODO:
// export const chipThemes = {
//     NORMAL: {
        
//     },
//     OUTLINED: {

//     }
// }

export default function Chip({

    buttonActive = true,
    label,
    iconName,
    bgStyle, 
    labelStyle,
    onClick,
    onDoubleClick,
    onDelete,
    iconBefore,
    iconStyle,
    onMouseEnter,
    onMouseLeave

}) {

    // let bgColor = '--color-background-hover';
    let borderColor = 'transparent';
    let contentColor = '--color-type-medium-emphasis';

    const btnStyle = {
        // backgroundColor: GetColor( bgColor ),
        borderColor: GetColor( borderColor ),
        color: GetColor( contentColor ),
        font: 'var( --font-body-small )',
        cursor: onClick ? 'pointer' : 'auto',
        
        ...bgStyle,
    }

    const textStyle = {
        ...labelStyle,
    }

    const icnStyle = {
        marginInlineEnd: 4, 
        marginInlineStart: -4, 
        width: 18,

        ...iconStyle
    }

    return (
        <div 
            className={ styles.mainContainer } 
            style={ btnStyle }
            onClick={ onClick }   
            onDoubleClick={ onDoubleClick }      
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
        >
            { iconBefore ? [
                    <Icon 
                        key="icon" 
                        name={ iconBefore }
                        color={ contentColor }
                        size = { 16 }
                        style={ icnStyle }
                    />,
                ] : null
            }

            <p 
                className={ styles.text } 
                style={ textStyle }
            > 
                { label } 
            </p>
            
            <div style={{ flexGrow: 1 }} />
            { buttonActive &&
                <IconButton 
                    name= { iconName }
                    theme = 'Plain'
                    size = 's'
                    colorSet = 'Grayscale'
                    onClick = { onDelete }
                    bgStyle={{
                        marginInlineEnd: -8,
                        marginInlineStart: 2,
                        minWidth: 20,
                        minHeight: 20
                    }}
                />
            }
        </div>
    )

}