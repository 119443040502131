import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { displayStateChanged, pagesReordered } from 'features/survey_editor/surveyEditorSlice';

/**
 * This component should be placed at the top and bottom of a content area item (Page/Block).
 * It's purpose is to mark the target location of a draggable page handle.
 * @param {{
 * pageId: string,
 * blockId: string, // when inside a block 
 * placement: BEFORE/AFTER
 * beforeOffset: number // for fine positioning of the element;
 * afterOffset: number // for fine positioning of the element;
 * }} params
 */
export default function PageDragOverReceiver( { 
    pageId, 
    blockId, 
    placement, 
    beforeOffset = -24,
    afterOffset = 18,
    height = 'calc( 50% - 16px )'
} ) {

    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState( false );
    const [dropError, setDropError] = useState( false );
    const isDraggingToReorder = useSelector( state => state.surveyEditor.present.display.isDraggingToReorder );
    const hasParentBlock = useSelector( state => {
		
		if( !pageId ) return false;
		return state.surveyEditor.present.survey.content.pagesData[ pageId ].parentBlock;

	});

    const getHighlightStyle = useCallback( ( borderPlacement ) => {

        if( isHovered && borderPlacement === placement) {

            return '6px solid var( --color-primary-light )';
        }

        if( dropError && borderPlacement === placement) {
            return '8px solid var( --color-error-medium )';
        }

        return '6px solid transparent';

    }, [isHovered, placement, dropError] )

    useEffect( () => {

        if( dropError ) {
            setTimeout( () => setDropError( false ), 200 )
        }

    }, [dropError])

    const handleClick = useCallback( (e) => {
        // If for some reason this component has been clicked, it means that it receives pointer when it shouldn't.
        // Make sure 'isDraggingToReorder' is off:
        dispatch( displayStateChanged( { property: 'isDraggingToReorder', value: false }))
    }, [dispatch])

    const handleDragEnter = useCallback( (e) => {
        e.preventDefault();
        setIsHovered( true )
    }, [])

    const handleDragLeave = useCallback( (e) => {
        e.preventDefault();
        setIsHovered( false )
    }, [])

    const handleDragOver = useCallback( (e) => {
        e.stopPropagation();
        e.preventDefault();
    }, [])

    const handleDrop = useCallback( (e) => {
        e.preventDefault();
        setIsHovered( false );
        // console.log( e.dataTransfer.getData('text') )
        const { movingPageId, isBlock } = JSON.parse( e.dataTransfer.getData('text') ) // Data from PageDragHandle component

        if( isBlock && hasParentBlock ) {
            setDropError( true );
            return;
        }

        if( movingPageId === pageId || movingPageId === blockId) return;

        dispatch( pagesReordered({ 
            movingPageId, 
            targetPageId: pageId, 
            targetBlockId: blockId,
            placement 
        }));

        dispatch( displayStateChanged( { property: 'isDraggingToReorder', value: false }))

    }, [pageId, blockId, hasParentBlock, placement, dispatch])

    return (
        <div style={{
                zIndex:999,
                position: 'absolute',
                right: 0, left: 0,
                top: placement === 'BEFORE' ? beforeOffset : 'unset',
                bottom: placement === 'AFTER' ? afterOffset : 'unset',
                height,
                // backgroundColor: 'rgba(255,0,0,0.1)',
                pointerEvents: isDraggingToReorder ? 'auto' : 'none',
                borderBottom: getHighlightStyle( 'AFTER' ),
                borderTop: getHighlightStyle( 'BEFORE' ),
            }}

            onClick={ handleClick }

            onDragEnter={ handleDragEnter }
            onDragLeave={ handleDragLeave }
            onDragOver={ handleDragOver }
            onDrop={ handleDrop }
        />

    )
}