import React, { useMemo } from 'react';
import styles from './MediaSettings.module.css';
import propertiesStyles from '../SurveyEditorProperties.module.css';
import PageMediaAssignment from './PageMediaAssignment';
import PageMediaSettings from './PageMediaSettings';
import { useSelector } from 'react-redux';
import { pageTypesConfig } from '../../surveyEditorConfiguration';
import { surveyEditorTexts } from 'utils/appTexts';
import { selectUiLanguage } from 'app/preferencesSlice';
import {
    getPageNumber,
    selectPageProperty,
} from 'features/survey_editor/surveyEditorSlice';

const texts = surveyEditorTexts.properties.media;

const PageMedia = () => {
    const lang = useSelector(selectUiLanguage);
    const currentPage = useSelector(
        (state) => state.surveyEditor.present.display.currentPage
    );
    const pageType = useSelector((state) =>
        selectPageProperty(state, currentPage, 'type')
    );
    const parentBlock = useSelector((state) =>
        selectPageProperty(state, currentPage, 'parentBlock')
    );
    const itemNumber = useSelector((state) =>
        getPageNumber(state, currentPage, parentBlock)
    );

    const title = useMemo(() => {
        return texts.pageMediaTitle[lang].replace('{n}', itemNumber);
    }, [itemNumber, lang]);

    const bgStyle = useMemo(() => {
        if (currentPage && pageType) {
            const pageTypeColor = pageTypesConfig[pageType].color + '1a';

            return {
                backgroundColor: pageTypeColor,
            };
        }

        return {
            backgroundColor: 'var( --color-background-mild )',
        };
    }, [currentPage, pageType]);

    return (
        <>
            {pageType !== 'BLOCK' && itemNumber > 0 && (
                <div className={styles.pageMediaCont} style={bgStyle}>
                    <h4 className={propertiesStyles.modeLabel}>{title}</h4>

                    <PageMediaAssignment />
                    <PageMediaSettings />
                </div>
            )}
        </>
    );
};

export default PageMedia;
