import React, { useMemo } from 'react';
import CascadingSelectionPopper from 'components/poppers/CascadingSelectionPopper';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import {
    variablesTypes,
    argumentsTypes,
    userInputTypes,
} from './LogicConfiguration';
import { getRawText } from 'components/rich_text/RichTextInput';

const texts =
    surveyEditorTexts.properties.logic.itemEditor.referenceDataOptions;

export default function ReferenceDataSelector({
    referenceElement,
    isActive,
    additionalOptions,
    onDismiss,
    onSelect,
    language,
    dir = 'auto',
}) {
    const uiLanguage = useSelector(selectUiLanguage);
    const surveyLang = useSelector(
        (state) => state.surveyEditor.present.display.currentLanguage
    );
    const pagesOrder = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesOrder
    );
    const pagesData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData
    );
    const surveyVariables = useSelector(
        (state) => state.surveyEditor.present.survey.logic.variables
    );

    const lang = useMemo(() => {
        if (language) return language;
        return uiLanguage;
    }, [language, uiLanguage]);

    const options = useMemo(() => {
        const pages = [];
        pagesOrder.forEach((id, inx) => {
            const data = pagesData[id];
            if (data.type === 'BLOCK') {
                data.pages.forEach((blockPageId, blockInx) => {
                    try {
                        const blockPageData = pagesData[blockPageId];
                        const op = {
                            key: blockPageId,
                            label: `${inx + 1}.${blockInx + 1} ${getRawText(
                                blockPageData.title[surveyLang]
                            )}`,
                            subOptions: getPageOptions(blockPageData),
                        };
                        pages.push(op);
                    } catch (err) {
                        console.error(err, 'blockPageId:', blockPageId);
                    }
                });
            } else {
                try {
                    const op = {
                        key: id,
                        label: `${inx + 1} ${getRawText(
                            data.title[surveyLang]
                        )}`,
                        subOptions: getPageOptions(data),
                    };
                    pages.push(op);
                } catch (err) {
                    console.error(err, 'page id:', id);
                }
            }
        });

        function getPageOptions(pageData) {
            const pageOptions = [
                {
                    key: userInputTypes.TIME_SPAN,
                    label: texts.userInputTypes.timeSpan[lang],
                },
            ];

            // @TODO: move these page-specific configurations into surveyEditorConfiguration.pageTypesConfig
            if (
                [
                    'MULTIPLE_CHOICE',
                    'NUMBER',
                    'DROPDOWN_LIST',
                    'OPEN_ENDED_QUESTION',
                ].includes(pageData.type)
            ) {
                pageOptions.push({
                    key: userInputTypes.ANSWER,
                    label: texts.userInputTypes.answer[lang],
                });
            }

            if (['MULTIPLE_CHOICE', 'DROPDOWN_LIST'].includes(pageData.type)) {
                pageOptions.push({
                    key: userInputTypes.ANSWER_TEXT,
                    label: texts.userInputTypes.answerText[lang],
                });
            }

            if (pageData.type === 'SCALE') {
                if (pageData.itemsOrder) {
                    const subOptions = pageData.itemsOrder.map((id) => {
                        return {
                            key: id,
                            label: `${
                                pageData.itemsData[id].key
                            } — ${getRawText(
                                pageData.itemsData[id].text[surveyLang]
                            )}`,
                        };
                    });

                    pageOptions.push({
                        key: userInputTypes.ANSWER,
                        label: texts.userInputTypes.answer[lang],
                        subOptions,
                    });

                    pageOptions.push({
                        key: userInputTypes.ANSWER_INDEX,
                        label: texts.userInputTypes.answerIndex[lang],
                        subOptions,
                    });
                }
            }

            return pageOptions;
        }

        const variablesOptions = surveyVariables.map((v) => {
            return { key: v.id, label: `${v.name} (${v.type})` };
        });

        const constantOptions = [
            {
                key: variablesTypes.NUMBER,
                label: surveyEditorTexts.properties.logic.variablesTypes[
                    variablesTypes.NUMBER
                ][lang],
            },
            {
                key: variablesTypes.STRING,
                label: surveyEditorTexts.properties.logic.variablesTypes[
                    variablesTypes.STRING
                ][lang],
            },
            {
                key: variablesTypes.BOOLEAN,
                label: surveyEditorTexts.properties.logic.variablesTypes[
                    variablesTypes.BOOLEAN
                ][lang],
            },
        ];

        const finalOptions = [
            {
                key: argumentsTypes.SURVEY_VARIABLES.key,
                label: texts.surveyVars[lang],
                subOptions: variablesOptions,
            },
            {
                key: argumentsTypes.USER_INPUT.key,
                label: texts.userInput[lang],
                subOptions: pages,
            },
            {
                key: argumentsTypes.CONSTANT.key,
                label: texts.constant[lang],
                subOptions: constantOptions,
            },
            {
                key: argumentsTypes.RANDOM.key,
                label: texts.random[lang],
            },
            ...additionalOptions,
        ];

        return finalOptions;
    }, [
        lang,
        surveyLang,
        additionalOptions,
        pagesOrder,
        pagesData,
        surveyVariables,
    ]);

    return (
        <CascadingSelectionPopper
            referenceElement={referenceElement}
            isActive={isActive}
            options={options}
            onDismiss={onDismiss}
            onSelect={onSelect}
            dir={dir}
        />
    );
}
