/**
 * 
 * @param {string} url 
 * @param {function} cb // returns { err: "", result: "" }
 */
export function getText( url, cb ){
    // read text from URL location
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.send(null);
    request.onreadystatechange = function () {
        if (request.readyState === 4 ) {
            if( request.status === 200 ) {
                // const type = request.getResponseHeader('Content-Type');
                cb( { err: null, result: request.responseText } );
            } else {
                console.log("Error while loading text file from server", request.statusText);
                cb( { err: request.statusText, result: null } );
             }
        }
    }
}

