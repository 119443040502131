import React, { useState, useRef, useCallback, useMemo } from 'react'
import styles from './Page.module.css';
import IconButton from 'components/IconButton';
import SelectionPopper from 'components/poppers/SelectionPopper';
import { useDispatch, useSelector } from 'react-redux';
import { alertAdded, displayStateChanged, localChangesMade, pageDeleted, pageDuplicated, surveyDebugChanged } from 'features/survey_editor/surveyEditorSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import { modes as propertiesAreaModes } from 'features/survey_editor/properties_area/SurveyEditorProperties';
import { splitViewModes } from 'features/survey_editor/SurveyEditorMain';
import { deletionAlert, useReferenceInLogic } from 'features/survey_editor/logic/LogicReferenceLoseProtection';
import { getSurveyClientUrl } from 'features/survey_editor/survey_services/SurveyClientUrlSolver';

const texts = surveyEditorTexts.content;

const optionsTypes = {
	SETTINGS: "properties",
	PREVIEW: "preview",
	DELETE: "delete",
	DUPLICATE: "duplicate",
}

const duplicationAlert = (lang) => {
	const daTxts = texts.pageOptions.duplicatePageAlert;
	return ({
		title: daTxts.title[ lang ],
		message: daTxts.message[ lang ],
		actions:[ 
			{ label: daTxts.actions.duplicateAnyway[ lang ], theme: 'Plain' },
			{ label: daTxts.actions.cancel[ lang ], theme: 'Plain' }
		]
	})
}

const localUrl = getSurveyClientUrl();
const rootUrl = localUrl;

export default function PageOptions ({id, onDone, lang, parentBlock, display }) {

	const dispatch = useDispatch();
	const optionsBtnRef = useRef();
    const pagesData = useSelector( state => state.surveyEditor.present.survey.content.pagesData );
	const [isOptionsActive, setIsOptionsActive] = useState( false );
    const getRefsInLogic = useReferenceInLogic( id );
	const hasLogicItems = useMemo( () => {

		const pageData = pagesData[id];
		if( 
			pageData.logic && (
				(pageData.logic.onPageEnter && pageData.logic.onPageEnter.length > 0) ||
				(pageData.logic.onPageExit && pageData.logic.onPageExit.length > 0)
			)
 		) {
			return true;
		}

	}, [id, pagesData])

	const surveyId = useSelector( state => state.surveyEditor.present.surveyId );
	const previewUrl = useMemo( () => rootUrl + 'preview/' + surveyId + '?read_debug=true&page_preview_id=' + id, [surveyId, id] );

	const handleSelection = useCallback( ( op, index ) => {
		switch( op.key ) {
			case optionsTypes.SETTINGS: 

				dispatch( displayStateChanged( { property: 'currentPage', value: id } ) );
				dispatch( displayStateChanged( { property: "splitViewMode", value: splitViewModes.NORMAL } ) )
                dispatch( displayStateChanged( { property: "propertiesMode", value: propertiesAreaModes.PAGE } ) )

				setIsOptionsActive( false );
				if( onDone ) onDone();
				break;

			case optionsTypes.PREVIEW:
				dispatch( surveyDebugChanged( {property: 'previewId', value: id } ) );
				dispatch( localChangesMade() );
				setTimeout( () => {
					window.open( previewUrl, '_blank' );
				}, 0.000 );
				setIsOptionsActive( false );
				break;

			case optionsTypes.DELETE:
				setIsOptionsActive( false );
				const refs = getRefsInLogic();

				if( refs.length > 0 ) {

					const alert = deletionAlert( lang );
					alert.message = alert.message.replace( '{item}', texts.page[ lang ] );
					alert.actions[0].callback = () => {
						refs.forEach( ref => {
							if( ref.cleanupCB ) {
								ref.cleanupCB();
							}
						})
						dispatch( 
							pageDeleted( {
								id, 
								parentBlockId: parentBlock ? parentBlock.id : null 
							} ) 
						)
					}
					
					dispatch( alertAdded( alert ));
		
				} else {
					dispatch( 
						pageDeleted( {
							id, 
							parentBlockId: parentBlock ? parentBlock.id : null 
						} ) 
					)
				}

				break;
			case optionsTypes.DUPLICATE:

				if( hasLogicItems ) {
					const alert = duplicationAlert( lang );
					alert.actions[0].callback = () => {
						dispatch( pageDuplicated( id ) );
					}
					dispatch( alertAdded( alert ));
					
				} else {

					dispatch( pageDuplicated( id ) );
				}
				
				setIsOptionsActive( false );
				if( onDone ) onDone(); 
				break;

			default: 
				setIsOptionsActive( false );
				if( onDone ) onDone(); 
				break;
		}
	}, [dispatch, lang, id, hasLogicItems, getRefsInLogic, parentBlock, previewUrl, onDone]);

	return (
		<>
			<div 
				ref={ optionsBtnRef }
				className={ styles.optionsBtnCont } 
				style={{opacity: display ? 1 : 0.3 }}
				>
				<IconButton
					name="options_horizontal"
					theme="Plain"
					colorSet="Grayscale"
					bgStyle={{minWidth: 32, minHeight: 32}}
					iconStyle={{width: 18, height: 18}}
					onClick={ () => setIsOptionsActive( true ) }
					tooltip={ texts.pageOptions.btnTooltip[ lang ] }
					tabIndex='-1'
				/>
			</div>

			<SelectionPopper
				referenceElement={ optionsBtnRef.current }
				isActive={ isOptionsActive }
				options={ [
					{ key: optionsTypes.SETTINGS, label: texts.pageOptions.pageSettings[ lang ] },
					{ key: optionsTypes.PREVIEW, label: texts.pageOptions.pagePreview[ lang ] },
					{ key: optionsTypes.DELETE, label: texts.pageOptions.pageDelete[ lang ] },
					{ key: optionsTypes.DUPLICATE, label: texts.pageOptions.pageDuplicate[ lang ] }, 
				] }
				onDismiss={ () => {
					if( onDone ) onDone();
					setIsOptionsActive( false );
				}}
				onSelect={ handleSelection }
			/>

		</>

	)
}