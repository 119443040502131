
import MilgoAuthentication from "./milgo_login/MilgoAuthentication";
import SurveySetup from 'features/survey_editor/SurveySetup';
import MilgoSurveyEditor from './MilgoSurveyEditor';
import SurveyEditorUndoRedo from 'features/survey_editor/SurveyEditorUndoRedo';
import MilgoSurveySynchronizer from "./MilgoSurveySynchronizer";

export default function MilgoSurveyApp() {
    return (
        <MilgoAuthentication>
            <MilgoSurveySynchronizer />

            <SurveySetup />
            <MilgoSurveyEditor />
            <SurveyEditorUndoRedo />
        </MilgoAuthentication>
    )
}