import {
    initialState as surveyEditorInitData,
    initiateAutoSave,
    surveyIdChanged,
} from 'features/survey_editor/surveyEditorSlice';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSockets from './useSockets';

let socketServerUrl =
    process.env.REACT_APP_MILGO_SOCKET_URL ?? 'http://localhost:3001';

export default function MilgoSurveySynchronizer() {
    const { resourceId } = useParams();
    const dispatch = useDispatch();
    const milgoToken = useSelector((state) => state.users.milgoUser.token);
    const surveyId = useSelector(
        (state) => state.surveyEditor.present.surveyId
    );
    const surveyData = useSelector(
        (state) => state.surveyEditor.present.survey
    );

    const autoSaveInitiated = useSelector(
        (state) => state.surveyEditor.present.autoSaveInitiated
    );

    const isLockedByAnother = useSelector(
        (state) => state.surveyEditor.present.remoteSurveyData?.isLocked
    );

    const userData = useSelector((state) => state.users.milgoUser);

    const [lastEdit, setLastEdit] = useState(null);

    function handleServerSendFullJson(data) {
        if (data?.creationInstructions) {
            // Handle initial survey data:
            const instructions = data.creationInstructions;
            const initialData = produce(
                surveyEditorInitData.survey,
                (draft) => {
                    const lang = instructions.language;
                    draft.surveyName[lang] = instructions.surveyName;
                    draft.debug.previewLanguage = lang;
                    draft.settings.general.supportedLanguages = [
                        instructions.language,
                    ];
                    draft.settings.general.splash.text[lang] =
                        instructions.surveyName;
                }
            );
            console.log(initialData);
            dispatch({
                type: 'surveyEditor/setIncomingSurveyData',
                payload: initialData,
            });
        }
    }

    const socket = useSockets({
        socketServerUrl,
        lastEdit,
        setLastEdit,
        userData,
        isLockedByAnother,
        setIsLockedByAnother: (isLocked) => {
            dispatch({
                type: 'surveyEditor/updateRemoteSurveyData',
                payload: { isLocked, editorName: lastEdit?.editorName },
            });
        },
        document: surveyData,
        initialDocumentData: surveyEditorInitData.survey,
        setDocument: (data) => {
            if (data.creationInstructions) return;
            dispatch({
                type: 'surveyEditor/setIncomingSurveyData',
                payload: data,
            });
        },
        onServerSendFullJson: handleServerSendFullJson,
    });

    useEffect(() => {
        if (resourceId) {
            dispatch(surveyIdChanged(resourceId));
        }
    }, [resourceId]);

    useEffect(() => {
        if (socket.loading) {
            dispatch({
                type: 'surveyEditor/setRemoteSurveyFetchStatus',
                payload: 'loading',
            });
        }
    }, [socket.loading]);

    useEffect(() => {
        if (socket.isSynced && !autoSaveInitiated) {
            dispatch(initiateAutoSave());
        }
        dispatch({
            type: 'surveyEditor/setIsSynced',
            payload: socket.isSynced,
        });
    }, [socket.isSynced, autoSaveInitiated]);

    useEffect(() => {
        if (socket.error) {
            dispatch({
                type: 'surveyEditor/setRemoteSurveyFetchStatus',
                payload: 'failed',
            });
            if (socket.error === 'resource_not_found') {
                alert(
                    'Failed to load survey. Please make sure that the provided resourceId in the URL is valid.'
                );
            }
            if (socket.error === 'TransportError') {
                dispatch({
                    type: 'surveyEditor/updateRemoteSurveyData',
                    payload: {
                        isConnecting: true,
                        status: 'NO_CONNECTION',
                    },
                });
            }
            console.log(socket.error);
        }
    }, [socket.error]);

    //Connect to sockets server
    useEffect(() => {
        // Shutdown existing socket
        if (
            socket.socketStatus === 'connected' &&
            socket.currentResourceId !== surveyId
        ) {
            socket.disconnect();
        }

        if (socket.socketStatus === 'disconnected' && surveyId && milgoToken) {
            socket.connect(surveyId, milgoToken);
        }
    }, [surveyId, socket.socketStatus, socket.currentResourceId, milgoToken]);

    return null;
}
