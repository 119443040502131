import React, { useState, useRef } from 'react';
import styles from './UserSettings.module.css';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '../../components/Button';
import SelectionPopper from '../../components/poppers/SelectionPopper';
import IconButton from '../../components/IconButton';
// import globalStyles from '../../GlobalStyles.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    uiLanguageChanged,
    selectUiLanguage,
} from '../../app/preferencesSlice';
import { userSettingsTexts } from '../../utils/appTexts';
import { userPanelActivation, resetMainUser } from './usersSlice';
import { LocalStorage, SessionStorage } from '../../utils/cacheManager';

// import * as Api from 'api/api';
// import FileInput from 'components/FileInput';

export default function UserSettings(props) {
    const lang = useSelector(selectUiLanguage);
    const userData = useSelector((state) => state.users.mainUser);

    const spBtnRef = useRef(null);
    const [selectionPopperActive, setSelectionPopperActive] = useState(false);
    // const [panelCollapse, setPanelCollapse] = useState( false );
    const panelActive = useSelector((state) => state.users.userPanelActive);
    const dispatch = useDispatch();

    const history = useHistory();

    const isLtr = lang === 'en';

    function setLang(op) {
        const targetLang = op === 'עברית' ? 'he' : 'en';
        dispatch(uiLanguageChanged(targetLang));
        dispatch(userPanelActivation(false));
        setSelectionPopperActive(false);
    }

    function hadleLogOut() {
        LocalStorage.DeleteAll();
        SessionStorage.DeleteAll();
        dispatch(userPanelActivation(false));
        dispatch(resetMainUser());
        history.push('./login');
    }

    return (
        <AnimatePresence>
            {panelActive && (
                <motion.div
                    className={styles.mainCont}
                    initial={{ x: isLtr ? 300 : -300 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.3, ease: 'easeOut' }}
                    exit={{ x: isLtr ? 300 : -300 }}
                >
                    <div className={styles.xBtnCont}>
                        <IconButton
                            name="x"
                            theme="Plain"
                            size="m"
                            colorSet="Grayscale"
                            bgStyle={{
                                width: 32,
                                height: 32,
                                minWidth: 32,
                                minHeight: 32,
                                padding: 0,
                            }}
                            iconStyle={{
                                width: 20,
                                height: 20,
                                margin: -1,
                            }}
                            onClick={() => dispatch(userPanelActivation(false))}
                        />
                    </div>

                    <div className={styles.firstSectionCont}>
                        <div className={styles.userDetailCont}>
                            <div
                                id={styles.profileImage}
                                style={{
                                    backgroundImage:
                                        userData.image &&
                                        userData.image.publicUrl
                                            ? `url("${userData.image.publicUrl}")`
                                            : null,
                                }}
                            />
                            <div id={styles.userDetailTextCont}>
                                <div id={styles.profileName}>
                                    {' '}
                                    {userData.name}{' '}
                                </div>
                                <div id={styles.email}> {userData.email} </div>
                            </div>
                        </div>

                        <Button
                            label={userSettingsTexts.logOut[lang]}
                            theme="Outlined"
                            size="m"
                            colorSet="Grayscale"
                            iconBefore="log_out"
                            bgStyle={{ width: '100%', marginTop: 16 }}
                            onClick={hadleLogOut}
                        />
                    </div>

                    <p id={styles.preferencesLable}>
                        {' '}
                        {userSettingsTexts.preferencesLabel[lang]}{' '}
                    </p>
                    <p id={styles.languageLable}>
                        {' '}
                        {userSettingsTexts.languageLabel[lang]}{' '}
                    </p>
                    <div ref={spBtnRef} key="column_selection_button_wrapper">
                        {' '}
                        {/*div wrapper for SelectionPopper reference*/}
                        <Button
                            label={userSettingsTexts.setLanguage[lang]}
                            theme="Outlined"
                            size={'m'}
                            colorSet="Grayscale"
                            bgStyle={{
                                margin: '10px 0px 0px 0px',
                                width: '100%',
                            }}
                            onClick={() => setSelectionPopperActive(true)}
                            iconBefore="language"
                            iconAfter="small_arrow_down"
                        />
                    </div>

                    <SelectionPopper
                        // key="column_selection_poper"
                        referenceElement={spBtnRef.current}
                        isActive={selectionPopperActive}
                        options={['English', 'עברית']}
                        onDismiss={() => setSelectionPopperActive(false)}
                        onSelect={setLang}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
}
