import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateNotebook } from '../notebookSlice';
import debounce from 'debounce';

const DEBOUNCE_SAVE_DELAY_MS = 500;

export default function NotebookAutoSave( {notebookId} ) {

    const dispatch = useDispatch();
    const localChangesMade = useSelector( state => state.notebook.editor.localChangesMade );

    const remoteDataUpdaetStatus = useSelector( state => state.notebook.remoteDataUpdaetStatus );
    const isEditing = useSelector( state => state.notebook.editor.isEditing );


    const debouncedSave = useMemo( () =>
        debounce( () => {

            dispatch( updateNotebook( notebookId ) );

        }, DEBOUNCE_SAVE_DELAY_MS),
    [dispatch, notebookId]);

    useEffect( () => {
    
        if( localChangesMade ) {
            debouncedSave();
        }

    }, [debouncedSave, localChangesMade])

    useEffect( () => {

        // While the editor is focused (even if the user doesn't type anything), keep calling the server to block other users' changes.
        if( remoteDataUpdaetStatus !== 'loading' && isEditing ) {
            debouncedSave();
        }

    }, [debouncedSave, remoteDataUpdaetStatus, isEditing])

    useEffect( () => {

        // Make sure there are no scheduled executions when unmounted:
        return () => {
            debouncedSave.clear();
        }

    }, [debouncedSave])

    return null;
}
