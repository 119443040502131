import React, { useCallback, useEffect, useState } from 'react';
import styles from  './AnalysisCard.module.css';
import Button from 'components/Button';
import { analysesTexts } from 'utils/appTexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { updateAnalysisResult } from '../analysesSlice';
import { Editor } from '@tinymce/tinymce-react';
import AnalysisNoTitleAlert from './AnalysisNoTitleAlert';


const texts = analysesTexts.cardTexts.cardNote;


export default function AnalysisCardNote({data, isHovered, onActivity}) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage )
    const [note, setNote] = useState( texts.notePlaceholder[ lang ] );
    const [noTitleAlertActive, setNotTitleAlertActive ] = useState( false );


    useEffect( () => {

        if( data.notes ) {
            setNote( data.notes );
        } else {
            setNote( texts.notePlaceholder[ lang ] );
        }

    }, [lang, data.notes])

    const saveNote = useCallback( () => {

        if( !data.notes || data.notes !== note ) {
            onActivity( true );
            dispatch( updateAnalysisResult( {
                id: data.id, 
                prop:'notes', 
                value: note
            } ) )
            .then( () => onActivity( false ));
        }

    }, [dispatch, data, note, onActivity])

    const handleAddBtn = useCallback( () => {

        if( !data.name ) {
            setNotTitleAlertActive( true );
        } else {
            saveNote();
        }

    }, [data, saveNote])

    return (
        <div className={ styles.noteMainCont }>
            {
                data.notes ?
                <div className={ styles.noteContent }>
                
                    <div className={ styles.cardFieldLabel }> { texts.label[ lang ] } </div>
                    <Editor 
                        apiKey="ay5zehwgcz9v6ecka2c7i9wuzc8nclzai43i5fr9thlh34bv"
                        value={ note }
                        initialValue={ note }
                        inline={ true }
                        init={{
                            setup: (editor) => {
                                editor.on('keydown', e => {
                                    if( e.key === 'Tab' ) { e.preventDefault() }
                                })
                            },
                            menubar: false,
                            plugins: ['advlist autolink lists link'],
                            toolbar:
                                `bold italic underline backcolor | 
                                bullist numlist outdent indent`,
                            color_cols: 4,
                            color_map: [
                                "#D0E3FF", "col1",
                                "#D6F9DE", "col2",
                                "#FFE4D1", "col3",
                                "#E8D6FF", "col4",
                                "#FFE1E1", "col5",
                                "#CBF9F3", "col6",
                                "#FFEEBB", "col7",
                                "#C8C8Cd", "col8",
                            ]
                        }}
                        onEditorChange={ (n) => setNote( n )}
                        onBlur={ () => saveNote() }
                    />

                </div>
                :
                <Button
                    label={ texts.addNoteBtn[ lang ]}   
                    theme='Outlined'
                    colorSet='Grayscale'
                    size='s'
                    onClick={ handleAddBtn }
                    bgStyle={{ opacity: isHovered ? 1 : 0.2}}
                    // disabled={ !isHovered }
                />
            }
            <AnalysisNoTitleAlert
                isActive={ noTitleAlertActive }
                item={ 'note' }
                onClose={ () => setNotTitleAlertActive( false) }
            />
        </div>
    )
}