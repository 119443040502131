import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './AnalysesFieldsModules.module.css';
import inputStyles from 'components/InputStyles.module.css';
import DropDown from 'components/DropDown';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import TextInput from 'components/TextInput';
import { fetchReport } from 'app/reportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { validateDataKeyType } from 'features/analyses/analysesUtils';

export default function CorrectionSettingsFieldModule( {project, data, value, handleChange, texts, lang} ) {

    const correctionList = useMemo( () => value, [value] )

    const handleAdd = useCallback( () => {

        const result = correctionList ? Array.from( correctionList ) : [];
        result.push( { key: undefined } )

        handleChange( result );

    }, [correctionList, handleChange] );

    const handleItemChange = useCallback( (index, key, value, isSingleValue) => {

        const result = Array.from( correctionList );

        if( isSingleValue ) {
            
            result[ index ] = {key, value};

        } else {

            result[ index ] = {key, values: value};

        }

        handleChange( result )

    },[correctionList, handleChange])

    const handleDeleteItem = useCallback( (index) => {

        const result = Array.from( correctionList );
        result.splice( index, 1 );

        handleChange( result )

    }, [correctionList, handleChange])

    return (
        <div className={ styles.listFieldContainer } >
            <div className={ styles.frame }/>
            <label className={ inputStyles.label }>{ data.label ? data.label[ lang ] : '' }</label>
            {
                correctionList && correctionList.map( (c,i) => (

                    <CorrectionField
                        key={ 'correction' + i }
                        index={ i }
                        project={ project }
                        fieldData={ data }
                        correctionItemData={ c }
                        texts={ texts }
                        lang={ lang }
                        onChange={ (k,v,s) => handleItemChange( i,k,v,s ) }
                        onDelete={ () => handleDeleteItem( i ) }
                    />
                ))
            }
            <Button
                label={ texts.addCorrectionBtn[ lang ] }
                iconBefore='plus'
                theme='Outlined'
                bgStyle={{width:'100%', padding: '6px 14px', marginTop: 8}}
                onClick={ handleAdd }
            />
        </div>
    )

}


const CorrectionField = ({index, project, fieldData, correctionItemData, texts, lang, onChange, onDelete}) => {

    const dispatch = useDispatch();

    const dataKeys = useSelector( state => {

        const dataKeys = state.report.dataKeys;

        if( !dataKeys ) {
            if( state.report.fetchStatus === 'idle' ) {

                dispatch( fetchReport( project.sourceName ) );
            }

        }

        return dataKeys;
    })

    const dataKeysOptions = useMemo( () => {

        if( !dataKeys ) return null;

        return Object.keys( dataKeys ).map( k => {
            
            const meta = dataKeys[k];
            const isValidType = validateDataKeyType( fieldData, meta );

            if( !isValidType ) return false;

            const option = {key: k, label: `${k} (${meta.type})`};
            return option; 

        }) 

    }, [dataKeys,fieldData])

    const selectedKeyPrefs = useMemo( () => {

        if( dataKeys ) {

            return dataKeys[ correctionItemData.key ];
        }

        return null;

    }, [dataKeys,correctionItemData] )

    const subCategories = useMemo( () => {

        if( selectedKeyPrefs ) {

            if( selectedKeyPrefs.type === 'STRING' && selectedKeyPrefs.valueDescriptions ) {
                return selectedKeyPrefs.valueDescriptions;
            }
        } 

        return null;

    }, [selectedKeyPrefs])

    const isLtr = useMemo( () => lang === 'en', [lang] );
    const [isHovered, setIsHovered] = useState( false );


    ////////////
    // RENDER //
    ////////////

    return (
        <div 
            className={ styles.fieldItemCont }
            onMouseEnter={ () => setIsHovered( true ) }
            onMouseLeave={ () => setIsHovered( false ) }
        >
            {
                isHovered &&
                <IconButton
                    name='x'
                    size='s'
                    theme='Plain'
                    bgStyle={{position:'absolute', top: -2, left: -2, right: -2 }}
                    onClick={ () => onDelete() }
                />
            }

            <DropDown
                label={ `${texts.keyLabel[ lang ]} ${index+1}` }
                options={ dataKeysOptions }
                value={ correctionItemData.key }
                onChange={ (op) => {
                    setIsHovered( false );
                    onChange( op.key, null );
                }}
            />    
            {
                selectedKeyPrefs ? 
                (
                    subCategories ? 
                    <CategoriesSpreadInputs
                        isLtr={ isLtr }
                        subCategories={ subCategories }
                        correctionItemData={ correctionItemData }
                        onChange={ onChange }
                    />
                    :
                    <SingleNumberInput 
                        isLtr={ isLtr } 
                        correctionItemData={ correctionItemData } 
                        onChange={ onChange }
                    />
                )
                :
                null
            }

        </div>
    )

}



const SingleNumberInput = ({isLtr, correctionItemData, onChange}) => {

    const [value, setValue] = useState( correctionItemData.value );

    useEffect( () => {

        if( !correctionItemData.value && correctionItemData.value !== 0 ) setValue( null );

    }, [correctionItemData.value])

    const applyChanges = useCallback ( () => {

        onChange( correctionItemData.key, value, true )

    }, [value, onChange, correctionItemData])

    return (
        <>
            <div 
                className={ styles.relatedFieldsIndicator }
                style={{ 
                    borderLeft: isLtr ? 'var(--border-main )' : 'none',
                    borderRight: isLtr ? 'none' : 'var(--border-main )',
                    left: isLtr ? 12 : 'unset',
                    right: isLtr ? 'unset' : 12,
                }}
            />
            <TextInput
                type='number'
                placeholder={ '#.#' }
                value={ (value !== null && value !== undefined ) ? value : "" }
                numberSettings={ {step: 0.1} }
                onChange={ (e) => setValue( parseFloat( e.target.value ) ) }
                onBlur={ () => applyChanges() }
                onReturn={ () => applyChanges() }
            />
        </>
    )
}

const CategoriesSpreadInputs = ({isLtr, subCategories, correctionItemData, onChange}) => {

    const [values, setValues] = useState( correctionItemData.values ? correctionItemData.values : {} );

    useEffect( () => {

        if( !correctionItemData.values ) setValues( {} );
        
    }, [correctionItemData.values])

    const handleValueChange = useCallback( (key, value) => {

        const result = { ...values };
        result[ key ] = parseFloat( value );

        setValues( result );

    }, [values])

    const applyChanges = useCallback ( () => {

        onChange( correctionItemData.key, values, false )

    }, [values, onChange, correctionItemData])

    return (
        <>
            {
                Object.keys( subCategories ).map( (k,i) => {
                    
                    const value = values[k];

                    return(
                        <div 
                            key={ k+1 }
                            className={ styles.spreadedKeysCont }
                        >
                            <div 
                                className={ styles.subItemIndicator }
                                style={{
                                    borderTop: i === 0 ? 'var( --border-main )' : 'none',
                                    borderLeft: isLtr ? 'var( --border-main )' : 'none',
                                    borderRight: isLtr ? 'none' : 'var( --border-main )',
                                }}
                            />
                            <label 
                                className={ styles.spreadedKeysLabel }
                                style={{
                                    textAlign: isLtr ? 'right' : 'left'
                                }}
                            >
                                { k }
                            </label>
                            <div style={{minWidth:6}}/>
                            <TextInput
                                type='number'
                                placeholder={ '#.#' }
                                value={ (value !== null && value !== undefined ) ? value : "" }
                                numberSettings={ {step: 0.1} }
                                onChange={ (e) => handleValueChange( k, e.target.value ) }
                                onBlur={ () => applyChanges() }
                                onReturn={ () => applyChanges() }
                            />
                        </div>
                    )
                })
            }
        </>
    )

}