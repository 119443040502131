import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import styles from './RecordsTable.module.css';
import ActivityIndicator from 'components/ActivityIndicator';
import IconButton from 'components/IconButton';
import SelectionPopper from 'components/poppers/SelectionPopper';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';
import {
    fetchRecords,
    fetchReport,
    fetchVirtualVarsSettings,
    updateVirtualVars,
    virtualVarDeleted,
    virtualVarsEditorChanged,
} from 'app/reportSlice';
import { resultsTexts } from 'utils/appTexts';
import { VariableSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import Checkbox from 'components/Checkbox';

export default function RecordsTable({
    project,
    showSelection,
    selectedRows,
    onSelectRow,
    onSelectAll,
}) {
    const COUNT_WIDTH = 160;
    const lang = useSelector(selectUiLanguage);
    const isLtr = useMemo(() => lang === 'en', [lang]);
    const columnsOptions = useSelector(
        (state) => state.report.recordsFilterData.desiredKeys
    );
    const recordsData = useSelector((state) => state.report.recordsData);
    const dataKeys = useSelector((state) => state.report.dataKeys);

    const CalculateNumberOfRows = useCallback((value) => {
        var letterInRow = 0;
        var rows = 1;
        for (var i = 0; i < value.length; i++) {
            letterInRow += 1;
            if (value.charAt(i) === '\n' || letterInRow > 22) {
                rows += 1;
                letterInRow = 0;
            }
        }
        return rows;
    }, []);

    const tableData = useMemo(() => {
        if (!columnsOptions || !recordsData || !dataKeys) {
            return { headers: [], rows: [], headerNumberOfRows: 0 };
        }

        const headers = [];
        let headerNumberOfRows = 1;

        if (showSelection) {
            headers.push({ key: '___selection', id: 'row_id' });
        }

        const visibleKeys = columnsOptions.map((col) =>
            col.isChecked ? col.key : null
        );
        recordsData.keysRow.forEach((key, i) => {
            if (visibleKeys.indexOf(key) >= 0) {
                headers.push({ key: key, prefs: dataKeys[key] });
            }
            if (dataKeys[key] && dataKeys[key].title) {
                let numberOfRows =
                    CalculateNumberOfRows(dataKeys[key].title) + 1;
                if (numberOfRows > headerNumberOfRows) {
                    headerNumberOfRows = numberOfRows;
                }
            }
        });

        const rows = recordsData.dataRows.map((row, i) => {
            const rowObj = {};
            let maxNumberOfRows = 0;
            for (let j = 0; j < row.length; j++) {
                const key = recordsData.keysRow[j];
                if (visibleKeys.indexOf(key) >= 0) {
                    const value = row[j];
                    const numberOfRows = CalculateNumberOfRows(value);
                    if (numberOfRows > maxNumberOfRows) {
                        maxNumberOfRows = numberOfRows;
                    }
                    rowObj[key] = row[j];
                }
            }
            return { data: rowObj, maxNumberOfRows: maxNumberOfRows };
        });
        return { headers, rows, headerNumberOfRows };
    }, [
        recordsData,
        dataKeys,
        columnsOptions,
        CalculateNumberOfRows,
        showSelection,
    ]);

    const GetRowHeight = useCallback(
        (rowIndex) => {
            if (rowIndex === 0) {
                const headerNumberOfRows = tableData.headerNumberOfRows;
                return 30 + headerNumberOfRows * 30;
            } else {
                let maxNumberOfRows =
                    tableData.rows[rowIndex - 1].maxNumberOfRows;
                return 45 + maxNumberOfRows * 30;
            }
        },
        [tableData]
    );

    const Cell = useCallback(
        ({ columnIndex, rowIndex, style }) => {
            const key = tableData.headers[columnIndex].key;
            const prefs = tableData.headers[columnIndex].prefs;

            if (key === '___rId') {
                // Hide record id
                return null;
            }

            if (rowIndex === 0) {
                return (
                    <HeaderCell
                        keyName={key}
                        prefs={prefs}
                        project={project}
                        style={style}
                        onSelectAll={onSelectAll}
                    />
                );
            } else {
                if (key === '___selection') {
                    const recordId = tableData.rows[rowIndex - 1]?.data.___rId;
                    return (
                        <SelectionCell
                            style={style}
                            isSelected={selectedRows.includes(recordId)}
                            onSelect={() => {
                                if (onSelectRow) {
                                    onSelectRow(recordId);
                                }
                            }}
                        />
                    );
                }

                if (
                    !tableData.rows[rowIndex - 1] ||
                    !tableData.rows[rowIndex - 1].data
                ) {
                    return '';
                }
                const data = tableData.rows[rowIndex - 1].data[key];
                return <RowCell data={data} style={style} prefs={prefs} />;
            }
        },
        [project, tableData, selectedRows, onSelectRow, onSelectAll]
    );

    if (tableData.headers.length < 1 || tableData.rows.length < 1) {
        return (
            <div className={styles.placeHolderCont}>
                {' '}
                <ActivityIndicator size={18} />{' '}
            </div>
        );
    }

    return (
        <div className={styles.tableContainer}>
            <AutoSizer>
                {({ height, width }) => (
                    <Grid
                        height={height}
                        width={width}
                        rowCount={tableData.rows.length + 1}
                        columnCount={tableData.headers.length}
                        columnWidth={(colIndex) => {
                            if (tableData.headers[colIndex].key === '___rId')
                                return 0;
                            if (colIndex === 0 && showSelection) return 72;
                            return COUNT_WIDTH;
                        }}
                        rowHeight={GetRowHeight}
                        direction={isLtr ? 'ltr' : 'rtl'}
                    >
                        {Cell}
                    </Grid>
                )}
            </AutoSizer>
        </div>
    );
}

const HeaderCell = ({ keyName, prefs, project, style, onSelectAll }) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);

    const updateVarsSettingsStatus = useSelector(
        (state) => state.report.virtualVars.updateVarsSettingsStatus
    );
    const isVirtual = prefs && prefs.originType === 'VIRTUAL';
    const keySplit = useMemo(() => keyName.split('___'), [keyName]);
    const keyDiv = useMemo(() => keySplit[0], [keySplit]);

    const [isUpdating, setIsUpdating] = useState(false);
    const [isHovered, setHovered] = useState(false);

    useEffect(() => {
        if (isUpdating && updateVarsSettingsStatus === 'succeeded') {
            // setIsUpdating( false );
            dispatch(fetchVirtualVarsSettings(project.bucket.id));
            dispatch(fetchReport(project.sourceName));
            dispatch(fetchRecords(project.sourceName));
        }
    }, [updateVarsSettingsStatus, isUpdating, dispatch, project]);

    if (keyName === '___selection')
        return (
            <div
                style={{
                    ...style,
                    backgroundColor: 'var( --color-primary-light )',
                    borderBottom: 'var( --border-main )',
                    backgroundClip: 'padding-box',
                }}
            >
                <IconButton
                    name={'check_all'}
                    tooltip={resultsTexts.sellectAllRows[lang]}
                    theme="Plain"
                    bgStyle={{ margin: 20 }}
                    onClick={onSelectAll}
                />
            </div>
        );

    if (isVirtual)
        return (
            <div
                style={{
                    ...style,
                    backgroundColor: 'var(--color-secondary-light)',
                    borderBottom: 'var( --border-main )',
                    backgroundClip: 'padding-box',
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <div
                    style={{
                        padding: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <p
                            style={{
                                paddingTop: '14px',
                                font: 'var( --font-body-small-bold )',
                            }}
                        >
                            {keyDiv}
                        </p>
                        <p style={{ paddingTop: '5px' }}>
                            {prefs && prefs.title ? prefs.title : keySplit[1]}
                        </p>
                    </div>
                    {isHovered ? (
                        <VirtualCellOptions
                            varKey={keyName}
                            project={project}
                            onUpdate={() => setIsUpdating(true)}
                        />
                    ) : (
                        <div style={{ width: 40 }} />
                    )}
                    {isUpdating && (
                        <div className={styles.cellActivity}>
                            <ActivityIndicator size={24} />
                        </div>
                    )}
                </div>
            </div>
        );

    return (
        <div
            style={{
                ...style,
                backgroundColor: 'var( --color-primary-light )',
                borderBottom: 'var( --border-main )',
                backgroundClip: 'padding-box',
            }}
        >
            <div style={{ padding: '15px' }}>
                <p
                    style={{
                        paddingTop: '14px',
                        font: 'var( --font-body-small-bold )',
                    }}
                >
                    {keyDiv}
                </p>
                <p
                    style={{
                        paddingTop: '5px',
                        font: 'var( --font-body-small )',
                    }}
                >
                    {prefs && prefs.title ? prefs.title : keySplit[1]}
                </p>
            </div>
        </div>
    );
};
const VirtualCellOptions = ({ project, varKey, onDone, onUpdate }) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);

    const optionsBtnRef = useRef();
    const texts = useMemo(() => resultsTexts.virtualVarOptions, []);

    const optionsTypes = useMemo(
        () => ({
            EDIT: 'edit',
            DELETE: 'delete',
        }),
        []
    );

    const [isOptionsActive, setIsOptionsActive] = useState(false);

    const handleSelection = useCallback(
        (op) => {
            switch (op.key) {
                case optionsTypes.EDIT:
                    dispatch(
                        virtualVarsEditorChanged({
                            prop: 'varToEdit',
                            value: varKey,
                        })
                    );
                    break;

                case optionsTypes.DELETE:
                    onUpdate();
                    dispatch(virtualVarDeleted(varKey));
                    dispatch(updateVirtualVars(project.bucket.id));
                    setIsOptionsActive(false);
                    if (onDone) onDone();
                    break;

                default:
                    break;
            }
        },
        [dispatch, optionsTypes, varKey, project, onDone, onUpdate]
    );

    return (
        <>
            <div ref={optionsBtnRef} className={styles.optionsBtnCont}>
                <IconButton
                    name="options"
                    size="s"
                    theme="Plain"
                    colorSet="Grayscale"
                    onClick={() => setIsOptionsActive(true)}
                />
            </div>

            <SelectionPopper
                referenceElement={optionsBtnRef.current}
                isActive={isOptionsActive}
                options={[
                    { key: optionsTypes.EDIT, label: texts.edit[lang] },
                    { key: optionsTypes.DELETE, label: texts.delete[lang] },
                ]}
                onDismiss={() => {
                    if (onDone) onDone();
                    setIsOptionsActive(false);
                }}
                onSelect={handleSelection}
            />
        </>
    );
};

const RowCell = ({ style, data, prefs }) => {
    const isVirtual = prefs && prefs.originType === 'VIRTUAL';
    return (
        <div
            style={{
                ...style,
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
                color: isVirtual
                    ? 'var(--color-secondary-dark)'
                    : 'var(--color-type-high-emphasis)',
                font: 'var( --font-body-small )',
                backgroundClip: 'paddingBox',
                borderCollapse: 'collapse',
                borderBottom: 'var( --border-light )',
            }}
        >
            <div style={{ padding: 16 }}>{data}</div>
        </div>
    );
};

const SelectionCell = ({ style, isSelected, onSelect }) => {
    return (
        <div
            style={{
                ...style,
                backgroundClip: 'paddingBox',
                borderCollapse: 'collapse',
                borderBottom: 'var( --border-light )',
            }}
        >
            <Checkbox
                contStyle={{ padding: '16px 24px' }}
                isChecked={isSelected}
                onChange={onSelect}
            />
        </div>
    );
};
