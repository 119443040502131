import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from 'react';

import styles from './Page.module.css';

import IconButton from 'components/IconButton';
import NewPagePopper from './NewPagePopper';
import PageDragHandle from './PageDragHandle';
import PageOptions from './PageOptions';
import PageDragOverReceiver from './PageDragOverReceiver';
import CollapseButton from 'components/CollapseButton';
import RichTextInput from 'components/rich_text/RichTextInput';

import { pageTypesConfig } from 'features/survey_editor/surveyEditorConfiguration';

import { useDispatch, useSelector } from 'react-redux';
import {
    pageTextContentChanged,
    displayStateChanged,
    surveyDebugChanged,
    pageContentCollapsed,
} from 'features/survey_editor/surveyEditorSlice';
import { selectUiLanguage } from 'app/preferencesSlice';

import { surveyEditorTexts } from 'utils/appTexts';
import { textDirection } from 'utils/stylingTools';
import Icon from 'components/Icon';
// import useOnScreen from 'utils/useOnScreen';

// import { useKeyPress } from 'utils/hooks';

/**
 *
 * @param {{
 * id: string // page id
 * data: object // page data
 * index: integer // index of page at list
 * parentBlock: object // if this page is inside a block, pass this information: {id: "", index: integer }
 * }} params
 */
export default function Page({ id, data, index, parentBlock }) {
    const dispatch = useDispatch();

    const lang = useSelector(selectUiLanguage);
    const surveyLang = useSelector(
        (state) => state.surveyEditor.present.display.currentLanguage
    );
    const currentPage = useSelector(
        (state) => state.surveyEditor.present.display.currentPage
    );
    const isCollapsed = useSelector(
        (state) => state.surveyEditor.present.display.collapsedPages[id]
    );

    const pageContRef = useRef();
    const addBtnRef = useRef();
    const titleInputRef = useRef();

    const [isPopperActive, setIsPopperActive] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    // const [isCollapsed, setIsCollapsed] = useState( false );
    const [isDragging, setIsDragging] = useState(false);

    // const isVisible = useOnScreen( pageContRef );

    // useKeyPress('ArrowDown', () => {

    // 	if( currentPage === id && document.activeElement.tagName === 'BODY' ) {
    // 		console.log( "@TODO: jump to next page")
    // 	}
    // })

    const pageTypeData = useMemo(() => pageTypesConfig[data.type], [data]);
    const TypeIcon = useMemo(() => pageTypeData.icon, [pageTypeData]);
    const Module = useMemo(() => pageTypeData.pageModule, [pageTypeData]);

    useEffect(() => {
        if (
            currentPage === id &&
            titleInputRef.current &&
            titleInputRef.current.value === ''
        ) {
            // focus on a new page title
            titleInputRef.current.focus();
        }
    }, [currentPage, id]);

    //// UTILS ////

    const handleMouseEnter = useCallback((e) => {
        setIsHovered(true);
    }, []);

    const handleMouseLeave = useCallback((e) => {
        setIsHovered(false);
    }, []);

    const handleMouseMove = useCallback(
        (e) => {
            //Prevent parent (block) from receiving mouse move events:
            e.stopPropagation();

            if (!isHovered) {
                setIsHovered(true);
            }
        },
        [isHovered]
    );

    const onNewPagePopperDismiss = useCallback(() => {
        setIsPopperActive(false);
        setIsHovered(false);
    }, []);

    const handleContSelect = useCallback(
        (e) => {
            e.stopPropagation();

            if (currentPage !== id) {
                dispatch(
                    displayStateChanged({ property: 'currentPage', value: id })
                );
                dispatch(
                    surveyDebugChanged({ property: 'previewId', value: id })
                );
                dispatch(
                    surveyDebugChanged({
                        property: 'previewSplash',
                        value: false,
                    })
                );
                // dispatch( displayStateChanged({ property: 'propertiesMode', value: propertiesAreaModes.PAGE}))

                setIsHovered(true);
            }
        },
        [dispatch, currentPage, id]
    );

    const updatePageData = useCallback(
        (prop, value) => {
            dispatch(
                pageTextContentChanged({ id, prop, value, lang: surveyLang })
            );
        },
        [dispatch, id, surveyLang]
    );

    //// RENDER ////

    return (
        <div
            ref={pageContRef}
            className={styles.pageContainer}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onClick={handleContSelect}
            style={{ opacity: isDragging ? 0.3 : 1 }}
        >
            {!isDragging && (
                <>
                    <PageDragOverReceiver pageId={id} placement="BEFORE" />
                    <PageDragOverReceiver pageId={id} placement="AFTER" />
                </>
            )}
            {currentPage === id && (
                <div
                    className={styles.selectedIndicator}
                    style={{
                        backgroundColor: pageTypeData.color + '1a',
                        top: index === 0 ? 0 : -21,
                    }}
                />
            )}
            <div className={styles.itemTopCont}>
                {hasLogic(data) && (
                    <Icon
                        name="logic"
                        size={16}
                        style={{
                            position: 'absolute',
                            top: 24,
                            right: 10,
                            left: 10,
                        }}
                        color={'--color-type-low-emphasis'}
                    />
                )}
                <TypeIcon className={styles.itemIcon} />
                <h2 className={styles.itemIndexLabel}>
                    {parentBlock
                        ? `${parentBlock.index + 1}.${index + 1}`
                        : index + 1}
                </h2>

                <CollapseButton
                    isCollapsed={isCollapsed}
                    display={isHovered}
                    onToggle={() =>
                        dispatch(
                            pageContentCollapsed({
                                isCollapsed: !isCollapsed,
                                pageId: id,
                            })
                        )
                    }
                />
            </div>

            <div className={styles.dragHandlePageCont}>
                <PageDragHandle
                    pageId={id}
                    isHovered={isHovered}
                    onDragStarted={() => setIsDragging(true)}
                    onDragEnded={() => setIsDragging(false)}
                />
            </div>

            <div className={styles.moduleCont}>
                <RichTextInput // Page Title
                    key={'page_title_' + surveyLang}
                    ref={titleInputRef}
                    className={styles.titleInput}
                    textAlignment={textDirection(lang).align}
                    value={data.title[surveyLang] || ''}
                    placeholder={
                        surveyEditorTexts.content.pageTitlePlaceholder[lang]
                    }
                    onChange={(value) => updatePageData('title', value)}
                    onFocus={handleContSelect}
                />

                {!isCollapsed && <Module pageId={id} pageHovered={isHovered} />}
            </div>

            <div className={styles.separationLine} />

            <PageOptions
                id={id}
                onDone={() => setIsHovered(false)}
                lang={lang}
                parentBlock={parentBlock}
                display={isHovered}
            />

            {(isHovered || isPopperActive) && (
                <>
                    <div ref={addBtnRef} className={styles.addBtnCont}>
                        <IconButton
                            name="plus"
                            theme="Outlined"
                            onClick={() => setIsPopperActive(true)}
                        />
                    </div>
                </>
            )}

            <NewPagePopper
                referenceElement={addBtnRef.current}
                isActive={isPopperActive}
                targetIndex={index + 1}
                blockId={parentBlock ? parentBlock.id : null}
                onDismiss={onNewPagePopperDismiss}
                onSelect={onNewPagePopperDismiss}
            />
        </div>
    );
}

function hasLogic(data) {
    const logic = data.logic;

    return (
        logic &&
        ((logic.onPageEnter && logic.onPageEnter.length > 0) ||
            (logic.onPageExit && logic.onPageExit.length > 0))
    );
}
