import React, { useCallback, useMemo } from 'react';
import moduleStyles from './PageModulesStyles.module.css';

import { surveyEditorTexts } from 'utils/appTexts';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import {
    selectPageProperty,
    pageTextContentChanged,
} from 'features/survey_editor/surveyEditorSlice';
import { textDirection } from 'utils/stylingTools';
import RichTextInput from 'components/rich_text/RichTextInput';

const texts = surveyEditorTexts.content.pageModules.opening;

export default function OpeningPageModule({ pageId }) {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const introText = useSelector((state) =>
        selectPageProperty(state, pageId, 'subtitle')
    );
    const surveyLang = useSelector(
        (state) => state.surveyEditor.present.display.currentLanguage
    );

    const settings = useSelector((state) =>
        selectPageProperty(state, pageId, 'settings')
    );

    const showIntro = useMemo(() => {
        try {
            return settings.general.showSubtitle;
        } catch (e) {
            return false;
        }
    }, [settings]);

    const handleTextChange = useCallback(
        (prop, value) => {
            dispatch(
                pageTextContentChanged({
                    id: pageId,
                    prop,
                    value,
                    lang: surveyLang,
                })
            );
        },
        [dispatch, pageId, surveyLang]
    );

    return (
        <>
            {showIntro && (
                <RichTextInput
                    key={'intro_' + surveyLang}
                    className={moduleStyles.editableParagraph}
                    textAlignment={textDirection(lang).align}
                    value={introText ? introText[surveyLang] : ''}
                    placeholder={texts.introTextPlaceholder[lang]}
                    onChange={(raw) => handleTextChange('subtitle', raw)}
                />
            )}
        </>
    );
}
