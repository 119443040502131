import React, { useState, useRef } from 'react'
import styles from './Page.module.css';
import IconButton from 'components/IconButton';
import SelectionPopper from 'components/poppers/SelectionPopper';
import { AlertDialog } from 'components/poppers/Dialog';
import { useDispatch } from 'react-redux';
import { displayStateChanged, pageDeleted, pageMovedOutOfBlock } from 'features/survey_editor/surveyEditorSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import { modes as propertiesAreaModes } from 'features/survey_editor/properties_area/SurveyEditorProperties';

const texts = surveyEditorTexts.content.pageModules.block;

const optionsTypes = {
	SETTINGS: "properties",
	DELETE: "delete"
}

export default function BlockOptions ({id, data, onDone, lang, display }) {

	const optionsBtnRef = useRef();
	const [isOptionsActive, setIsOptionsActive] = useState( false );
	const [isDialogActive, setIsDialogActive] = useState( false );

	const dispatch = useDispatch();

	function handleSelection( op, index ) {
		switch( op.key ) {
			case optionsTypes.SETTINGS: 

				dispatch( displayStateChanged( { property: 'currentPage', value: id } ) );
				dispatch( displayStateChanged( { property: "propertiesCollapsed", value: false } ) )
                dispatch( displayStateChanged( { property: "propertiesMode", value: propertiesAreaModes.PAGE } ) )

				setIsOptionsActive( false );
				if( onDone ) onDone();
				break;

			case optionsTypes.DELETE:
				
				const hasPages = data.pages.length > 0;
				if( !hasPages ) {
					dispatch( 
						pageDeleted( {
							id, 
							parentBlockId: null 
						} ) 
					);
				} else {
					setIsDialogActive( true );
				}
				
				setIsOptionsActive( false );

				break;

			default: 
				if( onDone ) onDone(); 
				setIsOptionsActive( false );
				break;
		}
	}



	return (
		<>
			<div 
				ref={ optionsBtnRef }
				className={ styles.blockOptionsBtnCont } 
				style={{opacity: display ? 1 : 0.3 }}
				>
				<IconButton
					name="options_horizontal"
					theme="Plain"
					colorSet="Grayscale"
					bgStyle={{minWidth: 32, minHeight: 32}}
					iconStyle={{width: 18, height: 18}}
					onClick={ () => setIsOptionsActive( true ) }
					tooltip={ texts.blockOptions.btnTooltip[ lang ]}
				/>
			</div>

			<SelectionPopper
				referenceElement={ optionsBtnRef.current }
				isActive={ isOptionsActive }
				options={ [
					{ key: optionsTypes.SETTINGS, label: texts.blockOptions.blockSettings[ lang ] },
					{ key: optionsTypes.DELETE, label: texts.blockOptions.blockDelete[ lang ] },
				] }
				onDismiss={ () => {
					if( onDone ) onDone();
					setIsOptionsActive( false );
				}}
				onSelect={ handleSelection }
			/>

			<AlertDialog 
                isActive={ isDialogActive }
                style={{
 
                    width: 420, 
                    // height: 150
                }}
                title={ texts.deleteBlockAlert.title[ lang ] }
                message={ texts.deleteBlockAlert.message[ lang ] }
                actions={ [
					{
						label: texts.deleteBlockAlert.actions.cancel[ lang ], 
						callback: () => { if( onDone ) onDone() }
					},
					{
						label: texts.deleteBlockAlert.actions.deleteAll[ lang ], 
						callback: () => {

							for( let i=0; i < data.pages.length; i++ ) {

								dispatch( 
									pageDeleted( {
										id: data.pages[ i ], 
										parentBlockId: id 
									} ) 
								);
							}

							dispatch( 
								pageDeleted( {
									id, 
									parentBlockId: null 
								} ) 
							);
							
							if( onDone ) onDone();
						}
					},
					{
						label: texts.deleteBlockAlert.actions.keepPages[ lang ], 
						theme: "Full",
						callback: () => {

							for( let i=0; i < data.pages.length; i++ ) {

								dispatch( 
									pageMovedOutOfBlock( {
										blockId: id, 
										pageId: data.pages[ i ]
									} ) 
								);
							}

							dispatch( 
								pageDeleted( {
									id, 
									parentBlockId: null 
								} ) 
							);
							if( onDone ) onDone();
						}
					},

                ]}
                onClose={ () => setIsDialogActive( false ) }    
            /> 

		</>

	)
}