import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Api from 'api/api';

const paginationAmount = 25;

var initialState = {
    methods: null,
    selectedMethod: null,
    newAnalysis: {
        isValid: false,
        name: '',
        variables: {},
        isReproduced: false,
    },
    fetchResultsStatus: 'idle',
    resultsPaginationIndex: 0,
    allResultsLoaded: false,

    submittionStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchPositionsInQueueStatus: 'idle',
    fetchSingleResultStatus: 'idle',
    archiveResultsStatus: 'idle',
    updateResultStatus: 'idle',
    
    results: [],
    loadMoreResultsStatus: 'idle',
    loadedResults: [],
    totalResults: -1,
    newResultToFetch: null,

    display: {
        showArchive: false,
        showStared: false
    }
}

////////////////////
// Async actions: //
////////////////////
export const fetchMethods = createAsyncThunk(
    'analyses/fetchMethods',
    async (args, thunkApi) => {

        const response = await Api.GetAnalysesMethods();

        if( response.error ) {
            console.warn( "Error while fetching analyses methods data. Details: ", response.error );
            return thunkApi.rejectWithValue( response.error.statusText );
        } else {
            return response.data;
        }
    }
)

// old gql function - to delete..
export const fetchAnalysesResults = createAsyncThunk(
    'analyses/fetchAnalysesResults',
    async (args, thunkApi) => {

        const { bucketId, isArchived, loadAll } = args;

        const state = thunkApi.getState();
        const resultsPaginationIndex = state.analyses.resultsPaginationIndex;
        const response = await Api.GetAnalysesResults( 
            bucketId, 
            isArchived, 
            (loadAll || isArchived) ? null : resultsPaginationIndex, 
            (loadAll || isArchived) ? null : paginationAmount 
        );

        if( response.error ) {
            console.warn( "Error while fetching analyses results. Details: ", response.error );
            return thunkApi.rejectWithValue( response.error.statusText );
        } else {
            return {data: response.data, isArchived};
        }
    }
)
// new route function
export const loadMoreAnalysesResults = createAsyncThunk(
    'analyses/loadMoreAnalysesResults',
    async (args, thunkApi) => {

        const { bucketId } = args;

        const state = thunkApi.getState();
        const skip = state.analyses.results.length;
        const limit = paginationAmount;
        const response = await Api.FetchAnalysesResults(bucketId, skip, limit);        
        if( response.error ) {
            console.warn( "Error while loading more analyses results. Details: ", response.error );
            return thunkApi.rejectWithValue( response.error.statusText );
        } else {
            var {totalCount, results} = response.data
            return {totalCount, allResults: state.analyses.results.concat(results)};
        }
    }
)

export const fetchAnalysesResultsPositionInQueue = createAsyncThunk(
    'analyses/fetchAnalysesResultsPositionInQueue',
    async (ids, thunkApi) => {

        const response = await Api.GetAnalysesResultPositionInQueue( ids );

        if( response.error ) {
            console.warn( "Error while checking analyses position in queue. Details: ", response.error );
            return thunkApi.rejectWithValue( response.error );
        } else {
            return response.data;
        }
    }
)

export const fetchSingleAnalysisResult = createAsyncThunk(
    'analyses/fetchSingleAnalysisResult',
    async (analysisId, thunkApi) => {

        const response = await Api.GetSingleAnalysisResult( analysisId );

        if( response.error ) {
            console.warn( "Error while fetching a single analysis result. Details: ", response.error );
            return thunkApi.rejectWithValue( response.error.statusText );
        } else {
            return response.data;
        }
    }
)

export const updateAnalysisResult = createAsyncThunk(
    'analyses/updateAnalysisResult',
    async ({id, prop, value}, thunkApi) => {

        const response = await Api.UpdateAnalysisResult( id, prop, value );

        if( response.error ) {
            console.warn( "Error while updating analysis result. Details: ", response.error );
            return thunkApi.rejectWithValue( response.error.statusText );
        } else {
            return {prop, data: response.data};
        }
    }
)

export const archiveAnalysisResults = createAsyncThunk(
    'analyses/archiveAnalysisResults',
    async ({ids, isArchived}, thunkApi) => {

        const response = await Api.ArchiveAnalysesResult( ids, isArchived );

        if( response.error ) {
            console.warn( "Error while archiving analysis results. Details: ", response.error );
            return thunkApi.rejectWithValue( response.error );
        } else {
            return response.data;
        }
    }
)

export const submitNewAnalyses = createAsyncThunk(
    'analyses/submitNewAnalyses',
    async (all, thunkApi) => {
        // const { userId, methodId, bucketId, variables, analysisName } = args;
        // console.log( variables );         
        // const response = await Api.submitNewAnalysesResult( userId, methodId, bucketId, JSON.stringify( variables ), analysisName );
        
        const response = await Api.SubmitNewAnalysesResult( all );

        if( response.error ) {
            console.warn( "Error while submitting new analysis. Details: ", response.error );
            return thunkApi.rejectWithValue( response.error.statusText );
        } else {
            return response.data;
        }
    }
)

///////////
// Utils //
///////////

function validateNewAnalysis( state ) {
    
    const categories = JSON.parse( state.selectedMethod.variablesSettings );
    const vars = state.newAnalysis.variables;
    let isValid = true;

    Object.keys( categories ).forEach( cat => {
        categories[ cat ].fields.forEach( f => {
            if( f.mandatory && !vars[ f.key ] ){
                isValid = false;
                return;
            };
        })
    })

    return isValid;
}

function handleAnalysesPagination( state, data, isArchived ) {

    const results = Array.from( state.results );

    data.results.forEach( (newResult,i) => {
        const existing = results.find( oldResult => oldResult.id === newResult.id );
        if( !existing ) {
            results.push( newResult );
        };
    });

    results.sort( (a,b) => b.createdAt - a.createdAt );

    state.results = results;

    if( !isArchived ) {
        if( data.results.length === paginationAmount ) {
            state.resultsPaginationIndex += paginationAmount;
        } else {
            state.allResultsLoaded = true;
        } 
    }

}

/////////////////
// SLICE ////////
/////////////////
const analysesSlice = createSlice({
    name: 'analyses',
    initialState,
    reducers: {
        resetAnalyses: state => initialState,
        methodSelected: (state, action) => {

            const methodId = action.payload;
            const methodData = state.methods.find( m => m.id === methodId );
            state.selectedMethod = methodData;

            state.newAnalysis.variables = {};
            state.newAnalysis.isReproduced = false;
            state.newAnalysis.isValid = validateNewAnalysis( state );
        },
        analysisNameChanged: (state, action) => {

            const { value } = action.payload;
            state.newAnalysis.name = value;
        },
        analysisVariableChanged: (state, action) => {

            const { key, value } = action.payload;

            state.newAnalysis.variables[ key ] = value;

            state.newAnalysis.isValid = validateNewAnalysis( state );
        },
        analysisReproduced: (state, action) => {

            const id = action.payload;
            const refData = state.results.find( x => x.id === id );
            const refMethod = state.methods.find( m => m.id === refData.method.id );
            const variables = JSON.parse( refData.paramters ).variables;

            state.selectedMethod = refMethod;
            state.newAnalysis.isReproduced = true;
            state.newAnalysis.name = "copy of " + refData.name;
            state.newAnalysis.variables = variables;

            state.newAnalysis.isValid = validateNewAnalysis( state );

        },

        // Analyses Display:
        displayStateChanged( state, action ) {

            const { property, value } = action.payload;

            state.display[ property ] = value;
        },
    }, 
    extraReducers: {
        [fetchMethods.fulfilled]: (state, action) => {
            const data = action.payload;
            if( data ) {
                state.methods = data.methods;
            }
        },

        // Submit New Analyses //
        [submitNewAnalyses.pending]: (state, action) => {
            state.submittionStatus = 'loading';
        },
        [submitNewAnalyses.fulfilled]: (state, action) => {
            state.submittionStatus = 'succeeded';

            state.selectedMethod = initialState.selectedMethod;
            state.newAnalysis = initialState.newAnalysis;

            const data = action.payload;
            const resultIds = data.createAnalysisResults.map( r => r.id );

            state.newResultToFetch = resultIds;

        },
        [submitNewAnalyses.rejected]: (state, action) => {
            state.submittionStatus = 'failed';
        },

        // Fetch Positions In Queue //
        [fetchAnalysesResultsPositionInQueue.pending]: (state, action) => {
            state.fetchPositionsInQueueStatus = 'loading';
        },
        [fetchAnalysesResultsPositionInQueue.fulfilled]: (state, action) => {
            state.fetchPositionsInQueueStatus = 'succeeded';
            const data = action.payload;

            if( data && data.positions ) {
                // console.log( "got positions: ", data.positions );
                Object.entries( data.positions ).forEach( ([id,pos]) => {
                    
                    const resultInx = state.results.findIndex( x => x.id === id );
                    if( resultInx > -1 ) {
                        state.results[ resultInx ].posInQueue = pos;
                    }
                })
            }
            
        },
        [fetchAnalysesResultsPositionInQueue.rejected]: (state, action) => {
            state.fetchPositionsInQueueStatus = 'failed';
        },

        // Fetch Analyses Results // to Delete
        [fetchAnalysesResults.pending]: (state, action) => {
            state.fetchResultsStatus = 'loading';
        },
        [fetchAnalysesResults.fulfilled]: (state, action) => {
            state.fetchResultsStatus = 'succeeded';
            const {data, isArchived} = action.payload;
            if( data ) {
                handleAnalysesPagination( state, data, isArchived );
            }

        },
        [fetchAnalysesResults.rejected]: (state, action) => {
            state.fetchResultsStatus = 'failed';
        },

        // Load More Analyses Results // new function..
        [loadMoreAnalysesResults.pending]: (state, action) => {
            state.loadMoreResultsStatus = 'loading';
        },
        [loadMoreAnalysesResults.fulfilled]: (state, action) => {
            state.loadMoreResultsStatus = 'succeeded';
            const {allResults, totalCount} = action.payload;
            state.results = allResults
            state.totalResults = totalCount
        },
        [loadMoreAnalysesResults.rejected]: (state, action) => {
            state.loadMoreResultsStatus = 'failed';
        },

        // Fetch a Single Analyses Results //
        [fetchSingleAnalysisResult.pending]: (state, action) => {
            state.fetchSingleResultStatus = 'loading';
        },
        [fetchSingleAnalysisResult.fulfilled]: (state, action) => {
            state.fetchSingleResultStatus = 'succeeded';

            state.newResultToFetch = null;

            const data = action.payload;
            if( data ) {
                // console.log( data );
                const newResults = Array.from( state.results );
                const itemInx = newResults.findIndex( x => x.id === data.result.id );
                
                if( itemInx >= 0) {
                    newResults[ itemInx ] = data.result;
                } else {
                    newResults.push( data.result );
                }

                newResults.sort( (a,b) => b.createdAt - a.createdAt );

                state.results = newResults;
            }
            
        },
        [fetchSingleAnalysisResult.rejected]: (state, action) => {
            state.fetchSingleResultStatus = 'failed';
        },

        // Update Analysis Result //
        [archiveAnalysisResults.pending]: (state, action) => {
            state.archiveResultsStatus = 'loading';
        },
        [archiveAnalysisResults.fulfilled]: (state, action) => {
            state.archiveResultsStatus = 'succeeded';

            state.newResultToFetch = null;

            const data = action.payload;

            if( data ) {

                data.results.forEach( res => {
                    const itemIndex = state.results.findIndex( x => x.id === res.id );

                    if( itemIndex >= 0 ) {
                        state.results[ itemIndex ].isArchived = res.isArchived;
                    }
                })

            }
            
        },
        [archiveAnalysisResults.rejected]: (state, action) => {
            state.archiveResultsStatus = 'failed';
        },

        // Update Analysis Result //
        [updateAnalysisResult.pending]: (state, action) => {
            state.updateResultStatus = 'loading';
        },
        [updateAnalysisResult.fulfilled]: (state, action) => {
            state.updateResultStatus = 'succeeded';

            state.newResultToFetch = null;

            const data = action.payload.data;
            // const prop = action.payload.prop;
            if( data ) {
                // console.log( prop, data );
                // Merge updated data with the result item:
                const itemInx = state.results.findIndex( x => x.id === data.result.id );
                state.results[ itemInx ] = Object.assign( state.results[ itemInx ], data.result );
            }
            
        },
        [updateAnalysisResult.rejected]: (state, action) => {
            state.updateResultStatus = 'failed';
        },


    }
})

export const { 
    resetAnalyses,
    methodSelected,
    analysisVariableChanged,
    analysisNameChanged,
    analysisReproduced,
    displayStateChanged
 } = analysesSlice.actions;

export default analysesSlice.reducer;


