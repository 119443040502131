import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { fetchTeamsData, fetchSomeTeamProjects, selectCurrentTeam, selectCurrentTeamLastVisitedProjects, selectCurrentTeamPinnedProject, syncPinnedAndVisitedProjects } from 'app/horizonDataSlice';
import { resetReport } from '../../app/reportSlice';
import { resetNotebook } from 'features/notebook/notebookSlice';
import { surveyEditorReset } from 'features/survey_editor/surveyEditorSlice';
import { resetAnalyses } from 'features/analyses/analysesSlice';
import CreateProjectRow from './CreateProjectRow';
import QuickAccessProjectsRow from './QuickAccessProjectsRow';
import ProjectsGrid from './ProjectsGrid';
import LabelPopper from './LabelsPopper';
import PermissionPopper from './PermissionPopper';
import DetailsPopper from './DetailsPopper';
import HomeSidePanel from './HomeSidePanel';
import SearchProjectsBox from './SearchProjectsBox';
import { homeTexts } from '../../utils/appTexts';
import styles from './Home.module.css';
import * as SocketApi from 'api/socketApi';

const MAX_ITEMS = 200

export default function Home(props) {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const showArchive = useSelector(state => state.horizonData.showArchive);
    const lastProjectsOpenings = useSelector(selectCurrentTeamLastVisitedProjects);
    const pinnedProjects = useSelector(selectCurrentTeamPinnedProject);
    const currentTeam = useSelector(selectCurrentTeam);
    const currentLabelId = useSelector(state => state.horizonData.currentLabelId);
    const fetchTeamsDataStatus = useSelector(state => state.horizonData.fetchTeamsDataStatus);
    const fetchTeamProjectsStatus = useSelector(state => state.horizonData.fetchTeamProjectsStatus);

    useEffect(() => {
        if (fetchTeamsDataStatus === 'idle') {
            dispatch(fetchTeamsData());
        }

        //Reset redux stores to make sure previously visited project data is cleaned before choosing another.
        dispatch(resetReport());
        dispatch(surveyEditorReset());
        dispatch(resetAnalyses());
        dispatch(resetNotebook());
        SocketApi.ResetSocket()
    }, [fetchTeamsDataStatus, dispatch]);

    useEffect(() => {
        if (fetchTeamsDataStatus === 'succeeded' && fetchTeamProjectsStatus === 'idle') {
            dispatch(fetchSomeTeamProjects());
            dispatch(syncPinnedAndVisitedProjects());
        }
    }, [fetchTeamsDataStatus, fetchTeamProjectsStatus, dispatch]);

    const homePageData = useMemo(() => {
        if (!currentTeam || !currentTeam.projects) {
            return { teamProjects: [], quickAccessProjects: [] }
        }
        var allProjects = []
        pinnedProjects.forEach(project => {
            var realProject = currentTeam.projects.find(p => p.id === project.id)
            if (realProject) {
                allProjects.push({ ...realProject, isPinned: true })
            } else {
                allProjects.push({ ...project, isPinned: true })
            }
        })

        currentTeam.projects.forEach(project => {
            if (!project.isArchived && !project.isTemplate && !pinnedProjects.find(p => p.id === project.id) && !allProjects.find(p => p.id === project.id)) {
                allProjects.push(project)
            }
        })



        return {
            quickAccessProjects: lastProjectsOpenings ? lastProjectsOpenings.slice(0, Math.min(8, lastProjectsOpenings.length)).map(mark => {
                var projectFromMemory = mark.project
                var realProject = allProjects.find(p => p.id === projectFromMemory.id)
                if (realProject) {
                    return ({
                        ...realProject,
                        lastOpeningDate: mark.time,
                        lastOpeningRoute: mark.route,
                        isPinned: pinnedProjects.find(p => p.id === mark.project.id),
                    });
                } else {
                    return ({
                        ...mark.project,
                        labels: mark.project.labels ? mark.project.labels.map(l => l.id) : [],
                        lastOpeningDate: mark.time,
                        lastOpeningRoute: mark.route,
                        isPinned: pinnedProjects.find(p => p.id === mark.project.id),
                    });
                }
            }) : [],
            allProjects: allProjects.slice(0, Math.min(MAX_ITEMS, allProjects.length)),
        }
    }, [currentTeam, pinnedProjects, lastProjectsOpenings])

    const labelPageData = useMemo(() => {

        if (!currentTeam || !currentTeam.projects || !currentLabelId) {
            return { gridTitle: '', projects: [] }

        }

        const label = currentTeam.labels ? currentTeam.labels.find(l => l.id === currentLabelId) : []
        if (!showArchive) {
            var projects;
            if (label && currentLabelId !== 'all') {
                projects = currentTeam.projects ? currentTeam.projects.filter(p => p.labels && p.labels.indexOf(currentLabelId) >= 0 && !p.isArchived && !p.isTemplate) : []
                return {
                    gridTitle: <span><span>{homeTexts.projectsView.projectsWithLabels[lang]} <span style={{ color: label.color, font: 'var( --font-headline-h3-bold)' }}>{label.name}</span>:</span></span>,
                    projects: projects.slice(0, Math.min(MAX_ITEMS, projects.length))
                }
            } else {
                projects = currentTeam.projects ? currentTeam.projects.filter(p => !p.isArchived && !p.isTemplate) : []
                return {
                    gridTitle: <span>{homeTexts.projectsView.allProjects[lang]}</span>,
                    projects: projects.slice(0, Math.min(MAX_ITEMS, projects.length))
                }
            }
        } else {
            if (label && currentLabelId !== 'all') {
                return {
                    gridTitle: <span><span>{homeTexts.projectsView.allArchivedProjectsWithLabel[lang]} <span style={{ color: label.color, font: 'var( --font-headline-h3-bold)' }}>{label.name}</span>:</span></span>,
                    projects: currentTeam.projects ? currentTeam.projects.filter(p => p.isArchived && !p.isTemplate && p.labels && p.labels.indexOf(currentLabelId) >= 0 && p.isArchived) : []
                }
            } else {
                return {
                    gridTitle: <span>{homeTexts.projectsView.allArchivedProjects[lang]} </span>,
                    projects: currentTeam.projects ? currentTeam.projects.filter(p => p.isArchived && !p.isTemplate) : []
                }
            }
        }
    }, [currentTeam, currentLabelId, showArchive, lang])

    return (
        <div id="top_bar">
            <div id="projects_view_cont" className={styles.mainContainer}>
                <HomeSidePanel />
                <div className={styles.contentContainer}>

                    {/* Home Page Comps */}
                    {currentLabelId === '' && <div>
                        <SearchProjectsBox />
                        <CreateProjectRow />
                        {homePageData.quickAccessProjects && homePageData.quickAccessProjects.length > 0 && <QuickAccessProjectsRow projects={homePageData.quickAccessProjects} />}
                        <ProjectsGrid title={homeTexts.projectsView.teamProject[lang]} projects={homePageData.allProjects} loadMoreMode='auto' maxItems={MAX_ITEMS} />
                    </div>}

                    {/* Label Page Comps */}
                    {currentLabelId !== '' && <div>
                        <ProjectsGrid title={labelPageData.gridTitle} projects={labelPageData.projects} loadMoreMode='manual' maxItems={MAX_ITEMS} />
                    </div>}

                    <LabelPopper lang={lang} />
                    <PermissionPopper lang={lang} />
                    <DetailsPopper lang={lang} />
                </div>
            </div>
        </div>
    )
}