import React, { useState, useEffect } from 'react';
import styles from './TopBar.module.css';
import IconButton from 'components/IconButton';
// import globalStyles from 'GlobalStyles.module.css';

import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { selectProjectById } from 'app/horizonDataSlice';
import { appSectionsTexts } from 'utils/appTexts';
import { userPanelActivation } from '../users/usersSlice';

export default function TopBar(props) {

    const location = useLocation();
    const atHome = location.pathname === '/home';
    const userData = useSelector(state => state.users.mainUser);
    const [currentPage, setCurrentPage] = useState('');
    const history = useHistory();
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const { projectId } = useParams();
    const project = useSelector(state => selectProjectById(state, projectId));

    useEffect(() => {

        const page = location.pathname.split('/')[1];
        setCurrentPage(page);

    }, [location])

    useEffect(() => {

        if (project) {

            if (project === '404') {
                console.error('Project id not found');
                history.push('/404');
                return;
            }

            document.title = `${project.name} ${currentPage}`;
        }

    }, [project, currentPage, history])

    // function toggleLang() {
    //     const targetLang = lang === 'en' ? 'he' : 'en';
    //     dispatch( uiLanguageChanged( targetLang ) )
    // }

    const selectStyle = {
        color: 'var( --color-type-high-emphasis )',
        borderBottom: '1px solid black',
    }

    return (
        <div className={styles.rootCont}>
            <div className={styles.logoSection}>

                <Link to='/home'>
                    <h2 className={styles.logo}>Horizon</h2>
                </Link>
                {
                    !atHome &&
                    [
                        <div key="border" className={styles.border} />,
                        <h3 key="projectName" className={styles.projectName}> {project ? project.name : ""}</h3>
                    ]
                }
            </div>

            {
                (!atHome && project) ?
                    <div className={styles.navButtons}>
                        {project.notebook &&
                            <Link
                                className={styles.navLink}
                                to={`/notebook/${projectId}`}
                                style={currentPage === 'notebook' ? selectStyle : {}}
                            >
                                {appSectionsTexts.notebook[lang]}
                            </Link>
                        }
                        {project.survey &&
                            <Link
                                className={styles.navLink}
                                to={`/survey/${projectId}`}
                                style={currentPage === 'survey' ? selectStyle : {}}
                            >
                                {appSectionsTexts.survey[lang]}
                            </Link>
                        }
                        {/* <Link 
                        className={styles.navLink} 
                        to={`/distribution/${projectId}`} 
                        style={ currentPage === 'distribution' ? selectStyle : {}}
                    >
                        { appSectionsTexts.distribution[ lang ] }
                    </Link>  */}
                        {project.bucket &&
                            <>
                                {/* 
                            insights temporarily removed. 18/11/2020
                            <Link 
                                className={styles.navLink} 
                                to={`/insights/${projectId}`} 
                                style={ currentPage === 'insights' ? selectStyle : {}}
                            >
                                { appSectionsTexts.insights[ lang ] }
                            </Link> */}
                                {!project.isTemplate && <Link
                                    className={styles.navLink}
                                    to={`/results/${projectId}`}
                                    style={currentPage === 'results' ? selectStyle : {}}
                                >
                                    {appSectionsTexts.results[lang]}
                                </Link>
                                }
                                <Link
                                    className={styles.navLink}
                                    to={`/analyses/${projectId}`}
                                    style={currentPage === 'analyses' ? selectStyle : {}}
                                >
                                    {appSectionsTexts.analyses[lang]}
                                </Link>
                            </>
                        }
                    </div>

                    :

                    null
            }

            <div className={styles.barEndCont}>
                {/* @TEMP */}
                {/* <Link className={styles.navLink} to={ "/compcatalogue/" }>Comps</Link> */}

                <IconButton
                    name="avatar"
                    theme='Outlined'
                    size='m'
                    colorSet='Grayscale'
                    bgStyle={{
                        width: 32,
                        height: 32,
                        minWidth: 32,
                        minHeight: 32,
                        padding: 0,
                        borderWidth: 0,
                    }}
                    iconStyle={{
                        width: 34,
                        height: 34,
                        margin: -1
                    }}
                    imageUrl={userData.image ? userData.image.publicUrl : null}
                    onClick={() => dispatch(userPanelActivation(true))}
                />
            </div>
        </div>
    )
}