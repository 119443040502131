import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import moduleStyles from './SelectionPopper.module.css';
import Modal from './Modal';
import PopperPanel from './PopperPanel';
import Icon from '../Icon';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';

/**
 * @param {{
 * referenceElement: object // Node element as a reference for placing the popper.
 * isActive: boolean // Is selection popper active?
 * options: Array // Options to choose from. [{ key: "category", label: "", subOptions: [{key: "op1", label: ""}] }, ...]
 * onSelect: function // On user select option. returns the option's key and if there are sub options, returns a string of parents and children, separated by period: "parent_key.sub_key"
 * onDismiss: function // On cancel selection
 * }} params
 */
export default function CascadingSelectionPopper({
    referenceElement,
    isActive,
    options,
    onSelect,
    onDismiss,
    dir = 'auto',
}) {
    const lang = useSelector(selectUiLanguage);
    const isLtr = useMemo(() => {
        return lang === 'en' || dir === 'ltr';
    }, [lang, dir]);
    const [rootElement, setRootElement] = useState(null);
    const [currentSubItem, setCurrentSubItem] = useState('');

    const placement = useMemo(() => {
        switch (dir) {
            case 'ltr':
                return 'bottom-start';
            case 'rtl':
                return 'bottom-end';
            default:
                return '';
        }
    }, [dir]);

    const handleSelect = useCallback(
        (option) => {
            if (onSelect) {
                onSelect(option);
            } else {
                console.error(
                    'onSelect callback is not defined for a SelectionPopper instance.'
                );
            }
        },
        [onSelect]
    );

    const handleDismiss = useCallback(() => {
        if (onDismiss) {
            onDismiss();
        } else {
            console.error(
                'onDismiss callback is not defined for a SelectionPopper instance.'
            );
        }
    }, [onDismiss]);

    return (
        <Modal
            isActive={isActive}
            onClose={handleDismiss}
            bgBlur={'low'}
            getRoot={(root) => setRootElement(root)}
        >
            <PopperPanel
                referenceElement={referenceElement}
                placement={placement}
                panelStyle={{ maxWidth: 300 }}
            >
                <div className={moduleStyles.listContainer} dir={dir}>
                    <ul className={moduleStyles.list}>
                        {options &&
                            options.map((op, i) => {
                                return (
                                    <ListItem
                                        key={op.key + i}
                                        isLtr={isLtr}
                                        rootElement={rootElement}
                                        label={op.label}
                                        subOptions={op.subOptions}
                                        onSelect={(subKey) =>
                                            handleSelect(
                                                solveSubKey(subKey, op.key)
                                            )
                                        }
                                        onItemMouseEnter={() =>
                                            setCurrentSubItem(op.key)
                                        }
                                        forceClose={currentSubItem !== op.key}
                                    />
                                );
                            })}
                    </ul>
                </div>
            </PopperPanel>
        </Modal>
    );
}

const ListItem = ({
    isLtr,
    rootElement,
    label,
    subOptions,
    onSelect,
    onItemMouseEnter,
    forceClose,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentSubItem, setCurrentSubItem] = useState('');
    const itemRef = useRef();

    const [activation, setActivation] = useState(false);

    useEffect(() => {
        if (forceClose) setIsOpen(false);
    }, [forceClose]);

    // Handle item activation:
    useEffect(() => {
        const activationHanlder = setTimeout(() => {
            if (activation) {
                if (subOptions) setIsOpen(true);
                if (onItemMouseEnter) onItemMouseEnter();
                setActivation(false);
            }
        }, 120);

        return () => clearTimeout(activationHanlder);
    }, [activation, subOptions, onItemMouseEnter]);

    const handleClick = useCallback(
        (e) => {
            e.stopPropagation();

            if (subOptions) {
                setIsOpen(!isOpen);
            } else {
                onSelect();
            }
        },
        [onSelect, subOptions, isOpen]
    );

    const handleItemMouseEnter = useCallback((e) => {
        e.stopPropagation();
        setActivation(true);
    }, []);

    const handleItemMouseLeave = useCallback((e) => {
        e.stopPropagation();
        setActivation(false);
    }, []);

    return (
        <>
            <li
                ref={itemRef}
                className={moduleStyles.listItem}
                onClick={handleClick}
                onBlur={() => setIsOpen(false)}
                onMouseEnter={handleItemMouseEnter}
                onMouseLeave={handleItemMouseLeave}
            >
                <span className={moduleStyles.listItemLabel}>{label}</span>
                {subOptions && (
                    <Icon
                        name={
                            isLtr ? 'chevron_right_arrow' : 'chevron_left_arrow'
                        }
                        size={18}
                    />
                )}
                {subOptions && isOpen && (
                    <PopperPanel
                        referenceElement={itemRef.current}
                        placement={isLtr ? 'right-start' : 'left-start'}
                        panelStyle={{
                            marginTop: -12,
                            maxWidth: 300,
                        }}
                        offset={[0, 0]}
                        boundary={rootElement}
                    >
                        <div className={moduleStyles.subOptionsCont}>
                            <ul className={moduleStyles.list}>
                                {subOptions.map((op, i) => {
                                    return (
                                        <ListItem
                                            key={op.key + i}
                                            isLtr={isLtr}
                                            rootElement={rootElement}
                                            label={op.label}
                                            subOptions={op.subOptions}
                                            onSelect={(subKey) =>
                                                onSelect(
                                                    solveSubKey(subKey, op.key)
                                                )
                                            }
                                            onItemMouseEnter={() =>
                                                setCurrentSubItem(op.key)
                                            }
                                            forceClose={
                                                currentSubItem !== op.key
                                            }
                                        />
                                    );
                                })}
                            </ul>
                        </div>
                    </PopperPanel>
                )}
            </li>
        </>
    );
};

function solveSubKey(sub, parent) {
    return sub ? parent + '.' + sub : parent;
}
