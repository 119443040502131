const milgoServerURL =
    process.env.REACT_APP_MILGO_SERVER_URL ?? 'http://localhost:3000';
const queryServerURL = `${milgoServerURL}/api/graphql/`;

export async function runMilgoQuery(milgoToken, queryStr) {
    const response = { error: null, data: null };
    await fetch(queryServerURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${milgoToken}`,
        },
        body: JSON.stringify({ query: queryStr }),
    })
        .then((result) => {
            if (!result.ok) {
                throw result;
            }
            return result.json();
        })
        .then((json) => {
            response.data = json.data;
        })
        .catch((err) => {
            response.error = err;
        });

    return response;
}
