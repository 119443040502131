export const milgoAdminUrl =
    process.env.REACT_APP_MILGO_ADMIN_URL ?? 'http://localhost:3000';

export const getTopicResources = async (resourceId, token) => {
    let result = { data: null, error: null };
    await fetch(
        `${milgoAdminUrl}/get-resource-topic-resources?resourceId=${resourceId}&sessionToken=${token}`,
        {
            method: 'get',
            cache: 'no-store',
        }
    )
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            if (!json) {
                throw new Error('Server sent bad data.');
            }
            if (json.status !== 'ok') {
                throw new Error(json.errorMessage);
            }
            result = json;
        })
        .catch((error) => {
            if (!result.error) result.error = error;
        });

    return result;
};
