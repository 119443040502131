import React, { /*useState, useRef*/ } from 'react';
import Modal from '../../components/poppers/Modal';
import PopperPanel from '../../components/poppers/PopperPanel';
import styles from './DetailsPopper.module.css';
import Icon from '../../components/Icon';
import Chip from '../../components/Chip';
import { homeTexts } from '../../utils/appTexts';
import Emoji from '../../components/Emoji';


export default function DetailsPopper({lang, isActive, referenceElement, onDismiss, onApply}) {
  

    return (
        <Modal 
            isActive={ false }
            onClose={ onDismiss }
            bgBlur={ "low" }
        >
            <PopperPanel 
                referenceElement={ referenceElement }
            >
                <div className={ styles.mainCont }>
                    <div className={ styles.blockCont }>
                        <p className={ styles.title }> { homeTexts.detailsPopper.detailsTitle[ lang ] } </p>
                        <p className={ styles.detailText } > <b>{ homeTexts.detailsPopper.targetPlatformText[ lang ] }:</b> Madad (temp) </p>
                        <p className={ styles.detailText }> <b>{ homeTexts.detailsPopper.versionText[ lang ] }:</b> 2 (temp)</p>
                        <p className={ styles.detailText }> <b>{ homeTexts.detailsPopper.publishedText[ lang ] }:</b> 6:45pm 11/10/2020 (temp)</p>
                        <p className={ styles.detailText }> <b>{ homeTexts.detailsPopper.lastChangeText[ lang ] }:</b> 1:45pm 18/10/2020 (temp)</p>
                    </div>

                    <div className={ styles.blockCont }>
                        <p className={ styles.title }> { homeTexts.detailsPopper.accessTitle[ lang ] } </p>
                        <div id={ styles.accessCont }>
                            <Icon 
                                name="avatar" 
                                style = {{margin: 2}}
                                size = {36}
                            />
                            <Icon 
                                name="avatar" 
                                style = {{margin: 2}}
                                size = {36}
                            />
                            <Icon 
                                name="avatar" 
                                style = {{margin: 2}}
                                size = {36}
                            />
                            <Icon 
                                name="avatar" 
                                style = {{margin: 2}}
                                size = {36}
                            />
                            <Icon 
                                name="avatar" 
                                style = {{margin: 2}}
                                size = {36}
                            />
                        </div>
                    </div>
                    
                    <div className={ styles.blockCont }>
                        <p className={ styles.title }> { homeTexts.detailsPopper.emojiTitle[ lang ] } </p>
                        <div id={ styles.emojiCont}>
                            <Emoji symbol="🍎"/>
                        </div>
                    </div>
                    
                    <div className={ styles.blockCont } style={{ marginBottom: 0 }}>
                        <p className={ styles.title }> { homeTexts.detailsPopper.labelsTitle[ lang ] } </p>
                        <Chip 
                            buttonActive = { false } 
                            label = 'Label A'
                            bgStyle = {{display: 'inline-block', height: 24, margin: '4px 4px 4px 4px', backgroundColor: '#FCE4EC'}}
                        />
                        <Chip 
                            buttonActive = { false } 
                            label = 'Longer Label B'
                            bgStyle = {{display: 'inline-block', height: 24, margin: '4px 4px 4px 4px', backgroundColor: '#ECE1FD'}}
                        />
                    </div>
                </div>
                
            </PopperPanel>
        </Modal>
    )

}
