import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNested } from 'utils/miscHelpers';
import UndoRedo from 'utils/UndoRedo';

import { localChangesMade, stateSnapshotCreated } from './surveyEditorSlice';

export default function EditorUndoRedo() {

    const dispatch = useDispatch();

    const pagesData = useSelector( state => state.surveyEditor.present.survey.content.pagesData );
    const historyIndex = useSelector( state => state.surveyEditor.index );
    const prevPagesData = useSelector( state => {
        
        const pastStates = state.surveyEditor.past;
        if( pastStates.length < 1 ) return {}
        
        const prevIndex = Math.max( 0, historyIndex - 1);

        return getNested( pastStates[ prevIndex ], 'survey', 'content', 'pagesData');
    });

    const [snapshotCreated, setSnapshotCreated] = useState( false );

    useEffect( () => {

        if( !snapshotCreated && Object.keys( pagesData ).length > 0 ) {

            dispatch( stateSnapshotCreated() );
            setSnapshotCreated( true );

        }

    }, [pagesData, snapshotCreated, dispatch])

    const disableUndo = useMemo( () => {
        
        if( !prevPagesData ) return true;

        // If the previous state is an empty survey, prevent undo. 
        return Object.keys(prevPagesData).length === 0;
        
    },[prevPagesData])

    const handleUndoRedo = useCallback( () => {

        dispatch( localChangesMade() );
        
    }, [dispatch])

    return (

        <UndoRedo onDone={ handleUndoRedo } disableUndo={ disableUndo }/>

    );
}