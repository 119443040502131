import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getSurveyClientUrl } from "../survey_services/SurveyClientUrlSolver";
import styles from "./SurveyEditorPreview.module.css";
import debounce from "debounce";

export const previewPanelModes = {
    MOBILE: {
        key: "mobile",
        width: 360,
        height: 640,
    },
    DESKTOP: {
        key: "desktop",
        width: 1280,
        height: 800,
    },
};

export default function SurveyPreviewFrame() {
    const frameRef = useRef();
    const src = useMemo(() => getSurveyClientUrl() + "editing", []);
    const survey = useSelector((state) => state.surveyEditor.present.survey);
    const mode = useSelector(
        (state) => state.surveyEditor.present.display.previewPanelMode
    );
    const [isReady, setIsReady] = useState(false);

    const debouncedSync = useMemo(
        () =>
            debounce((json) => {
                if (!frameRef.current) return;

                const targetWindow = frameRef.current.contentWindow;

                targetWindow.postMessage(
                    {
                        type: "SURVEY_DATA",
                        surveyJson: json,
                    },
                    "*"
                ); // @TODO: secure targetOrigin with real URI instead of "*" https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
            }, 50),
        [frameRef]
    );

    // Sync Data
    useEffect(() => {
        if (frameRef.current && isReady) {
            debouncedSync(survey);
        }
    }, [frameRef, isReady, survey, debouncedSync]);

    // Receive client messages
    useEffect(() => {
        const messageHandler = (event) => {
            const data = event.data;
            if (data.type && data.type === "READY") {
                setIsReady(true);
            }
        };

        window.addEventListener("message", messageHandler);

        return () => window.removeEventListener("message", messageHandler);
    }, []);

    return (
        <div className={styles.previewFrameCont}>
            <iframe
                ref={frameRef}
                title={"Survey Preview"}
                className={styles.surveyIframe}
                width={mode.width}
                height={mode.height}
                src={src}
                allow={"camera;microphone"}
            />
        </div>
    );
}
