export const isKeyLegal = ( key ) => {

    const regex = RegExp( /^[a-z0-9_]*$/, 'g' );
    const illegal1 = RegExp( /^((?!___).)*$/, 'g' ); // don't allow ___ (three under scores), it's reserved for server purposes.

    return regex.test( key ) && illegal1.test( key );
}

export const isUrlLegal = ( url ) => {

    // const regexp =  RegExp( /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ );
    const regexp =  RegExp( /^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-.@:%_+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/ );
    if (regexp.test(url))
    {
      return true;
    }
    else
    {
      return false;
    }
}