import React, { useMemo } from 'react';
import Modal from 'components/poppers/Modal';
import PopperPanel from 'components/poppers/PopperPanel';
import moduleStyles  from './NewPagePopper.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { pageAdded } from 'features/survey_editor/surveyEditorSlice';
import { pageTypesConfig } from 'features/survey_editor/surveyEditorConfiguration';

/**
 * @param {{ referenceElement: object, // Node element as a reference for placing the popper.
 * isActive: boolean, // Is selection popper active?
 * targetIndex: integer // Where to add the new page item
 * blockId: string // should be provided if the page is being added inside a block
 * onSelect: function, // On user select option
 * onDissmis: function, // On cancel selection
 * }}
 */
export default function NewPagePopper({ 
    referenceElement, 
    isActive, 
    targetIndex,
    blockId,
    onSelect, 
    onDismiss,
}) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );

    const options = useMemo( () => {

        const typeOptions = [];
            
        for (const [key, props] of Object.entries( pageTypesConfig )) {
            
            if( blockId && key === 'BLOCK' ) break;

            typeOptions.push({
                type: key,
                name: props.name,
                icon: props.icon
            })
        }

        return typeOptions;

    }, [blockId])

    function handleSelect( option ) {

        dispatch( pageAdded({
            type: option.type,
            atIndex: targetIndex,
            lang: surveyLang,
            parentBlock: blockId
        }) );

        if( onSelect ) {
            onSelect( option );
        } else {
            console.error( "onSelect callback is not defined for a SelectionPopper instance.");
        }

        if( onDismiss ) {
            onDismiss();
        } else {
            console.error( "onDismiss callback is not defined for a SelectionPopper instance.");
        }

    }

    return (
        <Modal 
            isActive={ isActive }
            onClose={ onDismiss }
            bgBlur={ "low" }
        >
            <PopperPanel referenceElement={ referenceElement }>
                <div id={moduleStyles.listContainer}>
                    <ul id={moduleStyles.list}>
                        {
                            options && options.map( (op,i) => {

                                return(
                                    <ListItem
                                        key = { op.type }
                                        label = { op.name[ lang ] }
                                        icon = { op.icon }
                                        onSelect = { () => handleSelect( op ) }
                                    />
                                )

                            })
                        }
                    </ul> 
                </div>
            </PopperPanel>

        </Modal>
    )
}

function ListItem({label, onSelect, icon }) {

    function handleClick( e ) {
        e.stopPropagation();

        onSelect();
    }

    const Icon = icon;

    return (
        <li className={ moduleStyles.listItem }
            onClick={handleClick}
            
        >
            <Icon className={ moduleStyles.typeIcon }/>
            <div className={ moduleStyles.gap }/>
            {label}
        </li>
    )
}