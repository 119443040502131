import React from 'react';
import DateInput from 'components/DateInput'

export default function DateFieldModule ({data, value, handleChange, lang}) {

    
    return (
        <DateInput
            label={ data.label ? data.label[ lang ] : ''  }
            value={ value }
            includeTime={ data.useTime ? data.useTime : false }
            labelStyle={{font: 'var( --font-body-small )'}}
            onChange={(op) => {
                handleChange(op.target.value)
            } }
        />
    )
}