import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './Tags.module.css';
import * as Api from '../../api/api';
import ActivityIndicator from 'components/ActivityIndicator';
import { tagTexts } from '../../utils/appTexts';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';
import IconButton from 'components/IconButton';
import clip from "text-clipper";
import debounce from 'debounce';
import Chip from 'components/Chip';
// import { fetchInitialData } from 'app/horizonDataSlice';
import Modal, { ModalPanel } from 'components/poppers/Modal';

export default function Tag(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { tagIds } = useParams();
    const [currentTagIds, setCurrentTagIds] = useState(tagIds.split(','));
    const lang = useSelector( selectUiLanguage ); 
    const currentTeam = useSelector(state => state.horizonData.currentTeam);
    const [data, setData] = useState(null);
    const [lightboxResult, setLightboxResult] = useState(null);
    const [showSelectTags, setShowSelectTags] = useState(false);
    
    useEffect(()=>{
        (async () => {
            if (currentTagIds.length === 0) return
            const res = await Api.GetTagsData(currentTagIds.join(','));
            if (res.data) { setData(res.data) } 
            else { history.push('/404') }
        })();
    },[history, currentTagIds])

    useEffect(() => {
        if (!currentTeam) {
            // dispatch(fetchInitialData())
        }
    }, [currentTeam, dispatch]);

    const debouncedUpdateTags = useMemo( () =>
        debounce( async ( newTagIds ) => {
            setData(null)
            setCurrentTagIds(newTagIds)
            window.history.replaceState(null, "Tags", `/tags/${newTagIds.join(',')}`)
        }, 100 ), []
    )

    if (data) {
        const pageTitleHtml = tagTexts.tagPageTitle[lang].split('{tagName}').join(`<strong>${data.tags.map(t=>t.name).join(', ')}</strong>`)   
        var rowsDict = {};
        data.analysisResults.forEach(result => {
            if (!rowsDict[result.project.id]) {
                rowsDict[result.project.id] = {
                    project: result.project,
                    results: []
                }
            }
            rowsDict[result.project.id].results.push(result)
        })
        var allResults = []
        Object.keys(rowsDict).forEach(rowId => {
            rowsDict[rowId].results.forEach(result=>allResults.push(result))
        })

        return (
            <div className={styles.mainCont}>
                { lightboxResult && <ResultLightBox setLightboxResult = {setLightboxResult} result={lightboxResult} allResults={allResults}/> }
                { showSelectTags && <TagsLighbox closeLightbox={()=>{setShowSelectTags(false)}} lang={lang} currentTagIds={currentTagIds} allTags={currentTeam ? currentTeam.tags : null} updateTags={debouncedUpdateTags}/> }
                <div className={styles.pageTitle} >
                    <span dangerouslySetInnerHTML={{__html: pageTitleHtml}} />
                    <span> <IconButton name="gear" theme="Plain" size="s" colorSet="Grayscale" onClick={ ()=> setShowSelectTags(!showSelectTags) }/></span>
                </div>
                { Object.values(rowsDict).reverse().map(rowObj=><ResultsRow key={rowObj.project.id} project={rowObj.project} results={rowObj.results} lang={lang} history={history} setLightboxResult={setLightboxResult} />)}
            </div>
        )
    } else { return <div className={styles.loadingCont}> <ActivityIndicator size={36} /> </div> }
}

function ResultsRow({project, results, lang, history, setLightboxResult}) {
    return (
        <div className={styles.cardsGalleryRow} style={{}}>
            <p 
                className={styles.rowTitle} 
                style={{cursor: 'pointer'}} 
                onClick={()=>{
                    history.push(`/analyses/${project.id}`);
                }}
            >
                {`${project.icon} ${project.name}`}
            </p>
            <div className={styles.rowGalleryCont}>
                { results.map(result => <ResultCard key={result.id} result={result} lang={lang} setLightboxResult={setLightboxResult}/>) }
            </div>
        </div>
    )
}
function ResultCard({result, lang, setLightboxResult}) {
    var imgUrl = '';
    if (Object.keys(result.outputs).length > 0) {
        imgUrl = result.outputs[Object.keys(result.outputs)[0]].url
    }
    const diffInDays = Math.floor((new Date() - new Date(result.createdAt)) / (1000 * 60 * 60 * 24))
    const dateStr = diffInDays === 0 ? tagTexts.createdAtToday[lang] : tagTexts.createdBeforeDays[lang].split('{days}').join('' + diffInDays)
    var noteHtml = clip(result.notes, 200);
    return (
        <div className={styles.card} onClick={()=>{setLightboxResult(result)}}>
            <div 
                className={styles.cardImage}
                style={{backgroundImage: `url("${imgUrl}")`}}
            />
            <div className={styles.cardNoteText}>
                <div className={styles.cardNameTitle}>{clip(result.name, 50)}</div>
                <p dangerouslySetInnerHTML={{__html: noteHtml}}/>
            </div>
            <div className={styles.cardStats}>
                <div className={styles.stats}>
                    <div className={styles.value}>
                    <span className={styles.date}> {dateStr} </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
function ResultLightBox({allResults, result, lang, history, setLightboxResult}) {
    var imgUrl = '';
    if (Object.keys(result.outputs).length > 0) {
        imgUrl = result.outputs[Object.keys(result.outputs)[0]].url
    }
    return (
        <div className={styles.lightboxCont}>
            <div className={styles.previousBtn}>
                <IconButton
                    key='collapseButton'
                    name={ 'chevron_left_arrow' }
                    theme='Plain'
                    colorSet='White'
                    iconStyle={{width: '8vh', height: '8vh', opacity: 1}}
                    onClick={ () => {
                        const index = allResults.indexOf(result)
                        if (index > 0) {
                            setLightboxResult(allResults[index-1])
                        } else {
                            setLightboxResult(allResults[allResults.length - 1])
                        }
                    } }
                    tabIndex={ -1 }
                />
            </div>
            <div className={styles.nextBtn}>
                <IconButton
                    key='collapseButton'
                    name={ 'chevron_right_arrow' }
                    theme='Plain'
                    colorSet='White'
                    iconStyle={{width: '8vh', height: '8vh', opacity: 1}}
                    onClick={ () => {
                        const index = allResults.indexOf(result)
                        if (index < allResults.length - 1) {
                            setLightboxResult(allResults[index+1])
                        } else {
                            setLightboxResult(allResults[0])
                        }
                    } }
                    tabIndex={ -1 }
                />
            </div>
            <div className={styles.lightboxCard}>
            <div className={styles.lightboxCloseBtn}>
                <IconButton
                    key='collapseButton'
                    name={ 'x' }
                    theme='Plain'
                    colorSet='Grayscale'
                    iconStyle={{width: 30, height: 30, opacity: 1}}
                    onClick={ () => setLightboxResult( null ) }
                    tabIndex={ -1 }
                />
            </div>
            <div 
                className={styles.lightboxImage}
                style={{backgroundImage: `url("${imgUrl}")`}}
            />
            <div className={styles.lightboxText}>
            <div className={styles.lightboxNotes}>
                <div className={styles.lightboxTitle}>{result.name}</div>
                <div dangerouslySetInnerHTML={{__html: result.notes}}/>
            </div>
            
                <div className={styles.lightboxProject}>{`${result.project.icon} ${result.project.name}`}</div>
                <div className={styles.lightboxDate}>עדכון אחרון: { `${new Date( result.createdAt ).toLocaleDateString( 'he-IL' )} ${new Date( result.createdAt ).toLocaleTimeString( 'he-IL' ) }`} </div>
            </div>
            </div>
            
        </div>
    )
}
function TagsLighbox({lang, allTags, currentTagIds, updateTags, closeLightbox}) {
    if (!allTags) {
        return (
            <Modal isActive={true} bgBlur={"high"} onClose={closeLightbox}>
                 <ModalPanel isActive={ true } style={{display: 'grid', placeItems: 'center'}} >
                    <ActivityIndicator size={18} />
                </ModalPanel>
            </Modal>
        );
    }

    return (
        <Modal isActive={true} bgBlur={"high"} onClose={closeLightbox}>
            <ModalPanel isActive={ true } style={ {display: 'grid', placeItems: 'center'} } >
                <span className={ styles.chipsContainer }>
                    { allTags.map((tag, i) => {
                        var bgStyle = { height : '14px'}
                        var iconBefore = 'plus'
                        if (currentTagIds.indexOf(tag.id) > -1) {
                            bgStyle.backgroundColor = "var( --color-primary-light )"
                            if (currentTagIds.length > 1) { iconBefore = 'minus'
                            } else { iconBefore = null }
                        }
                        var onClick = () => {
                            var newTagIds = currentTagIds.slice()
                            const tagIndex = currentTagIds.indexOf(tag.id)
                            if (tagIndex === -1) {
                                newTagIds.push(tag.id)
                                updateTags(newTagIds)
                            } else if (newTagIds.length > 1) {
                                newTagIds.splice(tagIndex, 1)
                                updateTags(newTagIds)
                            }   
                        }
                        
                        return (
                            <div className={ styles.chipContainer }>
                                <Chip
                                    key={'' + i}
                                    bgStyle={bgStyle}
                                    iconBefore={iconBefore}
                                    label={tag.name}
                                    onClick={onClick}
                                    buttonActive={false}
                                />
                            </div>
                        )
                    })}
                </span>
            </ModalPanel>
        </Modal>
    )
}