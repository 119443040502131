import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSomeTeamProjects, selectCurrentTeam } from 'app/horizonDataSlice';
import { selectUiLanguage } from 'app/preferencesSlice';
import ActivityIndicator from 'components/ActivityIndicator';
import ProjectItem from './ProjectItem';
import styles from './ProjectsGrid.module.css';
import Button from 'components/Button';
import { homeTexts } from '../../utils/appTexts';



export default function ProjectsGrid({ title, projects, loadMoreMode, maxItems = 100 }) {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const fetchTeamProjectsStatus = useSelector(state => state.horizonData.fetchTeamProjectsStatus);
    const currentTeam = useSelector(selectCurrentTeam)
    const [loadAll, setLoadAll] = useState(false)
    const observer = useRef()

    const lastProjectElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (loadMoreMode !== 'auto' || !projects || projects.length >= maxItems) return
            if (entries[0].isIntersecting && fetchTeamProjectsStatus !== 'loading') {
                dispatch(fetchSomeTeamProjects());
            }
        })
        if (node) observer.current.observe(node)
    }, [loadMoreMode, dispatch, fetchTeamProjectsStatus, maxItems, projects])

    
    const handleAllMore = useCallback(node => {
        setLoadAll(true)
    }, [])

    useEffect(() => {
        if (loadMoreMode !== 'auto') return
        if (projects && currentTeam && projects.length <= 3 && currentTeam.projects.length < currentTeam.projectsCount) {
            if (fetchTeamProjectsStatus !== 'loading') {
                dispatch(fetchSomeTeamProjects());
            }
        }
    }, [loadMoreMode, projects, fetchTeamProjectsStatus, currentTeam, dispatch])

    useEffect(() => {
        if (loadAll && projects && currentTeam && currentTeam.projects.length < currentTeam.projectsCount) {
            if (fetchTeamProjectsStatus !== 'loading') {
                dispatch(fetchSomeTeamProjects({limit: 1000}));
            }
        }
    }, [projects, fetchTeamProjectsStatus, currentTeam, loadAll, dispatch])

    return (
        <div className={styles.mainCont}>
            <p className={styles.labelHeadline}> {title} </p>
            <div className={styles.rowCont}>
                {projects && projects.map((project, i) => {
                    return <ProjectItem
                        key={`${project.id}_${i}`}
                        data={project}
                        refToAttech={loadMoreMode === 'auto' && projects.length === i + 1 ? lastProjectElementRef : null}
                        itemIndex={i}
                    />
                })}
                { projects && projects.length < maxItems && loadMoreMode === 'manual' && fetchTeamProjectsStatus !== 'loading' && currentTeam.projects && currentTeam.projects.length < currentTeam.projectsCount &&
                    <div className={styles.activityIndicatorCont}>
                        <Button
                                label= { homeTexts.projectsView.loadAllBtn[ lang ]}
                                theme='Plain'
                                onClick={ handleAllMore }
                            />
                    </div>
                }
                {projects && projects.length < maxItems && fetchTeamProjectsStatus === 'loading' &&
                    <div className={styles.activityIndicatorCont}>
                        <ActivityIndicator size={24} />
                    </div>
                }
               
            </div>
            { projects && projects.length >= maxItems &&
                    <div className={styles.maxProjectsIndicatorCont}>
                        <p>{homeTexts.projectsView.displayOnlyMaxItems[lang].replace('{maxItems}', `${maxItems}`)}</p>
                        <p>{homeTexts.projectsView.useSearchBarInstead[lang]}</p>
                        <p style={{color:"black", marginTop:"20px"}}>🐈</p>
                    </div>
                }
        </div>
    )
}