import { createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from '../utils/cacheManager';

const initialState = {
    uiLanguage: 'he',
}

const preferencesSlice = createSlice({
    name: 'preferences',
    initialState,
    reducers: {
        uiLanguageChanged( state, action ) {
            const lang = action.payload;
            state.uiLanguage = lang;
            LocalStorage.Save( LocalStorage.keys.UI_LANGUAGE, lang );
        },
        loadUserPrefs( state, action ) {
            const uiLang = LocalStorage.Read( LocalStorage.keys.UI_LANGUAGE );
            if( uiLang ) state.uiLanguage = uiLang;
        }
    }
})

export const { uiLanguageChanged, loadUserPrefs } = preferencesSlice.actions;

export default preferencesSlice.reducer;

export const selectUiLanguage = state => state.preferences.uiLanguage;
