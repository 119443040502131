import React, { useCallback } from 'react';
import styles from './LogicActionsStyles.module.css';
import LogicActionItem from './LogicActionItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { logicItemActionAdded, logicItemActionReordered } from 'features/survey_editor/surveyEditorSlice';
import { surveyEditorTexts } from 'utils/appTexts';


const texts = surveyEditorTexts.properties.logic.itemEditor.actions;

export default function LogicActions({ itemId, actions }) {

    const dispatch = useDispatch();

    const handleDragEnd = useCallback( (result) => {
        if (!result.destination) {
            return;
          }
      
        dispatch( logicItemActionReordered( {
            logicItemId: itemId,
            sourceIndex: result.source.index,
            destinationIndex: result.destination.index,
        }))

    }, [dispatch, itemId] )

    const handleAddAction = useCallback( () => {

        dispatch( logicItemActionAdded( {logicItemId: itemId } ));

    }, [itemId, dispatch])

    return (
        <>
            <DragDropContext onDragEnd={ handleDragEnd }>
                <Droppable droppableId='logicActions'>
                { (provided, snapshot) => (

                    <div 
                        {...provided.droppableProps }
                        ref={ provided.innerRef }
                        className={ styles.actionItemsListCont }
                    >
                        { actions && actions.order.map( (actionId, index) => (
                            <LogicActionItem
                                index={ index }
                                logicItemId={ itemId }
                                actionId={ actionId }
                                key={ 'action_' + index }
                            />
                        )) }
                        {provided.placeholder}
                    </div>
                )}

                </Droppable>
            </DragDropContext>
            <Button
                label={ texts.addActionBtn[ 'en' ] }
                iconBefore='plus'
                theme='Outlined'
                bgStyle={{
                    marginTop: 'var(--space-small)',
                    borderRadius: 'var(--corner-radius-large)'
                }}
                onClick={ handleAddAction }
            />
        </>
    )
}