import React, { useState, useEffect } from 'react';
import styles from './Login.module.css';
import Button from '../../components/Button';
import TextInput, { TextTypes } from '../../components/TextInput';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';
import { login } from '../../features/users/usersSlice';

import { login as loginText } from '../../utils/appTexts';

export default function Login() {
    const lang = useSelector(selectUiLanguage);
    const loginError = useSelector((state) => state.users.mainUser.loginError);
    const loginStatus = useSelector(
        (state) => state.users.mainUser.loginStatus
    );
    // const mainUserData = useSelector( state => state.users.mainUser );

    const dispatch = useDispatch();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [changesMade, setChangesMade] = useState(false);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (changesMade) {
            // @TODO: improve email validation with regex
            setIsValid(email && password);
            setChangesMade(false);
        }
    }, [email, password, changesMade]);

    useEffect(() => {
        if (loginStatus === 'succeeded') {
            console.log('%c Successfully logged in.', 'color: green');

            history.push('/home');
        }
    }, [loginStatus, history]);

    function handleSubmit() {
        dispatch(login({ email, password }));
    }

    function handleForgot() {
        console.log('TODO: handle forgot password');
    }

    const dynamicFormStyle = {
        pointerEvents: loginStatus === 'loading' ? 'none' : 'auto',
        opacity: loginStatus === 'loading' ? 0.3 : 1,
    };

    return (
        <div className={styles.loginContainer}>
            <div className={styles.sideOneCont}>
                <div id={styles.logoCont}>Horizon</div>
                <p id={styles.subHeadlineLabel}>{loginText.Moto[lang]}</p>
                <pre className={styles.versionLabel}>
                    V{process.env.REACT_APP_VERSION}
                </pre>
            </div>

            <div className={styles.sideTwoCont}>
                <form id={styles.loginForm} style={dynamicFormStyle}>
                    <p id={styles.headlineLabel}>
                        {loginText.signInTitle[lang]}
                    </p>

                    <TextInput
                        type={TextTypes.EMAIL}
                        name="email"
                        theme="Outlined"
                        label={loginText.email[lang]}
                        contStyle={{ marginTop: 8, marginBottom: 8 }}
                        onChange={(event) => {
                            setChangesMade(true);
                            setEmail(event.target.value);
                        }}
                        placeholder="name@email.com"
                    />
                    <div className={styles.passwordCont}>
                        <p onClick={handleForgot} id={styles.forgotPassword}>
                            {loginText.forgotMyPass[lang]}
                        </p>
                        <TextInput
                            type={TextTypes.PASSWORD}
                            name="password"
                            label={loginText.password[lang]}
                            contStyle={{ marginTop: 0, marginBottom: 8 }}
                            onChange={(event) => {
                                setChangesMade(true);
                                setPassword(event.target.value);
                            }}
                            placeholder="********"
                            onReturn={() => {
                                if (isValid) handleSubmit();
                            }}
                        />
                    </div>

                    {loginError && (
                        <p id={styles.errorLabel}>
                            {loginText.errors[loginError]
                                ? loginText.errors[loginError][lang]
                                : loginText.errors.general[lang]}
                        </p>
                    )}
                    <Button
                        label={loginText.submitBtn[lang]}
                        disabled={!isValid}
                        bgStyle={{
                            marginTop: 24,
                            marginBottom: 8,
                            width: 190,
                            padding: 10,
                        }}
                        onClick={handleSubmit}
                    />
                    <p id={styles.getIntouchLabel}>
                        {loginText.getIntouch[lang]}
                    </p>
                </form>
            </div>
        </div>
    );
}
