import React, { useCallback, useEffect, useState } from 'react';
import TextInput from 'components/TextInput';
import { AlertDialog } from 'components/poppers/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectPageProperty,
    pageContentChanged,
    messageAdded,
} from 'features/survey_editor/surveyEditorSlice';
import { isKeyLegal } from 'utils/validation';
import { textDirection } from 'utils/stylingTools';
import { surveyEditorTexts } from 'utils/appTexts';

const texts = surveyEditorTexts.properties.pageSettings;

const PageKey = ({ lang, currentPage }) => {
    const dispatch = useDispatch();
    const pagesData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData
    );
    const variables = useSelector(
        (state) => state.surveyEditor.present.survey.logic.variables
    );

    const [keyWarning, setKeyWarning] = useState(false);
    const [noKeyAlertIsActive, setNoKeyAlertIsActive] = useState(false);
    const pageKey = useSelector((state) => {
        if (!currentPage) return null;
        return selectPageProperty(state, currentPage, 'key');
    });

    useEffect(() => {
        // Validate that this page key is unique:
        if (currentPage && pagesData) {
            let keyWarning = '';

            for (const [id, page] of Object.entries(pagesData)) {
                if (id !== currentPage && page.key === pageKey) {
                    keyWarning = texts.keyAlreadyExistWarning[lang];
                }
            }

            for (const v of variables) {
                if (v.name === pageKey) {
                    keyWarning = texts.varNameAlreadyExistWarning[lang];
                }
            }

            if (!pageKey) {
                keyWarning = texts.missingKeytWarning[lang];
            }

            setKeyWarning(keyWarning);
        }
    }, [currentPage, pagesData, pageKey, variables, lang]);

    const handleKeyChange = useCallback(
        (event) => {
            const value = event.target.value;

            if (isKeyLegal(value)) {
                dispatch(
                    pageContentChanged({ id: currentPage, prop: 'key', value })
                );
            } else {
                dispatch(
                    messageAdded({ type: 'ILLEGAL_KEY_CHARACTER', arg: '' })
                );
            }
        },
        [currentPage, dispatch]
    );

    const handleKeyBlur = useCallback((e) => {
        if (e.target.value === '') {
            setNoKeyAlertIsActive(true);
        }
    }, []);

    const handleOnCloseNoKeyAlert = useCallback(() => {
        const newPageKey = 'page_' + new Date().getTime().toString(25);
        dispatch(
            pageContentChanged({
                id: currentPage,
                prop: 'key',
                value: newPageKey,
            })
        );
        setNoKeyAlertIsActive(false);
    }, [currentPage, dispatch]);

    return (
        <>
            <TextInput
                label={texts.pageKeyLabel[lang]}
                theme="Outlined"
                // placeholder={ }
                value={pageKey || ''}
                contStyle={{ marginBottom: 12 }}
                fieldStyle={{
                    font: 'var( --font-code )',
                    direction: 'ltr',
                    textAlign: textDirection(lang).align,
                }}
                onChange={handleKeyChange}
                onBlur={handleKeyBlur}
                warning={keyWarning}
            />
            <AlertDialog
                isActive={noKeyAlertIsActive}
                onClose={handleOnCloseNoKeyAlert}
                title={texts.missingKeyAlert.title[lang]}
                message={texts.missingKeyAlert.message[lang]}
                actions={[
                    {
                        label: texts.missingKeyAlert.action[lang],
                        callback: () => {},
                        // theme
                    },
                ]}
            />
        </>
    );
};

export default PageKey;
