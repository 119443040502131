import Tooltip from 'components/poppers/Tooltip';
import { useRef, useState } from 'react';

export const placeholderSearchString = '#[^#\\s]+#';
const placeholderTextPattern = new RegExp(placeholderSearchString, 'g');

const PlacholderRenderer = ({ children, contentState, ...rest }) => {
    const spanRef = useRef(null);
    const [display, setDisplay] = useState(false);
    return (
        <>
            <span
                ref={spanRef}
                onPointerEnter={() => setDisplay(true)}
                onPointerLeave={() => setDisplay(false)}
                style={{
                    opacity: 0.5,
                }}
            >
                {children}
            </span>
            <Tooltip
                label={'Placeholder text'}
                display={display}
                referenceElement={spanRef.current}
            />
        </>
    );
};

export const placeholderDecorator = {
    strategy: (block, callback) => {
        const txt = block.getText();
        const placeHolders = txt.match(placeholderTextPattern);

        if (placeHolders && placeHolders.length > 0) {
            placeHolders.forEach((str) => {
                const start = txt.indexOf(str);
                const end = start + str.length;
                callback(start, end);
            });
        }
    },
    component: PlacholderRenderer,
};
