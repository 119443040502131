import React, { memo, useCallback, useMemo, useRef } from 'react';
import styles from './PageEditableItemsList.module.css';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import PageEditableItem, { ADD_ITEM } from './PageEditableItem';
import PageProxyItem from './PageProxyItem';
import { useSelector, useDispatch } from 'react-redux';
import { pageItemsReordered } from 'features/survey_editor/surveyEditorSlice';
import { areEqual, FixedSizeList } from 'react-window';
import { selectUiLanguage } from 'app/preferencesSlice';
import { textDirection } from 'utils/stylingTools';
import useOnScreen from 'utils/useOnScreen';

export const types = {
    REORDERABLE: 'reorderable', // Enbable drag and drop reordering
    VIRTUALIZED: 'virtualized', // For a very large list 
}

export default function PageEditableItemsList({pageId, pageHovered, type = types.REORDERABLE, isRichText = true }) {

    const listContRef = useRef();
    const isVisible = useOnScreen( listContRef );

    const List = useMemo( () => {
        
        switch( type ) {
            case types.REORDERABLE: {
                if( isVisible || pageHovered ) {
                    return ReorderableList;
                } else {
                    return ProxyList;
                }
            }
            case types.VIRTUALIZED: return VirtualizedList;
            default: return null;
        }

    }, [pageHovered, type, isVisible])

    const listOrder = useSelector( state => state.surveyEditor.present.survey.content.pagesData[ pageId ].itemsOrder );

    return (
        <div ref={ listContRef }>
            <List pageId={ pageId } listOrder={ listOrder } pageHovered={ pageHovered } isRichText={ isRichText }/>
        </div>
    )
}

// Light and simple list for fast rendering.
const ProxyList = ( {pageId, listOrder} ) => {

    return (
        <>
            {
                listOrder.map( (id,i) => (
                    <PageProxyItem
                        key={ id } 
                        itemId={ id }
                        pageId={ pageId }
                    />
                ))
            }
            <div className={ styles.newItemBtnCon }>
                <PageProxyItem/>
            </div>
        </>
    )

}


const ReorderableList = ( {pageId, listOrder, pageHovered, isRichText } ) => {
    
    const dispatch = useDispatch();

    const getListStyle = ( snaphot ) => ({

        backgroundColor: snaphot.isDraggingOver ? 'rgba(0,0,0,0.05)' : 'transparent',

    })

    const updateListOrder = useCallback( ( result ) => {
        
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        dispatch( pageItemsReordered( { 
            pageId, 
            startIndex: result.source.index, 
            endIndex: result.destination.index 
        }) );

    }, [pageId, dispatch] )

    return (
        <>
            {
                listOrder && listOrder.length > 0 &&
                <DragDropContext onDragEnd={ updateListOrder }>
                    <Droppable droppableId="pageItemInnerList">
                        {
                            ( provided, snapshot ) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={ provided.innerRef }
                                    style={ getListStyle( snapshot ) }
                                >
                                    
                                    {   
                                        listOrder.map( (id,i) => (
                                            <PageEditableItem 
                                                key={ id } 
                                                itemId={ id }
                                                pageId={ pageId }
                                                index={ i }
                                                isRichText={ isRichText }
                                            />
                                        ))
                                    }

                                    {provided.placeholder}
                                    
                                </div>
                            )
                        }
                    </Droppable>
                </DragDropContext>
            }
            <NewItem pageId={ pageId } listOrder={ listOrder } pageHovered={ pageHovered } isRichText={ isRichText }/>
        </>
    )
    
}

const VirtualizedList = ( { pageId, listOrder, pageHovered, isRichText } ) => {

    const lang = useSelector( selectUiLanguage );

    const itemData = useMemo( () => ({
        pageId,
        listOrder,
        isRichText
    }), [pageId, listOrder, isRichText]);

    const height = useMemo( () => {

        if( listOrder && listOrder.length > 1 ) {
            return Math.min( 300, listOrder.length * 32 ); 
        }

        return 32;

    }, [listOrder])

    return (
        <div className={ styles.listWrapper }>
            {
                listOrder && listOrder.length > 0 &&
                <FixedSizeList 
                    key={ pageId }
                    className={ styles.listCont }
                    height={ height }
                    itemCount={ listOrder.length }
                    itemSize={ 32 }
                    direction={ textDirection( lang ).direction }
                    itemData={ itemData }
                >
                    { Row }
                </FixedSizeList>
            }
            <NewItem pageId={ pageId } listOrder={ listOrder } pageHovered={ pageHovered }/>
        </div>
    )
}
  
const Row = memo(({ data, index, style }) => {
    const id = data.listOrder[ index ];
    return(
        <div                                 
            key={ id }  
            className={ styles.virtualizedItemCont }
            style={ style }
        >
            <PageEditableItem 
                key={ id }
                itemId={ id }
                pageId={ data.pageId }
                index={ index }
                isDraggable={ false }
                isRichText={ data.isRichText }
            />
        </div>
    )

}, areEqual);

const NewItem = ({pageId, listOrder, pageHovered, isRichText}) => {

    const handleNewitemIdDown = useCallback( ( e ) => {
        if( e.key === 'Enter' ) {
            e.target.focus(); // Focus back on self to allow user to edit a new item.
        }
        if( e.key === 'Tab' && !e.shiftKey ) {
            e.target.blur(); // Cause PageEditableItemRow to add the new item by triggering "Blur" event.
            e.target.focus(); // Focous back on self to allow user to edit a new item.
            e.preventDefault(); // Prevent document from jumping to the next element.
        }

    }, [] )

    return (
        <div className={ styles.newItemBtnCon }  style={{opacity: pageHovered ? 1 : 0.3}}>
            <PageEditableItem 
                key={ ADD_ITEM } 
                pageId={ pageId }
                itemId={ ADD_ITEM }
                index={ listOrder ? listOrder.length : 0 }
                isRichText={ isRichText }
                onKeyDown={ handleNewitemIdDown } // focus back on selef to let the use add new item.
            />
        </div>
    )
}