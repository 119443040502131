import React from 'react';
import ActivityIndicator from '../../components/ActivityIndicator';
import styles from './AuthenticationLoader.module.css';
import { login } from '../../utils/appTexts';

import { useSelector } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';

export default function AuthenticationLoader() {

    const lang = useSelector( selectUiLanguage );

    return (
        <div id={ styles.mainCont }>
            <div id={ styles.elementsCont }>
                <h1>{ login.authenticationLoadingTitle[ lang ]}</h1>
                <ActivityIndicator />
            </div>
        </div>
    )
}