import React, { useMemo, useCallback } from 'react';
import surveySettingsStyles from './SurveySettings.module.css';
import { surveyEditorTexts } from 'utils/appTexts';
import ColorPicker from 'components/ColorPicker';
import DropDown from 'components/DropDown';
import { useSelector, useDispatch } from 'react-redux';
import { surveySettingsChanged } from '../../surveyEditorSlice';
import { colors } from 'features/survey_editor/surveyEditorConfiguration';
import { selectUiLanguage } from 'app/preferencesSlice';

const texts = surveyEditorTexts.properties.surveySettings;

const ColorOptions = () => {
    const lang = useSelector(selectUiLanguage);
    const dispatch = useDispatch();
    const surveyColor = useSelector(
        (state) => state.surveyEditor.present.survey.settings.general.color
    );

    const isCustom = useMemo(() => {
        return Array.isArray(surveyColor);
    }, [surveyColor]);

    const colorOptions = useMemo(() => {
        const options = colors.map((col) => ({
            key: col,
            label: texts.colors[col][lang],
        }));
        options.push({ key: 'custom', label: texts.colors.custom[lang] });
        return options;
    }, [lang]);

    const handleColorSelection = useCallback(
        (op) => {
            let value = op.key;

            if (op.key === 'custom') {
                value = ['#eaeaea', '#a4a4a4'];
            }

            dispatch(surveySettingsChanged({ prop: 'color', value }));
        },
        [dispatch]
    );

    const handleCustomColorsChange = useCallback(
        (col, i) => {
            const newColors = Array.from(surveyColor);
            newColors[i] = col;
            dispatch(
                surveySettingsChanged({ prop: 'color', value: newColors })
            );
        },
        [surveyColor, dispatch]
    );

    return (
        <div className={surveySettingsStyles.colorsCont}>
            <DropDown
                label={texts.surveyColorLabel[lang]}
                contStyle={{ width: '100%' }}
                theme="Outlined"
                value={isCustom ? 'custom' : surveyColor}
                options={colorOptions}
                onChange={handleColorSelection}
            />
            {isCustom && (
                <div className={surveySettingsStyles.customColorsCont}>
                    <ColorPicker
                        style={{ flex: 1, marginInlineEnd: 6 }}
                        value={surveyColor[0]}
                        onChange={(c) => handleCustomColorsChange(c, 0)}
                    />
                    <ColorPicker
                        style={{ flex: 1 }}
                        value={surveyColor[1]}
                        onChange={(c) => handleCustomColorsChange(c, 1)}
                    />
                </div>
            )}
        </div>
    );
};

export default ColorOptions;
