import React, { useCallback, useState, useRef, useMemo } from 'react';
import styles from './ColorPicker.module.css';
import Button from 'components/Button';
import Modal from 'components/poppers/Modal';
import PopperPanel from 'components/poppers/PopperPanel';
import { motion } from 'framer-motion';
import { componentsTexts } from 'utils/appTexts';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import debounce from 'debounce';

const texts = componentsTexts.colorPicker;

const defaultColorPresets = [
    '#D81B60',
    '#6202EE',
    '#FB8006',
    '#FFC108',
    '#795548',
    '#5D9431',
    '#1E88E5',
    '#D81B60',
    '#D0E3FF',
    '#D6F9DE',
    '#FFE4D1',
    '#E8D6FF',
    '#FFE1E1',
    '#CBF9F3',
    '#FFEEBB',
]

/**
 * 
 * @param {{
 * className, //container css class
 * style,
 * label,
 * value,
 * presets, // preset colors list
 * labelStyle,
 * onChange
 * }} params 
 */
export default function ColorPicker ({
    className,
    style,
    label,
    value = "#4877FF",
    presets = defaultColorPresets,
    labelStyle,
    onChange
}) {

    const btnRef = useRef();
    const [ isPopperActive, setIsPopperActive ] = useState( false );

    const handleColorSelect = useCallback( ( col ) => {

        if( onChange ) onChange( col );

    }, [onChange])

    return (
        <div 
            className={ `${ styles.container } ${ className }`}
            style={ style }
        >
            {
                label &&
                <label className={ styles.label } style={ labelStyle }>{ label }</label>
            }

            <div className={ styles.btnCont } ref={ btnRef }>
                <div className={ styles.currentColor} style={{ backgroundColor: value }}/>
                <Button
                    label={ value }
                    theme='Outlined'
                    colorSet='Grayscale'
                    size='m'
                    iconBefore='blank'
                    bgStyle = {{ justifyContent: 'space-between', padding: '6px 14px', height: '100%', width: '100%' }}
                    labelStyle = {{ direction: 'ltr', font: 'var( --font-btn )', color: 'var( --color-type-high-emphasis)' }}
                    onClick = { () => setIsPopperActive( true ) }
                />
            </div>

            <ColorPopper
                referenceElement={ btnRef.current }
                isActive={ isPopperActive }
                value={ value }
                presets={ presets }
                onSelect={ handleColorSelect }
                onDismiss={ () => setIsPopperActive( false ) }
            />
        </div>
    )

}

//////////////////
// COLOR POPPER //
//////////////////

const DEBOUNCE_COLORPICK_DELAY = 100;

const ColorPopper = ({ 
    referenceElement, 
    isActive,
    value, 
    presets, 
    onSelect, 
    onDismiss
}) => {
     
    const inputRef = useRef();
    const lang = useSelector( selectUiLanguage );

    const handleDismiss = useCallback( () => {

        if( onDismiss ) {
            onDismiss();
        } else {
            console.error( "onDismiss callback is not defined for a SelectionPopper instance.");
        }
    }, [onDismiss]);

    const handleSelect = useCallback( ( value ) => {

        onSelect( value );
        onDismiss();

    }, [onSelect,onDismiss]);

    const handlePickerKeyDown = useCallback( ( e ) => {

        if( e.key === 'Enter' ) {
            e.target.blur();
            onDismiss();
        }
    }, [onDismiss]);

    const debouncedColorPick = useMemo( () =>
        debounce( ( value ) => onSelect( value ) , DEBOUNCE_COLORPICK_DELAY ),
        [onSelect]
    )


    return (
        <Modal 
            isActive={ isActive }
            onClose={ handleDismiss }
            bgBlur={ "low" }
        >
            <PopperPanel referenceElement={ referenceElement }>
                <div className={ styles.popperCont }>
                    <p className={ styles.popperCuerrentColorLabel }>{ value }</p>
                    <div className={ styles.presetesCont }>
                        {
                            presets.map( (col,i) => (
                                <motion.div
                                    key={ col + i}
                                    className={ styles.presetColor }
                                    style={{ backgroundColor: col }}
                                    whileHover={{
                                        scale: 1.1,
                                        transition: { duration: 0.2, ease:'easeOut' },
                                    }}
                                    onClick={ () => handleSelect( col ) }
                                >

                                </motion.div>
                            ))
                        }
                        <div 
                            className={ styles.customColorPickerCont } 
                            onClick={() => inputRef.current.click()}
                        >
                            <motion.input 
                                ref={ inputRef }
                                id='colorPicker'
                                type='color' 
                                name={ 'colorPicker' }
                                value={ value }
                                onKeyDown={ handlePickerKeyDown }
                                onChange={ e => debouncedColorPick( e.target.value ) }
                                whileHover={{
                                    scale: 1.1,
                                    transition: { duration: 0.2, ease:'easeOut' },
                                }}
                            >
                            </motion.input>
                            <p className={ styles.customColorLabel }>{ texts.customLabel[ lang ] }</p>
                        </div>
                    </div>

                </div>
            </PopperPanel>

        </Modal>
    )
}