import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { useSelector } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';

import styles from './Modal.module.css';
import { motion, AnimatePresence, usePresence } from 'framer-motion';
import { useKeyPress } from 'utils/hooks';

const bgBlurOptions = {
    none: { opacity: 0, blur: 0, grayscale: 0 },
    low: { opacity: 0.01, blur: 0.5, grayscale: 5 },
    medium: { opacity: 0.2, blur: 6, grayscale: 30 },
    high: { opacity: 0.4, blur: 12, grayscale: 60 },
};

/**
 * Low level container for all sorts of popper dialogs
 * @param {{
 * isActive {bool} // is the modal active
 * onClose {function} // callback when pressing the background
 * shouldCloseOnBgClick {} // trigger onClose callback on bg click
 * bgBlur {string} // amount of background visual dimming: "none" | "low" | "medium" | "high"
 * getRoot {function} // returns the DOM element of the modal
 * blockBgClicks {bool} // block mouse clicks on underlying elements
 * transitionDuration {number}
 * }} params
 */
export default function Modal({
    isActive,
    onClose,
    shouldCloseOnBgClick = true,
    bgBlur = 'medium',
    getRoot,
    blockBgClicks = false,
    transitionDuration = 0.05,
    children,
}) {
    const uiLanguage = useSelector(selectUiLanguage);
    const appUiDirection = uiLanguage === 'en' ? 'ltr' : 'rtl';
    const modalContRef = useRef();

    const [clickTarget, setClickTarget] = useState(null);

    useEffect(() => {
        if (modalContRef && getRoot) getRoot(modalContRef.current);
    }, [modalContRef, getRoot]);

    useKeyPress('Escape', onClose);

    // Handle close on blur
    useEffect(() => {
        if (shouldCloseOnBgClick && onClose && isActive) {
            const onPointerDown = (e) => {
                setClickTarget(e.target);
            };

            const onPointerUp = (e) => {
                let element = e.target;

                if (element !== clickTarget) {
                    // Click ended on a diffrent target.
                    return;
                }

                if (modalContRef.current === element) {
                    // Modal clicked bg clicked.
                    onClose();
                }

                let isOnModal = false;

                while (element && element.parentElement && !isOnModal) {
                    if (modalContRef.current === element) {
                        isOnModal = true;
                        break;
                    }

                    // Stop iteration if this modal is inside other modal:
                    if (element.id === 'modal_cont') break;

                    element = element.parentElement;
                }
                setClickTarget(null);

                if (!isOnModal) {
                    const root = document.getElementById('root');
                    const children = Array.from(root.children);
                    //Close the modal only if the clicked element is behind it:
                    if (
                        children.indexOf(modalContRef.current) >
                        children.indexOf(element)
                    ) {
                        onClose();
                    }
                }
            };

            window.addEventListener('pointerdown', onPointerDown);
            window.addEventListener('pointerup', onPointerUp);

            return () => {
                window.removeEventListener('pointerdown', onPointerDown);
                window.removeEventListener('pointerup', onPointerUp);
            };
        }
    }, [isActive, shouldCloseOnBgClick, onClose, clickTarget]);

    const handleBgClick = useCallback(
        (e) => {
            if (blockBgClicks) {
                e.stopPropagation();
            }
        },
        [blockBgClicks]
    );

    return ReactDOM.createPortal(
        <AnimatePresence>
            {isActive && (
                <motion.div
                    ref={modalContRef}
                    id="modal_cont"
                    key="modal_bg"
                    className={styles.modalContainer}
                    style={{
                        direction: appUiDirection,
                        backgroundColor: `rgba(0,0,0,${bgBlurOptions[bgBlur].opacity})`,
                        pointerEvents: blockBgClicks ? 'auto' : 'none',
                    }}
                    onClick={handleBgClick}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    transition={{
                        duration: transitionDuration,
                        ease: 'easeOut',
                    }}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>,

        document.getElementById('root')
    );
}

const duration = 0.05;
const motionVarients = {
    visible: {
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
            duration: duration,
            ease: 'easeOut',
        },
    },
    hidden: {
        opacity: 0,
        scale: 0.9,
        y: 40,
        transition: {
            duration: duration * 0.5,
            ease: 'easeOut',
        },
    },
};

const ModalPanel = React.forwardRef(({ className, style, children }, ref) => {
    const [isPresent, safeToRemove] = usePresence();

    useEffect(() => {
        !isPresent && setTimeout(safeToRemove, duration);
    }, [isPresent, safeToRemove]);

    return (
        <AnimatePresence>
            {isPresent && (
                <motion.div
                    ref={ref}
                    key="modal_panel"
                    className={`${styles.modalPanel} ${className}`}
                    style={{ ...style }}
                    onClick={(e) => e.stopPropagation()}
                    onMouseDown={(e) => e.stopPropagation()}
                    onMouseUp={(e) => e.stopPropagation()}
                    // onPointerUp={(e) => e.stopPropagation()}
                    variants={motionVarients}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    );
});

export { ModalPanel };
