export const LocalStorage = {
    keys: {
        TOKEN: 'token',
        UI_LANGUAGE: 'ui_language',
        CURRENT_TEAM: 'current_team',
        CURRENT_LABEL: 'current_label',
        LAST_VISITED_PROJECTS: 'last_visited_projects',
        PINNED_PROJECTS: 'pinned_projects',
        MILGO_TOKEN: 'milgo_token',
    },

    Save: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },

    Read: (key) => {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            console.error('Cannot access localStorage. Details: ' + e);
            return null;
        }
    },

    Delete: (key) => {
        localStorage.removeItem(key);
    },

    DeleteAll: () => {
        localStorage.clear();
    },
};

export const SessionStorage = {
    keys: {
        LAST_PAGE_PATH: 'last_page_path',
        INITIAL_DATA: 'initial_data',
        TEAMS_DATA: 'teams_data',
        USER_DATA: 'user_data',
        SURVEY_EDITOR_DATA: 'survey_editor_data',
        CLIENT_KEY: 'client_key', // is used by the server for identification while multiple clients are editing the same server
    },

    Save: (key, value) => {
        sessionStorage.setItem(key, JSON.stringify(value));
    },

    Read: (key) => {
        try {
            return JSON.parse(sessionStorage.getItem(key));
        } catch (e) {
            console.error('Cannot access sessionStorage. Details: ' + e);
            return null;
        }
    },

    Delete: (key) => {
        sessionStorage.removeItem(key);
    },

    DeleteAll: () => {
        sessionStorage.clear();
    },
};
