import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styles from  './AnalysisCard.module.css';

import AnalysisCardSynchronizer from './AnalysisCardSynchronizer';
import AnalysisCardOptions from './AnalysisCardOptions';
import AnalysisCardOutput from './AnalysisCardOutput';
import AnalysisCardNote from './AnalysisCardNote';
import AnalysisCardTagsSection from './AnalysisCardTagsSection';

import ActivityIndicator from 'components/ActivityIndicator';
import { Editable, Types as EditableTypes } from 'components/Editable';

import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
// import { fetchInitialData } from 'app/horizonDataSlice';
import { updateAnalysisResult } from 'features/analyses/analysesSlice';
import { analysesTexts } from 'utils/appTexts';
import IconButton from 'components/IconButton';

const texts = analysesTexts.cardTexts;

export default function AnalysisCard({data, project, refToAttech}) {
    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const [showProcessingActivity, setShowProcessingActivity] = useState( false );
    const [showActivity, setShowActivity] = useState( false );
    const [hovered, setHovered] = useState( false );
    const [name, setName] = useState( '...' );

    useEffect( () => { setName( data.name )}, [data] );

    const submitNameChange = useCallback( () => {

        if( data.name !== name ) {
            setShowActivity( true );
            dispatch( updateAnalysisResult( {id: data.id, prop:'name', value: name} ) )
                .then( () => setShowActivity( false ));
        }

    }, [data, name, dispatch])

    const handleStar = useCallback( () => {
        setShowActivity( true );
        dispatch( updateAnalysisResult( {id: data.id, prop:'isStared', value: !data.isStared } ) )
            .then( () => setShowActivity( false ) );
      
    }, [data, dispatch])

    const handleConnectTag = useCallback( (tagId) => {
        setShowActivity( true );
        dispatch( updateAnalysisResult( {id: data.id, prop:'tags', value: {connect: {id: tagId}} } ) )
            .then( () => setShowActivity( false ) );
    }, [data, dispatch])

    const handleDisconnectTag = useCallback( (tagId) => {
        setShowActivity( true );
        dispatch( updateAnalysisResult( {id: data.id, prop:'tags', value: {disconnect: {id: tagId}} } ) )
            .then( () => setShowActivity( false ) );
    }, [data, dispatch])

    const handleCreateTag = useCallback( (tagName, teamId) => {
        setShowActivity( true );
        dispatch( updateAnalysisResult( {id: data.id, prop:'tags', value: {create: {name: tagName, team: {connect: {id: teamId}}}} } ) )
            .then( () => {
                setShowActivity( false )
                // dispatch(fetchInitialData())
            } );
    }, [data, dispatch])

    return (
        <>
            <AnalysisCardSynchronizer 
                project={ project }
                data={ data } 
                onActivity={ isActive => setShowProcessingActivity( isActive ) }
            />
            <div 
                ref = {refToAttech}
                className={ styles.analysisCard }
                onMouseEnter={ () => setHovered( true ) }
                onMouseLeave={ () => setHovered( false ) }
                onBlur={ () => setHovered( false ) }
            >
                <div className={ styles.cardDetailsCont }>
                    <div className={ styles.cardDetailsTop }>
                        <Editable 
                            type={ EditableTypes.TEXT } 
                            className={ styles.cardTitle } 
                            value={ name } 
                            placeholder={ texts.titlePlaceholder[ lang ] }
                            onChange={ value => setName( value ) }
                            onBlur={ submitNameChange }
                        />
                        <IconButton
                            name={ data.isStared ? 'star_full' : 'star_outline' }
                            theme='Plain'
                            colorSet='Secondary'
                            iconStyle={ {
                                opacity: (hovered || data.isStared) ? 1 : 0.2,
                                fill: data.isStared ? 'var(--color-secondary-medium)' : 'rgba(0,0,0,0.3)' 
                            } }
                            onClick={ handleStar }
                        />
                    </div>

                    <div className={ styles.cardFieldCont }>

                        <div className={ styles.cardFieldLabel }> { texts.createdBy[ lang ] } </div>
                        <div className={ styles.cardFieldValue }> { data.user.name } </div>

                    </div>

                    <div className={ styles.cardFieldCont }>

                        <div className={ styles.cardFieldLabel }> { texts.createdAt[ lang ] } </div>
                        <div className={ styles.cardFieldValue }> 
                            { `${new Date( data.createdAt ).toLocaleDateString( 'he-IL' )} /                         
                            ${new Date( data.createdAt ).toLocaleTimeString( 'he-IL' ) }`} 
                        </div>

                    </div>

                    <div className={ styles.cardFieldCont }>
                        <AnalysisCardTagsSection 
                            analysisData={ data }
                            lang = { lang }
                            isHovered={ hovered }
                            onRemoveTag = { handleDisconnectTag }
                            onConnectTag = { handleConnectTag }
                            onCreateTag = { handleCreateTag }
                        />
                    </div>

                    <AnalysisCardNote 
                        data={ data } 
                        isHovered={ hovered }
                        onActivity={ (isLoading) => setShowActivity( isLoading )}
                    />

                    {
                        !data.isArchived &&
                        <AnalysisCardOptions 
                            data={ data } 
                            lang={ lang } 
                            project={project} 
                            showIcon={ hovered }
                            onActivityStart={ (isLoading) => setShowProcessingActivity( isLoading )}
                        />
                    }

                    { 
                        showActivity && 
                        <div className={ styles.minorActivityIndicatorCont }>
                            <ActivityIndicator size={ 18 }/>
                        </div>
                    }           
                    
                </div>

                <AnalysisCardOutput data={ data } lang={ lang }/>

                {
                    data.isArchived && 
                    <div className={ styles.isArchivedCont }>
                        <h1 className={ styles.isArchivedLabel }> {texts.isArchived[ lang ] }
                        </h1>   
                    </div>
                }

                <ProcessingActivity lang={ lang } isActive={ showProcessingActivity } posInQueue={ data.posInQueue }/>

            </div>
        </>
    )

}


const ProcessingActivity = ({lang, isActive, posInQueue}) => {

    const processingTime = useMemo( () => {

        // if( !posInQueue ) return '';
        // @TEMP temporary guesstimation of analysis processing time. Should get real time from the server.
        const t = 10;
        let estimation = ( t * posInQueue ) / 60;
        if( estimation < 1 ) {
            return texts.lessThanMinute[lang]
        } else {
            return Math.round( estimation );
        }


    }, [posInQueue, lang]); 

    if( !isActive ) return null;

    return (
        <div className={ styles.activityCont }>
            <ActivityIndicator size={ 18 } />
                <div className={ styles.positionInQueueCont }>
                {
                    posInQueue && posInQueue > -1 ?
                    <>
                        {texts.positionInQueue[lang]}: <span className={ styles.strong }> {posInQueue} </span>
                        <br/>
                        {texts.expectedCompletion[lang]} <span className={ styles.strong }>{processingTime}</span> {texts.minutes[lang]}.
                    </>
                    :
                    texts.isAnalysing[lang]
                }
                </div>
        </div>
    )
}




