import React, { useMemo } from 'react';
import styles from './PageEditableItemsList.module.css';
import Icon from 'components/Icon';
import { useSelector } from 'react-redux';
import { textDirection } from 'utils/stylingTools';
import { selectUiLanguage } from 'app/preferencesSlice';
import { getRawText } from 'components/rich_text/RichTextInput';

export default function PageProxyItem({pageId,itemId}) {

    const lang = useSelector( selectUiLanguage );
    const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );
    const pageData = useSelector( state => state.surveyEditor.present.survey.content.pagesData[pageId] );

    const item = useMemo( () => {
        if( pageData && pageData.itemsData && itemId ) {
            return pageData.itemsData[itemId];
        } else {
            return null;
        }
    }, [pageData, itemId]);

    const itemText = useMemo( () => {
        if( item ) {
            const text = item.text[surveyLang];
            return getRawText( text );
        }
    }, [item, surveyLang])

    return (
        <div className={ styles.itemCont } >
            <div 
                className={ styles.bullet}
                tabIndex="-1"
            >                            
                <Icon
                    name={ 'asterix_bullet' }
                    size={ 16 }
                    color={ item ? '--color-type-high-emphasis' : '--color-type-low-emphasis'}
                />
            </div>

            <div
                className={ styles.itemText }
                style={{
                    direction: textDirection( surveyLang ).direction,
                    textAlign: textDirection( lang ).align,
                }}
            >
                { itemText }
            </div>
            {
                item &&
                <>
                    <div className={ styles.itemKey }>
                        { item.key}
                    </div>
                    {/* Icons place holder: */}
                    <div style={{width: pageData.type === 'SCALE' ? 84 : 100}}/> 
                </>
            }
        </div>
    )

}
