import React from 'react';

import { useSelector } from 'react-redux';
import { selectUiLanguage } from '../app/preferencesSlice';

import { GetColor } from '../utils/stylingTools';

import { ReactComponent as _Blank } from '../assets/icons/_.svg';
import { ReactComponent as Avatar } from '../assets/icons/avatar.svg';
import { ReactComponent as Bold } from '../assets/icons/bold.svg';
import { ReactComponent as Link } from '../assets/icons/link.svg';
import { ReactComponent as DynamicText } from '../assets/icons/dynamic_text.svg';
import { ReactComponent as RtlText } from '../assets/icons/rtl_text.svg';
import { ReactComponent as LtrText } from '../assets/icons/ltr_text.svg';
import { ReactComponent as Help } from '../assets/icons/help.svg';
import { ReactComponent as Attention } from '../assets/icons/attention.svg';
import { ReactComponent as Search } from '../assets/icons/search.svg';
import { ReactComponent as Delete } from '../assets/icons/delete.svg';
import { ReactComponent as New } from '../assets/icons/new.svg';
import { ReactComponent as X } from '../assets/icons/x.svg';
import { ReactComponent as Plus } from '../assets/icons/plus.svg';
import { ReactComponent as Options } from '../assets/icons/options.svg';
import { ReactComponent as OptionsHorizontal } from '../assets/icons/options_horizontal.svg';
import { ReactComponent as Label } from '../assets/icons/lable.svg';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow_down.svg';
import { ReactComponent as RightArrow } from '../assets/icons/right_arrow.svg';
import { ReactComponent as DownArrow } from '../assets/icons/down_arrow.svg';
import { ReactComponent as UpArrow } from '../assets/icons/up_arrow.svg';
import { ReactComponent as LeftArrow } from '../assets/icons/left_arrow.svg';
import { ReactComponent as ChevronLeftArrow } from '../assets/icons/chevron_left_arrow.svg';
import { ReactComponent as ChevronRightArrow } from '../assets/icons/chevron_right_arrow.svg';
import { ReactComponent as ChevronUpArrow } from '../assets/icons/chevron_up_arrow.svg';
import { ReactComponent as ChevronDownArrow } from '../assets/icons/chevron_down_arrow.svg';
import { ReactComponent as Columns } from '../assets/icons/columns.svg';
import { ReactComponent as Download } from '../assets/icons/download.svg';
import { ReactComponent as Upload } from '../assets/icons/upload.svg';
import { ReactComponent as LogOut } from '../assets/icons/log_out.svg';
import { ReactComponent as Media } from '../assets/icons/media.svg';
import { ReactComponent as Language } from '../assets/icons/language.svg';
import { ReactComponent as RightArrowTwo } from '../assets/icons/right_arrow_2.svg';
import { ReactComponent as Location } from '../assets/icons/location.svg';
import { ReactComponent as Source } from '../assets/icons/source.svg';
import { ReactComponent as Grid } from '../assets/icons/grid.svg';
import { ReactComponent as Rows } from '../assets/icons/rows.svg';
import { ReactComponent as GradedRows } from '../assets/icons/graded_rows.svg';
import { ReactComponent as Code } from '../assets/icons/code.svg';
import { ReactComponent as Filter } from '../assets/icons/filter.svg';
import { ReactComponent as I } from '../assets/icons/i.svg';
import { ReactComponent as Small_arrow_down } from '../assets/icons/small_arrow_down.svg';
import { ReactComponent as Vi } from '../assets/icons/vi.svg';
import { ReactComponent as Minus } from '../assets/icons/minus.svg';
import { ReactComponent as DragHandle } from '../assets/icons/drag_handle.svg';
import { ReactComponent as AsterixBullet } from '../assets/icons/asterix_bullet.svg';
import { ReactComponent as Warning } from '../assets/icons/warning.svg';
import { ReactComponent as Error } from '../assets/icons/error.svg';
import { ReactComponent as WarningFlat } from '../assets/icons/warning_flat.svg';
import { ReactComponent as ErrorFlat } from '../assets/icons/error_flat.svg';
import { ReactComponent as Settings } from '../assets/icons/settings.svg';
import { ReactComponent as Export } from '../assets/icons/export.svg';
import { ReactComponent as Gear } from '../assets/icons/gear.svg';
import { ReactComponent as Random } from '../assets/icons/random.svg';
import { ReactComponent as ZoomIn } from '../assets/icons/zoom_in.svg';
import { ReactComponent as ZoomOut } from '../assets/icons/zoom_in.svg';
import { ReactComponent as CheckAll } from '../assets/icons/check_all.svg';
import { ReactComponent as CheckNone } from '../assets/icons/check_none.svg';
import { ReactComponent as Locked } from '../assets/icons/locked.svg';
import { ReactComponent as Unlocked } from '../assets/icons/unlocked.svg';
import { ReactComponent as Refresh } from '../assets/icons/refresh.svg';
import { ReactComponent as Restore } from '../assets/icons/restore.svg';
import { ReactComponent as Archive } from '../assets/icons/archive.svg';
import { ReactComponent as StarFull } from '../assets/icons/star_full.svg';
import { ReactComponent as StarOutline } from '../assets/icons/star_outline.svg';
import { ReactComponent as Preview } from '../assets/icons/preview.svg';
import { ReactComponent as ExternalLink } from '../assets/icons/external_link.svg';
import { ReactComponent as Logic } from '../assets/icons/logic.svg';
import { ReactComponent as Goto } from '../assets/icons/goto.svg';
import { ReactComponent as OnEnter } from '../assets/icons/on_enter.svg';
import { ReactComponent as OnExit } from '../assets/icons/on_exit.svg';
import { ReactComponent as Home } from '../assets/icons/home.svg';
import { ReactComponent as Clipboard } from '../assets/icons/clipboard.svg';
import { ReactComponent as Pinned } from '../assets/icons/pinned.svg';
import { ReactComponent as Unpinned } from '../assets/icons/unpinned.svg';
import { ReactComponent as ReadOnly } from '../assets/icons/read_only.svg';
import { ReactComponent as Mobile } from '../assets/icons/mobile.svg';
import { ReactComponent as Desktop } from '../assets/icons/desktop.svg';
import { ReactComponent as NoConnection } from '../assets/icons/no_connection.svg';
import { ReactComponent as Hide } from '../assets/icons/hide.svg';
import { ReactComponent as CollapseAll } from '../assets/icons/collapse_all.svg';
import { ReactComponent as ExpandAll } from '../assets/icons/expand_all.svg';
import { ReactComponent as Chart } from '../assets/icons/chart.svg';
import { ReactComponent as Accessibility } from '../assets/icons/accessibility.svg';

import { ReactComponent as ArithmeticOperators } from '../assets/icons/arithmetic_operators.svg';
import { ReactComponent as LogicOperators } from '../assets/icons/logic_operators.svg';
import { ReactComponent as MathMethods } from '../assets/icons/math_methods.svg';

import { ReactComponent as BooleanVar } from '../assets/icons/boolean_var.svg';
import { ReactComponent as NumberVar } from '../assets/icons/number_var.svg';
import { ReactComponent as TextVar } from '../assets/icons/text_var.svg';
import { ReactComponent as UrlVar } from '../assets/icons/url_var.svg';
import { ReactComponent as DataSource } from '../assets/icons/datasource.svg';

const icons = {
    blank: { comp: _Blank, reflectable: true },
    avatar: { comp: Avatar, reflectable: true },
    bold: { comp: Bold, reflectable: false },
    link: { comp: Link, reflectable: false },
    dynamic_text: { comp: DynamicText, reflectable: false },
    ltr_text: { comp: LtrText, reflectable: false },
    rtl_text: { comp: RtlText, reflectable: false },
    help: { comp: Help, reflectable: false },
    attention: { comp: Attention, reflectable: true },
    search: { comp: Search, reflectable: true },
    delete: { comp: Delete, reflectable: true },
    new: { comp: New, reflectable: true },
    x: { comp: X, reflectable: true },
    plus: { comp: Plus, reflectable: true },
    options: { comp: Options, reflectable: true },
    options_horizontal: { comp: OptionsHorizontal, reflectable: true },
    label: { comp: Label, reflectable: true },
    arrow_down: { comp: ArrowDown, reflectable: true },
    right_arrow: { comp: RightArrow, reflectable: false },
    left_arrow: { comp: LeftArrow, reflectable: false },
    up_arrow: { comp: UpArrow, reflectable: true },
    down_arrow: { comp: DownArrow, reflectable: true },
    chevron_left_arrow: { comp: ChevronLeftArrow, reflectable: false },
    chevron_right_arrow: { comp: ChevronRightArrow, reflectable: false },
    chevron_up_arrow: { comp: ChevronUpArrow, reflectable: true },
    chevron_down_arrow: { comp: ChevronDownArrow, reflectable: true },
    columns: { comp: Columns, reflectable: true },
    download: { comp: Download, reflectable: true },
    upload: { comp: Upload, reflectable: true },
    log_out: { comp: LogOut, reflectable: true },
    media: { comp: Media, reflectable: true },
    language: { comp: Language, reflectable: true },
    right_arrow_two: { comp: RightArrowTwo, reflectable: true },
    location: { comp: Location, reflectable: true },
    source: { comp: Source, reflectable: true },
    code: { comp: Code, reflectable: true },
    rows: { comp: Rows, reflectable: true },
    graded_rows: { comp: GradedRows, reflectable: true },
    grid: { comp: Grid, reflectable: true },
    small_arrow_down: { comp: Small_arrow_down, reflectable: true },
    filter: { comp: Filter, reflectable: true },
    i: { comp: I, reflectable: false },
    vi: { comp: Vi, reflectable: false },
    minus: { comp: Minus, reflectable: true },
    drag_handle: { comp: DragHandle, reflectable: true },
    asterix_bullet: { comp: AsterixBullet, reflectable: true },
    warning: { comp: Warning, reflectable: true },
    error: { comp: Error, reflectable: true },
    warning_flat: { comp: WarningFlat, reflectable: true },
    error_flat: { comp: ErrorFlat, reflectable: true },
    settings: { comp: Settings, reflectable: true },
    export: { comp: Export, reflectable: true },
    gear: { comp: Gear, reflectable: true },
    random: { comp: Random, reflectable: true },
    zoom_in: { comp: ZoomIn, reflectable: true },
    zoom_out: { comp: ZoomOut, reflectable: true },
    check_all: { comp: CheckAll, reflectable: false },
    check_none: { comp: CheckNone, reflectable: false },
    locked: { comp: Locked, reflectable: false },
    unlocked: { comp: Unlocked, reflectable: false },
    refresh: { comp: Refresh, reflectable: false },
    restore: { comp: Restore, reflectable: false },
    archive: { comp: Archive, reflectable: true },
    star_full: { comp: StarFull, reflectable: true },
    star_outline: { comp: StarOutline, reflectable: true },
    preview: { comp: Preview, reflectable: true },
    external_link: { comp: ExternalLink, reflectable: true },
    logic: { comp: Logic, reflectable: false },
    goto: { comp: Goto, reflectable: true },
    on_enter: { comp: OnEnter, reflectable: true },
    on_exit: { comp: OnExit, reflectable: true },
    home: { comp: Home, reflectable: true },
    clipboard: { comp: Clipboard, reflectable: true },
    pinned: { comp: Pinned, reflectable: true },
    unpinned: { comp: Unpinned, reflectable: true },
    read_only: { comp: ReadOnly, reflectable: false },
    mobile: { comp: Mobile, reflectable: true },
    desktop: { comp: Desktop, reflectable: true },
    no_connection: { comp: NoConnection, reflectable: false },
    hide: { comp: Hide, reflectable: false },
    collapse_all: { comp: CollapseAll, reflectable: false },
    expand_all: { comp: ExpandAll, reflectable: false },
    chart: { comp: Chart, reflectable: false },
    accessibility: { comp: Accessibility, reflectable: false },

    arithmetic_operators: { comp: ArithmeticOperators, reflectable: false },
    logic_operators: { comp: LogicOperators, reflectable: false },
    math_methods: { comp: MathMethods, reflectable: false },

    boolean_var: { comp: BooleanVar, reflectable: false },
    number_var: { comp: NumberVar, reflectable: false },
    text_var: { comp: TextVar, reflectable: false },
    url_var: { comp: UrlVar, reflectable: false },
    datasource: { comp: DataSource, reflectable: false },
};

/**
 * Icon component
 * @param {string} props.name Icon name
 * @param {object} props.style Custom style
 * @param {string} props.color Hex or global css param
 * @param {number} props.size Icon size in pixels
 */
export default function Icon({ name, style, color, size = 24 }) {
    const lang = useSelector(selectUiLanguage);

    try {
        const IconComp = icons[name].comp;

        const isLtr = lang === 'en';

        const iconStyle = {
            width: size,
            height: size,
            minWidth: size,
            minHeight: size,
            fill: GetColor(color),
            transition: '0.25s ease-out',
            pointerEvents: 'none',
            transform: !isLtr && icons[name].reflectable ? 'scaleX( -1 )' : '',
            ...style,
        };
        return <IconComp style={iconStyle} />;
    } catch {
        console.warn(`Icon "${name}" does not exists`);
        return null;
    }
}
