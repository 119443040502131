import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './AnalysesFieldsModules.module.css';
import DropDown from 'components/DropDown';
import MultipleSelect from 'components/MultipleSelect';
import { fetchReport } from 'app/reportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllLimitation, validateDataKeyType } from 'features/analyses/analysesUtils';

export default function KeyAndValuesFieldModule( {project, data, value, handleChange, texts, lang} ) {

    const dispatch = useDispatch();

    const dataKeys = useSelector( state => {

        const dataKeys = state.report.dataKeys;

        if( !dataKeys ) {
            if( state.report.fetchStatus === 'idle' ) {

                dispatch( fetchReport( project.sourceName ) );
            }

        }

        return dataKeys;
    })

    const dataKeysOptions = useMemo( () => {

        if( !dataKeys ) return [{key: 'loading', label: texts.loadingDataKeys[ lang ]}];

        const options = [];

        Object.keys( dataKeys ).forEach( k => {
            
            const meta = dataKeys[k];
            const isValidType = validateDataKeyType( data, meta );

            if( !isValidType ) return;

            // const type = 
            // meta.originType === 'SCALE_ITEM' ? 'SCALE_ITEM' : 
            // meta.type === 'NULL' ? meta.originType :
            // meta.type;

            const type = meta.originType;

            const title = meta.title ? meta.title : ''

            options.push({
                key: k, 
                label: k,
                type,
                title
            }) 

        }) 

        options.sort( (a,b) => dataKeys[ a.key ].originIndex - dataKeys[ b.key ].originIndex );

        return options;

    }, [dataKeys,data,lang,texts])

    const isLtr = useMemo( () => lang === 'en', [lang] );
    const selectedKey = useMemo ( () => ( value ? value.key : null), [value] );
    const selectedValues = useMemo ( () => ( value ? value.values : null), [value] );

    const isCategorial = useMemo ( () => {

        if( !dataKeys || !selectedKey ) return null;

        const meta = dataKeys[ selectedKey ];
        return ( 
            (meta.type === 'STRING' || 
            meta.originType === 'SCALE_BUNDLE' ||
            meta.originType === 'MULTIPLE_CHOICE_BUNDLE') &&
            meta.valueDescriptions 
        )

    }, [ dataKeys, selectedKey ])

    const valuesOptions = useMemo ( () => {

        if( dataKeys && selectedKey ) {

            if( !isCategorial ) return [];

            const meta = dataKeys[ selectedKey ];
            const isScale = meta.originType === 'SCALE_ITEM' || meta.originType === 'SCALE_BUNDLE';
            const values = meta.valueDescriptions;
            const options = Object.keys( values ).map( k => (
                {
                    key: k, 
                    label: isScale ? values[ k ] : k, 
                    isSelected: selectedValues ? selectedValues.includes( k ) : false
                }) 
            )

            return options;
        }

        return []

    }, [ dataKeys, selectedKey, isCategorial, selectedValues ] )

    useEffect( () => {

        if( data.selectAllByDefault && isCategorial && dataKeys && selectedKey && !selectedValues ) {

            // Select all values by default:

            const values = Object.keys( dataKeys[ selectedKey ].valueDescriptions );
            if( values.length <= selectAllLimitation ) { 
                handleChange( { key: selectedKey, values })
            }
        }

    }, [data, isCategorial, dataKeys, selectedKey, selectedValues, handleChange])

    ///////////
    // UTILS //
    ///////////

    const handleKeySelection = useCallback( ( key ) => {

        handleChange( {key, values: null } )

    }, [ handleChange ])

    const handleValueSelection = useCallback( ( {key} ) => {

        const newValues = selectedValues ? Array.from( selectedValues ) : [];

        if ( newValues.indexOf( key ) >= 0 ) {

            newValues.splice( newValues.indexOf( key ), 1 );

        } else {
            newValues.push( key )
        }

        handleChange( {key: selectedKey, values: newValues } )

    }, [selectedValues,selectedKey, handleChange])

    return (
        <div>
            <DropDown
                label={ data.label ? data.label[ lang ] : '' }
                value={ selectedKey }
                onChange={ (opKey) => handleKeySelection( opKey ) }
            > {
                dataKeysOptions.map( (op,i) => {
                    return (
                        <span key={op.key} label={op.label}>
                            <span className={ styles.dropdownKeyLabel }> {op.label} </span>
                            <span className={ styles.dropdownTypeLabel }> ({op.type}) </span>
                            <span className={ styles.dropdownTitleLabel }> — {op.title}</span>
                        </span>
                    )
                })
            }  
            </DropDown>   
            {
                selectedKey && valuesOptions.length > 0 &&
                <>
                    <div 
                        className={ styles.relatedFieldsIndicator }
                        style={{ 
                            borderLeft: isLtr ? 'var(--border-main )' : 'none',
                            borderRight: isLtr ? 'none' : 'var(--border-main )',
                        }}
                    />
                    <MultipleSelect
                        // label={ data.label ? data.label[ lang ] : '' }
                        options={ valuesOptions }
                        placeholder={ texts.chooseValuesPlaceholder[ lang ] }
                        onChange={ handleValueSelection }
                    />
                </>
            }
        </div>
    )
}
