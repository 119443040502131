import React, { useMemo } from 'react';
import Checkbox from 'components/Checkbox';
import IconButton from 'components/IconButton';
import { useSelector } from 'react-redux';
import { selectPageProperty } from 'features/survey_editor/surveyEditorSlice';
import { surveyEditorTexts } from 'utils/appTexts';
const texts = surveyEditorTexts.properties.pageSettings;

const RepeatedInput = ({ lang, settings, currentPage, updateSettings }) => {
    const pageType = useSelector((state) => {
        if (!currentPage) return null;
        return selectPageProperty(state, currentPage, 'type');
    });

    const { allowRepeatedInput, resetInputOnRevisit } = useMemo(() => {
        if (!settings) return {};
        return { ...settings.general };
    }, [settings]);

    return (
        <>
            {pageType && pageType !== 'BLOCK' && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 12,
                    }}
                >
                    <Checkbox
                        size="s"
                        // className={ propertiesStyles.checkbox }
                        label={texts.allowRepeatedInput[lang]}
                        isChecked={allowRepeatedInput}
                        onChange={(isChecked) =>
                            updateSettings({
                                prop: 'allowRepeatedInput',
                                value: isChecked,
                            })
                        }
                    />
                    <IconButton
                        name="help"
                        theme="Plain"
                        size="s"
                        tooltip={texts.allowRepeatedInpuTooltip[lang]}
                    />
                </div>
            )}

            {allowRepeatedInput && (
                <Checkbox
                    size="s"
                    // className={ propertiesStyles.checkbox }
                    label={texts.resetInputOnRevisit[lang]}
                    isChecked={resetInputOnRevisit}
                    onChange={(isChecked) =>
                        updateSettings({
                            prop: 'resetInputOnRevisit',
                            value: isChecked,
                        })
                    }
                />
            )}
        </>
    );
};

export default RepeatedInput;
