import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import Button from 'components/Button';

import { useSelector } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';

import styles from './Snackbar.module.css';
import { motion, AnimatePresence } from 'framer-motion'
import Icon from 'components/Icon';

export const types = {
    PLAIN: 'plain',
    NEUTRAL: 'neutral', 
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error' 
}

/**
 * Snackbar poper
 * @param {{
 * type: enum // NEUTRAL | SUCCESS | WARNING | ERROR
 * message: string // text message
 * index: integer // placement index, usefull for displaying multiple messages at once 
 * timeout: number // display duration of the snack bar (in seconds)
 * onClose: function
 * action: object{ label <string>, callback <function> }
 * }} props 
 */
export default function Snackbar({
    type = types.NEUTRAL, 
    message, 
    index = 0,
    timeout = -1,
    onClose, 
    action
}) {

    const lang = useSelector( selectUiLanguage );
    const isLtr = lang === 'en';
    const [isActive, setIsActive] = useState( false );

    useEffect( () => { setIsActive( true ); }, [] )

    const motionVarients = useMemo( () => ({
        visible: { 
            y: index * -54,
            transition: { duration: 0.5, ease: 'backOut' }
        },
        hidden: { 
            y: 54,
            transition: { duration: 0.3, ease: 'backIn' }
        }
    }), [index]);

    function handleClose() {
        
        setIsActive( false );

        if( onClose ) { 
            setTimeout( () => onClose(), 600 ); 
        }

    }

    function handleActionButton() {

        if( action.callback ) {
            action.callback();
        }

        handleClose();
    }

    return ReactDOM.createPortal(

        <AnimatePresence initial={false}>
            { isActive &&
                <motion.div 
                    className={ styles.container } 
                    style={{ 
                        direction: isLtr ? 'ltr' : 'rtl',
                        left: isLtr ? 'unset' : 'var( --space-medium )',
                        right: !isLtr ? 'unset' : 'var( --space-medium )',
                    }}
                    variants={ motionVarients }
                    initial='hidden'
                    animate='visible'
                    exit='hidden'   
                    // onAnimationStart={ () => console.log("Anim start")}
                    // onAnimationComplete={ () => console.log("Anim end")}
                    transition={{ duration: 0.2, ease: "easeOut" }}
                >
                    <motion.div
                        initial={{width: '0%'}}
                        animate={ timeout === -1 ? {} : {width: '100%'}}
                        transition={{ duration: timeout, ease:'linear' }}
                        className={ styles.timeoutFill }
                        onAnimationComplete={ handleClose }
                    />
                    {
                        type !== types.PLAIN &&
                        <SideIcon type={ type }/>
                    }
                    <div className={ styles.gap }/>
                    <h3 className={ styles.messageLabel }>{ message }</h3>
                    
                    { action ?
                        <>
                            <div className={ styles.gap }/>
                            <Button                    
                                theme='Plain'
                                label={ action.label }
                                colorSet='PrimaryOnDark'
                                onClick={ handleActionButton }
                                bgStyle={{
                                    padding: '2px 6px',
                                    margin: '0 12px'
                                }}
                            />
                        </>
                        :
                        <div className={ styles.gap }/>
                    }

                </motion.div>
            }
        </AnimatePresence>,
        
        document.getElementById('root')

    );
}

const sideIconContent = {
    'neutral': { color: '#ffffff3f', icon: 'i' }, 
    'success': { color: 'var( --color-success )', icon: 'vi' },
    'warning': { color: 'var( --color-warning )', icon: 'warning_flat' },
    'error': { color: 'var( --color-error-medium )', icon: 'error_flat' } 
}

const SideIcon = ({type}) => {
    
    return (
        <div 
            className={ styles.sideIconCont }
            style={{
                backgroundColor: sideIconContent[ type ].color
            }}
        >
            <Icon
                name={ sideIconContent[ type ].icon }
                size={ 18 }
                color={ '#ffffff' }
            />
        </div>
    )
}