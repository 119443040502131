import React from "react";
import styles from "./MediaSettings.module.css";
import GlobalMediaSettings from "./GlobalMediaSettings";
import PageMedia from "./PageMedia";

// @TODO: separate into different files: PageMediaSelection, MediaSettings...
const MediaSettings = () => {
    return (
        <div className={styles.mediaSettingsCont}>
            <PageMedia />
            <GlobalMediaSettings />
        </div>
    );
};

export default MediaSettings;
