import React, { useCallback, useMemo } from 'react';
import styles from './Page.module.css';
import DragIcon from 'assets/icons/reorder.png';
import { useDispatch } from 'react-redux';
import { displayStateChanged } from 'features/survey_editor/surveyEditorSlice';

export default function PageDragHandle ({pageId, isBlock, isHovered, onDragStarted, onDragEnded}) {

    const dispatch = useDispatch();

    const dragIcon = useMemo( () => {
        const img = new Image(24,24);
        img.src = DragIcon;
        return img
    }, [])

    const mainScroller = useMemo( () =>{
        const element = document.getElementById( 'survey_editor_content_main' );
        return element;
    }, [])


    const handleDragStart = useCallback( (e) => {
        e.stopPropagation();
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setDragImage( dragIcon, 24,4 );
        const data = {
            movingPageId: pageId, 
            isBlock
        }
        e.dataTransfer.setData('text/plain', JSON.stringify( data ));

        if( onDragStarted ) {
            onDragStarted()
        }

        dispatch( displayStateChanged( { property: 'isDraggingToReorder', value: true }))

    }, [pageId, isBlock, onDragStarted, dragIcon, dispatch])


    const handleDrag = useCallback( (e) => {
        e.stopPropagation();
        
        // Handle auto-scroll on container boundries:
        if( mainScroller ) {
            const delta = window.innerHeight - e.pageY;
            if( delta < 100 ) {
                // Scroll down:
                mainScroller.scrollBy( 0,100/delta )
            }
            if( delta > (window.innerHeight - 100) ) {
                // Scroll up:
                mainScroller.scrollBy( 0, 100 / (delta - window.innerHeight) )
            }
        }
    }, [mainScroller])

    const handleDragEnd = useCallback( (e) => {
        e.stopPropagation();
        dispatch( displayStateChanged( { property: 'isDraggingToReorder', value: false }))
        if( onDragEnded ) {
            onDragEnded();
        }
    },[dispatch, onDragEnded])

    return (
        <>
            <div 
                className={ styles.dragHandle } 
                style={{visibility: isHovered ? 'unset' : 'hidden'}}
                tabIndex="-1" // exclued from tab focus

                draggable={ true }
                onDragStart={ handleDragStart }
                onDrag={ handleDrag }
                onDragEnd={ handleDragEnd }
            />
        </>
    )
}