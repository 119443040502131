import React, { useState } from 'react';
import styles from './SidePanelButton.module.css';
import IconButton from '../../components/IconButton';
import Icon from '../../components/Icon'


export default function SidePanelButton({

    label,
    bgStyle, 
    onClick,
    isSelected,
    onDelete,
    icnStyle = { width: 18, margin: '0px 14px 0px 14px'},
    icnName = 'label',
    showOptions = true,
    color

}) {

    const [ itemHovered, setItemHovered ] = useState( false );

    const contStyle = {

        backgroundColor: isSelected || itemHovered ? 'var( --color-background-mild )' : 'transparent',
        ...bgStyle

    }

    return (
        <div 
            className={ styles.mainContainer } 
            style={ contStyle }
            onClick={ onClick }
            onMouseEnter={ () => {setItemHovered( true )}}
            onMouseLeave={ () => {setItemHovered( false )}}
        >
            <Icon 
                key= "icon" 
                name= { icnName } 
                color={ color }
                size = { 24 }
                style={ icnStyle }
            />

            <p className={ styles.text }> { label } </p>
            
            <div style={{ flexGrow: 1 }} />
            { showOptions && <IconButton 
                name = "options"
                theme = 'Plain'
                size = 's'
                colorSet = 'Grayscale'
                onClick = { onDelete }
                bgStyle = {{ opacity: itemHovered ? 1 : 0, margin: '0px 6px'}}
            /> }
        </div>
    )

}