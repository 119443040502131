import React, { useCallback, useState, useMemo } from "react";
import styles from "./MediaSettings.module.css";
import MediaLibrary from "components/media_library/MediaLibrary";
import Button from "components/Button";
import IconButton from "components/IconButton";
import produce from "immer";
import { surveyEditorTexts } from "utils/appTexts";
import { useDispatch, useSelector } from "react-redux";
import { mediaSettings as initMediaSettings } from "features/survey_editor/surveyEditorConfiguration";
import { selectUiLanguage } from "app/preferencesSlice";
import {
    mediaChanged,
    pageContentChanged,
} from "features/survey_editor/surveyEditorSlice";

const texts = surveyEditorTexts.properties.media;

const GlobalMediaSettings = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const surveyId = useSelector(
        (state) => state.surveyEditor.present.surveyId
    );
    const media = useSelector(
        (state) => state.surveyEditor.present.survey.media
    );
    const currentPage = useSelector(
        (state) => state.surveyEditor.present.display.currentPage
    );
    const pageData = useSelector(
        (state) =>
            state.surveyEditor.present.survey.content.pagesData[currentPage]
    );
    const [pageSettings, mediaSettings] = useMemo(() => {
        return [pageData?.settings, pageData?.settings?.media];
    }, [pageData]);

    const pageMedia = useMemo(() => {
        if (pageData && media && media.files) {
            return media.files.find((f) => f.id === pageData.media);
        }
    }, [pageData, media]);

    const [isLibraryOpen, setIsLibraryOpen] = useState(false);

    const resetSettings = useCallback(() => {
        if (pageSettings && mediaSettings) {
            const newSettings = produce(pageSettings, (s) => {
                s.media = initMediaSettings;
            });

            dispatch(
                pageContentChanged({
                    id: currentPage,
                    prop: "settings",
                    value: newSettings,
                })
            );
        }
    }, [currentPage, pageSettings, mediaSettings, dispatch]);

    const handleMediaChange = useCallback(
        (state) => {
            dispatch(mediaChanged(state));
        },
        [dispatch]
    );

    const handleSelection = useCallback(
        (selected) => {
            if (selected[0]) {
                dispatch(
                    pageContentChanged({
                        id: currentPage,
                        prop: "media",
                        value: selected[0],
                    })
                );
                resetSettings();
            }
            setIsLibraryOpen(false);
        },
        [currentPage, dispatch, resetSettings]
    );

    const handleRemoveMedia = useCallback(() => {
        dispatch(
            pageContentChanged({
                id: currentPage,
                prop: "media",
                value: null,
            })
        );
        resetSettings();
    }, [dispatch, currentPage, resetSettings]);

    return (
        <>
            <div className={styles.mediaSelectionCont}>
                <Button
                    label={pageMedia?.name || texts.chooseMediaBtn[lang]}
                    theme="Outlined"
                    bgStyle={{ flex: 1, maxWidth: "calc( 100% - 32px)" }}
                    colorSet={"Field"}
                    onClick={() => setIsLibraryOpen(true)}
                />
                {pageData.media && (
                    <IconButton
                        name={"x"}
                        tooltip={texts.clearMediaTooltip[lang]}
                        theme="Outlined"
                        bgStyle={{ marginInlineStart: 6 }}
                        size="s"
                        colorSet={"Grayscale"}
                        onClick={handleRemoveMedia}
                    />
                )}
            </div>

            <MediaLibrary
                storageId={surveyId}
                isActive={isLibraryOpen}
                mediaFiles={media?.files}
                mediaFilesArchive={media?.archive}
                onClose={() => setIsLibraryOpen(false)}
                onChange={handleMediaChange}
                onSelection={handleSelection}
            />
        </>
    );
};

export default GlobalMediaSettings;
