import React from 'react';
import Icon from './Icon';
import styles from './AttentionBar.module.css';

export default function AttentionBar({
    className,
    style,
    labelStyle,
    iconStyle, 
    label,
    icon,
    children
}) {

    return (
        <div 
            className={ `${styles.barContainer} ${className}`}
            style={ style }
        >
            {
                icon &&
                <Icon 
                    name={ icon }
                    size={ 18 }
                    color='--color-onPrimary'
                    style={{
                        marginInlineEnd: 8,
                        ...iconStyle 
                    }}
                />

            }

            { label && 
                <label
                    className={ styles.label}
                    style={ labelStyle}
                >
                    { label }
                </label>
            }

            {children}
        </div>
    )
}