import React, { useCallback } from 'react';
import moduleStyles from './PageModulesStyles.module.css';
import RichTextInput from 'components/rich_text/RichTextInput';
import { surveyEditorTexts } from 'utils/appTexts';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { selectPageProperty, pageTextContentChanged } from 'features/survey_editor/surveyEditorSlice';
import { textDirection } from 'utils/stylingTools';

const texts = surveyEditorTexts.content.pageModules.description;

export default function DescriptionPageModule( {pageId}) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const description = useSelector( state => selectPageProperty( state, pageId, 'subtitle' ) );
    const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );

    const handleTextChange = useCallback( ( prop, value ) => {

        dispatch( pageTextContentChanged( {id: pageId, prop, value, lang: surveyLang }) );

    }, [pageId, surveyLang, dispatch ])

    return (
        <>
            <RichTextInput
                key={ 'description_' + surveyLang }
                className={ moduleStyles.editableParagraph }
                textAlignment={ textDirection( lang ).align }
                value={ description[ surveyLang ] || '' }
                placeholder={ texts.placeholder[ lang ] }
                onChange={ value => handleTextChange( "subtitle", value )}
            />
        </>
    )
}