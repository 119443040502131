import React, { useMemo, useState } from "react";
import styles from "./SurveyEditorMain.module.css";
import Split from "react-split"; //https://github.com/nathancahill/split/tree/master/packages/splitjs#options
import "components/Split.css";
import SurveyEditorContent from "./content_area/SurveyEditorContent";
import SurveyEditorProperties from "./properties_area/SurveyEditorProperties";
import SurveyEditorPreview from "./preview_area/SurveyEditorPreview";
import EditingLock from "./EditingLock";
import EdirotSideBar from "./SurveyEditorSideBar";
import ActivityIndicator from "components/ActivityIndicator";
import AttentionBar from "components/AttentionBar";
import EditorMessages from "./SurveyEditorMessages";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectProjectById } from "../../app/horizonDataSlice";
import { selectUiLanguage } from "app/preferencesSlice";
import { surveyEditorTexts, appSectionsTexts } from "utils/appTexts";

export const splitViewModes = {
    NORMAL: "NORMAL",
    COLLAPSED_PROPERTIES: "COLLAPSED_PROPERTIES",
    EXTENDED_PROPERTIES: "EXTENDED_PROPERTIES",
    DESKTOP_VIEW: "DESKTOP_VIEW",
    MOBILE_VIEW: "MOBILE_VIEW",
};

export default function SurveyEditorMain() {
    const { projectId } = useParams();
    const project = useSelector((state) => selectProjectById(state, projectId));

    const lang = useSelector(selectUiLanguage);
    const isTemplate = useMemo(() => project && project.isTemplate, [project]);

    if (!project) return <NoProject />;

    return (
        <>
            {isTemplate && (
                <AttentionBar
                    label={appSectionsTexts.previewOnly[lang]}
                    icon={"read_only"}
                />
            )}
            <div
                className={styles.surveyEditorMainView}
                style={{ top: isTemplate ? 44 : 0 }}
            >
                <EdirotSideBar />
                <SplitView />
                <EditingLock />
                <EditorMessages />
            </div>
        </>
    );
}

const SplitView = () => {
    const lang = useSelector(selectUiLanguage);
    const isLtr = lang === "en";
    const splitViewMode = useSelector(
        (state) => state.surveyEditor.present.display.splitViewMode
    );

    const [animate, setAnimate] = useState(true);

    const transition = useMemo(() => {
        return animate ? "width 0.3s ease-out" : "none";
    }, [animate]);

    const sizes = useMemo(() => {
        switch (splitViewMode) {
            case splitViewModes.COLLAPSED_PROPERTIES:
                return isLtr ? [0, 50, 50] : [50, 50, 0];

            case splitViewModes.EXTENDED_PROPERTIES:
                return isLtr ? [75, 25, 0] : [0, 25, 75];

            case splitViewModes.NORMAL:
                return isLtr ? [24, 38, 38] : [38, 38, 24];

            case splitViewModes.MOBILE_VIEW:
                return isLtr ? [0, 50, 50] : [50, 50, 0];

            case splitViewModes.DESKTOP_VIEW:
                return isLtr ? [0, 30, 70] : [70, 30, 0];

            default:
                return isLtr ? [24, 38, 38] : [38, 38, 24];
        }
    }, [isLtr, splitViewMode]);

    // const getWidth = useCallback( (dimeniton,elementSize) => {
    //     return ({ 'width': `${elementSize}%` });
    // },[])

    if (isLtr) {
        return (
            <Split
                className={styles.splitContainer}
                sizes={sizes}
                minSize={[0, 200, 0]}
                onDragStart={() => setAnimate(false)}
                onDragEnd={() => setAnimate(true)}
                gutterSize={6}
                snapOffset={10}
                dir="ltr"
                cursor="col-resize"
            >
                <div className={styles.propertiesArea} style={{ transition }}>
                    <SurveyEditorProperties />
                </div>
                <div className={styles.contentArea} style={{ transition }}>
                    <SurveyEditorContent />
                </div>
                <div className={styles.previewArea} style={{ transition }}>
                    <SurveyEditorPreview />
                </div>
            </Split>
        );
    } else {
        return (
            <Split
                className={styles.splitContainer}
                sizes={sizes}
                onDragStart={() => setAnimate(false)}
                onDragEnd={() => setAnimate(true)}
                minSize={[0, 200, 0]}
                gutterSize={6}
                snapOffset={10}
                dir="ltr"
                cursor="col-resize"
            >
                <div
                    className={styles.previewArea}
                    style={{ transition }}
                    dir="rtl"
                >
                    <SurveyEditorPreview />
                </div>
                <div
                    className={styles.contentArea}
                    style={{ transition }}
                    dir="rtl"
                >
                    <SurveyEditorContent />
                </div>
                <div
                    className={styles.propertiesArea}
                    style={{ transition }}
                    dir="rtl"
                >
                    <SurveyEditorProperties />
                </div>
            </Split>
        );
    }
};

const NoProject = () => {
    const lang = useSelector(selectUiLanguage);
    const fetchStatus = useSelector((state) => state.horizonData.fetchStatus);
    const fetchProjectByIdStatus = useSelector(
        (state) => state.horizonData.fetchProjectByIdStatus
    );

    if (fetchStatus === "loading" || fetchProjectByIdStatus === "loading") {
        return (
            <div className={styles.loaderContainer}>
                <ActivityIndicator size={32} />
            </div>
        );
    }
    return <h2>{surveyEditorTexts.projectNotFound[lang]}</h2>;
};
