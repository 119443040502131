import React, { useCallback, useMemo, useState } from 'react';
import styles from './ConditionBuilder.module.css';
import ArgumentSetup from '../ArgumentSetup';
import ComparisonOperator, {
    dataTypes as comparisonDataTypes,
} from './ComparisonOperator';
import IconButton from 'components/IconButton';
import { useSelector } from 'react-redux';
import { surveyEditorTexts } from 'utils/appTexts';
import { motion } from 'framer-motion';
import {
    argumentsTypes,
    userInputTypes,
    variablesTypes,
} from '../LogicConfiguration';

const texts = surveyEditorTexts.properties.logic.itemEditor.condition;

export default function ConditionRule({
    contStyle,
    showDelete = true,
    rule,
    args,
    onChange,
    onArgChange,
    onDelete,
}) {
    const surveyVariables = useSelector(
        (state) => state.surveyEditor.present.survey.logic.variables
    );
    const pagesData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData
    );

    const [isHovered, setIsHovered] = useState(false);

    const [operator, pair] = useMemo(() => {
        return Object.entries(rule)[0];
    }, [rule]);

    const argsPair = useMemo(() => {
        const argsIds = pair.map((arg) => arg.var);
        return argsIds.map((argId) => ({ id: argId, ...args[argId] }));
    }, [pair, args]);

    const comparisonType = useMemo(() => {
        let targetDataType = comparisonDataTypes.BOOLEAN;

        const firstArg = argsPair[0];

        switch (firstArg.type) {
            case argumentsTypes.SURVEY_VARIABLES.key:
                const targetVar = surveyVariables.find(
                    (v) => v.id === firstArg.variable
                );
                if (targetVar) {
                    const varType = targetVar.type;
                    switch (varType) {
                        case variablesTypes.NUMBER:
                            targetDataType = comparisonDataTypes.NUMBER;
                            break;
                        case variablesTypes.STRING:
                            targetDataType = comparisonDataTypes.STRING;
                            break;
                        case variablesTypes.URL_PARAM:
                            targetDataType = comparisonDataTypes.STRING;
                            break;
                        case variablesTypes.DATASOURCE:
                            targetDataType = comparisonDataTypes.STRING;
                            break;
                        case variablesTypes.BOOLEAN:
                            targetDataType = comparisonDataTypes.BOOLEAN;
                            break;
                        case variablesTypes.ARRAY:
                            targetDataType = comparisonDataTypes.ARRAY;
                            break;
                        default:
                            break;
                    }
                }
                break;
            case argumentsTypes.USER_INPUT.key:
                if (
                    firstArg.inputType === userInputTypes.TIME_SPAN ||
                    firstArg.inputType === userInputTypes.ANSWER_INDEX
                ) {
                    targetDataType = comparisonDataTypes.NUMBER;
                    break;
                }

                if (firstArg.inputType === userInputTypes.ANSWER) {
                    const targetPage = pagesData[firstArg.page];
                    if (targetPage) {
                        const pageType = targetPage.type;
                        if (
                            [
                                'MULTIPLE_CHOICE',
                                'DROPDOWN_LIST',
                                'SCALE',
                            ].includes(pageType)
                        ) {
                            targetDataType = comparisonDataTypes.ARRAY;
                            break;
                        }

                        if (pageType === 'OPEN_ENDED_QUESTION') {
                            targetDataType = comparisonDataTypes.STRING;
                            break;
                        }

                        if (pageType === 'NUMBER') {
                            targetDataType = comparisonDataTypes.NUMBER;
                            break;
                        }
                    }
                }
                break;
            case argumentsTypes.CONSTANT.key:
                const varType = firstArg.variableType;
                switch (varType) {
                    case variablesTypes.NUMBER:
                        targetDataType = comparisonDataTypes.NUMBER;
                        break;
                    case variablesTypes.STRING:
                        targetDataType = comparisonDataTypes.STRING;
                        break;
                    case variablesTypes.URL_PARAM:
                        targetDataType = comparisonDataTypes.STRING;
                        break;
                    case variablesTypes.DATASOURCE:
                        targetDataType = comparisonDataTypes.STRING;
                        break;
                    case variablesTypes.BOOLEAN:
                        targetDataType = comparisonDataTypes.BOOLEAN;
                        break;
                    case variablesTypes.ARRAY:
                        targetDataType = comparisonDataTypes.ARRAY;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }

        return targetDataType;
    }, [argsPair, surveyVariables, pagesData]);

    const handleOperatorChange = useCallback(
        (newOperator) => {
            const newRule = { [newOperator]: pair };
            onChange(newRule);
        },
        [pair, onChange]
    );

    const handleArgTypeChange = useCallback(
        (arg, typeData) => {
            onArgChange({
                id: arg.id,
                ...typeData,
            });
        },
        [onArgChange]
    );

    const handleArgValueChange = useCallback(
        (arg, value) => {
            onArgChange({
                ...arg,
                value,
            });
        },
        [onArgChange]
    );

    return (
        <motion.div
            className={styles.ruleCont}
            style={contStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onBlur={() => setIsHovered(false)}
            initial={{ scale: 0.9, opacity: 0, y: 10 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
            <div className={styles.ruleComparisonCont}>
                <ArgumentSetup
                    key={'arg_0'}
                    argInx={0}
                    args={argsPair}
                    isHovered={isHovered}
                    onSelectType={(t) => handleArgTypeChange(argsPair[0], t)}
                    onValueChange={(v) => handleArgValueChange(argsPair[0], v)}
                />
                <ComparisonOperator
                    operator={operator}
                    dataType={comparisonType}
                    onChange={handleOperatorChange}
                    disabled={!argsPair[0].type}
                />
                <ArgumentSetup
                    key={'arg_1'}
                    argInx={1}
                    args={argsPair}
                    isHovered={isHovered}
                    disabled={!argsPair[0].type}
                    onSelectType={(t) => handleArgTypeChange(argsPair[1], t)}
                    onValueChange={(v) => handleArgValueChange(argsPair[1], v)}
                />
            </div>

            {showDelete && (
                <div
                    className={styles.deleteBtnCont}
                    style={{
                        right: 'calc( 100% - 4px )',
                        opacity: isHovered ? 1 : 0.05,
                    }}
                >
                    <IconButton
                        name="delete"
                        tooltip={texts.deleteRuleTooltip['en']}
                        size="s"
                        colorSet="Grayscale"
                        theme="Plain"
                        onClick={onDelete}
                    />
                </div>
            )}
        </motion.div>
    );
}
