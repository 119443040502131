import React, { useEffect, useState } from "react";
import styles from "./Results.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { selectUiLanguage } from "app/preferencesSlice";
import { fetchReport } from "app/reportSlice";
import {
    selectProjectById,
    fetchProjectById,
    saveProjectOpeningMark,
    selectCurrentTeam,
} from "app/horizonDataSlice";
import ResultsRecords from "./ResultsRecords";
import RecordsOptions from "./RecorsOptions";
import "components/Split.css";

export default function Results(props) {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const lang = useSelector(selectUiLanguage);
    const currentTeam = useSelector(selectCurrentTeam);
    const reportFetchStatus = useSelector((state) => state.report.fetchStatus);
    const results = useSelector((state) => state.report.results);

    const project = useSelector((state) => selectProjectById(state, projectId));
    const fetchProjectByIdStatus = useSelector(
        (state) => state.horizonData.fetchProjectByIdStatus
    );

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (projectId && !project && fetchProjectByIdStatus === "idle") {
            dispatch(fetchProjectById(projectId));
        }
    }, [dispatch, project, fetchProjectByIdStatus, projectId]);

    useEffect(() => {
        if (project && currentTeam) {
            dispatch(
                saveProjectOpeningMark({
                    teamId: currentTeam.id,
                    project: project,
                    route: "/results",
                })
            );
        }
    }, [dispatch, project, currentTeam]);

    useEffect(() => {
        // Once the project data has been fetched and updated in the redux store, we can dispatch an action to fetch the report data:
        if (project) {
            if (reportFetchStatus === "idle") {
                setIsLoading(true);
                dispatch(fetchReport(project.sourceName));
            }

            if (reportFetchStatus === "succeeded") {
                if (results) {
                    setIsLoading(false);
                }
            }
        } else {
            setIsLoading(true);
        }
    }, [dispatch, project, reportFetchStatus, results]);

    return (
        <div className={styles.mainContainer}>
            <RecordsOptions
                lang={lang}
                project={project}
                isLoading={isLoading}
                onLoading={setIsLoading}
            />
            <ResultsRecords
                lang={lang}
                project={project}
                isLoading={isLoading}
                onLoading={setIsLoading}
            />
        </div>
    );
}
