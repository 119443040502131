import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import styles from './Page.module.css';
import PageDragOverReceiver from './PageDragOverReceiver';
import { Editable } from 'components/Editable';
import NewPagePopper from './NewPagePopper';
import BlockOptions from './BlockOptions';
import IconButton from 'components/IconButton';
import ContentListItem from '../ContentListItem'
import Button from 'components/Button';
import CollapseButton from 'components/CollapseButton';

import { modes as propertiesAreaModes } from 'features/survey_editor/properties_area/SurveyEditorProperties';
import { pageTypesConfig } from 'features/survey_editor/surveyEditorConfiguration';

import { useDispatch, useSelector } from 'react-redux';
import { displayStateChanged, surveyDebugChanged, pageTextContentChanged } from 'features/survey_editor/surveyEditorSlice';
import { selectUiLanguage } from 'app/preferencesSlice';

import { surveyEditorTexts } from 'utils/appTexts';
import { textDirection } from 'utils/stylingTools';
import Icon from 'components/Icon';
import PageDragHandle from './PageDragHandle';

const blockTexts = surveyEditorTexts.content.pageModules.block;

export default function Block( {id, index, data} ) {

    const dispatch = useDispatch();
    
    const lang = useSelector( selectUiLanguage );
	const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );
    const currentPage = useSelector( state => state.surveyEditor.present.display.currentPage );
    
    const titleInputRef = useRef();
    const addPageExternalyBtnRef = useRef();
    const addPageInternalyBtnRef = useRef();

    const [isExternalPopperActive, setIsExternalPopperActive] = useState( false );
    const [isInternalPopperActive, setIsInternalPopperActive] = useState( false );
  
    const [isHovered, setIsHovered] = useState( false );
    const [isCollapsed, setIsCollapsed] = useState( false );
	const [isDragging, setIsDragging] = useState( false );

	useEffect( () => {
		
		if( currentPage === id && titleInputRef.current && titleInputRef.current.value === '' ) {
			// focus on a new page title
			titleInputRef.current.focus();
		}

    }, [currentPage, id])
    

    //// UTILS ////

    const handleMouseEnter = useCallback( ( e ) => {

        setIsHovered( true )

    }, [])
    
    const onNewPagePopperDismiss = useCallback( () => {

        setIsExternalPopperActive( false );
        setIsInternalPopperActive( false );
        setIsHovered( false );

    }, [])

    const handleContSelect = useCallback( () => {

        if( currentPage !== id ) {

            dispatch( displayStateChanged( { property: 'currentPage', value: id } ) );
            dispatch( surveyDebugChanged( {property: 'previewSplash', value: false } ) );
            dispatch( surveyDebugChanged( {property: 'previewId', value: id } ) );
            dispatch( displayStateChanged({ property: 'propertiesMode', value: propertiesAreaModes.PAGE}))
    
            setIsHovered( true );
        }

    }, [dispatch, currentPage, id])
    
    const updatePageData = useCallback( ( prop, value ) => {

        dispatch( pageTextContentChanged( { id, prop, value, lang: surveyLang } ) );
        
    }, [dispatch,id,surveyLang])

    //// RENDER ////

    const BlockIcon = useMemo( () => pageTypesConfig.BLOCK.icon, []);

    return (

        <div 
            className={ styles.blockContainer }
            onMouseEnter={ handleMouseEnter }
            onMouseOver={ () => { if( !isHovered ) {   setIsHovered( true ) } } }
            onMouseLeave={ () => setIsHovered( false ) }
            onClick={ handleContSelect }

            style={{opacity: isDragging ? 0.3 : 1}}
        >
            {   !isDragging &&
                <>
                    <PageDragOverReceiver pageId={ id } placement='BEFORE' beforeOffset={ -22 } height={ 48 }/>
                    <PageDragOverReceiver pageId={ id } placement='AFTER' afterOffset={ 6 } height={ 48 }/>
                </>
            }
            
            <div className={ styles.blockFrame }
                // style={{
                //     backgroundColor: (snapshot.combineTargetFor || snapshot.isDraggingOver) ? 
                //         'var( --color-primary-extra-light )' 
                //         : 
                //         'var( --color-background-light )',
                // }}
            >
            {
                currentPage === id &&
                <div 
                    style={{ backgroundColor: pageTypesConfig[ 'BLOCK' ].color + '1a' }}
                    className={ styles.selectedBlockIndicator }
                />
            }
            </div>

            <div className={ styles.blockTopCont }>
                <div className={ styles.dragHandleBlockCont } tabIndex="-1">
                    <PageDragHandle 
                        pageId={ id }
                        isHovered={ isHovered }
                        isBlock={ true }
                        onDragStarted={ () => setIsDragging( true ) }
                        onDragEnded={ () => setIsDragging( false ) }
                    />
                    {
                        !isHovered &&  
                        <BlockIcon 
                            className={ styles.itemIcon } 
                            style={{position: 'absolute', width: 24, minWidth: 24}}
                        />
                    }
                </div>

                <h2 className={ styles.itemIndexLabel } > { index+1 } </h2>

                <CollapseButton
                    isCollapsed={ isCollapsed }
                    display={ isHovered }
                    onToggle={ () => setIsCollapsed( !isCollapsed ) }
                />

                <div className={ styles.blockTitleCont }>
                    <Editable // Block Title
                        ref={ titleInputRef }
                        className={ styles.titleInput }
                        value={ data.title[ surveyLang ] || "" }
                        placeholder={ blockTexts.blockTitlePlaceholder[ lang ] }
                        style={{
                            direction: textDirection( surveyLang ).direction,
                            textAlign: textDirection( lang ).align,
                            marginBottom: 0
                        }}
                        onChange={ value => updatePageData( 'title', value ) }
                        onFocus={ handleContSelect }
                        onBlur={ () => setIsHovered( false ) }
                    />
                </div>

                {   //Randomization indication

                    data.settings.general.isRandom &&
                    <Icon 
                        name='random'
                        size={ 20 }
                        color='--color-type-low-emphasis'
                        style={{margin: '0 8px'}}
                    /> 
                }

                <BlockOptions
                    id={ id }
                    data={ data }
                    onDone={ () => setIsHovered( false ) }
                    lang={ lang }
                    display={ isHovered }
                />
            </div>
            
            <div 
                className={ styles.blockInnerCont }
                style={{
                    padding: !isCollapsed ? '24px 16px 16px 16px' : 0
                }}
            >
                {
                    // If block is empty, presetn 'add page' button:
                    data.pages.length === 0 &&

                    <div ref={ addPageInternalyBtnRef } className={ styles.addPageInternalyBtnCont }>
                        <PageDragOverReceiver blockId={ id } placement='BEFORE' beforeOffset={ 16 }/>  
                        <Button 
                            theme="Outlined"
                            label={ blockTexts.addInternalPage[ lang ] }
                            iconBefore='plus'
                            bgStyle={{
                                width: '100%'
                            }}
                            onClick={ () => setIsInternalPopperActive( true ) }
                        />
                    </div>
                }

                <div 
                    style={{width: '100%', minHeight: 10}}
                >
                    { !isCollapsed &&
                        data.pages.map( (innerPageId,i) => (
                                <ContentListItem 
                                    id={ innerPageId } 
                                    index={ i } 
                                    key={ 'innerPage_' + innerPageId } 
                                    parentBlock={ { id, index }}
                                />
                            )
                        )
                    }
                </div>

            </div>

            { isHovered &&
            <>

                <div 
                ref={ addPageExternalyBtnRef }
                className={ styles.addBtnCont } 
                >
                    <IconButton
                        name="plus"
                        theme="Outlined"
                        onClick={ () => setIsExternalPopperActive( true ) }
                        />
                </div>
            </>
            }
            <NewPagePopper
                referenceElement={ isExternalPopperActive ? 
                    addPageExternalyBtnRef.current : addPageInternalyBtnRef.current
                } 
                isActive={ isExternalPopperActive || isInternalPopperActive }
                targetIndex={ isExternalPopperActive ? ( index + 1 ) : 0 }
                blockId={ isInternalPopperActive ? id : null }
                onDismiss={ onNewPagePopperDismiss }
                onSelect={ onNewPagePopperDismiss }
            />

        </div>


    )
}
