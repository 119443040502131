export function Evaluate( varType, formula, args ) {

    const result = { error:"", output: "" }

    if( !formula ) {
        result.error = "No formula";
        return result;
    } 

    const illegalRegex = /(\b(function)\b|\b(var)\b|()=>)/g;
    const illegal = formula.match( illegalRegex );

    if( illegal ) {

        result.error = `"${illegal[0]}" is illegal.`;
        return result;
    }

    try {

        let _formula = formula;
        let errorWhileParsingArgs = false;
        const argRegex = /\${.+?}/g;
        const matches = _formula.match( argRegex );

        if( matches && matches.length > 0 ) {
            matches.forEach( match => {
                const argKey = match.substring( 2, match.length -1 );
                const argData = Object.values(args).find( d => d.name === argKey );

                if( argData ) {
                    const value = argData.testingValue;
                    _formula = _formula.replace( match, value );
                } else {
                    result.error = "Argument not found: " + argKey;
                    errorWhileParsingArgs = true;
                }
            })
        }
        
        if( errorWhileParsingArgs ) return result;

        /* eslint-disable */
        const func = new Function( `return ${_formula}` );

        // to do: test if categorical or bool return true/false and number return number
        let output = func.apply();
        
        if( varType === 'CATEGORICAL' || varType === 'BOOLEAN' ) {
            if( typeof( output ) !== 'boolean' ) {
                result.error = "Output must be Boolean (true/false)";
                return result;
            } else {
                output = output.toString();
            }
        }

        if( varType === 'NUMERIC' ) {

            if( isNaN( parseFloat( output ) ) ) {
                result.error = "Output must be a number.";
                return result;
            }
        }


        result.output = output;

        return result;

    } catch (err) {

        // console.error( 'FORMULA ERROR:', err );
        result.error = "Invalid formula";
        return result;
    }

}