import FileInput from 'components/FileInput';
import { useCallback, useState } from 'react';
import { surveyEditorTexts } from 'utils/appTexts';
import RestoreAlert from './RestoreAlert';
import useParseBackup from './useParseBackup';

const texts = surveyEditorTexts.properties.publishAndExport;

export default function RestoreFromFile({ lang }) {
    const [alertActive, setAlertActive] = useState(false);
    const [fileData, setFileData] = useState(null);

    const parser = useParseBackup();

    const handleFileSelection = useCallback(
        (file) => {
            try {
                file[0].text().then((fileText) => {
                    const data = parser(fileText);

                    if (data) {
                        setFileData(data);
                        setAlertActive(true);
                    }
                });
            } catch (err) {
                alert("Can't read file. Details: " + err);
            }
        },
        [parser]
    );

    return (
        <>
            <FileInput
                textOnButton={texts.restoreFromFileBtn[lang]}
                showFileName={false}
                buttonTheme="Plain"
                buttonBgStyle={{
                    width: '100%',
                }}
                accept=".hrz"
                onSelect={handleFileSelection}
            />
            <RestoreAlert
                isActive={alertActive}
                surveyData={fileData}
                onClose={() => setAlertActive(false)}
            />
        </>
    );
}
