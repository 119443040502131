import React, { useCallback, useMemo } from 'react';
import DropDown from 'components/DropDown';
import MultipleSelect from 'components/MultipleSelect';

export default function SelectFieldModule ({data, value, handleChange, lang}) {

    const singleSelectionOptions = useMemo( () => {
        
        if( data ) {

            return data.options.map( op => ({key: op.key, label: op.label[lang]}) );
        }

        return [];

    }, [data, lang] );

    const selectedValues = useMemo( () => {

        if( value ) {

            return value.split(',');
        }

        return [];

    }, [value])

    const multipleSelectionOption = useMemo( () => {

        if( data ) {
            
            return data.options.map( op => ({ 
                key: op.key, 
                label: op.label[ lang ], 
                isSelected: selectedValues ? selectedValues.includes( op.key ) : false 
            }))
        }

        return [];

    }, [data, selectedValues, lang])

    const handleValueSelection = useCallback( ( {key, isSelected} ) => {

        const newValues = selectedValues ? Array.from( selectedValues ) : [];

        if ( newValues.indexOf( key ) >= 0 ) {

            newValues.splice( newValues.indexOf( key ), 1 );

        } else {
            newValues.push( key )
        }

        handleChange( newValues.join(',') );

    }, [selectedValues,handleChange])

    return (
        <>
        {
            data.isMultiple ?
            <MultipleSelect
                label={ data.label ? data.label[ lang ] : '' }
                options={ multipleSelectionOption }
                placeholder={ data.placeholder ? data.placeholder[ lang ] : "..." }
                onChange={ (op) => handleValueSelection( {key: op.key, isSelected: op.isSelected} ) }
            />
            :
            <DropDown
                label={ data.label ? data.label[ lang ] : '' }
                options={ singleSelectionOptions }
                value={ value }
                onChange={ (op) => handleChange( op.key ) }
            />
            
        }
        </>
    )
}