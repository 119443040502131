import React, { useEffect, useMemo }from 'react';
import styles from  './Analyses.module.css';
import AnalysesSidePanel from './AnalysesSidePanel';
import AnalysesContent from './AnalysesContent';
import Split from 'react-split'; //https://github.com/nathancahill/split/tree/master/packages/splitjs#options
import 'components/Split.css';
import AttentionBar from 'components/AttentionBar';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectProjectById, fetchProjectById } from '../../app/horizonDataSlice';
import { selectUiLanguage } from 'app/preferencesSlice';
import { appSectionsTexts } from 'utils/appTexts';


export default function Analyses() {

    const { projectId } = useParams();
    const project = useSelector( state => selectProjectById( state, projectId ) );
    const lang = useSelector( selectUiLanguage );
    const dispatch = useDispatch();
    const fetchProjectByIdStatus = useSelector( state => state.horizonData.fetchProjectByIdStatus );

    const isTemplate = useMemo( () => project && project.isTemplate, [project]);

    useEffect(() => {
        if (projectId && !project && fetchProjectByIdStatus !== 'loading') {
            dispatch(fetchProjectById(projectId));
        }
    }, [project, fetchProjectByIdStatus, projectId, dispatch])

    return (
        <>
            { isTemplate &&
                <AttentionBar
                    label={ appSectionsTexts.previewOnly[ lang ] }
                    icon={ 'read_only' }
                />
            }
            <div 
                className={ styles.analysesMainCont }
                style={{ top: isTemplate ? 44 : 0 }}
            >
                <AnalysesSplitView project={ project }/>
            </div>
        </>
    )

}

const AnalysesSplitView = ({project}) => {

    const lang = useSelector( selectUiLanguage );
    const isLtl = useMemo( () => lang === 'en', [lang]);

    if( isLtl ) {
        return (
            <Split 
                className={ styles.analysesSplitCont }
                sizes={[30,70]}
                minSize={[360, 500]}
                gutterSize={6}
                snapOffset={10}
                dir='ltr'
                cursor="col-resize"
            >
                <AnalysesSidePanel project={ project }/>
                <AnalysesContent project={ project }/>
            </Split>
        )
    } else {
        return (
            <Split 
                className={ styles.analysesSplitCont }
                sizes={[70,30]}
                minSize={[500,360]}
                gutterSize={6}
                snapOffset={10}
                dir='ltr'
                cursor="col-resize"
            >
                <AnalysesContent project={ project } dir='rtl'/>
                <AnalysesSidePanel project={ project } dir='rtl'/>
            </Split>
        )
    }
}