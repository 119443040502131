import React, { useCallback, useRef, useState } from 'react';
import styles from './ConditionBuilder.module.css';
import Button from 'components/Button';
import SelectionPopper from 'components/poppers/SelectionPopper';
import { surveyEditorTexts } from 'utils/appTexts';
import { logicOperators } from '../LogicConfiguration';

const texts = surveyEditorTexts.properties.logic.itemEditor.condition;

export default function LogicOperator({operator, isDisabled, onChange}) {

    const btnRef = useRef();

    const [isPopperActive, setIsPopperActive] = useState( false );

    const handleSelect = useCallback( (selected) => {

        onChange( selected );
        setIsPopperActive( false );

    }, [onChange])

    return (
        <>
            <div ref={ btnRef } className={ styles.logicOperatorBtnCont }>
                    <Button
                        label={ isDisabled ? "●" : operator }
                        tooltip={ texts.logicalOperatorTooltip[ 'en' ] }
                        theme='Plain'
                        colorSet='Primary'
                        bgStyle={{
                            padding: '6px 8px',
                            borderRadius: 16,
                        }}
                        labelStyle={{ 
                            fontFamily: 'Plex-Mono',
                            fontWeight: 700,
                            fontSize: 16,
                        }}
                        disabled={ isDisabled }
                        onClick={ () => setIsPopperActive( true ) }
                    />
            </div>
            <SelectionPopper
                isActive={ isPopperActive }
                referenceElement={ btnRef.current }
                onDismiss={ () => setIsPopperActive( false ) }
                onSelect={ handleSelect }
                dir='ltr'
            >
                <span key={ logicOperators.AND } >
                    { texts.and[ 'en' ] } 
                </span>
                <span key={ logicOperators.OR } >
                    { texts.or[ 'en' ] } 
                </span>
            </SelectionPopper>
        </>
    )
}