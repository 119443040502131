import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';
import NewProject from './NewProject';
import styles from './CreateProjectRow.module.css';
import { homeTexts } from '../../utils/appTexts';
import ActivityIndicator from 'components/ActivityIndicator';
import { selectCurrentTeam, setHomePageSelectedTemplateId } from 'app/horizonDataSlice';
import IconButton from 'components/IconButton';


export default function CreateProjectRow() {
    
    const lang = useSelector(selectUiLanguage);
    const createNewProjectStatus = useSelector(state => state.horizonData.createNewProjectStatus);
    const fetchProjectByIdStatus = useSelector(state => state.horizonData.fetchProjectByIdStatus);
    const newProjectToFetch = useSelector(state => state.horizonData.newProjectToFetch);
    const currentTeam = useSelector(selectCurrentTeam);
    

    const isLoading = useMemo(() => {
        return createNewProjectStatus === 'succeeded' && fetchProjectByIdStatus === 'loading' && newProjectToFetch
    }, [createNewProjectStatus, newProjectToFetch, fetchProjectByIdStatus])

    const templateProjects = useMemo(() => {
        if (!currentTeam || !currentTeam.projects) {
            return []
        }
        var templates = []
        currentTeam.projects.forEach(project => {
            if (project.isTemplate && !templates.find(p=>p.id === project.id) ) {
                templates.push(project)
            }
        })

        return templates
    }, [currentTeam])


    return (
        <div className={styles.mainCont}>
            <p className={styles.labelHeadline}> {homeTexts.newProject.createNew[lang]} </p>
            <div className={styles.rowCont}>
                {!isLoading && <>
                    <NewProject />
                    {templateProjects.map(project => {
                        return <TemplateItem key={project.id} project={project} />
                    })}
                </>}
                {isLoading && <div className={styles.rowItem}> <ActivityIndicator size={24} /> </div>}

            </div>
        </div>
    )
}

function TemplateItem({ project }) {
    const [isHovered, setIsHovered] = useState(false)
    const dispatch = useDispatch();
    
    return <div 
        className={styles.rowItem}
        onPointerEnter={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)}
        onClick={() => {
            dispatch (setHomePageSelectedTemplateId(project.id))
        }}>
        
        <span className={ styles.newItemLabel }>{`${project.icon} ${project.name}`}</span>
        <IconButton
            name="preview"
            tooltip="Preview template"
            theme="Plain"
            size="m"
            colorSet="Grayscale"
            bgStyle={{ 
                opacity: isHovered ? 1 : 0.1
            }}
            onClick={() => {
                window.open(`/survey/${project.id}`);
            }}
        />
    </div>
}