import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Api from '../api/api';
import { LocalStorage, SessionStorage } from '../utils/cacheManager';

const initialState = {
    currentTeamId: null,
    teams: {},
    tempProjects: {}, //TODO explain..
    lastVisitedProjects: {},
    pinnedProjects: {},
    currentLabelId: '',
    homePageSelectedTemplateId: null,
    projectToCopyId: null,
    showArchive: false,
    newProjectToFetch: null,
    fetchTeamsDataStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchTeamProjectsStatus: 'idle',
    syncPinnedAndVisitedProjectsStatus: 'idle',
    createNewProjectStatus: 'idle',
    updateProjectStatus: 'idle',
    deleteProjectStatus: 'idle',
    fetchProjectByIdStatus: 'idle',

    fetchTeamsError: null,
    fetchTeamProjectsError: null,
    deleteProjectError: null,
}

///////////////////
// ASYNC ACTIONS //
///////////////////

export const fetchTeamsData = createAsyncThunk(
    'horizonData/fetchTeamsData',
    async (arg, thunkApi) => {
        const response = await Api.FetchTeamsData();
        if (response.error || !response.data.teamsData) {
            console.warn("Error while fetching initial data. Details: ", response.error);
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            SessionStorage.Save(SessionStorage.keys.TEAMS_DATA, response.data.teamsData);
            return response.data.teamsData;
        }
    }
)

export const syncPinnedAndVisitedProjects = createAsyncThunk(
    'horizonData/syncPinnedAndVisitedProjects',
    async (arg, thunkApi) => {

        const state = thunkApi.getState();
        var projectIds = []
        Object.keys(state.horizonData.lastVisitedProjects).forEach(teamId => {
            state.horizonData.lastVisitedProjects[teamId].forEach((mark, index) => {
                projectIds.push(mark.projectId)
            });
        });
        Object.keys(state.horizonData.pinnedProjects).forEach(teamId => {
            state.horizonData.pinnedProjects[teamId].forEach((project, index) => {
                projectIds.push(project.id)
            });
        });

        const response = await Api.GetProjectsById(projectIds);
        if (response.error || !response.data || !response.data.allProjects) {
            console.warn("Error while fetching initial data. Details: ", response.error);
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            var projectsDict = {}
            response.data.allProjects.forEach(project => { projectsDict[project.id] = project })

            var synced_last_visited_projects = {}
            var synced_pinned_projects = {}
            Object.keys(state.horizonData.lastVisitedProjects).forEach(teamId => {
                synced_last_visited_projects[teamId] = []
                state.horizonData.lastVisitedProjects[teamId].forEach(mark => {
                    const serverProject = projectsDict[mark.projectId]
                    if (serverProject && !projectsDict.isArchive) {
                        synced_last_visited_projects[teamId].push({ ...mark, project: serverProject })
                    }
                })
            })
            Object.keys(state.horizonData.pinnedProjects).forEach(teamId => {
                synced_pinned_projects[teamId] = []
                state.horizonData.pinnedProjects[teamId].forEach(project => {
                    const serverProject = projectsDict[project.id]
                    if (serverProject && !projectsDict.isArchive) {
                        synced_pinned_projects[teamId].push(serverProject)
                    }
                })
            })
            return { lastVisitedProjects: synced_last_visited_projects, pinnedProjects: synced_pinned_projects };
        }
    }
)
export const fetchSomeTeamProjects = createAsyncThunk(
    'horizonData/fetchSomeTeamProjects',
    async (arg, thunkApi) => {
        const limit = arg && arg.limit ? arg.limit : 20

        const state = thunkApi.getState();
        const teamId = state.horizonData.currentTeamId;
        if (!teamId) {
            return thunkApi.rejectWithValue("Cant fetch projects if no currentTeam");
        }

        const currentTeam = state.horizonData.teams[teamId]
        const skip = currentTeam.projects ? currentTeam.projects.length : 0;
        const response = await Api.FetchTeamProjects(teamId, skip, limit);
        if (response.error || !response.data.projects) {
            console.warn("Error while fetching team's project. Details: ", response.error);
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            return { currectState: state, newProjects: response.data.projects };
        }
    }
)
export const fetchProjectById = createAsyncThunk(
    'horizonData/fetchProjectById',
    async (id, thunkApi) => {
        const state = thunkApi.getState();
        const response = await Api.GetProjectById(id);

        if (response.error) {
            console.warn("Error while fetching project by id. Details: ", response.error);
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            return { currectState: state, project: response.data.Project };
        }
    }
)
export const createNewProject = createAsyncThunk(
    'horizonData/createNewProject',
    async (data, thunkApi) => {

        const { type, projectName, sourceName, icon, labelIds, appendixBucketsProjectIds, templateProjectId } = data;
        const state = thunkApi.getState();
        const teamId = state.horizonData.currentTeamId;

        const response = await Api.CreateProject({
            teamId,
            sourceName,
            projectName,
            icon,
            templateProjectId,
            labelIds: JSON.stringify(labelIds),
            createNotebook: true,
            createSurvey: type === 'regular',
            appendixBucketsProjectIds: JSON.stringify(appendixBucketsProjectIds),
            projectType: type
        });

        // const response = await Api.CreateSurveyProject( {name, sourceName, icon, teamId, labels, notebookTitle } );

        if (response.error) {
            console.warn("Error while creating new project. Details: ", response.error);
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            return response.data;
        }
    }
)
export const updateProject = createAsyncThunk(
    'horizonData/updateProject',
    async (args, thunkApi) => {
        const state = thunkApi.getState();
        const { projectId, data, ignoreFeedback } = args; // data: { isArchived: false, name: 'some_name' ... }

        const response = await Api.UpdateProject(projectId, data);

        if (response.error) {
            console.warn("Error while updating a project. Details: ", response.error);
            return thunkApi.rejectWithValue(response.error.statusText);
        } else {
            return { currectState: state, data: response.data, ignoreFeedback };
        }
    }
)
export const deleteProject = createAsyncThunk(
    'horizonData/deleteProject',
    async (sourceName, thunkApi) => {
        const state = thunkApi.getState();
        const response = await Api.DeleteProject(sourceName);
        if (response.error) {
            console.warn("Error while deleting a project. Details: ", response.error);
            return thunkApi.rejectWithValue({ error: response.error, sourceName });
        } else {
            return { currentState: state, data: response.data, sourceName };
        }
    }
)

//////////////////
// UTILS /////////
//////////////////

function initiateTeam(state, teamsData) {
    var currentTeamId = LocalStorage.Read(LocalStorage.keys.CURRENT_TEAM);
    if (!currentTeamId && teamsData[0]) {
        currentTeamId = teamsData[0].id;
    }
    state.currentTeamId = currentTeamId;
    LocalStorage.Save(LocalStorage.keys.CURRENT_TEAM, currentTeamId);
}
async function initiatePinnedAndOpenedProjects(state) {
    var last_visited_projects = {}
    try {
        last_visited_projects = JSON.parse(LocalStorage.Read(LocalStorage.keys.LAST_VISITED_PROJECTS))
    } catch {
        last_visited_projects = {}
    }
    state.lastVisitedProjects = last_visited_projects

    var pinned_projects = {}
    try {
        pinned_projects = JSON.parse(LocalStorage.Read(LocalStorage.keys.PINNED_PROJECTS))
    } catch {
        pinned_projects = {}
    }
    if (!pinned_projects) {
        pinned_projects = {}
    }
    state.pinnedProjects = pinned_projects
}
function initiateLabel(state, label) {
    const storedLabel = LocalStorage.Read(LocalStorage.keys.CURRENT_LABEL);
    if (storedLabel) {
        state.currentLabelId = storedLabel;
    } else {
        state.currentLabelId = '';
    }
}

/////////////////
// SLICE ////////
/////////////////

const horizonDataSlice = createSlice({
    name: 'horizonData',
    initialState,
    reducers: {
        setCurrentTeam: (state, action) => {
            const teamId = action.payload
            state.currentTeamId = teamId;
            state.currentLabelId = '';
            state.fetchTeamProjectsStatus = 'idle';
            LocalStorage.Save(LocalStorage.keys.CURRENT_TEAM, teamId);
        },
        setCurrentLabelId: (state, action) => {
            state.currentLabelId = action.payload;
            LocalStorage.Save(LocalStorage.keys.CURRENT_LABEL, action.payload);
        },
        setHomePageSelectedTemplateId: (state, action) => {
            state.homePageSelectedTemplateId = action.payload;
        },
        setProjectToCopyId: (state, action) => {
            state.projectToCopyId = action.payload;
        },
        updateTeamTags: (state, action) => {
            state.tags = action.payload;
        },
        deleteErrorAcknowledged: state => {
            state.deleteProjectError = null
        },
        resetFetchStatus: (state, action) => {
            const proccessName = action.payload;
            state[proccessName] = 'idle';
        },

        // HOME //
        homeDisplayChanged: (state, action) => {
            const { prop, value } = action.payload;
            state.home.display[prop] = value;
        },
        setShowArchive: (state, action) => {
            state.showArchive = action.payload;
        },
        saveProjectOpeningMark: (state, action) => {
            const { project, route, teamId } = action.payload;
            if (!project || !route || !teamId) {
                return
            }
            const projectId = project.id
            var last_visited_projects;
            try {
                last_visited_projects = JSON.parse(LocalStorage.Read(LocalStorage.keys.LAST_VISITED_PROJECTS))
            } catch { }
            if (!last_visited_projects) { last_visited_projects = {} }
            if (!last_visited_projects[teamId]) {
                last_visited_projects[teamId] = []
            }


            last_visited_projects[teamId] = last_visited_projects[teamId].filter(function (mark) {
                return mark.projectId !== projectId;
            });

            last_visited_projects[teamId].unshift({
                projectId: projectId,
                time: new Date().getTime(),
                route: route,
                project: project
            })


            state.lastVisitedProjects = last_visited_projects
            LocalStorage.Save(LocalStorage.keys.LAST_VISITED_PROJECTS, JSON.stringify(last_visited_projects));
        },
        removesProjectOpeningMark: (state, action) => {
            const { project, teamId } = action.payload;
            if (!project || !teamId) {
                return
            }
            const projectId = project.id
            var last_visited_projects;
            try {
                last_visited_projects = JSON.parse(LocalStorage.Read(LocalStorage.keys.LAST_VISITED_PROJECTS))
            } catch { }
            if (!last_visited_projects) { last_visited_projects = {} }
            if (!last_visited_projects[teamId]) {
                last_visited_projects[teamId] = []
            }

            last_visited_projects[teamId] = last_visited_projects[teamId].filter(function (mark) {
                return mark.projectId !== projectId;
            });

            state.lastVisitedProjects = last_visited_projects
            LocalStorage.Save(LocalStorage.keys.LAST_VISITED_PROJECTS, JSON.stringify(last_visited_projects));
        },

        saveToPinnedProjects: (state, action) => {
            const { project, saveOrRemove, teamId } = action.payload;
            if (!project || !saveOrRemove || !teamId) {
                return
            }
            var pinned_projects = {}
            try {
                pinned_projects = JSON.parse(LocalStorage.Read(LocalStorage.keys.PINNED_PROJECTS))
            } catch {
                pinned_projects = {}
            }
            if (!pinned_projects) {
                pinned_projects = {}
            }
            if (!pinned_projects[teamId]) {
                pinned_projects[teamId] = []
            }


            if (saveOrRemove === 'save') {
                if (!pinned_projects[teamId].find(p => p.id === project.id)) {
                    pinned_projects[teamId].unshift(project)
                }
            } else if (saveOrRemove === 'remove') {
                pinned_projects[teamId] = pinned_projects[teamId].filter(function (p) {
                    return p.id !== project.id;
                });
            }


            LocalStorage.Save(LocalStorage.keys.PINNED_PROJECTS, JSON.stringify(pinned_projects));
            state.pinnedProjects = pinned_projects
        },
        updateLocalProject: (state, action) => {
            const { projectId, teamId, data } = action.payload;
            if (!projectId || !teamId || !data) {
                return
            }

            Object.keys(data).forEach(key => {
                var inx = state.teams[teamId].projects.findIndex(p => p.id === projectId);
                if (inx > -1) {
                    state.teams[teamId].projects[inx][key] = data[key]
                }
                if (state.pinnedProjects[teamId]) {
                    inx = state.pinnedProjects[teamId].findIndex(p => p.id === projectId);
                
                    if (inx > -1) {
                        state.pinnedProjects[teamId][inx][key] = data[key]
                    }
                }
            })
        }
    },
    extraReducers: {
        // FETCH TEAMS DATA //
        [fetchTeamsData.pending]: (state, action) => {
            state.fetchTeamsDataStatus = 'loading';
        },
        [fetchTeamsData.fulfilled]: (state, action) => {
            const teamsArray = action.payload;
            initiateTeam(state, teamsArray);
            initiatePinnedAndOpenedProjects(state)
            initiateLabel(state, teamsArray);

            var teamsDict = {}
            teamsArray.forEach(team => {
                teamsDict[team.id] = team
            })
            state.teams = teamsDict;
            state.fetchTeamsDataStatus = 'succeeded';
        },
        [fetchTeamsData.rejected]: (state, action) => {
            state.fetchTeamsError = action.payload;
            state.fetchTeamsDataStatus = 'failed';
        },

        // FETCH TEAM PROJECTS //
        [fetchSomeTeamProjects.pending]: (state, action) => {
            state.fetchTeamProjectsStatus = 'loading';
        },
        [fetchSomeTeamProjects.fulfilled]: (state, action) => {
            state.fetchTeamProjectsStatus = 'succeeded';
            var { newProjects, currectState } = action.payload;
            const teamId = currectState.horizonData.currentTeamId;
            state.teams[teamId].projects = state.teams[teamId].projects ? state.teams[teamId].projects.concat(newProjects) : newProjects;
        },
        [fetchSomeTeamProjects.rejected]: (state, action) => {
            state.fetchTeamProjectsStatus = 'failed';
            state.fetchTeamProjectsError = action.payload;
        },

        // SYNC PINNED AND VISITED PROJECTS //
        [syncPinnedAndVisitedProjects.pending]: (state, action) => {
            state.syncPinnedAndVisitedProjectsStatus = 'loading';
        },
        [syncPinnedAndVisitedProjects.fulfilled]: (state, action) => {
            state.syncPinnedAndVisitedProjectsStatus = 'succeeded';
            var { lastVisitedProjects, pinnedProjects } = action.payload;
            if (lastVisitedProjects && pinnedProjects) {
                state.lastVisitedProjects = lastVisitedProjects
                state.pinnedProjects = pinnedProjects
                LocalStorage.Save(LocalStorage.keys.PINNED_PROJECTS, JSON.stringify(pinnedProjects));
                LocalStorage.Save(LocalStorage.keys.LAST_VISITED_PROJECTS, JSON.stringify(lastVisitedProjects));
            }


        },
        [syncPinnedAndVisitedProjects.rejected]: (state, action) => {
            state.syncPinnedAndVisitedProjectsStatus = 'failed';

        },






        // FETCH PROJECT BY ID //
        [fetchProjectById.pending]: (state, action) => {
            state.fetchProjectByIdStatus = 'loading'
        },
        [fetchProjectById.fulfilled]: (state, action) => {
            state.fetchProjectByIdStatus = 'succeeded';
            state.newProjectToFetch = null;
            const { project, currectState } = action.payload;

            if (project && currectState) {
                if (project.labels) {
                    project.labels = project.labels.map(l => l.id)
                }
                if (currectState.horizonData.currentTeamId &&
                    currectState.horizonData.teams[currectState.horizonData.currentTeamId] &&
                    currectState.horizonData.teams[currectState.horizonData.currentTeamId].projects) {
                    const currentProjects = currectState.horizonData.teams[currectState.horizonData.currentTeamId].projects
                    state.teams[currectState.horizonData.currentTeamId].projects = [project].concat(currentProjects)
                } else {
                    state.tempProjects[project.id] = project;
                }
            }
        },
        [fetchProjectById.rejected]: (state, action) => {
            state.fetchProjectByIdStatus = 'failed'
        },

        // CREATE PROJECT //
        [createNewProject.pending]: state => {
            state.createNewProjectStatus = 'loading'
        },
        [createNewProject.fulfilled]: (state, action) => {
            state.createNewProjectStatus = 'succeeded';
            const data = action.payload;
            if (data && data.projectId) {
                state.newProjectToFetch = data.projectId;
            }
        },
        [createNewProject.rejected]: state => {
            state.createNewProjectStatus = 'failed'
        },

        // UPDATE PROJECT //
        [updateProject.pending]: state => {
            state.updateProjectStatus = 'loading'
        },
        [updateProject.fulfilled]: (state, action) => {
            state.updateProjectStatus = 'succeeded';
            const { data, currectState, ignoreFeedback } = action.payload;
            if (data && currectState && !ignoreFeedback) {
                var project = data.project
                if (project.labels) {
                    project.labels = project.labels.map(l => l.id)
                }
                const teamId = currectState.horizonData.currentTeamId;
                const inx = state.teams[teamId].projects.findIndex(p => p.id === project.id);
                if (inx > -1) {
                    Object.assign(state.teams[teamId].projects[inx], project);
                }
            }
        },
        [updateProject.rejected]: state => {
            state.updateProjectStatus = 'failed'
        },

        // DELETE PROJECT //
        [deleteProject.pending]: state => {
            state.deleteProjectStatus = 'loading'
        },
        [deleteProject.fulfilled]: (state, action) => {
            state.deleteProjectStatus = 'succeeded';
            const { data, sourceName, currentState } = action.payload;
            if (data) {
                const teamId = currentState.horizonData.currentTeamId;
                const inx = state.teams[teamId].projects.findIndex(p => p.sourceName === sourceName);
                state.teams[teamId].projects.splice(inx, 1);
            }
        },
        [deleteProject.rejected]: (state, action) => {
            state.deleteProjectStatus = 'failed';
            const { error, sourceName } = action.payload;
            state.deleteProjectError = { sourceName, error };
        },
    }
})

export const {
    setCurrentTeam,
    setShowArchive,
    setCurrentLabelId,
    setHomePageSelectedTemplateId,
    setProjectToCopyId,
    updateTeamTags,
    deleteErrorAcknowledged,
    resetFetchStatus,
    homeDisplayChanged,
    saveProjectOpeningMark,
    removesProjectOpeningMark,
    saveToPinnedProjects,
    updateLocalProject
} = horizonDataSlice.actions;

export default horizonDataSlice.reducer;

///////////////////////
///// SELECTORS //////
/////////////////////

export const selectCurrentTeam = state => {
    if (state.horizonData.teams && state.horizonData.currentTeamId) {
        return state.horizonData.teams[state.horizonData.currentTeamId]
    }
    return null
}
export const selectCurrentTeamLabels = (state) => {
    if (state.horizonData.teams && state.horizonData.currentTeamId && state.horizonData.teams[state.horizonData.currentTeamId]) {
        if (state.horizonData.teams[state.horizonData.currentTeamId].labels) {
            return state.horizonData.teams[state.horizonData.currentTeamId].labels
        }
    }
    return []
}
export const selectCurrentTeamLastVisitedProjects = (state) => {
    if (state.horizonData.currentTeamId && state.horizonData.lastVisitedProjects) {
        const projects = state.horizonData.lastVisitedProjects[state.horizonData.currentTeamId]
        return projects ? projects : []
    }
    return []
}
export const selectCurrentTeamPinnedProject = (state) => {
    if (state.horizonData.currentTeamId && state.horizonData.pinnedProjects) {
        const projects = state.horizonData.pinnedProjects[state.horizonData.currentTeamId]
        return projects ? projects : []
    }
    return []
}

export const selectProjectById = (state, projectId) => {
    if (!projectId) return null;
    var project;
    if (projectId && state.horizonData.teams && state.horizonData.currentTeamId) {
        if (state.horizonData.teams[state.horizonData.currentTeamId].projects) {
            project = state.horizonData.teams[state.horizonData.currentTeamId].projects.find(proj => {
                if (!proj) return false;
                return proj.id === projectId;
            });
            return project;
        }
    }
    if (!project && projectId) {
        project = state.horizonData.tempProjects[projectId]
    }
    if (state.horizonData.fetchProjectByIdStatus === 'succeeded' && !project) return '404';
    return project
}
export const selectAllTeamProjects = (state) => {
    if (state.horizonData.teams && state.horizonData.currentTeamId && state.horizonData.teams[state.horizonData.currentTeamId]) {
        if (state.horizonData.teams[state.horizonData.currentTeamId].projects) {
            return state.horizonData.teams[state.horizonData.currentTeamId].projects
        }
    }
    return []
}