import React, { useEffect, useMemo } from 'react';
import NotebookAutoSave from './NotebookAutoSave';
import NotebookSynchronizer from './NotebookSynchronizer';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectProjectById, fetchProjectById, fetchTeamsData } from 'app/horizonDataSlice';
import { fetchSectionsTemplates } from '../notebookSlice';

export default function NotebookServices() {

    const { projectId } = useParams();
    
    const dispatch = useDispatch();
    
    const project = useSelector( state => selectProjectById( state, projectId ) );
    const fetchTeamsDataStatus = useSelector( state => state.horizonData.fetchTeamsDataStatus );
    const fetchProjectByIdStatus = useSelector( state => state.horizonData.fetchProjectByIdStatus );
    const sectionsTemplatesFetchStatus = useSelector( state => state.notebook.sectionsTemplatesFetchStatus );

    const isTemplate = useMemo( () => project && project.isTemplate, [project]);

    useEffect( () => {

        // If project id exists but the project data hasn't been fetched yet, fetch the project first.

        if( projectId ) {
            if( fetchProjectByIdStatus === 'idle' ) {
                dispatch( fetchProjectById( projectId ));
            }
        }

    }, [projectId, dispatch, fetchProjectByIdStatus])

    useEffect( () => {
        if( fetchTeamsDataStatus === 'idle' ) {
            dispatch( fetchTeamsData() );
        }

        if( fetchTeamsDataStatus === 'succeeded' && sectionsTemplatesFetchStatus === 'idle' ) {
            dispatch( fetchSectionsTemplates() );
        }

    }, [fetchTeamsDataStatus, sectionsTemplatesFetchStatus, dispatch])

    if( !project || project === '404' || !project.notebook ) return null;

    return (
        <>
            <NotebookSynchronizer notebookId={ project.notebook.id }/>
            { !isTemplate &&
                <NotebookAutoSave notebookId={ project.notebook.id }/>
            }
        </>
    );
}