import React, { useCallback, useMemo, useState } from 'react';
import styles from './PageEditableItemsList.module.css';
import IconButton from 'components/IconButton';
import Icon from 'components/Icon';
import { Draggable } from 'react-beautiful-dnd';
import { Editable } from 'components/Editable';
import RichTextInput from 'components/rich_text/RichTextInput';
import NavigationOptions from './PageItemNavigationOptions';
import HidingOptions from './PageItemHidingOptions';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import {
    itemAddedToPage,
    pageItemTextChanged,
    pageItemDeleted,
    pageItemKeyChanged,
    messageAdded,
    alertAdded,
    surveyDebugChanged,
} from 'features/survey_editor/surveyEditorSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import { textDirection } from 'utils/stylingTools';
import { isKeyLegal } from 'utils/validation';
import {
    deletionAlert,
    useReferenceInLogic,
} from 'features/survey_editor/logic/LogicReferenceLoseProtection';
import { OPEN_ITEM } from 'features/survey_editor/surveyEditorConfiguration';
import PageItemNumericValue from './PageItemNumericValue';

export const ADD_ITEM = 'add_item';

const texts = surveyEditorTexts.content.pageItem;

/**
 *
 * @param {{
 * pageId,
 * item,
 * index,
 * onChange
 * onKeyDown
 * onBlur
 * isDraggable
 * isRichText
 * }} props
 */
export default function PageEditableItem({
    pageId,
    itemId,
    index,
    onChange,
    onKeyDown,
    onBlur,
    isRichText = false,
    isDraggable = true,
}) {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const surveyLang = useSelector(
        (state) => state.surveyEditor.present.display.currentLanguage
    );

    const pageData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData[pageId]
    );
    const item = useMemo(() => {
        if (itemId === ADD_ITEM) {
            return {
                isNew: true,
            };
        } else if (itemId === OPEN_ITEM) {
            return {
                isOpen: true,
                ...pageData.itemsData[OPEN_ITEM],
            };
        } else if (pageData && pageData.itemsData && itemId !== ADD_ITEM) {
            return pageData.itemsData[itemId];
        } else return {};
    }, [pageData, itemId]);

    const [isHovered, setIsHovered] = useState(false);

    if (item.isOpen) {
        return (
            <div
                className={styles.itemCont}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className={styles.bullet} />
                <ItemContent
                    pageId={pageId}
                    pageData={pageData}
                    itemId={OPEN_ITEM}
                    item={item}
                    isRichText={isRichText}
                    isHovered={isHovered}
                    lang={lang}
                    surveyLang={surveyLang}
                    dispatch={dispatch}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    onHover={(h) => setIsHovered(h)}
                />
            </div>
        );
    }

    if (item.isNew) {
        return (
            <NewItem
                pageId={pageId}
                isRichText={isRichText}
                index={index}
                lang={lang}
                surveyLang={surveyLang}
                dispatch={dispatch}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
            />
        );
    }

    if (isDraggable) {
        return (
            <DraggableWrapper
                itemId={itemId}
                index={index}
                isHovered={isHovered}
                onHover={(h) => setIsHovered(h)}
            >
                <ItemContent
                    pageId={pageId}
                    pageData={pageData}
                    itemId={itemId}
                    item={item}
                    index={index}
                    isRichText={isRichText}
                    isHovered={isHovered}
                    lang={lang}
                    surveyLang={surveyLang}
                    dispatch={dispatch}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    onHover={(h) => setIsHovered(h)}
                />
            </DraggableWrapper>
        );
    }

    return (
        <div
            className={styles.itemCont}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <ItemContent
                pageId={pageId}
                pageData={pageData}
                itemId={itemId}
                item={item}
                index={index}
                isRichText={isRichText}
                isHovered={isHovered}
                lang={lang}
                surveyLang={surveyLang}
                dispatch={dispatch}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                onHover={(h) => setIsHovered(h)}
            />
        </div>
    );
}

const NewItem = ({
    pageId,
    isRichText,
    index,
    lang,
    surveyLang,
    dispatch,
    onChange,
    onKeyDown,
    onBlur,
}) => {
    const [tempText, setTempText] = useState('');

    ///////////
    // UTILS //
    ///////////

    const handleChange = useCallback(
        (value) => {
            if (onChange) onChange(value);
            setTempText(value);
        },
        [onChange]
    );

    const addNew = useCallback(() => {
        setTempText('');

        const payload = {
            pageId: pageId,
            atIndex: index,
            lang: surveyLang,
            value: tempText,
        };

        dispatch(itemAddedToPage(payload));
    }, [pageId, index, surveyLang, tempText, dispatch]);

    const handleBlur = useCallback(
        (event) => {
            if (tempText) {
                addNew();
            }
            if (onBlur) onBlur(event);
        },
        [tempText, addNew, onBlur]
    );

    /////////////
    // RENDER ///
    /////////////

    const getBulletColor = () => {
        return !tempText
            ? '--color-type-low-emphasis'
            : '--color-type-high-emphasis';
    };

    return (
        <div className={styles.itemCont}>
            <div className={styles.bullet} tabIndex="-1">
                <Icon
                    name={'asterix_bullet'}
                    size={16}
                    color={getBulletColor()}
                />
            </div>

            <Editable
                className={styles.itemText}
                value={tempText}
                placeholder={texts.addNewItemBtn[lang]}
                style={{
                    direction: textDirection(surveyLang).direction,
                    textAlign: textDirection(lang).align,
                }}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                onFocus={() => {}}
                onBlur={handleBlur}
            />
        </div>
    );
};

const DraggableWrapper = ({ itemId, index, onHover, isHovered, children }) => {
    const getItemStyle = (isDragging, draggableStyle) => ({
        backgroundColor: isDragging
            ? 'var( --color-background-light )'
            : isHovered
            ? 'var( --color-background-hover )'
            : 'transparent',
        filter: isDragging ? 'var( --shadow-extra-low )' : 'unset',
        transform: isDragging ? 'rotate(20deg)' : 'unset',

        ...draggableStyle,
    });

    return (
        <Draggable key={itemId} draggableId={itemId} index={index}>
            {(provided, snapshot) => (
                <div
                    className={styles.itemCont}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                    onMouseEnter={() => onHover(true)}
                    onMouseLeave={() => onHover(false)}
                >
                    <div
                        className={styles.bullet}
                        {...provided.dragHandleProps}
                        tabIndex="-1"
                    >
                        <Icon
                            name={isHovered ? 'drag_handle' : 'asterix_bullet'}
                            size={16}
                        />
                    </div>

                    {children}
                </div>
            )}
        </Draggable>
    );
};

const ItemContent = ({
    pageId,
    pageData,
    itemId,
    index,
    isRichText,
    item,
    lang,
    surveyLang,
    dispatch,
    isHovered,
    onHover,
    onBlur,
    onChange,
    onKeyDown,
}) => {
    const getRefsInLogic = useReferenceInLogic(itemId);

    ///////////
    // UTILS //
    ///////////

    const handleBlur = useCallback(
        (event) => {
            dispatch(
                surveyDebugChanged({
                    property: 'currentItemId',
                    value: undefined,
                })
            );
            if (onBlur) onBlur(event);
        },
        [onBlur, dispatch]
    );

    const handleFocus = useCallback(
        (e) => {
            dispatch(
                surveyDebugChanged({ property: 'currentItemId', value: itemId })
            );
        },
        [dispatch, itemId]
    );

    const handleChange = useCallback(
        (value) => {
            if (onChange) onChange(value);

            dispatch(
                pageItemTextChanged({
                    pageId,
                    itemId,
                    lang: surveyLang,
                    value,
                })
            );
        },
        [surveyLang, pageId, itemId, onChange, dispatch]
    );

    const handleKeyChange = useCallback(
        (value) => {
            const isLegalValue = isKeyLegal(value);

            if (isLegalValue) {
                dispatch(
                    pageItemKeyChanged({
                        pageId,
                        itemId,
                        value,
                    })
                );
            } else {
                dispatch(
                    messageAdded({
                        type: 'ILLEGAL_KEY_CHARACTER',
                        args: '',
                    })
                );
            }
        },
        [pageId, itemId, dispatch]
    );

    const handleDelete = useCallback(() => {
        const refs = getRefsInLogic();
        if (refs.length > 0) {
            const alert = deletionAlert(lang);
            alert.message = alert.message.replace('{item}', texts.item[lang]);
            alert.actions[0].callback = () => {
                refs.forEach((ref) => {
                    if (ref.cleanupCB) {
                        ref.cleanupCB();
                    }
                });
                dispatch(pageItemDeleted({ pageId, itemId }));
            };

            dispatch(alertAdded(alert));
        } else {
            dispatch(pageItemDeleted({ pageId, itemId }));
        }
    }, [getRefsInLogic, pageId, itemId, lang, dispatch]);

    const handleReturn = useCallback(
        (e, editorContent) => {
            if (item.text) {
                // On Return key, if item text is empty and it has no logic refs, delete item.
                let isEmpty = true;
                Object.values(item.text).forEach((v) => {
                    if (editorContent) {
                        // Rich Text
                        const content = editorContent.getCurrentContent();
                        isEmpty = !content.hasText();
                    } else if (v) {
                        isEmpty = false;
                    }
                });

                if (isEmpty && getRefsInLogic().length === 0) {
                    handleDelete();
                }
            }
        },
        [item, getRefsInLogic, handleDelete]
    );

    /////////////
    // RENDER ///
    /////////////

    const warning = useMemo(() => {
        if (item.warning) {
            return (
                <IconButton
                    name="warning"
                    theme="Plain"
                    colorSet="Grayscale"
                    size="s"
                    tabIndex="-1"
                    tooltip={item.warning[lang]}
                />
            );
        } else {
            return <div style={{ width: 28 }} />;
        }
    }, [item, lang]);

    return (
        <>
            {isRichText ? (
                <RichTextInput
                    key={'item_' + surveyLang}
                    className={styles.itemText}
                    textAlignment={textDirection(lang).align}
                    value={item.text[surveyLang] || ''}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onReturn={handleReturn}
                    onKeyDown={onKeyDown}
                />
            ) : (
                <Editable
                    className={styles.itemText}
                    value={item.text[surveyLang] || ''}
                    style={{
                        direction: textDirection(surveyLang).direction,
                        textAlign: textDirection(lang).align,
                    }}
                    onChange={handleChange}
                    onReturn={handleReturn}
                    onKeyDown={onKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            )}

            <Editable
                className={styles.itemKey}
                value={item.key}
                style={{
                    color: item.warning
                        ? 'var( --color-error-medium)'
                        : 'var( --color-type-medium-emphasis )',
                }}
                onChange={handleKeyChange}
                placeholder={texts.itemNoKey[lang]}
            />

            <PageItemNumericValue item={item} pageId={pageId} />

            {warning}

            <HidingOptions
                item={item}
                pageId={pageId}
                pageData={pageData}
                lang={lang}
                isHovered={isHovered}
                onDone={() => {
                    onHover(false);
                }}
            />

            {
                //Don't allow goto navigation on Scale pages
                pageData.type !== 'SCALE' && (
                    <NavigationOptions
                        item={item}
                        pageId={pageId}
                        lang={lang}
                        isHovered={isHovered}
                        onDone={() => {
                            onHover(false);
                        }}
                    />
                )
            }

            <IconButton
                name="delete"
                tooltip={texts.deleteItemTooltip[lang]}
                theme="Plain"
                colorSet="Grayscale"
                size="s"
                bgStyle={{
                    opacity: isHovered ? 1 : 0.1,
                    marginTop: 2,
                    marginInlineEnd: 6,
                }}
                tabIndex="-1"
                onClick={handleDelete}
            />
        </>
    );
};
