import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './LogicItemEditor.module.css';
import IconButton from 'components/IconButton';
import ConditionBuilder from './condition_comps/ConditionBuilder';
import LogicActions from './actions_comps/LogicActions';

import { useDispatch, useSelector } from 'react-redux';
import { displayStateChanged, logicItemChanged, logicItemConditionChanged, selectPageProperty, setCurrentLogicItem } from '../surveyEditorSlice';
import { pageTypesConfig } from 'features/survey_editor/surveyEditorConfiguration';
import { surveyEditorTexts } from 'utils/appTexts'
import { selectUiLanguage } from 'app/preferencesSlice';
import { splitViewModes } from 'features/survey_editor/SurveyEditorMain';
import { Editable } from 'components/Editable';


const texts = surveyEditorTexts.properties.logic.itemEditor;

export default function LogicItemEditor() {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const currentLogicItem = useSelector( state => state.surveyEditor.present.display.currentLogicItem );
    const logicItemData = useSelector( state => state.surveyEditor.present.survey.logic.logicItemsData[ currentLogicItem ] );
    const currentPage = useSelector( state => state.surveyEditor.present.display.currentPage );
    const pageType = useSelector( state => selectPageProperty( state, currentPage, 'type' ) );

    const alwaysDo = useMemo( () => logicItemData && logicItemData.condition.isAlways, [logicItemData] );

    ///////////
    // UTILS //
    ///////////

    const closeItem = useCallback( () => {

        dispatch( setCurrentLogicItem( null ) );
        dispatch( displayStateChanged({ property: "splitViewMode", value: splitViewModes.NORMAL }))

    },[dispatch])

    useEffect( () => {

        if( currentPage && logicItemData && (currentPage !== logicItemData.pageId) ){

            closeItem()
        }

    }, [currentPage, logicItemData, closeItem])

    const handleConditionToggle = useCallback( (type) => {

        if( type === 'ALWAYS' && !alwaysDo ) {

            dispatch( logicItemConditionChanged( {id: currentLogicItem, prop: 'isAlways', value: true } ))
        
        } 
        
        if( type === 'IF' && alwaysDo ) {

            dispatch( logicItemConditionChanged( {id: currentLogicItem, prop: 'isAlways', value: false } ))
            
        }

    }, [alwaysDo, currentLogicItem, dispatch])

    const handleTitleChange = useCallback( (value) => {
        
        dispatch( logicItemChanged( {id: currentLogicItem, prop: 'title', value} ));

    },[currentLogicItem, dispatch] )

    ////////////
    // RENDER //
    ////////////
    const bgColor = useMemo( () => {
        if( !pageType ) return 'var(--color-background-mild)';
        return pageTypesConfig[pageType].color + '1a';
    }, [pageType])

    const getHeadlinStyle = useCallback( (isActive) => {
        if( isActive ) {
            return {
                backgroundColor: 'var( --color-type-high-emphasis )',
                color: 'var( --color-onDark )'
            }
        } else {
            return {
                backgroundColor: 'transparent',
                color: 'var( --color-type-medium-emphasis )',
                cursor: 'pointer'
            }
        }
    }, [])

    if( !logicItemData ) return null;

    return (
        <div 
            className={ styles.mainCont }
            style={{
                background: `linear-gradient(${bgColor}, var(--color-background-mild))`
            }}
            dir='ltr'
        >
            <div className={ styles.topBar }>
                <Editable
                    value={ logicItemData.title }
                    placeholder={ texts.titlePlaceholder[ lang ] }
                    className={ styles.itemTitle }
                    onChange={ handleTitleChange }
                />
                <IconButton
                    name='x'
                    tooltip={ texts.closeLogicItem[ lang ] }
                    theme='Plain'
                    colorSet='Grayscale'
                    onClick={ closeItem }
                        
                /> 
            </div>
            <div className={ styles.conditionsCont } >
                <div className={ styles.header }>
                    <h2 
                        className={ styles.headline}
                        style={ getHeadlinStyle( alwaysDo ) }
                        onClick={ () => handleConditionToggle( 'ALWAYS') }
                    >
                        {texts.always[ 'en' ]}
                    </h2>
                    <div className={ styles.slash }/>
                    <h2 
                        className={ styles.headline}
                        style={ getHeadlinStyle( !alwaysDo ) }
                        onClick={ () => handleConditionToggle( 'IF') }
                    >
                        {texts.if[ 'en' ]}
                    </h2>
                </div>
                {
                    !alwaysDo && 
                    <ConditionBuilder itemId={ currentLogicItem } condition={ logicItemData.condition }/>
                }
            </div>
            <div className={ styles.actionsCont }>
                <div className={ styles.header }>
                    <h2 
                        className={ styles.headline}
                        style={ getHeadlinStyle( true ) }
                    >
                        {texts.do[ 'en' ]}
                    </h2>
                </div>
                <LogicActions itemId={ currentLogicItem } actions={ logicItemData.actions }/>
            </div>
        </div>
    )
}

