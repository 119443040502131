import React, { useCallback, useMemo } from 'react';
import propStyles from './PageModulesStyles.module.css';
import styles from './NumberPageModule.module.css';
import TextInput from 'components/TextInput';
import DropDown from 'components/DropDown';
import RichTextInput from 'components/rich_text/RichTextInput';
import { useSelector, useDispatch } from 'react-redux';
import { selectPageProperty, pageContentChanged, pageTextContentChanged } from 'features/survey_editor/surveyEditorSlice';
import { selectUiLanguage } from 'app/preferencesSlice';
import { textDirection } from 'utils/stylingTools';

import { surveyEditorTexts } from 'utils/appTexts';

const texts = surveyEditorTexts.content.pageModules.number;

export const inputTypes = {
    SLIDER: "slider",
    KEYBOARD: "keyboard"
}

export default function NumberPageModule( {pageId, pageHovered} ) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );


    const subtitle = useSelector( state => selectPageProperty( state, pageId, "subtitle" ) );
    const inputType = useSelector( state => selectPageProperty( state, pageId, "inputType" ) );
    const pageSettings = useSelector( state => selectPageProperty( state, pageId, "settings" ) );

    const { showSubtitle } = pageSettings.general;

    const inputTypeOptions = useMemo( () => {
        return Object.values( inputTypes ).map( type => ({ key: type, label: texts.inputTypes[ type ][ lang ] }) )
    }, [lang])

    const handleChange = useCallback( ( prop, value ) => {

        dispatch( pageContentChanged( {id: pageId, prop, value}) );

    }, [pageId, dispatch] )

    const handleTextChange = useCallback( ( prop, value ) => {

        dispatch( pageTextContentChanged( {id: pageId, prop, value, lang: surveyLang }) );

    }, [pageId, surveyLang, dispatch] )

    return (
        <>
            { showSubtitle &&            
                <RichTextInput
                    key={ 'subtitle_' + surveyLang }
                    className = { propStyles.subtitle }
                    value = { subtitle[ surveyLang ] || '' }
                    placeholder = { texts.subtitlePlaceholder[ lang ] }
                    textAlignment={ textDirection( lang ).align }
                    onChange = { value => handleTextChange( 'subtitle', value ) }
                />
            }
            <div className={ styles.mainInputsCont }>
                <DropDown
                    label={ texts.inputTypeLabel[ lang ] }
                    className={ styles.inputField }
                    options={ inputTypeOptions }
                    value={ inputType }
                    theme="Outlined"
                    colorSet="Grayscale"
                    contStyle={{width: 200}}
                    onChange={ op => handleChange( "inputType", op.key ) }
                />
                {
                    inputType === inputTypes.SLIDER &&
                    <SliderOptions pageId={ pageId } lang={ lang } pageSettings={ pageSettings } onChange={ handleChange } onTextChange={ handleTextChange }/>
                }
                {
                    inputType === inputTypes.KEYBOARD &&
                    <KeyboardOptions pageId={ pageId } lang={ lang } onChange={ handleChange } onTextChange={ handleTextChange }/>
                }
            </div>
        </>
    )
}

const SliderOptions = ({pageId, lang, pageSettings, onChange, onTextChange}) => {
    
    const min = useSelector( state => selectPageProperty( state, pageId, "min" ) );
    const max = useSelector( state => selectPageProperty( state, pageId, "max" ) );
    const minLabel = useSelector( state => selectPageProperty( state, pageId, "minLabel" ) );
    const maxLabel = useSelector( state => selectPageProperty( state, pageId, "maxLabel" ) );
    const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );


    return (
        <>
            <TextInput 
                type="number"
                className={ styles.inputField }
                label={ texts.min[ lang ] }
                value={ min || "" }
                contStyle={{maxWidth: 120}}
                theme="Outlined"    
                onChange={ e => onChange( "min", e.target.value ) }
            />
            <TextInput 
                type="number"
                className={ styles.inputField }
                label={ texts.max[ lang ] }
                value={ max || "" }
                contStyle={{maxWidth: 120}}
                theme="Outlined"    
                onChange={ e => onChange( "max", e.target.value ) }
            />
            {/* <div className={ styles.gap }/>
            <TextInput 
                type="number"
                label={ texts.initial[ lang ] }
                value={ initial }
                theme="Outlined"    
                onChange={ e => onChange( "initial", e.target.value ) }
            /> */}
            { pageSettings.general.useCustomRangeLabels &&
                <>
                    <div className={ styles.gap }/>
                    <TextInput 
                        className={ styles.inputField }
                        label={ texts.minLabel[ lang ] }
                        value={ minLabel[ surveyLang ] || '' }
                        contStyle={{maxWidth: 120}}
                        theme="Outlined"    
                        onChange={ e => onTextChange( "minLabel", e.target.value ) }
                    />
                    <TextInput 
                        className={ styles.inputField }
                        label={ texts.maxLabel[ lang ] }
                        value={ maxLabel[ surveyLang ] || '' }
                        contStyle={{maxWidth: 120}}
                        theme="Outlined"    
                        onChange={ e => onTextChange( "maxLabel", e.target.value ) }
                    />
                </>
            }
        </>
    )
}

const KeyboardOptions = ({pageId, lang, onChange, onTextChange}) => {

    const minCharacters = useSelector( state => selectPageProperty( state, pageId, "minCharacters" ) );
    const placeholder = useSelector( state => selectPageProperty( state, pageId, "placeholder" ) );
    const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );


    return (
        <>
            <TextInput 
                type="number"
                className={ styles.inputField }
                label={ texts.minCharactersLabel[ lang ] }
                value={ minCharacters || "" }
                theme="Outlined"    
                contStyle={{maxWidth: 70}}
                onChange={ e => onChange( "minCharacters", e.target.value ) }
            />  
            <TextInput 
                type="text"
                className={ styles.inputField }
                label={ texts.placeholderLabel[ lang ] }
                value={ placeholder[ surveyLang ] || "" }
                theme="Outlined"
                onChange={ e => onTextChange( 'placeholder', e.target.value ) }
            />  
            {/* @TODO: add placeholder*/}
        </>
    )
}