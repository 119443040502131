import React, { useMemo } from "react";
import styles from "./ReportStoryView.module.css";
import { useSelector } from "react-redux";
import { getNested } from "utils/miscHelpers";
import Modal, { ModalPanel } from "components/poppers/Modal";

const storyAdresses = {
    local: "http://localhost:3008",
    stating:
        "https://horizon-story-client-staging.s3-website.eu-central-1.amazonaws.com",
    production: "https://story.hrzn.app",
};
const storyUrl = storyAdresses.production;

export default function ReportStoryView({ isActive, project, onClose }) {
    const token = useSelector((state) => state.users.mainUser.token);

    const src = useMemo(() => {
        if (project) {
            const surveyId = getNested(project, "survey", "id");
            if (surveyId) {
                const url = `${storyUrl}/${surveyId}?token=${token}`;
                // console.log("story url:", url);
                return url;
            }
        }
    }, [project, token]);

    return (
        <Modal isActive={isActive} bgBlur={"high"} onClose={onClose}>
            <ModalPanel className={styles.modalPanel}>
                <iframe
                    title={"Survey Preview"}
                    className={styles.storyIframe}
                    src={src}
                />
            </ModalPanel>
        </Modal>
    );
}
