import React from 'react';
import styles from './PageStatsRow.module.css';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from '../../app/preferencesSlice';
import Button from '../../components/Button';
import Chip from '../../components/Chip';
import { insightsTexts } from '../../utils/appTexts';
import { useState } from 'react';
import { FormatTime } from '../../utils/stylingTools';

export default function PageStatsRow({

    fieldKey,
    data,
    onRemove

}) {

    const [ itemHovered, setItemHovered ] = useState( false );
    const lang = useSelector( selectUiLanguage );
    
    if( !data ) return <p>No data yet</p>
    
    const { title, subtitle } = data;

    return (

        <div className={ styles.mainContainer } 
            onMouseEnter={ () => {setItemHovered( true )}}
            onMouseLeave={ () => {setItemHovered( false )}}
        >
            <div className={ styles.identifierContainer }>
                <Chip 
                    label = { fieldKey }
                    iconName = 'x' 
                    // onClick = { () => console.log( "chip click " ) }
                    onDelete = { onRemove }
                    bgStyle = {{
                        width: 170,
                        maxWidth: 170
                    }}
                    labelStyle = {{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                />
                <div style={{ flexGrow: 1 }} />
            </div>
            <p className={ styles.questionText }> { title ? title : subtitle } </p>
            <Button 
                label = { insightsTexts.editBtn[ lang ] }
                theme = 'Outlined'
                size = 'm'
                colorSet = 'Grayscale'
                disabled = { true } // Temporary, until implemented
                bgStyle={{ 
                    alignSelf: 'flex-start',  
                    opacity: itemHovered ? 1 : 0,
                }}

            />
            <p className={ styles.time }>{ data.time ? FormatTime( data.time ) : "—" }</p>
            {/* <div className={ styles.dropOff } style={time > 15 ? {backgroundColor: 'var( --color-error-light )', color: 'var( --color-error-medium )'} : {backgroundColor: 'none'}}> 30% (300/1000)</div> */}
        </div>    
        
    );
}
