import React, { useCallback } from 'react';
import styles from './ConditionBuilder.module.css';
import ConditionGroup from './ConditionGroup';

import { useDispatch } from 'react-redux';
import { logicItemConditionChanged } from 'features/survey_editor/surveyEditorSlice';

export default function ConditionBuilder({itemId, condition}) {

    const { rules, args } = condition;

    const dispatch = useDispatch()

    const handleRulesChange = useCallback( (newRules) => {

        dispatch( logicItemConditionChanged( {id: itemId, prop: 'rules', value: newRules} ) );

    }, [dispatch, itemId])

    return (
        <div className={ styles.mainCont}>
            {/* { getItems() } */}

            <ConditionGroup 
                key={ 'main_group'}
                logicItemId={ itemId }
                isMain={ true } 
                args={ args }
                rules={ rules }
                onChange={ handleRulesChange }
            />


        </div>
    )
}