import SocketIO from "socket.io-client";
import { diff_match_patch } from 'utils/diff_match_patch_uncompressed'
var DiffMatchPatch = new diff_match_patch();

let token = null;
let serverUrl = null // 'Api.js' is setting this var.

var currentSocket, currentEntityType, currentEntityId, currentClientKey, lastServerResponse, lastJsonSentToServer;
function GetSocket(clientKey, entityType, entityId) {
    if (currentSocket && (currentEntityType !== entityType || currentEntityId !== entityId || currentClientKey !== clientKey)) {
        currentSocket.disconnect()
        currentSocket = null;
        currentEntityType = null;
        currentEntityId = null;
        currentClientKey = null;
        lastServerResponse = null;
        lastJsonSentToServer = null;
    }

    if (!currentSocket) {
        currentClientKey = clientKey;
        currentEntityType = entityType;
        currentEntityId = entityId;

        currentSocket = SocketIO(serverUrl);
        currentSocket.on('SetupCompleted', data => {
            lastServerResponse = data
        })
        currentSocket.on('DataChangedOnServer', data => {
            if (data.patches && !data.json) {
                const [jsonFromPatches] = DiffMatchPatch.patch_apply(data.patches, lastServerResponse.json)
                data.json = jsonFromPatches
            }
            lastServerResponse = data
        })
        currentSocket.on('disconnect', data => {
            currentSocket = null;
            currentEntityType = null;
            currentEntityId = null;
            currentClientKey = null;
            lastServerResponse = null;
            lastJsonSentToServer = null;
        })

        currentSocket.emit('Setup', { clientKey, entityType, entityId, token })
    }
    return currentSocket
}

export const ResetSocket = () => {
    if (currentSocket) {
        currentSocket.disconnect()
    }
    currentSocket = null;
    currentEntityType = null;
    currentEntityId = null;
    currentClientKey = null;
    lastServerResponse = null;
    lastJsonSentToServer = null;
}

export const SetToken = (userToken) => {
    token = userToken
}

export const SetServerUrl = (url) => {
    serverUrl = url
}

export const FetchSurvey = async (surveyId, clientKey) => {
    GetSocket(clientKey, 'survey', surveyId);
    if (lastServerResponse) {
        const isBeingEditedRightNow = new Date().getTime() - lastServerResponse.updatedAt < 10000
        const lastUpdateByAnotherClient = clientKey !== lastServerResponse.lastEditorClientKey
        const isLocked = isBeingEditedRightNow && lastUpdateByAnotherClient
        return {
            error: null,
            data: {
                status: "OK",
                isLocked: isLocked,
                isConnecting: false,
                survey: {
                    ...lastServerResponse,
                    editorName: lastServerResponse.lastEditorName,
                    isLocked: isLocked,
                }
            },
        }
    } else {
        return {
            error: null,
            data: {
                status: "OK",
                survey: {
                    isLocked: false,
                    isConnecting: true,
                    updatedAt: 0,
                    editorName: "",
                }
            }
        }
    }
}
export const UpdateSurvey = (surveyId, surveyJson, clientKey) => {
    const socket = GetSocket(clientKey, 'survey', surveyId)

    const isBeingEditedRightNow = new Date().getTime() - lastServerResponse.updatedAt < 10000
    // const lastUpdateByAnotherClient = clientKey !== lastServerResponse.lastEditorClientKey
    const lastUpdateByAnotherClient = socket.id !== lastServerResponse.lastEditorClientKey

    if (isBeingEditedRightNow && lastUpdateByAnotherClient) {
        console.log("Warning: we are trying to update while survey is locked! ")
    } else {
        if (!lastJsonSentToServer) {
            socket.emit("ClientChangedJson", { json: surveyJson })
            console.log(`Sending ${surveyJson.length} chars`)
        } else {
            const patches = DiffMatchPatch.patch_make(lastJsonSentToServer, surveyJson)
            socket.emit("ClientChangedJson", { patches: patches })
            console.log(`Sending ${JSON.stringify(patches).length} chars`)
        }
        lastJsonSentToServer = surveyJson
    }

    return {
        error: null,
        data: {
            status: "OK"
        }
    }
}
export const ServerResyncSurvey = (surveyId, clientKey) => {
    const socket = GetSocket(clientKey, 'survey', surveyId)
    socket.emit("ClientAskedToResync", {})
}