import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./Results.module.css";
import { useSelector, useDispatch } from "react-redux";
import { resultsTexts } from "utils/appTexts";
import * as ReportSlice from "app/reportSlice";
import RecordsTable from "./RecordsTable";
import ResultaRecordsSelectionOptions from "./ResultsRecordsSelectionOptions";
import ActivityIndicator from "components/ActivityIndicator";

import Emoji from "components/Emoji";

export default function ResultsRecords({
    lang,
    project,
    isLoading,
    onLoading,
}) {
    const dispatch = useDispatch();
    const recordsData = useSelector((state) => state.report.recordsData);
    const fetchRecordsStatus = useSelector(
        (state) => state.report.fetchRecordsStatus
    );
    const fetchVarsSettingsStatus = useSelector(
        (state) => state.report.virtualVars.fetchVarsSettingsStatus
    );

    const [selectedRows, setSelectedRows] = useState([]);
    // const isLtr = useMemo( () => lang === 'en', [lang]);

    useEffect(() => {
        if (project) {
            if (fetchRecordsStatus === "idle") {
                onLoading(true);
                dispatch(ReportSlice.fetchRecords(project.sourceName));
            }

            if (fetchRecordsStatus === "loading") {
                onLoading(true);
            }

            if (fetchRecordsStatus === "succeeded") {
                // console.log(recordsData);
                if (recordsData) {
                    onLoading(false);
                }
            }
        } else {
            onLoading(true);
        }
    }, [recordsData, fetchRecordsStatus, dispatch, project, onLoading]);

    useEffect(() => {
        if (project && project.bucket && fetchVarsSettingsStatus === "idle") {
            dispatch(ReportSlice.fetchVirtualVarsSettings(project.bucket.id));
        }
    }, [fetchVarsSettingsStatus, project, dispatch]);

    const countInfo = useMemo(() => {
        let text = "...";
        if (recordsData) {
            const samples = recordsData.sampleRecordsCount;
            const total = recordsData.totalRecordsCount;
            if (samples < total) {
                text = resultsTexts.sampledRecordsCountInfo[lang]
                    .replace("{sample}", samples)
                    .replace("{total}", total);
            } else {
                text = resultsTexts.totalRecordsCountInfo[lang].replace(
                    "{total}",
                    total
                );
            }
        }
        return text;
    }, [recordsData, lang]);

    const handleRowsSelection = useCallback((id) => {
        setSelectedRows((state) => {
            const newSelection = [...state];
            const inx = state.findIndex((_id) => _id === id);
            if (inx > -1) {
                newSelection.splice(inx, 1);
            } else {
                newSelection.push(id);
            }
            return newSelection;
        });
    }, []);

    const handleSelectAllRows = useCallback(() => {
        const total = recordsData?.dataRows?.length;
        if (selectedRows.length === total) {
            setSelectedRows([]);
        } else {
            const rIdInx = recordsData?.keysRow?.findIndex(
                (k) => k === "___rId"
            );
            if (rIdInx > -1) {
                setSelectedRows(recordsData?.dataRows?.map((r) => r[rIdInx]));
            }
        }
    }, [selectedRows, recordsData]);

    if (isLoading) {
        // should load a new report.
        return (
            <div className={styles.recordsTableArea}>
                <div id={styles.reloadingCont}>
                    <ActivityIndicator size={24} />
                </div>
            </div>
        );
    }

    if (recordsData && recordsData.totalRecordsCount === 0) {
        return (
            <div className={styles.recordsTableArea}>
                <div id={styles.reloadingCont}>
                    <h2 className={styles.noAnalysesYet}>
                        {resultsTexts.noResults[lang] + " "}
                        <Emoji symbol="🤷‍♀️" />
                    </h2>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.recordsTableArea}>
            <ResultaRecordsSelectionOptions
                project={project}
                selectedRows={selectedRows}
                onDeselect={() => setSelectedRows([])}
            />
            <p id={styles.recordsNumber} className={styles.responseNumber}>
                {countInfo}
            </p>
            <RecordsTable
                project={project}
                showSelection={true}
                selectedRows={selectedRows}
                onSelectRow={handleRowsSelection}
                onSelectAll={handleSelectAllRows}
            />
        </div>
    );
}
