import React, { useMemo } from 'react';
import propsStyles from '../../SurveyEditorProperties.module.css';
import Checkbox from 'components/Checkbox';
import TermsLinksEdit from './TermsLinksEdit';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';

import { surveyEditorTexts } from 'utils/appTexts';

const texts = surveyEditorTexts.properties.pageSettings.settingsModules.opening;

export default function OpeningSettingsModule({ settings, updateSettings }) {
    const lang = useSelector(selectUiLanguage);

    const generalSettings = useMemo(() => settings?.general, [settings]);
    const { showSubtitle, showTermsLinks } = useMemo(
        () => (generalSettings ? generalSettings : {}),
        [generalSettings]
    );

    if (!settings) return null;

    return (
        <>
            <Checkbox
                size="s"
                className={propsStyles.checkbox}
                label={texts.showIntroText[lang]}
                isChecked={showSubtitle}
                onChange={(isChecked) =>
                    updateSettings({ prop: 'showSubtitle', value: isChecked })
                }
            />
            <Checkbox
                size="s"
                className={propsStyles.checkbox}
                label={texts.terms.showTermsLinks[lang]}
                isChecked={showTermsLinks}
                onChange={(isChecked) =>
                    updateSettings({ prop: 'showTermsLinks', value: isChecked })
                }
            />
            {showTermsLinks && <TermsLinksEdit />}
        </>
    );
}
