import React from "react";
import IconButton from "components/IconButton";
import { useSelector } from "react-redux";
import { selectUiLanguage } from "app/preferencesSlice";
import { componentsTexts } from "utils/appTexts";

const texts = componentsTexts.collapseButton;

/**
 *
 * @param {{
 * isCollapsed: boolean,
 * display: boolean,
 * onToggle: function,
 * style: ElementCSSInlineStyle
 * }} params
 */
export default function CollapseButton({
    isCollapsed,
    display = true,
    onToggle,
    style,
}) {
    const lang = useSelector(selectUiLanguage);

    return (
        <IconButton
            key="collapseButton"
            name={
                isCollapsed
                    ? lang === "he"
                        ? "chevron_left_arrow"
                        : "chevron_right_arrow"
                    : "chevron_down_arrow"
            }
            theme="Plain"
            colorSet="Grayscale"
            bgStyle={{ minWidth: 24, minHeight: 24, ...style }}
            iconStyle={{ width: 18, height: 18, opacity: display ? 1 : 0.3 }}
            tooltip={texts[isCollapsed ? "expand" : "collapse"][lang]}
            onClick={() => onToggle(!isCollapsed)}
            tabIndex={-1}
        />
    );
}
