import React, { useCallback, useRef, useState } from 'react';
import styles from './PageLogicPhase.module.css';
import Icon from 'components/Icon';
import { Editable } from 'components/Editable';
import { surveyEditorTexts } from 'utils/appTexts';

const texts = surveyEditorTexts.properties.logic;

export default function CreateNewLogicItem ({lang, onCreate}) {

    const titleInputRef = useRef();
    const [title, setTitle] = useState( '' );

    const handleTitleChange = useCallback( (value) => {
        setTitle( value );
    },[] )

    const handleTitleBlur = useCallback( () => {

        if( title ) {
            onCreate( title ); 
            setTitle( '' );
        }

    }, [title, onCreate] )


    return (
        <div
            className={ styles.createItemCont }
            onClick={ () => titleInputRef.current.focus() }
        >
            <Icon
                name='plus'
                size={18}
                style={{marginInlineEnd: 12}}
                color='--color-type-low-emphasis'
            />
            <Editable
                ref={ titleInputRef }
                value={ title }
                placeholder={ texts.createLogicItem[ lang ] }
                className={ styles.itemTitleInput }
                onChange={ handleTitleChange }
                onBlur={ handleTitleBlur }
            />
        </div>
    )
}