import React, { useCallback } from 'react';
import Modal, { ModalPanel } from './Modal';
import styles from './Dialog.module.css';
import Button from '../Button';

/**
 * 
 * @param {{
 * isActive,
 * onClose,
 * style
 * }} params 
 */
export default function Dialog ({isActive, onClose, style, children}) {

    return (
        <Modal isActive={ isActive } onClose={ onClose } >
            <ModalPanel isActive={ isActive } style={ style } >
                { children }
            </ModalPanel>
        </Modal>
    )
}

/**
 * 
 * @param {{
 * isActive: boolean 
 * onClose: function 
 * style: object 
 * title: string 
 * message: string //Can be a simple string or a more complex JSX such as: <>some line <br/> second line</>
 * actions: array of objects //[{label,callback,theme}]
 * }} params 
 */
export function AlertDialog ({
    isActive, 
    onClose, 
    style = {minWidth: 300}, 
    title,
    message,
    actions,
    children,

}) {

    const getButton = useCallback( (action, inx ) => {

        return (
            <Button
                key={ 'actionBtn'+inx}
                label={ action.label }
                theme={ action.theme ? action.theme : "Plain" }
                bgStyle={ {marginRight: 2, marginLeft: 2} }
                onClick={ () => {
                    if( onClose ) {
                        onClose() 
                    } else { 
                        console.warn( "AlertDialog is missing 'onClose' callback." )
                    }
                    
                    if( action.callback ) action.callback();
                }}
            />
        )
    },[onClose]);

    return (
        <Modal isActive={ isActive } onClose={ onClose } bgBlur="medium">
            <ModalPanel isActive={ isActive } style={ style } >
                <h2 className={ styles.title }> { title } </h2>
                <p className={ styles.message }> { message } </p>
                {children && <div id={ styles.additionalContent }> { children } </div> }
                { actions && 
                    <div id={ styles.actionsCont}>
                        { actions.map( getButton ) }
                    </div>
                }
            </ModalPanel>
        </Modal>
    )
}