import React, { useCallback, useMemo, useState } from 'react';
import styles from './ContinueButtonSettings.module.css';
import { useSelector } from 'react-redux';
import CollapseButton from 'components/CollapseButton';
import { surveyEditorTexts } from 'utils/appTexts';
import Checkbox from 'components/Checkbox';
import TextInput from 'components/TextInput';
import { surveyLanguages } from 'features/survey_editor/surveyEditorConfiguration';
import produce from 'immer';

const texts = surveyEditorTexts.properties.pageSettings.continueButtonSettings;

const ContinueButton = ({ lang, settings, updateSettings }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    return (
        <>
            <div className={styles.collapseTitleCont}>
                <CollapseButton
                    isCollapsed={isCollapsed}
                    onToggle={(c) => setIsCollapsed(c)}
                />
                <label
                    className={styles.collapseTitle}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    {texts.title[lang]}
                </label>
            </div>
            {!isCollapsed && (
                <>
                    <CustomText
                        lang={lang}
                        settings={settings}
                        updateSettings={updateSettings}
                    />
                    <Checkbox
                        label={texts.continueButtonCentered[lang]}
                        isChecked={settings?.general?.continueButtonCentered}
                        onChange={(v) =>
                            updateSettings({
                                prop: 'continueButtonCentered',
                                value: v,
                            })
                        }
                    />
                </>
            )}
        </>
    );
};

const CustomText = ({ lang, settings, updateSettings }) => {
    const supportedLanguages = useSelector(
        (state) =>
            state.surveyEditor.present.survey.settings.general
                .supportedLanguages
    );

    const continueButtonText = useMemo(
        () => settings.general.continueButtonText,
        [settings]
    );

    const handleText = useCallback(
        (e, l) => {
            let newTexts;
            if (continueButtonText) {
                newTexts = produce(continueButtonText, (draft) => {
                    draft[l] = e.target.value;
                });
            } else {
                newTexts = { [l]: e.target.value };
            }

            updateSettings({ prop: 'continueButtonText', value: newTexts });
        },
        [continueButtonText, updateSettings]
    );

    return (
        <div className={styles.customTextCont}>
            <label>{texts.customTextLabel[lang] + ':'}</label>
            {supportedLanguages?.map((l) => (
                <TextInput
                    key={l}
                    label={surveyLanguages[l].name[lang]}
                    className={styles.customTextInput}
                    placeholder="..."
                    value={
                        continueButtonText ? continueButtonText[l] || '' : ''
                    }
                    onChange={(e) => handleText(e, l)}
                />
            ))}
        </div>
    );
};

export default ContinueButton;
