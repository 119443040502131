import React, { useCallback } from 'react';
import styles from './MediaLibrary.module.css';
import Button from 'components/Button';
import { componentsTexts } from 'utils/appTexts';

const texts = componentsTexts.mediaLibrary.topBar;
const buttonsStyle = {
    padding: '6px 12px',
};

const MediaLibraryTopBar = ({
    lang,
    selected,
    showArchive,
    hasArchive,
    onArchive,
    onAction,
}) => {
    return (
        <div className={styles.topBar}>
            <h3 className={styles.libraryTitle}>
                {showArchive ? texts.archive[lang] : texts.libraryTitle[lang]}
            </h3>
            {hasArchive && (
                <Button
                    iconBefore={showArchive ? 'x' : 'archive'}
                    theme="Plain"
                    colorSet={showArchive ? 'Primary' : 'Grayscale'}
                    label={showArchive ? texts.back[lang] : texts.archive[lang]}
                    bgStyle={buttonsStyle}
                    onClick={onArchive}
                />
            )}
            {selected && selected.length > 0 && (
                <MultipleEdit
                    lang={lang}
                    selected={selected}
                    showArchive={showArchive}
                    onAction={onAction}
                />
            )}
        </div>
    );
};

const mulTexts = texts.multipleEdit;

const MultipleEdit = ({ lang, showArchive, selected, onAction }) => {
    const handleAction = useCallback(
        (action) => {
            if (onAction) {
                onAction(selected, action);
            }
        },
        [onAction, selected]
    );

    return (
        <div className={styles.multipleEditBar}>
            <span className={styles.selectedFilesCounter}>
                {' '}
                {mulTexts.selected[lang].replace('{n}', selected.length)}
            </span>
            {showArchive ? (
                <>
                    <Button
                        iconBefore={'restore'}
                        theme="Plain"
                        label={mulTexts.restore[lang]}
                        onClick={() => handleAction('restore')}
                    />
                    <Button
                        iconBefore={'delete'}
                        theme="Plain"
                        label={mulTexts.delete[lang]}
                        onClick={() => handleAction('delete')}
                    />
                </>
            ) : (
                <Button
                    iconBefore={'archive'}
                    theme="Plain"
                    label={mulTexts.archive[lang]}
                    onClick={() => handleAction('archive')}
                />
            )}
        </div>
    );
};

export default MediaLibraryTopBar;
