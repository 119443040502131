import React, { useMemo, useCallback } from 'react';
import surveySettingsStyles from './SurveySettings.module.css';
import { surveyEditorTexts } from 'utils/appTexts';
import MultipleSelect from 'components/MultipleSelect';
import { useSelector, useDispatch } from 'react-redux';
import {
    surveySettingsChanged,
    displayLanguageChanged,
} from '../../surveyEditorSlice';
import { surveyLanguages } from 'features/survey_editor/surveyEditorConfiguration';
import { selectUiLanguage } from 'app/preferencesSlice';

const texts = surveyEditorTexts.properties.surveySettings;

const LanguagesOptions = () => {
    const lang = useSelector(selectUiLanguage);
    const dispatch = useDispatch();
    const supportedLanguages = useSelector(
        (state) =>
            state.surveyEditor.present.survey.settings.general
                .supportedLanguages
    );

    const languages = useMemo(() => {
        const options = [];

        Object.values(surveyLanguages).forEach((l) => {
            options.push({
                key: l.key,
                label: l.name[lang],
                isSelected: supportedLanguages.includes(l.key),
            });
        });

        return options;
    }, [lang, supportedLanguages]);

    const handleLanguagesChange = useCallback(
        (op) => {
            // Make sure there is at least one language supported:
            const numLanguages = languages.filter((x) => x.isSelected);
            if (numLanguages.length < 2 && op.isSelected) return; // @TODO: popup a warning alert

            const newLangs = Array.from(supportedLanguages);
            if (op.isSelected) {
                newLangs.splice(newLangs.indexOf(op.key), 1);

                // Make sure the displayed language is not the one who is being removed:
                const defaultLang = languages.find(
                    (x) => x.key !== op.key && x.isSelected
                ).key;
                dispatch(displayLanguageChanged(defaultLang));
            } else {
                newLangs.push(op.key);
            }

            dispatch(
                surveySettingsChanged({
                    prop: 'supportedLanguages',
                    value: newLangs,
                })
            );
        },
        [languages, supportedLanguages, dispatch]
    );

    return (
        <MultipleSelect
            label={texts.surveyLanguagesLabel[lang]}
            className={surveySettingsStyles.languagesCont}
            theme="Outlined"
            placeholder={texts.surveyLanguagesPlaceHolder[lang]}
            options={languages}
            onChange={handleLanguagesChange}
        />
    );
};

export default LanguagesOptions;
