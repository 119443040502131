import React, { useCallback } from 'react';
import styles from './PageModulesStyles.module.css';
import PageEditableItemsList from './page_module_components/PageEditableItemsList';
import RichTextInput from 'components/rich_text/RichTextInput';
import PageEditableItem from './page_module_components/PageEditableItem';

import { useSelector, useDispatch } from 'react-redux';
import { selectPageProperty, pageTextContentChanged } from 'features/survey_editor/surveyEditorSlice';
import { selectUiLanguage } from 'app/preferencesSlice';
import { textDirection } from 'utils/stylingTools';
import { surveyEditorTexts } from 'utils/appTexts';
import { OPEN_ITEM } from 'features/survey_editor/surveyEditorConfiguration';

const texts = surveyEditorTexts.content.pageModules.multipleChoice;

export default function MultipleChoicePageModule( {pageId, pageHovered} ) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );
    const subtitle = useSelector( state => selectPageProperty( state, pageId, "subtitle" ) );
    const pageSettings = useSelector( state => selectPageProperty( state, pageId, "settings" ) );

    const { showSubtitle, showOpenItem } = pageSettings.general;

    const handleTextChange = useCallback( ( prop, value ) => {

        dispatch( pageTextContentChanged( {id: pageId, prop, value, lang: surveyLang }) );

    }, [pageId, surveyLang, dispatch] )

    return (
        <>
            { showSubtitle &&            
                <RichTextInput
                    key={ 'subtitle_' + surveyLang }
                    className = { styles.subtitle }
                    value = { subtitle[ surveyLang ] || '' }
                    placeholder = { texts.subtitlePlaceholder[ lang ] }
                    textAlignment={ textDirection( lang ).align }
                    onChange = { value => handleTextChange( 'subtitle', value ) }
                />
            }
            <PageEditableItemsList pageId={ pageId } pageHovered={ pageHovered }/>
            { showOpenItem &&
                <> 
                    <hr/>
                    <PageEditableItem
                        key={ OPEN_ITEM }
                        itemId={ OPEN_ITEM }
                        pageId={ pageId }
                        isDraggable={ false }
                        isRichText={ true }
                    />

                </> 
            }
        </>
    )
}