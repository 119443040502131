import React, { useCallback, useMemo, useRef, useState } from 'react';
import styles from './PageEditableItemsList.module.css';
import IconButton from 'components/IconButton';
import Modal from 'components/poppers/Modal';
import PopperPanel from 'components/poppers/PopperPanel';
import GotoPageSelection from 'features/survey_editor/logic/GotoPageSelection';
import Button from 'components/Button';

import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import {
    logicItemAddedToPage,
    logicItemDeleted,
    pageItemPropChanged,
} from 'features/survey_editor/surveyEditorSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import {
    pageLogicPhases,
    actionsTypes,
    argumentsTypes,
    userInputTypes,
    variablesTypes,
} from 'features/survey_editor/logic/LogicConfiguration';

export const ADD_ITEM = 'add_item';

const texts = surveyEditorTexts.content.pageItem.navOptions;

// export default function NavigationOptionsWrapper({item, pageId, lang, isHovered, onDone}) {

//     if( isHovered ) {
//         return(
//             <NavigationOptions
//                 item={ item }
//                 pageId={ pageId }
//                 lang={ lang }
//                 onDone={ onDone }
//             />
//         )
//     }

//     return (
//         <div className={ styles.optionsRefCont }>
//             <IconButton
//                 name='goto'
//                 colorSet='Grayscale'
//                 theme='Plain'
//                 size='s'
//                 bgStyle={{
//                     opacity: isHovered ? 1 : item.navigation ? 0.5 : 0.0
//                 }}
//                 tabIndex='-1'
//             />
//         </div>
//     )
// }

export default function NavigationOptions({
    item,
    pageId,
    lang,
    isHovered,
    onDone,
}) {
    const dispatch = useDispatch();

    const referenceElement = useRef();
    const gotoPageBtnRef = useRef();

    const pagesData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData
    );

    const [navOptionsActive, setNavOptionsActive] = useState(false);
    const [isGotoPageActive, setIsGotoPageActive] = useState(false);

    const tooltip = useMemo(() => {
        if (
            pagesData &&
            item.navigation &&
            item.navigation.targetPage &&
            pagesData[item.navigation.targetPage]
        ) {
            const prefix = lang === 'en' ? '→' : '←';
            return `${prefix}  ${pagesData[item.navigation.targetPage].key}`;
        }

        return texts.navOptionsTooltip[lang];
    }, [item.navigation, pagesData, lang]);

    const handleRemoveNavigation = useCallback(() => {
        dispatch(logicItemDeleted(item.navigation.logicItem));
        dispatch(
            pageItemPropChanged({
                pageId,
                itemId: item.id,
                prop: 'navigation',
                data: null,
            })
        );
    }, [pageId, item, dispatch]);

    const handleRemoveBtn = useCallback(() => {
        handleRemoveNavigation();
        setNavOptionsActive(false);
        onDone();
    }, [handleRemoveNavigation, onDone]);

    const handleGotoPageSelection = useCallback(
        (selected) => {
            if (item.navigation) {
                // Remove previous logic:
                handleRemoveNavigation();
            }

            // Create new logic:
            const pageArgId = uuidv4();
            const itemArgId = uuidv4();
            const actionId = uuidv4();

            const targetPageKey = pagesData[selected].key;

            dispatch(
                logicItemAddedToPage({
                    pageId,
                    phase: pageLogicPhases.ON_PAGE_EXIT,
                    title: `${item.key} → ${targetPageKey}`,
                    condition: {
                        isAlways: false,
                        rules: {
                            and: [
                                {
                                    in: [
                                        { var: pageArgId },
                                        { var: itemArgId },
                                    ],
                                },
                            ],
                        },
                        args: {
                            [pageArgId]: {
                                type: argumentsTypes.USER_INPUT.key,
                                page: pageId,
                                inputType: userInputTypes.ANSWER,
                            },
                            [itemArgId]: {
                                type: argumentsTypes.CONSTANT.key,
                                variableType: variablesTypes.ARRAY,
                                value: [item.id],
                            },
                        },
                    },
                    actions: {
                        order: [actionId],
                        data: {
                            [actionId]: {
                                type: actionsTypes.STOP_AND_GOTO.key,
                                page: selected,
                            },
                        },
                    },

                    isNavigationLogic: true,
                    navigationData: {
                        sourcePage: pageId,
                        sourcePageItem: item.id,
                        targetPage: selected,
                    },
                })
            );

            setIsGotoPageActive(false);
            setNavOptionsActive(false);
            onDone();
        },
        [item, pageId, pagesData, onDone, handleRemoveNavigation, dispatch]
    );

    return (
        <>
            <div className={styles.optionsRefCont} ref={referenceElement}>
                <IconButton
                    name="goto"
                    tooltip={tooltip}
                    colorSet="Grayscale"
                    theme="Plain"
                    size="s"
                    bgStyle={{
                        opacity: isHovered ? 1 : item.navigation ? 0.5 : 0.0,
                    }}
                    tabIndex="-1"
                    onClick={() => setNavOptionsActive(true)}
                />
            </div>
            <Modal
                isActive={navOptionsActive}
                onClose={() => {
                    setNavOptionsActive(false);
                    setIsGotoPageActive(false);
                }}
                bgBlur={'low'}
            >
                <PopperPanel
                    referenceElement={referenceElement.current}
                    panelStyle={{ padding: 0 }}
                >
                    <div
                        className={styles.gotoPageRefCont}
                        ref={gotoPageBtnRef}
                    />
                    <Button
                        label={texts.gotoPage[lang]}
                        theme="Plain"
                        colorSet="Grayscale"
                        bgStyle={{
                            height: 32,
                            width: '100%',
                            justifyContent: 'space-between',
                        }}
                        iconAfter={
                            lang === 'en'
                                ? 'chevron_right_arrow'
                                : 'chevron_left_arrow'
                        }
                        onClick={() => {
                            setIsGotoPageActive(!isGotoPageActive);
                        }}
                    />
                    {item.navigation && (
                        <Button
                            label={texts.removeNavitation[lang]}
                            theme="Plain"
                            colorSet="Grayscale"
                            bgStyle={{
                                height: 32,
                                width: '100%',
                                justifyContent: 'flex-start',
                            }}
                            iconBefore={'x'}
                            onClick={handleRemoveBtn}
                        />
                    )}
                </PopperPanel>
            </Modal>
            <GotoPageSelection
                isActive={isGotoPageActive}
                referenceElement={gotoPageBtnRef.current}
                forbiddenPages={[pageId]}
                onSelect={handleGotoPageSelection}
                onDismiss={() => {
                    setNavOptionsActive(false);
                    setIsGotoPageActive(false);
                    onDone();
                }}
            />
        </>
    );
}
