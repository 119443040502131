import React, { useState, useMemo, useCallback } from 'react';
import styles from  './AnalysisCard.module.css';
import Dialog from 'components/poppers/Dialog';
import DropDown from 'components/DropDown';

import JSONPretty from 'react-json-pretty';
var theme = require('react-json-pretty/dist/1337');


export default function AnalysisResultsDetails ({isActive, onClose, data}) {

    const [currentLogView, setCurrentLogView] = useState( "log" );

    const paramters = useMemo( () => {

        if( data ) {
            return data.paramters;
        }
        return "";

    }, [data] )

    const formattedLog = useCallback( () => {

        let log = data.log;

        if( log ) {

            const regex = /Error.+./sg;
            const matches = log.match(regex);  
            if (matches) {
                matches.forEach(match => {
                    log = log.replace(match, `<p style="color:red;font-weight: bold;">${match}</p>`)
                });
            }
            log = log.replace(/(?:\r\n|\r|\n)/g, '<br>')
        }

        return log;

    }, [data]);    

    return (
        
        <Dialog
            isActive={ isActive }
            onClose={ onClose }
        >                
            <div className={ styles.detailsDialogCont}> 
                <DropDown 
                    theme='Outlined' 
                    className={ styles.chooseOutputDropdown }
                    options={ [
                        {key: "log", label:"Full log"}, 
                        {key: "params", label:"Parameters"}
                    ]}
                    value={ currentLogView }
                    onChange={ (op) => setCurrentLogView( op.key ) }
                />
                {currentLogView === 'log' && <div className={ styles.logBlackCont } dangerouslySetInnerHTML={{__html:  formattedLog() }}/>}
                {currentLogView === 'params' && <JSONPretty className={ styles.logCont } style={{fontSize: 12}} id="paramters" theme={theme} data={paramters}></JSONPretty>}


            </div>

        </Dialog>

    )
}