import React, { useCallback, useMemo, useState } from 'react';
import styles from './BlockSettingsModule.module.css';
import propsStyles from '../../SurveyEditorProperties.module.css';
import Dropdown from 'components/DropDown';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Dialog from 'components/poppers/Dialog';
import Icon from 'components/Icon';
import TextInput, { TextTypes as inputTypes } from 'components/TextInput';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import { selectPageProperty } from 'features/survey_editor/surveyEditorSlice';
import { getRawText } from 'components/rich_text/RichTextInput';

const texts = surveyEditorTexts.properties.pageSettings.settingsModules.block;

export default function BlockSettingsModule({ settings, updateSettings }) {
    const lang = useSelector(selectUiLanguage);
    const currentBlock = useSelector(
        (state) => state.surveyEditor.present.display.currentPage
    );
    const blockPages = useSelector((state) =>
        selectPageProperty(state, currentBlock, 'pages')
    );

    const generalSettings = settings.general;
    const { isRandom, randomDrawCount, randomizationWeights } = generalSettings;

    const [isWeightsDialogActive, setIsWeightsDialogActive] = useState(false);

    const drawCountOptions = useMemo(() => {
        if (!blockPages || blockPages.length === 0) return [];

        const options = [];
        for (let i = 1; i < blockPages.length; i++) {
            options.push({ key: i, label: i });
        }
        options.push({ key: blockPages.length, label: texts.drawAll[lang] });

        return options;
    }, [blockPages, lang]);

    const enableRandomSettings = useMemo(() => {
        return isRandom && blockPages && blockPages.length > 0;
    }, [blockPages, isRandom]);

    ///////////
    // UTILS //
    ///////////

    const handleDrawCount = useCallback(
        ({ key, label }) => {
            updateSettings({ prop: 'randomDrawCount', value: key });
        },
        [updateSettings]
    );

    const handleIsRandom = useCallback(
        (isChecked) => {
            updateSettings({ prop: 'isRandom', value: isChecked });
        },
        [updateSettings]
    );

    ////////////
    // RENDER //
    ////////////

    return (
        <>
            <div className={styles.titleCont}>
                <Icon
                    name="random"
                    size={18}
                    color="--color-type-low-emphasis"
                />
                <h4 className={styles.sectionsLabel}>
                    {texts.randomizationTitle[lang]}
                </h4>
            </div>

            <Checkbox
                size="s"
                className={propsStyles.checkbox}
                label={texts.randomize[lang]}
                isChecked={isRandom}
                onChange={handleIsRandom}
                disabled={false}
            />
            {enableRandomSettings && (
                <>
                    <Dropdown
                        className={propsStyles.dropdown}
                        label={texts.randomDrawCount[lang]}
                        options={drawCountOptions}
                        value={randomDrawCount}
                        onChange={handleDrawCount}
                    />
                    <Button
                        label={texts.setWeights[lang]}
                        size="m"
                        theme="Outlined"
                        colorSet="Grayscale"
                        bgStyle={{ width: '100%' }}
                        onClick={() => setIsWeightsDialogActive(true)}
                    />
                </>
            )}
            <div className={propsStyles.separationLine} />

            <WeightsDialog
                blockPages={blockPages}
                randomizationWeights={randomizationWeights}
                isActive={isWeightsDialogActive}
                onClose={() => setIsWeightsDialogActive(false)}
                updateSettings={updateSettings}
            />
        </>
    );
}

const WeightsDialog = ({
    blockPages,
    isActive,
    onClose,
    randomizationWeights,
    updateSettings,
}) => {
    const lang = useSelector(selectUiLanguage);

    const pagesData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData
    );
    const surveyLang = useSelector(
        (state) => state.surveyEditor.present.display.currentLanguage
    );

    const pages = useMemo(() => {
        return blockPages.map((id) => ({ id, ...pagesData[id] }));
    }, [blockPages, pagesData]);

    const [modifiedWeights, setModifiedWeights] = useState([]); // is used for locking last user changes from auto normalization

    ///////////
    // UTILS //
    ///////////

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleWeightChange = useCallback(
        (value, pageId) => {
            const newWeights = { ...randomizationWeights };

            const clamped = Math.max(0, Math.min(1, value));

            newWeights[pageId] = parseFloat(clamped);

            // Add current page id to the list of modified weights in order to lock it from auto normaliztion.
            const newModifiedWeights = Array.from(modifiedWeights);
            newModifiedWeights.push(pageId);
            if (
                newModifiedWeights.length >
                Object.keys(newWeights).length - 1
            ) {
                newModifiedWeights.shift();
            }

            // Summing locked weights:
            let modifiedSum = 0;
            Object.keys(newWeights).forEach((k) => {
                if (newModifiedWeights.includes(k))
                    modifiedSum += newWeights[k];
            });

            // Summing the other weights:
            let othersSum = 0;
            Object.keys(newWeights).forEach((k) => {
                if (!newModifiedWeights.includes(k)) othersSum += newWeights[k];
            });

            // Normalizing weights:
            Object.keys(newWeights).forEach((k) => {
                if (!newModifiedWeights.includes(k)) {
                    const relativeWeight = newWeights[k] / othersSum;
                    const reminder = 1 - modifiedSum;
                    let weight = reminder * relativeWeight;
                    weight *= 100;
                    weight = Math.round(weight) / 100;
                    weight = Math.max(0.01, weight);
                    newWeights[k] = weight;
                }
            });

            setModifiedWeights(newModifiedWeights);

            updateSettings({ prop: 'randomizationWeights', value: newWeights });
        },
        [updateSettings, randomizationWeights, modifiedWeights]
    );

    const handleRebalance = useCallback(() => {
        const newWeights = {};
        blockPages.forEach((k) => (newWeights[k] = 1 / blockPages.length));
        updateSettings({ prop: 'randomizationWeights', value: newWeights });
    }, [updateSettings, blockPages]);

    const getFormattedWeight = useCallback(
        (pageId) => {
            if (randomizationWeights && !isNaN(randomizationWeights[pageId])) {
                const weight = randomizationWeights[pageId];
                return weight.toFixed(2);
            }

            return 0;
        },
        [randomizationWeights]
    );

    ////////////
    // RENDER //
    ////////////

    return (
        <Dialog isActive={isActive} onClose={handleClose}>
            <div className={styles.weightsDialogTopCont}>
                <h4 className={styles.weightsDialogTitle}>
                    {texts.weightsDialog.title[lang]}
                </h4>
                <div className={styles.gap} />
                <Button
                    label={texts.weightsDialog.rebalanceButton[lang]}
                    theme="Outlined"
                    size="s"
                    onClick={handleRebalance}
                />
            </div>

            {pages && pages.length > 0 && (
                <div>
                    {pages.map((page) => {
                        return (
                            <div
                                key={page.id}
                                className={styles.pageWeightItemCont}
                            >
                                <p style={{ flex: 2, maxWidth: 300 }}>
                                    {page.title &&
                                        getRawText(page.title[surveyLang])}
                                </p>
                                <div className={styles.gap} />
                                <TextInput
                                    type={inputTypes.NUMBER}
                                    className={styles.weightInput}
                                    value={getFormattedWeight(page.id)}
                                    onChange={(event) =>
                                        handleWeightChange(
                                            event.target.value,
                                            page.id
                                        )
                                    }
                                    numberSettings={{
                                        min: 0,
                                        max: 1,
                                        step: 0.1,
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </Dialog>
    );
};
