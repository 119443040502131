import React, { useCallback, useMemo } from 'react';
import styles from './VirtualVarsEditor.module.css';
import Dialog from 'components/poppers/Dialog';
import VirtualVarsSettings from './VirtualVarsSettings';
import VirtualVarsFormula from './VirtualVarsFormula';
import VirtualVarsArgs from './VirtualVarsArgs';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectProjectById } from 'app/horizonDataSlice';
import ActivityIndicator from 'components/ActivityIndicator';
import VirtualVarsMessages from './VirtualVarsMessages';
import { fetchRecords, fetchReport, fetchVirtualVarsSettings, unpackVirtualVarSettings, virtualVarsEditorChanged } from 'app/reportSlice';

export const modes = {
    CREATE: 'create',
    EDIT: 'edit'
}

export const types = {
    NUMERIC: "NUMERIC",
    BOOLEAN: "BOOLEAN",
    CATEGORICAL: "CATEGORICAL",
}

export default function VirtualVarsEditor({isActive, onClose }) {
    
    const { projectId } = useParams();

    const dispatch = useDispatch();
    const project = useSelector(state => selectProjectById(state, projectId));
    const varToEdit = useSelector( state => state.report.virtualVars.editor.varToEdit );
    const updateVarsSettingsStatus = useSelector( state => state.report.virtualVars.updateVarsSettingsStatus );

    const mode = useMemo( () => {

        if( varToEdit ) {
            dispatch( unpackVirtualVarSettings( varToEdit ) );
            return modes.EDIT;
        }

        return modes.CREATE;

    }, [varToEdit,dispatch])

    const handleClose = useCallback( () => {
        
        dispatch( virtualVarsEditorChanged( {prop: 'varToEdit', value: null}));
        onClose();

    }, [onClose,dispatch])

    const handleSubmitted = useCallback( () => {
        
        dispatch( virtualVarsEditorChanged( {prop: 'varToEdit', value: null}));
        dispatch( fetchVirtualVarsSettings( project.bucket.id ) );
        dispatch( fetchReport( project.sourceName ) );
        dispatch(fetchRecords( project.sourceName ) );

    }, [ project, dispatch])

    return (
        <>
            <Dialog 
                style={{
                    backgroundColor: 'var(--color-background-mild)',
                    padding: 0,
                }}
                isActive={ isActive } 
                onClose={ handleClose } 
            >
                <div className={ styles.mainCont }>
                    <VirtualVarsSettings 
                        project={ project } 
                        mode={ mode } 
                        onSubmitted={ handleSubmitted }
                        onCancel={ handleClose }
                    />
                    <VirtualVarsFormula />
                    <VirtualVarsArgs project={ project }/>
                    <Activity isActive={ updateVarsSettingsStatus === 'loading' }/>
                </div>
            
            </Dialog>

            <VirtualVarsMessages/>
        </>
    )
}

const Activity = ({isActive}) => {

    if( !isActive ) return false;

    return (
        <div className={ styles.activityIndicatorCont }>
            <ActivityIndicator size={ 24 }/>
        </div>
    )
}