import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Snackbar, { types as SnackbarTypes } from 'components/poppers/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from "redux-undo";
import { surveyEditorTexts } from 'utils/appTexts';
import { alertRemoved, localChangesMade, messageRemoved } from './surveyEditorSlice';
import { selectUiLanguage } from 'app/preferencesSlice';
import { AlertDialog } from 'components/poppers/Dialog';

const texts = surveyEditorTexts.messages;

export const MessageTypes = {
    ILLEGAL_KEY_CHARACTER: {
        message: ( lang, args ) => texts.illegalKeyCharacter[ lang ],
        type: SnackbarTypes.WARNING,
        timeout: 6,
        action: ( lang, args ) => ({ 
            label: texts.actions.gotIt[ lang ],
            callback: () => {}
        })
        
    },
    UNDO: {
        message: ( lang, args ) => args[ lang ],
        type: SnackbarTypes.PLAIN,
        timeout: 6,
        action: ( lang, args, dispatch ) => ({
            label: texts.actions.undo[ lang ],
            callback: () => {
                dispatch( ActionCreators.undo() );
                dispatch( localChangesMade() )
            }
        })
    }
}

export const AlertTypes = {}


export default function SurveyEditorMessages () {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const message = useSelector( state => state.surveyEditor.present.display.message );
    const alert = useSelector( state => state.surveyEditor.present.display.alert );

    const [alertActive, setAlertActive] = useState( false );

    const config = useMemo( () => {
        return ( message ? MessageTypes[ message.type ] : null );
    }, [message] )

    useEffect( () => {
        if( alert ) {
            setAlertActive( true );
        }
    }, [alert])

    const handleOnCloseMessage = useCallback( () => {
        
        dispatch( messageRemoved() );
        
    },[dispatch])

    const handleOnCloseAlert = useCallback( () => {
        
        dispatch( alertRemoved() );
        setAlertActive( false )
        
    },[dispatch])
    
    return (
        <>
            {
                message &&
                <Snackbar
                    key={ message.id }
                    message={ config.message( lang, message.args )}
                    type={ config.type }
                    timeout={ config.timeout }
                    index={ 0 }
                    onClose={ handleOnCloseMessage }
                    action={ config.action( lang, message.args, dispatch ) }
                />
            }
            <AlertDialog
                isActive={ alertActive }
                onClose={ handleOnCloseAlert }
                title={ alert ? alert.title : "" }
                message={ alert ? alert.message : "" }
                actions={ alert ? alert.actions : null }
            />
        </>
    )
}