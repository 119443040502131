import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styles from './VirtualVarsEditor.module.css';

import TextInput from 'components/TextInput';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import OperatorsToolbar from './OperatorsToolbar';
import { resultsTexts } from 'utils/appTexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { /*modes,*/ types } from './VirtualVarsEditor';
import { 
    virtualVarsEditorChanged,
    virtualVarCategoryChanged, 
    virtualVarCategoryCreated, 
    virtualVarCategoryDeleted, 
    virtualVarChanged,
    messageAdded
} from 'app/reportSlice';
import { isKeyLegal } from 'utils/validation';

const texts = resultsTexts.virtualVarEditor.formula;


export default function VirtualVarsFormula() {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const isLtr = lang === 'en';

    const varType = useSelector( state => state.report.virtualVars.editor.varData.varType );
    const selectedCategory = useSelector( state => state.report.virtualVars.editor.selectedCategory );
    const output = useSelector( state => state.report.virtualVars.editor.output );
    const formula = useSelector( state => {

        if( varType === types.CATEGORICAL ) {
            if( selectedCategory > -1 ) {
                return state.report.virtualVars.editor.varData.categories[ selectedCategory ].formula;
            }
            return ""

        } else {
            return state.report.virtualVars.editor.varData.formula;
        }
    });

    const isCategorialAndNotSelected = useMemo ( () => {
        return varType === types.CATEGORICAL && selectedCategory === -1;
    }, [varType, selectedCategory])

    const textAreaRef = useRef();

    useEffect( () => {

        if ( !formula ) {

            dispatch( virtualVarsEditorChanged( {prop: 'output', value: texts.outputPlaceholder} ) );
            return;
        }

    }, [formula, dispatch])

    const handleFormulaTyping = useCallback( (value) => {

        if( varType === types.CATEGORICAL ) {

            dispatch( virtualVarCategoryChanged( {index: selectedCategory, prop: 'formula', value }) );

        } else {

            dispatch( virtualVarChanged( {prop: 'formula', value} ) );

        }

    }, [varType,selectedCategory, dispatch])

    const handleInsertOperator = useCallback ( (value) => {

        let cursorPosition = formula.length;
        if( textAreaRef.current ) {
            cursorPosition = textAreaRef.current.selectionStart;
        }

        const newFormula = [
            formula.slice( 0, cursorPosition ),
            value,
            formula.slice( cursorPosition )
        ].join('');

        handleFormulaTyping( newFormula );

    }, [formula, textAreaRef, handleFormulaTyping])

    return (

        <div 
            className={ styles.formulaArea }
            style={{
                paddingLeft: isLtr ? 'var( --space-very-large )' : 'var( --space-large )',
                paddingRight: !isLtr ? 'var( --space-very-large )' : 'var( --space-large )',
            }}   
        >
            <h3 className={ styles.editorTitle }>{ texts.title[ lang ] }</h3>

            <div className={ styles.formulaContent }>
                {
                    varType === types.CATEGORICAL &&
                    <CatagoricalOptions lang={ lang }/>
                }

                <div className={ styles.formulaInputCont }>
                    <OperatorsToolbar lang={ lang } onInsert={ handleInsertOperator }/>
                    <pre>
                        <textarea
                            ref={ textAreaRef }
                            className={ styles.formulaInput }
                            placeholder={ isCategorialAndNotSelected ? texts.noCategoryPlaceholder : texts.placeholder }
                            disabled={ isCategorialAndNotSelected }
                            dir='ltr'
                            rows='5'
                            wrap='soft'
                            value={ formula }
                            onChange={ e => handleFormulaTyping( e.target.value) }
                        />
                    </pre>

                </div>
                <span className={ styles.label }>{ texts.outputLabel[ lang ] }</span>
                <pre className={ styles.outputArea }>
                    { output }
                </pre>
            </div>
        </div>

    )
}



const CatagoricalOptions = ({lang}) => {

    const dispatch = useDispatch();
    const categories = useSelector( state => state.report.virtualVars.editor.varData.categories );
    const selected = useSelector( state => state.report.virtualVars.editor.selectedCategory );

    const catetory = useMemo (()=> categories[selected], [categories,selected]);

    const handleChanges = useCallback( (index, prop, value) => {

        dispatch( virtualVarCategoryChanged( {index, prop, value} ) )

    }, [dispatch])

    const handleDelete = useCallback( (index) => {

        dispatch( virtualVarCategoryDeleted( index ) )

    }, [dispatch])

    const handleCreate = useCallback( () => {

        dispatch( virtualVarCategoryCreated() )

    }, [dispatch])


    const handleValueChange = useCallback( (index,value) => {

        const isLegalValue = isKeyLegal( value );

        if( isLegalValue ) {

            handleChanges ( index, 'value', value );

        } else {

            dispatch( messageAdded( {
                type: "ILLEGAL_KEY_CHARACTER",
                args: ""
            }) )

        }


    }, [handleChanges, dispatch])

    return (
        <div style={{marginTop: -24}}>
            <span className={ styles.label }>{ texts.categoriesLabel[ lang ] }</span>
            <div className={ styles.categoriesCont }>

                {
                    categories.map( (c,i) => {
                        return (
                            <CategoryChip
                                key={ c + i }
                                lang={ lang }
                                data={ c }
                                isSelected={ selected === i }
                                onSelect={ () => dispatch( virtualVarsEditorChanged( {prop:'selectedCategory', value: i} ) ) }
                                onDelete={ () => handleDelete( i ) }
                            />
                        )
                    })
                }
                <Button
                    label={ texts.createCategory[ lang ] }
                    theme='Outlined'
                    colorSet='Grayscale'
                    iconAfter='plus'
                    bgStyle={{borderRadius: 32, margin: 2}}
                    onClick={ handleCreate }
                />
            </div>

            {
                catetory &&
                <div className={ styles.categoryPropsCont }>
                    <TextInput
                        label={ texts.valueName[ lang ] }
                        className={ styles.settingsInputField }
                        value={ catetory.value || ''}
                        onChange={ e => handleValueChange( selected, e.target.value ) }
                    />
                    <div style={{width: 'var( --space-small )'}}/>
                    <TextInput
                        label={ texts.valueDescription[ lang ] }
                        className={ styles.settingsInputField }
                        value={ catetory.description || ''}
                        onChange={ e => handleChanges( selected, 'description', e.target.value ) }
                    />
                </div>
            }
        </div>
    )
}


const CategoryChip = ({lang, data, isSelected, onSelect, onDelete}) => {

    // const isLtr = lang === 'en';

    const warning = useMemo( () => {
        if( data.warning ) {
             return (
                 <IconButton
                     name="warning"
                     theme="Plain"
                     colorSet="Grayscale"
                     size="s"
                     tabIndex="-1"
                     tooltip={ data.warning[ lang ] }
                 />
             )
        } else return null;
     }, [data, lang])

    return (
        <>            
        { warning }
        <div className={ styles.categoryChipCont }>
            <Button
                label={ data.value }
                theme={ isSelected ? 'Full' : 'Outlined' }
                colorSet='Grayscale'
                bgStyle={{
                    borderRadius: 32,
                }}
                iconAfter='x'
                iconStyle={{opacity:0}}
                labelStyle={{
                    color: isSelected ? '#ffffff' : 'var( --color-type-high-emphasis )'
                }}
                onClick={ onSelect }
            />
            <IconButton
                name='x'
                theme='Plain'
                colorSet='Grayscale'
                size='s'
                bgStyle={{
                    position: 'absolute',
                    top: 9,
                    right: 'calc( 100% - 32px )',
                    left: 'calc( 100% - 32px )'
                }}
                iconStyle={{
                    fill: isSelected ? '#ffffff' : 'var( --color-type-high-emphasis )'
                }}
                onClick={ onDelete }
            />
        </div>
        </>
    )

}