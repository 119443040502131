import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleAnalysisResult } from '../analysesSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import debounce from 'debounce';

const DEBOUNCE_FETCH_DELAY_MS = 1000;


export default function AnalysisCardSynchronizer({data, onActivity}) {

    const dispatch = useDispatch();
    const fetchPositionsInQueueStatus = useSelector( state => state.analyses.fetchPositionsInQueueStatus );

    const [isFetchingData, setIsFetchingData] = useState( false );

    const debouncedFetch = useMemo( () =>

        debounce( () => {

            if( !isFetchingData ) {
    
                console.log( `fetching full data of ${data.name} analysis` );
                setIsFetchingData( true );

                dispatch( fetchSingleAnalysisResult( data.id ) )
                    .then( unwrapResult )
                    .then( originalPromiseResult => {
                        onActivity( false );
                        setIsFetchingData( false );
                    })
                    .catch(rejectedValueOrSerializedError => {})

            }

            
        }, DEBOUNCE_FETCH_DELAY_MS),

    [data, isFetchingData, onActivity, dispatch]);

    useEffect( () => {

        if( 
            data && 
            data.status !== 'finished' && 
            data.status !== 'error' &&
            fetchPositionsInQueueStatus !== 'loading'
        ) {

            onActivity( true );

        } 

        if( fetchPositionsInQueueStatus === 'succeeded' && data.posInQueue === -1 ) {

            if( data.status !== 'finished' && data.status !== 'error' ) { 

                // The analysis results has finished proccessing but the data is partial.
                // Now we need to fetch the full data:

                onActivity( true );
                debouncedFetch()

            }
        }
        


    }, [data,  fetchPositionsInQueueStatus, debouncedFetch, onActivity])

    useEffect( () => {

        // Make sure there are no scheduled executions when unmounted:

        return () => {
            debouncedFetch.clear();
        }

    }, [debouncedFetch])

    return null;
}