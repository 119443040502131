import React, { useMemo } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";
import Icon from 'components/Icon';

const transitions = {
	normal: { duration: 0.3, ease: "easeOut" },
	loop: { duration: 1, ease: "easeInOut", yoyo: Infinity },
} 

const tickVariants = {
  checked: { pathLength: 1, opacity: 1, transition: transitions.normal },
  isLoading: { pathLength: 0 }
};

const boxVariants = {
  checked: { fill: "rgba(128,128,128,1)", scale: 1.05, stroke: "rgba(128,128,128,0)", transition: transitions.normal },
  isLoading: { fill: "rgba(128,128,128,0)", stroke: "rgba(128,128,128,1)", transition: transitions.loop }
};

export default function CloudSaveIcon ({size = 24, isLoading=false, isChecked=true, noConnection}) {

  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);

  const currentAnimation = useMemo( () => {
    if( isLoading ) {
      return "isLoading"
    }

    if( isChecked ) {
      return "checked"
    }
  }, [isLoading, isChecked])

  if( noConnection ) return (
    <Icon name='no_connection' size={ size } color={ '--color-type-low-emphasis'}/>
  )

  return (
    <motion.svg
      initial={ "checked" }
      animate={ currentAnimation }
      width={ `${size}` }
      height={ `${size}` }
    >
      <motion.path
        d="M23,14.25A4.39,4.39,0,0,0,19.68,10a.77.77,0,0,0,0-.15,4.4,4.4,0,0,0-4.4-4.4,4.36,4.36,0,0,0-1.77.38,5.48,5.48,0,0,0-9.23,4,.77.77,0,0,0,0,.15A4.39,4.39,0,0,0,1,14.25a7.52,7.52,0,0,0,1.73,4.4H21.35A7.81,7.81,0,0,0,23,14.25Z"
        fill="rgba(128,128,128,1)"
        strokeWidth="1"
        stroke="rgba(128,128,128,1)"
        variants={boxVariants}
        // style={{ pathLength }}
      />

      <motion.path
        d="M4.44,11.72l5.19,6.2L19.56,6.08"
        transform="scale(0.5 0.5) translate(12,12)"
        fill="transparent"
        strokeWidth="3"
        stroke="hsl(0, 0%, 100%)"
        variants={tickVariants}
        style={{ pathLength, opacity }}
      />
    </motion.svg>
  );
};
