import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAnalysesResultsPositionInQueue } from './analysesSlice';

import debounce from 'debounce';

const DEBOUNCE_FETCH_DELAY_MS = 1000;


export default function AnalysesContentQueueSynchronizer() {

    const dispatch = useDispatch();

    const analysesResults = useSelector( state => state.analyses.results );
    const fetchPositionsInQueueStatus = useSelector( state => state.analyses.fetchPositionsInQueueStatus );

    const debouncedFetch = useMemo( () =>

        debounce( (idsToFetch) => {
            // console.log( 'fetching queue positions', idsToFetch )
            dispatch( fetchAnalysesResultsPositionInQueue( idsToFetch ) );
            
        }, DEBOUNCE_FETCH_DELAY_MS),

    [dispatch]);

    useEffect( () => {

        if( analysesResults && fetchPositionsInQueueStatus !== 'loading' ) {
            
            const idsToFetch = [];

            analysesResults.forEach( result => {
                if(result.status !== 'finished' && result.status !== 'error' ) {
                    idsToFetch.push( result.id );
                }
            })

            if( idsToFetch.length > 0 ) {
                debouncedFetch( idsToFetch )
            }
        }

    }, [analysesResults, debouncedFetch, fetchPositionsInQueueStatus])

    return null;
}