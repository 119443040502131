import React, { useCallback, useMemo, useRef, useState } from 'react';
import sharedStyles from './PageEditableItemsList.module.css';
import IconButton from 'components/IconButton';
import Button from 'components/Button';
import Modal from 'components/poppers/Modal';
import PopperPanel from 'components/poppers/PopperPanel';
import ConditionRule from 'features/survey_editor/logic/condition_comps/ConditionRule';

import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { surveyEditorTexts } from 'utils/appTexts';
import { pageItemPropChanged } from 'features/survey_editor/surveyEditorSlice';

const texts = surveyEditorTexts.content.pageItem.hidingOptions;
const hidingStates = {
    VISIBLE: 'visible',
    HIDDEN: 'hidden',
    CONDITIONALLY_HIDDEN: 'conditionallyHidden'
}

export default function HidingOptions({item, pageId, lang, isHovered, onDone}) {

    const dispatch = useDispatch();

    const referenceElement = useRef();
    const [isPopperActive, setPopperActive] = useState( false );
    const hidingState = useMemo( () => {
        if( !item.hidingCondition ) return hidingStates.VISIBLE;
        if( item.hidingCondition.isAlways ) return hidingStates.HIDDEN;
        return hidingStates.CONDITIONALLY_HIDDEN;
    }, [item])
    
    const hidingStateBtnStyle = useMemo( () => ({
        height: 32,
        width: '100%',
        justifyContent: 'flex-start'
    }), [])

    const setHidingState = useCallback( (state) => {
        if( state === hidingState ) return;

        let data = { isAlways: false }
        if( state === hidingStates.HIDDEN ) data.isAlways = true;
        if( state === hidingStates.VISIBLE ) data = null;
        if( state === hidingStates.CONDITIONALLY_HIDDEN ) {
            const arg_1_id = uuidv4();
            const arg_2_id = uuidv4();

            data.rule = {
                '==': [ 
                    {'var': arg_1_id}, 
                    {'var': arg_2_id} 
                ]
            };
            data.args = {
                [arg_1_id]: {},
                [arg_2_id]: {},
            }
        }

        dispatch( pageItemPropChanged( {
            pageId, 
            itemId: item.id, 
            prop: 'hidingCondition', 
            data
        } ) );

    }, [dispatch, pageId, item, hidingState ])

    return (
        <>
            <div className={ sharedStyles.optionsRefCont } ref={ referenceElement }>
                <IconButton
                    name='hide'
                    tooltip={ texts.hidingOptionsTooltip[ lang ] }
                    colorSet='Grayscale'
                    theme='Plain'
                    size='s'
                    bgStyle={{
                        opacity: isHovered ? 1 : hidingState !== hidingStates.VISIBLE ? 0.5 : 0.0
                    }}
                    tabIndex='-1'
                    onClick={ () => setPopperActive( true ) }
                />
            </div>
            <Modal
                isActive={ isPopperActive }
                onClose={ () => {
                    setPopperActive( false );
                    onDone();
                } }
                bgBlur={ "low" }
            >
                <PopperPanel 
                    key={ hidingState }
                    referenceElement={ referenceElement.current }
                    // panelStyle={{ padding: 0}}
                >
                    <Button                    
                        label={ texts.stateBtn_visible[ lang ] }
                        colorSet={ hidingState === hidingStates.VISIBLE ? 'Primary' : 'Grayscale'}
                        theme={ 'Plain' }
                        bgStyle={ hidingStateBtnStyle }
                        iconBefore={ hidingState === hidingStates.VISIBLE ? 'vi' : 'blank'}
                        onClick={ () => { 
                            setHidingState( hidingStates.VISIBLE );
                            setPopperActive( false );
                            onDone();
                        } }
                    />
                    <Button                    
                        label={ texts.stateBtn_hidden[ lang ] }
                        colorSet={ hidingState === hidingStates.HIDDEN ? 'Primary' : 'Grayscale'}
                        theme={ 'Plain' }
                        bgStyle={ hidingStateBtnStyle }
                        iconBefore={ hidingState === hidingStates.HIDDEN ? 'vi' : 'blank'}
                        onClick={ () => { 
                            setHidingState( hidingStates.HIDDEN );
                            setPopperActive( false );
                            onDone();
                        } }
                    />
                    <Button                    
                        label={ 
                            hidingState === hidingStates.CONDITIONALLY_HIDDEN ?
                            texts.conditionTitle[ lang ]
                            :
                            texts.stateBtn_conditionallyHidden[ lang ] 
                        }
                        colorSet={ hidingState === hidingStates.CONDITIONALLY_HIDDEN ? 'Primary' : 'Grayscale'}
                        theme={ 'Plain' }
                        bgStyle={ hidingStateBtnStyle }
                        iconBefore={ hidingState === hidingStates.CONDITIONALLY_HIDDEN ? 'vi' : 'blank'}
                        onClick={ () => setHidingState( hidingStates.CONDITIONALLY_HIDDEN ) }
                    />

                    {
                        hidingState === hidingStates.CONDITIONALLY_HIDDEN &&
                        <ConditionalHiding
                            item={ item }
                            pageId={ pageId }
                            hidingState={ hidingState }
                        />
                    }

                </PopperPanel>
            </Modal>
        </>
    )
}

const ConditionalHiding = ({item, pageId}) => {

    const dispatch = useDispatch();

    const handleRuleChange = useCallback( rule => {

        const newData = JSON.parse( JSON.stringify( item.hidingCondition ) );
        newData.rule = rule;

        dispatch( pageItemPropChanged( {
            pageId, 
            itemId: item.id, 
            prop: 'hidingCondition', 
            data: newData
        } ) );

    }, [dispatch, pageId, item])

    const handleArgChange = useCallback( arg => {

        const newData = JSON.parse( JSON.stringify( item.hidingCondition ) );
        newData.args[ arg.id ] = arg;

        dispatch( pageItemPropChanged( {
            pageId, 
            itemId: item.id, 
            prop: 'hidingCondition', 
            data: newData
        } ) );

    }, [dispatch, pageId, item])

    return (
        <ConditionRule
            contStyle={{
                width: 400,
                border: 'none',
                justifyContent: 'center',
                margin: '8px 12px 4px',
                padding: 0
            }}
            showDelete={ false }
            rule={ item.hidingCondition.rule }
            args={ item.hidingCondition.args }
            onChange={ handleRuleChange }
            onArgChange={ handleArgChange }
        />
    )
    
}