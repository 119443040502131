import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './VirtualVarsEditor.module.css';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import DropDown from 'components/DropDown';

import { resultsTexts } from 'utils/appTexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { types, modes } from './VirtualVarsEditor';
import { packVirtualVarsSettings, updateVirtualVars, virtualVarChanged, virtualVarsEditorChanged, virtualVarsEditoReset } from 'app/reportSlice';
import { isKeyLegal } from 'utils/validation';
import { messageAdded } from 'app/reportSlice';

const texts = resultsTexts.virtualVarEditor.settings;


export default function VirtualVarsSettings({project, mode, onSubmitted, onCancel }) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );

    const varType = useSelector( state => state.report.virtualVars.editor.varData.varType );
    const varKey = useSelector( state => state.report.virtualVars.editor.varData.key );
    const keyWarning = useSelector( state => state.report.virtualVars.editor.keyWarning );
    const varTitle = useSelector( state => state.report.virtualVars.editor.varData.title );
    const varSubtitle = useSelector( state => state.report.virtualVars.editor.varData.subtitle );

    const typesOptions = useMemo( () => {

        return Object.keys( types ).map( t => ({key: t, label: t}) );

    }, [])

    const handleChanges = useCallback( (prop, value ) => {

        dispatch( virtualVarChanged( {prop, value} ) );

    }, [dispatch])

    const handleKeyChange = useCallback( value => {

        const isLegalValue = isKeyLegal( value );

        if( isLegalValue ) {

            dispatch( virtualVarChanged( { prop: 'key', value} ) );

        } else {

            dispatch( messageAdded( {
                type: "ILLEGAL_KEY_CHARACTER",
                args: ""
            }) )

        }


    }, [dispatch])

    const handleTypeChange = useCallback( typeKey => {

        if( typeKey !== varType ) {

            handleChanges( 'categories', [] );
            handleChanges( 'formula', '' );
            handleChanges( 'varType', typeKey );

            dispatch( virtualVarsEditorChanged( {prop: 'selectedCategory', value: -1} ) );
        }

    },[handleChanges, varType, dispatch])

    return (

        <div className={ styles.varSettingsArea}>
            <h3 className={ styles.editorTitle }>{ texts.title[ lang ] }</h3>
            <DropDown
                label={ texts.typeInputLabel[ lang ] }
                className={ styles.settingsInputField }
                contStyle={{ marginTop: 'var(--space-large)'}}
                options={ typesOptions }
                value={ varType }
                onChange={ op => handleTypeChange( op.key )}
            />
            <TextInput
                label={ texts.keyInputLabel[ lang ] }
                className={ styles.settingsInputField }
                value={ varKey }
                onChange={ e => handleKeyChange( e.target.value ) }
                warning={ keyWarning ? keyWarning[ lang ] : '' }
            />
            <TextInput
                label={ texts.titleInputLabel[ lang ] }
                className={ styles.settingsInputField }
                value={ varTitle }
                onChange={ e => handleChanges( 'title', e.target.value ) }
            />
            <TextInput
                label={ texts.subtitleInputLabel[ lang ] }
                className={ styles.settingsInputField }
                value={ varSubtitle }
                onChange={ e => handleChanges( 'subtitle', e.target.value ) }
            />
            <Submit 
                project={ project } 
                mode={ mode } 
                lang={ lang } 
                onSubmitted={ onSubmitted } 
                onCancel={ onCancel }
            />
            <Button
                label={ texts.clearBtn[ lang ] }
                theme='Plain'
                bgStyle={{width: '100%'}}
                onClick={ () => dispatch( virtualVarsEditoReset() ) }
            />
        </div>

    )
}

const Submit = ({ project, mode, lang, onSubmitted, onCancel }) => {

    const dispatch = useDispatch();
    const isValid = useSelector( state => state.report.virtualVars.editor.isValid );
    const updateVarsSettingsStatus = useSelector( state => state.report.virtualVars.updateVarsSettingsStatus );
    const [submitted, setSubmitted] = useState( false );

    useEffect( () => {

        if( submitted && updateVarsSettingsStatus === 'succeeded' ) {
            setSubmitted( false );
            onSubmitted();
        }

    }, [submitted, updateVarsSettingsStatus, onSubmitted])

    const handleSubmit = useCallback( () => {

        dispatch( packVirtualVarsSettings() );
        dispatch( updateVirtualVars( project.bucket.id ));
        setSubmitted( true );

    }, [dispatch, project])

    if( mode === modes.EDIT ) return (
        <div className={ styles.submitCont }>
            <Button 
                label={ texts.cancelBtn[ lang ]}
                disabled={ !isValid || !project }
                theme='Outlined'
                colorSet='Grayscale'
                bgStyle={{width:'100%'}}
                onClick={ () => onCancel() }
            />
            <div style={{width:8}}/>
            <Button 
                label={ texts.saveBtn[ lang ]}
                disabled={ !isValid || !project }
                colorSet='Secondary'
                bgStyle={{width:'100%'}}
                onClick={ handleSubmit }
            />
        </div>
    )

    return (
        <div className={ styles.submitCont }>
            <Button 
                label={ texts.createBtn[ lang ]}
                disabled={ !isValid || !project }
                colorSet='Secondary'
                bgStyle={{width:'100%'}}
                onClick={ handleSubmit }
            />
        </div>
    )
}