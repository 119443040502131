import React, { useCallback, useRef, useState } from 'react';
import Button from './Button';
import styles from './FileInput.module.css';
import inputStyles from './InputStyles.module.css';
import { componentsTexts } from 'utils/appTexts';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';

const texts = componentsTexts.fileInput;

/**
 * Choose file from device
 * @param {{
 * label,
 * className,
 * style,
 * accept, {string} // file types. example: 'image/png, impage/jpeg'
 * multiple, {boolean} // whether the user may choose more than one file
 * showFileName, {boolean}
 * buttonSize, {string} // 's' | 'm' | 'l'
 * buttonTheme,
 * buttonBgStyle,
 * textOnButton {string},
 * onSelect {function}
 * }} params
 */
export default function FileInput({
    label,
    className,
    style,
    accept,
    multiple = false,
    showFileName = true,
    buttonSize = 'm',
    buttonTheme = 'Full',
    buttonBgStyle,
    textOnButton,
    onSelect
}) {

    const inputRef= useRef();
    const lang = useSelector( selectUiLanguage );

    const [inputKey,setInputKey] = useState( Math.random() );
    const [fileName, setFileName] = useState( "..." )

    const handleSelect = useCallback( (event) => {

        const files = event.target.files;

        if( Object.keys( files ).length > 0 && onSelect ) {
            onSelect( Object.values( files ) );
        }

        const names = Object.values( files ).map( f => f.name );
        setFileName( names.join(", "))
        setInputKey( Math.random() ); // Force input element to reset and dispose previously uploaded files

    }, [onSelect])

    return (
        <div
            className={ `${className} ${inputStyles.mainCont}` }
            style={ style }
        >
            { 
                label &&
                <label htmlFor={ label } className={ inputStyles.label }>
                    { label }
                </label>
            }
            <div className={ styles.fileSelectionCont }>
                <Button
                    label={ textOnButton || texts.chooseFileButtonLabel[ lang ]}
                    theme={ buttonTheme }
                    iconBefore='upload'
                    size={ buttonSize }
                    bgStyle={ buttonBgStyle }
                    onClick={ () => inputRef.current.click() }
                    
                />
                {
                    showFileName && 
                    <p className={ styles.fileNames }>{ fileName }</p>
                }
            </div>

            <input  
                key={ inputKey }
                ref={ inputRef }
                name={ label }
                type='file'
                style={{
                    display: 'none'
                }}
                className={ styles.fileInput }
                accept={ accept }
                multiple={ multiple }
                onChange={ handleSelect }
            />
        </div>
    )
}