import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styles from './Results.module.css';
import Button from 'components/Button';
import VirtualVarsEditor from './virtual_vars/VirtualVarsEditor';
import ColumnSelector from './ColumnSelector';
import FilterRecords from './FilterRecords';
import ReportStoryView from './ReportStoryView';
import { AlertDialog } from 'components/poppers/Dialog';
import { ExportRecordsToEmail, RequestAccessToProject } from 'api/api';
import { useSelector, useDispatch } from 'react-redux';
import { resultsTexts } from 'utils/appTexts';

export default function TopBarOptions({ lang, project, isLoading, onLoading }) {
    const dispatch = useDispatch();
    const [btnToShow, setBtnToShow] = useState(''); // requestAccess | requestSent | getData
    const recordsData = useSelector((state) => state.report.recordsData);
    const userId = useSelector((state) => state.users.mainUser.id);

    useEffect(() => {
        // console.log({project})
        if (project) {
            var haveAccessToData = false;
            var requestSent = false;
            if (project.fetchers) {
                project.fetchers.forEach((fetcher) => {
                    if (fetcher.id === userId) {
                        haveAccessToData = true;
                    }
                });
            }

            if (!haveAccessToData && project.modifiers) {
                project.modifiers.forEach((modifier) => {
                    if (modifier.id === userId) {
                        haveAccessToData = true;
                    }
                });
            }
            if (!haveAccessToData && project.owners) {
                project.owners.forEach((owner) => {
                    if (owner.id === userId) {
                        haveAccessToData = true;
                    }
                });
            }
            if (!haveAccessToData && project.accessRequests) {
                project.accessRequests.forEach((request) => {
                    if (request.user.id === userId) {
                        requestSent = true;
                    }
                });
            }
            if (haveAccessToData) {
                setBtnToShow('getData');
            } else if (requestSent) {
                setBtnToShow('requestSent');
            } else {
                setBtnToShow('requestAccess');
            }
        }
    }, [isLoading, dispatch, project, userId]);

    return (
        <div className={styles.topOptionBar}>
            <ColumnSelector lang={lang} recordsData={recordsData} />

            <FilterRecords project={project} onApply={() => onLoading(true)} />
            <VirtualVars lang={lang} />

            <div style={{ flexGrow: 1 }} />

            {btnToShow === 'getData' && (
                <GetResultsBtn project={project} lang={lang} />
            )}
            {btnToShow === 'requestAccess' && (
                <AskAccessBtn
                    project={project}
                    lang={lang}
                    onRequestSent={() => setBtnToShow('requestSent')}
                />
            )}
            {btnToShow === 'requestSent' && (
                <RequestSentIndicator lang={lang} />
            )}
            <ReportView project={project} lang={lang} />
        </div>
    );
}

const VirtualVars = ({ lang }) => {
    const varToEdit = useSelector(
        (state) => state.report.virtualVars.editor.varToEdit
    );
    const [isEditorActive, setIsEditorActive] = useState(false);

    useEffect(() => {
        if (varToEdit) setIsEditorActive(true);
    }, [varToEdit]);

    return (
        <>
            <Button
                label={resultsTexts.virtualVarsBtn[lang]}
                theme="Outlined"
                colorSet="Grayscale"
                iconBefore="plus"
                // bgStyle={{margin: '0 6px'}}
                onClick={() => setIsEditorActive(true)}
            />
            <VirtualVarsEditor
                isActive={isEditorActive}
                onClose={() => setIsEditorActive(false)}
            />
        </>
    );
};

const GetResultsBtn = ({ project, lang }) => {
    const filterData = useSelector((state) => state.report.recordsFilterData);

    const [isSending, setIsSending] = useState(false);
    const [dialogActive, setDialogActive] = useState(false);
    const [recordsCount, setRecordsCount] = useState(null);
    const [error, setError] = useState(null);

    const exportRecords = async () => {
        setIsSending(true);

        const response = await ExportRecordsToEmail(
            project.sourceName,
            filterData
        );

        if (response && response.data) {
            setRecordsCount(response.data.records);
        }

        if (!response || response.error) {
            setError(response.error);
        }

        setIsSending(false);

        // @TODO: show error dialog if something went wrong...
    };

    useEffect(() => {
        if (recordsCount) {
            setDialogActive(true);
        }
    }, [recordsCount]);

    function setMessage() {
        if (error) return error;

        let time = (2 * recordsCount) / 1000 / 60; // 2 seconds per 1000 records (divided by 60 to get minutes)
        time = Math.max(time, 1);

        const msgParts = resultsTexts.getDataDialog.message[lang].split('{-}');

        let msg = (
            <>
                {msgParts[0]}
                <b>{recordsCount}</b>
                {msgParts[1]}
                <br />
                {msgParts[2]}
                <b>{time.toFixed(0)}</b>
                {msgParts[3]}
            </>
        );

        return msg;
    }

    function dialogDone() {
        setDialogActive(false);
        setRecordsCount(null);
        setError(null);
    }

    return (
        <>
            <Button
                label={resultsTexts.getDataBtn[lang]}
                theme={'Full'}
                size={'m'}
                iconBefore={'download'}
                colorSet={'Primary'}
                bgStyle={{ margin: 0 }}
                onClick={exportRecords}
                disabled={isSending}
            />
            <AlertDialog
                isActive={dialogActive}
                title={
                    error
                        ? resultsTexts.getDataDialog.errorTitle[lang]
                        : resultsTexts.getDataDialog.title[lang]
                }
                message={setMessage()}
                actions={[{ label: resultsTexts.getDataDialog.action[lang] }]}
                onClose={dialogDone}
            />
        </>
    );
};

const AskAccessBtn = ({ project, lang, onRequestSent }) => {
    const [isSending, setIsSending] = useState(false);
    const [dialogActive, setDialogActive] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(null);
    const [error, setError] = useState(null);

    const askAccess = useCallback(async () => {
        setIsSending(true);

        const response = await RequestAccessToProject(
            project.sourceName,
            'fetcher'
        );

        if (process.env.NODE_ENV === 'development') {
            console.log(response);
        }

        if (response && response.data) {
            setDialogMessage(response.data);
        }

        if (!response || response.error) {
            setError(response.error);
        }

        setIsSending(false);
    }, [project.sourceName]);

    useEffect(() => {
        if (dialogMessage) {
            setDialogActive(true);
        }
    }, [dialogMessage]);

    const dialogMessageContent = useMemo(() => {
        if (error) return error;

        const msg = (
            <>
                {resultsTexts.askAccessDialog.message[lang]}
                <br />
                {dialogMessage?.owners?.map((owner) => (
                    <span key={owner.email}>
                        <span>{`${owner.name} - ${owner.email}`}</span>
                        <br />
                    </span>
                ))}
            </>
        );

        return msg;
    }, [error, dialogMessage, lang]);

    const dialogDone = useCallback(() => {
        setDialogActive(false);
        setDialogMessage(null);
        setError(null);
        onRequestSent();
    }, [onRequestSent]);

    return (
        <>
            <Button
                label={resultsTexts.askAccessBtn[lang]}
                theme={'Outlined'}
                size={'m'}
                iconBefore={'locked'}
                colorSet={'Primary'}
                bgStyle={{ margin: 0 }}
                onClick={askAccess}
                disabled={isSending}
            />
            <AlertDialog
                isActive={dialogActive}
                title={
                    error
                        ? resultsTexts.askAccessDialog.errorTitle[lang]
                        : resultsTexts.askAccessDialog.title[lang]
                }
                message={dialogMessageContent}
                actions={[{ label: resultsTexts.askAccessDialog.action[lang] }]}
                onClose={dialogDone}
            />
        </>
    );
};

const RequestSentIndicator = ({ lang }) => {
    return (
        <>
            <Button
                label={resultsTexts.accessRequestSent[lang]}
                theme={'Outlined'}
                iconBefore={'vi'}
                size={'m'}
                colorSet={'Primary'}
                bgStyle={{ margin: 0 }}
                disabled={true}
            />
        </>
    );
};

const ReportView = ({ project, lang }) => {
    const [isActive, setIsActive] = useState(false);

    if (!project || !project.survey) return null;

    return (
        <>
            <Button
                label={resultsTexts.viewReportyBtn[lang]}
                theme={'Full'}
                size={'m'}
                iconBefore={'chart'}
                colorSet={'Primary'}
                bgStyle={{ marginInlineStart: 8 }}
                onClick={() => setIsActive(true)}
            />
            <ReportStoryView
                isActive={isActive}
                onClose={() => setIsActive(false)}
                project={project}
                lang={lang}
            />
        </>
    );
};
