import { useDispatch } from 'react-redux';
import { ActionCreators } from "redux-undo";
import { useKeyPress } from 'utils/hooks';

/**
 * 
 * @param {{
 * onDone: function //
 * disableUndo: boolean //
 * }} params 
 */
export default function UndoRedo({onDone, disableUndo}) {

    const dispatch = useDispatch();

    useKeyPress( 'z', ( event ) => {

        if( event.metaKey || event.ctrlKey ) {

            event.preventDefault();

            if( event.shiftKey ) {
                
                dispatch( ActionCreators.redo() );
                // console.log( "REDO")

            } else if( !disableUndo ) {

                dispatch( ActionCreators.undo() );
                // console.log( "UNDO" );
            }

            if( onDone ) onDone();
        }
    })

    return null;
}