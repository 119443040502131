import React, {} from 'react';
import styles from 'features/survey_editor/logic/LogicStyles.module.css';

import VariablesList from 'features/survey_editor/logic/VariablesList';
import PageLogicItemsList from 'features/survey_editor/logic/PageLogicItemsList';
import LogicItemEditor from 'features/survey_editor/logic/LogicItemEditor';
import { useSelector } from 'react-redux';

export default function LogicProperties() {

    const currentLogicItem = useSelector( state => state.surveyEditor.present.display.currentLogicItem );

    return (
        <div className={ styles.mainCont }>

            <div className={ styles.sideCol}>
                <PageLogicItemsList/>
                <VariablesList/>
            </div>

            {
                currentLogicItem && 
                <div className={ styles.itemEditorCont }>
                    <LogicItemEditor/>
                </div>
            }

        </div>
    )
}

