import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './NewProject.module.css';
import nstyles from './CreateProjectRow.module.css';
import Dialog from 'components/poppers/Dialog';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import MultipleSelect, { SelectionItem } from 'components/MultipleSelect';
import DropDown from 'components/DropDown';
import EmojiPicker from 'components/EmojiPicker';
import Snackbar, { types as SnackbarTypes } from 'components/poppers/Snackbar';
import ActivityIndicator from 'components/ActivityIndicator';
import { isKeyLegal } from 'utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { homeTexts } from 'utils/appTexts';
import { CheckSourceNameAvailability } from 'api/api';
import { setHomePageSelectedTemplateId, selectCurrentTeam, createNewProject, fetchProjectById, selectAllTeamProjects, selectCurrentTeamLabels, fetchSomeTeamProjects, setProjectToCopyId } from 'app/horizonDataSlice';
import debounce from 'debounce';
import Icon from 'components/Icon';

 
const texts = homeTexts.newProject;
const types = {
    SURVEY: 'regular',
    DATA_COLLECTION: 'data_collection',
    TEMPLATE: 'from_template' //TODO: uncomment this to show templates
}
const typesPrefaces = {
    survey: 'hsp___',
    dataCollection: 'hdc___'
}

export default function NewProject({ onSubmit }) {

    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const labels = useSelector(selectCurrentTeamLabels);
    const createNewProjectStatus = useSelector(state => state.horizonData.createNewProjectStatus);
    const newProjectToFetch = useSelector(state => state.horizonData.newProjectToFetch);
    const currentTeam = useSelector(selectCurrentTeam);
    const projects = useSelector(selectAllTeamProjects);
    const allProjectsLoaded = useSelector(state => state.horizonData.allProjectsLoaded);
    const fetchTeamProjectsStatus = useSelector(state => state.horizonData.fetchTeamProjectsStatus);
    const homePageSelectedTemplateId = useSelector(state => state.horizonData.homePageSelectedTemplateId);
    const projectToCopyId = useSelector(state => state.horizonData.projectToCopyId);


    const typeOptions = useMemo(() => {
        return Object.keys(types).map(t => ({ key: types[t], label: texts.types[types[t]][lang] }))
    }, [lang])

    const [isActive, setIsActive] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [sourceName, setSourceName] = useState('');
    const [projectIcon, setProjectIcon] = useState('✨')
    const [selectedType, setSelectedType] = useState(types.SURVEY);
    const [labelsOptions, setLabelOptions] = useState(null);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [projectsOptions, setProjectsOptions] = useState(null);
    const [templatesOptions, setTemplatesOptions] = useState(null);
    const [projectToCopy, setProjectToCopy] = useState(null);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState([]);

    const [warning, setWarning] = useState(null);

    const resetState = useCallback(() => {

        setIsValid(false);
        setProjectName('');
        setSourceName('');
        setProjectIcon('✨')
        setSelectedType(types.SURVEY);
        setLabelOptions(null);
        setSelectedLabels([]);
        setProjectsOptions(null);
        setSelectedProjects([]);
        setWarning(null);
        setProjectToCopy(null)

    }, [])

    // Labels options:
    useEffect(() => {
        if (homePageSelectedTemplateId) {
            setIsActive(true)
            setSelectedType(types.TEMPLATE)
            setSelectedTemplateId(homePageSelectedTemplateId)

            dispatch(setHomePageSelectedTemplateId(null))
        }
        if (projectToCopyId) {
            setIsActive(true)
            setSelectedType(types.TEMPLATE)
            setSelectedTemplateId(projectToCopyId)
        }

    }, [homePageSelectedTemplateId, projectToCopyId, dispatch])

    // Labels options:
    useEffect(() => {

        if (currentTeam && labels && labels.length > 0) {
            try {
                const options = currentTeam.labels.map(l => {
                    const label = labels.find(x => x.id === l.id);
                    return { key: l.id, label: label.name, isSelected: selectedLabels.includes(l.id) }
                });
                setLabelOptions(options);

            } catch (error) {
                // console.error( 'Erro while setting up new project labels. ', error );
            }
        }

    }, [labels, currentTeam, selectedLabels])


    // Appendix projects options:
    useEffect(() => {

        if (projects && projects.length > 0) {
            try {
                const options = [];
                const templates = [];
                projects.forEach(p => {
                    if (p.isArchived) return;
                    if (!p.isTemplate) {
                        options.push({
                            key: p.id,
                            label: p.name,
                            sourceName: p.sourceName,
                            isSelected: selectedProjects.includes(p.id)
                        })
                        if (projectToCopyId === p.id) {
                            setProjectToCopy({
                                key: p.id,
                                label: p.name,
                                sourceName: p.sourceName,
                                isSelected: selectedProjects.includes(p.id)
                            })
                            dispatch(setProjectToCopyId(null))
                        }
                    } else {
                        templates.push({
                            key: p.id,
                            label: p.name,
                            sourceName: p.sourceName,
                            isSelected: selectedProjects.includes(p.id)
                        })
                    }
                });
                setProjectsOptions(options);
                setTemplatesOptions(templates)
            } catch (error) {
                // console.error( 'Erro while setting up new project labels. ', error );
            }
        }

        if (
            isActive &&
            currentTeam &&
            selectedType === types.DATA_COLLECTION &&
            fetchTeamProjectsStatus !== 'loading' &&
            currentTeam.projects.length < currentTeam.projectsCount
        ) {
            dispatch(fetchSomeTeamProjects({ limit: 1000 }))
        }
    }, [projects, projectToCopyId, currentTeam, selectedProjects, isActive, selectedType, fetchTeamProjectsStatus, allProjectsLoaded, dispatch])

    // Validation:
    useEffect(() => {

        setIsValid(
            projectName && sourceName && selectedType && !warning
        )

    }, [projectName, sourceName, selectedType, warning]);

    // Handle api call state change:
    useEffect(() => {

        if (createNewProjectStatus === 'succeeded' && newProjectToFetch) {
            dispatch(fetchProjectById(newProjectToFetch));
            // dispatch( fetchInitialData() );
            resetState();
            setIsActive(false);
        }

    }, [createNewProjectStatus, resetState, newProjectToFetch, dispatch])

    const handleProjectsChange = useCallback((key) => {

        const selected = Array.from(selectedProjects);
        const targetInx = selected.indexOf(key);
        if (targetInx > -1) {
            selected.splice(targetInx, 1);
        } else {
            selected.push(key);
        }
        setSelectedProjects(selected);

    }, [selectedProjects])

    const handleLabelsChange = useCallback((op) => {

        const newLabels = Array.from(selectedLabels);
        const targetInx = newLabels.indexOf(op.key);
        if (targetInx > -1) {
            newLabels.splice(targetInx, 1);
        } else {
            newLabels.push(op.key);
        }
        setSelectedLabels(newLabels);

    }, [selectedLabels])

    const handleCreate = useCallback(() => {

        let sourcePreface = "";
        if (selectedType === types.SURVEY) sourcePreface = typesPrefaces.survey;
        if (selectedType === types.DATA_COLLECTION) sourcePreface = typesPrefaces.dataCollection;
        if (selectedType === types.TEMPLATE) sourcePreface = typesPrefaces.survey;

        const selectedlabels = [];
        if (labelsOptions) {
            labelsOptions.forEach(l => {
                if (l.isSelected) selectedlabels.push(l.key);
            })
        }

        dispatch(createNewProject({
            type: selectedType,
            projectName,
            sourceName: sourcePreface + sourceName,
            icon: projectIcon,
            labelIds: selectedlabels,
            appendixBucketsProjectIds: selectedProjects,
            templateProjectId: selectedTemplateId
        }));

    }, [projectName, sourceName, projectIcon, selectedType, labelsOptions, selectedProjects, selectedTemplateId, dispatch])

    const handleClose = useCallback(() => {
        resetState();
        setIsActive(false);
    }, [resetState])


    return (
        <>
            <div
                className={nstyles.rowItem}
                onClick={() => setIsActive(true)}
                style={{ justifyContent: 'center' }}
            >

                <Icon
                    name="plus"
                    size={24}
                />
                <span className={nstyles.newItemLabel}>{homeTexts.newProject.blankProject[lang]}</span>
            </div>

            <Dialog
                isActive={isActive}
                onClose={handleClose}
                style={{
                    backgroundColor: 'var(--color-background-mild)',
                    padding: 'var(--space-large)'
                }}
            >
                <div className={styles.mainCont}>
                    <p className={styles.title}> {texts.poperTitle[lang]} </p>
                    <div className={styles.fieldsCont}>
                        <div className={styles.sharedSettingsCont}>
                            <TextInput
                                label={texts.projectNameLabel[lang]}
                                className={styles.fields}
                                dir={lang === 'en' ? 'ltr' : 'rtl'}
                                placeholder='...'
                                value={projectName}
                                onChange={e => setProjectName(e.target.value)}
                            />
                            <SourceNameInput
                                lang={lang}
                                projectType={selectedType}
                                value={sourceName}
                                onChange={name => setSourceName(name)}
                                onWarning={warn => setWarning(warn)}
                            />
                            <EmojiPicker
                                label={texts.projectIconLabel[lang]}
                                className={styles.fields}
                                value={projectIcon}
                                onChange={e => setProjectIcon(e)}
                            />
                            <MultipleSelect
                                label={texts.projectLabelsLabel[lang]}
                                className={styles.fields}
                                options={labelsOptions}
                                onChange={handleLabelsChange}
                            />
                        </div>
                        <div className={styles.separator} />
                        <div className={styles.typeSpecificCont}>
                            {!projectToCopy && <>
                                <DropDown
                                    label={texts.projectTypeLabel[lang]}
                                    options={typeOptions}
                                    value={selectedType}
                                    className={styles.fields}
                                    onChange={op => setSelectedType(op.key)}
                                />
                                {
                                    selectedType === types.DATA_COLLECTION &&
                                    currentTeam.projects.length >= currentTeam.projectsCount &&
                                    <MultipleSelect
                                        label={texts.appendixProjectsLabel[lang]}
                                        className={styles.fields}
                                        onChange={handleProjectsChange}
                                        isLoading={currentTeam.projects.length < currentTeam.projectsCount}
                                    >
                                        {projectsOptions &&
                                            projectsOptions.map(op =>
                                                <SelectionItem key={op.key} label={op.label} isSelected={op.isSelected}>
                                                    <span className={styles.projectNameLabel}> {op.label} </span>
                                                    <span className={styles.projectSourceNameLabel}> — {op.sourceName} </span>
                                                </SelectionItem>
                                            )
                                        }

                                    </MultipleSelect>
                                }
                                {
                                    selectedType === types.TEMPLATE &&
                                    currentTeam.projects.length >= currentTeam.projectsCount &&
                                    projectsOptions &&
                                    <DropDown
                                        label={texts.templateTypeLabel[lang]}
                                        options={templatesOptions.map(op => { return { key: op.key, label: op.label } })}
                                        value={selectedTemplateId}
                                        className={styles.fields}
                                        onChange={op => setSelectedTemplateId(op.key)}
                                    />
                                }
                                {
                                    (selectedType === types.DATA_COLLECTION || selectedType === types.TEMPLATE) &&
                                    currentTeam.projects.length < currentTeam.projectsCount &&
                                    <ActivityIndicator size={20} />
                                }
                            </> }
                            {projectToCopy && <>
                               <p className={styles.title} >{texts.copyProject[lang]}</p> 
                               <p className={styles.fields}>{projectToCopy.label}</p>
                            </> }

                        </div>

                    </div>

                    <div className={styles.bottomCont}>
                        <Button
                            label={texts.cancelBtn[lang]}
                            theme='Outlined'
                            size='m'
                            colorSet='Grayscale'
                            onClick={handleClose}
                        />
                        <div className={styles.gap} />
                        <Button
                            label={texts.createBtn[lang]}
                            theme='Full'
                            size='m'
                            colorSet='Primary'
                            disabled={!isValid}
                            onClick={() => handleCreate()}
                        />
                    </div>

                    {
                        createNewProjectStatus === 'loading' &&
                        <div className={styles.activityBlock}>
                            <ActivityIndicator size={32} />
                        </div>
                    }
                </div>
            </Dialog>

            {
                warning &&
                <Snackbar
                    key={'warning'}
                    message={warning}
                    type={SnackbarTypes.WARNING}
                    timeout={6}
                    onClose={() => setWarning(null)}
                    action={{
                        label: texts.warnings.actions.gotIt[lang]
                    }}
                />
            }
        </>
    )

}

const DEBOUNCE_SOURCE_CHECK_DELAY = 500;

const SourceNameInput = ({ lang, projectType, value, onChange, onWarning }) => {

    const [sourceName, setSourceName] = useState(value);
    const [isLoading, setIsLoading] = useState(false);
    const [isAvailable, setIsAvailable] = useState(false);
    const [warn, setWarn] = useState("");

    const debouncedSourceCheck = useMemo(() =>
        debounce(async (n) => {

            onChange(null);
            setIsAvailable(false);
            setIsLoading(true);
            setWarn("");
            const fullName = typesPrefaces[projectType] + n;
            const res = await CheckSourceNameAvailability(fullName);

            setIsLoading(false);

            if (res.data && res.data.sourceNameStatus) {

                const status = res.data.sourceNameStatus;
                if (status === 'available') {
                    onChange(n);
                    setIsAvailable(true);
                    setWarn("");
                } else {
                    setWarn(texts.warnings.sourceNameIsNotAvailable[lang]);
                    setIsAvailable(false);
                    onChange(null);
                }
            }

        },
            DEBOUNCE_SOURCE_CHECK_DELAY
        ),
        [lang, projectType, onChange]
    )

    useEffect(() => {

        // Make sure there are no scheduled executions when unmounted:
        return () => {
            debouncedSourceCheck.clear();
        }

    }, [debouncedSourceCheck])

    const handleSourceChange = useCallback(async (n) => {

        if (isKeyLegal(n)) {

            setSourceName(n);
            debouncedSourceCheck(n);

        } else {
            onWarning(texts.warnings.illegalKeyCharacter[lang])
        }

    }, [lang, onWarning, debouncedSourceCheck]);

    return (
        <TextInput
            label={texts.projectSourceNameLabel[lang]}
            className={styles.fields}
            placeholder='...'
            dir={lang === 'en' ? 'ltr' : 'rtl'}
            value={sourceName}
            onChange={e => handleSourceChange(e.target.value)}
            isLoading={isLoading}
            isValid={isAvailable}
            warning={warn}
        />
    )
}