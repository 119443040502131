import React from 'react';
import styles from './SurveyEditorContent.module.css';
import SurveyEditorContentList from './SurveyEditorContentList';
import SurveyEditorContentTobBar from './SurveyEditorContentTopBar';

export default function SurveyEditorContent () {

    return (
        <div id='survey_editor_content_main' className={ styles.mainCont }>
            <SurveyEditorContentTobBar/>
            <SurveyEditorContentList/>
        </div>
    )
}
