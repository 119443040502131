import React from 'react';
import ColorPicker from 'components/ColorPicker';

export default function ColorFieldModule ({data, value, handleChange, lang}) {

    return (
        <ColorPicker
            label={ data.label ? data.label[ lang ] : '' }
            value={ value || data.defaultValut }
            onChange={ (col) => handleChange( col ) }
        />
    )
}