import React from 'react';
import styles from '../SurveyEditorProperties.module.css';

import LanguagesOptions from './LanguageOptions';
import ColorOptions from './ColorOptions';
import FillingOptions from './FillingOptions';
import SplashSettings from './SplashSettings';
import PlatformLimitation from './PlatformLimitations';
import ProgressBarOptions from './ProgressBarOptions';

import { surveyEditorTexts } from 'utils/appTexts';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import SurveyName from './SurveyName';

const texts = surveyEditorTexts.properties.surveySettings;

export default function SurveySettings() {
    const lang = useSelector(selectUiLanguage);

    const sourceName = useSelector(
        (state) => state.surveyEditor.present.survey.sourceName
    );

    if (!sourceName) return null;

    return (
        <div className={styles.mainCont}>
            <h4 className={styles.modeLabel}>{texts.title[lang]}</h4>
            <SurveyName />
            <LanguagesOptions />
            <ColorOptions />

            <div className={styles.separationLine} />
            <FillingOptions />
            <div className={styles.separationLine} />
            <SplashSettings />
            <div className={styles.separationLine} />
            <PlatformLimitation />
            <div className={styles.separationLine} />
            <ProgressBarOptions />
        </div>
    );
}
