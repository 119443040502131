import React from 'react';

import SurveySetup from './SurveySetup';
import SurveyServices from './survey_services/SurveyServices';
import SurveyEditorMain from './SurveyEditorMain';
import SurveyEditorUndoRedo from './SurveyEditorUndoRedo';

export default function SurveyEditor() {
        
    return (
        <>
            <SurveySetup/>
            <SurveyServices/>
            <SurveyEditorMain/>
            <SurveyEditorUndoRedo/>
        </>
    )
}

