import React, { useMemo } from 'react';
import styles from './GotoPageSelection.module.css';
import SelectionPopper from 'components/poppers/SelectionPopper';
import { useSelector } from 'react-redux';
import { getRawText } from 'components/rich_text/RichTextInput';

export default function GotoPageSelection({
    isActive,
    referenceElement,
    onSelect,
    onDismiss,
    forbiddenPages,
    dir = 'auto',
}) {
    const surveyLang = useSelector(
        (state) => state.surveyEditor.present.display.currentLanguage
    );
    const pagesData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData
    );
    const pagesOrder = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesOrder
    );

    const options = useMemo(() => {
        const _options = [];
        pagesOrder.forEach((id, i) => {
            const data = pagesData[id];

            if (forbiddenPages && forbiddenPages.includes(id)) return;

            _options.push({
                key: id,
                pageNumber: i + 1,
                title: getRawText(data.title[surveyLang]),
            });
            if (data.type === 'BLOCK') {
                data.pages.forEach((pageId, j) => {
                    if (forbiddenPages && forbiddenPages.includes(pageId))
                        return;
                    const blockPageData = pagesData[pageId];
                    _options.push({
                        key: pageId,
                        pageNumber: `${i + 1}.${j + 1}`,
                        title: getRawText(blockPageData.title[surveyLang]),
                    });
                });
            }
        });

        return _options;
    }, [pagesData, pagesOrder, forbiddenPages, surveyLang]);

    return (
        <SelectionPopper
            referenceElement={referenceElement}
            isActive={isActive}
            onSelect={onSelect}
            onDismiss={onDismiss}
            dir={dir}
        >
            {options.map((op, i) => {
                return (
                    <span key={op.key} className={styles.pageItemCont}>
                        <span className={styles.pageNumber}>
                            {op.pageNumber}
                        </span>
                        {op.title}
                    </span>
                );
            })}
        </SelectionPopper>
    );
}
