import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Notebook.module.css';
import Emoji from 'components/Emoji';
import { Editable } from 'components/Editable';
import { notebookTexts } from 'utils/appTexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { updateNotebookMetadata } from './notebookSlice';

const texts = notebookTexts.header;

export default function NotebookHeader ({project}) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );

    const remoteData = useSelector( state => state.notebook.remoteData );

    const createdAt = useMemo( () => {
        
        if( project && project.createdAt ) {
            return new Date( project.createdAt ).toLocaleDateString('he-IL');
        }
        return '—';
        
    }, [project]);
    
    const modificationDate = useMemo( () => {
        if( !remoteData.updatedAt ) return null;
        return new Date ( remoteData.updatedAt );
    }, [remoteData] );
    
    const editorName = useMemo( () => {
        if( !remoteData.editorName ) return 'unknown';
        return remoteData.editorName;
    }, [remoteData] );

    const modifiedDetails = useMemo( () => {
        if( !modificationDate ) return '—';
        return texts.modifiedLabelDetails[ lang ]
            .replace( '{d}', `${modificationDate.toLocaleDateString('he-IL')}, ${modificationDate.toLocaleTimeString('he-IL')}` )
            .replace( '{u}', editorName )
    }, [lang, editorName, modificationDate]);

    const [title, setTitle] = useState( null );

    useEffect( () => {
        
        if( remoteData.title && !title ) {
            setTitle( remoteData.title )
        }

    }, [remoteData, title ] );

    const handleTitleChange = useCallback( () => {

        if( !project.isTemplate ) {
            dispatch( updateNotebookMetadata( {
                notebookId: project.notebook.id, 
                prop: 'title', 
                value: title 
            }))
        }

    }, [dispatch, project, title])

    return (
        <>
            <div className={ styles.headerCont}>
                <div className={ styles.notebookDetailsCont }>
                    <Editable 
                        className={ styles.notebookTitle }
                        value={ title ? title : "..." }
                        onChange={ v => setTitle( v ) }
                        onBlur={ handleTitleChange }
                    />
                    <p className={ styles.headerDetail }>{texts.createdAtLabel[ lang ]}: {createdAt}</p>
                    <p className={ styles.headerDetail }>{texts.modifiedLabel[ lang ]}: {modifiedDetails}</p>
                </div>
                <div className={ styles.headerIconCont}>
                    {
                        project &&
                        <Emoji symbol={ project.icon } style={{fontSize: 72}}/>
                    }
                </div>
            </div>
            <div className={ styles.headerSeparator }/>
        </>
    )
}