import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { surveySettingsChanged } from 'features/survey_editor/surveyEditorSlice';
import Checkbox from 'components/Checkbox';
import { surveyEditorTexts } from 'utils/appTexts';
const texts = surveyEditorTexts.properties.surveySettings;

const ProgressBarOptions = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const hideProgressbar = useSelector(
        (state) =>
            state.surveyEditor.present.survey.settings.general.hideProgressbar
    );

    const handleChange = useCallback(() => {
        dispatch(
            surveySettingsChanged({
                prop: 'hideProgressbar',
                value: !hideProgressbar,
            })
        );
    }, [dispatch, hideProgressbar]);

    return (
        <>
            <Checkbox
                label={texts.hideProgressBar[lang]}
                isChecked={hideProgressbar}
                onChange={handleChange}
            />
        </>
    );
};

export default ProgressBarOptions;
