import React, {useMemo} from 'react';
import styles from './InputStyles.module.css';

/**
 * @param {string} theme Styling theme: FullDark | FullLight | Outlined | BorderBottom
 * @param {string} label Label to display by the text field
 * @param {string} name The data name (usually a key)
 * @param {string} value Initial datetime value
 * @param {number} tabIndex When jumping from one element to another by hitting 'Tab', this index determines the order by which the focus will change
 * @param {boolean} incluedTime Also include time
 * @param {function} onChange Use event.target.value to get the text value
 * @param {string} className cont class name
 * @param {object} contStyle Inline styling for the component container
 * @param {object} fieldStyle Inline styling for the input field
 * @param {object} labelStyle Inline styling for the label
 */
export default function DateInput({

    theme = 'Outlined',
    label,
    name,
    value,
    includeTime = false,
    className,
    contStyle,
    fieldStyle,
    labelStyle,
    tabIndex,
    onChange,

}) {
    const fieldsStyles = useMemo( () => ({
        FullDark: {
            backgroundColor: 'var( --color-background-hover )',
            border: 'none',

            ...fieldStyle
        },
        FullLight: {
            backgroundColor: 'white',
            border: 'none',

            ...fieldStyle
        },
        Outlined: {
            border: 'var( --border-light )',

            ...fieldStyle
        },
        BorderBottom: {
            border: 'none',
            borderBottom: 'var( --border-light )', 

            ...fieldStyle
        }
    }), [fieldStyle] );

    const fieldThemeStyle = fieldsStyles[ theme ];

    return (
        <div className={ `${styles.mainCont} ${className}` } style={ contStyle }>
            <label className={ styles.label } htmlFor={ name } style={ labelStyle }>{ label }</label>
            <input 
                id={ name }
                className={ styles.input }
                type={ includeTime ? 'datetime-local' : 'date' }
                value={ value ? value : '' }
                tabIndex={ tabIndex }
                onChange={ onChange }
                placeholder="dd/mm/yyyy, hh:mm"
                style={ fieldThemeStyle }
            >

            </input>
        </div>
    )
}
