import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import styles from './VariableItem.module.css';
import { Editable } from 'components/Editable';
import Button from 'components/Button';
import SelectionPopper from 'components/poppers/SelectionPopper';
import TextInput from 'components/TextInput';
import IconButton from 'components/IconButton';
import DropDown from 'components/DropDown';
import { variablesTypes } from './LogicConfiguration';
import { surveyEditorTexts } from 'utils/appTexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import {
    alertAdded,
    messageAdded,
    variableAdded,
    variableChanged,
    variableDeleted,
} from '../surveyEditorSlice';
import { isKeyLegal } from 'utils/validation';
import {
    deletionAlert,
    useReferenceInLogic,
} from './LogicReferenceLoseProtection';

const texts = surveyEditorTexts.properties.logic;

export default function VariableItem({ varData }) {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const isNew = useMemo(() => varData.id === 'ADD_ITEM', [varData]);
    const allVars = useSelector(
        (state) => state.surveyEditor.present.survey.logic.variables
    );
    const pagesData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData
    );

    const [isHovered, setIsHovered] = useState(false);
    const [initialName, setInitialName] = useState('');
    const [initialType, setInitialType] = useState(variablesTypes.NUMBER);
    const [warning, setWarning] = useState(false);

    const getRefsInLogic = useReferenceInLogic(varData.id);

    // Warn when var name is aleary taken:
    useEffect(() => {
        let warning = '';

        allVars.forEach((v) => {
            if (isNew) {
                if (initialName === v.name) {
                    warning = texts.idencticalVarNameWarning[lang];
                }
            } else if (v.id !== varData.id) {
                if (v.name === varData.name) {
                    warning = texts.idencticalVarNameWarning[lang];
                }
            }
        });

        Object.values(pagesData).forEach((pageData) => {
            const pageKey = pageData.key;
            if (
                pageKey === varData.name ||
                (isNew && pageKey === initialName)
            ) {
                warning = texts.idencticalToPageKeyWarning[lang];
            }
        });

        setWarning(warning);
    }, [allVars, pagesData, isNew, initialName, varData, lang]);

    ///////////
    // UTILS //
    ///////////

    const addNew = useCallback(() => {
        setInitialName('');

        const payload = {
            name: initialName,
            type: initialType,
        };

        dispatch(variableAdded(payload));
    }, [initialType, initialName, dispatch]);

    const handleTypeChange = useCallback(
        (type) => {
            if (isNew) {
                setInitialType(type);
            } else {
                dispatch(
                    variableChanged({
                        id: varData.id,
                        prop: 'type',
                        value: type,
                    })
                );
            }
        },
        [isNew, varData, dispatch]
    );

    const handleNameChange = useCallback(
        (value) => {
            if (isKeyLegal(value)) {
                if (isNew) {
                    setInitialName(value);
                } else {
                    dispatch(
                        variableChanged({
                            id: varData.id,
                            prop: 'name',
                            value: value,
                        })
                    );
                }
            } else {
                dispatch(
                    messageAdded({ type: 'ILLEGAL_KEY_CHARACTER', arg: '' })
                );
            }
        },
        [isNew, varData, dispatch]
    );

    const handleTextInputChange = useCallback(
        (e) => {
            let value = e.target.value;
            if (varData.type === variablesTypes.NUMBER) {
                value = parseFloat(value);
            }
            dispatch(variableChanged({ id: varData.id, prop: 'value', value }));
        },
        [varData, dispatch]
    );

    const handleBooleanChange = useCallback(
        (selected) => {
            // const isTrue = selected.key === 'true';

            dispatch(
                variableChanged({
                    id: varData.id,
                    prop: 'value',
                    value: selected.key,
                })
            );
        },
        [varData, dispatch]
    );

    const handleDelete = useCallback(() => {
        const refs = getRefsInLogic();
        if (refs.length > 0) {
            const alert = deletionAlert(lang);
            alert.message = alert.message.replace(
                '{item}',
                texts.variable[lang]
            );
            alert.actions[0].callback = () => {
                refs.forEach((ref) => {
                    if (ref.cleanupCB) {
                        ref.cleanupCB();
                    }
                });
                dispatch(variableDeleted(varData.id));
            };

            dispatch(alertAdded(alert));
        } else {
            dispatch(variableDeleted(varData.id));
        }
    }, [lang, varData, getRefsInLogic, dispatch]);

    const handleNameBlur = useCallback(
        (event) => {
            if (isNew && initialName) {
                addNew();
            }
        },
        [isNew, initialName, addNew]
    );

    ////////////
    // RENDER //
    ////////////

    const nameInputRef = useRef();

    return (
        <div
            className={styles.itemCont}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onBlur={() => setIsHovered(false)}
        >
            <TypeSelection
                lang={lang}
                value={isNew ? initialType : varData.type}
                onSelect={handleTypeChange}
            />

            <div className={styles.midCont}>
                <Editable
                    ref={nameInputRef}
                    value={isNew ? initialName : varData.name}
                    placeholder={'new_var'}
                    className={styles.varName}
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                />
                {!isNew && varData.type === variablesTypes.BOOLEAN ? (
                    <>
                        <span className={styles.eqLabel}>=</span>
                        <DropDown
                            className={styles.booleanCont}
                            options={[
                                { key: 'true', label: 'true' },
                                { key: 'false', label: 'false' },
                            ]}
                            bgStyle={{
                                height: 28,
                                margin: 0,
                                opacity: isNew ? 0.4 : 1,
                                padding: '4px 6px',
                            }}
                            labelStyle={{
                                font: 'var( --font-code )',
                            }}
                            value={varData.value?.toString()}
                            onChange={handleBooleanChange}
                        />
                    </>
                ) : (
                    <>
                        <span
                            className={styles.eqLabel}
                            style={{ opacity: isNew ? 0.4 : 1 }}
                        >
                            =
                        </span>
                        <TextInput
                            className={styles.valueInput}
                            type={
                                varData.type &&
                                varData.type === variablesTypes.NUMBER
                                    ? 'number'
                                    : 'text'
                            }
                            // contStyle={{ width: isNew ? 100 : 10 * varData.value.toString().length }}
                            fieldStyle={{
                                height: 28,
                                opacity: isNew ? 0.4 : 1,
                                padding: '4px 6px',
                                font: 'var( --font-code )',
                            }}
                            value={isNew ? '' : varData.value}
                            onChange={handleTextInputChange}
                            disabled={isNew}
                        />
                    </>
                )}
            </div>
            <Warning warning={warning} />

            {isNew ? (
                <IconButton
                    name="help"
                    tooltip={texts.addNewVarTip[lang]}
                    size="s"
                    theme="Plain"
                    // colorSet='Grayscale'
                    bgStyle={{ opacity: isHovered ? 1 : 0, margin: '0 -6px' }}
                    iconStyle={{ width: 24, height: 24 }}
                    onClick={() => nameInputRef.current.focus()}
                />
            ) : (
                <IconButton
                    name="delete"
                    tooltip={texts.deleteVar[lang]}
                    size="s"
                    theme="Plain"
                    colorSet="Grayscale"
                    bgStyle={{ opacity: isHovered ? 1 : 0, margin: '0 -6px' }}
                    onClick={handleDelete}
                />
            )}
        </div>
    );
}

const Warning = ({ warning }) => {
    if (warning) {
        return (
            <IconButton
                name="warning"
                theme="Plain"
                colorSet="Grayscale"
                size="s"
                bgStyle={{ margin: '0 4px' }}
                tooltip={warning}
            />
        );
    } else {
        return null;
    }
};

const TypeSelection = ({ lang, value, onSelect }) => {
    const btnRef = useRef();
    const [isActive, setIsActive] = useState(false);

    const icons = useMemo(
        () => ({
            [variablesTypes.NUMBER]: 'number_var',
            [variablesTypes.STRING]: 'text_var',
            [variablesTypes.BOOLEAN]: 'boolean_var',
            [variablesTypes.URL_PARAM]: 'url_var',
            [variablesTypes.DATASOURCE]: 'datasource',
        }),
        []
    );

    const options = useMemo(() => {
        return [
            {
                key: variablesTypes.NUMBER,
                label: texts.variablesTypes[variablesTypes.NUMBER][lang],
            },
            {
                key: variablesTypes.STRING,
                label: texts.variablesTypes[variablesTypes.STRING][lang],
            },
            {
                key: variablesTypes.BOOLEAN,
                label: texts.variablesTypes[variablesTypes.BOOLEAN][lang],
            },
            {
                key: variablesTypes.URL_PARAM,
                label: texts.variablesTypes[variablesTypes.URL_PARAM][lang],
            },
            {
                key: variablesTypes.DATASOURCE,
                label: texts.variablesTypes[variablesTypes.DATASOURCE][lang],
            },
        ];
    }, [lang]);

    const handleSelect = useCallback(
        (selected) => {
            setIsActive(false);
            onSelect(selected.key);
        },
        [onSelect]
    );

    return (
        <>
            <div className={styles.typeSelectionCont} ref={btnRef}>
                <Button
                    iconBefore={icons[value]}
                    tooltip={texts.varTypeTooltip[lang]}
                    size="s"
                    theme="Outlined"
                    colorSet="Grayscale"
                    bgStyle={{ padding: 4, minWidth: 24, height: 24 }}
                    labelStyle={{
                        color: 'var(--color-type-low-emphasis)',
                        font: 'var(--font-code)',
                    }}
                    onClick={() => setIsActive(true)}
                />
            </div>
            <SelectionPopper
                referenceElement={btnRef.current}
                isActive={isActive}
                options={options}
                onSelect={handleSelect}
                onDismiss={() => setIsActive(false)}
            />
        </>
    );
};
