import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './VirtualVarsEditor.module.css';
import inputStyles from 'components/InputStyles.module.css';

import Button from 'components/Button';
import CollapseButton from 'components/CollapseButton';
import DropDown from 'components/DropDown';
import TextInput, { TextTypes } from 'components/TextInput';
import { Editable } from 'components/Editable';
import IconButton from 'components/IconButton';

import { resultsTexts } from 'utils/appTexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { types } from './VirtualVarsEditor';
import { argAdded, argAddedToFormula, argChanged, argDeleted, fetchReport, messageAdded } from 'app/reportSlice';
import { isKeyLegal } from 'utils/validation';

const texts = resultsTexts.virtualVarEditor.args;


export default function VirtualVarsArgs({project}) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const isLtr = lang === 'en';

    const args = useSelector( state => state.report.virtualVars.editor.varData.args );

    const handleCreate = useCallback( () => {

        dispatch( argAdded() );

    }, [dispatch])

    return (

        <div 
            className={ styles.argsArea} 
            style={{
                paddingLeft: isLtr ? 0 : 'var( --space-very-large )',
                paddingRight: !isLtr ? 0 : 'var( --space-very-large )',
            }} 
        >
            <h3 className={ styles.editorTitle }>
                { texts.title[ lang ] }
            </h3>

            <div 
                className={ styles.argsCont }
                style={{
                    left: isLtr ? 0 : 'var( --space-very-large )',
                    right: !isLtr ? 0 : 'var( --space-very-large )',
                }} 
            >
                {
                    args && Object.keys( args ).length > 0 &&
                    
                    Object.keys( args ).map( (a,i) => 
                        <Argument 
                            key={i} 
                            id={ a } 
                            project={ project }
                            data={ args[ a ] } 
                            lang={ lang }
                        />
                    )
                }
                
                <Button
                    label={ texts.createNewArgBtn[ lang ] }
                    theme='Outlined'
                    iconBefore='plus'
                    bgStyle={{width: '100%'}}
                    onClick={ handleCreate }
                />

            </div>
        </div>

    )
}

const Argument = ({ project, data, id, lang }) => {

    const dispatch = useDispatch();

    const varType = useSelector( state => state.report.virtualVars.editor.varData.varType );
    const selectedCategoryIndex = useSelector( state => state.report.virtualVars.editor.selectedCategory );

    const [isCollapsed, setIsCollapsed] = useState( false );
    const [isHovered, setIsHovered] = useState( false );

    const dataKeys = useSelector( state => {

        if( !project ) return null;

        const dataKeys = state.report.dataKeys;

        if (!dataKeys) {
            if (state.report.fetchStatus === 'idle') {
                dispatch(fetchReport(project.sourceName));
            }
            return null
        }
        return dataKeys

    })

    const dataKeysOptions = useMemo( () => {

        if( !dataKeys ) return [{key: 'loading', label: texts.loadingDataKeys[ lang ]}];

        const options = [];

        Object.keys( dataKeys ).forEach( k => {
            
            const meta = dataKeys[k];
            const type = meta.type;

            if( type === 'STRING' || type === 'NUMBER' || type === 'BOOL' ) {

                const title = meta.title ? meta.title : ''
    
                options.push({
                    key: k, 
                    label: k,
                    type,
                    title
                }) 
            }


        }) 

        return options;

    }, [dataKeys,lang])

    const isCategorial = useMemo ( () => {

        if( !dataKeys || !data.key ) return null;

        const meta = dataKeys[ data.key ];
        return ( 
            (meta.type === 'STRING' || 
            meta.originType === 'SCALE_BUNDLE' ||
            meta.originType === 'MULTIPLE_CHOICE_BUNDLE') &&
            meta.valueDescriptions 
        )

    }, [ dataKeys, data ])

    const valuesToMap = useMemo ( () => {

        if( dataKeys && data.key && isCategorial ) {
            return dataKeys[ data.key ].valueDescriptions;
        }

        return []

    }, [ dataKeys, data.key, isCategorial ] )

    const handleChange = useCallback( (prop, value) => {

        dispatch( argChanged( {id, prop, value } ) );

    }, [id,dispatch])

    const handleNameChange = useCallback( value => {

        const isLegalValue = isKeyLegal( value );

        if( isLegalValue ) {

            handleChange ('name', value );

        } else {

            dispatch( messageAdded( {
                type: "ILLEGAL_KEY_CHARACTER",
                args: ""
            }) )

        }


    }, [handleChange, dispatch])

    const handleDelete = useCallback( () => {

        dispatch( argDeleted( { id } ) );

    }, [id,dispatch])

    const warning = useMemo( () => {
        if( data.warning ) {
             return (
                 <IconButton
                     name="warning"
                     theme="Plain"
                     colorSet="Grayscale"
                     size="s"
                     tabIndex="-1"
                     tooltip={ data.warning[ lang ] }
                 />
             )
        } else return null;
     }, [data, lang])

    return (
        <div 
            className={ styles.argItemCont }
            onMouseEnter={ () => setIsHovered( true ) }
            onMouseLeave={ () => setIsHovered( false ) }
        >
            <div className={ styles.argItemTopCont }>
                <CollapseButton isCollapsed={ isCollapsed } onToggle={ val => setIsCollapsed( val ) }/>
                { warning }
                <Editable 
                    className={ styles.argNameLabel }
                    value={ data.name }
                    onChange={ value => handleNameChange( value ) }
                    placeholder={ '!missing name'}
                />
                {
                    isHovered && <>
                    <IconButton
                        name='plus'
                        size='s'
                        bgStyle={{margin: '0 6px'}}
                        tooltip={ texts.addBtnTooltip[ lang ] }
                        disabled={ varType === types.CATEGORICAL && selectedCategoryIndex === -1 }
                        onClick={ () => dispatch( argAddedToFormula( {name: data.name}) ) }
                    />
                    <IconButton
                        name='delete'
                        tooltip={ texts.deleteBtnTooltip[ lang ]}
                        colorSet='Grayscale'
                        theme='Plain'
                        size='s'
                        onClick={ handleDelete }
                    />

                    </>
                }
            </div>
            
            {
                !isCollapsed && 
                <div className={ styles.argsSettingsCont }>

                    <DropDown
                        label={ texts.keyInputLabel[ lang ] }
                        className={ styles.settingsInputField }
                        value={ data.key }
                        onChange={ (opKey) => {
                            handleChange( 'key', opKey );
                            handleChange( 'valuesDict', {} ); // Reset valuesDict upon changing key
                        }}
                        > 
                        {
                            dataKeysOptions.map( (op,i) => {
                                return (
                                    <span key={op.key} label={op.label}>
                                        <span className={ styles.dropdownKeyLabel }> {op.label} </span>
                                        <span className={ styles.dropdownTypeLabel }> ({op.type}) </span>
                                        <span className={ styles.dropdownTitleLabel }> — {op.title}</span>
                                    </span>
                                )
                            })
                        }  
                    </DropDown>   
                    <TextInput
                        label={ texts.nullInputLabel[ lang ] }
                        type={ TextTypes.NUMBER }
                        value={ data.nullValue ? data.nullValue : "" }
                        className={ styles.settingsInputField }
                        onChange={ event => handleChange( 'nullValue', event.target.value ? event.target.value : null ) }
                    />
                    <TextInput
                        label={ texts.defaultInputLabel[ lang ] }
                        type={ TextTypes.NUMBER }
                        value={ data.defaultValue }
                        className={ styles.settingsInputField }
                        onChange={ event => handleChange( 'defaultValue', event.target.value ) }
                    />
                    {
                        isCategorial && 
                        <ValuesDictInput
                            lang={ lang }
                            dict={ data.valuesDict }
                            values={ valuesToMap }
                            onChange={ dict => handleChange( 'valuesDict', dict ) }
                        />
                    }
                    <hr className={ styles.divider }/>
                    <TextInput
                        label={ texts.testingInputLabel[ lang ] }
                        type={ TextTypes.NUMBER }
                        value={ data.testingValue }
                        className={ styles.settingsInputField }
                        onChange={ event => handleChange( 'testingValue', event.target.value ) }
                    />
                </div>
            }
        </div>
    )
}


const ValuesDictInput = ({lang, dict, values, onChange}) => {

    const isLtr = lang === 'en';

    useEffect( () => {

        if( 
            values && Object.keys( values ).length > 0 && 
            Object.keys( dict ).length === 0 

        ) {

            const newDict = {}
            Object.keys( values ).forEach( (k,i) => newDict[k] = i );

            onChange( newDict );
        }

    }, [dict, values, onChange])


    const handleChange = useCallback( (key, value) => {

        const newDict = { ...dict }
        newDict[ key ] = value;
        onChange( newDict );

    }, [onChange, dict])

    return (
        <div
            className={ styles.settingsInputField }
        >
            <label className={ inputStyles.label }>{ texts.valuesDictLabel[ lang ] }</label>

            {
                Object.keys( dict ).map( (k,i) => (

                    <div 
                        key={ k + i }
                        className={ styles.valuesDictRow}
                    >
                        <label 
                            className={ styles.valuesDictValueLabel }
                            style={{
                                textAlign: isLtr ? 'right' : 'left'
                            }}
                        >
                                {k}
                        </label>
                        <div style={{minWidth: 4}}/>
                        <TextInput
                            type={ TextTypes.NUMBER }
                            value={ dict[k] }
                            onChange={ event => handleChange( k, event.target.value ) }
                        />
                    </div>
                ))
                
            }
            

        </div>
    )

}