import React from 'react';
import propsStyles from '../SurveyEditorProperties.module.css';
import Checkbox from 'components/Checkbox';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
// import { selectPageProperty } from 'features/survey_editor/surveyEditorSlice';

import { surveyEditorTexts } from 'utils/appTexts';

const generalTexts = surveyEditorTexts.properties.pageSettings;
// const texts = generalTexts.settingsModules.dropdownList;

export default function DropdownListSettingsModule({settings, updateSettings}) {

    const lang = useSelector( selectUiLanguage );
    // const currentPage = useSelector( state => state.surveyEditor.present.display.currentPage );
    // const itemsOrder = useSelector( state => {
    //     if( !currentPage ) return null;
    //     return selectPageProperty( state, currentPage, "itemsOrder" );
    // });

    if( !settings ) return null;
    
    const generalSettings = settings.general;
    const { showSubtitle, randomizeItems } = generalSettings;

    return (
        <>
            <Checkbox
                size='s'
                className={ propsStyles.checkbox }
                label={ generalTexts.randomizeItems[ lang ] }
                isChecked={ randomizeItems }
                onChange={ isChecked => updateSettings( {prop: 'randomizeItems', value: isChecked} ) }
            />
            <Checkbox
                size='s'
                className={ propsStyles.checkbox }
                label={ generalTexts.showSubtitleLabel[ lang ] }
                isChecked={ showSubtitle }
                onChange={ isChecked => updateSettings( {prop: 'showSubtitle', value: isChecked} ) }
            />
        </>
    )
}