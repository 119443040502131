import React, { useState, useRef, useCallback } from 'react';
import styles from  './AnalysisCard.module.css';
import IconButton from 'components/IconButton';
import SelectionPopper from 'components/poppers/SelectionPopper';
import { AlertDialog } from 'components/poppers/Dialog';
import AnalysisResultsDetails from './AnalysisResultsDetails';

import { useSelector, useDispatch } from 'react-redux';
import { 
    analysisReproduced, 
    archiveAnalysisResults, 
    submitNewAnalyses, 
} from 'features/analyses/analysesSlice';
import { analysesTexts } from 'utils/appTexts';
import * as Api from 'api/api';

const texts = analysesTexts.cardTexts;

const optionsTypes = {
    ARCHIVE: "archive",
    REPRODUCE: "reproduce",
    UPDATE: "update",
    VIEW_DETAILS: "view_details",
    KEYSTONE: "keystone",
    TOKEN: "token"
}

export default function AnalysisCardOptions ({data, onDone, showIcon, lang, project, onActivityStart}) {

    const dispatch = useDispatch();
    const userId = useSelector( state => state.users.mainUser.id );
	const optionsBtnRef = useRef();
    const [isOptionsActive, setIsOptionsActive] = useState( false );
    const [dialogActive, setDialogActive] = useState(false);
    const [dialogMessage, setDialogMessage] = useState({title: "", body: ""});
    const [isDetailsActive, setIsDetailsActive] = useState( false );
    
    const handleArchive = useCallback( async() => {
        onActivityStart( true );

        dispatch( archiveAnalysisResults( {ids: [data.id], isArchived: true } ) );
        
    }, [data, dispatch, onActivityStart])

    const handleUpdate = useCallback( () => {
        
        onActivityStart( true );

        try {

            const parameters = JSON.parse( data.paramters );

            dispatch( submitNewAnalyses( [{ 
                userId, 
                methodId: data.method.id, 
                bucketId: project.bucket.id, 
                variables: JSON.stringify( parameters.variables ),
                analysisName: data.name,
                isStared: data.isStared,
                notes: data.notes,
                tags: data.tags
                }] ) 
            );


            handleArchive();

        } catch (error) {

            console.error( error );

        }

    }, [data, dispatch, project, userId, onActivityStart, handleArchive])

    const handleKeystone = useCallback( () => {
        window.open(`http://kayma-horizon.herokuapp.com/admin/analysis-results/${data.id}`, "_blank")
    },[data]);

    const handleToken = useCallback( async () => {
        const response = await Api.AskForAnalysisResultDataToken(data.id);
        if (response.data) {
            if (response.data.status === 'error') {
                setDialogMessage({
                    title: texts.tokenAlert.errorTitle[ lang ],
                    body: response.data.error
                })
            } else {
                setDialogMessage({
                    title: texts.tokenAlert.successTitle[ lang ],
                    body: response.data.token
                })
            }
            setDialogActive(true)
        }
    },[data, lang]);
    
    const dialogDone = () => {
        setDialogActive(false);
    }

    const handleReproduce = useCallback( () => {

        dispatch( analysisReproduced( data.id ) );

    }, [data, dispatch])

	const handleSelection = useCallback( ( op, index ) => {
		switch( op.key ) {

			case optionsTypes.ARCHIVE:

                handleArchive();
                setIsOptionsActive( false );
                break;
                
			case optionsTypes.REPRODUCE:

                handleReproduce();
                setIsOptionsActive( false );
                break;
                
			case optionsTypes.UPDATE:

                handleUpdate();
                setIsOptionsActive( false );
                break;

			case optionsTypes.VIEW_DETAILS:

                setIsDetailsActive( true );
                setIsOptionsActive( false );
                break;
            
            case optionsTypes.KEYSTONE:
                handleKeystone();
                setIsOptionsActive( false );
                break;

            case optionsTypes.TOKEN:
                handleToken();
                setIsOptionsActive( false );
                break;

			default: if( onDone ) onDone(); break;
		}
	}, [handleArchive, handleUpdate, handleReproduce, handleKeystone, handleToken, onDone] )

	return (
		<>
			<div 
				ref={ optionsBtnRef }
				className={ styles.optionsBtnCont } 
				>
				<IconButton
					name="options_horizontal"
                    tooltip={ texts.cardOptions.tooltip[ lang ] }
                    theme="Plain"
					colorSet="Grayscale"
					bgStyle={{minWidth: 32, minHeight: 32}}
					iconStyle={{
                        opacity: showIcon ? 1 : 0.2,
                        width: 18, 
                        height: 18
                    }}
					onClick={ () => setIsOptionsActive( true ) }
				/>
			</div>

			<SelectionPopper
				referenceElement={ optionsBtnRef.current }
				isActive={ isOptionsActive }
				options={ [
					{ key: optionsTypes.VIEW_DETAILS, label: texts.cardOptions.viewDetails[ lang ] },
					{ key: optionsTypes.UPDATE, label: texts.cardOptions.update[ lang ] },
					{ key: optionsTypes.REPRODUCE, label: texts.cardOptions.reproduce[ lang ] },
                    { key: optionsTypes.KEYSTONE, label: texts.cardOptions.keystone[ lang ] },
                    { key: optionsTypes.TOKEN, label: texts.cardOptions.token[ lang ] },
                    { key: optionsTypes.ARCHIVE, label: texts.cardOptions.archive[ lang ] },
				] }
				onDismiss={ () => {
					if( onDone ) onDone();
					setIsOptionsActive( false );
				}}
				onSelect={ handleSelection }
			/>
            
            <AnalysisResultsDetails
                data={ data }
                isActive={ isDetailsActive }
                onClose={ () => setIsDetailsActive( false ) }
            /> 
            
            <AlertDialog
                isActive={dialogActive}
                title={dialogMessage.title}
                message={dialogMessage.body}
                actions={
                    dialogMessage.title === texts.tokenAlert.successTitle[ lang ] ? 
                        [
                            {
                                label: texts.tokenAlert.copyToClipboard[ lang ],
                                callback: () => {
                                    const el = document.createElement('textarea');
                                    el.value = dialogMessage.body;
                                    document.body.appendChild(el);
                                    el.select();
                                    document.execCommand('copy');
                                    document.body.removeChild(el);
                                } 
                            },
                            {
                                label: texts.tokenAlert.closeBtn[lang], 
                                callback: dialogDone 
                            }
                        ] :
                        [
                            {
                                label: texts.tokenAlert.closeBtn[lang], 
                                callback: dialogDone 
                            }
                        ]
                }
                onClose={dialogDone}
            />
		</>

	)
}