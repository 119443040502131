import React, { useCallback, useState } from 'react';
import styles from './TermsLinksEdit.module.css';
import Modal, { ModalPanel } from 'components/poppers/Modal';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import {
    selectPageProperty,
    pageContentChanged,
} from 'features/survey_editor/surveyEditorSlice';
import produce from 'immer';
import TextInput from 'components/TextInput';
import IconButton from 'components/IconButton';
import { surveyLanguages } from 'features/survey_editor/surveyEditorConfiguration';
import { isUrlLegal } from 'utils/validation';

const texts =
    surveyEditorTexts.properties.pageSettings.settingsModules.opening.terms;

const TermsLinksEdit = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const supportedLanguages = useSelector(
        (state) =>
            state.surveyEditor.present.survey.settings.general
                .supportedLanguages
    );

    const currentPage = useSelector(
        (state) => state.surveyEditor.present.display.currentPage
    );
    const termsLinks = useSelector((state) =>
        selectPageProperty(state, currentPage, 'termsLinks')
    );

    const [isActive, setIsActive] = useState(false);

    const getNewItem = useCallback(() => {
        const link = { text: {}, url: '' };
        supportedLanguages?.forEach((l) => {
            link.text[l] = texts.linkLabelPlaceHolder[l];
        });
        return link;
    }, [supportedLanguages]);

    const updateLinks = useCallback(
        (newLinks) => {
            dispatch(
                pageContentChanged({
                    id: currentPage,
                    prop: 'termsLinks',
                    value: newLinks,
                })
            );
        },
        [currentPage, dispatch]
    );

    const handleAddItem = useCallback(() => {
        let links;

        if (!termsLinks) {
            links = [getNewItem()];
        } else {
            links = Array.from(termsLinks);
            links.push(getNewItem());
        }
        console.log(links);
        updateLinks(links);
    }, [termsLinks, updateLinks, getNewItem]);

    const handleDeleteItem = useCallback(
        (i) => {
            const links = Array.from(termsLinks);
            links.splice(i, 1);
            updateLinks(links);
        },
        [termsLinks, updateLinks]
    );

    const handleItemChange = useCallback(
        (i, newData) => {
            const links = Array.from(termsLinks);
            links[i] = newData;
            updateLinks(links);
        },
        [termsLinks, updateLinks]
    );

    return (
        <>
            <Button
                label={texts.editTermsLinks[lang]}
                iconBefore={'link'}
                // size={'s'}
                theme={'Outlined'}
                colorSet={'Field'}
                onClick={() => setIsActive(true)}
            />
            <Modal isActive={isActive} onClose={() => setIsActive(false)}>
                <ModalPanel className={styles.panel} isActive={isActive}>
                    {termsLinks?.map((l, i) => {
                        return (
                            <LinkItem
                                key={'link_item_' + i}
                                index={i}
                                data={l}
                                supportedLanguages={supportedLanguages}
                                lang={lang}
                                onChange={(newData) =>
                                    handleItemChange(i, newData)
                                }
                                onDelete={() => handleDeleteItem(i)}
                            />
                        );
                    })}
                    <div className={styles.footer}>
                        <Button
                            label={texts.addLinkBtn[lang]}
                            iconBefore={'plus'}
                            theme={'Outlined'}
                            onClick={handleAddItem}
                        />
                        <Button
                            label={texts.done[lang]}
                            size={'s'}
                            onClick={() => setIsActive(false)}
                        />
                    </div>
                </ModalPanel>
            </Modal>
        </>
    );
};

const LinkItem = ({
    index,
    data,
    supportedLanguages,
    lang,
    onChange,
    onDelete,
}) => {
    const [warning, setWarning] = useState('');

    const handleUrlChange = useCallback(
        (e) => {
            const value = e.target.value;
            setWarning(isUrlLegal(value) ? '' : 'invalid url');
            const item = produce(data, (draft) => {
                draft.url = value;
            });
            onChange(item);
        },
        [data, onChange]
    );

    const handleText = useCallback(
        (value, l) => {
            const item = produce(data, (draft) => {
                draft.text[l] = value;
            });
            onChange(item);
        },
        [data, onChange]
    );

    return (
        <div className={styles.linkMainCont}>
            <div className={styles.rowCont}>
                <TextInput
                    label={`#${index + 1} ${texts.linkUrlInputLabel[lang]}`}
                    dir={'ltr'}
                    placeholder={'https://webpage.com/'}
                    value={data.url || ''}
                    onChange={handleUrlChange}
                    warning={warning}
                />
                <IconButton
                    name={'delete'}
                    bgStyle={{
                        marginInlineStart: 'var(--space-small)',
                        marginTop: 16,
                    }}
                    tooltip={texts.removeLinktTooltip[lang]}
                    size={'s'}
                    theme={'Plain'}
                    colorSet={'Grayscale'}
                    onClick={onDelete}
                />
            </div>
            <div className={styles.rowCont}>
                {supportedLanguages.map((l) => (
                    <TextInput
                        key={l}
                        label={`${texts.linkTextInput[lang]} (${surveyLanguages[l].name[lang]})`}
                        className={styles.textInput}
                        value={data.text ? data.text[l] : ''}
                        onChange={(e) => handleText(e.target.value, l)}
                    />
                ))}
            </div>
        </div>
    );
};

export default TermsLinksEdit;
