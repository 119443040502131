import { PublishSurvey } from 'api/api';
import { selectProjectById } from 'app/horizonDataSlice';
import { selectUiLanguage } from 'app/preferencesSlice';
import Button from 'components/Button';
import CascadingSelectionPopper from 'components/poppers/CascadingSelectionPopper';
import propGeneralStyles from 'features/survey_editor/properties_area/SurveyEditorProperties.module.css';
import { surveyLanguages } from 'features/survey_editor/surveyEditorConfiguration';
import {
    fetchSurvey,
    serverResyncSurvey,
    surveyVersionUpdated,
} from 'features/survey_editor/surveyEditorSlice';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { surveyEditorTexts } from 'utils/appTexts';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import DownloadBackup from './DownloadBackup';
import RestoreFromFile from './RestoreFromFile';
import SurveyVersions from './SurveyVersions';

const texts = surveyEditorTexts.properties.publishAndExport;

export default function PublishAndExport() {
    const { projectId } = useParams();
    const project = useSelector((state) => selectProjectById(state, projectId));
    const localChangesMade = useSelector(
        (state) => state.surveyEditor.present.localChangesMade
    );
    const remoteSurveyUpdateStatus = useSelector(
        (state) => state.surveyEditor.present.remoteSurveyUpdateStatus
    );
    const lang = useSelector(selectUiLanguage);

    const [isPublishing, setIsPublishing] = useState(false);

    const dispatch = useDispatch();

    const canPublish = useMemo(() => {
        return (
            project &&
            !project.isTemplate &&
            !localChangesMade &&
            remoteSurveyUpdateStatus !== 'loading' &&
            remoteSurveyUpdateStatus !== 'failed'
        );
    }, [project, localChangesMade, remoteSurveyUpdateStatus]);

    const handlePublish = useCallback(async () => {
        setIsPublishing(true);
        if (project && project.sourceName) {
            // Save version date in survey JSON:
            const versionDate = moment(new Date()).format(
                'DD-MM-YYYY_HH-mm-ss'
            );
            dispatch(surveyVersionUpdated(versionDate));

            setTimeout(async () => {
                const result = await PublishSurvey(project.sourceName);
                if (!result.error && result.data) {
                    console.log('Successfully published.');
                    dispatch(serverResyncSurvey(project.survey.id));
                    dispatch(fetchSurvey(project.survey.id));
                }
                setIsPublishing(false);
            }, 2000);
        } else {
            setIsPublishing(false);
        }
    }, [dispatch, project]);

    return (
        <div className={propGeneralStyles.mainCont}>
            <h4 className={propGeneralStyles.modeLabel}>
                {texts.title[lang]}{' '}
            </h4>
            <Button
                label={texts.publishBtn[lang]}
                bgStyle={{ width: '100%' }}
                onClick={handlePublish}
                indicateActivity={isPublishing}
                disabled={!canPublish}
            />
            <GetLink lang={lang} />
            <SurveyVersions lang={lang} />
            <div className={propGeneralStyles.separationLine} />
            <DownloadBackup lang={lang} />
            <RestoreFromFile lang={lang} />
        </div>
    );
}

const GetLink = ({ lang }) => {
    const buttonWrapper = useRef();
    const [isPopperActive, setIsPopperActive] = useState(false);
    const surveyId = useSelector(
        (state) => state.surveyEditor.present.surveyId
    );
    const supportedLanguages = useSelector(
        (state) =>
            state.surveyEditor.present.survey.settings.general
                .supportedLanguages
    );

    const [isCopied, setIsCopied] = useState(false);

    const options = useMemo(() => {
        const liveOptions = {
            key: 'live',
            label: texts.linkOptions.live[lang],
        };

        if (supportedLanguages.length > 1) {
            liveOptions.subOptions = supportedLanguages.map((l) => ({
                key: l,
                label: surveyLanguages[l].name[lang],
                // url: liveUrl + `?lang=${l}`
            }));
        }
        return [
            liveOptions,
            {
                key: 'view_only',
                label: texts.linkOptions.view_only[lang],
            },
        ];
    }, [lang, supportedLanguages]);

    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => setIsCopied(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [isCopied]);

    const handleLinkSelect = useCallback(
        (op) => {
            const urls = {
                live: `https://hrzn.app/${surveyId}`,
                view_only: `https://hrzn.app/view_only/${surveyId}`,
            };

            const key = op.split('.');
            let url = urls[key[0]];

            if (key.length > 1) {
                url += `?lang=${key[1]}`;
            }

            copyTextToClipboard(url);
            setIsPopperActive(false);
            setIsCopied(true);
        },
        [surveyId]
    );

    return (
        <>
            <div ref={buttonWrapper}>
                <Button
                    label={
                        !isCopied
                            ? texts.surveyLinkBtn[lang]
                            : texts.linkCopied[lang]
                    }
                    iconBefore={!isCopied ? 'link' : 'clipboard'}
                    bgStyle={{
                        marginTop: 8,
                        width: '100%',
                    }}
                    theme={!isCopied ? 'Outlined' : 'Plain'}
                    disabled={isCopied}
                    onClick={() => setIsPopperActive(true)}
                />
            </div>

            <CascadingSelectionPopper
                referenceElement={buttonWrapper.current}
                isActive={isPopperActive}
                options={options}
                onDismiss={() => setIsPopperActive(false)}
                onSelect={handleLinkSelect}
            />
        </>
    );
};
