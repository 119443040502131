import React, { useCallback, useMemo } from 'react';
import Checkbox from 'components/Checkbox';
import Modal from './Modal';
import PopperPanel from './PopperPanel';
import moduleStyles  from './MultipleSelectionPopper.module.css';
import ActivityIndicator from 'components/ActivityIndicator';
import { componentsTexts } from 'utils/appTexts';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';

const texts = componentsTexts.multipleSelection;

/**
 * @param {object} referenceElement Node element as a reference for placing the popper.
 * @param {boolean} isActive Is selection popper active?
 * @param {Array} options Options to choose from. Object{ key: "", label: "", isSelected: true | false }
 * @param {function} onSelect On user select option. if options are passed as DOM children, the return value is key. Otherwise it's the opiton.
 * @param {function} onDissmis On cancel selection
 * @param {isLoading} isLoading Show loader
 * @param {string} placement Where to place the box, relative to the referece element.  | 'auto'| 'auto-start'| 'auto-end'| 'top'| 'top-start'| 'top-end'| 'bottom'| 'bottom-start'| 'bottom-end'| 'right'| 'right-start'| 'right-end'| 'left'| 'left-start'| 'left-end';
 */
export default function SelectionPopper({ 
    referenceElement, 
    isActive, 
    options, 
    children,
    onSelect, 
    isLoading,
    onDismiss,
    dir='auto'
}) {
     
    const lang = useSelector( selectUiLanguage );

    const placement = useMemo( () => {

        switch( dir ) {
            case 'ltr': return 'bottom-start';
            case 'rtl': return 'bottom-end';
            default: return ''
        }

    }, [dir])

    const handleSelect = useCallback(( option, index ) => {

        if( onSelect ) {
            onSelect( option, index );
        } else {
            console.error( "onSelect callback is not defined for a SelectionPopper instance.");
        }

    }, [onSelect])

    const handleDismiss = useCallback(() => {

        if( onDismiss ) {
            onDismiss();
        } else {
            console.error( "onDismiss callback is not defined for a SelectionPopper instance.");
        }

    }, [onDismiss]);


    return (
        <Modal 
            isActive={ isActive }
            onClose={ handleDismiss }
            bgBlur={ "low" }
        >
            <PopperPanel referenceElement={ referenceElement } placement={ placement }>
                <div className={moduleStyles.listContainer}>
                    <ul className={moduleStyles.list}>
                        {
                            children ? React.Children.map( children, (child, i) => {
                                if( !child.key ) return null;
                                
                                return (
                                    <ListItem
                                    key = { child.key }
                                    isSelected = { child.props.isSelected }
                                    onSelect = { () => handleSelect( child.key, i ) }
                                    >
                                        { child }
                                    </ListItem>
                                )
                            })
                            :
                            options && options.map( (op,i) => (
                                <ListItem
                                key = { op.key }
                                label = { op.label }
                                isSelected = { op.isSelected }
                                onSelect = { () => handleSelect( op, i ) }
                                />
                                )
                                )
                            }
                            {
                                isLoading &&
                                <div className={ moduleStyles.loadingCont }>
                                    <ActivityIndicator size={ 18 }/>
                                    <div className={ moduleStyles.gap }/>
                                    { texts.loading[ lang ]}
                                </div>
                            }
                    </ul> 
                </div>
            </PopperPanel>

        </Modal>
    )
}

function ListItem({children, label, onSelect, isSelected }) {

    function handleClick( e ) {
        e.stopPropagation();

        onSelect();
    }

    if( children ) {
        return (
            <li className={ moduleStyles.listItem }
                onClick={handleClick}
            >
                <Checkbox
                    size= 's'
                    isChecked={ isSelected }
                />
                <div className={ moduleStyles.gap }/>
                { children }
            </li>
        )
    }
        
    return (
        <li className={ moduleStyles.listItem }
            onClick={handleClick}
        >
            <Checkbox
                size= 's'
                isChecked={ isSelected }
            />
            <div className={ moduleStyles.gap }/>
            {label}
        </li>
    )
}