/**
 * 
 * @param {string} colString Can be either hexadecimal number or a global css property such as "--color-primary-main"
 */
export const GetColor = ( colString ) => {

    if( !colString ) return "#000000";

    const prefix = colString.slice(0,1);

    if( prefix === "#" || colString === "transparent" ) {
        
        return colString;
    }

    if( prefix === "-" ) {

        return `var(${colString})`;
    }

    if( prefix === "r" ) {
        return colString
    }

}

/**
 * @summary Get a global styling value by providing the property name
 * @param {string} propertyName Property name
 */
export const GetValue = ( propertyName ) => {

    const globalStyle = getComputedStyle( document.body );

    return globalStyle.getPropertyValue( propertyName );

}

/**
 * 
 * @param {number} t Time in milliseconds
 */
export const FormatTime = ( t ) => {

    // Pad to 2 or 3 digits, default is 2
    function pad(n, z) {
        z = z || 2;
        return ('00' + n).slice(-z);
    }

    const ms = t % 1000;
    t = (t - ms) / 1000;
    const secs = t % 60;
    t = (t - secs) / 60;
    const mins = t % 60;
    const hrs = (t - mins) / 60;

    return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);

}

/**
 * @summary Recieves language code, returns object with 'direction' and 'align' properties
 */
export const textDirection = ( lang ) => {

    const directions = {
        'en': { direction: 'ltr', align: 'left' },
        'he': { direction: 'rtl', align: 'right' },
        'ar': { direction: 'rtl', align: 'right' }
    }

    return directions[lang];

}