import { variableAdded } from 'features/survey_editor/surveyEditorSlice';
import { useDispatch } from 'react-redux';

const {
    default: RichTextInput,
} = require('components/rich_text/RichTextInput');
const { useState, useEffect } = require('react');

const Dev_RichText = () => {
    const dispatch = useDispatch();
    const [myRichText, setMyRichText] = useState({
        blocks: [
            {
                key: 'x5es4',
                text: 'The following value is 🤚🏾 dynamic: value',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
            },
            {
                key: 'e6ai4',
                text: 'Temp text example',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [
                    {
                        offset: 10,
                        length: 7,
                        style: 'BOLD',
                    },
                ],
                entityRanges: [
                    {
                        offset: 5,
                        length: 4,
                        key: 0,
                    },
                ],
                data: {},
            },
        ],
        entityMap: {
            0: {
                type: 'LINK',
                mutability: 'MUTABLE',
                data: {
                    url: 'https://www.cnn.com',
                },
            },
        },
        lastEdit: 1621871806808,
    });

    useEffect(() => {
        const payload = {
            name: 'value',
            type: 'text',
            value: '9876543210',
        };

        dispatch(variableAdded(payload));
    }, []);

    return (
        <RichTextInput
            value={myRichText}
            placeholder="enter rich text"
            onChange={(v) => {
                // console.log( JSON.stringify(v,null,4) );
                setMyRichText(v);
            }}
        />
    );
};

export default Dev_RichText;
