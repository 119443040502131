import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectProjectById } from 'app/horizonDataSlice';
import {
    displayLanguageChanged,
    pageContentCollapsed,
    sourceNameChanged,
} from 'features/survey_editor/surveyEditorSlice';

import { restoreFromCache } from './surveyEditorSlice';
import { getNested } from 'utils/miscHelpers';

export default function SurveySetup() {
    const { projectId } = useParams();

    const dispatch = useDispatch();
    const project = useSelector((state) => selectProjectById(state, projectId));
    const surveyReady = useSelector((state) => {
        return (
            state.surveyEditor.present.remoteSurveyFetchStatus ===
                'succeeded' &&
            !getNested(
                state,
                'surveyEditor',
                'present',
                'remoteSurveyData',
                'isConnecting'
            )
        );
    });

    useEffect(() => {
        // Use for restoring last editor redux state before page update (see surveyEditorSlice ):
        dispatch(restoreFromCache());
    }, [dispatch]);

    return (
        <>
            <SourceNameHandler
                key={'source_name_handler_' + projectId}
                project={project}
                dispatch={dispatch}
                surveyReady={surveyReady}
            />
            <LanguageHandler
                key={'language_handler_' + projectId}
                dispatch={dispatch}
                surveyReady={surveyReady}
            />
            <PagesContentHandler
                key={'page_items_handler_' + projectId}
                dispatch={dispatch}
                surveyReady={surveyReady}
            />
        </>
    );
}

const SourceNameHandler = ({ project, surveyReady, dispatch }) => {
    const sourceName = useSelector(
        (state) => state.surveyEditor.present.survey.sourceName
    );

    // Assign sourceName
    useEffect(() => {
        // Assign sourceName to the survey if it's empty (probably because the project has just been created).
        if (surveyReady && !sourceName) {
            const newSourceName =
                project?.sourceName ?? `undefined_${Date.now()}`;
            console.log('Assigning sourceName to survey: ', newSourceName);

            dispatch(sourceNameChanged(newSourceName));
        }
    }, [surveyReady, sourceName, project, dispatch]);

    return null;
};

const LanguageHandler = ({ surveyReady, dispatch }) => {
    const supportedLanguages = useSelector(
        (state) =>
            state.surveyEditor.present.survey?.settings?.general
                ?.supportedLanguages
    );
    const [initiated, setInitiated] = useState(false);

    // Set survey initial display language:
    useEffect(() => {
        if (surveyReady && !initiated) {
            dispatch(displayLanguageChanged(supportedLanguages[0]));
            setInitiated(true);
        }
    }, [surveyReady, supportedLanguages, initiated, dispatch]);

    return null;
};

const PagesContentHandler = ({ surveyReady, dispatch }) => {
    const pagesData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData
    );
    const [initiated, setInitiated] = useState(false);

    // Collapse all page items if survey is long:
    useEffect(() => {
        if (surveyReady && !initiated) {
            let shouldCollapse = true;
            if (Object.keys(pagesData).length < 50) {
                shouldCollapse = false;
            }

            dispatch(
                pageContentCollapsed({
                    isCollapsed: shouldCollapse,
                    all: true,
                })
            );
            setInitiated(true);
        }
    }, [surveyReady, initiated, pagesData, dispatch]);

    return null;
};
