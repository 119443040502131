import React, { useCallback, useMemo, useRef, useState } from 'react';
import styles from './SurveyEditorPreview.module.css';
import IconButton from 'components/IconButton';
import PopperPanel from 'components/poppers/PopperPanel';
import Button from 'components/Button';
import Modal from 'components/poppers/Modal';

import { surveyEditorTexts } from 'utils/appTexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { getSurveyClientUrl } from '../survey_services/SurveyClientUrlSolver';
import { previewPanelModes } from './SurveyPreviewFrame';
import { splitViewModes } from '../SurveyEditorMain';
import { displayStateChanged } from '../surveyEditorSlice';
import { modes as propertiesPanelModes } from 'features/survey_editor/properties_area/SurveyEditorProperties';
import { useRouteMatch } from 'react-router-dom';
import { paths } from 'app/App';

const texts = surveyEditorTexts.preview.topBar;

const localUrl = getSurveyClientUrl();
const rootUrl = localUrl;
const milgoSurveyClientUrl =
    process.env.REACT_APP_MILGO_SURVEY_CLIENT_URL ?? 'http://localhost:3006';

export default function SurveyEditorPreviewTopBar() {
    const lang = useSelector(selectUiLanguage);

    return (
        <div className={styles.topBarCont}>
            <DeviceViewOptions lang={lang} />
            <PreviewOptions lang={lang} />
        </div>
    );
}

const DeviceViewOptions = ({ lang }) => {
    const dispatch = useDispatch();

    const setDevice = useCallback(
        (mode) => {
            switch (mode) {
                case previewPanelModes.MOBILE.key:
                    dispatch(
                        displayStateChanged({
                            data: {
                                propertiesMode: propertiesPanelModes.COLLAPSED,
                                splitViewMode: splitViewModes.MOBILE_VIEW,
                                previewPanelMode: previewPanelModes.MOBILE,
                            },
                        })
                    );
                    break;
                case previewPanelModes.DESKTOP.key:
                    dispatch(
                        displayStateChanged({
                            data: {
                                propertiesMode: propertiesPanelModes.COLLAPSED,
                                splitViewMode: splitViewModes.DESKTOP_VIEW,
                                previewPanelMode: previewPanelModes.DESKTOP,
                            },
                        })
                    );
                    break;

                default:
                    break;
            }
        },
        [dispatch]
    );

    return (
        <>
            <IconButton
                name="mobile"
                theme="Plain"
                colorSet="Grayscale"
                tooltip={texts.mobileViewBtnTooltip[lang]}
                onClick={() => setDevice(previewPanelModes.MOBILE.key)}
            />
            <IconButton
                name="desktop"
                theme="Plain"
                colorSet="Grayscale"
                tooltip={texts.desktopViewBtnTooltip[lang]}
                onClick={() => setDevice(previewPanelModes.DESKTOP.key)}
            />
        </>
    );
};

const PreviewOptions = ({ lang }) => {
    const surveyId = useSelector(
        (state) => state.surveyEditor.present.surveyId
    );

    const { path } = useRouteMatch();

    const previewCode = useSelector((state) => {
        if (state.surveyEditor.present.remoteSurveyData) {
            return state.surveyEditor.present.remoteSurveyData.code;
        }

        return null;
    });

    const previewUrl = useMemo(() => {
        if (path === paths.milgo_survey) {
            return milgoSurveyClientUrl + '/milgo-preview/' + surveyId;
        }
        return rootUrl + 'preview/' + surveyId;
    }, [surveyId, path]);
    const previewBtnRef = useRef();
    const [isPoperActive, setIsPoperActive] = useState(false);

    return (
        <>
            <div ref={previewBtnRef} className={styles.previewBtnCont}>
                <IconButton
                    name="preview"
                    theme="Plain"
                    colorSet="Grayscale"
                    tooltip={texts.previewBtnTooltip[lang]}
                    onClick={() => setIsPoperActive(true)}
                />
            </div>

            <Modal
                isActive={isPoperActive}
                onClose={() => setIsPoperActive(false)}
                bgBlur={'low'}
            >
                <PopperPanel
                    referenceElement={previewBtnRef.current}
                    panelStyle={{ padding: 12 }}
                >
                    <Button
                        label={texts.openInNewWindow[lang]}
                        iconAfter="external_link"
                        bgStyle={{ width: '100%', boxSizing: 'border-box' }}
                        onClick={() => {
                            window.open(previewUrl, '_blank');
                            setIsPoperActive(false);
                        }}
                    />
                    <p className={styles.previewCodeLabel}>
                        {texts.previewCodeLabel[lang]}:{' '}
                        <span className={styles.previewCode}>
                            {' '}
                            {previewCode}{' '}
                        </span>
                    </p>
                    <p className={styles.previewCodeLabel}>
                        id:{' '}
                        <span className={styles.previewCode}> {surveyId} </span>
                    </p>
                </PopperPanel>
            </Modal>
        </>
    );
};
