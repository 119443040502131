import React, { useEffect, useMemo } from 'react';

import styles from './Notebook.module.css';
import NotebookHeader from './NotebookHeader';
import NotebookContent from './NotebookContent';
import NotebookToolbars from './NotebookToolbars';
import AttentionBar from 'components/AttentionBar';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectProjectById, fetchProjectById } from '../../app/horizonDataSlice';
import { selectUiLanguage } from 'app/preferencesSlice';
import { notebookTexts, appSectionsTexts } from 'utils/appTexts';

export default function NotebookMain() {
    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const { projectId } = useParams();
    const fetchProjectByIdStatus = useSelector( state => state.horizonData.fetchProjectByIdStatus );
    const project = useSelector( state => selectProjectById( state, projectId ) );
    const pageDirection = useSelector( state => state.notebook.display.pageDirection );

    const isTemplate = useMemo( () => project && project.isTemplate, [project]);

    useEffect(() => {
        if (projectId && !project && fetchProjectByIdStatus !== 'idle') {
            dispatch(fetchProjectById(projectId));
        }
    }, [project, fetchProjectByIdStatus, projectId, dispatch])

    if( !project || project === '404' || !project.notebook ) {
        return null
    };
    
    return (
        <>
            { isTemplate &&
                <AttentionBar
                    label={ appSectionsTexts.previewOnly[ lang ]}
                    icon={ 'read_only' }
                />
            }
            <div 
                className={ styles.mainCont} 
                style={{ top: isTemplate ? 44 : 0 }}
            >
                <div className={ styles.pageCont } dir={pageDirection ? pageDirection : 'auto'}>
                    <NotebookHeader project={ project }/>
                    <NotebookContent/>
                </div>
                <NotebookToolbars project={project}/>
            </div>
            <LockedByAnother/>

        </>
    )
}

const LockedByAnother = () => {

    const lang = useSelector( selectUiLanguage );
    const lockState = useSelector( state => {
    
        if( !state.notebook.remoteData ) return null;

        return ({
            isLocked: state.notebook.remoteData.isLocked,
            editorName: state.notebook.remoteData.editorName
        })
        
    });

    if( lockState && lockState.isLocked ) {
        
        return (
            <div className={ styles.lockContainer }>
                <div className={ styles.lockPanel }>
                    { `${notebookTexts.anotherClientIsEditing[ lang ].replace('{name}', lockState.editorName)} ☕️`  }
                </div>
            </div>
        )

    }

    return null;

}