import React from 'react';
import styles from './Checkbox.module.css';
import Icon from './Icon';

const boxColor = {
    Primary: 'var( --color-primary-medium )',
    Greyscale: 'var( --color-background-light )',
};

const sizes = {
    s: { box: 16, icon: 12 },
    m: { box: 24, icon: 16 },
};

/**
 *
 * @param {{
 * theme, //
 * colorSet, //
 * size, //
 * label, //
 * className, // container class name
 * contStyle, //
 * boxStyle, //
 * labelStyle, //
 * color, //
 * isChecked, //
 * onChange: function, //
 * disabled, //
 * }}
 */
export default function Checkbox({
    theme = 'Outlined',
    colorSet = 'Greyscale',
    size = 's',
    label,
    className,
    contStyle,
    boxStyle,
    labelStyle,
    color,
    isChecked,
    onChange,
    disabled = false,
}) {
    function handleClick() {
        if (onChange) onChange(!isChecked);
    }

    const sharedBoxStyle = {
        width: sizes[size].box,
        height: sizes[size].box,
        minWidth: sizes[size].box,
        minHeight: sizes[size].box,
    };

    const boxStyles = {
        Full: {
            backgroundColor: color ? color : boxColor[colorSet],
            border: 'none',

            ...sharedBoxStyle,
            ...boxStyle,
        },
        Outlined: {
            border: 'var( --border-main )',
            backgroundColor: boxColor[colorSet],

            ...sharedBoxStyle,
            ...boxStyle,
        },
    };

    const contDynamicStyle = {
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? 'none' : 'auto',

        ...contStyle,
    };

    return (
        <div
            className={`${styles.container} ${className}`}
            style={contDynamicStyle}
        >
            <div
                className={styles.box}
                style={boxStyles[theme]}
                onClick={handleClick}
            >
                {isChecked && <Icon name={'vi'} size={sizes[size].icon} />}
            </div>
            {label && (
                <p
                    className={styles.label}
                    style={labelStyle}
                    onClick={handleClick}
                >
                    {label}
                </p>
            )}
        </div>
    );
}
