import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
} from 'react';
import styles from './Button.module.css';
import Icon from './Icon';
import Tooltip from './poppers/Tooltip';
import { isMobile } from 'react-device-detect';
import { GetColor } from '../utils/stylingTools';
import { mergeRefs } from 'utils/mergeRefs';

const themes = {
    Full: {
        normal: { bg: 'main', border: 'none', content: 'overlay' },
        hovered: { bg: 'dark', border: 'none', content: 'overlay' },
        pressed: { bg: 'dark', border: 'none', content: 'light' },
        disabled: { bg: 'light', border: 'none', content: 'main' },
    },
    Outlined: {
        normal: { bg: 'none', border: 'light', content: 'main' },
        hovered: { bg: 'light', border: 'main', content: 'main' },
        pressed: { bg: 'light', border: 'dark', content: 'dark' },
        disabled: { bg: 'none', border: 'light', content: 'main' },
    },
    Plain: {
        normal: { bg: 'none', border: 'none', content: 'main' },
        hovered: { bg: 'light', border: 'none', content: 'main' },
        pressed: { bg: 'light', border: 'none', content: 'dark' },
        disabled: { bg: 'none', border: 'none', content: 'main' },
    },
};

const colorSets = {
    // main, dark, light, none, overlay

    Disabled: {
        none: 'transparent',
        main: '--color-type-low-emphasis',
        dark: '--color-type-medium-emphasis',
        light: '--color-type-disabled',
        overlay: '--color-type-medium-emphasis',
    },
    Primary: {
        none: 'transparent',
        main: '--color-primary-medium',
        dark: '--color-primary-dark',
        light: '--color-primary-light',
        overlay: '--color-onPrimary',
    },
    PrimaryOnDark: {
        none: 'transparent',
        main: '--color-primary-light',
        dark: '--color-primary-extra-light',
        light: '--color-primary-dark',
        overlay: '--color-onPrimary',
    },
    Secondary: {
        none: 'transparent',
        main: '--color-secondary-medium',
        dark: '--color-secondary-dark',
        light: '--color-secondary-light',
        overlay: '--color-onSecondary',
    },
    Error: {
        none: 'transparent',
        main: '--color-error-medium',
        dark: '--color-error-dark',
        light: '--color-error-light',
        overlay: '#FFFFFF',
    },
    CustomColors: {
        none: 'transparent',
        main: '#34de3e',
        dark: '#12ad8d',
        light: '#FeFFab',
        overlay: '#000000',
    },
    Grayscale: {
        none: 'transparent',
        main: 'rgba(0, 0, 0, 0.55)',
        dark: 'rgba(0, 0, 0, 0.8)',
        light: 'rgba(0, 0, 0, 0.24)',
        overlay: '--color-onPrimary',
    },
    White: {
        none: 'transparent',
        main: 'rgba(255, 255, 255, 0.55)',
        dark: 'rgba(255, 255, 255, 0.8)',
        light: 'rgba(255, 255, 255, 0.24)',
        overlay: 'rgba(255, 255, 255, 1)',
    },
    Field: {
        none: 'rgba(255, 255, 255, 1)',
        main: 'rgba(0, 0, 0, 0.55)',
        dark: 'rgba(0, 0, 0, 0.8)',
        light: 'rgba(0, 0, 0, 0.24)',
        overlay: '--color-type-low-emphasis',
    },
};

const sizeProps = {
    xs: {
        fontSize: 10,
        padding: '2px 10px',
        iconSize: 12,
        iconMargin: '0 -1px 0 -1px',
    },
    s: {
        fontSize: 14,
        padding: '4px 12px',
        iconSize: 14,
        iconMargin: '0 -1px 0 -1px',
    },
    m: {
        fontSize: 16,
        padding: '10px 16px',
        iconSize: 16,
        iconMargin: '0 -2px 0 -2px',
    },
    l: {
        fontSize: 24,
        padding: '12px 18px',
        iconSize: 24,
        iconMargin: '0 -4px 0 -4px',
    },
};

/**
 * Modular text button component
 * @param {{
 * label, // Text on button
 * tooltip, // Any text to appear on hover
 * theme, // "Full" | "Outlined" | "Plain"
 * size, // "s" | "m" | "l"
 * tabIndex,
 * colorSet, // See colorSets inside the component
 * iconBefore, // Icon name to appear before the text label.
 * iconAfter, // Icon name to appear after the text label.
 * bgStyle, // Custom background style
 * className, // Custom css class
 * labelStyle, // Custom text style
 * iconStyle, // Custom icon style
 * disabled, // Is the button disabled?
 * onClick, // callback
 * onMouseEnter, // callback
 * onMouseLeave, // callback
 * indicateActivity, // prevent user press and show loading anim
 * }}
 */
const Button = React.forwardRef(
    (
        {
            label,
            tooltip,
            theme = 'Full',
            size = 'm',
            colorSet = 'Primary',
            tabIndex,
            iconBefore,
            iconAfter,
            bgStyle,
            className,
            labelStyle,
            iconStyle,
            disabled = false,
            onClick,
            onMouseEnter,
            onMouseLeave,
            indicateActivity = false,
            imageUrl,
        },
        ref
    ) => {
        const [btnState, setButtonState] = useState('normal'); // normal, hovered, pressed

        const buttonRef = useRef();

        const [bgColor, setBgColor] = useState('transparent');
        const [borderColor, setBorderColor] = useState('transparent');
        const [contentColor, setContentColor] = useState('--color-onPrimary');

        useEffect(() => {
            try {
                if (disabled) {
                    setBgColor(
                        colorSets['Disabled'][themes[theme]['disabled'].bg]
                    );
                    setBorderColor(
                        colorSets['Disabled'][themes[theme]['disabled'].border]
                    );
                    setContentColor(
                        colorSets['Disabled'][themes[theme]['disabled'].content]
                    );
                } else {
                    setBgColor(colorSets[colorSet][themes[theme][btnState].bg]);
                    setBorderColor(
                        colorSets[colorSet][themes[theme][btnState].border]
                    );
                    setContentColor(
                        colorSets[colorSet][themes[theme][btnState].content]
                    );
                }
            } catch (error) {
                console.warn(
                    'Error while assigning Button colors. Details: ' + error
                );
            }
        }, [disabled, btnState, colorSet, theme]);

        const btnStyle = useMemo(
            () => ({
                backgroundColor: GetColor(bgColor),
                borderColor: GetColor(borderColor),
                color: GetColor(contentColor),
                cursor:
                    disabled || indicateActivity ? 'not-allowed' : 'pointer',
                padding: sizeProps[size].padding,
                pointerEvents: indicateActivity ? 'none' : 'auto',
                opacity: indicateActivity ? 0.3 : 1,

                ...bgStyle,
            }),
            [
                size,
                bgStyle,
                bgColor,
                borderColor,
                contentColor,
                disabled,
                indicateActivity,
            ]
        );

        const lblStyle = useMemo(
            () => ({
                fontSize: sizeProps[size].fontSize,

                ...labelStyle,
            }),
            [labelStyle, size]
        );

        const icnStyle = useMemo(
            () => ({
                margin: sizeProps[size].iconMargin,

                ...iconStyle,
            }),
            [iconStyle, size]
        );

        const handleClick = useCallback(
            (e) => {
                e.stopPropagation();

                if (!disabled && !indicateActivity && onClick) onClick();
            },
            [disabled, indicateActivity, onClick]
        );

        const handleHoverIn = useCallback(
            (e) => {
                e.stopPropagation();

                if (isMobile) return;

                setButtonState('hovered');
                if (onMouseEnter) onMouseEnter();
            },
            [onMouseEnter]
        );

        const handleHoverOut = useCallback(
            (e) => {
                e.stopPropagation();

                setButtonState('normal');
                if (onMouseLeave) onMouseLeave();
            },
            [onMouseLeave]
        );

        const handlePressOn = useCallback((e) => {
            e.stopPropagation();
            setButtonState('pressed');
        }, []);

        const handlePressOff = useCallback(
            (e) => {
                e.stopPropagation();
                setButtonState(btnState === 'hovered' ? 'hovered' : 'normal');
            },
            [btnState]
        );

        const handleFocus = useCallback((e) => {
            setButtonState('pressed');
        }, []);

        const handleBlur = useCallback((e) => {
            setButtonState('normal');
        }, []);

        const Gap = useCallback(() => {
            if (!label) return null;
            return <div key="gap" className={styles.gap} />;
        }, [label]);

        return (
            <>
                <button
                    type="button"
                    ref={mergeRefs([buttonRef, ref])}
                    className={`${styles.basic} ${className}`}
                    style={{
                        ...btnStyle,
                        backgroundImage: `url("${imageUrl}")`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                    tabIndex={tabIndex}
                    onClick={handleClick}
                    onMouseEnter={handleHoverIn}
                    onMouseOut={handleHoverOut}
                    onMouseDown={handlePressOn}
                    onMouseUp={handlePressOff}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onTouchStart={handlePressOn}
                    onTouchEnd={handlePressOff}
                >
                    {!imageUrl && iconBefore
                        ? [
                              <Icon
                                  key="icon"
                                  name={iconBefore}
                                  color={contentColor}
                                  size={sizeProps[size].iconSize}
                                  style={icnStyle}
                              />,
                              <Gap key="gap" />,
                          ]
                        : null}

                    {label && (
                        <span className={styles.label} style={lblStyle}>
                            {label}
                        </span>
                    )}

                    {!imageUrl && iconAfter
                        ? [
                              <Gap key="gap" />,
                              <Icon
                                  key="icon"
                                  name={iconAfter}
                                  color={contentColor}
                                  size={sizeProps[size].iconSize}
                                  style={icnStyle}
                              />,
                          ]
                        : null}
                </button>

                {tooltip && (
                    <Tooltip
                        display={btnState === 'hovered'}
                        referenceElement={buttonRef.current}
                        label={tooltip}
                    />
                )}
            </>
        );
    }
);

export default Button;
