import React, { useEffect,useCallback, useRef, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalysesContentQueueSynchronizer from './AnalysesContentQueueSynchronizer';
import AnalysisCard from './analysis_card/AnalysisCard';
import ActivityIndicator from 'components/ActivityIndicator';
import Button from 'components/Button';
import Emoji from 'components/Emoji';
import IconButton from 'components/IconButton';
import { loadMoreAnalysesResults, fetchSingleAnalysisResult,submitNewAnalyses, archiveAnalysisResults, fetchAnalysesResults, displayStateChanged } from './analysesSlice';
import { selectUiLanguage } from 'app/preferencesSlice';
import styles from './Analyses.module.css';
import { analysesTexts } from 'utils/appTexts';
const texts = analysesTexts.contentArea;

export default function AnalysesContent({ project, dir = 'ltr' }) {
    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const results = useSelector(state => state.analyses.results);
    const newResultToFetch = useSelector( state => state.analyses.newResultToFetch );
    const totalResults = useSelector(state => state.analyses.totalResults);
    const loadMoreResultsStatus = useSelector(state => state.analyses.loadMoreResultsStatus);
    const showArchive = useSelector( state => state.analyses.display.showArchive );    
    const showStared = useSelector( state => state.analyses.display.showStared );   
    const [ indicateActivity, setIndicateActivity ] = useState( false );
    const observer = useRef()  

    useEffect(() => {
        if (project && project.bucket && project.bucket.id && results.length === 0 && loadMoreResultsStatus === 'idle') {
            dispatch(loadMoreAnalysesResults({ bucketId: project.bucket.id }));
        }
    }, [project, results, loadMoreResultsStatus, dispatch])

    useEffect( () => {
        if( newResultToFetch ) {
            if( newResultToFetch.length > 1 ) {
                dispatch( fetchAnalysesResults( { bucketId: project.bucket.id, isArchived: false, loadAll: true } ) );
            } else {
                dispatch( fetchSingleAnalysisResult( newResultToFetch[0] ) );
            }
        }
    }, [newResultToFetch, dispatch, project ])

    const lastResultElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            
            if (entries[0].isIntersecting && loadMoreResultsStatus !== 'loading' && results.length < totalResults) {
                dispatch(loadMoreAnalysesResults({ bucketId: project.bucket.id }));
            }
        })
        if (node) observer.current.observe(node)
    }, [loadMoreResultsStatus, dispatch, project, totalResults, results])

    if( loadMoreResultsStatus !== 'idle' && loadMoreResultsStatus !== 'loading' && (!results || results.length === 0 )) {
        console.log("B: " + loadMoreResultsStatus)
        return <div className={ styles.noAnalysesCont }>
                <h2 className={ styles.noAnalysesYet }>{texts.noAnalyses[ lang ] + " "}<Emoji symbol="🧐"/></h2>
            </div>
    }
    console.log("A: " + loadMoreResultsStatus)

    var lastShowingResultIndex = 0
    for (var i=0; i<results.length; i++) {
        if (showArchive) {
            if (results[i].isArchived) {
                lastShowingResultIndex = i
            }
        } else if (showStared) {
            if (results[i].isStared) {
                lastShowingResultIndex = i
            }
        } else {
            if (!results[i].isArchived) {
                lastShowingResultIndex = i
            }
        }
    }
    return (
        <>
            <div className={styles.contentCont} dir={dir}>
                <AnalysesContentQueueSynchronizer/>
                <TopBar 
                    project = {project}
                    analysesResults = {results}
                    onActivityStart={ (isLoading) => setIndicateActivity( isLoading ) }
                />
                <div className={styles.contentItemsCont}>
                    { results && results.map((result, i) => {
                        if (showArchive && !result.isArchived) return null
                        if (!showArchive && result.isArchived) return null
                        if (showStared && !result.isStared) return null

                        if (i === lastShowingResultIndex) {
                            return <AnalysisCard 
                                refToAttech = {lastResultElementRef}
                                key={result.id} 
                                data={result} 
                                project={project} 
                            />
                        } else {
                            return <AnalysisCard 
                                key={result.id} 
                                data={result} 
                                project={project} 
                            />
                        }
                        })
                    }
                    <div>
                        { loadMoreResultsStatus === 'loading' && <div className={styles.loaderCont}> <ActivityIndicator size={ 18 } style={{margin: 'var(--space-large)'}}/> </div>}
                        { loadMoreResultsStatus !== 'loading' && results.length !== totalResults && <div><br/><br/><br/><br/></div> }
                        { !showArchive && loadMoreResultsStatus !== 'loading' && results.length === totalResults && <p style={{textAlign: 'center', margin: "24px"}}><Emoji symbol="🐈"/></p> }
                    </div>
                </div>
            </div>
            { indicateActivity && 
                <div className={ styles.contentActivity }>
                    <ActivityIndicator size={ 24 }/>
                </div>
            }
        </>
    );
}



const TopBar = ({project, analysesResults, onActivityStart }) => {
    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const showArchive = useSelector( state => state.analyses.display.showArchive );    
    const showStared = useSelector( state => state.analyses.display.showStared );    
    const allResultsLoaded = useSelector( state => state.analyses.allResultsLoaded );
    const userId = useSelector( state => state.users.mainUser.id );

    const nonArchivedResults = useMemo( () => {
        if( !analysesResults ) return null;
        const results = [];
        analysesResults.forEach( res => {
            if( !res.isArchived ) {
                results.push( res )
            }
        })
        return results;
    }, [analysesResults]);

    const handleShowArchiveBtn = useCallback(() => {
        dispatch( displayStateChanged( {property: 'showArchive', value: !showArchive } ) );
        dispatch( displayStateChanged( {property: 'showStared', value: false } ) );
    }, [showArchive, dispatch]);

    const toggleShowStared = useCallback( () => {
        dispatch( displayStateChanged( {property: 'showStared', value: !showStared } ) );
    }, [dispatch,showStared])

    const handleArchiveAll = useCallback( async () => {
        onActivityStart( true );
        const resultsToArchive = [];
        analysesResults.forEach( r => {
            if( !r.isArchived ) {
                resultsToArchive.push( r.id )
            }
        })
        dispatch( archiveAnalysisResults( {ids: resultsToArchive, isArchived: true } ) )
            .then( () => onActivityStart( false ) );
    }, [dispatch, onActivityStart, analysesResults]);

    const handleUpdateAll = useCallback(() => {
        try {
            const resultsToUpdate = []
            analysesResults.forEach( r => {

                if( !r.isArchived ) {
                    const parameters = JSON.parse( r.paramters );
                    resultsToUpdate.push ({ 
                        userId, 
                        methodId: r.method.id, 
                        bucketId: project.bucket.id, 
                        variables: JSON.stringify( parameters.variables ),
                        analysisName: r.name,
                        isStared: r.isStared,
                        notes: r.notes
                    })
                }
            })

            dispatch( submitNewAnalyses( resultsToUpdate ) );
            handleArchiveAll();
        } catch (error) {
            console.error( error );
        }
    }, [dispatch, analysesResults, userId, handleArchiveAll, project]);
    
    if (analysesResults.length === 0) return <div></div>
    return (
        <div className={ styles.contentTopBar }>
            <div style={{display:'flex'}}>
                {
                    <>
                     {  !showArchive && !showStared &&
                        <Button
                            label={ 
                                allResultsLoaded ? 
                                texts.updateAll[ lang ] : 
                                texts.updateSome[ lang ].replace( "{n}", nonArchivedResults.length ) 
                            }
                            theme='Plain'
                            iconBefore='refresh'
                            onClick={ handleUpdateAll }
                        />
                    }
                        <div style={{width:6}}/>
                    {  !showArchive && !showStared &&
                        <Button
                            label={ 
                                allResultsLoaded ? 
                                texts.archiveAll[ lang ] :
                                texts.archiveSome[ lang ].replace( "{n}", nonArchivedResults.length ) 
                            }
                            theme='Plain'
                            iconBefore='archive'
                            onClick={ handleArchiveAll }
                        />
                    }
                    </>
                }
            </div>

            <div style={{display:'flex'}}>
                {  !showArchive &&
                    <IconButton
                        name={ showStared ? 'star_full' : 'star_outline' }
                        tooltip={ texts.showStaredBtnTooltip[lang] }
                        theme='Plain'
                        colorSet='Grayscale'
                        onClick={ toggleShowStared }
                    />
                }
                <div style={{width:6}}/>
                <Button
                    label={ texts.showArchiveBtn[ lang ] }
                    theme={ showArchive ? 'Full' : 'Plain' }
                    colorSet='Grayscale'
                    iconBefore={ showArchive ? 'x' : '' }
                    onClick={ handleShowArchiveBtn }
                />
            </div>
        </div>
    )
}