import React from "react";
import styles from "./LogicStyles.module.css";
import propGeneralStyles from "features/survey_editor/properties_area/SurveyEditorProperties.module.css";
import VariableItem from "./VariableItem";
import { useSelector } from "react-redux";
import { selectUiLanguage } from "app/preferencesSlice";
import { surveyEditorTexts } from "utils/appTexts";

const texts = surveyEditorTexts.properties.logic;

export default function VariablesList() {
    const lang = useSelector(selectUiLanguage);
    const variables = useSelector(
        (state) => state.surveyEditor.present.survey.logic.variables
    );

    return (
        <div className={styles.variablesListCont}>
            <h4 className={propGeneralStyles.modeLabel}>
                {texts.variablesTitle[lang]}
            </h4>
            {variables.map((v) => (
                <VariableItem key={v.id} varData={v} />
            ))}
            <VariableItem varData={{ id: "ADD_ITEM" }} />
        </div>
    );
}
