import React from 'react';
import propsStyles from '../SurveyEditorProperties.module.css';
import Checkbox from 'components/Checkbox';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';


import { surveyEditorTexts } from 'utils/appTexts';

const generalTexts = surveyEditorTexts.properties.pageSettings;
// const texts = generalTexts.settingsModules.openEnded;

export default function OpenEndedSettingsModule({settings, updateSettings}) {

    const lang = useSelector( selectUiLanguage );


    if( !settings ) return null;
    
    const generalSettings = settings.general;
    const { showSubtitle } = generalSettings;


    return (
        <>
            <Checkbox
                size='s'
                className={ propsStyles.checkbox }
                label={ generalTexts.showSubtitleLabel[ lang ] }
                isChecked={ showSubtitle }
                onChange={ isChecked => updateSettings( {prop: 'showSubtitle', value: isChecked} ) }
            />
        </>
    )
}