import React from 'react';
import { useSelector } from 'react-redux';
import { appSectionsTexts } from '../../utils/appTexts';
import { selectUiLanguage } from '../../app/preferencesSlice';

export default function Distribution(props) {

    const lang = useSelector( selectUiLanguage );

    return (
        <div id="top_bar">
            <h2>{ appSectionsTexts.distribution[ lang ] }</h2>
        </div>
    )
}