import React, { useCallback, useMemo, useState } from 'react';
import styles from './PageLogicPhase.module.css';
import CollapseButton from 'components/CollapseButton';
import Icon from 'components/Icon';
import CreateNewLogicItem from './CreateNewLogicItem';
import LogicPhaseListItem from './LogicPhaseListItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { surveyEditorTexts } from 'utils/appTexts';
import { selectUiLanguage } from 'app/preferencesSlice';
import { pageLogicPhases } from './LogicConfiguration';
import { useDispatch, useSelector } from 'react-redux';
import { displayStateChanged, logicItemAddedToPage, logicItemReordered, selectPageProperty } from '../surveyEditorSlice';
import { splitViewModes } from '../SurveyEditorMain';


const texts = surveyEditorTexts.properties.logic;

export default function PageLogicPhase({phase, pageId}) {
    
    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const logicItems = useSelector( state => {
        const logic = selectPageProperty( state, pageId, 'logic' );
        if( !logic ) return null;
        return logic[ phase ];
    })
    const logicItemsData = useSelector( state => state.surveyEditor.present.survey.logic.logicItemsData );
    const pagesData = useSelector( state => state.surveyEditor.present.survey.content.pagesData );

    const {reorderableItems, navigationItems} = useMemo( () => {

        const reorderable = [];
        const navigation = [];

        if( logicItems ) {
            logicItems.forEach( itemId => {
                if( logicItemsData[ itemId ].isNavigationLogic ) {
                    navigation.push( itemId );
                } else {
                    reorderable.push( itemId )
                }
            })
        }
        
        return { reorderableItems: reorderable, navigationItems: navigation }

    }, [logicItems, logicItemsData] )


    const handleNewItem = useCallback( (title) => {

        dispatch( logicItemAddedToPage({
            pageId,
            phase: phase,
            condition: { isAlways: true, rules: { 'and': [] }, args: {} },
            title,
        }))

        dispatch( displayStateChanged({ property: "splitViewMode", value: splitViewModes.EXTENDED_PROPERTIES }))

    }, [dispatch, pageId, phase])

    const handleDragEnd = useCallback( (result) => {
        if (!result.destination) {
            return;
          }
      
        dispatch( logicItemReordered( {
            pageId,
            phase,
            sourceIndex: result.source.index,
            destinationIndex: result.destination.index,
        }))

    }, [dispatch, pageId, phase] )

    const [isCollapsed, setIsCollpsed] = useState( false );
    return (
        <div className={ styles.logicPhaseCont}>
            <div className={ styles.phaseTitleCont } onClick={ () => setIsCollpsed( !isCollapsed ) }>
                <CollapseButton
                    isCollapsed={ isCollapsed }
                    onToggle={ () => setIsCollpsed( !isCollapsed ) }
                />
                <div className={ styles.inoutIcon}>
                    <Icon
                        name={ phase === pageLogicPhases.ON_PAGE_ENTER ? 'on_enter' : 'on_exit' }
                        size={24}
                    />
                </div>
                <h3>{ texts.pageLogicPhases[ phase ][ lang ]}</h3>
            </div>
            {
                !isCollapsed &&
                <>
                    <DragDropContext onDragEnd={ handleDragEnd }>
                        <Droppable droppableId={ phase + '_logicItems'}>
                        { (provided) => (
                            <div 
                                {...provided.droppableProps}
                                ref={ provided.innerRef }
                                className={ styles.itemsCont }
                            >

                                {
                                    reorderableItems.map( (id,i)=> {
                                        return( 
                                            <LogicPhaseListItem 
                                                key={ id }
                                                index={ i }
                                                lang={ lang }
                                                itemId={ id }
                                                item={ logicItemsData[ id ] }
                                            />
                                        )
                                    })
                                }
                                {provided.placeholder}

                            </div>
                        )}
                        </Droppable>
                    </DragDropContext>
                    <CreateNewLogicItem lang={ lang } onCreate={ handleNewItem }/>
                    {
                        navigationItems.length > 0 &&
                        <>
                            <p className={ styles.navigationItemsTitle}>
                                { texts.navigationItemsTite[ lang ] }
                            </p>
                            {
                                navigationItems.map( (id,i)=> {
                                    const { sourcePage, sourcePageItem, targetPage} = logicItemsData[ id ].navigationData;
                                    const pageData = pagesData[ sourcePage ];
                                    const itemKey = pageData.itemsData[ sourcePageItem ].key;
                                    const targetPageKey = pagesData[ targetPage ].key;

                                    return( 
                                        <div key={ id } className={ styles.navigationItem }>
                                            <Icon   
                                                name='goto'
                                                color='--color-type-low-emphasize'
                                                size={ 16 }
                                                style={{
                                                    marginInlineEnd: 8,
                                                }}
                                            />
                                            { lang === 'en' ?
                                                `${itemKey} → ${targetPageKey}`
                                                :
                                                `${targetPageKey} ← ${itemKey}`
                                            }
                                        </div>
                                    )
                                })
                            }
                        </>
                    }
                </>
            }

        </div>
    )
}
