import React, { useCallback, useEffect, useState } from 'react';
import styles from './MediaLibrary.module.css';
import Modal, { ModalPanel } from 'components/poppers/Modal';
import MediaLibraryTopBar from './MediaLibraryTopBar';
import MediaLibraryThumbnails from './MediaLibraryThumbnails';
import MediaLibraryAddNew from './MediaLibraryAddNew';
import { AlertDialog } from 'components/poppers/Dialog';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { useSafeToRemoveMedia, useRemoveMediaFromAllPages } from './mediaUtils';
import { componentsTexts } from 'utils/appTexts';
import produce from 'immer';

const texts = componentsTexts.mediaLibrary;
/*
type MediaFile = {
    id:string,
    type = "image" |  "lottie", // "video" will be added in the future...
    src:string = "",
    name:string = "",
    date:number = 1640536245006,
    alt:{he:string, en:string, ar:string}
}
*/

/**
 *
 * @param {
 * storageId: string; // storage id is used as the destination folder where the media is being stored
 * mediaFiles: MediaFile[];
 * mediaFilesArchive: MediaFile[];
 * isActive: boolean;
 * onChange: ({files, archive} : {files: MediaType[], archive: MediaType[]}) => void;
 * onSelection: (files: MediaType[]) => void;
 * onClose: () => void;
 * } props
 */
const MediaLibrary = ({
    storageId,
    mediaFiles,
    mediaFilesArchive,
    isActive,
    onChange,
    onSelection,
    onClose,
}) => {
    const lang = useSelector(selectUiLanguage);
    const [showArchive, setShowArchive] = useState(false);
    const [selected, setSelected] = useState([]);

    const [alert, setAlert] = useState(null);
    const isSafeToRemove = useSafeToRemoveMedia();
    const removeMediaFromAllPages = useRemoveMediaFromAllPages();

    useEffect(() => {
        // Reset selction on activation:
        if (isActive) setSelected([]);
    }, [isActive]);

    const handleAdd = useCallback(
        (media) => {
            const newFiles = mediaFiles ? Array.from(mediaFiles) : [];
            newFiles.push(media);
            if (onChange) onChange({ files: newFiles });
        },
        [mediaFiles, onChange]
    );

    /**
     * ids: string[]
     * action: "archive" | "restore" | "delete"
     */
    const handleFilesChange = useCallback(
        (ids, action, payload) => {
            if (!onChange) {
                console.error(
                    "Trying to change files in the MediaLibrary but 'onChange' handler hasn't been provided."
                );
                return;
            }

            if (!mediaFiles) {
                console.error(
                    "Trying to change files in the MediaLibrary but 'mediaFiles' is undefined."
                );
                return;
            }
            let files = Array.from(mediaFiles || []);
            let archive = Array.from(mediaFilesArchive || []);
            ids.forEach((id) => {
                let inx, file;
                switch (action) {
                    case 'archive':
                        const isSafe = isSafeToRemove(id);
                        if (!isSafe) {
                            console.log('TODO: alert not safe');
                            setAlert({
                                title: texts.notSafeToRemoveAlert.title[lang],
                                message:
                                    texts.notSafeToRemoveAlert.message[lang],
                                actions: [
                                    {
                                        label: texts.notSafeToRemoveAlert
                                            .actionRemoveAll[lang],
                                        callback: () => {
                                            setAlert(null);
                                            removeMediaFromAllPages(id);
                                        },
                                    },
                                    {
                                        label: texts.notSafeToRemoveAlert
                                            .actionCancel[lang],
                                        callback: () => setAlert(null),
                                        theme: 'Full',
                                    },
                                ],
                            });
                            return;
                        }

                        inx = files.findIndex((f) => f.id === id);
                        if (inx === -1) return;
                        file = files[inx];
                        archive.push(file);
                        files.splice(inx, 1);
                        break;
                    case 'restore':
                        inx = archive.findIndex((f) => f.id === id);
                        if (inx === -1) return;
                        file = archive[inx];
                        files.push(file);
                        archive.splice(inx, 1);
                        break;
                    case 'delete':
                        inx = archive.findIndex((f) => f.id === id);
                        if (inx === -1) return;
                        archive.splice(inx, 1);
                        break;
                    case 'set_alt':
                        // payload: {lang, value}
                        inx = files.findIndex((f) => f.id === id);
                        if (inx === -1) return;
                        files = produce(files, (draft) => {
                            file = draft[inx];
                            const alt = file.alt ? { ...file.alt } : {};
                            alt[payload.lang] = payload.value;
                            draft[inx].alt = alt;
                        });
                        break;
                    default:
                        break;
                }
            });

            onChange({ files, archive });
            if (archive.length === 0) setShowArchive(false);
        },
        [
            lang,
            mediaFiles,
            mediaFilesArchive,
            onChange,
            isSafeToRemove,
            removeMediaFromAllPages,
        ]
    );

    const handleSelection = useCallback(
        (id) => {
            if (!id) {
                setSelected([]);
                return;
            }
            const inx = selected.findIndex((sid) => sid === id);
            const newSelection = Array.from(selected);
            if (inx > -1) {
                newSelection.splice(inx, 1);
            } else {
                newSelection.push(id);
            }
            setSelected(newSelection);
            if (onSelection) onSelection(newSelection);
            return;
        },
        [selected, onSelection]
    );

    const onMultipleEditAction = useCallback(
        (ids, action) => {
            setSelected([]);
            handleFilesChange(ids, action);
        },
        [handleFilesChange]
    );

    return (
        <>
            <Modal isActive={isActive} onClose={onClose}>
                <ModalPanel className={styles.mainPanel}>
                    <MediaLibraryTopBar
                        lang={lang}
                        hasArchive={
                            mediaFilesArchive && mediaFilesArchive.length > 0
                        }
                        showArchive={showArchive}
                        selected={selected}
                        onAction={onMultipleEditAction}
                        onArchive={() => setShowArchive(!showArchive)}
                    />
                    <MediaLibraryThumbnails
                        lang={lang}
                        storageId={storageId}
                        mediaFiles={
                            showArchive ? mediaFilesArchive : mediaFiles
                        }
                        archiveMode={showArchive}
                        selected={selected}
                        onFilesChange={handleFilesChange}
                        onSelection={handleSelection}
                        onFileDropUpload={handleAdd}
                    />
                    <MediaLibraryAddNew
                        lang={lang}
                        storageId={storageId}
                        onAdd={handleAdd}
                    />
                </ModalPanel>
            </Modal>
            <AlertDialog
                isActive={alert !== null}
                title={alert?.title}
                message={alert?.message}
                actions={alert?.actions}
                onClose={() => setAlert(null)}
            />
        </>
    );
};

export default MediaLibrary;
