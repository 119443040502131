import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styles from  './AnalysisCard.module.css';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Dialog from 'components/poppers/Dialog';
import DropDown from 'components/DropDown';
import { analysesTexts } from 'utils/appTexts';
import { getText } from 'utils/serverFileReader';

const texts = analysesTexts.cardTexts;

export default function AnalysisCardOutput ({data, lang}) {

    const [currentOutput, setCurrentOutput] = useState( null );

    const outputs = useMemo( () => {

        if( data && data.outputs ) {
            const outputs = JSON.parse( data.outputs );
            if( Object.keys( outputs ).length > 0 ) {

                setCurrentOutput( Object.keys( outputs )[0] );

            }
            return outputs;
        }

        return null;

    }, [data])

    const options = useMemo( () => {
        
        if( outputs ) {

            const options = Object.keys( outputs ).map( key => ({ key, label: key }) );
            return options;
        }

        return [];

    }, [outputs] )

    
    /////////////////
    // INNER COMPS //
    /////////////////

    const ArrowBtn = useCallback( ({type, onClick}) => {

        const isLtr = lang === 'en';
        let dir = 'right';
        if( (type === 'next' && !isLtr ) || (type === 'prev' && isLtr)) {
            dir = 'left'
        }

        return (
            <IconButton name={ `chevron_${dir}_arrow` } theme='Outlined' size='s' onClick={ onClick }/>
        )

    }, [lang])

    const handleArrow = useCallback (type => {

        if( currentOutput && options ) {
            let index = 0;

            for( let i=0; i < options.length; i++ ) {

                if( options[i].key === currentOutput ) {
                    index = i; 
                    break;   
                }
            }

            index += (type === 'next' ) ? 1 : -1;
            if( index < 0 ) index = options.length -1;
            else index %= options.length;

            setCurrentOutput( options[index].key );
        }

    }, [currentOutput, options])


    ////////////
    // RENDER //
    ////////////

    if( data.status !== 'finished' && data.status !== 'error' ) {
        return (
            <div className={ styles.cardOutputCont }>
                <p className={ styles.cardStatus }>{ texts.processingOutput[ lang ]}</p>
            </div>
        )
    }

    if( data.status === 'error' ) {
        var errorMsg = texts.statusError[ lang ]
        var methodErrors = data.log.match(/<ERROR_MSG>(.*?)<\/ERROR_MSG>/g)

        if (methodErrors) {
            try {
                const errorDictStr = '' + methodErrors[0].replace(/<\/?ERROR_MSG>/g,'').replace(/\\"/g,'"')
                const errorDict = JSON.parse(`${errorDictStr}`)
                if (errorDict[lang]) {
                    errorMsg = errorDict[lang]
                    
                }
            } catch {}
        }
        return (
            <div className={ styles.cardOutputCont }>
                <div className={ styles.cardStatusError }>{ errorMsg.split('\n').map(pr => <p key={pr}>{pr}</p>) }</div>
            </div>
        )
    }

    if( !outputs || Object.keys( outputs ).length < 1 ) {
        return null;
    }

    if( Object.keys( outputs ).length === 1 ) {
        
        return (
            <div className={ styles.cardOutputCont }>
                <div className={ styles.cardOutputMenuCont }>
                    <div className={ styles.cardFieldLabel } style={{minWidth:'unset', maxWidth:'fit-content'}}> 
                        { `${texts.outputs[ lang ]}` } 
                    </div>
                </div>
                <div className={ styles.cardOutputFileCont }>
                    <OutputFile 
                        key={ currentOutput } 
                        data={ data } 
                        lang={ lang } 
                        output={ { key: currentOutput, ...outputs[ currentOutput ] } }
                        currentOutput={ currentOutput }
                    />
                </div>
            </div>
        );
    }
    
    return (
        <div className={ styles.cardOutputCont }>
            <div className={ styles.cardOutputMenuCont }>
                <div className={ styles.cardFieldLabel } style={{minWidth:'unset', maxWidth:'fit-content'}}> 
                    { `${texts.outputs[ lang ]}` } 
                </div>
                <DropDown 
                    theme='Plain' 
                    className={ styles.chooseOutputDropdown }
                    options={ options }
                    value={ currentOutput }
                    onChange={ (op) => setCurrentOutput( op.key ) }
                />
                <div className={ styles.outputArrowsCont }>
                    <ArrowBtn type='prev' onClick={ () => handleArrow( 'prev') }/>
                    <ArrowBtn type='next' onClick={ () => handleArrow( 'next') }/>
                </div>
            </div>
            <div className={ styles.cardOutputFileCont }>
                {
                    Object.keys( outputs ).map( k => (
                        <OutputFile 
                            key={ k } 
                            data={ data } 
                            lang={ lang } 
                            output={ { key: k, ...outputs[ k ] } }
                            currentOutput={ currentOutput }
                        />
                    ))
                }
            </div>
        </div>
    )
}

const OutputFile = ({data, lang, output, currentOutput}) => {

    const [isHovered, setIsHovered] = useState( false );
    const [isImgFullsize, setIsImgFullsize] = useState( false );

    const getMedia = useCallback( () => {

        switch( output.type ) {
            case 'png':
            case 'jpg': 
            case 'svg':
                return (
                    <>
                        {
                            isHovered &&
                            <IconButton
                                name='zoom_in'
                                theme='Outlined'
                                bgStyle={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 'calc( 100% - 38px )',  left: 'calc( 100% - 38px )'
                                }}
                                onClick={ () => setIsImgFullsize( true ) }
                            />
                        }

                        <img 
                            className={ styles.cardImage }
                            src={ output.url }
                            alt='analysis_graph'
                        />


                        <Dialog
                            isActive={ isImgFullsize }
                            onClose={ () => setIsImgFullsize( false ) }
                        >
                            <div 
                                className={ styles.fullsizeImageCont }
                            >
                                <img 
                                    className={ styles.cardImage }
                                    src={ output.url }
                                    alt='analysis_graph'
                                />
                            </div>
                        </Dialog>
                        
                    </>
                )
            case 'csv':
                return (
            
                    <Button
                        label={ texts.getBtn[ lang ] + " csv" }
                        theme="Outlined"
                        iconAfter="download"
                        onClick={ () => window.open( output.url,'_blank') }
                    />
            
                )
            case 'json':
                return (
                    <Button
                        label={ texts.getBtn[ lang ] + " json" }
                        theme="Outlined"
                        iconAfter="download"
                        onClick={ () => window.open( output.url,'_blank') }
                    />
                )
            case 'html':
            case 'pdf':
                return (
                    <Button
                        label={ `${texts.viewBtn[ lang ]} ${output.type}` }
                        theme="Outlined"
                        iconAfter={ lang === 'he' ? 'left_arrow' : 'right_arrow' }
                        onClick={ () => window.open( output.url,'_blank') }
                    />

                )
            case 'num':
            case 'txt':
                return <AsyncTextDisplay output={ output }/>
            default: return <p>{output.type}</p>;
        }
    }, [output,lang, isHovered, isImgFullsize])

    return (
        <div 
            style={{display: currentOutput === output.key ? 'unset' : 'none' }}
            onMouseEnter={ () => setIsHovered( true ) }
            onMouseLeave={ () => setIsHovered( false ) }
        >
            { getMedia() }
        </div>
    )
}

const AsyncTextDisplay = ( {output} ) => {

    const [text, setText] = useState( null );

    useEffect( () => {
        
        getText( output.url, ({err,result}) => {
            if( !err && result ) {
                setText( result );
            };
        });

    }, [output])

    if( !text ) {
        return (
            <h2 className={ styles.cardOutputText }>...</h2>
        )
    }

    return <h2 className={ styles.cardOutputText }>{ text }</h2>
}