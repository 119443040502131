import React from 'react';
import Checkbox from 'components/Checkbox'

export default function CheckboxFieldModule ({data, value, handleChange, lang}) {

    return (
        <Checkbox
            label={ data.label ? data.label[ lang ] : '' }
            isChecked={ value }
            size='s'
            labelStyle={{ 
                // font: 'var( --font-body-small )', 
                color: 'var( --color-type-high-emphasis )'
            }}
            onChange={(op) => {  
                handleChange(op)
            } }
        />
    )
}