import React, { useMemo, useCallback, useState } from 'react';

import { surveyEditorTexts } from 'utils/appTexts';
import IconButton from 'components/IconButton';
import Checkbox from 'components/Checkbox';
import DropDown from 'components/DropDown';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { surveySettingsChanged } from '../../surveyEditorSlice';
import { AlertDialog } from 'components/poppers/Dialog';
const texts = surveyEditorTexts.properties.surveySettings;

const FillingOptions = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const fillingLimit = useSelector(
        (state) =>
            state.surveyEditor.present.survey.settings.general.fillingLimit
    );
    const anonymousFilling = useSelector(
        (state) =>
            state.surveyEditor.present.survey.settings.general.anonymousFilling
    );

    const [isHelpActive, setIsHelpActive] = useState(false);

    const fillingOptions = useMemo(() => {
        const lables = texts.fillingOptions;

        return [
            { key: '-2', label: lables.noLimitClearRecord[lang] },
            { key: '-1', label: lables.noLimit[lang] },
            { key: '0', label: lables.onlyOnce[lang] },
            { key: '1', label: lables.onceADay[lang] },
            { key: '7', label: lables.onceAWeek[lang] },
            { key: '30', label: lables.onceAMonth[lang] },
            { key: '365', label: lables.onceAYear[lang] },
        ];
    }, [lang]);

    const handleFillingOptionsChange = useCallback(
        (op) => {
            dispatch(
                surveySettingsChanged({ prop: 'fillingLimit', value: op.key })
            );
        },
        [dispatch]
    );

    const handleAnonymousFilling = useCallback(
        (isChecked) => {
            dispatch(
                surveySettingsChanged({
                    prop: 'anonymousFilling',
                    value: isChecked,
                })
            );

            if (isChecked) {
                // Anonymous filling forces 'fillingLimit' to be 'no limit + clear record'.
                dispatch(
                    surveySettingsChanged({
                        prop: 'fillingLimit',
                        value: '-2',
                    })
                );
            }
        },
        [dispatch]
    );

    return (
        <>
            <IconButton
                name="help"
                theme="Plain"
                size="s"
                bgStyle={{
                    marginInlineStart: 'calc( 100% - 24px)',
                    marginBottom: -4,
                }}
                tooltip={texts.fillingOptions.help.helpTooltip[lang]}
                onClick={() => setIsHelpActive(true)}
            />
            <DropDown
                label={texts.fillingOptions.limitationLabel[lang]}
                options={fillingOptions}
                value={fillingLimit}
                onChange={handleFillingOptionsChange}
                disabled={anonymousFilling}
            />
            <Checkbox
                label={texts.fillingOptions.anonymousFilling[lang]}
                contStyle={{ marginTop: 16 }}
                isChecked={anonymousFilling}
                onChange={handleAnonymousFilling}
            />

            <AlertDialog
                isActive={isHelpActive}
                title={texts.fillingOptions.help.title[lang]}
                actions={[
                    {
                        label: texts.fillingOptions.help.gotItAction[lang],
                        theme: 'Plain',
                    },
                ]}
                onClose={() => setIsHelpActive(false)}
            >
                <div style={{ maxWidth: 400 }}>
                    <p>{texts.fillingOptions.help.preface[lang]}</p>
                    <br />
                    <p>
                        <strong>{texts.fillingOptions.noLimit[lang]}</strong>
                        {` - `}
                        <span>
                            {texts.fillingOptions.help.noLimitExplanation[lang]}
                        </span>
                    </p>
                    <br />
                    <p>
                        <strong>
                            {texts.fillingOptions.noLimitClearRecord[lang]}
                        </strong>
                        {` - `}
                        <span>
                            {
                                texts.fillingOptions.help
                                    .noLimitClearRecordExplanation[lang]
                            }
                        </span>
                    </p>
                    <br />
                    <p>
                        <strong>
                            {texts.fillingOptions.help.timeLimitation[lang]}
                        </strong>
                        {` - `}
                        <span>
                            {
                                texts.fillingOptions.help
                                    .timeLimitationExplanation[lang]
                            }
                        </span>
                    </p>
                </div>
            </AlertDialog>
        </>
    );
};

export default FillingOptions;
