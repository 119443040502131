import React from 'react';
import styles from './SurveyEditorPreview.module.css';
import SurveyEditorPreviewTopBar from './SurveyEditorPreviewTopBar';
import SurveyPreviewFrame from './SurveyPreviewFrame';

export default function SurveyEditorPreview() {

    return (
        <div className={ styles.mainCont }>
            <SurveyEditorPreviewTopBar/>
            <SurveyPreviewFrame/>
        </div>
    )
}