import React, { useState, useRef, useCallback, useMemo } from 'react'
import styles from './NotebookSection.module.css';
import IconButton from 'components/IconButton';
import SelectionPopper from 'components/poppers/SelectionPopper';
import { useDispatch, useSelector } from 'react-redux';
import { sectionDeleted } from 'features/notebook/notebookSlice';
import { notebookTexts } from 'utils/appTexts';
import { selectUiLanguage } from 'app/preferencesSlice';

const texts = notebookTexts.content.section.options;

const optionsTypes = {
	DELETE: "delete"
}

export default function SectionOptions ({id, onDone, display }) {

	const optionsBtnRef = useRef();
    const lang = useSelector( selectUiLanguage );
	const [isOptionsActive, setIsOptionsActive] = useState( false );

	const dispatch = useDispatch();

    const options = useMemo( () => {

        return [
            { 
                key: optionsTypes.DELETE, 
                label: texts.delete[ lang ],
                callback: () => {
                    console.log( "@TODO: allow to undo deletion" );
                    dispatch( sectionDeleted( id ) )
                } 
            },
        ]

    }, [id, dispatch, lang])

	const handleSelection = useCallback( ( op, index ) => {

        op.callback();
        if( onDone ) onDone();

	}, [onDone]);

	return (
		<>
			<div 
				ref={ optionsBtnRef }
				className={ styles.optionsBtnCont } 
				style={{opacity: display ? 1 : 0 }}
				>
				<IconButton
					name="options_horizontal"
					theme="Plain"
					colorSet="Grayscale"
					bgStyle={{minWidth: 32, minHeight: 32}}
					iconStyle={{width: 18, height: 18}}
					onClick={ () => setIsOptionsActive( true ) }
					tooltip={ texts.btnTooltip[ lang ] }
				/>
			</div>

			<SelectionPopper
				referenceElement={ optionsBtnRef.current }
				isActive={ isOptionsActive }
				options={ options }
				onDismiss={ () => {
					setIsOptionsActive( false );
					if( onDone ) onDone();
				}}
				onSelect={ handleSelection }
			/>

		</>

	)
}