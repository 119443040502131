/**
 * Convert CSV to Javascript Object
 * @param {{
 * }} content 
 * @returns result = { error: "", data: {} }
 */
export function csvToObj( content ) {

    const result = { error: null, data: null };

    try {
        const lines = content.split( '\n' );
        const colNames = lines[0].split(',').map( k => k.toLowerCase().trim() );
        const data = [];

        for( let i=1; i < lines.length; i++ ) {
            const lineContent = lines[i].split(',');
            const lineData = {};
            lineData.text = {};

            for( let j=0; j < lineContent.length; j++ ) {

                if( j === 0 ) {
                    // At the first place should be a key identifier.
                    // Cleanup illegal characters:
                    let key = lineContent[j];
                    key = key.toLowerCase().trim();
                    key = key.replace(/[\s-]/g, "_" );
                    key = key.replace( /[^a-z0-9_]/g, "" );
                    key = key.replace( /___/g, "_" );

                    lineData[ colNames[j] ] = key;

                } else {

                    lineData.text[ colNames[j] ] = lineContent[j];
                }
            }
            data.push( lineData );
        }

        result.data = data;

    } catch (error) {
        result.error = error; 
    }

    return result
}          