import React, { useCallback, useMemo } from 'react';
import moduleStyles from './PageModulesStyles.module.css';
import RichTextInput from 'components/rich_text/RichTextInput';
import TextInput from 'components/TextInput';

import { surveyEditorTexts } from 'utils/appTexts';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import { selectPageProperty, pageTextContentChanged, pageContentChanged } from 'features/survey_editor/surveyEditorSlice';
import { textDirection } from 'utils/stylingTools';

const texts = surveyEditorTexts.content.pageModules.ending;

export default function EndingPageModule( {pageId} ) {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const epilogeText = useSelector( state => selectPageProperty( state, pageId, 'subtitle' ) );
    const callToAction = useSelector( state => selectPageProperty( state, pageId, 'callToAction' ) );
    const surveyLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );

    const settings = useSelector( state => selectPageProperty( state, pageId, "settings" ) );

    const {showSubtitle, showCallToAction } = useMemo( () => {
        
        return settings.general 

    }, [settings])

    const handleTextChange = useCallback( ( prop, value ) => {

        dispatch( pageTextContentChanged( {id: pageId, prop, value, lang: surveyLang }) );

    }, [pageId, surveyLang, dispatch] )

    const handleCallToActionChanges = useCallback( ( prop, value ) => {

        const newData = callToAction ? 
            JSON.parse(JSON.stringify(callToAction)) : 
            { text: {}, type: "open_url", link: "" };

        if( prop === 'text' ) {
            newData.text[ surveyLang ] = value
        } else {
            newData[ prop ] = value;
        }
        dispatch( pageContentChanged( {id: pageId, prop: 'callToAction', value: newData}) );

    }, [pageId, callToAction, surveyLang, dispatch])

    const handleCallToActionLink = useCallback( (e) => {

        // @TODO: validate valid url using 'isUrlLegal'
        handleCallToActionChanges( 'link', e.target.value )

    }, [handleCallToActionChanges])

    return (
        <>
            { showSubtitle &&
                <RichTextInput
                    key={ 'epiloge_' + surveyLang }
                    className={ moduleStyles.editableParagraph }
                    textAlignment={ textDirection( lang ).align }
                    value={ epilogeText ? epilogeText[ surveyLang ] : '' }
                    placeholder={ texts.epilogePlaceholder[ lang ] }
                    onChange={ value => handleTextChange( "subtitle", value ) }
                />
            }
            {   showCallToAction &&
                <>
                    <TextInput
                        key={ 'btn_text_' + surveyLang }
                        label={ texts.callToActionText[ lang ]}
                        contStyle={{ marginTop: 8 }}
                        value={ callToAction ? callToAction.text[surveyLang] : "" || "" }
                        onChange={ e => handleCallToActionChanges( 'text', e.target.value)}
                    />
                    <TextInput
                        key={ 'call_to_action_link' + surveyLang }
                        label={ texts.callToActionLink[ lang ]}
                        contStyle={{ marginTop: 8 }}
                        value={ callToAction ? callToAction.link : "" || "" }
                        onChange={ handleCallToActionLink }
                    />
                </>
            }
        </>
    )
}