import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { resultsTexts } from '../../utils/appTexts';
import styles from './FilterRecords.module.css';
import Modal from '../../components/poppers/Modal';
import PopperPanel from '../../components/poppers/PopperPanel';
import Button from '../../components/Button';
import Checkbox from '../../components//Checkbox';
import { selectUiLanguage } from '../../app/preferencesSlice';
import { recordsFilterUpdated, fetchRecords } from '../../app/reportSlice';

export default function FilterRecords({ project, onApply }) {

    const lang = useSelector(selectUiLanguage);
    const [isWindowActive, setIsWindowActive] = useState(false);
    const filterBtnRef = useRef();

    function handleDismissed() {
        setIsWindowActive(false)
    }

    return [
        <div
            // div wrapper for PopperPanel reference
            ref={filterBtnRef}
            id={styles.filterBtnCont}
            key="column_selection_button_wrapper"
        >
            <Button
                label={resultsTexts.filterBtn[lang]}
                // disabled = 'true'
                theme='Outlined'
                size='m'
                colorSet='Grayscale'
                iconBefore='filter'
                onClick={() => setIsWindowActive(true)}
            />
        </div>,
        <FilterWindow
            key="filter_window"
            lang={lang}
            project={project}
            isActive={isWindowActive}
            referenceElement={filterBtnRef.current}
            onApply={onApply}
            onDismiss={handleDismissed}
        />
    ]
}

function FilterWindow({ lang, isActive, project, referenceElement, onDismiss, onApply }) {
    const dispatch = useDispatch();

    const params = useSelector(state => state.report.recordsFilterData.params);

    const [filterUnfinished, setFilterUnfinished] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
  
    useEffect( () => {

        if( params ) {
            setFilterUnfinished( params.filterUnfinished );
        }

    }, [params])

    // Update recordsFilterData + fetch records from server
    const updateFilter = useCallback(() => {

        const appliedParams = { filterUnfinished };
        dispatch(recordsFilterUpdated({
            type: "params",
            data: appliedParams
        }))

        dispatch(fetchRecords(project.sourceName));

    }, [dispatch, filterUnfinished, project])

    // Closing window + calling "updateFilter" if needed
    const handleApply = useCallback(() => {
        if (onDismiss) onDismiss();
        if (onApply) onApply();
        setChangesMade( false );

        // allow FilterWindow to finish it's transition animation before applying the data update that might have impact on rendering performance:
        setTimeout(updateFilter, 300)

    }, [onDismiss, onApply, updateFilter])


    // Closing the window
    const handleClose = useCallback( () => {

        onDismiss();

    }, [onDismiss]);

    return (
        <Modal
            isActive={isActive}
            onClose={handleClose}
            bgBlur={"low"}
        >
            <PopperPanel referenceElement={referenceElement}>
                <div id={styles.filterCont}>
                    <Checkbox
                        size='s'
                        label={resultsTexts.filterOptions.filterUnfinished[lang]}
                        isChecked={filterUnfinished}
                        name="onlyFinished"
                        onChange={isChecked => {
                            setChangesMade( true );
                            setFilterUnfinished(isChecked);
                        }}
                    />
                    <div id={styles.applyCont}>
                        <Button
                            label={resultsTexts.filterOptions.applyBtn[lang]}
                            size="s"
                            disabled={!changesMade}
                            onClick={handleApply}
                        />
                    </div>
                </div>
            </PopperPanel>
        </Modal>
    )
}