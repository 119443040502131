import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSurvey } from "features/survey_editor/surveyEditorSlice";

import debounce from "debounce";

const DEBOUNCE_FETCH_DELAY_MS = 100;

/**
 * This service is responsible for synchronizing the survey with remote changes.
 */
export default function SurveySynchronizer({ surveyId }) {
    const dispatch = useDispatch();
    const remoteSurveyFetchStatus = useSelector(
        (state) => state.surveyEditor.present.remoteSurveyFetchStatus
    );
    const remoteSurveyUpdateStatus = useSelector(
        (state) => state.surveyEditor.present.remoteSurveyUpdateStatus
    );
    const localChangesMade = useSelector(
        (state) => state.surveyEditor.present.localChangesMade
    );

    const [fetchPromise, setFetchPromise] = useState();

    const debouncedFetch = useMemo(
        () =>
            debounce(() => {
                const promise = dispatch(fetchSurvey(surveyId));
                setFetchPromise(promise);
            }, DEBOUNCE_FETCH_DELAY_MS),
        [dispatch, surveyId]
    );

    useEffect(() => {
        if (
            !localChangesMade &&
            remoteSurveyFetchStatus !== "loading" &&
            remoteSurveyUpdateStatus !== "loading"
        ) {
            // Keep executing fetch calls as long as the client is not busy saving local changes or already fetching.
            debouncedFetch();
        }
    }, [
        localChangesMade,
        remoteSurveyFetchStatus,
        remoteSurveyUpdateStatus,
        debouncedFetch,
    ]);

    useEffect(() => {
        // Make sure there are no scheduled executions when unmounted:
        return () => {
            debouncedFetch.clear();
            if (fetchPromise) {
                fetchPromise.abort();
                // console.log( 'Aborting survey fetch promise')
            }
        };
    }, [surveyId, debouncedFetch, fetchPromise]);

    return null;
}
