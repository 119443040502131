import { ReactComponent as ScaleIcon } from 'assets/icons/page_types/scale_1.svg';
import { ReactComponent as NumberIcon } from 'assets/icons/page_types/number.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/icons/page_types/multiple_choice.svg';
import { ReactComponent as DropdownListIcon } from 'assets/icons/page_types/dropdown_list.svg';
import { ReactComponent as OpenEndedIcon } from 'assets/icons/page_types/open_ended.svg';
import { ReactComponent as DescriptionIcon } from 'assets/icons/page_types/description_1.svg';
import { ReactComponent as OpeningIcon } from 'assets/icons/page_types/opening.svg';
import { ReactComponent as EndingIcon } from 'assets/icons/page_types/ending.svg';
import { ReactComponent as BlockIcon } from 'assets/icons/page_types/block.svg';
import { ReactComponent as ExternalPageIcon } from 'assets/icons/page_types/external_page.svg';

import OpeningPageModule from './content_area/page/page_modules/OpeningPageModule';
import DescriptionPageModule from './content_area/page/page_modules/DescriptionPageModule';
import DropdownListPageModule from './content_area/page/page_modules/DropdownListPageModule';
import MultipleChoicePageModule from './content_area/page/page_modules/MultipleChoicePageModule';
import NumberPageModule from './content_area/page/page_modules/NumberPageModule';
import OpenEndedPageModule from './content_area/page/page_modules/OpenEndedPageModule';
import ScalePageModule from './content_area/page/page_modules/ScalePageModule';
import EndingPageModule from './content_area/page/page_modules/EndingPageModule';
import ExternalPageModule from './content_area/page/page_modules/ExternalPageModule';

import OpeningSettingsModule from './properties_area/page_settings_modules/opening_settings/OpeningSettingsModule';
import DescriptionSettingsModule from './properties_area/page_settings_modules/DescriptionSettingsModule';
import DropdownListSettingsModule from './properties_area/page_settings_modules/DropdownListSettingsModule';
import MultipleChoiceSettingsModule from './properties_area/page_settings_modules/MultipleChoiceSettingsModule';
import NumberSettingsModule from './properties_area/page_settings_modules/NumberSettingsModule';
import OpenEndedSettingsModule from './properties_area/page_settings_modules/OpenEndedSettingsModule';
import ScaleSettingsModule from './properties_area/page_settings_modules/ScaleSettingsModule';
import EndingSettingsModule from './properties_area/page_settings_modules/EndingSettingsModule';
import BlockSettingsModule from './properties_area/page_settings_modules/block_settings/BlockSettingsModule';
import ExternalPageSettingsModule from './properties_area/page_settings_modules/ExternalPageSettingsModule';

import { surveyEditorTexts } from 'utils/appTexts';
import { getRichText } from 'components/rich_text/RichTextInput';

export const surveyLanguages = {
    he: { key: 'he', direction: 'rtl', name: { en: 'Hebrew', he: 'עברית' } },
    ar: { key: 'ar', direction: 'rtl', name: { en: 'Arabic', he: 'ערבית' } },
    en: { key: 'en', direction: 'ltr', name: { en: 'English', he: 'אנגלית' } },
};

export const mediaSettings = {
    width: 'content', // "full" | "content"
    heightLimit: '30vh', // "auto" | "10vh" | "20vh" | ... | "100vh"
    framePositioning: 'center', // "top" | "center" | "bottom"
    allowZoom: false, // boolean
    lottiePlayMode: 'once', // "once" | "loop"
};

function getInitialScaleData() {
    const data = [];
    const numItems = 5;

    for (let i = 0; i < numItems; i++) {
        const item = {
            key: 'scale_' + (i + 1),
            text: {},
        };

        Object.values(surveyLanguages).forEach((lang) => {
            item.text[lang.key] = (i + 1).toString();
        });
        data.push(item);
    }

    return data;
}

export const scaleSecondaryButtonInitialData = {
    key: 'scale_na',
    text: surveyEditorTexts.content.pageModules.scale.secondaryButtonText,
};

export const colors = ['000', '001', '002', '003', '004'];
export const textColors = {
    color_000: { color: 'hsl(209, 100%, 40%)' }, // Blue
    color_001: { color: 'hsl(139, 100%, 40%)' }, // Green
    color_002: { color: 'hsl(354, 100%, 40%)' }, // Pink
    color_003: { color: 'hsl(51, 100%, 40%)' }, // Yellow
    color_004: { color: 'hsl(30, 100%, 40%)' }, // Orange
};

export const pageTypesConfig = {
    OPENING: {
        name: { en: 'Opening', he: 'פתיחה' },
        icon: OpeningIcon,
        color: '#D81B60',
        pageModule: OpeningPageModule,
        pageSettingsModule: OpeningSettingsModule,
        data: {
            subtitle: {},
            media: null,
            termsLinks: null,
            settings: {
                general: {
                    saveAsUserArgument: false,
                    showSubtitle: true,
                    allowRepeatedInput: false, // unlock page items when returning to the page, and run logic
                    resetInputOnRevisit: false, // clear user input upon returning to the page
                    showTermsLinks: false,
                    continueButtonCentered: false, // @TODO: remove when Pakadim project ends
                    continueButtonText: null,
                },
            },
        },
    },
    MULTIPLE_CHOICE: {
        name: { en: 'Multiple Choice', he: 'בחירה מרובה' },
        icon: MultipleChoiceIcon,
        pageModule: MultipleChoicePageModule,
        pageSettingsModule: MultipleChoiceSettingsModule,
        color: '#6202EE',
        data: {
            subtitle: {}, // not mandatory
            media: null,
            itemsOrder: [],
            itemsData: {
                OPEN_ITEM: {},
            },
            settings: {
                general: {
                    minChoices: 1, //
                    choicesLimit: 1, // -1 for 'no limit'
                    autoContinue: true, // becomes false if more than 2 choices
                    saveAsUserArgument: false,
                    randomizeItems: false,
                    showSubtitle: false,
                    allowRepeatedInput: false, // unlock page items when returning to the page, and run logic
                    resetInputOnRevisit: false, // clear user input upon returning to the page
                    showOpenItem: false,
                    continueButtonText: null,
                    isNumeric: false, // if true the user is able to assign a number to each choice

                    // @TODO: remove when Pakadim project ends:
                    continueButtonCentered: false,
                    centeredAnswers: false,
                    //
                },
                media: mediaSettings,
            },
        },
    },
    SCALE: {
        name: { en: 'Scale', he: 'סקאלה' },
        icon: ScaleIcon,
        pageModule: ScalePageModule,
        pageSettingsModule: ScaleSettingsModule,
        color: '#FB8006',
        data: {
            subtitle: {},
            media: null,
            itemsOrder: [],
            itemsData: {},
            scaleData: getInitialScaleData(), // [{ key: 'scale_1', text: { he: '1', en: '1', ... }}]
            secondaryButtonData: null, // { key: '', text: {he: '', ...} }
            settings: {
                general: {
                    scaleSize: 5, // 2 to 10
                    showSubtitle: true,
                    customScaleValues: false,
                    randomizeItems: false,
                    randomDrawCount: 0,
                    allowRepeatedInput: false, // unlock page items when returning to the page
                    resetInputOnRevisit: false, // clear user input upon returning to the page
                    showSecondaryButton: false, // underlying button such as 'irrelevant'
                    continueButtonCentered: false, // @TODO: remove when Pakadim project ends
                    continueButtonText: null,
                },
                media: mediaSettings,
            },
        },
    },
    NUMBER: {
        name: { en: 'Number', he: 'מספר' },
        icon: NumberIcon,
        pageModule: NumberPageModule,
        pageSettingsModule: NumberSettingsModule,
        color: '#FFC108',
        data: {
            subtitle: {}, // not mandatory,
            media: null,
            inputType: '', // slider | keyboard
            minCharacters: 1,
            placeholder: {},
            min: '0',
            max: '100',
            minLabel: {},
            maxLabel: {},
            // initial: 50,
            settings: {
                general: {
                    saveAsUserArgument: false,
                    showSubtitle: false,
                    unit: { he: '', en: '' }, // not mandatory
                    allowRepeatedInput: false, // unlock page items when returning to the page
                    resetInputOnRevisit: false, // clear user input upon returning to the page
                    useCustomRangeLabels: false, // slider only
                    continueButtonCentered: false, // @TODO: remove when Pakadim project ends
                    continueButtonText: null,
                },
                media: mediaSettings,
            },
        },
    },
    DROPDOWN_LIST: {
        name: { en: 'Dropdown List', he: 'רשימה נפתחת' },
        icon: DropdownListIcon,
        pageModule: DropdownListPageModule,
        pageSettingsModule: DropdownListSettingsModule,
        color: '#795548',
        data: {
            subtitle: {},
            media: null,
            listTitle: {},
            itemsOrder: [],
            itemsData: {},
            settings: {
                general: {
                    saveAsUserArgument: false,
                    randomizeItems: false,
                    showSubtitle: false,
                    allowRepeatedInput: false, // unlock page items when returning to the page
                    resetInputOnRevisit: false, // clear user input upon returning to the page
                    continueButtonCentered: false, // @TODO: remove when Pakadim project ends
                    continueButtonText: null,
                },
                media: mediaSettings,
            },
        },
    },
    OPEN_ENDED_QUESTION: {
        name: { en: 'Open Ended', he: 'שדה פתוח' },
        icon: OpenEndedIcon,
        pageModule: OpenEndedPageModule,
        pageSettingsModule: OpenEndedSettingsModule,
        color: '#5D9431',
        data: {
            subtitle: {},
            media: null,
            inputType: 'text', // text | text_area | number | email,
            placeholder: {},
            minCharacters: 1,
            regexValidation: '',
            settings: {
                general: {
                    showSubtitle: false,
                    allowRepeatedInput: false, // unlock page items when returning to the page
                    resetInputOnRevisit: false, // clear user input upon returning to the page
                    continueButtonCentered: false, // @TODO: remove when Pakadim project ends
                    continueButtonText: null,
                },
                media: mediaSettings,
            },
        },
        // platformSpecificProps: {
        //     WEB: {

        //     },
        //     APP: {

        //     }
        // }
    },
    DESCRIPTION: {
        name: { en: 'Description', he: 'תיאור' },
        icon: DescriptionIcon,
        pageModule: DescriptionPageModule,
        pageSettingsModule: DescriptionSettingsModule,
        color: '#1E88E5',
        data: {
            subtitle: {},
            media: null,
            imgUrl: '',
            settings: {
                general: {
                    allowRepeatedInput: false, // unlock page items when returning to the page, and run logic
                    resetInputOnRevisit: false, // clear user input upon returning to the page
                    continueButtonCentered: false, // @TODO: remove when Pakadim project ends
                    continueButtonText: null,
                    titleSize: 'normal', // 'normal' | 'large
                },
                media: mediaSettings,
            },
        },
    },
    EXTERNAL_PAGE: {
        name: { en: 'External Page', he: 'עמוד חיצוני' },
        icon: ExternalPageIcon,
        color: '#808390',
        pageModule: ExternalPageModule,
        pageSettingsModule: ExternalPageSettingsModule,
        data: {
            url: '',
            settings: {
                general: {
                    showContinueButton: true,
                    allowRepeatedInput: false, // unlock page items when returning to the page, and run logic
                    resetInputOnRevisit: false, // clear user input upon returning to the page
                    allowCamera: false,
                    allowMic: false,
                    continueButtonCentered: false, // @TODO: remove when Pakadim project ends
                    continueButtonText: null,
                },
            },
        },
    },
    ENDING: {
        name: { en: 'Ending', he: 'סיום' },
        icon: EndingIcon,
        pageModule: EndingPageModule,
        pageSettingsModule: EndingSettingsModule,
        color: '#D81B60',
        data: {
            subtitle: {},
            media: null,
            settings: {
                general: {
                    showSubtitle: true,
                    showConfetti: true,
                    showCallToAction: false,
                },
                media: mediaSettings,
            },
            callToAction: {
                text: {},
                type: 'open_url',
                link: '',
            },
        },
    },
    BLOCK: {
        name: { en: 'Block', he: 'בלוק' },
        icon: BlockIcon,
        color: '#808390',
        // pageModule: ,
        pageSettingsModule: BlockSettingsModule,
        data: {
            pages: [], // during edit, pages is only a list of ids. The finall JSON will consist of full page data in this array;
            settings: {
                general: {
                    isRandom: false,
                    randomDrawCount: 0, // amount of pages to randomly draw
                    randomizationWeights: {}, // dictionary of pages probability weights: { id, 0.0-1.0 }
                },
            },
        },
    },
};

const openItemTexts =
    surveyEditorTexts.content.pageModules.multipleChoice.openItemDefaultText;
export const OPEN_ITEM = 'open_item';
export const openItemDataConfig = {
    id: OPEN_ITEM,
    key: OPEN_ITEM,
    text: {
        he: getRichText(openItemTexts.he),
        en: getRichText(openItemTexts.en),
        ar: getRichText(openItemTexts.ar),
    },
};

// const listItemExample = {
//     id: "itemId",
//     key: "itemKey",
//     text: { /*...supported langs dictionary*/ },
//     navigation: {
//         logicItem: "logicItemId",
//         targetPage: "pageId"
//     },
//     hidingCondition: {
//         isAlways: true | false,
//         rule: { "==": ["arg_a_id", "arg_b_id"] },
//         args: {}
//     }
// }
