import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './AnalysesFieldsModules.module.css';
import inputStyles from 'components/InputStyles.module.css';
import DropDown from 'components/DropDown';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import MultipleSelect from 'components/MultipleSelect';
import TextInput from 'components/TextInput';
import { fetchReport } from 'app/reportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { validateDataKeyType } from 'features/analyses/analysesUtils';

const inputTypes = {
    SINGLE: 'single',
    MINMAX: 'minMax',
    CATEGORIAL: 'categorial',
    BOOLEAN: 'boolean'
}

export default function FilteringSettingsFieldModule( {project, data, value, handleChange, texts, lang} ) {

    const filteringList = useMemo( () => value, [value] )

    const handleAdd = useCallback( () => {

        const result = filteringList ? Array.from( filteringList ) : [];
        result.push( { key: undefined } )

        handleChange( result );

    }, [filteringList, handleChange] );

    const handleItemChange = useCallback( (index, key, data, inputType ) => {

        const result = Array.from( filteringList );

        if( !data ) {

            result[ index ] = {key};

        } else {

            switch( inputType ) {
                case inputTypes.SINGLE:
                    result[ index ] = {key, value: data.value };
                    break;
                case inputTypes.MINMAX:
                    result[ index ] = {key, min: data.min, max: data.max};
                    break;
                case inputTypes.CATEGORIAL:
                    result[ index ] = {key, values: data.values};
                    break;
                case inputTypes.BOOLEAN:
                    result[ index ] = {key, value: data.value};
                    break;
                default: break;
            }
            
        } 

        handleChange( result )

    },[filteringList, handleChange])

    const handleDeleteItem = useCallback( (index) => {

        const result = Array.from( filteringList );
        result.splice( index, 1 );

        handleChange( result )

    }, [filteringList, handleChange])

    return (
        <div className={ styles.listFieldContainer } >
            <div className={ styles.frame }/>
            <label className={ inputStyles.label }>{ data.label ? data.label[ lang ] : '' }</label>
            {
                filteringList && filteringList.map( (f,i) => (

                    <FilterField
                        key={ 'filter' + i }
                        index={ i }
                        project={ project }
                        fieldData={ data }
                        filterItemData={ f }
                        texts={ texts }
                        lang={ lang }
                        onChange={ (k,d,s) => handleItemChange( i,k,d,s ) }
                        onDelete={ () => handleDeleteItem( i ) }
                    />
                ))
            }
            <Button
                label={ texts.addFilterBtn[ lang ] }
                iconBefore='plus'
                theme='Outlined'
                bgStyle={{width:'100%', padding: '6px 14px', marginTop: 8}}
                onClick={ handleAdd }
            />
        </div>
    )

}


const FilterField = ({index, project, fieldData, filterItemData, texts, lang, onChange, onDelete}) => {

    const dispatch = useDispatch();

    const dataKeys = useSelector( state => {

        const dataKeys = state.report.dataKeys;

        if( !dataKeys ) {
            if( state.report.fetchStatus === 'idle' ) {

                dispatch( fetchReport( project.sourceName ) );
            }

        }

        return dataKeys;
    })

    const dataKeysOptions = useMemo( () => {

        if( !dataKeys ) return null;

        return Object.keys( dataKeys ).map( k => {
            
            const meta = dataKeys[k];
            const isValidType = validateDataKeyType( fieldData, meta );

            if( !isValidType ) return false;

            const option = {key: k, label: `${k} (${meta.type})`};
            return option; 

        }) 

    }, [dataKeys,fieldData])

    const selectedKeyPrefs = useMemo( () => {

        if( dataKeys ) {

            return dataKeys[ filterItemData.key ];
        }

        return null;

    }, [dataKeys,filterItemData] )

    useEffect( () => {

        if( selectedKeyPrefs ) {

            if( selectedKeyPrefs.type === 'STRING' && selectedKeyPrefs.valueDescriptions ) {

                setSubCategories( selectedKeyPrefs.valueDescriptions );
                setInpuType( inputTypes.CATEGORIAL );

            } else if( selectedKeyPrefs.type === 'BOOL' ) {

                setInpuType( inputTypes.BOOLEAN );
            
            } else if( selectedKeyPrefs.type === 'NUMBER') {

                setInpuType( inputTypes.MINMAX );
                
            } else {
                
                setInpuType( inputTypes.SINGLE );

            }
        } 

    }, [selectedKeyPrefs])

    const [subCategories, setSubCategories] = useState( null );
    const [inputType, setInpuType] = useState( null );
    const isLtr = useMemo( () => lang === 'en', [lang] );
    const [isHovered, setIsHovered] = useState( false );

    return (
        <div
            className={ styles.fieldItemCont }
            onMouseEnter={ () => setIsHovered( true ) }
            onMouseLeave={ () => setIsHovered( false ) }
        >
            {
                isHovered &&
                <IconButton
                    name='x'
                    size='s'
                    theme='Plain'
                    bgStyle={{position:'absolute', top: -2, left: -2, right: -2 }}
                    onClick={ () => onDelete() }
                />
            }

            <DropDown
                label={ `${texts.keyLabel[ lang ]} ${index+1}` }
                options={ dataKeysOptions }
                value={ filterItemData.key }
                onChange={ (op) => {
                    setIsHovered( false );
                    onChange( op.key, null );
                }}
            />   
            {
                selectedKeyPrefs ? 
                (
                    inputType === inputTypes.CATEGORIAL ? 
                    <CategoriesSelect
                        isLtr={ isLtr }
                        lang={ lang }
                        texts={ texts }
                        subCategories={ subCategories }
                        filterItemData={ filterItemData }
                        onChange={ onChange }
                    />
                    :
                    inputType === inputTypes.MINMAX ?
                    <MinMaxInput 
                        isLtr={ isLtr } 
                        lang={ lang }
                        texts={ texts }
                        filterItemData={ filterItemData } 
                        onChange={ onChange }
                    />
                    :
                    inputType === inputTypes.BOOLEAN ?
                    <BooleanInput
                        isLtr={ isLtr } 
                        filterItemData={ filterItemData } 
                        onChange={ onChange }
                    />
                    :
                    <SingleNumberInput
                        isLtr={ isLtr } 
                        filterItemData={ filterItemData } 
                        onChange={ onChange }
                    />
                )
                :
                null
            }

        </div>
    )
}

const SingleNumberInput = ({lang, isLtr, texts, filterItemData, onChange}) => {

    const [value, setValue] = useState( filterItemData.value );

    useEffect( () => {

        if( !filterItemData.value && filterItemData.value !== 0 ) setValue( null );

    }, [filterItemData.value])

    const applyChanges = useCallback ( () => {

        onChange( filterItemData.key, {value}, inputTypes.SINGLE )

    }, [value, onChange, filterItemData])

    return (
        <>
            <div 
                className={ styles.relatedFieldsIndicator }
                style={{ 
                    borderLeft: isLtr ? 'var(--border-main )' : 'none',
                    borderRight: isLtr ? 'none' : 'var(--border-main )',
                    left: isLtr ? 12 : 'unset',
                    right: isLtr ? 'unset' : 12,
                }}
            />
            <TextInput
                type='number'
                placeholder={ '#' }
                value={ (value !== null && value !== undefined ) ? value : "" }
                onChange={ (e) => setValue( parseFloat( e.target.value ) ) }
                onBlur={ () => applyChanges() }
                onReturn={ () => applyChanges() }
            />
        </>
    )
}

const MinMaxInput = ({lang, isLtr, texts, filterItemData, onChange}) => {

    const [min, setMin] = useState( filterItemData.min );
    const [max, setMax] = useState( filterItemData.max );

    useEffect( () => {

        if( !filterItemData.min && filterItemData.min !== 0 ) setMin( null );
        if( !filterItemData.max && filterItemData.max !== 0 ) setMax( null );

    }, [filterItemData])

    const applyChanges = useCallback ( () => {

        onChange( filterItemData.key, {min,max}, inputTypes.MINMAX );

    }, [min, max, onChange, filterItemData])

    return (
        <>
            <div 
                className={ styles.relatedFieldsIndicator }
                style={{ 
                    borderLeft: isLtr ? 'var(--border-main )' : 'none',
                    borderRight: isLtr ? 'none' : 'var(--border-main )',
                    left: isLtr ? 12 : 'unset',
                    right: isLtr ? 'unset' : 12,
                }}
            />
            <div style={{display:'flex'}}>
                <TextInput
                    type='number'
                    label={ texts.min[ lang ] }
                    placeholder={ '#' }
                    value={ (min !== null && min !== undefined ) ? min : "" }
                    onChange={ (e) => setMin( parseFloat( e.target.value ) ) }
                    onBlur={ () => applyChanges() }
                    onReturn={ () => applyChanges() }
                />
                <div style={{minWidth: 6}}/>
                <TextInput
                    type='number'
                    label={ texts.max[ lang ] }
                    placeholder={ '#' }
                    value={ (max !== null && max !== undefined ) ? max : "" }
                    onChange={ (e) => setMax( parseFloat( e.target.value ) ) }
                    onBlur={ () => applyChanges() }
                    onReturn={ () => applyChanges() }
                />
            </div>
        </>
    )
}

const CategoriesSelect = ({isLtr, lang, texts, subCategories, filterItemData, onChange}) => {


    const valuesOptions = useMemo ( () => {

        if( subCategories ) {

            const options = Object.keys( subCategories ).map( k => (
                {
                    key: k, 
                    label: k, 
                    isSelected: filterItemData.values ? filterItemData.values.includes( k ) : false
                }) 
            )

            return options;
        }

        return []

    }, [ subCategories, filterItemData, ] )

    const handleValueSelection = useCallback( ( {key, isSelected} ) => {

        const newValues = filterItemData.values ? Array.from( filterItemData.values ) : [];

        if ( newValues.indexOf( key ) >= 0 ) {

            newValues.splice( newValues.indexOf( key ), 1 );

        } else {
            newValues.push( key )
        }

        onChange( filterItemData.key, {values: newValues}, inputTypes.CATEGORIAL );

    }, [filterItemData,onChange])


    return (
        <>
            <div 
                className={ styles.relatedFieldsIndicator }
                style={{ 
                    borderLeft: isLtr ? 'var(--border-main )' : 'none',
                    borderRight: isLtr ? 'none' : 'var(--border-main )',
                    left: isLtr ? 12 : 'unset',
                    right: isLtr ? 'unset' : 12,
                }}
            />
            <MultipleSelect
                // label={ data.label ? data.label[ lang ] : '' }
                options={ valuesOptions }
                placeholder={ texts.chooseValuesPlaceholder[ lang ] }
                onChange={ handleValueSelection }
            />
        </>
    )

}

const BooleanInput = ({isLtr, filterItemData, onChange}) => {
    
    const options = useMemo( () => [
        {key: 'true', label: 'True'}, 
        {key: 'false', label: 'False'}, 
    ], [])

    const value = useMemo( () => {

        if( filterItemData.value !== undefined && filterItemData.value !== null ) {

            return filterItemData.value.toString();
        }
        
        return null;

    }, [filterItemData.value])

    return (
        <>
            <div 
                className={ styles.relatedFieldsIndicator }
                style={{ 
                    borderLeft: isLtr ? 'var(--border-main )' : 'none',
                    borderRight: isLtr ? 'none' : 'var(--border-main )',
                    left: isLtr ? 12 : 'unset',
                    right: isLtr ? 'unset' : 12,
                }}
            />
            <DropDown
                options={ options }
                value={ value }
                onChange={ (op) => {
                    onChange( filterItemData.key, {value: op.key === 'true' }, inputTypes.BOOLEAN );
                }}
            /> 
        </> 
    )
}
