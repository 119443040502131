import React, { useCallback, useRef, useState } from 'react';
import inputStyles from './InputStyles.module.css';
import styles from './EmojiPicker.module.css';
import Modal from './poppers/Modal';
import PopperPanel from './poppers/PopperPanel';
import Button from './Button';
import { emojies } from './Emoji';
/**
 * 
 * @param {{
 * label: string // field label
 * className: string // container className
 * bgStyle: object // style of the button background
 * theme: enum, // See Button themes
 * colorSet: enum, // See Button colorSets
 * value: string // current choosen emoji
 * onChange: function // returns the newly selected emoji
 * }} param0 
 */
export default function EmojiPicker({ 
    label, 
    className, 
    bgStyle,
    theme = "Outlined",
    colorSet = "Field", 
    value, 
    onChange 
}) {

    const [isPopperActive, setIsPoperActive ] =useState( false );

    const referenceElement = useRef();

    const handleEmojiClick = useCallback( (emoji) => {
        onChange(emoji);
        setIsPoperActive( false );
    }, [onChange]);

    return (
        <div className={ className }>

        { label && 
            <label className={ inputStyles.label }> { label } </label>
        }
        <div ref={ referenceElement }>
            <Button
                label={ value }
                theme={ theme }
                iconAfter={ 'arrow_down' }
                colorSet={ colorSet }
                size = 'm'
                labelStyle={{color: 'black', fontSize: 20}}
                bgStyle = {{ 
                    boxSizing: 'border-box', 
                    // width: '100%', 
                    justifyContent: 'space-between', 
                    padding: '6px 14px' ,
                    height: 40,
                    // backgroundColor: 'white' ,
                    ...bgStyle
                }}
                onClick={ () => setIsPoperActive( true ) }
            />
        </div>
        <Modal 
            isActive={ isPopperActive }
            onClose={ () => setIsPoperActive( false ) }
            bgBlur={ "low" }
        >
            <PopperPanel 
                referenceElement={ referenceElement.current }
                panelStyle={{ padding: 'var(--space-medium'}}    
            >
                {
                    isPopperActive &&
                    <div className={ styles.pickerCont }>
                        { emojies.map( e => {
                            return (
                                <button 
                                    key={e} 
                                    className={ styles.emojiBtn }
                                    onClick={ () => handleEmojiClick( e )}
                                >
                                    {e}
                                </button>
                            )
                        }) }  
                    </div>
                }
            </PopperPanel>
        </Modal>
        </div>
    )

}