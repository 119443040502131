import React, { useEffect, useMemo } from 'react';
import styles from './DescriptionSettingsModule.module.css';
import inputStyles from 'components/InputStyles.module.css';
import { selectUiLanguage } from 'app/preferencesSlice';
import { useSelector } from 'react-redux';
import { surveyEditorTexts } from 'utils/appTexts';

const texts =
    surveyEditorTexts.properties.pageSettings.settingsModules.description;

export default function DescriptionSettingsModule({
    settings,
    updateSettings,
}) {
    const lang = useSelector(selectUiLanguage);
    const { titleSize } = useMemo(() => {
        return { titleSize: settings?.general?.titleSize };
    }, [settings]);

    useEffect(() => {
        // Initiate titleSize if missing from legacy surveys
        if (!titleSize) {
            updateSettings({ prop: 'titleSize', value: 'normal' });
        }
    }, [titleSize, updateSettings]);

    return (
        <>
            <label
                className={inputStyles.label}
                style={{
                    marginTop: 'var(--space-medium)',
                    marginBottom: 0,
                    marginInlineEnd: 8,
                    lineHeight: 'normal',
                }}
            >
                {texts.titleSize[lang]}
            </label>
            <div className={styles.radioOptionsCont}>
                <input
                    type="radio"
                    name="titleSizeSetting"
                    id="normal"
                    checked={titleSize === 'normal'}
                    onChange={(e) =>
                        updateSettings({
                            prop: 'titleSize',
                            value: e.target.id,
                        })
                    }
                />
                <label htmlFor="normal" style={{ marginInlineEnd: 12 }}>
                    {texts.normal[lang]}
                </label>
                <input
                    type="radio"
                    name="titleSizeSetting"
                    id="large"
                    checked={titleSize === 'large'}
                    onChange={(e) =>
                        updateSettings({
                            prop: 'titleSize',
                            value: e.target.id,
                        })
                    }
                />
                <label htmlFor="large">{texts.large[lang]}</label>
            </div>
        </>
    );
}
