import React, { useEffect, useMemo } from 'react';
import TextInput from 'components/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReport } from 'app/reportSlice';

export default function StringFieldModule ({project, data, value, handleChange, lang}) {

    const dispatch = useDispatch();
    const allVariables = useSelector( state => state.analyses.newAnalysis.variables );
    const selectedKey = useMemo( () => {
        if( !data.ref || !allVariables[ data.ref ] ) return null;
        // Depending of field type (KEY_NAME / KEY_NAME_AND_VALUES), the key is beign stored in different ways:
        return allVariables[ data.ref ].key || allVariables[ data.ref ];
    }, [allVariables,data]);

    const dataKeys = useSelector(state => {

        const dataKeys = state.report.dataKeys;

        if (!dataKeys) {
            if (state.report.fetchStatus === 'idle') {

                dispatch(fetchReport(project.sourceName));
            }
            return null
        }

        return dataKeys

    })

    useEffect( () => {

        if( selectedKey && dataKeys ) {

            // Set initial string if field has ref:
            handleChange( dataKeys[ selectedKey ].title )
        }

    }, [selectedKey, handleChange, dataKeys])
    
    return (
        <TextInput
            type='text'
            label={ data.label ? data.label[ lang ] : '' }
            placeholder={ data.placeholder ? data.placeholder[ lang ] : "..." }
            value={ value || "" }
            dir={ lang === 'en' ? 'ltr' : 'rtl' }
            onChange={ (e) => handleChange( e.target.value ) }
        />
    )
}