import React, { useEffect, useMemo } from "react";
import SurveyAutoSave from "./SurveyAutoSave";
import SurveySynchronizer from "./SurveySynchronizer";
import InactivityDetector from "./InactivityDetector";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectProjectById, fetchProjectById } from "app/horizonDataSlice";
import {
    fetchSurvey,
    initiateAutoSave,
    surveyIdChanged,
} from "features/survey_editor/surveyEditorSlice";

export default function SurveyServices() {
    const { projectId } = useParams();

    const dispatch = useDispatch();

    const project = useSelector((state) => selectProjectById(state, projectId));
    const fetchProjectByIdStatus = useSelector(
        (state) => state.horizonData.fetchProjectByIdStatus
    );
    const remoteSurveyFetchStatus = useSelector(
        (state) => state.surveyEditor.present.remoteSurveyFetchStatus
    );
    const autoSaveInitiated = useSelector(
        (state) => state.surveyEditor.present.autoSaveInitiated
    );
    const isTemplate = useMemo(() => project && project.isTemplate, [project]);

    useEffect(() => {
        // If project id exists but the project data hasn't been fetched yet, fetch the project first.

        if (projectId) {
            if (fetchProjectByIdStatus === "idle") {
                dispatch(fetchProjectById(projectId));
            }
        }
    }, [projectId, dispatch, fetchProjectByIdStatus]);

    useEffect(() => {
        if (
            project &&
            project !== "404" &&
            remoteSurveyFetchStatus === "idle"
        ) {
            // Initial survey fetch:
            dispatch(surveyIdChanged(project.survey.id));
            dispatch(fetchSurvey(project.survey.id));
        }

        if (remoteSurveyFetchStatus === "succeeded" && !autoSaveInitiated) {
            setTimeout(() => dispatch(initiateAutoSave()), 2500);
        }
    }, [remoteSurveyFetchStatus, project, autoSaveInitiated, dispatch]);

    if (!project || project === "404") return null;

    return (
        <>
            <InactivityDetector />
            <SurveySynchronizer surveyId={project.survey.id} />
            {!isTemplate && <SurveyAutoSave surveyId={project.survey.id} />}
        </>
    );
}
