import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Notebook.module.css';
import CloudSaveIcon from 'components/animated_icons/CloudSaveIcon';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'components/IconButton';
import { selectUiLanguage } from 'app/preferencesSlice';
import { notebookTexts } from 'utils/appTexts';
import { displayChanged } from './notebookSlice';


const texts = notebookTexts.toolbars;

export default function NotebookToolbars({project}) {

    const isTemplate = useMemo( () => project && project.isTemplate, [project]);

    return (
        <>
            { !isTemplate && <AutoSaveIndication/> }
            <PageDirection/>
        </>
    )
}

const AutoSaveIndication = () => {
    // const lang = useSelector( selectUiLanguage );
    const isAutoSaving = useSelector( state => state.notebook.editor.localChangesMade );

    return (
        <div className={ styles.autoSaveIndicator }>
            <CloudSaveIcon isChecked={ !isAutoSaving } isLoading={ isAutoSaving }/>
        </div>
    )
}

const PageDirection = () => {

    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const direction = useSelector( state => state.notebook.display.pageDirection );
    const remoteDataFetchStatus = useSelector( state => state.notebook.remoteDataFetchStatus );

    const [isInitiating, setIsInitiating] = useState( true );

    useEffect( () => {

        if( isInitiating && remoteDataFetchStatus === 'succeeded' ) {
            setIsInitiating( false );
        }

    }, [remoteDataFetchStatus, isInitiating])
    
    const handleClick = useCallback( () => {

        const dir = direction === 'ltr' ? 'rtl' : 'ltr';
        dispatch( displayChanged( {prop: 'pageDirection', value: dir }) )

    }, [direction, dispatch])

    if( isInitiating ) return null;

    return (
        <div className={ styles.pageDirection }>
            <IconButton
                name={ direction === 'ltr' ? 'ltr_text' : 'rtl_text' }
                theme='Plain'
                colorSet='Grayscale'
                tooltip={ `${texts.pageDirection[ lang ]}: ${direction ? direction.toUpperCase() : '-'}`}
                onClick={ handleClick }
            />
        </div>
    )
}