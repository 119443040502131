import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './MediaLibrary.module.css';
import Button from 'components/Button';
import { componentsTexts } from 'utils/appTexts';
import { UploadMedia } from 'api/api';
import { useSelector } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';

const texts = componentsTexts.mediaLibrary;
const validFileTypes = 'image/jpeg, image/png, .json'; //  @TBD - "video/*",

function isValidLottie(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', (e) => {
            try {
                const json = JSON.parse(e.target.result);
                if (
                    json.v !== undefined &&
                    json.fr !== undefined &&
                    json.ip !== undefined &&
                    json.op !== undefined &&
                    json.w !== undefined &&
                    json.h !== undefined &&
                    json.assets !== undefined &&
                    json.layers !== undefined
                ) {
                    resolve(true);
                } else {
                    reject();
                }
            } catch {
                reject();
            }
        });
        reader.readAsText(file);
    });
}

// file sizes in KB:
const sizeLimit = { image: 1000, video: 100000, lottie: 1000 };
const getFileSize = (number) => number / 1024;

export const useMediaFilesUploader = (storageId, onFileUploaded) => {
    const lang = useSelector(selectUiLanguage);

    const uploader = useCallback(
        async (files, onLoadStart, onLoadEnd) => {
            Object.values(files).forEach(async (file) => {
                const type = /image/.test(file.type)
                    ? 'image'
                    : /video/.test(file.type)
                    ? 'video'
                    : /application\/json/.test(file.type)
                    ? 'lottie'
                    : null;
                if (type && getFileSize(file.size) <= sizeLimit[type]) {
                    if (type === 'lottie') {
                        const isValid = await isValidLottie(file).catch(() => {
                            alert(texts.corruptedLottie[lang]);
                        });
                        if (!isValid) return;
                    }
                    onLoadStart();
                    const url = await UploadMedia(file, storageId).catch(
                        (err) => alert(`Error: ${err}`)
                    );
                    onLoadEnd();
                    if (url) {
                        console.log('file uploaded to: ', url);
                        const date = Date.now();
                        onFileUploaded({
                            id: `${file.name}_${date}`,
                            type,
                            src: url,
                            name: file.name,
                            date: date,
                        });
                    }
                } else {
                    alert(
                        texts.fileSizeError[lang]
                            .replace('{type}', type)
                            .replace('{sizeLimit}', sizeLimit[type])
                    );
                }
            });
        },
        [storageId, lang, onFileUploaded]
    );
    return uploader;
};

const MediaLibraryAddNew = ({ lang, storageId, onAdd }) => {
    const buttonRef = useRef();
    const inputRef = useRef();
    const [isUploading, setIsUploading] = useState(false);
    const [fileToAdd, setFileToAdd] = useState(null);

    const uploader = useMediaFilesUploader(storageId, setFileToAdd);

    useEffect(() => {
        if (fileToAdd && onAdd) {
            onAdd(fileToAdd);
            setFileToAdd(null);
        }
    }, [fileToAdd, onAdd]);

    const handleAdd = useCallback(() => {
        inputRef.current?.click();
    }, []);

    const handleFileSelection = useCallback(
        async (e) => {
            const files = e.target.files;
            uploader(
                files,
                () => setIsUploading(true),
                () => setIsUploading(false)
            );
            e.target.value = ''; // Reset files input.
        },
        [uploader]
    );

    return (
        <div className={styles.addCont}>
            <div ref={buttonRef}>
                <Button
                    label={texts.addButton[lang]}
                    iconBefore="upload"
                    onClick={handleAdd}
                    indicateActivity={isUploading}
                />
            </div>

            <input
                ref={inputRef}
                type="file"
                style={{
                    display: 'none',
                }}
                className={styles.fileInput}
                accept={validFileTypes}
                multiple={true}
                onChange={handleFileSelection}
            />
        </div>
    );
};

export default MediaLibraryAddNew;
