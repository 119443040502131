import IconButton from 'components/IconButton';
import styles from './MediaLibrary.module.css';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

import { componentsTexts } from 'utils/appTexts';
import Emoji from 'components/Emoji';
import SelectionPopper from 'components/poppers/SelectionPopper';
import { useMediaFilesUploader } from './MediaLibraryAddNew';
import PopperPanel from 'components/poppers/PopperPanel';
import Modal from 'components/poppers/Modal';
import { useSelector } from 'react-redux';
import TextInput from 'components/TextInput';
import { surveyLanguages } from 'features/survey_editor/surveyEditorConfiguration';

const texts = componentsTexts.mediaLibrary;

const MediaLibraryThumbnails = ({
    lang,
    storageId,
    mediaFiles,
    archiveMode,
    onFilesChange,
    onFileDropUpload,
    selected,
    onSelection,
}) => {
    const [fileToAdd, setFileToAdd] = useState(null);
    const [isDropping, setIsDropping] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        if (fileToAdd && onFileDropUpload) {
            onFileDropUpload(fileToAdd);
            setFileToAdd(null);
        }
    }, [fileToAdd, onFileDropUpload]);

    const uploader = useMediaFilesUploader(storageId, setFileToAdd);

    const handleDragEnter = useCallback((e) => {
        e.preventDefault();
        setIsDropping(true);
    }, []);
    const handelDragOver = useCallback((e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
    }, []);
    const handleDragEnd = useCallback((e) => {
        e.preventDefault();
        setIsDropping(false);
    }, []);
    const handleDrop = useCallback(
        (e) => {
            e.preventDefault();
            setIsDropping(false);
            uploader(
                e.dataTransfer.files,
                () => setIsUploading(true),
                () => setIsUploading(false)
            );
        },
        [uploader]
    );

    const handleBgClick = useCallback(() => {
        if (onSelection) onSelection(null);
    }, [onSelection]);

    const handleAction = useCallback(
        (action, id, payload) => {
            if (action === 'select' && onSelection) {
                onSelection(id);
            }

            if (onFilesChange) onFilesChange([id], action, payload);
        },
        [onFilesChange, onSelection]
    );

    return (
        <div
            className={styles.thumbnailsRoot}
            style={{
                background: isDropping ? 'var(--color-primary-light)' : 'none',
                opacity: isUploading ? 0.3 : 1,
            }}
            onClick={handleBgClick}
            onDragEnter={handleDragEnter}
            onDragOver={handelDragOver}
            onDragEnd={handleDragEnd}
            onDrop={handleDrop}
        >
            {mediaFiles && mediaFiles.length > 0 ? (
                mediaFiles.map((file, i) => (
                    <Thumbnail
                        key={file.name + i}
                        file={file}
                        lang={lang}
                        archiveMode={archiveMode}
                        isSelected={selected?.includes(file.id)}
                        onAction={(a, p) => handleAction(a, file.id, p)}
                    />
                ))
            ) : (
                <NoFiles lang={lang} />
            )}
        </div>
    );
};

const NoFiles = ({ lang }) => {
    return (
        <div className={styles.noFilesCont}>
            <Emoji symbol="👀" style={{ fontSize: 32 }} />
            {texts.noFiles[lang]}
        </div>
    );
};

const thumbTexts = texts.thumbnail;

const Thumbnail = ({ lang, file, archiveMode, isSelected, onAction }) => {
    const date = useMemo(() => {
        const d = new Date(file.date);
        return d.toLocaleDateString('he-IL');
    }, [file]);

    const [isHovered, setIsHovered] = useState(false);

    const handleClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onAction) onAction('select');
        },
        [onAction]
    );

    return (
        <div
            className={styles.thumbnailCont}
            style={{
                opacity: archiveMode ? 0.7 : 1,
                filter: archiveMode ? 'grayscale(1)' : 'none',
                border: isSelected
                    ? '1px solid var(--color-primary-medium)'
                    : 'var(--border-light)',
            }}
            onPointerEnter={() => setIsHovered(true)}
            onPointerLeave={() => setIsHovered(false)}
            onClick={handleClick}
        >
            <ThumbnailTopBar
                lang={lang}
                file={file}
                isHovered={isHovered}
                archiveMode={archiveMode}
                isSelected={isSelected}
                onAction={onAction}
            />
            {file.type === 'image' && (
                <img
                    draggable={false}
                    className={styles.thumbnail}
                    src={file.src}
                    alt={file.name}
                />
            )}
            {/*             
            @TBD embed youtube player or solve safari video formats compatability...
            {file.type === "video" && (
                <video className={styles.thumbnail} src={file.src} controls />
            )} */}
            {file.type === 'lottie' && (
                <Player
                    autoplay
                    hover={true}
                    src={file.src}
                    className={styles.thumbnail}
                />
            )}
            <label className={styles.fileName}>{file.name}</label>
            <label className={`${styles.fileDate} ${styles.fileMetaLabel}`}>
                {date}
            </label>
        </div>
    );
};

const ThumbnailTopBar = ({
    lang,
    file,
    isHovered,
    archiveMode,
    isSelected,
    onAction,
}) => {
    const opBtnRef = useRef();
    const [optionsActive, setOptionsActive] = useState(false);

    const options = useMemo(() => {
        const _options = [];
        if (archiveMode) {
            _options.push({ key: 'restore', label: thumbTexts.restore[lang] });
            _options.push({ key: 'delete', label: thumbTexts.delete[lang] });
        } else {
            _options.push({ key: 'archive', label: thumbTexts.archive[lang] });
        }

        return _options;
    }, [archiveMode, lang]);

    const handleOptions = useCallback(
        (op) => {
            if (onAction) onAction(op.key);
            setOptionsActive(false);
        },
        [onAction]
    );

    return (
        <div
            className={styles.thumbNailTopBar}
            style={!isHovered ? { background: 'none' } : {}}
        >
            {(isHovered || isSelected) && (
                <>
                    <IconButton
                        tooltip={thumbTexts.selectTooltip[lang]}
                        size={'s'}
                        colorSet={isSelected ? 'Primary' : 'Grayscale'}
                        bgStyle={{ marginInlineEnd: 'auto' }}
                        name="vi"
                        onClick={() => handleOptions({ key: 'select' })}
                    />
                </>
            )}
            <AccessibilityOptions
                isHovered={isHovered}
                lang={lang}
                file={file}
                onAction={onAction}
            />

            <div ref={opBtnRef} style={{ opacity: isHovered ? 1 : 0.3 }}>
                <IconButton
                    tooltip={thumbTexts.optionsTooltip[lang]}
                    size={'s'}
                    colorSet={'Grayscale'}
                    name="options"
                    onClick={() => setOptionsActive(true)}
                />
            </div>
            <SelectionPopper
                referenceElement={opBtnRef.current}
                isActive={optionsActive}
                onDismiss={() => setOptionsActive(false)}
                onSelect={handleOptions}
                options={options}
            />
        </div>
    );
};

const AccessibilityOptions = ({ isHovered, lang, file, onAction }) => {
    const [poperActive, setPoperActive] = useState(false);
    const btnRef = useRef();

    const supportedLanguages = useSelector(
        (state) =>
            state.surveyEditor.present.survey.settings.general
                .supportedLanguages
    );

    const handleChange = useCallback(
        (e, l) => {
            onAction('set_alt', { lang: l, value: e.target.value });
        },
        [onAction]
    );

    return (
        <>
            <IconButton
                ref={btnRef}
                name="accessibility"
                size="s"
                onClick={() => setPoperActive(true)}
            />
            <Modal
                isActive={poperActive}
                onClose={() => setPoperActive(false)}
                bgBlur={'none'}
            >
                <PopperPanel
                    referenceElement={btnRef.current}
                    panelStyle={{
                        padding: 16,
                        backgroundColor: 'var(--color-background-mild)',
                    }}
                >
                    <div className={styles.accessibilityPanelTopCont}>
                        <h3>{texts.accessibility.altTextTitle[lang]}</h3>
                        <IconButton
                            name="x"
                            onClick={() => setPoperActive(false)}
                            size="s"
                            theme="Plain"
                            colorSet="Grayscale"
                        />
                    </div>
                    {supportedLanguages.map((l, i) => (
                        <TextInput
                            key={i}
                            label={surveyLanguages[l].name[lang]}
                            contStyle={{ marginTop: 8 }}
                            value={file.alt ? file.alt[l] || '' : ''}
                            onChange={(e) => handleChange(e, l)}
                        />
                    ))}
                </PopperPanel>
            </Modal>
        </>
    );
};

export default MediaLibraryThumbnails;
