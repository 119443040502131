import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import styles from './ArgumentSetup.module.css';
import Button from 'components/Button';
import ReferenceDataSelector from './ReferenceDataSelector';
import { useSelector } from 'react-redux';
import { surveyEditorTexts } from 'utils/appTexts';
import {
    variablesTypes,
    argumentsTypes,
    userInputTypes,
} from './LogicConfiguration';
import DropDown from 'components/DropDown';
import TextInput, { TextTypes } from 'components/TextInput';
import MultipleSelect, { SelectionItem } from 'components/MultipleSelect';
import { getRawText } from 'components/rich_text/RichTextInput';
import { getNested } from 'utils/miscHelpers';
import { OPEN_ITEM } from '../surveyEditorConfiguration';

const texts = surveyEditorTexts.properties.logic.itemEditor.argumentSetup;

const modes = {
    ARG_NAME: 'argName',
    NUMBER: 'number',
    STRING: 'string',
    BOOLEAN: 'boolean',
    ARRAY: 'array',
};

const fieldsStyle = {
    height: 32,
    padding: '4px 6px',
    marginInlineEnd: '4px',
    width: '100%',
};

export default function ArgumentSetup({
    argInx,
    args,
    isHovered,
    disabled,
    onSelectType,
    onValueChange,
}) {
    const btnRef = useRef();

    const pagesData = useSelector(
        (state) => state.surveyEditor.present.survey.content.pagesData
    );
    const surveyVariables = useSelector(
        (state) => state.surveyEditor.present.survey.logic.variables
    );

    const [isPopperActive, setIsPopperActive] = useState(false);

    const [argName, setArgName] = useState('');
    const [mode, setMode] = useState(modes.ARG_NAME);

    const [arg, otherArg] = useMemo(() => {
        if (!args) return [null, null];
        return [args[argInx], args[1 - argInx]];
    }, [argInx, args]);

    const handleValueChange = useCallback(
        (value) => {
            if (onValueChange) onValueChange(value);
        },
        [onValueChange]
    );

    const otherArgAnswerItems = useMemo(() => {
        if (
            otherArg &&
            otherArg.type === argumentsTypes.USER_INPUT.key &&
            otherArg.inputType === userInputTypes.ANSWER
        ) {
            const pageData = pagesData[otherArg.page];
            if (pageData) {
                const pageType = pageData.type;
                if (['MULTIPLE_CHOICE', 'DROPDOWN_LIST'].includes(pageType)) {
                    const itemsOrder = pageData.itemsOrder;
                    const itemsData = pageData.itemsData;
                    const list = [];

                    itemsOrder.forEach((itemId) => {
                        const itemData = { ...itemsData[itemId] };
                        itemData.id = itemId;
                        list.push(itemData);
                    });

                    if (
                        getNested(
                            pageData,
                            'settings',
                            'general',
                            'showOpenItem'
                        )
                    ) {
                        const openItem = itemsData[OPEN_ITEM];
                        if (openItem) {
                            list.push({ id: OPEN_ITEM, ...openItem });
                        }
                    }

                    return list;
                }

                if (pageType === 'SCALE') {
                    const itemsData = pageData.scaleData;
                    const list = itemsData.map((item) => ({
                        id: item.key,
                        ...item,
                    }));

                    if (
                        getNested(
                            pageData,
                            'settings',
                            'general',
                            'showSecondaryButton'
                        ) &&
                        pageData.secondaryButtonData
                    ) {
                        list.push({
                            id: pageData.secondaryButtonData.key,
                            ...pageData.secondaryButtonData,
                        });
                    }

                    return list;
                }
            }
        }

        return null;
    }, [otherArg, pagesData]);

    const inputComp = useMemo(() => {
        switch (mode) {
            case modes.NUMBER:
                return (
                    <NumberInput
                        key="numberInput"
                        arg={arg}
                        onChange={handleValueChange}
                    />
                );
            case modes.STRING:
                return (
                    <StringInput
                        key="stringInput"
                        arg={arg}
                        onChange={handleValueChange}
                    />
                );
            case modes.BOOLEAN:
                return (
                    <BooleanInput
                        key="booleanInput"
                        arg={arg}
                        onChange={handleValueChange}
                    />
                );
            case modes.ARRAY:
                return (
                    <ArrayInput
                        key="arrayInput"
                        arg={arg}
                        items={otherArgAnswerItems}
                        onChange={handleValueChange}
                    />
                );
            default:
                return null;
        }
    }, [mode, arg, otherArgAnswerItems, handleValueChange]);

    const additionalOptions = useMemo(() => {
        const finalOptions = [];

        if (otherArgAnswerItems && otherArgAnswerItems.length > 0) {
            finalOptions.push({
                key: variablesTypes.ARRAY,
                label: texts.answersItemsArg['en'],
            });
        }

        return finalOptions;
    }, [otherArgAnswerItems]);

    const selectionBtnLabel = useMemo(() => {
        let name = '';
        if (mode === modes.ARG_NAME) {
            name = argName || texts.argSelectionPlaceholder['en'];
        }
        return name;
    }, [argName, mode]);

    const selectionBtnIcon = useMemo(() => {
        let icon = '';
        if (mode === modes.ARG_NAME) {
            icon = argName ? '' : 'chevron_down_arrow';
        } else {
            icon = 'options';
        }

        return icon;
    }, [argName, mode]);

    // Handle other arg: (set default mode and value by other arg type)
    useEffect(() => {
        // Skip if this is the arg on the left side of the rule, or if the other arg is not set
        if (argInx === 0 || !otherArg || !otherArg.type) return;

        if (
            otherArg.type === argumentsTypes.SURVEY_VARIABLES.key &&
            !arg.type
        ) {
            // If this arg is not defined yet, assign constant to match variable type:
            if (surveyVariables && surveyVariables.length > 0) {
                const varData = surveyVariables.find(
                    (x) => x.id === otherArg.variable
                );

                if (!varData || varData.type === arg.variableType) return;
                const value =
                    varData.type === variablesTypes.BOOLEAN ? 'true' : '';
                onSelectType({
                    type: argumentsTypes.CONSTANT.key,
                    variableType: varData.type,
                    value: value,
                });
            }
        }

        // @PATCH: Reset arg value if otherArg of type USER_INPUT changes.
        // @TODO: Solve this with better args management.
        if (
            otherArg?.type === argumentsTypes.USER_INPUT.key &&
            Array.isArray(arg.value) &&
            otherArgAnswerItems
        ) {
            arg.value.forEach((ansId) => {
                const found = otherArgAnswerItems.find(
                    (item) => item.id === ansId
                );
                if (!found) {
                    handleValueChange([]);
                }
            });
        }
    }, [
        argInx,
        arg,
        otherArg,
        surveyVariables,
        onSelectType,
        otherArgAnswerItems,
        handleValueChange,
    ]);

    // Handle arg:
    useEffect(() => {
        if (!arg) return;

        if (
            !arg.type &&
            otherArgAnswerItems &&
            otherArgAnswerItems.length > 0
        ) {
            onSelectType({
                type: argumentsTypes.CONSTANT.key,
                variableType: variablesTypes.ARRAY,
                value: [],
            });
            setArgName('');
            setMode(modes.ARRAY);
            return;
        }

        if (arg.type === argumentsTypes.SURVEY_VARIABLES.key) {
            if (surveyVariables && surveyVariables.length > 0) {
                const varData = surveyVariables.find(
                    (x) => x.id === arg.variable
                );
                if (varData) {
                    setArgName(varData.name);
                    setMode(modes.ARG_NAME);
                } else {
                    return;
                }
            }
        }

        if (arg.type === argumentsTypes.USER_INPUT.key) {
            const pageData = pagesData[arg.page];
            if (!pageData) {
                setArgName('');
                return;
            }

            const inputType = arg.inputType;

            if (
                inputType === userInputTypes.ANSWER ||
                inputType === userInputTypes.ANSWER_INDEX
            ) {
                if (arg.item) {
                    const itemData = pageData.itemsData[arg.item];

                    if (itemData) {
                        setArgName(
                            `${pageData.key}.${itemData.key}.${inputType}`
                        );
                    }
                } else {
                    setArgName(`${pageData.key}.${inputType}`);
                }
                setMode(modes.ARG_NAME);
                return;
            }

            setArgName(`${pageData.key}.${inputType}`);
            setMode(modes.ARG_NAME);
        }

        if (arg.type === argumentsTypes.CONSTANT.key) {
            setArgName('');
            const type = arg.variableType;
            if (type === variablesTypes.NUMBER) {
                setMode(modes.NUMBER);
            }
            if (type === variablesTypes.STRING) {
                setMode(modes.STRING);
            }
            if (type === variablesTypes.BOOLEAN) {
                setMode(modes.BOOLEAN);
            }
            if (type === variablesTypes.ARRAY) {
                setMode(modes.ARRAY);
            }
            if (type === variablesTypes.URL_PARAM) {
                setMode(modes.STRING);
            }
            if (type === variablesTypes.DATASOURCE) {
                setMode(modes.STRING);
            }
        }

        if (arg.type === argumentsTypes.RANDOM.key) {
            setArgName('Random()');
            setMode(modes.ARG_NAME);
        }

        if (!arg.type) {
            setArgName('');
            setMode(modes.ARG_NAME);
        }
    }, [arg, otherArgAnswerItems, surveyVariables, pagesData, onSelectType]);

    const handleSelect = useCallback(
        (selected) => {
            const argInfo = selected.split('.');
            const type = argInfo[0];
            switch (type) {
                case argumentsTypes.SURVEY_VARIABLES.key:
                    onSelectType({
                        type,
                        variable: argInfo[1],
                    });
                    break;

                case argumentsTypes.USER_INPUT.key:
                    onSelectType({
                        type,
                        page: argInfo[1],
                        inputType: argInfo[2],
                        item: argInfo.length > 3 ? argInfo[3] : null,
                    });
                    break;

                case argumentsTypes.CONSTANT.key:
                    onSelectType({
                        type,
                        variableType: argInfo[1],
                        value:
                            argInfo[1] === variablesTypes.BOOLEAN ? 'true' : '',
                    });
                    break;

                case variablesTypes.ARRAY:
                    onSelectType({
                        type: argumentsTypes.CONSTANT.key,
                        variableType: variablesTypes.ARRAY,
                        value: [],
                    });
                    break;

                case argumentsTypes.RANDOM.key:
                    onSelectType({
                        type: argumentsTypes.RANDOM.key,
                    });
                    break;

                default:
                    break;
            }

            setIsPopperActive(false);
        },
        [onSelectType]
    );

    ////////////
    // RENDER //
    ////////////

    return (
        <>
            <div ref={btnRef} className={styles.argCont}>
                {inputComp}
                <Button
                    label={selectionBtnLabel}
                    iconAfter={selectionBtnIcon}
                    tooltip={
                        arg && arg.type
                            ? texts.argChangeTooltip['en']
                            : texts.argSelectionTooltip['en']
                    }
                    theme="Outlined"
                    colorSet="Field"
                    bgStyle={{
                        minWidth: 24,
                        width: mode === modes.ARG_NAME ? '100%' : 'fit-content',
                        boxSizing: 'border-box',
                        padding: 8,
                        height: fieldsStyle.height,
                        // backgroundColor: 'var(--color-background-mild)'
                    }}
                    labelStyle={{
                        fontFamily: !argName ? '' : 'Plex-Mono',
                        fontSize: !argName ? 18 : 16,
                        marginBottom: !argName ? 0 : 4,
                        color: `rgba(0,0,0, ${
                            disabled ? 0.2 : argName ? 1 : 0.5
                        })`,
                    }}
                    iconStyle={{
                        opacity: isHovered ? 1 : 0.3,
                    }}
                    onClick={() => setIsPopperActive(true)}
                    disabled={disabled}
                />
            </div>
            <ReferenceDataSelector
                referenceElement={btnRef.current}
                isActive={isPopperActive}
                additionalOptions={additionalOptions}
                onDismiss={() => setIsPopperActive(false)}
                onSelect={handleSelect}
                language="en"
                dir="ltr"
            />
        </>
    );
}

const NumberInput = ({ arg, onChange }) => {
    return (
        <TextInput
            type={TextTypes.NUMBER}
            className={styles.argInput}
            fieldStyle={{
                height: fieldsStyle.height,
                width: fieldsStyle.width,
                padding: fieldsStyle.padding,
                font: 'var( --font-code )',
            }}
            value={arg.value || ''}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};

const StringInput = ({ arg, onChange }) => {
    return (
        <TextInput
            type={TextTypes.TEXT}
            className={styles.argInput}
            fieldStyle={{
                height: fieldsStyle.height,
                width: fieldsStyle.width,
                padding: fieldsStyle.padding,
                font: 'var( --font-code )',
            }}
            value={arg.value || ''}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};

const BooleanInput = ({ arg, onChange }) => {
    const handleChange = useCallback(
        (selected) => {
            // const isTrue = selected.key === 'true'; console.log( 'isTrue:', isTrue)
            onChange(selected.key);
        },
        [onChange]
    );

    return (
        <DropDown
            bgStyle={{
                width: '100%',
                height: fieldsStyle.height,
                padding: fieldsStyle.padding,
            }}
            contStyle={{
                width: fieldsStyle.width,
                marginInlineEnd: 8,
                boxSizing: 'border-box',
            }}
            labelStyle={{
                font: 'var( --font-code )',
            }}
            options={[
                { key: 'true', label: 'true' },
                { key: 'false', label: 'false' },
            ]}
            value={arg.value ? arg.value.toString() : 'true'}
            onChange={handleChange}
            dir="ltr"
        />
    );
};

const ArrayInput = ({ arg, items, onChange }) => {
    const surveyLang = useSelector(
        (state) => state.surveyEditor.present.display.currentLanguage
    );

    const options = useMemo(() => {
        if (!items)
            return (
                <span style={{ color: 'var(--color-error-medium)' }}>
                    No Items
                </span>
            );

        const renderList = [];

        items.forEach((item) => {
            if (!item.key) return;

            const isSelected =
                arg.value &&
                arg.value.length > 0 &&
                arg.value.includes(item.id);

            let itemText = '...';
            if (item.text) {
                itemText = getRawText(item.text[surveyLang]);
            }

            renderList.push(
                <SelectionItem
                    key={item.id}
                    label={item.key}
                    isSelected={isSelected}
                >
                    <span> {itemText} </span>
                    <span
                        style={{
                            font: 'var(--font-code)',
                            marginInlineStart: 4,
                        }}
                    >
                        {` — ${item.key}`}
                    </span>
                </SelectionItem>
            );
        });

        return renderList;
    }, [arg, items, surveyLang]);

    const handleSelect = useCallback(
        (selected) => {
            const newList = Array.from(arg.value);
            const targetInx = newList.indexOf(selected);
            if (targetInx > -1) {
                newList.splice(targetInx, 1);
            } else {
                newList.push(selected);
            }

            onChange(newList);
        },
        [arg, onChange]
    );

    return (
        <MultipleSelect
            bgStyle={{
                height: fieldsStyle.height,
                padding: fieldsStyle.padding,
            }}
            contStyle={{
                marginInlineEnd: 8,
                width: '100%',
                boxSizing: 'border-box',
            }}
            labelStyle={{
                font: 'var( --font-code )',
            }}
            // options={ options }
            onChange={handleSelect}
            dir="ltr"
        >
            {options}
        </MultipleSelect>
    );
};
