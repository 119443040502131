import { selectUiLanguage } from "app/preferencesSlice";
import "components/Split.css";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Split from "react-split"; //https://github.com/nathancahill/split/tree/master/packages/splitjs#options
import SurveyEditorContent from "features/survey_editor/content_area/SurveyEditorContent";
import EditingLock from "features/survey_editor/EditingLock";
import SurveyEditorPreview from "features/survey_editor/preview_area/SurveyEditorPreview";
import SurveyEditorProperties from "features/survey_editor/properties_area/SurveyEditorProperties";
import styles from "features/survey_editor/SurveyEditorMain.module.css";
import EditorMessages from "features/survey_editor/SurveyEditorMessages";
import EdirotSideBar from "features/survey_editor/SurveyEditorSideBar";
import MilgoSurveyTopBar from "./MilgoSurveyTopBar";
import appStyles from 'app/App.module.css';
import MilgoUserSettings from "./MilgoUserSettings";

export const splitViewModes = {
    NORMAL: "NORMAL",
    COLLAPSED_PROPERTIES: "COLLAPSED_PROPERTIES",
    EXTENDED_PROPERTIES: "EXTENDED_PROPERTIES",
    DESKTOP_VIEW: "DESKTOP_VIEW",
    MOBILE_VIEW: "MOBILE_VIEW",
};

export default function SurveyEditorMain() {
    const uiLanguage = useSelector(selectUiLanguage);
    const appUiDirection = uiLanguage === 'en' ? 'ltr' : 'rtl';
    return (
        <div
            className={appStyles.app_root}
            style={{ direction: appUiDirection }}
        >
            <MilgoSurveyTopBar />
            <MilgoUserSettings />
            <div
                className={styles.surveyEditorMainView}
                style={{ top: 44 }}
            >
                <EdirotSideBar />
                <SplitView />
                <EditingLock />
                <EditorMessages />
            </div>
        </div>
    );
}

const SplitView = () => {
    const lang = useSelector(selectUiLanguage);
    const isLtr = lang === "en";
    const splitViewMode = useSelector(
        (state) => state.surveyEditor.present.display.splitViewMode
    );

    const [animate, setAnimate] = useState(true);

    const transition = useMemo(() => {
        return animate ? "width 0.3s ease-out" : "none";
    }, [animate]);

    const sizes = useMemo(() => {
        switch (splitViewMode) {
            case splitViewModes.COLLAPSED_PROPERTIES:
                return isLtr ? [0, 50, 50] : [50, 50, 0];

            case splitViewModes.EXTENDED_PROPERTIES:
                return isLtr ? [75, 25, 0] : [0, 25, 75];

            case splitViewModes.NORMAL:
                return isLtr ? [24, 38, 38] : [38, 38, 24];

            case splitViewModes.MOBILE_VIEW:
                return isLtr ? [0, 50, 50] : [50, 50, 0];

            case splitViewModes.DESKTOP_VIEW:
                return isLtr ? [0, 30, 70] : [70, 30, 0];

            default:
                return isLtr ? [24, 38, 38] : [38, 38, 24];
        }
    }, [isLtr, splitViewMode]);

    // const getWidth = useCallback( (dimeniton,elementSize) => {
    //     return ({ 'width': `${elementSize}%` });
    // },[])

    if (isLtr) {
        return (
            <Split
                className={styles.splitContainer}
                sizes={sizes}
                minSize={[0, 200, 0]}
                onDragStart={() => setAnimate(false)}
                onDragEnd={() => setAnimate(true)}
                gutterSize={6}
                snapOffset={10}
                dir="ltr"
                cursor="col-resize"
            >
                <div className={styles.propertiesArea} style={{ transition }}>
                    <SurveyEditorProperties />
                </div>
                <div className={styles.contentArea} style={{ transition }}>
                    <SurveyEditorContent />
                </div>
                <div className={styles.previewArea} style={{ transition }}>
                    <SurveyEditorPreview />
                </div>
            </Split>
        );
    } else {
        return (
            <Split
                className={styles.splitContainer}
                sizes={sizes}
                onDragStart={() => setAnimate(false)}
                onDragEnd={() => setAnimate(true)}
                minSize={[0, 200, 0]}
                gutterSize={6}
                snapOffset={10}
                dir="ltr"
                cursor="col-resize"
            >
                <div
                    className={styles.previewArea}
                    style={{ transition }}
                    dir="rtl"
                >
                    <SurveyEditorPreview />
                </div>
                <div
                    className={styles.contentArea}
                    style={{ transition }}
                    dir="rtl"
                >
                    <SurveyEditorContent />
                </div>
                <div
                    className={styles.propertiesArea}
                    style={{ transition }}
                    dir="rtl"
                >
                    <SurveyEditorProperties />
                </div>
            </Split>
        );
    }
};
