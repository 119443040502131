import React, { useEffect, useState } from 'react';
import styles from './Insights.module.css';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { insightsTexts } from '../../utils/appTexts';
import { selectUiLanguage } from '../../app/preferencesSlice';
import { selectProjectById, fetchProjectById } from '../../app/horizonDataSlice';
import * as ReportsSlice from '../../app/reportSlice';
import PageStatsItem from './PageStatsItem';
import Button from '../../components/Button';
import Emoji from '../../components/Emoji';
import InsightsGraph from './InsightsGraph';
import InsightsGraphAnswerRate from './InsightsGraphAnswerRate';
import { FormatTime } from '../../utils/stylingTools';
import IconButton from '../../components/IconButton';

export default function Insights(props) {
    
    const { projectId } = useParams();
    const lang = useSelector( selectUiLanguage );

    const dispatch = useDispatch();

    const project = useSelector( state => selectProjectById( state, projectId ) );
    const fetchProjectByIdStatus = useSelector( state => state.horizonData.fetchProjectByIdStatus );

    const bucketId = useSelector( state => state.report.bucketId );
    const dataStats = useSelector( state => state.report.dataStats );
    const fetchStatus = useSelector( state => state.report.fetchStatus );
    const sampleRecordsCount = useSelector( state => state.report.sampleRecordsCount );
    const totalRecordsCount = useSelector( state => state.report.totalRecordsCount );

    const insightsPreferences = useSelector( state => state.report.insightsPreferences );
    const { insightsPrefsFetchStatus, rawPrefsData, funnelData } = insightsPreferences;
    const serverFunnel = rawPrefsData.funnel;
    const { funnelFields, initialFunnel } = funnelData;

    const [isLoading, setIsLoading] = useState( false );
    const [funnel, setFunnel] = useState( null );
    const [dropOffGraph, setDropOffGraph] = useState( true );
    const [stepsCounts, setStepsCounts] = useState( [] ); // Fields count for displaying drop-off info
    const [fieldsOptions, setFieldsOptions] = useState( [] ); // Fields available for adding to steps


    useEffect( () => {

        // If project id exists but the project data hasn't been fetched yet, fetch this project firts.

        if( projectId ) {
            if( fetchProjectByIdStatus === 'idle' ) {
                dispatch( fetchProjectById( projectId ));
            }
        }

    }, [projectId,project,dispatch,fetchProjectByIdStatus])

    useEffect( () => {

        // Once the project data has been fetched from the server and updated in the redux store, we can dispatch an action to fetch the report data:

        if( project ) {

            if( fetchStatus === 'idle' ) getData(); 
                
            if( fetchStatus === 'succeeded' ) {

                if( bucketId !== project.bucket.id ) {
                    
                    // should fetch another report.
                    getData();
                    return;
                }
                
                setIsLoading( false );

            } 

            if( project.bucket && insightsPrefsFetchStatus === 'idle' ) {

                dispatch( ReportsSlice.fetchInsightsPrefs( project.bucket.id ) );

            } 
            
        }

        function getData() {

            setIsLoading( true );
            dispatch( ReportsSlice.fetchReport( project.sourceName ) );
    
        }

    }, [project, bucketId, fetchStatus, dispatch, insightsPrefsFetchStatus])

    useEffect( () => {

        if( serverFunnel && serverFunnel.length >= 1 ) {

            const copy = serverFunnel.map((item) => [...item] )
            setFunnel( copy );

        } else {

            const copy = initialFunnel.map((item) => [...item] )
            setFunnel( copy );
        }

    }, [serverFunnel, initialFunnel] )

    useEffect( () => {

        // On funnel update, recaluculate the following:

        if( !funnel || !dataStats || !funnelFields ) return;
        
        const stepsCounts = [];
        const options = Object.keys( funnelFields );

        for( let i=0; i<funnel.length; i++ ) {
            const arr = funnel[ i ];
            let totalCount = 0;
            for( let j=0; j<arr.length; j++ ) {

                if( !dataStats[ arr[ j ] ] ) {
                    console.log( dataStats, arr[ j ] );
                    break;
                }
                const count = dataStats[ arr[ j ] ].count;
                totalCount += count; 

                const removeIndex = options.indexOf( arr[ j ] );
                options.splice( removeIndex, 1 );
            }
            stepsCounts.push( totalCount );
        }
        setStepsCounts( stepsCounts );

        setFieldsOptions( options );

    }, [funnel, dataStats, funnelFields])

    
    function addFunnelStep( addIndex ) {

        const newFunnel = [ ...funnel ];
        newFunnel.splice( addIndex, 0, []);
        setFunnel( newFunnel );

    }

    function removeFunnelStep( removeIndex ) {

        const newFunnel = [ ...funnel ];
        newFunnel.splice( removeIndex, 1 );
        setFunnel( newFunnel );

    }

    function addFieldToFunnelStep( stepInx, key ) {

        const newFunnel = [ ...funnel ];
        newFunnel[ stepInx ].push( key );
        setFunnel( newFunnel );
    }

    function removeFieldFromFunnelStep( stepInx, keyInx ) {

        const newFunnel = [ ...funnel ];
        newFunnel[ stepInx ].splice( keyInx, 1 );
        setFunnel( newFunnel );
    }

    function saveFunnel() {

        const prefs = { ...rawPrefsData }

        prefs.funnel = funnel;
        dispatch( ReportsSlice.updateInsightPrefs( { bucketId, prefs } ));

    }

    function resetFunnel() {

        const prefs = { ...rawPrefsData }

        delete prefs.funnel;
        dispatch( ReportsSlice.updateInsightPrefs( { bucketId, prefs } ));
    }

    function changeDropOffGraph() {

        setDropOffGraph( true );

    }

    function changeParticipantsGraph() {

        setDropOffGraph( false );

    }

    function getAvgTimeToCompletion() {

        let totalMs = 0;

        for( let i=0; i<funnel.length; i++ ) {
            const arr = funnel[ i ];

            let stepTimeSum = 0;
            let stepTimesCount = 0;
            for( let j=0; j<arr.length; j++ ) {
                const time = funnelFields[ arr[ j ] ].time;

                if( time ) {
                    stepTimeSum += time; 
                    stepTimesCount++;
                }
            }
            
            if( stepTimesCount > 0 ) {

                const avgStepTime = stepTimeSum / stepTimesCount;
                totalMs += avgStepTime;
            }
            
        }

        return FormatTime( totalMs )

    }

    if( !project || !funnel || isLoading ) {
        // should load a new report. TODO: add a nice loading animation"
        return (
            <div className={ styles.loadingContainer }>
                <p className={ styles.loadingHeadline } >Loading...</p>
                <p className={ styles.loadingSubHeadline } >your computer is doing magic <Emoji style={{color: 'black', display: 'inline'}} symbol="✨" label="stars" /> </p> 
            </div>
        )
    }

    return (
        // <div id="top_bar">
            <div className={ styles.mainContainer }>
                <div className={ styles.innerContainer}>
                    <div className={ styles.topContainer }>
                        <div id={ styles.headlineCont }>
                            <h3 className={ styles.headline }>{ insightsTexts.projectStats[ lang ] }</h3>
                            <div id={styles.noteCont}>
                                <p id={styles.responseNumber}>{
                                    insightsTexts.responseCountInfo[ lang ]
                                    .replace('{sample}', sampleRecordsCount ? sampleRecordsCount : '—' )
                                    .replace('{total}', totalRecordsCount ? totalRecordsCount: '—' ) 
                                }</p>
                                <IconButton 
                                    name='i'
                                    tooltip='To keep computation time low we limit the number of responses'
                                    theme = 'Outlined'
                                    size = 'xs'
                                    colorSet = 'Grayscale'
                                    bgStyle = {{
                                        width: 5,
                                        height: 5,
                                        marginTop: 10
                                    }}
                                />
                            </div>
                        </div>
                        
                        <div className={ styles.actionBarContainer }>
                            {/* <DropDown label={'temporary - version x'}/>
                            <div style={{flexGrow: 1}} />
                            <Button 
                                label = { insightsTexts.getDataBtn[ lang ] }
                                iconBefore = "download"
                                theme = 'Full'
                                size = 'm'
                                colorSet = 'Primary'
                                disabled = { true } // Temporary until implemented
                                bgStyle={{ alignSelf: 'flex-start' }}
                            /> */}
                        </div>

                        <div className={ styles.surveyStatsContainer }>
                            <div className={ styles.statItem }>
                                <div className={ styles.statHeadline }>
                                    { insightsTexts.startsTitle[ lang ] }
                                </div>
                                <div className={ styles.surveyStat }>
                                { stepsCounts[ 0 ] }
                                </div>
                            </div>
                            <div className={ styles.gap } />
                            <div className={ styles.statItem }>
                                <div className={ styles.statHeadline }>
                                    { insightsTexts.completionsTitle[ lang ] }
                                </div>
                                <div className={ styles.surveyStat }>
                                { stepsCounts[ stepsCounts.length - 1 ] }
                                </div>
                            </div>
                            <div className={ styles.gap } />
                            <div className={ styles.statItem }>
                                <div className={ styles.statHeadline }>
                                    { insightsTexts.completionRateTitle[ lang ] }
                                </div>
                                <div className={ styles.surveyStat }>
                                { Math.round( 100 * stepsCounts[ stepsCounts.length - 1 ] / stepsCounts[ 0 ] ) + "%"}
                                </div>
                            </div>
                            <div className={ styles.gap } />
                            <div className={ styles.statItem }>
                                <div className={ styles.statHeadline }>
                                    { insightsTexts.averageProgressionTitle[ lang ] }
                                </div>
                                <div className={ styles.surveyStat }>
                                    —
                                </div>
                            </div>
                            <div className={ styles.gap } />
                            <div className={ styles.statItem }>
                                <div className={ styles.statHeadline }>
                                    { insightsTexts.completionTimeTitle[ lang ] }
                                </div>
                                <div className={ styles.surveyStat }>
                                    { getAvgTimeToCompletion() }
                                </div>
                            </div>
                        </div>
                        <div className={ styles.mainGraphContainer }>
                            <div id={ styles.graphBtnCont }>
                                <Button
                                    label = { insightsTexts.dropOffGraphTitle[ lang ] }
                                    theme = 'Plain'
                                    size = 's'
                                    colorSet = 'Grayscale'
                                    bgStyle = {{
                                        backgroundColor: dropOffGraph ? 'var( --color-primary-light )' : '',
                                    }}
                                    labelStyle = {{
                                        fontSize: 16
                                    }}
                                    onClick =  {() => {changeDropOffGraph()}}
                                />
                                <Button
                                    label = { insightsTexts.completionOverTimeTitle[ lang ] }
                                    theme = 'Plain'
                                    size = 's'
                                    colorSet = 'Grayscale'
                                    bgStyle = {{
                                        backgroundColor: !dropOffGraph ? '#ECE1FD' : '',
                                        margin: '0px 12px'
                                    }}
                                    labelStyle = {{
                                        fontSize: 16
                                    }}
                                    onClick =  {() => {changeParticipantsGraph()}}
                                />
                            </div>
                            <div id={styles.graphCont}>
                                { dropOffGraph ?
                                    <InsightsGraph stepsCounts={ stepsCounts } /> :
                                    <InsightsGraphAnswerRate stepsCounts={ stepsCounts } />
                                }
                            </div>
                            
                        </div>
                    </div>
                    <div className={ styles.bottomContainer }>
                        <h3 className={ styles.headline }>{ insightsTexts.pageStats[ lang ] }</h3>
                        
                        <div className={ styles.subheadlineContainer }>
                            <p className={ styles.subHeadlineTime }> { insightsTexts.timeHeadLine[ lang ] } </p>
                            <p className={ styles.subHeadline } > { insightsTexts.dropOffHeadLine[ lang ] } </p>
                        </div>
                        {
                            funnel && funnel.map( (step,i) => {

                                return <PageStatsItem 
                                    key = { "step" + i }
                                    stepIndex = { i } 
                                    fields = { [ ...step ] }
                                    fieldsOptions = { fieldsOptions }
                                    fieldsData = { funnelFields }
                                    stepsCounts = { stepsCounts }
                                    onAddField = { ( key ) => addFieldToFunnelStep(i, key) }
                                    onRemoveField = { ( inx ) => removeFieldFromFunnelStep( i, inx ) }
                                    onAddStep = { () => addFunnelStep( i + 1 ) }
                                    onDelete = { () => removeFunnelStep( i )}
                                />
                            })
                        }
                        <div style={{display:'flex', margin: 48 }}>
                            <Button 
                                label={insightsTexts.saveFunnelBtn[ lang ]} 
                                onClick={ saveFunnel } 
                            />
                            <div style={{width:16}}/>
                            <Button 
                                label={insightsTexts.resetFunnelBtn[ lang ]} 
                                theme="Plain"
                                onClick={ resetFunnel } 
                            />
                        </div>
                    </div>
                </div>
            </div>
        // </div>
    )
}

// function StatItem({title, value}) {
//     return(
//         // <div className={styles.}
//     )
// }