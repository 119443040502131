import IconButton from "components/IconButton";
import React, { useCallback } from "react";
import styles from "./SurveyEditorSideBar.module.css";
import { useSelector, useDispatch } from "react-redux";
import { selectUiLanguage } from "app/preferencesSlice";
import { displayStateChanged, setCurrentLogicItem } from "./surveyEditorSlice";
import { modes as propertiesModes } from "./properties_area/SurveyEditorProperties";
import { surveyEditorTexts } from "utils/appTexts";
import { splitViewModes } from "features/survey_editor/SurveyEditorMain";

const sidebarTexts = surveyEditorTexts.sidebar;

const actions = {
    SURVEY_SETTINGS: "survey_settings",
    PAGE_SETTINGS: "page_settings",
    PUBLISH_AND_EXPORT: "publish_and_export",
    LOGIC: "logic",
    MEDIA: "media",
};

export default function SurveyEditorSideBar() {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const mode = useSelector(
        (state) => state.surveyEditor.present.display.propertiesMode
    );
    const splitViewMode = useSelector(
        (state) => state.surveyEditor.present.display.splitViewMode
    );

    const handleAction = useCallback(
        (action) => {
            let targetsplitViewMode = splitViewModes.NORMAL;
            let targetMode = propertiesModes.SURVEY;

            switch (action) {
                case actions.SURVEY_SETTINGS:
                    if (
                        mode === propertiesModes.SURVEY &&
                        splitViewMode !== splitViewModes.COLLAPSED_PROPERTIES
                    ) {
                        targetsplitViewMode =
                            splitViewModes.COLLAPSED_PROPERTIES;
                    } else targetsplitViewMode = splitViewModes.NORMAL;

                    targetMode = propertiesModes.SURVEY;
                    break;

                case actions.PAGE_SETTINGS:
                    if (
                        mode === propertiesModes.PAGE &&
                        splitViewMode !== splitViewModes.COLLAPSED_PROPERTIES
                    ) {
                        targetsplitViewMode =
                            splitViewModes.COLLAPSED_PROPERTIES;
                    } else targetsplitViewMode = splitViewModes.NORMAL;

                    targetMode = propertiesModes.PAGE;
                    break;

                case actions.PUBLISH_AND_EXPORT:
                    if (
                        mode === propertiesModes.PUBLISH_AND_EXPORT &&
                        splitViewMode !== splitViewModes.COLLAPSED_PROPERTIES
                    ) {
                        targetsplitViewMode =
                            splitViewModes.COLLAPSED_PROPERTIES;
                    } else targetsplitViewMode = splitViewModes.NORMAL;

                    targetMode = propertiesModes.PUBLISH_AND_EXPORT;
                    break;

                case actions.LOGIC:
                    if (
                        mode === propertiesModes.LOGIC &&
                        splitViewMode !== splitViewModes.COLLAPSED_PROPERTIES
                    ) {
                        targetsplitViewMode =
                            splitViewModes.COLLAPSED_PROPERTIES;
                    } else targetsplitViewMode = splitViewModes.NORMAL;

                    targetMode = propertiesModes.LOGIC;
                    break;

                case actions.MEDIA:
                    if (
                        mode === propertiesModes.MEDIA &&
                        splitViewMode !== splitViewModes.COLLAPSED_PROPERTIES
                    ) {
                        targetsplitViewMode =
                            splitViewModes.COLLAPSED_PROPERTIES;
                    } else targetsplitViewMode = splitViewModes.NORMAL;

                    targetMode = propertiesModes.MEDIA;
                    break;

                default:
                    break;
            }

            dispatch(setCurrentLogicItem(null));
            dispatch(
                displayStateChanged({
                    property: "splitViewMode",
                    value: targetsplitViewMode,
                })
            );
            dispatch(
                displayStateChanged({
                    property: "propertiesMode",
                    value: targetMode,
                })
            );
        },
        [mode, splitViewMode, dispatch]
    );

    return (
        <div className={styles.sideBarCont}>
            <SideBarIcon
                name="gear"
                tooltip={sidebarTexts.surveySettingsTooltip[lang]}
                onClick={() => handleAction(actions.SURVEY_SETTINGS)}
                isCurrent={
                    splitViewMode !== splitViewModes.COLLAPSED_PROPERTIES &&
                    mode === propertiesModes.SURVEY
                }
            />
            <SideBarIcon
                name="settings"
                tooltip={sidebarTexts.pageSettingsTooltip[lang]}
                onClick={() => handleAction(actions.PAGE_SETTINGS)}
                isCurrent={
                    splitViewMode !== splitViewModes.COLLAPSED_PROPERTIES &&
                    mode === propertiesModes.PAGE
                }
            />
            <SideBarIcon
                name="logic"
                tooltip={sidebarTexts.logicTooltip[lang]}
                onClick={() => handleAction(actions.LOGIC)}
                isCurrent={
                    splitViewMode !== splitViewModes.COLLAPSED_PROPERTIES &&
                    mode === propertiesModes.LOGIC
                }
            />
            <SideBarIcon
                name="media"
                tooltip={sidebarTexts.mediaTooltip[lang]}
                onClick={() => handleAction(actions.MEDIA)}
                isCurrent={
                    splitViewMode !== splitViewModes.COLLAPSED_PROPERTIES &&
                    mode === propertiesModes.MEDIA
                }
            />
            <SideBarIcon
                name="export"
                tooltip={sidebarTexts.publishTooltip[lang]}
                onClick={() => handleAction(actions.PUBLISH_AND_EXPORT)}
                isCurrent={
                    splitViewMode !== splitViewModes.COLLAPSED_PROPERTIES &&
                    mode === propertiesModes.PUBLISH_AND_EXPORT
                }
            />
        </div>
    );
}

function SideBarIcon({ name, tooltip, onClick, isCurrent }) {
    return (
        <div className={styles.sideBarIconCont}>
            {isCurrent && <div className={styles.currentModeIndicator} />}
            <IconButton
                name={name}
                theme="Plain"
                size="m"
                colorSet="Grayscale"
                bgStyle={{ borderRadius: 0, margin: 0 }}
                onClick={onClick}
                tooltip={tooltip}
            />
        </div>
    );
}
