import React, { useCallback, useMemo, useState } from 'react';
import styles from './PageLogicPhase.module.css';
import { Draggable } from 'react-beautiful-dnd';
import IconButton from 'components/IconButton';
import { Editable } from 'components/Editable';
import { surveyEditorTexts } from 'utils/appTexts';
import { useDispatch, useSelector } from 'react-redux';
import { displayStateChanged, logicItemChanged, logicItemDeleted, messageAdded, selectPageProperty, setCurrentLogicItem } from '../surveyEditorSlice';
import { pageTypesConfig } from 'features/survey_editor/surveyEditorConfiguration';
import { splitViewModes } from 'features/survey_editor/SurveyEditorMain';

const texts = surveyEditorTexts.properties.logic;

export default function LogicPhaseListItem ({lang, itemId, item, index}) {

    const dispatch = useDispatch();    
    const pageType = useSelector( state => selectPageProperty( state, item.pageId, 'type' ) );
    const currentLogicItem = useSelector( state => state.surveyEditor.present.display.currentLogicItem );

    const isCurrent = useMemo( () => currentLogicItem === itemId, [currentLogicItem,itemId] );
    const [isHovered, setIsHovered] = useState( false );

    ///////////
    // UTILS //
    ///////////
    const handleItemClick = useCallback( () => {

        dispatch( setCurrentLogicItem( itemId ) );
        dispatch( displayStateChanged({ property: "splitViewMode", value: splitViewModes.EXTENDED_PROPERTIES }))

    }, [itemId, dispatch])
    
    const handleTitleChange = useCallback( (value) => {
        
        dispatch( logicItemChanged( {id: itemId, prop: 'title', value}));

    },[itemId, dispatch] )

    const handleDelete = useCallback( () => {

        dispatch( logicItemDeleted( itemId ) );
        dispatch( messageAdded( { type: 'UNDO', args: texts.logicItemDeletedMsg } ));

    }, [itemId, dispatch])

    if( !item ) return null;

    return (
        <Draggable key={ itemId } draggableId={ itemId } index={index}>
        {(provided) => (
            <div 
                ref={ provided.innerRef }
                { ...provided.draggableProps }
                className={ styles.itemCont }
                onMouseEnter={ () => setIsHovered( true )}
                onMouseLeave={ () => setIsHovered( false )}
                onBlur={ () => setIsHovered( false )}

            >
                <div
                    className={ styles.itemBg }
                    style={{
                        borderColor: pageTypesConfig[pageType].color + (isCurrent ? 'dd' : '6a'),
                        backgroundColor: `rgba( 255,255,255,${ isHovered? 0.6 : isCurrent ? 0.8 : 0.4 })`
                    }}
                    onClick={ handleItemClick }
                >
                    <div 
                        className={ styles.dragHandle }
                        style={{
                            opacity: isHovered ? 1 : 0.2
                        }}
                        {...provided.dragHandleProps}
                        tabIndex="-1"
                    />
                    <Editable
                        value={ item.title }
                        placeholder={ texts.createLogicItem[ lang ] }
                        className={ styles.itemTitleInput }
                        onChange={ handleTitleChange }
                    />
                </div>
                <IconButton
                    name='delete'
                    tooltip={ texts.deleteLogicItem[ lang ]}
                    size='s'
                    theme='Plain'
                    colorSet='Grayscale'
                    bgStyle={{ opacity: isHovered ? 1 : 0.15}}
                    onClick={ handleDelete }
                />
            </div>
        )}
        </Draggable>
    )
}