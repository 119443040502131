import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './ConditionBuilder.module.css';
import Button from 'components/Button';
import SelectionPopper from 'components/poppers/SelectionPopper';
import { surveyEditorTexts } from 'utils/appTexts';
import { comparisonOperators } from '../LogicConfiguration';


const texts = surveyEditorTexts.properties.logic.itemEditor.condition.comparison;

export const dataTypes = {
    NUMBER: ['==','!=','>' ,'>=','<' ,'<='],
    STRING: ['==','!=','in'],
    BOOLEAN: ['==','!='],
    ARRAY: ['in', '==','!='],
}

export default function ComparisonOperator({dataType = dataTypes.BOOLEAN, operator, onChange, disabled}) {

    const btnRef = useRef();

    const [isPopperActive, setIsPopperActive] = useState( false );

    const options = useMemo( () => {
        return dataType.map( ( op ) => {
            return (
                <span key={ op } > 
                    <span className={ styles.comparisonIcon }> { op } </span> 
                    { texts[ comparisonOperators[op] ][ 'en' ] } 
                </span>
            )
        })
    }, [dataType])

    useEffect( () => {

        if( !dataType.includes( operator ) ) {
            onChange( dataType[ 0 ]);
        }

    }, [operator, dataType, onChange])

    const handleSelect = useCallback( (selected) => {

        onChange( selected );
        setIsPopperActive( false );

    }, [onChange])

    return (
        <>
            <div ref={ btnRef } className={ styles.comparisonBtnCont }>
                    <Button
                        label={ operator }
                        tooltip={ texts.comperisonOperatorTooltip[ 'en' ] }
                        theme='Plain'
                        colorSet='Grayscale'
                        bgStyle={{
                            padding: '4px 8px',
                            height: 32,
                        }}
                        labelStyle={{ 
                            fontFamily: 'Plex-Mono',
                            fontWeight: 700,
                            fontSize: 16,
                            marginBottom: 4,
                            color: `rgba(0,0,0, ${ disabled? 0.2 : 1})`
                        }}
                        onClick={ () => setIsPopperActive( true ) }
                        disabled={ disabled }
                    />
            </div>
            <SelectionPopper
                isActive={ isPopperActive }
                referenceElement={ btnRef.current }
                onDismiss={ () => setIsPopperActive( false ) }
                onSelect={ handleSelect }
                dir='ltr'
            >
                { options }
            </SelectionPopper>
        </>
    )
}