import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import styles from './SurveyEditorContent.module.css';
import CloudSaveIcon from 'components/animated_icons/CloudSaveIcon';
import SelectionPopper from 'components/poppers/SelectionPopper';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectProjectById } from 'app/horizonDataSlice';
import { selectUiLanguage } from 'app/preferencesSlice';
import { surveyEditorTexts } from 'utils/appTexts';
import { surveyLanguages } from 'features/survey_editor/surveyEditorConfiguration';
import { displayLanguageChanged, pageContentCollapsed } from 'features/survey_editor/surveyEditorSlice';
import Button from 'components/Button';
import { useKeyPress } from 'utils/hooks';
import IconButton from 'components/IconButton';
import { getNested } from 'utils/miscHelpers';

const texts = surveyEditorTexts.contentTopBar;

export default function SurveyEditorContentTobBar () {

    const { projectId } = useParams();
    const dispatch = useDispatch();
    const lang = useSelector( selectUiLanguage );
    const isAutoSaving = useSelector( state => state.surveyEditor.present.localChangesMade );
    const currentLang = useSelector( state => state.surveyEditor.present.display.currentLanguage );
    const supportedLanguages = useSelector( state => state.surveyEditor.present.survey.settings.general.supportedLanguages );
    const project = useSelector( state => selectProjectById( state, projectId ) );
    // const remoteSurveyFetchStatus = useSelector( state => state.surveyEditor.present.remoteSurveyFetchStatus );
    // const remoteSurveyUpdateStatus = useSelector( state => state.surveyEditor.present.remoteSurveyUpdateStatus );
    const autoSaveInitiated = useSelector( state => state.surveyEditor.present.autoSaveInitiated );
    const isConnectingToServer = useSelector(state => getNested(state, 'surveyEditor', 'present', 'remoteSurveyData', 'isConnecting'));

    const [noConnection, setNoConnection] = useState( false ); 
    const isTemplate = useMemo( () => project && project.isTemplate, [project]);
    
    // 17.8.21 note: Socket api doesn't return error on fetch. we only know if it 'isConnecting'. 
    //               this is why we can't use 'failed' to determin offline connection, hence the following code has been commented out:
    // useEffect( () => {
    //     if ( remoteSurveyFetchStatus === 'failed' && remoteSurveyUpdateStatus === 'failed' ) setNoConnection( true );
    //     if ( remoteSurveyFetchStatus === 'succeeded' || remoteSurveyUpdateStatus === 'succeeded' ) setNoConnection( false );
    // }, [remoteSurveyFetchStatus,remoteSurveyUpdateStatus] );

    useEffect( () => {
        setNoConnection( isConnectingToServer );
    }, [isConnectingToServer])

    useKeyPress( 76, (e) => {
        if( e.ctrlKey ) {
            const langInx = supportedLanguages.indexOf( currentLang ) + 1;
            const nextLang = supportedLanguages[ langInx % supportedLanguages.length ];
            // dispatch( displayStateChanged( {property: 'currentLanguage', value: nextLang } ))
            dispatch( displayLanguageChanged( nextLang ))
        }
    })

    return (
        <div 
            className={ styles.tobBarCont }                 
            style={{padding: lang === 'en' ? '0 16px 0 6px' : '0 6px 0 16px'}}
        >
            <div style={{display: 'flex', alignItems: 'center'}}>
                <PagesFolding lang={ lang }/>
                { supportedLanguages.length > 1 &&
                    <LanguageSelection lang={ lang } currentLang={ currentLang } supportedLanguages={ supportedLanguages }/>
                }
            </div>
            { !isTemplate &&
                <div className={ styles.autoSaveIndicator }>
                    {
                        autoSaveInitiated &&
                        <CloudSaveIcon 
                            isChecked={ !isAutoSaving } 
                            isLoading={ isAutoSaving }
                            noConnection={ noConnection }
                        />
                    }
                </div>
            }
        </div>
    )
}

const PagesFolding = ({lang}) => {

    const dispatch = useDispatch();
 
    const pagesData = useSelector( state => state.surveyEditor.present.survey.content.pagesData );
    const collapsedPages = useSelector( state => state.surveyEditor.present.display.collapsedPages )

    const allCollapsed = useMemo( () => {
        const numPages = Object.keys( pagesData ).length;

        if( Object.values( collapsedPages ).length > 0 ) {
            const numCollapsed = Object.values( collapsedPages ).reduce( 
                (accumulator, isCollapsed) => accumulator + isCollapsed )
            return numPages === numCollapsed;
        }
        
    }, [pagesData, collapsedPages])

    const handleCollapse = useCallback( () => {
        
        dispatch( pageContentCollapsed( {
            isCollapsed: !allCollapsed,
            all: true
        }))

    }, [dispatch, allCollapsed])

    return (
        <IconButton
            name={ allCollapsed ? 'expand_all' : 'collapse_all'}
            tooltip={ texts[ allCollapsed ? 'expandAllTooltip' : 'collapseAllTooltip' ][ lang ] }
            theme='Plain'
            size='s'
            bgStyle={{height: 36, width: 36}}
            iconStyle={{height: 18, width: 18}}
            colorSet='Grayscale'
            onClick={ handleCollapse }
        />
    )
}

const LanguageSelection = ({onDone, currentLang, lang, supportedLanguages }) => {

	const langBtnRef = useRef();
    const [isLangSelectionActive, setIsLangSelectionActive] = useState( false );

	const dispatch = useDispatch();

    const surveyLangs = useMemo( () => (

        supportedLanguages.map( l => (
            { key: l, label: surveyLanguages[l].name[ lang ] } 
        ))

    ), [lang,supportedLanguages] )
    
	function handleSelection( op, index ) {

        // dispatch( displayStateChanged( {property: 'currentLanguage', value: op.key } ))
        dispatch( displayLanguageChanged( op.key ))
        setIsLangSelectionActive( false );
    }

	return (
		<>
			<div 
				ref={ langBtnRef }
				className={ styles.langBtnCont } 
            >
				<Button
                    label={ currentLang }
                    theme="Plain"
                    colorSet="Grayscale"
                    iconAfter="language"
                    bgStyle={{height: 36, borderRadius:18, padding: 10}}
                    iconStyle={{width: 24, height: 24}}
                    tooltip={ texts.toggleLangTooltip[ lang ] }
					onClick={ () => setIsLangSelectionActive( true ) }
				/>
			</div>

			<SelectionPopper
				referenceElement={ langBtnRef.current }
				isActive={ isLangSelectionActive }
                options={ surveyLangs }
				onDismiss={ () => {
					if( onDone ) onDone();
					setIsLangSelectionActive( false );
				}}
				onSelect={ handleSelection }
			/>

		</>

	)
}