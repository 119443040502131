import React, { useCallback, useEffect, useState } from 'react';
// import moduleStyles from './PageModulesStyles.module.css';
import TextInput from 'components/TextInput';
import { surveyEditorTexts } from 'utils/appTexts';
import { useSelector, useDispatch } from 'react-redux';
import { selectUiLanguage } from 'app/preferencesSlice';
import {
    selectPageProperty,
    pageContentChanged,
} from 'features/survey_editor/surveyEditorSlice';
import { isUrlLegal } from 'utils/validation';

const texts = surveyEditorTexts.content.pageModules.externalPage;

export default function ExternalPageModule({ pageId }) {
    const dispatch = useDispatch();
    const lang = useSelector(selectUiLanguage);
    const url = useSelector((state) =>
        selectPageProperty(state, pageId, 'url')
    );

    const [urlWarning, setUrlWarning] = useState('');

    useEffect(() => {
        const isValid = isUrlLegal(url);
        setUrlWarning(isValid ? '' : texts.invalidUrl[lang]);
    }, [url, lang]);

    const handleUrlChange = useCallback(
        (e) => {
            const urlValue = e.target.value;
            dispatch(
                pageContentChanged({ id: pageId, prop: 'url', value: urlValue })
            );
        },
        [dispatch, pageId]
    );

    return (
        <TextInput
            dir="ltr"
            label={texts.urlLabel[lang]}
            value={url || ''}
            onChange={handleUrlChange}
            theme="Outlined"
            warning={urlWarning}
        />
    );
}
