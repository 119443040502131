import React from 'react';
import TextInput from 'components/TextInput';

export default function NumberFieldModule ({data, value, handleChange, lang}) {
    
    return (
        <TextInput
            type='number'
            label={ data.label ? data.label[ lang ] : '' }
            placeholder={ data.placeholder ? data.placeholder[ lang ] : "#" }
            value={ (value !== null && value !== undefined ) ? value : "" }
            onChange={ (e) => handleChange( e.target.value ) }
        />
    )
}