import TextInput from 'components/TextInput';
import {
    pageItemPropChanged,
    selectPageProperty,
} from 'features/survey_editor/surveyEditorSlice';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function PageItemNumericValue({ item, pageId }) {
    const pageSettings = useSelector((state) =>
        selectPageProperty(state, pageId, 'settings')
    );

    const dispatch = useDispatch();
    const handleChange = useCallback(
        (e) => {
            dispatch(
                pageItemPropChanged({
                    pageId,
                    itemId: item.id,
                    prop: 'numericValue',
                    data: parseFloat(e.target.value),
                })
            );
        },
        [pageId, item.id]
    );

    if (!pageSettings?.general?.isNumeric) return null;

    return (
        <>
            <TextInput
                type="number"
                value={item.numericValue ?? ''}
                contStyle={{ width: 64, minWidth: 48 }}
                fieldStyle={{
                    height: 28,
                    paddingInline: 4,
                    paddingTop: 0,
                    paddingBottom: 0,
                }}
                onChange={handleChange}
            />
        </>
    );
}
