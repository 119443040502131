import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './AnalysesFieldsModules.module.css';
import DropDown from 'components/DropDown';
import MultipleSelect, { SelectionItem } from 'components/MultipleSelect';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReport } from 'app/reportSlice';
import { selectAllLimitation, validateDataKeyType } from '../analysesUtils';

export default function KeyNameFieldModule ({project, data, value, handleChange, texts, lang}) {

    const dispatch = useDispatch();

    const dataKeys = useSelector( state => {

        const dataKeys = state.report.dataKeys;

        if( !dataKeys ) {
            if( state.report.fetchStatus === 'idle' ) {

                dispatch( fetchReport( project.sourceName ) );
            }

        }

        return dataKeys;
    })

    const dataKeysOptions = useMemo( () => {

        if( !dataKeys ) return [{key: 'loading', label: texts.loadingDataKeys[ lang ], isSelected: true}];

        let options = [];

        Object.keys( dataKeys ).forEach( k => {
            
            const meta = dataKeys[k];
            const isValidType = validateDataKeyType( data, meta );

            if( !isValidType ) return;

            // const type = 
            // meta.originType === 'SCALE_ITEM' ? 'SCALE_ITEM' : 
            // meta.type === 'NULL' ? meta.originType :
            // meta.type;

            const type = meta.originType;
            const title = meta.title ? meta.title : ''

            const isSelected = ( data.isMultiple && value ) ? value.includes( k ) : false;

            options.push({
                key: k, 
                label: k,
                type,
                title,
                isSelected 
            }) 

        }) 

        options.sort( (a,b) => dataKeys[ a.key ].originIndex - dataKeys[ b.key ].originIndex );

        return options;

    }, [dataKeys,data,value,lang,texts])

    useEffect( () => {

        if( data.selectAllByDefault && dataKeys && !value) {

            // Select all values by default:
            const values = [];

            Object.keys( dataKeys ).forEach( k => {
                const meta = dataKeys[k];
                const isValidType = validateDataKeyType( data, meta );
                if( isValidType ) values.push( k );
            });

            if( values.length <= selectAllLimitation ) {
                handleChange( values )
            }
        }

    }, [data, dataKeys, value, handleChange])

    const handleKeysSelection = useCallback( ( key ) => {

        const newKeys = value ? Array.from( value ) : [];

        if ( newKeys.indexOf( key ) >= 0 ) {

            newKeys.splice( newKeys.indexOf( key ), 1 );

        } else {
            newKeys.push( key )
        }

        handleChange( newKeys )

    }, [value, handleChange])

    if( data.isMultiple ) return (
        <MultipleSelect
            label={ data.label ? data.label[ lang ] : '' }
            value={ value }
            onChange={ (key) => handleKeysSelection( key ) }
        > {
            dataKeysOptions.map( (op,i) => {
                return (
                    <SelectionItem key={op.key} label={op.label} isSelected={op.isSelected}>
                        <span className={ styles.dropdownKeyLabel }> {op.label} </span>
                        <span className={ styles.dropdownTypeLabel }> ({op.type}) </span>
                        <span className={ styles.dropdownTitleLabel }> — {op.title}</span>
                    </SelectionItem>
                )
            })
        }  
        </MultipleSelect>  
    )

    return (
        <DropDown
            label={ data.label ? data.label[ lang ] : '' }
            value={ value }
            onChange={ (op) => handleChange( op ) }
        > {
            dataKeysOptions.map( (op,i) => {
                return (
                    <span key={op.key} label={op.label}>
                        <span className={ styles.dropdownKeyLabel }> {op.label} </span>
                        <span className={ styles.dropdownTypeLabel }> ({op.type}) </span>
                        <span className={ styles.dropdownTitleLabel }> — {op.title}</span>
                    </span>
                )
            })
        }  
        </DropDown>  
    )
}