export const componentsTexts = {
    colorPicker: {
        customLabel: { en: 'Custom', he: 'אחר' },
    },
    collapseButton: {
        collapse: { en: 'Collapse', he: 'כיווץ' },
        expand: { en: 'Expand', he: 'הרחבה' },
    },
    toggleOptionsPopper: {
        checkAll: { en: 'Check all', he: 'בחירת הכל' },
        checkNone: { en: 'Check none', he: 'הסרת הכל' },
    },
    multipleSelection: {
        loading: { en: 'Loading...', he: 'טוען...' },
    },
    fileInput: {
        chooseFileButtonLabel: { en: 'Choose file', he: 'בחירת קובץ' },
    },
    richText: {
        richTextToolbar: {
            boldTooltip: { en: 'Bold', he: 'הדגשה' },
            linkTooltip: { en: 'Link', he: 'קישור' },
            dynamicTextTooltip: { en: 'Dynamic Texts', he: 'טקסט דינמי' },
            textColorTooltip: { en: 'Text color', he: 'צבע טקסט' },
        },
        dynamicTextEntityEditor: {
            noVars: { en: 'No variables', he: 'טרם הוגדרו משתנים' },
            selectVarLabel: { en: 'Variable', he: 'משתנה' },
            applyBtn: { en: 'Apply', he: 'אישור' },
            removeBtn: { en: 'Remove', he: 'הסרה' },
            modes: {
                simple: {
                    modeName: { en: 'Simple', he: 'בסיסי' },
                    tip: {
                        en: 'Use text from survey variable value.',
                        he: 'שימוש בערך טקסטואלי מתוך משתנה שאלון.',
                    },
                },
                conditional: {
                    modeName: { en: 'Conditional', he: 'מותנה' },
                    tip: {
                        en: 'Set text conditionally.',
                        he: 'הזנת ערך טקסטואלי בעקבות תנאי.',
                    },
                    addItemBtnTooltip: {
                        en: 'Add conditional item',
                        he: 'הוספת טקסט מותנה',
                    },
                    deleteItemBtnTooltip: {
                        en: 'Delete conditional item',
                        he: 'מחיקת טקסט מותנה',
                    },
                    textLabel: { en: 'Set text to:', he: 'הצג את הטקסט:' },
                    ifLabel: { en: 'If', he: 'בתנאי ש:' },
                    elseIfLabel: { en: 'Else, if', he: 'אחרת, בתנאי ש:' },
                    defaultOptions: {
                        tooltip: {
                            en: 'Default text options',
                            he: 'אפשרויות ברירת מחדל',
                        },
                        text: { en: 'Text', he: 'טקסט' },
                        variable: { en: 'Variable', he: 'משתנה' },
                    },
                    defaultTextLabel: {
                        en: 'Default text',
                        he: 'טקסט ברירת מחדל',
                    },
                    defaultVariableLabel: {
                        en: 'Default from variable',
                        he: 'ברירת מחדל מתוך משתנה',
                    },
                },
            },
        },
        dynamicTextEntityDecorator: {
            tooltip: { en: 'Replace with: {v}', he: 'החלפה עם: {v}' },
        },
        linkEntityEditor: {
            applyBtn: { en: 'Apply', he: 'אישור' },
            removeBtn: { en: 'Remove', he: 'הסרה' },
            linkLable: { en: 'Link', he: 'קישור' },
            webviewTitle: { en: 'Window title', he: 'כותרת לחלון' },
            openUrlTooltip: { en: 'Open link', he: 'פתיחת הקישור' },
            targetWindowLabel: { en: 'Target window', he: 'פתיחה ב:' },
            targetWindowOptions: {
                blank: { en: 'New tab', he: 'טאב נפרד' },
                self: {
                    en: 'Same tab (leave survey)',
                    he: 'טאב נוכחי (יציאה מהשאלון)',
                },
                webview: { en: 'Internal window', he: 'חלון פנימי' },
            },
        },
    },
    mediaLibrary: {
        topBar: {
            libraryTitle: {
                he: 'ספרית מדיה',
                en: 'Media Library',
            },
            back: { he: 'חזרה', en: 'Back' },
            archive: {
                he: 'ארכיון',
                en: 'Archive',
            },
            multipleEdit: {
                selected: { he: 'נבחרו {n} קבצים', en: '{n} files selected' },
                archive: { he: 'העברה לארכיון', en: 'Archive' },
                restore: { he: 'שחזור', en: 'Restore' },
                delete: { he: 'מחיקה', en: 'Delete' },
            },
        },
        addButton: {
            he: 'הוספה',
            en: 'Add',
        },
        upload: {
            he: 'העלאה',
            en: 'Upload',
        },
        fileSizeError: {
            he: 'קבצי {type} צריכים לשקול מתחת ל-{sizeLimit}kb.',
            en: `Files of type "{type}" should be under {sizeLimit}kb.`,
        },
        corruptedLottie: {
            he: 'קובץ לוטי לא תקין.',
            en: 'Lottie file is corrupted.',
        },
        thumbnail: {
            selectTooltip: { he: 'בחירה', en: 'Select' },
            optionsTooltip: { he: 'אפשרויות', en: 'Options' },
            archive: { he: 'העברה לארכיון', en: 'Archive' },
            restore: { he: 'שחזור', en: 'Restore' },
            delete: { he: 'מחיקה', en: 'Delete' },
        },

        noFiles: {
            he: 'הספריה ריקה',
            en: 'No media yet',
        },

        notSafeToRemoveAlert: {
            title: { en: 'One moment', he: 'רגע רגע' },
            message: {
                en: "The media you're trying to archive is in use in one or more pages.\n Remove it first from all pages and then try again.",
                he: 'המדיה שאתם מנסים להעביר לארכיון נמצאת בשימוש בעמוד אחד או יותר.\n הסירו אותה מכל העמודים ונסו שוב.',
            },
            actionCancel: { en: 'Cancle', he: 'ביטול' },
            actionRemoveAll: { en: 'Remove all', he: 'הסרה מהכל' },
        },
        accessibility: {
            altTextTitle: {
                en: 'Media description',
                he: 'תיאור המדיה',
            },
        },
    },
};

export const appSectionsTexts = {
    home: { en: 'Projects', he: 'פרוייקטים' },
    notebook: { en: 'Notebook', he: 'מחברת' },
    survey: { en: 'Survey', he: 'שאלון' },
    distribution: { en: 'Distribution', he: 'הפצה' },
    insights: { en: 'Insights', he: 'תובנות' },
    results: { en: 'Results', he: 'תוצאות' },
    analyses: { en: 'Analyses', he: 'אנליזות' },
    previewOnly: {
        en: "Read-only template preview. Changes won't be saved.",
        he: 'תבנית לתצוגה מקדימה. שינויים לא ישמרו.',
    },
};

export const insightsTexts = {
    pageStats: { en: 'Step Stats', he: 'נתוני שלבים' },
    projectStats: { en: 'Project Stats', he: 'נתוני פרוייקט' },
    filterBtn: { en: 'Filter', he: 'סינון' },
    getDataBtn: { en: 'Get data', he: 'קבלת נתונים' },
    addBtn: { en: 'Add', he: 'הוספה' },
    newKeyBtn: { en: 'Add a new key', he: 'הוספת מפתח חדש' },
    saveFunnelBtn: { en: 'Save funnel', he: 'שמירת משפך' },
    resetFunnelBtn: { en: 'Reset funnel', he: 'איפוס משפך' },
    editBtn: { en: 'Edit', he: 'עריכה' },
    deleteBtnTooltip: { en: 'Delete step', he: 'מחיקת שלב' },
    timeHeadLine: { en: 'Step duration', he: 'משך זמן' },
    dropOffHeadLine: { en: 'Drop off', he: 'נשירה' },
    dropOffGraphTitle: { en: 'Drop off rate', he: 'קצב נשירה' },
    completionOverTimeTitle: {
        en: 'Completion over time',
        he: 'שיעור סיום לפי זמן',
    },
    startsTitle: { en: 'Starts', he: 'התחלות' },
    completionsTitle: { en: 'Completions', he: 'מסיימים' },
    completionRateTitle: { en: 'Completion rate', he: 'אחוז מסיימים' },
    averageProgressionTitle: {
        en: 'Average progression',
        he: 'התקדמות ממוצעת',
    },
    completionTimeTitle: {
        en: 'Average time to completion',
        he: 'זמן ממוצע לסיום',
    },
    responseCountInfo: {
        en: 'Based on {sample} / {total} responses',
        he: 'מבוסס על {sample} / {total} רשומות',
    },
};

export const analysesTexts = {
    sidePanel: {
        sidePabelTitle: { en: 'Create analysis', he: 'יצירת ניתוח' },
        requiredField: { en: 'Required field', he: 'שדה חובה' },
        methodTypeLabel: { en: 'Method', he: 'מתודה' },
        methodPlaceholder: { en: 'Select', he: 'בחירה' },
        chooseValuesPlaceholder: { en: 'Choose values', he: 'בחירת ערכים' },
        submitNewBtn: { en: 'Send for analysis', he: 'שליחה לניתוח' },
        loadingMethods: { en: 'Loading methods', he: 'טוען מתודות' },
        methodDescriptionBtn: { en: '... Full info', he: '... הסבר מלא' },
        loadingDataKeys: { en: 'Loading...', he: 'טוען...' },
        nameInputLabel: { en: 'Analysis name', he: 'שם הניתוח' },
        noRelevantData: { en: 'No relevant data', he: 'אין מידע רלוונטי' },
        addCorrectionBtn: { en: 'Add correction', he: 'הוספת תיקון' },
        addFilterBtn: { en: 'Add filter', he: 'הוספת סינון' },
        keyLabel: { en: 'Key', he: 'מזהה' },
        min: { en: 'Min', he: 'מינימום' },
        max: { en: 'Max', he: 'מקסימום' },
    },
    tagsSection: {
        addTag: { en: 'Add Tag', he: 'הוספת תגית' },
        createTag: { en: 'Create tag: {query}', he: 'צור תגית חדשה: {query}' },
        writeTag: { en: 'Enter tag', he: 'הזן תגית' },
    },
    contentArea: {
        noAnalyses: { en: 'No analyses', he: 'אין ניתוחים' },
        andOfAnalyses: { en: 'You have reach the end', he: 'הגעת לסוף' },

        showArchiveBtn: { en: 'Archive', he: 'ארכיון' },
        showStaredBtnTooltip: { en: 'Show only stared', he: 'הצגת מועדפים' },
        updateAll: { en: 'Update all', he: 'עדכון הכל' },
        updateSome: { en: 'Update ({n})', he: 'עדכון ({n})' },
        archiveAll: { en: 'Archive all', he: 'הכל לארכיון' },
        archiveSome: { en: 'Archive ({n})', he: '({n}) לארכיון' },
        loadAllBtn: { en: 'Load all', he: 'טען הכל' },
    },
    cardTexts: {
        titlePlaceholder: { en: 'Untitled', he: 'ללא כותרת' },
        createdBy: { en: 'Created by', he: 'נוצר ע״י' },
        createdAt: { en: 'Created at', he: 'תאריך יצירה' },
        outputs: { en: 'Outputs', he: 'פלטים' },
        cardNote: {
            label: { en: 'Note', he: 'הערות' },
            addNoteBtn: { en: 'Add note', he: 'הוספת הערות' },
            notePlaceholder: {
                en: 'Enter your note...',
                he: 'רשמו את ההערות שלכם כאן...',
            },
        },
        cardOptions: {
            tooltip: { en: 'Analysis options', he: 'אפשרויות אנליזה' },
            archive: { en: 'Archive', he: 'העברה לארכיון' },
            viewDetails: { en: 'View details', he: 'פרטים נוספים' },
            reproduce: { en: 'Reproduce', he: 'הפקה מחדש' },
            update: { en: 'Update', he: 'עדכון' },
            token: { en: 'Get Data Token', he: 'קבל קוד גישה' },
            keystone: { en: 'Watch in keystone', he: 'הצג בKeystone' },
        },
        tokenAlert: {
            successTitle: { en: '🐿 Token Recived', he: '🐿 קוד גישה לנתונים' },
            errorTitle: {
                en: 'Sorry, something went wrong 😖',
                he: 'מצטערים, משהו השתבש 😖',
            },
            closeBtn: { en: 'Close', he: 'סגירה' },
            copyToClipboard: {
                en: '📋 Copy to clipboard',
                he: '📋 העתק למקלדת',
            },
        },
        noTitleAlert: {
            title: { en: 'Missing title', he: 'חסרה כותרת' },
            body: {
                en: 'Please give the analysis a title before adding {item}.',
                he: ' אנא תנו כותרת לתוצאת הניתוח לפני הוספה של {item}.',
            },
            okayAction: { en: 'Okay', he: 'אוקי' },
            items: {
                tag: { en: 'a tag', he: 'תגית' },
                note: { en: 'a note', he: 'הערה' },
            },
        },
        isArchived: { en: 'Archived', he: 'ארכיון' },
        processingOutput: { en: 'Processing...', he: 'מעבד נתונים...' },
        statusError: {
            en: 'Error occurred during processing. Please check log for details.',
            he: 'ארעה שגיאה בעת עיבוד הנתונים. לפרטים בדקו את הלוג.',
        },
        statusErrorPrefix: {
            en: 'Error occurred during processing: ',
            he: 'ארעה שגיאה בעת עיבוד הנתונים: ',
        },
        getBtn: { en: 'Get', he: 'הורדה' },
        viewBtn: { en: 'View', he: 'הצגת' },
        logLabel: { en: 'Log', he: 'Log' },
        errorLabel: { en: 'Error', he: 'Error' },
        parametersLabel: { en: 'parameters.json', he: 'parameters.json' },
        methodNameLabel: { en: 'Method', he: 'Method' },

        positionInQueue: { en: 'Position in queue', he: 'מיקום בתור' },
        isAnalysing: { en: 'Processing ✨', he: 'מעבד ✨' },
        expectedCompletion: {
            en: 'Expected completion in',
            he: 'צפי לסיום בעוד',
        },
        lessThanMinute: { en: 'less than 1', he: 'פחות מ-1' },
        minutes: { en: 'minutes', he: 'דקות' },
    },
};

export const tagTexts = {
    tagPageTitle: {
        en: 'analysis results with the tags: {tagName}',
        he: 'תוצאות ניתוחים עם התגיות: {tagName}',
    },
    showingTagsData: {
        en: 'Showing data for tags:',
        he: 'מציג נתונים עבור התגיות:',
    },
    createdAtToday: { en: 'Created today', he: 'נוצר היום' },
    createdBeforeDays: { en: '{days} days ago', he: 'לפני {days} ימים' },
    addTag: { en: 'Add Tag', he: '+ הוספת תגית' },
};

export const resultsTexts = {
    headline: { en: 'Response summary', he: 'סיכום תשובות' },
    getDataBtn: { en: 'Get data', he: 'קבלת נתונים' },
    askAccessBtn: { en: 'Request data access', he: 'בקשת גישה לנתונים' },
    viewReportyBtn: { en: 'View report', he: 'הצגת דוח' },
    noResults: { en: 'Waiting for answers', he: 'אין תשובות עדיין' },
    accessRequestSent: { en: 'Request sent', he: 'הבקשה נשלחה' },
    getDataDialog: {
        errorTitle: {
            en: 'Sorry, something went wrong 😖',
            he: 'מצטערים, משהו השתבש 😖',
        },
        title: { en: 'Proccessing Data 🏄🏽‍♂️', he: 'השרת מעבד נתונים 🏄🏽‍♂️' },
        message: {
            en: "A report of {-} records is on it's way to your inbox.{-}Estimated delivery time: {-} minutes",
            he: 'דו״ח בן {-} רשומות בדרך לתיבת המייל שלך.{-}זמן שליחה משוער: {-} דקות',
        },
        action: { en: 'Got it', he: 'הבנתי' },
    },
    askAccessDialog: {
        errorTitle: {
            en: 'Sorry, something went wrong 😖',
            he: 'מצטערים, משהו השתבש 😖',
        },
        title: { en: 'Request Sent 🌽', he: 'הבקשה נשלחה 🌽' },
        message: {
            en: "You will be able to get this project's data as soon as one of the project's owners will accept your request.",
            he: 'הנתונים יהיו זמינים לך ברגע שאחד ממנהלי הפרוייקט יאשר את בקשתך.',
        },
        action: { en: 'Got it', he: 'הבנתי' },
    },
    columnDropDown: { en: 'Columns', he: 'עמודות' },
    columnPopper: {
        checkAll: { en: 'Check all', he: 'בחירת הכל' },
        checkNone: { en: 'Check none', he: 'הסרת הכל' },
        apply: { en: 'Apply', he: 'עדכון' },
        toggleVirtualVars: { en: 'Virtual vars', he: 'משתנים וירטואליים' },
    },
    filterBtn: { en: 'Rows filter', he: 'סינון שורות' },
    filterOptions: {
        startDate: { en: 'Start date', he: 'תאריך התחלה' },
        endDate: { en: 'End date', he: 'תאריך סיום' },
        applyBtn: { en: 'Apply', he: 'אישור' },
        resetBtn: { en: 'Reset', he: 'איפוס' },
        filterUnfinished: {
            en: 'Show only finished records',
            he: 'הצג רק רשומות שהסתיימו',
        },
    },
    noRecordsData: { en: 'No data 🦋', he: '🦋 אין מידע לתצוגה' },
    responseCountInfo: {
        en: 'Based on {sample} / {total} responses',
        he: 'מבוסס על {sample} / {total} רשומות',
    },
    totalRecordsCountInfo: {
        en: 'Showing {total} records',
        he: 'מציג {total} רשומות',
    },
    sampledRecordsCountInfo: {
        en: 'Sample of {sample} out of {total} records',
        he: 'תצוגה מדגמית של {sample} מתוך {total} רשומות',
    },
    sellectAllRows: {
        en: 'Select all',
        he: 'בחירת הכל',
    },
    numberOfSelectedRows: {
        en: '{n} rows selected',
        he: '{n} שורות נבחרו',
    },
    deleteRecordsButton: {
        en: 'Delete',
        he: 'מחיקה',
    },
    deleteRecordsDialog: {
        title: { en: 'Are you sure?', he: 'האם אתם בטוחים?' },
        message: {
            en: 'The selected records will be removed from the database.',
            he: 'הרשומות המסומנות יוסרו ממאגר הנתונים.',
        },
        cancel: { en: 'Cancel', he: 'ביטול' },
        remove: { en: 'Yes, remove', he: 'כן, להסיר' },
    },
    deleteRecordsSuccess: {
        en: 'Removed successfully',
        he: 'ההסרה התבצעה',
    },
    deleteRecordsError: {
        1: {
            en: 'Only project owners are allowed to remove records',
            he: 'רק בעלי הפרוייקט רשאים להסיר רשומות.',
        },
        2: {
            en: 'Server error while trying to remove records',
            he: 'שגיאת שרת בעת נסיון הסרת רשומות.',
        },
        action: {
            en: 'Try again',
            he: 'נסו שוב',
        },
    },
    respondents: { en: 'respondents', he: 'משיבים' },
    limitedResponsesInfo: {
        en: 'To keep computation time low we limit the number of responses',
        he: 'תצוגת התוצאות מצומצמת על מנת ליעל זמני חישוב',
    },
    virtualVarsBtn: { en: 'Virtual var', he: 'משתנה וירטואלי' },
    virtualVarOptions: {
        edit: { en: 'Edit var', he: 'עריכת משתנה' },
        delete: { en: 'Delete var', he: 'מחיקת משתנה' },
    },
    virtualVarEditor: {
        settings: {
            title: { en: 'Variable settings', he: 'הגדרות משתנה' },
            clearBtn: { en: 'Clear all', he: 'איפוס משתנה' },
            createBtn: { en: 'Create', he: 'יצירת משתנה' },
            saveBtn: { en: 'Save', he: 'שמירה' },
            cancelBtn: { en: 'Cancel', he: 'ביטול' },
            typeInputLabel: { en: 'Type', he: 'סוג משתנה' },
            keyInputLabel: { en: 'Key', he: 'מזהה' },
            titleInputLabel: { en: 'Title', he: 'כותרת' },
            subtitleInputLabel: { en: 'Subtitle', he: 'כותרת משנה' },
        },
        formula: {
            title: { en: 'Formula', he: 'נוסחה' },
            placeholder: `Enter your formula (i.e: \u0024{argA} * \u0024{argB}...)`,
            noCategoryPlaceholder: 'Select a category to edit the formula',
            categoriesLabel: { en: 'Categories', he: 'קטגוריות' },
            outputLabel: { en: 'Output', he: 'פלט' },
            valueName: { en: 'Value name', he: 'שם הערך' },
            valueDescription: { en: 'Value description', he: 'תיאור הערך' },
            createCategory: { en: 'Add', he: 'הוספה' },
            outputPlaceholder: '...',
            toolbar: {
                arithmeticTooltip: {
                    en: 'Arithmetic operators',
                    he: 'סימני חישוב',
                },
                logicTooltip: { en: 'Logic operators', he: 'סימני לוגיקה' },
                mathTooltip: { en: 'Math functions', he: 'פעולות מתמטיות' },
            },
        },
        args: {
            title: { en: 'Arguments', he: 'ארגומנטים' },
            createNewArgBtn: {
                en: 'Create a new argument',
                he: 'יצירת ארגומנט חדש',
            },
            keyInputLabel: { en: 'Reference key', he: 'מזהה מקור' },
            nullInputLabel: { en: 'Null value', he: 'ערך נול' },
            defaultInputLabel: { en: 'Default value', he: 'ערך ברירת מחדל' },
            valuesDictLabel: { en: 'Map text to value', he: 'מיפוי טקסט לערך' },
            testingInputLabel: { en: 'Testing value', he: 'ערך לבדיקה' },
            loadingDataKeys: { en: 'Loadiing...', he: 'טוען...' },
            deleteBtnTooltip: { en: 'Delete argument', he: 'מחיקת ארגומנט' },
            addBtnTooltip: { en: 'Add to formula', he: 'הוספה לנוסחה' },
        },
        messages: {
            actions: {
                gotIt: { en: 'Got it', he: 'הבנתי' },
            },
            illegalKeyCharacter: {
                en: "You can only use small English letters, numbers and underscores '_'.",
                he: 'ניתן להשתמש רק באותיות אנגלית קטנות, מספרים וקו תחתון ׳_׳.',
            },
        },
        warnings: {
            varKeyAlreadyExists: {
                en: 'Var key already exists!',
                he: 'המזהה כבר קיים בנתונים!',
            },
            argNameAlreadyExists: {
                en: 'Arg name already exists!',
                he: 'שם הארגומנט כבר קיים!',
            },
            categoryValueAlreadyExists: {
                en: 'Category value already exists!',
                he: 'ערך הקטגוריה כבר קיים!',
            },
        },
    },
};

export const pageNotFound = {
    title: { en: '404 - Horizon page not found', he: '404 - העמוד לא נמצא' },
    goHomeBtn: { en: 'Go to Horizon home', he: 'חזרה לעמוד הבית' },
};

export const login = {
    signInTitle: { en: 'Sign-in to Horizon', he: 'כניסה להורייזון' },
    email: { en: 'email', he: 'אימייל' },
    password: { en: 'password', he: 'סיסמה' },
    submitBtn: { en: 'Log in', he: 'כניסה' },
    forgotMyPass: { en: 'Forgot your password?', he: 'שכחת את הסיסמה?' },
    errors: {
        general: { en: 'Error', he: 'שגיאה' },
        failed_to_authenticate: {
            en: 'Failed to authenticate',
            he: 'בעיה באימות המשתמש',
        },
    },
    getIntouch: {
        en: 'Interested in Horizon? Get intouch at kayma@kayma.com',
        he: 'מתעניינים בהורייזון? צרו איתנו קשר ב-kayma@kayma.com',
    },
    Moto: {
        en: 'Harnessing the power of big data and behavioral science to drive innovation',
        he: '',
    },
    authenticationLoadingTitle: {
        en: 'Welcome to Horizon',
        he: 'Welcome to Horizon',
    },
};

export const userSettingsTexts = {
    image: { en: 'Set image', he: 'הגדרת תמונה' },
    logOut: { en: 'Log out', he: 'יציאה' },
    preferencesLabel: { en: 'Preferences', he: 'הגדרות' },
    languageLabel: { en: 'Interface Language', he: 'שפת שימוש' },
    setLanguage: { en: 'English', he: 'עברית' },
};

export const homeTexts = {
    projectsView: {
        loadMoreBtn: { en: 'Load more', he: 'טען עוד' },
        loadAllBtn: { en: 'Load All', he: 'טען הכל' },
        quickAccess: { en: 'Quick Access', he: 'גישה מהירה' },
        teamProject: { en: 'Team Projects', he: 'פרוייקטים אחרונים' },
        pinedProjects: { en: 'Pinned Projects', he: 'פרוייקטים נעוצים' },
        projectsWithLabels: {
            en: 'Projects with the label',
            he: 'מציג פרוייקטים עם הלייבל',
        },
        allProjects: { en: 'All Projects:', he: 'כל הפרוייקטים:' },
        allArchivedProjects: {
            en: 'All Archived Projects:',
            he: (
                <span>
                    כל הפרוייקטים{' '}
                    <span style={{ color: '#99290f' }}>בארכיון:</span>
                </span>
            ),
        },
        allArchivedProjectsWithLabel: {
            en: 'All Archived Projects with the Label',
            he: (
                <span>
                    מציג את כל הפרוייקטים{' '}
                    <span style={{ color: '#99290f' }}>שבארכיון</span> עם התגית:
                </span>
            ),
        },
        displayOnlyMaxItems: {
            en: 'Displaying last {maxItems} projects.',
            he: 'מציג {maxItems} פרוייקטים אחרונים.',
        },
        useSearchBarInstead: {
            en: 'Access older projects using the search bar.',
            he: 'על מנת לגשת לפרוייקט ישן יותר, יש להשתמש בשורת החיפוש.',
        },
    },
    detailsPopper: {
        detailsTitle: { en: 'DETAILS', he: 'פרטים' },
        accessTitle: { en: 'WHO HAS ACCESS', he: 'למי יש גישה' },
        emojiTitle: { en: 'PROJECT EMOJI', he: 'אמוג׳י' },
        labelsTitle: { en: 'LABELS', he: 'תוויות' },
        targetPlatformText: { en: 'Target platform', he: 'פלטפורמה יעודית' },
        versionText: { en: 'Version', he: 'גרסה' },
        publishedText: { en: 'Published', he: 'פורסם' },
        lastChangeText: { en: 'Last change', he: 'שינוי אחרון' },
    },
    sidePanel: {
        allLabels: { en: 'All', he: 'הכל' },
        labelsHeadline: { en: 'LABELS', he: 'לייבלים' },
        addLabel: { en: 'Add label', he: 'הוספת לייבל' },
        archive: { en: 'Archive', he: 'ארכיון' },
        home: { en: 'Home', he: 'מסך בית' },
        tags: {
            en: (
                <span>
                    Tags{' '}
                    <span style={{ font: 'var( --font-body-extra-small )' }}>
                        (Soon..)
                    </span>
                </span>
            ),
            he: (
                <span>
                    תגיות
                    <span style={{ font: 'var( --font-body-extra-small )' }}>
                        {' '}
                        (בקרוב..)
                    </span>
                </span>
            ),
        },
        highlights: {
            en: (
                <span>
                    Highlights{' '}
                    <span style={{ font: 'var( --font-body-extra-small )' }}>
                        (Soon..)
                    </span>
                </span>
            ),
            he: (
                <span>
                    חדשות{' '}
                    <span style={{ font: 'var( --font-body-extra-small )' }}>
                        (בקרוב...)
                    </span>
                </span>
            ),
        },
    },
    editLabels: { en: 'EDIT LABELS', he: 'עריכת תוויות' },
    permissionsPopper: {
        editTitle: { en: 'EDIT PERMISSIONS', he: '' },
        add: { en: 'add', he: 'הוסף' },
        error: { en: '', he: '' },
        search: { en: 'Search...', he: 'חיפוש...' },
    },
    newProject: {
        types: {
            regular: { en: 'Survey', he: 'שאלון' },
            data_collection: { en: 'Data collection', he: 'אסופת נתונים' },
            from_template: { en: 'From template', he: 'מתוך תבנית' },
        },
        warnings: {
            actions: {
                gotIt: { en: 'Got it', he: 'הבנתי' },
            },
            illegalKeyCharacter: {
                en: "You can only use small English letters, numbers and underscores '_'.",
                he: 'ניתן להשתמש רק באותיות אנגלית קטנות, מספרים וקו תחתון ׳_׳.',
            },
            sourceNameIsNotAvailable: {
                en: 'The current source name is not available.',
                he: 'שם המזהה לא פנוי.',
            },
        },
        loading: { en: 'Loading', he: 'טוען' },
        poperTitle: { en: 'New Project', he: 'פרויקט חדש' },
        projectNameLabel: { en: 'Project name', he: 'שם הפרוייקט' },
        projectSourceNameLabel: { en: 'Source name', he: 'מזהה' },
        projectIconLabel: { en: 'Icon', he: 'אייקון' },
        projectLabelsLabel: { en: 'Labels', he: 'לייבלים' },
        projectTypeLabel: { en: 'Project type', he: 'סוג הפרוייקט' },
        templateTypeLabel: { en: 'Template Type', he: 'סוג התבנית' },
        copyProject: { en: 'Copy the project:', he: 'העתק את הפרוייקט:' },
        appendixProjectsLabel: {
            en: 'Appendix projects',
            he: 'פרוייקטים נספחים',
        },
        cancelBtn: { en: 'Cancel', he: 'ביטול' },
        createBtn: { en: 'Create', he: 'יצירה' },
        createNew: { en: 'Create', he: 'יצירה' },
        blankProject: { en: 'New Project', he: 'פרוייקט חדש' },
    },
    search: {
        placeholder: { en: 'Search projects', he: 'חיפוש פרויקטים' },
        noQuaryMsg: {
            en: 'Enter project name or source name (at least 4 characters).',
            he: 'הזינו שם פרוייקט או קוד מקור (לפחות 4 תווים).',
        },
        noResultsMsg: {
            en: 'No projects match your search.',
            he: 'לא נמצאו פרויקטים תואמים',
        },
    },
    projectItem: {
        options: {
            duplicate: { en: 'Duplicate', he: 'שכפול' },
            archive: { en: 'Archive', he: 'העברה לארכיון' },
            editLabels: { en: 'Edit labels', he: 'עריכת לייבלים' },
            showDetails: { en: 'Show details', he: 'הצגת פרטים' },
            share: { en: 'Share', he: 'שיתוף' },
            restore: { en: 'Restore', he: 'שיחזור' },
            delete: { en: 'Delete', he: 'מחיקה' },
        },
        deleteValidationAlert: {
            title: { en: 'Delete for ever 🕳', he: 'מחיקה סופית 🕳' },
            message: { en: 'Are you sure?', he: 'האם אתם בטוחים?' },
            cancel: { en: 'Cancel', he: 'ביטול' },
            delete: { en: 'Delete', he: 'מחיקה' },
        },
        deleteErrorAlert: {
            title: { en: 'Could not delete', he: 'לא ניתן להשלים את המחיקה' },
            message: { en: 'Error details: ', he: 'פרטי השגיאה: ' },
            okay: { en: 'Okay', he: 'אוקי' },
        },
        bottomSentence: {
            today: {
                en: 'You opened this today',
                he: 'נכנסת לפרוייקט זה היום',
            },
            yesterday: {
                en: 'You opened this yesterday',
                he: 'נכנסת לפרוייקט זה אתמול',
            },
            lastDate: { en: 'Last opened:', he: 'נפתח לאחרונה:' },
        },
    },
};

export const surveyEditorTexts = {
    appName: { en: 'Survey Editor', he: 'עורך סקרים' },
    projectNotFound: { en: 'Project not found', he: 'הפרוייקט לא נמצא' },
    anotherClientIsEditing: {
        en: 'It seems like {name} is editing the survey, please wait a moment...',
        he: 'נראה שהסקר בעריכה ע״י {name}, חכו כמה רגעים...',
    },
    connectingToServer: {
        en: 'Connecting to server..',
        he: 'מתחבר לשרת..',
    },
    serverConnectionIssues: {
        en: "It seems like we're havign connection issues, please wait a moment and reload.",
        he: 'נראה שיש בעיות תקשורת, חכו כמה רגעים ורעננו את המסך.',
    },
    contentTopBar: {
        isAutoSaving: { en: 'Saving...', he: 'שומר...' },
        toggleLangTooltip: { en: 'Ctrl+L', he: 'Ctrl+L' },
        collapseAllTooltip: { en: 'Collapse all', he: 'כיווץ הכל' },
        expandAllTooltip: { en: 'Expand all', he: 'פריסת הכל' },
    },
    sidebar: {
        surveySettingsTooltip: { en: 'Survey settings', he: 'הגדרות שאלון' },
        pageSettingsTooltip: { en: 'Page settings', he: 'הגדרות עמוד' },
        publishTooltip: { en: 'Publish & export', he: 'הפקה ויצוא' },
        mediaTooltip: { en: 'Media settings', he: 'הגדרות מדיה' },
        logicTooltip: { en: 'Logic', he: 'לוגיקה' },
    },
    properties: {
        surveySettings: {
            title: { en: 'Survey settings', he: 'הגדרות שאלון' },
            sourceNameLabel: { en: 'SN', he: 'SN' },
            surveyNameLabel: { en: 'Name', he: 'שם' },
            surveyNamePlaceholder: { en: 'Untitled', he: 'ללא כותרת' },
            surveyColorLabel: { en: 'Color', he: 'צבע' },
            surveyLanguagesLabel: { en: 'Languages', he: 'שפות' },
            surveyLanguagesPlaceHolder: { en: 'Choose', he: 'בחירה' },
            hideProgressBar: {
                en: 'Hide progress bar',
                he: 'הסתרת בר התקדמות',
            },
            colors: {
                '000': { en: 'Blue', he: 'כחול' },
                '001': { en: 'Green', he: 'ירוק' },
                '002': { en: 'Pink', he: 'ורוד' },
                '003': { en: 'Yellow', he: 'צהוב' },
                '004': { en: 'Orange', he: 'כתום' },
                custom: { en: 'Custom colors', he: 'צבעים יחודיים' },
            },
            fillingOptions: {
                limitationLabel: {
                    en: 'Filling limitation',
                    he: 'הגבלת מילוי',
                },
                noLimitClearRecord: {
                    en: 'No limit + restart ',
                    he: 'ללא הגבלה + איפוס',
                },
                noLimit: { en: 'No limit', he: 'ללא הגבלה' },
                onlyOnce: { en: 'Only Once', he: 'פעם אחת בלבד' },
                onceADay: { en: 'Once a day', he: 'פעם ביום' },
                onceAWeek: { en: 'Once a week', he: 'פעם בשבוע' },
                onceAMonth: { en: 'Once a month', he: 'פעם בחודש' },
                onceAYear: { en: 'Once a year', he: 'פעם בשנה' },
                help: {
                    helpTooltip: { en: 'Help', he: 'עזרה' },
                    title: { en: 'Filling limitation', he: 'הגבלת מילוי' },
                    gotItAction: { en: 'Got it', he: 'הבנתי' },
                    preface: {
                        en: `A survey can only be filled once by default.
                        Upon returning to a survey that has been completed, the respondent will be directed to the ending page.
                        If the respondent has left a survey uncompleted, on revisiting he will be directed to the page he left.`,
                        he: `בתור ברירת מחדל ניתן להשיב על שאלון פעם אחת בלבד. 
                        בעת כניסה חוזרת לשאלון שנגמר, המשיב יופנה למסך הסיום. 
                        במידה והמשיב לא סיים את השאלון, בכניסה חוזרת הוא ימשיך מהנקודה בה הפסיק.`,
                    },
                    noLimitExplanation: {
                        en: `A completed survey will be available to be filled again on the next visit. 
                        An uncompleted survey will direct the respondent to where he left.`,
                        he: `שאלון שהסתיים יתחיל מחדש בעת כניסה מחודשת. כל עוד השאלון
                    לא הסתיים, המשיב ימשיך מהנקודה בה הפסיק.`,
                    },
                    noLimitClearRecordExplanation: {
                        en: `Any second visit to the survey will clear the last session and the respondent will start from scratch.`,
                        he: `כל כניסה חוזרת לשאלון תאפס את התשובות והמשיב יתחיל
                        מחדש את השאלון, גם אם טרם סיים אותו בביקור הקודם.`,
                    },
                    timeLimitation: {
                        en: 'Limit by time frame',
                        he: 'הגבלת בהתאם לפרק הזמן',
                    },
                    timeLimitationExplanation: {
                        en: `A completed survey will be available to be filled again if the required time frame has passed since completion.
                        Uncompleted entries will be discarded once the time frame ends.`,
                        he: `ניתן יהיה
                        לשוב ולענות על שאלון שהסתיים, במידה ופרק הזמן המבוקש
                        עבר. תשובות שלא הסתיימו יתאפסו בעת חלוף פרק הזמן.`,
                    },
                },
                anonymousFilling: {
                    en: 'Anonymous filling',
                    he: 'מענה אנונימי',
                },
            },
            splashSettings: {
                title: { en: 'Splash settings', he: 'הגדרות ספלאש' },
                previewSplash: { en: 'Toggle preview', he: 'תצוגה מקדימה' },
                missingSurveyName: {
                    en: '(Survey name is empty)',
                    he: '(חסר שם לשאלון)',
                },
                useSplash: { en: 'Use splash', he: 'שימוש בספלאש' },
                logoLabel: { en: 'Logo', he: 'לוגו' },
                removeLogo: { en: 'Remove logo', he: 'הסרת לוגו' },
                uplaodLogo: { en: 'Upload logo', he: 'העלאת לוגו' },
                replaceLogo: { en: 'Replace logo', he: 'שינוי לוגו' },
                text: { en: 'Text', he: 'טקסט' },
            },
            platformLimition: {
                title: { en: 'Platform limitation', he: 'הגבלת פלטפורמה' },
                options: {
                    none: { en: 'None', he: 'ללא הגבלה' },
                    desktop_only: { en: 'Desktop only', he: 'רק דסקטופ' },
                    mobile_only: { en: 'Mobile only', he: 'רק מובייל' },
                    force_mobile: { en: 'Force mobile', he: 'מובייל מאולץ' },
                },
                help: {
                    helpTooltip: { en: 'Help', he: 'עזרה' },
                    title: { en: 'Platform limitation', he: 'הגבלת פלטפורמה' },
                    gotItAction: { en: 'Got it', he: 'הבנתי' },
                    preface: {
                        en: 'You can define the range of screen types the survey is supporting.',
                        he: 'ניתן להגדיר לאילו סוגי מסכים השאלון מיועד.',
                    },
                    noneExplanation: {
                        en: '(default) Support all screen types and sizes.',
                        he: '(ברירת מחדל) תמיכה רגילה בכל סוגי המסכים.',
                    },
                    desktopOnlyExplanation: {
                        en: 'The survey is only available on large screens.',
                        he: 'השאלון יהיה זמין רק עבור מסכים גדולים.',
                    },
                    mobileOnlyExplanation: {
                        en: 'The survey is only available on small screens.',
                        he: 'השאלון יהיה זמין רק עבור מסכים קטנים.',
                    },
                    forceMobileExplanation: {
                        en: "The survey is available on small screens, and will keep it's small dimensions even when presented on large screens.",
                        he: 'השאלון יהיה זמין עבור מסכים קטנים וישמור על מימדיו הקטנים גם כאשר יפתח במסך גדול.',
                    },
                },
            },
        },
        pageSettings: {
            pageTitle: { en: 'Page {n} settings', he: 'הגדרות עמוד {n}' },
            blockTitle: { en: 'Block {n} settings', he: 'הגדרות בלוק {n}' },
            noPage: { en: 'Please select page...', he: 'יש לבחור עמוד...' },
            pageKeyLabel: { en: 'Key', he: 'מזהה' },
            showSubtitleLabel: { en: 'Show subtitle', he: 'הצגת כותרת משנה' },
            mediaTitle: { en: 'Media', he: 'מדיה' },
            advancedSettingsLabel: {
                en: 'Advanced settings',
                he: 'הגדרות מתקדמות',
            },
            saveAsUserArg: {
                en: 'Save answer as a user argument',
                he: 'הוספת תשובה לפרופיל משתמש',
            },
            randomizeItems: { en: 'Randomize items', he: 'סדר פריטים רנדומלי' },
            missingKeytWarning: {
                en: 'Key is missing. Pages should always have a key!',
                he: 'חובה להזין מזהה לכל עמוד!',
            },
            missingKeyAlert: {
                title: { en: 'Page key is missing', he: 'מזהה העמוד חסר' },
                message: {
                    en: 'We are adding a temporary key but you should define one of your own.',
                    he: 'המערכת תגדיר מזהה זמני. מומלץ להחליף אותו במזהה יחודי משלכם.',
                },
                action: { en: 'Got it', he: 'הבנתי' },
            },
            keyAlreadyExistWarning: {
                en: 'This key is already used in another page',
                he: 'המזהה כבר קיים במסך אחר',
            },
            varNameAlreadyExistWarning: {
                en: 'This key is already used as a survey variable',
                he: 'המזהה כבר משמש בתור שם משתנה',
            },
            allowRepeatedInput: { en: 'Repeated use', he: 'שימוש חוזר' },
            allowRepeatedInpuTooltip: {
                en: 'Upon returning to the page, the page logic will run again and users will be able to change their answers.',
                he: 'בעת ביקור נוסף בעמוד, הלוגיקה תרוץ מחדש ומשתמשים יוכלו לשנות את תשובותיהם הקודמות.',
            },
            resetInputOnRevisit: {
                en: 'Reset input on repeated use',
                he: 'איפוס תשובות בשימוש חוזר',
            },
            continueButtonSettings: {
                title: {
                    en: 'Continue Button Settings',
                    he: 'הגדרות כפתור המשך',
                },
                continueButtonCentered: {
                    en: 'Centered text',
                    he: 'מירכוז טקסט',
                },
                customTextLabel: {
                    en: 'Custom text',
                    he: 'טקסט חליפי',
                },
            },
            settingsModules: {
                opening: {
                    showIntroText: {
                        en: 'Show intro text',
                        he: 'הצגת טקסט פתיחה',
                    },
                    terms: {
                        showTermsLinks: {
                            en: 'Show terms links',
                            he: 'הצגת תנאי שימוש',
                        },
                        editTermsLinks: {
                            en: 'Edit terms links',
                            he: 'עריכת לינקים לתנאי שימוש',
                        },
                        done: {
                            en: 'Done',
                            he: 'אישור',
                        },
                        addLinkBtn: { en: 'Add link', he: 'הוספת לינק' },
                        linkLabelPlaceHolder: {
                            en: 'Terms of service',
                            he: 'תנאי שימוש',
                            ar: 'شروط الخدمة',
                        },
                        linkUrlInputLabel: {
                            en: 'URL',
                            he: 'URL',
                        },
                        linkTextInput: {
                            en: 'Link text',
                            he: 'טקסט',
                        },
                        removeLinktTooltip: {
                            en: 'Remove Link',
                            he: 'הסרת לינק',
                        },
                    },
                },
                multipleChoice: {
                    ansCountSection: { en: 'Answers count', he: 'כמות תשובות' },
                    minChoices: { en: 'At least', he: 'לפחות' },
                    choicesLimit: { en: 'No more than', he: 'לא יותר מ:' },
                    noLimit: { en: 'No limit', he: 'ללא הגבלה' },
                    autoContinue: { en: 'Auto continue', he: 'המשך אוטומטי' },
                    showOpenItem: {
                        en: 'Show open item',
                        he: 'הצגת פריט פתוח',
                    },
                    centeredAnswers: {
                        en: 'Centered answers',
                        he: 'מירכוז תשובות',
                    },
                    isNumeric: {
                        en: 'Assign numeric values',
                        he: 'הוספת ערכים מספריים',
                    },
                },
                scale: {
                    scaleSize: { en: 'Scale size', he: 'גודל הסקאלה' },
                    customScaleValues: {
                        en: 'Custom scale values',
                        he: 'הזנת ערכים ידנית',
                    },
                    randomDrawCount: {
                        en: 'Amount of items to draw',
                        he: 'מספר פריטים להגרלה',
                    },
                    drawAll: { en: 'All', he: 'כולם' },
                    showSecondaryButton: {
                        en: 'Show secondary button',
                        he: 'הצגת כפתור משני',
                    },
                },
                dropdownList: {},
                openEnded: {},
                description: {
                    titleSize: { en: 'Title size', he: 'גודל כותרת' },
                    normal: { en: 'Normal', he: 'רגיל' },
                    large: { en: 'Large', he: 'גדול' },
                },
                numberModule: {
                    unitLabel: { en: 'Unit', he: 'יחידה' },
                    unitPlaceholder: { en: "i.e. '%'", he: 'למשל ׳%׳' },
                    useCustomRangeLabels: {
                        en: 'Use custom range labels',
                        he: 'הזנת תוויות טווח ידנית',
                    },
                },
                ending: {
                    showEpiloge: {
                        en: 'Show epiloge text',
                        he: 'הצגת טקסט סיכום',
                    },
                    showConfetti: { en: 'Show confetti', he: 'הצגת קונפטי' },
                    callToActionButtn: {
                        en: 'Show call-to-action button',
                        he: 'הצגת כפתור פעולה',
                    },
                },
                block: {
                    randomizationTitle: { en: 'Randomization', he: 'אקראיות' },
                    randomize: {
                        en: 'Display pages randomly',
                        he: 'הצגת עמודים אקראית',
                    },
                    randomDrawCount: {
                        en: 'Amount of pages to draw',
                        he: 'מספר עמודים להגרלה',
                    },
                    drawAll: { en: 'All', he: 'כולם' },
                    setWeights: { en: 'Set weights', he: 'הגדרת משקלים' },
                    weightsDialog: {
                        title: {
                            en: 'Pages weights (0 to 1)',
                            he: 'משקלי עמודים (0 עד 1)',
                        },
                        rebalanceButton: { en: 'Rebalance', he: 'איזון' },
                    },
                },
                externalPage: {
                    showContinueButton: {
                        en: 'Show continue button',
                        he: 'הצגת כפתור המשך',
                    },
                    permissionsLabel: {
                        en: 'Device permissions',
                        he: 'הרשאות מכשיר',
                    },
                    allowCamera: {
                        en: 'Allow camera',
                        he: 'גישה למצלמה',
                    },
                    allowMic: {
                        en: 'Allow microphone',
                        he: 'גישה למיקרופון',
                    },
                },
            },
        },
        publishAndExport: {
            title: { en: 'Publish & Export', he: 'הפקה וייצוא' },
            publishBtn: { en: 'Publish', he: 'הפקת שאלון' },
            versionsSectionLabel: { en: 'Versions', he: 'גרסאות' },
            loadVersionBtn: { en: 'Restore', he: 'שחזור' },
            copyVersionUrl: { en: 'Copy URL', he: 'העתקת URL' },
            surveyLinkBtn: { en: 'Survey link', he: 'לינק לשאלון' },
            linkOptions: {
                live: { en: 'Live survey', he: 'שאלון חי' },
                view_only: {
                    en: 'Internal preview',
                    he: 'תצוגה ללא שמירת תשובות',
                },
            },
            linkCopied: { en: 'Copied', he: 'הועתק' },
            downloadBackupBtn: { en: 'Download backup', he: 'הורדת גיבוי' },
            restoreFromFileBtn: {
                en: 'Restore from file',
                he: 'שחזור מתוך קובץ',
            },
            restoreAlert: {
                title: {
                    en: 'Are you sure you want to restore?',
                    he: 'בטוחים שאתם רוצים להמשיך בשחזור?',
                },
                message: {
                    en: 'The current content will be replaced by the restored data.',
                    he: 'התוכן הנוכחי ידרס על ידי המידע המשוחזר.',
                },
                actions: {
                    cancel: { en: 'Cancel', he: 'ביטול' },
                    approve: { en: 'Approve', he: 'אישור' },
                },
            },
            milgoPublishing: {
                descriptionPlaceholder: {
                    en: 'version description',
                    he: 'תיאור גרסה',
                },
                noDescriptionPublishBtn: {
                    en: 'Publish (missing description)',
                    he: 'הפקת שאלון (יש להזין תיאור)',
                },
                feedbackError: { en: 'publising error', he: 'שגיאה בהפקה' },
                feedbackSuccess: {
                    en: 'Successfully publised',
                    he: 'הופק בהצלחה',
                },
            },
        },
        logic: {
            pageLogicTitle: { en: 'Page {n} logic', he: 'לוגיקת עמוד {n}' },
            variablesTitle: { en: 'Survey variables', he: 'משתני שאלון' },
            variablesTypes: {
                number: { en: 'Number', he: 'מספר' },
                string: { en: 'Text', he: 'טקסט' },
                boolean: { en: 'True/False', he: 'אמת/שקר' },
                array: { en: 'List', he: 'רשימה' },
                url_param: { en: 'URL parameter', he: 'פרמטר URL' },
                datasource: { en: 'DataSource var', he: 'משתנה DataSource' },
            },
            variable: { en: 'variable', he: 'משתנה' },
            varTypeTooltip: { en: 'Variable type', he: 'סוג משתנה' },
            deleteVar: { en: 'Delete var', he: 'מחיקת משתנה' },
            addNewVarTip: {
                en: 'Enter name to add a new variable.',
                he: 'הוסיפו משתנה חדש באמצעות עריכת השם.',
            },
            idencticalVarNameWarning: {
                en: 'Duplicate variable name',
                he: 'שם המשתנה תפוס.',
            },
            idencticalToPageKeyWarning: {
                en: 'This variable name is already used as a page key.',
                he: 'שם המשתנה כבר משמש כמזהה של עמוד.',
            },
            pageLogicPhases: {
                onPageEnter: { en: 'On Page Enter', he: 'בכניסה לעמוד' },
                onPageExit: { en: 'On Page Exit', he: 'ביציאה מהעמוד' },
            },
            createLogicItem: { en: 'Create logic item', he: 'יצירת לוגיקה' },
            deleteLogicItem: { en: 'Delete logic item', he: 'מחיקת לוגיקה' },
            logicItemDeletedMsg: {
                en: 'Logic item deleted',
                he: 'הלוגיקה נמחקה.',
            },
            navigationItemsTite: {
                en: 'Navigation items',
                he: 'לוגיקות ניווט',
            },
            itemEditor: {
                always: { en: 'Always', he: 'תמיד' },
                titlePlaceholder: { en: 'Set title', he: 'הזינו כותרת' },
                if: { en: 'If', he: 'בתנאי ש' },
                do: { en: 'Do', he: 'עשה' },
                closeLogicItem: { en: 'Close logic item', he: 'סגירת לוגיקה' },
                referenceDataOptions: {
                    surveyVars: { en: 'Survey variables', he: 'משתני שאלון' },
                    userInput: { en: 'User input', he: 'קלט משתמש' },
                    userInputTypes: {
                        timeSpan: {
                            en: 'Time on page (seconds)',
                            he: 'משך שהייה בעמוד (שניות)',
                        },
                        answer: { en: 'Answer', he: 'תשובה' },
                        answerText: {
                            en: 'Answer text',
                            he: 'טקסט מתוך התשובה',
                        },
                        answerIndex: { en: 'Answer index', he: 'אינדקס תשובה' },
                    },
                    constant: { en: 'Constant', he: 'קבוע' },
                    random: {
                        en: 'Random number (0.0-1.0)',
                        he: 'מספר רנדומלי (1.0-0.0)',
                    },
                },
                argumentSetup: {
                    argSelectionPlaceholder: { en: 'Select', he: 'בחירה' },
                    argSelectionTooltip: {
                        en: 'Select argument',
                        he: 'בחירת ארגומנט',
                    },
                    argChangeTooltip: {
                        en: 'Change argument',
                        he: 'שינוי ארגומנט',
                    },
                    answersItemsArg: { en: 'Page answers', he: 'תשובות עמוד' },
                },
                condition: {
                    addFragmentTooltip: { en: 'Add rule', he: 'הוספת כלל' },
                    group: { en: 'Group', he: 'קבוצה' },
                    groupOptions: {
                        btnTooltip: {
                            en: 'Group options',
                            he: 'אפשרויות קבוצה',
                        },
                        ungroup: { en: 'Ungroup', he: 'הפרדת הקבוצה' },
                        deleteAll: { en: 'Remove', he: 'מחיקה' },
                    },

                    rule: { en: 'Rule', he: 'כלל' },
                    comparison: {
                        eq: { en: 'Equals', he: 'שווה' },
                        ne: { en: 'Not equals', he: 'לא שווה' },
                        gt: { en: 'Greater than', he: 'גדול מ_' },
                        ge: {
                            en: 'Greater than or equal',
                            he: 'גדול מ_ או שווה',
                        },
                        lt: { en: 'Less than', he: 'קטן מ_' },
                        le: { en: 'Less than or equal', he: 'קטן מ_ או שווה' },
                        in: { en: 'Included in', he: 'נכלל ב' },
                        comperisonOperatorTooltip: {
                            en: 'Comparison rule',
                            he: 'כלל השוואה',
                        },
                    },
                    deleteRuleTooltip: { en: 'Remove', he: 'מחיקה' },
                    and: { en: 'and', he: 'וגם' },
                    or: { en: 'or', he: 'או' },
                    logicalOperatorTooltip: {
                        en: 'Logical operator',
                        he: 'פעולה לוגית',
                    },
                },
                actions: {
                    addActionBtn: { en: 'Add action', he: 'הוספת פעולה' },
                    deleteBtnTooltip: {
                        en: 'Delete action',
                        he: 'מחיקת פעולה',
                    },
                    actionTypePlaceholder: {
                        en: 'Select action:',
                        he: 'בחירת פעולה:',
                    },
                    actionTypes: {
                        stopAndGoto: {
                            en: 'Stop and goto',
                            he: 'עצירה ומעבר ל־',
                        },
                        setNextPage: {
                            en: 'Set next page',
                            he: 'שינוי עמוד יעד',
                        },
                        stopAndSkipPage: {
                            en: 'Stop and skip to',
                            he: 'עצירה ודילוג ל־',
                        },
                        setVar: { en: 'Set var', he: 'שינוי משתנה' },
                        redirectToUrl: {
                            en: 'Redirect to URL',
                            he: 'הפנייה ל־URL',
                        },
                        consoleLog: { en: 'Console log', he: 'רישום לוג' },
                    },
                    modules: {
                        pageSelectionLabel: {
                            en: 'Select page',
                            he: 'בחירת עמוד',
                        },
                        variableSelectionLabel: {
                            en: 'Select variable',
                            he: 'בחירת משתנה',
                        },
                        gotoOptions: {
                            nextInOrder: {
                                en: 'Next in order',
                                he: 'הבא ברשימה',
                            },
                            targetPage: {
                                en: 'Target page',
                                he: 'עמוד לבחירה',
                            },
                        },
                        redirectToUrlOption: {
                            invalidUrlWarning: {
                                en: 'Invalid URL',
                                he: 'כתובת URL לא תקינה',
                            },
                            openInNewTabLabel: {
                                en: 'Open in a new tab',
                                he: 'פתיחה בטאב חדש',
                            },
                        },
                    },
                    assignment: {
                        assign: { en: 'Assignment', he: 'השמה' },
                        addAssign: { en: 'Addition', he: 'הוספה' },
                        subAssign: { en: 'Subtraction', he: 'החסרה' },
                        mulAssign: { en: 'Multiplication', he: 'הכפלה' },
                        divAssign: { en: 'Division', he: 'חלוקה' },
                        modAssign: { en: 'Remainder', he: 'שארית' },
                        expAssign: { en: 'Exponentiation', he: 'חזקה' },
                        assignmentOperatorTooltip: {
                            en: 'Assignment operator',
                            he: 'פעולת השמה',
                        },
                    },
                },
            },
        },
        media: {
            pageMediaTitle: { en: 'Page {n} Media', he: 'מדיה / עמוד {n}' },
            surveyMediaTitle: { en: 'Library', he: 'ספריה' },
            chooseMediaBtn: { en: 'Choose media', he: 'בחירת מדיה' },
            clearMediaTooltip: {
                en: 'Clear page media',
                he: 'הסרת מדיה מעמוד',
            },
            pageMediaSettings: {
                width: { en: '↔ Placement', he: '↔ פריסה' },
                content: { en: 'With content', he: 'עם התוכן' },
                full: { en: 'Full width', he: 'ברוחב מלא' },

                framePositioning: { en: 'Frame position', he: 'מיקום במסגרת' },
                top: { en: 'Top', he: 'למעלה' },
                center: { en: 'Center', he: 'במרכז' },
                bottom: { en: 'Bottom', he: 'למטה' },

                heightLimit: { en: '↕ Frame height', he: '↕ גובה מסגרת' },
                allowZoom: { en: 'Allow zoom', he: 'אפשרות לזום' },

                lottiePlayMode: {
                    en: 'Animation play mode',
                    he: 'ניגון אנימציה',
                },
                once: { en: 'Once', he: 'פעם אחת' },
                loop: { en: 'Loop', he: 'לופ' },
            },
        },
    },
    content: {
        page: { en: 'page', he: 'עמוד' },
        noPagesDescription: {
            en: 'Go on, create something:',
            he: 'השאלון ריק, קדימה לעבודה:',
        },
        noPagesNewBtn: { en: 'New page', he: 'עמוד חדש' },
        pageOptions: {
            btnTooltip: { en: 'Options', he: 'אפשרויות' },
            pageSettings: { en: 'Settings', he: 'הגדרות' },
            pagePreview: { en: 'Preview outside', he: 'תצוגה בטאב נפרד' },
            pageDelete: { en: 'Delete', he: 'מחיקה' },
            pageDuplicate: { en: 'Duplicate', he: 'שכפול' },
            duplicatePageAlert: {
                title: {
                    en: "Page logic can't be duplicated.",
                    he: 'לוגיקת עמוד לא ניתנת לשיכפול.',
                },
                message: {
                    en: 'Whould you like to duplicate the page without logic?',
                    he: 'האם תרצו לשכפל את העמוד ללא לוגיקה?',
                },
                actions: {
                    duplicateAnyway: { en: 'Duplicate', he: 'שכפול' },
                    cancel: { en: 'Cancel', he: 'ביטול' },
                },
            },
        },
        pageTitlePlaceholder: { en: 'Title / Question', he: 'כותרת / שאלה' },
        pageItem: {
            item: { en: 'item', he: 'פריט' },
            pageItemPlaceholder: { en: 'List item', he: 'תוכן הפריט' },
            addNewItemBtn: { en: 'Add item', he: 'הוספת פריט' },
            itemNoKey: { en: 'undefined', he: 'undefined' },
            deleteItemTooltip: { en: 'Delete item', he: 'מחיקת פריט' },
            navOptions: {
                navOptionsTooltip: {
                    en: 'Navigation options',
                    he: 'אפשרויות ניווט',
                },
                gotoPage: { en: 'Go to page', he: 'מעבר לעמוד' },
                removeNavitation: { en: 'Remove navigation', he: 'ללא ניווט' },
            },
            hidingOptions: {
                hidingOptionsTooltip: { en: 'Hide', he: 'הסתרה' },
                stateBtn_visible: { en: 'Show', he: 'הצגה' },
                stateBtn_hidden: { en: 'Hide', he: 'הסתרה' },
                stateBtn_conditionallyHidden: {
                    en: 'Hide conditionally',
                    he: 'הסתרה בתנאי',
                },
                conditionTitle: { en: 'Hide if:', he: 'הסתרה בתנאי ש:' },
            },
        },
        deletePageAlert: {
            title: { en: 'Are you sure?', he: 'בטוחים?' },
            message: { en: "It can't be undone.", he: 'לא ניתן להתחרט.' },
            actions: {
                delete: { en: 'Delete', he: 'מחיקה' },
                cancel: { en: 'Cancel', he: 'ביטול' },
            },
        },
        warnings: {
            itemKeyAlreadyExist: {
                en: 'Key already exist!',
                he: 'המזהה כבר קיים!',
            },
        },
        pageModules: {
            block: {
                addInternalPage: {
                    en: 'Create a new page',
                    he: 'הוספת עמוד חדש',
                },
                blockOptions: {
                    btnTooltip: { en: 'Options', he: 'אפשרויות' },
                    blockSettings: { en: 'Settings', he: 'הגדרות' },
                    blockDelete: { en: 'Delete', he: 'מחיקה' },
                },
                deleteBlockAlert: {
                    title: { en: 'Delete block', he: 'מחיקת בלוק' },
                    message: {
                        en: "Would you like to also delete the block's pages?",
                        he: 'האם תרצו למחוק גם את העמודים שמכיל הבלוק?',
                    },
                    actions: {
                        keepPages: { en: 'Keep Pages', he: 'שמירת עמודים' },
                        deleteAll: { en: 'Delete All', he: 'מחיקת הכל' },
                        cancel: { en: 'Cancel', he: 'ביטול' },
                    },
                },
                blockTitlePlaceholder: { en: 'Block title', he: 'כותרת הבלוק' },
            },
            opening: {
                introTextPlaceholder: { en: 'Intro text', he: 'טקסט פתיחה' },
            },
            multipleChoice: {
                subtitlePlaceholder: { en: 'Enter subtitle', he: 'כותרת משנה' },
                openItemDefaultText: {
                    en: 'Other ＿',
                    he: 'אחר ＿',
                    ar: 'آخر ＿',
                },
            },
            number: {
                subtitlePlaceholder: { en: 'Enter subtitle', he: 'כותרת משנה' },
                inputTypeLabel: { en: 'Input type', he: 'אמצעי קלט' },
                inputTypes: {
                    slider: { en: 'Slider (estimation)', he: 'סליידר (הערכה)' },
                    keyboard: {
                        en: 'Keyboard (specification)',
                        he: 'מקלדת (דיוק)',
                    },
                },

                // Keyboard options:
                minCharactersLabel: { en: 'Min chars', he: 'מינ׳ תווים' },
                placeholderLabel: { en: 'Placeholder', he: 'טקסט זמני' },

                // Slider option:
                min: { en: 'Min', he: 'מינימום' },
                max: { en: 'Max', he: 'מקסימום' },
                minLabel: { en: 'Min label', he: 'תווית מינ׳' },
                maxLabel: { en: 'Max label', he: 'תווית מקס׳' },
                initial: { en: 'Initial', he: 'ערך התחלתי' },
                unit: { en: 'Unit', he: 'יחידה' },
            },
            scale: {
                legend: { en: 'Legend', he: 'מקרא' },
                placeholder: {
                    en: '{low} - Low | {high} - High',
                    he: '{low} - נמוך | {high} - גבוה',
                    ar: '{low} - قليل | {high} - عالي',
                },
                keyAlreadyExists: {
                    en: 'Key already exists',
                    he: 'המזהה כבר קיים',
                },
                secondaryButtonText: { en: 'N/A', he: 'לא רלוונטי', ar: 'غ/م' },
            },
            openEnded: {
                subtitlePlaceholder: { en: 'Enter subtitle', he: 'כותרת משנה' },
                placeholderLabel: { en: 'Placeholder', he: 'טקסט זמני' },
                placeholderHint: { en: 'none', he: 'ללא' },
                inputTypeLabel: { en: 'Input type', he: 'סוג הקלט' },
                minCharactersLabel: { en: 'Min chars', he: 'מינ׳ תווים' },
                inputTypes: {
                    text: { en: 'Short text', he: 'טקסט קצר' },
                    multiline: { en: 'Multiline', he: 'פסקה' },
                    email: { en: 'email', he: 'אימייל' },
                },
            },
            dropdownList: {
                subtitlePlaceholder: { en: 'Enter subtitle', he: 'כותרת משנה' },
                listNamePlaceholderLabel: {
                    en: 'List title',
                    he: 'כותרת הרשימה',
                },
                listNamePlaceholderHint: { en: 'none', he: 'ללא' },
                addItemsFromFileBtn: {
                    en: 'Add items from CSV',
                    he: 'העלאת פריטים מ־ CSV',
                },
                deleteAllBtn: { en: 'Delete all', he: 'מחיקת הכל' },
                helpDialog: {
                    title: {
                        en: 'CSV file instructions:',
                        he: 'הוראות הכנת קובץ פריטים',
                    },
                    message: {
                        step1: {
                            en: 'Open the template file.',
                            he: 'פתחו את קובץ הדוגמה.',
                        },
                        step2: {
                            en: 'Make a copy of the file.',
                            he: 'צרו העתק של הקובץ.',
                        },
                        step3: {
                            en: 'Fill in the data for each of the survey languages and add a unique key to each row.',
                            he: 'הזינו את המידע עבור כל אחת מהשפות בשאלון והגדירו מזהה ייחודי (key) עבור כל שורה.',
                        },
                        step4: {
                            en: 'Remove blank rows.',
                            he: 'הקפידו למחוק שורות ריקות.',
                        },
                        step5: {
                            en: 'Download as CSV and upload Horizon.',
                            he: 'הורידו את הטבלה בתור קובץ CSV והעלו להוריזון.',
                        },
                    },
                    file: { en: 'Template file', he: 'קובץ דוגמה' },
                    action: { en: 'Got it', he: 'הבנתי' },
                },
            },
            description: {
                placeholder: { en: 'Description', he: 'תיאור' },
            },
            ending: {
                epilogePlaceholder: { en: 'Epiloge text', he: 'טקסט סיכום' },
                callToActionText: {
                    en: 'Call to action text',
                    he: 'טקסט פעולה',
                },
                callToActionLink: {
                    en: 'Call to action link',
                    he: 'לינק פעולה',
                },
            },
            externalPage: {
                urlLabel: { en: 'Page URL', he: 'כתובת העמוד (URL)' },
                invalidUrl: { en: 'Invalid URL', he: 'כתובת URL לא תקנית' },
            },
        },
    },
    preview: {
        topBar: {
            mobileViewBtnTooltip: { en: 'Mobile view', he: 'תוצגת מכשיר נייד' },
            desktopViewBtnTooltip: { en: 'Desktop view', he: 'תצוגת מחשב' },
            previewBtnTooltip: { en: 'Preview', he: 'תצוגה מקדימה' },
            previewCodeLabel: { en: 'Preview code', he: 'קוד תצוגה מקדימה' },
            openInNewWindow: { en: 'Open in a new tab', he: 'הצגה בטאב חדש' },
        },
    },
    messages: {
        actions: {
            gotIt: { en: 'Got it', he: 'הבנתי' },
            undo: { en: 'Undo', he: 'ביטול' },
        },
        illegalKeyCharacter: {
            en: "You can only use small English letters, numbers and underscores '_'.",
            he: 'ניתן להשתמש רק באותיות אנגלית קטנות, מספרים וקו תחתון ׳_׳.',
        },
        referenceInLogicAlert: {
            title: { en: 'Logic break warning ⚡️', he: 'אזהרת לוגיקה ⚡️' },
            message: {
                en: "The {item} you're about to delete is referenced from a logic item.",
                he: 'קיים רפרנס ל{item} באחת מהלוגיקות. הרפרנס יעלם בעת המחיקה.',
            },
            actions: {
                deleteAnyway: { en: 'Delete anyway', he: 'מחיקה' },
                cancel: { en: 'Cancel', he: 'ביטול' },
            },
        },
    },
};

export const notebookTexts = {
    header: {
        createdAtLabel: { en: 'Created at', he: 'תאריך יצירה' },
        modifiedLabel: { en: 'Modified', he: 'עריכה אחרונה' },
        modifiedLabelDetails: { en: '{d} by {u}', he: '{d} ע״י {u}' },
    },
    toolbars: {
        isAutoSaving: { en: 'Saving...', he: 'שומר...' },
        pageDirection: { en: 'Page direction', he: 'כיוון העמוד' },
    },
    content: {
        blankMessage: {
            en: 'Jumpstart your research:',
            he: 'המחקר שלך מתחיל כאן:',
        },
        addSectionBtn: { en: 'Add a section', he: 'הוספת סעיף' },
        templatesOption: { en: 'Templates', he: 'טמפלייטים' },
        loading: { en: 'Loading...', he: 'טוען...' },
        section: {
            options: {
                btnTooltip: { en: 'Options', he: 'אפשרויות' },
                delete: { en: 'Delete', he: 'מחיקה' },
            },
            titlePlaceholder: { en: 'Section title', he: 'כותרת הסעיף' },
            styleFormats: {
                normalText: { en: 'Normal text', he: 'טקסט רגיל' },
                heading: { en: 'Heading', he: 'כותרת' },
            },
        },
    },
    anotherClientIsEditing: {
        en: 'It seems like {name} is editing the notebook, please wait a moment...',
        he: 'נראה שהמחברת בעריכה ע״י {name}, חכו כמה רגעים...',
    },
};
