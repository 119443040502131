import { jsonToGraphQLQuery, EnumType } from "json-to-graphql-query";
import { diff_match_patch } from "utils/diff_match_patch_uncompressed";
import * as SocketApi from "api/socketApi";
var DiffMatchPatch = new diff_match_patch();

var environmentName = "PRODUCTION";
// if (process.env.NODE_ENV === 'development') {
//   environmentName = 'LOCAL'
// }
var USE_PATCHES = false;

const ENV_SETTINGS = {
    LOCAL: {
        gqlApiUrl: "http://localhost:3000/api",
        answersServerUrl: "http://localhost:3000",
        socketServerUrl: "http://localhost:3002",
    },
    STAGING: {
        gqlApiUrl: "https://kayma-horizon-staging.herokuapp.com/api",
        answersServerUrl: "https://kayma-horizon-staging.herokuapp.com",
        socketServerUrl: "https://kayma-horizon-sockets-staging.herokuapp.com",
    },
    PRODUCTION: {
        gqlApiUrl: "https://kayma-horizon.herokuapp.com/api",
        answersServerUrl: "https://answers.kayma.com",
        socketServerUrl: "https://kayma-horizon-sockets.herokuapp.com",
    },
};
const hostname = window.location.hostname;
if (hostname.indexOf("kayma-horizon-staging.herokuapp.com") === 0) {
    environmentName = "STAGING";
}
if (hostname.indexOf("kayma-horizon.herokuapp.com") === 0) {
    environmentName = "PRODUCTION";
}
if (hostname.indexOf("answers.kayma.com") === 0) {
    environmentName = "PRODUCTION";
}

const gqlApiUrl = ENV_SETTINGS[environmentName].gqlApiUrl;
const answersServerUrl = ENV_SETTINGS[environmentName].answersServerUrl;
SocketApi.SetServerUrl(ENV_SETTINGS[environmentName].socketServerUrl);
let token = null;

async function RunQuery(queryStr, TitleForTimer) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }

    const response = { error: null, data: null };
    if (TitleForTimer) {
        console.time(TitleForTimer);
    }
    await fetch(gqlApiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ query: queryStr }),
    })
        .then((result) => {
            if (!result.ok) {
                throw result;
            }
            return result.json();
        })
        .then((json) => {
            response.data = json.data;
        })
        .catch((err) => {
            response.error = err;
        });

    if (TitleForTimer) {
        console.timeEnd(TitleForTimer);
    }
    return response;
}

/* TEMPRARILY COMMENTED OUT UNTIL NEEDED
async function RunMainServerGetRequest(path, params) {

  const headers = { 'Content-Type': 'application/json' }
  if (token) { headers['Authentication'] = `Bearer ${token}` }

  var url = `https://kayma-horizon.herokuapp.com${path}`
  const paramKeys = Object.keys(params);
  if (paramKeys.length > 0) {
    url += "?"
    paramKeys.forEach(key => {
      url += `${key}=${params[key]}&`
    });
    url = url.slice(0, -1)
  }

  // console.log( url, headers );

  var response = { error: null, data: null };
  // console.time(path)
  await fetch(url, {
    method: 'GET',
    headers: headers,
  }).then(result => {
    if (!result.ok) { throw result }
    return result.json();
  }).then(json => {
    response.data = json;
  }).catch(err => {
    response.error = err;
  })
  // console.timeEnd(path)
  return response;
}
*/

async function RunAnswersServerGetRequest(path, params) {
    var url = `${answersServerUrl}${path}`;
    const paramKeys = Object.keys(params);
    if (paramKeys.length > 0) {
        url += "?";
        paramKeys.forEach((key) => {
            url += `${key}=${params[key]}&`;
        });
        url = url.slice(0, -1);
    }

    // console.log(`Calling: ${url}`)
    // console.log( token );
    var response = { error: null, data: null };
    // console.time(path)
    await fetch(url, {
        method: "GET",
        headers: token ? { Authorization: `Bearer ${token}` } : {},
    })
        .then((result) => {
            if (!result.ok) {
                throw result;
            }
            return result.json();
        })
        .then((json) => {
            response.data = json;
        })
        .catch((err) => {
            response.error = err;
        });
    // console.timeEnd(path)
    return response;
}

async function RunAnswersServerPostRequest(path, params, body) {
    var url = `${answersServerUrl}${path}`;
    const paramKeys = Object.keys(params);
    if (paramKeys.length > 0) {
        url += "?";
        paramKeys.forEach((key) => {
            url += `${key}=${params[key]}&`;
        });
        url = url.slice(0, -1);
    }

    // console.log(`Calling: ${url}`);
    // console.log( token );
    var headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }

    var response = { error: null, data: null };
    await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
    })
        .then((result) => {
            if (!result.ok) {
                throw result;
            }
            return result.json();
        })
        .then((json) => {
            response.data = json;
        })
        .catch((err) => {
            response.error = err;
        });

    if (response.data.error) {
        response.error = response.data.error;
        response.data.error = undefined;
    }

    return response;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// User ////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const SetToken = (userToken) => {
    token = userToken;
    SocketApi.SetToken(userToken);
};

export const AuthenticateUser = async () => {
    const query = jsonToGraphQLQuery(
        {
            query: {
                user: {
                    __aliasFor: "authenticatedUser",
                    id: true,
                    name: true,
                    email: true,
                    image: {
                        publicUrl: true,
                    },
                },
            },
        },
        { pretty: true }
    );

    return await RunQuery(query, "AuthenticateUser");
};

export const Login = async (email, password) => {
    const query = jsonToGraphQLQuery(
        {
            mutation: {
                userData: {
                    __aliasFor: "authenticateUserWithPassword",
                    __args: {
                        email,
                        password,
                    },
                    token: true,
                    item: {
                        id: true,
                        name: true,
                        email: true,
                        image: {
                            publicUrl: true,
                        },
                    },
                },
            },
        },
        { pretty: true }
    );

    return await RunQuery(query, "Login");
};

// export const GetInitialData = async () => {

//   const query = jsonToGraphQLQuery({
//     query: {
//       teams: {
//         __aliasFor: 'allTeams',
//         id: true,
//         name: true,
//         labels: {
//           id: true
//         },
//         tags: {
//           id: true,
//           name: true
//         },
//         projects: {
//           id: true
//         }
//       },
//       labels: {
//         __aliasFor: 'allLabels',
//         id: true,
//         name: true,
//         color: true,
//         projects: { id: true },
//       },
//       projectsCount: {
//         __aliasFor: '_allProjectsMeta',
//         count: true
//       }
//     }
//   }, { pretty: true });

//   // console.log( query );

//   return await RunQuery(query, 'GetInitialData');

// }

export const GetTeamsData = async () => {
    const query = jsonToGraphQLQuery(
        {
            query: {
                teams: {
                    __aliasFor: "allTeams",
                    id: true,
                    name: true,
                    labels: {
                        id: true,
                    },
                    tags: {
                        id: true,
                        name: true,
                    },
                    projects: {
                        id: true,
                    },
                },
            },
        },
        { pretty: true }
    );

    // console.log( query );

    return await RunQuery(query, "GetTeamsData");
};
export const FetchTeamsData = async () => {
    return await RunAnswersServerGetRequest("/fetchTeamsData", {});
};

export const FetchTeamProjects = async (teamId, skip, limit) => {
    return await RunAnswersServerGetRequest("/fetchTeamProjects", {
        teamId,
        skip,
        limit,
    });
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Projects ////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const GetProjects = async (
    paginationIndex,
    amountOfProjects,
    teamId,
    isArchived
) => {
    const query = jsonToGraphQLQuery(
        {
            query: {
                projects: {
                    __aliasFor: "allProjects",
                    __args: {
                        where: {
                            isArchived: isArchived,
                            teams_some: {
                                id: teamId,
                            },
                        },
                        sortBy: new EnumType("createdAt_DESC"),
                        skip: paginationIndex,
                        first: amountOfProjects,
                    },
                    id: true,
                    name: true,
                    sourceName: true,
                    icon: true,
                    isArchived: true,
                    isTemplate: true,
                    createdAt: true,
                    fetchers: { id: true },
                    modifiers: { id: true },
                    owners: { id: true },
                    labels: {
                        id: true,
                    },
                    notebook: {
                        id: true,
                    },
                    bucket: {
                        id: true,
                    },
                    survey: {
                        id: true,
                    },
                },
            },
        },
        { pretty: true }
    );

    // console.log( query );

    return await RunQuery(query, "GetProjects");
};

export const GetProjectById = async (id) => {
    const query = jsonToGraphQLQuery(
        {
            query: {
                Project: {
                    __args: {
                        where: {
                            id,
                        },
                    },
                    id: true,
                    name: true,
                    sourceName: true,
                    icon: true,
                    isArchived: true,
                    isTemplate: true,
                    createdAt: true,
                    labels: { id: true },
                    notebook: { id: true },
                    bucket: { id: true },
                    survey: { id: true },
                    accessRequests: {
                        user: { id: true },
                    },
                    fetchers: { id: true },
                    modifiers: { id: true },
                    owners: { id: true },
                },
            },
        },
        { pretty: true }
    );

    // console.log( query )

    return await RunQuery(query, "GetProjectById");
};

export const GetProjectsById = async (idsArray) => {
    const query = jsonToGraphQLQuery(
        {
            query: {
                allProjects: {
                    __args: {
                        where: {
                            id_in: idsArray,
                        },
                    },
                    id: true,
                    name: true,
                    sourceName: true,
                    icon: true,
                    isArchived: true,
                    isTemplate: true,
                    createdAt: true,
                    labels: { id: true },
                    notebook: { id: true },
                    bucket: { id: true },
                    survey: { id: true },
                    accessRequests: {
                        user: { id: true },
                    },
                    fetchers: { id: true },
                    modifiers: { id: true },
                    owners: { id: true },
                },
            },
        },
        { pretty: true }
    );

    // console.log( query )

    return await RunQuery(query, "GetProjectById");
};

export const SearchProjectsInTeams = async (teamId, queryString) => {
    return await RunAnswersServerGetRequest("/search_projects", {
        teamId,
        queryString,
    });
};

export const SearchTagsInTeam = async (teamId, queryString) => {
    return await RunAnswersServerGetRequest("/search_tags", {
        teamId,
        queryString,
    });
};

export const CreateNewLabel = async (name, teamId, color) => {
    const mutation = jsonToGraphQLQuery({
        mutation: {
            createLabel: {
                __args: {
                    data: {
                        name,
                        color,
                        team: {
                            connect: { id: teamId },
                        },
                    },
                },
                id: true,
            },
        },
    });

    return await RunQuery(mutation, "CreateNewLabel");
};

export const UpdateProject = async (projectId, data) => {
    const mutation = jsonToGraphQLQuery(
        {
            mutation: {
                project: {
                    __aliasFor: "updateProject",
                    __args: {
                        id: projectId,
                        data,
                    },
                    id: true,
                    name: true,
                    icon: true,
                    isArchived: true,
                    isTemplate: true,
                    labels: {
                        id: true,
                    },
                },
            },
        },
        { pretty: true }
    );

    // console.log( mutation );

    return await RunQuery(mutation, "UpdateProject");
};

export const CheckSourceNameAvailability = async (sourceName) => {
    // return await RunMainServerGetRequest('/check_source_name_availability', {sourceName} );
    return await RunAnswersServerGetRequest("/check_source_name_availability", {
        sourceName,
    });
};

export const CreateProject = async ({
    projectType,
    teamId,
    sourceName,
    projectName,
    icon,
    labelIds,
    createNotebook,
    createSurvey,
    appendixBucketsProjectIds,
    templateProjectId,
}) => {
    const data = {
        projectType,
        teamId,
        sourceName,
        projectName,
        icon,
        templateProjectId,
        labelIds, // ["label_a_id","label_b_id",...]
        createNotebook, // boolean
        createSurvey, // boolean
        appendixBucketsProjectIds, // ["project_a_id","project_b_id",...]
    };

    return await RunAnswersServerPostRequest("/create_project", {}, data);
};

export const DeleteProject = async (sourceName) => {
    return await RunAnswersServerPostRequest(
        "/delete_project",
        {},
        { sourceName }
    );
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Insights ////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const GetBucketInsightsPrefs = async (bucketId) => {
    const query = jsonToGraphQLQuery(
        {
            query: {
                Bucket: {
                    __args: {
                        where: { id: bucketId },
                    },
                    insightsPreferences: true,
                },
            },
        },
        { pretty: true }
    );

    return await RunQuery(query);
};

export const UpdateBucketInsightsPrefs = async (bucketId, prefs) => {
    const mutation = jsonToGraphQLQuery(
        {
            mutation: {
                updateBucket: {
                    __args: {
                        id: bucketId,
                        data: {
                            insightsPreferences: JSON.stringify(prefs, null, 4),
                        },
                    },
                    insightsPreferences: true,
                },
            },
        },
        { pretty: true }
    );

    return await RunQuery(mutation);
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Report & Records ////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const GetReport = async (sourceName, filterData) => {
    const response = await RunAnswersServerGetRequest("/answers_report", {
        sourceName: sourceName,
    });
    return response;
};

export const GetRecords = async (sourceName, filterData) => {
    const filterObj = {
        sourceName: sourceName,
        // "params.finished" : true,
    };

    if (filterData) {
        if (filterData.dates) {
            if (filterData.dates.startDate) {
                const from = new Date(filterData.dates.startDate).getTime();
                filterObj["from"] = from;
            }

            if (filterData.dates.endDate) {
                const to = new Date(filterData.dates.endDate).getTime();
                filterObj["to"] = to;
            }
        }

        if (filterData.params) {
            if (filterData.params.filterUnfinished) {
                filterObj["params.finished"] = true;
            }
        }
    }

    const response = await RunAnswersServerGetRequest(
        "/records_sample",
        filterObj
    );
    return response;
};

export const ExportRecordsToEmail = async (sourceName, filterData) => {
    const filterObj = {
        sourceName: sourceName,
        // "params.finished" : true,
    };

    if (filterData) {
        if (filterData.dates) {
            if (filterData.dates.startDate) {
                const from = new Date(filterData.dates.startDate).getTime();
                filterObj["from"] = from;
            }

            if (filterData.dates.endDate) {
                const to = new Date(filterData.dates.endDate).getTime();
                filterObj["to"] = to;
            }
        }

        // if( filterData.desiredKeys ) {

        //   const keys = [];

        //   filterData.desiredKeys.forEach( keyObj => {
        //     if( keyObj.isChecked ) {
        //       keys.push( keyObj.key );
        //     }
        //   })

        //   filterObj["desiredKeys"] = keys;
        // }
    }

    // console.log( filterObj );

    const response = await RunAnswersServerGetRequest(
        "/export_to_email",
        filterObj
    );
    return response;
};

export const RequestAccessToProject = async (sourceName, accessType) => {
    const params = {
        sourceName: sourceName,
        accessType: accessType,
    };

    const response = await RunAnswersServerGetRequest(
        "/request_project_access",
        params
    );
    return response;
};

export const HideRecord = async (recordId) => {
    const response = await RunAnswersServerPostRequest(
        "/hideRecord",
        {},
        {
            recordId,
        }
    );
    return response;
};

//{filter
//sourceName
//"params."
//"data." only keys that have values can be filtered by. ie: "some_key~value" -> find all keys that contains "~value" -> remove "~value" when calling the server with a key to filter by.
//from: timestamp
//to: timestamp
//desiredKeys: [""] // when asking to export records to email, list here the keys the user choosen to get
//}

// const some = await RunAnswersServerGetRequest('/records_sample', {
//   sourceName: sourceName,
//   "params.finished" : true,
//   "data."
//   "data.gender" : "female",
// });

//"/custom_report"
//"/export_to_email"

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Survey //////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

var lastJsonRecivedTime = 0;
var lastJsonRecived = null;
export const FetchSurvey = async (surveyId, clientKey) => {
    const askForPatches =
        USE_PATCHES &&
        lastJsonRecived &&
        new Date().getTime() - lastJsonRecivedTime < 10000
            ? true
            : false;

    // const timeStr = askForPatches ? `FetchSurvey (Patches)` : `FetchSurvey (Regular)`
    // console.time(timeStr)
    const response = await RunAnswersServerGetRequest("/fetch_survey", {
        surveyId,
        clientKey,
        askForPatches,
    });
    // console.timeEnd(timeStr)

    if (response && response.data && response.data.survey) {
        if (response.data.survey.json) {
            lastJsonRecived = response.data.survey.json;
            lastJsonRecivedTime = new Date().getTime();
        } else if (askForPatches && response.data.survey.patches) {
            const [newSurveyJson] = DiffMatchPatch.patch_apply(
                response.data.survey.patches,
                lastJsonRecived
            );
            response.data.survey.json = newSurveyJson;
            lastJsonRecived = newSurveyJson;
            lastJsonRecivedTime = new Date().getTime();
        }
    }
    return response;
};

var lastJsonSentTime = 0;
var lastJsonSent = null;
export const UpdateSurvey = async (surveyId, surveyJson, clientKey) => {
    var response;
    if (
        !lastJsonSent ||
        new Date().getTime() - lastJsonSentTime > 10000 ||
        !USE_PATCHES
    ) {
        // var timeStr = `UpdateSurvey (Regular. Payload length=${surveyJson.length})`
        // console.time(timeStr)
        response = await RunAnswersServerPostRequest(
            "/update_survey",
            {},
            { surveyId, surveyJson, clientKey }
        );
        // console.timeEnd(timeStr)
    } else {
        var patches = DiffMatchPatch.patch_make(lastJsonSent, surveyJson);
        var surveyJsonPatches = JSON.stringify(patches);
        // var timeStr2 = `UpdateSurvey (Patches. Payload length=${surveyJsonPatches.length})`
        // console.time(timeStr2)
        response = await RunAnswersServerPostRequest(
            "/update_survey",
            {},
            { surveyId, surveyJsonPatches, clientKey }
        );
        // console.timeEnd(timeStr2)
    }
    lastJsonSent = surveyJson;
    lastJsonSentTime = new Date().getTime();

    return response;
};

export const PublishSurvey = async (sourceName) => {
    const response = await RunAnswersServerGetRequest("/publish_survey", {
        sourceName: sourceName,
    });
    return response;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Analyses ////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const GetAnalysesMethods = async (id, surveyJson) => {
    const query = jsonToGraphQLQuery(
        {
            query: {
                methods: {
                    __aliasFor: "allAnalysisMethods",
                    id: true,
                    name: true,
                    hebrewName: true,
                    description: true,
                    hebrewDescription: true,
                    variablesSettings: true,
                    scriptName: true,
                },
            },
        },
        { pretty: true }
    );

    // console.log( query );

    return await RunQuery(query, "GetAnalysesMethods");
};

export const SubmitNewAnalysesResult = async (all) => {
    const mutationData = all.map((a) => {
        const data = {
            user: { connect: { id: a.userId } },
            method: { connect: { id: a.methodId } },
            bucket: { connect: { id: a.bucketId } },
            createdAt: new Date().getTime(),
            variables: a.variables,
            name: a.analysisName,
            isStared: a.isStared ? a.isStared : false,
            notes: a.notes ? a.notes : "",
        };

        if (a.tags) {
            data["tags"] = { connect: a.tags.map((t) => ({ id: t.id })) };
        }

        return { data: data };
    });

    const mutation = jsonToGraphQLQuery(
        {
            mutation: {
                createAnalysisResults: {
                    __args: {
                        data: mutationData,
                    },
                    id: true,
                },
            },
        },
        { pretty: true }
    );

    // console.log( mutation );

    return await RunQuery(mutation, "SubmitNewAnalysesResult");
};

export const GetAnalysesResults = async (
    bucketId,
    isArchived,
    paginationIndex,
    amountOfResults
) => {
    const query = jsonToGraphQLQuery(
        {
            query: {
                results: {
                    __aliasFor: "allAnalysisResults",
                    __args: {
                        where: {
                            bucket: { id: bucketId },
                            isArchived,
                        },
                        sortBy: new EnumType("createdAt_DESC"),
                        skip: paginationIndex,
                        first: amountOfResults,
                    },
                    id: true,
                    name: true,
                    user: {
                        name: true,
                    },
                    method: {
                        id: true,
                        name: true,
                        hebrewName: true,
                    },
                    paramters: !isArchived,
                    createdAt: true,
                    resultUrl: true,
                    status: true,
                    isArchived: true,
                    isStared: true,
                    notes: true,
                    tags: {
                        id: true,
                        name: true,
                    },
                    outputs: true,
                    log: !isArchived,
                    error: true,
                },
            },
        },
        { pretty: true }
    );

    return await RunQuery(query, "GetAnalysesResults");
};

export const FetchAnalysesResults = async (bucketId, skip, limit) => {
    const params = { bucketId, skip, limit };
    return await await RunAnswersServerGetRequest(
        "/fetch_analysis_results",
        params
    );
};

// export const GetAnalysisResultsStatus = async ( bucketId ) => {

//   const query = jsonToGraphQLQuery({
//     query: {
//       results: {
//         __aliasFor: 'allAnalysisResults',
//         __args: {
//           where: {
//             bucket: { id: bucketId },
//             isArchived: false
//           }
//         },
//         id: true,
//         status: true,
//       }
//     }
//   }, { pretty: true });

//   // console.log( query );

//   return await RunQuery(query);
// }

export const GetSingleAnalysisResult = async (id) => {
    const query = jsonToGraphQLQuery(
        {
            query: {
                result: {
                    __aliasFor: "AnalysisResult",
                    __args: {
                        where: {
                            id,
                        },
                    },
                    id: true,
                    name: true,
                    user: {
                        name: true,
                    },
                    method: {
                        id: true,
                        name: true,
                        hebrewName: true,
                    },
                    paramters: true,
                    createdAt: true,
                    resultUrl: true,
                    status: true,
                    isArchived: true,
                    isStared: true,
                    notes: true,
                    outputs: true,
                    tags: {
                        id: true,
                        name: true,
                    },
                    log: true,
                    error: true,
                },
            },
        },
        { pretty: true }
    );

    return await RunQuery(query, `GetSingleAnalysisResult_${id}`);
};

export const GetAnalysesResultPositionInQueue = async (ids) => {
    const params = { resultIds: JSON.stringify(ids) };
    return await await RunAnswersServerGetRequest(
        "/fetch_analyses_position_in_queue",
        params
    );
};

export const UpdateAnalysisResult = async (id, prop, value) => {
    const json = {
        mutation: {
            result: {
                __aliasFor: "updateAnalysisResult",
                __args: {
                    id,
                    data: {},
                },
                id: true,
            },
        },
    };

    json.mutation.result.__args.data[prop] = value;
    if (prop === "tags") {
        json.mutation.result[prop] = { id: true, name: true, color: true };
    } else {
        json.mutation.result[prop] = true;
    }

    const mutation = jsonToGraphQLQuery(json, { pretty: true });

    // console.log( mutation );

    return await RunQuery(mutation, "UpdateAnalysisResult");
};

export const ArchiveAnalysesResult = async (resultIds, isArchived) => {
    const mutationData = resultIds.map((id) => ({
        id,
        data: {
            isArchived,
            tags: {
                disconnectAll: isArchived,
            },
        },
    }));

    const mutation = jsonToGraphQLQuery(
        {
            mutation: {
                results: {
                    __aliasFor: "updateAnalysisResults",
                    __args: {
                        data: mutationData,
                    },
                    id: true,
                    isArchived: true,
                    tags: {
                        id: true,
                        name: true,
                    },
                },
            },
        },
        { pretty: true }
    );

    // console.log( mutation );

    return await RunQuery(mutation, "ArchiveAnalysesResult");
};

export const AskForAnalysisResultDataToken = async (resultId) => {
    const params = { resultId: resultId };
    const response = await RunAnswersServerGetRequest(
        "/analysis_token_request",
        params
    );
    return response;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Virtual Vars ////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const GetVirtualVarsSettings = async (bucketId) => {
    const query = jsonToGraphQLQuery(
        {
            query: {
                Bucket: {
                    __args: {
                        where: { id: bucketId },
                    },
                    virtualVarsSettings: true,
                },
            },
        },
        { pretty: true }
    );

    return await RunQuery(query, "GetVirtualVarsSettings");
};

export const UpdateVirtualVars = async (bucketId, virtualVarsJson) => {
    const response = await RunAnswersServerPostRequest(
        "/update_virtual_vars",
        {},
        { bucketId, virtualVarsJson }
    );

    return response;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Notebook ////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const FetchNotebook = async (notebookId, clientKey) => {
    const response = await RunAnswersServerGetRequest("/fetch_notebook", {
        notebookId,
        clientKey,
    });

    return response;
};

export const UpdateNotebook = async (notebookId, notebookJson, clientKey) => {
    const response = await RunAnswersServerPostRequest(
        "/update_notebook",
        {},
        { notebookId, notebookJson, clientKey }
    );

    return response;
};

export const UpdateNotebookMetadata = async (notebookId, prop, value) => {
    const mutation = jsonToGraphQLQuery(
        {
            mutation: {
                updateNotebook: {
                    __args: {
                        id: notebookId,
                        data: {
                            [prop]: value,
                        },
                    },
                    id: true,
                    [prop]: true,
                },
            },
        },
        { pretty: true }
    );

    return await RunQuery(mutation, "UpdateNotebookMetadata");
};

export const FetchNotebookSectionsTemplates = async (teamId) => {
    const query = jsonToGraphQLQuery(
        {
            query: {
                sections: {
                    __aliasFor: "allNotebookSections",
                    __args: {
                        where: { teams_some: { id: teamId } },
                    },
                    id: true,
                    name: true,
                    template: true,
                },
                templates: {
                    __aliasFor: "allNotebookTemplates",
                    __args: {
                        where: { teams_some: { id: teamId } },
                    },
                    id: true,
                    name: true,
                    sections: { id: true },
                },
            },
        },
        { pretty: true }
    );

    return await RunQuery(query, "FetchNotebookSectionsTemplates");
};

export const GetTagsData = async (tagIds, skip, limit) => {
    return await RunAnswersServerGetRequest("/get_tag_data", {
        tagIds,
        skip,
        limit,
    });
};

export const UploadMedia = (media, surveyId) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("media", media);
        if (surveyId) {
            formData.append("surveyId", surveyId);
        }
        var url = `${answersServerUrl}/uploadMedia`;
        let xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        xhr.send(formData);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                try {
                    const resDict = JSON.parse(xhr.response);
                    if (resDict.url) {
                        resolve(resDict.url);
                    } else {
                        reject("Error while uploading");
                    }
                } catch {
                    reject("Error while parsing response");
                }
            }
        };
    });
};
