import { configureStore } from '@reduxjs/toolkit';
import usersReducer from '../features/users/usersSlice';
import horizonDataReducer from './horizonDataSlice';
import preferencesReducer from './preferencesSlice';
import reportReducer from './reportSlice';
import surveyEditorReducer, { SurveyEditorUndoableFilter } from 'features/survey_editor/surveyEditorSlice';
import analysesReducer from 'features/analyses/analysesSlice';
import notebookReducer from 'features/notebook/notebookSlice';
import undoable from 'redux-undo';

export default configureStore({
    reducer: {
        users: usersReducer,
        horizonData: horizonDataReducer,
        preferences: preferencesReducer,
        report: reportReducer,
        surveyEditor: undoable( surveyEditorReducer, { filter: SurveyEditorUndoableFilter } ),
        analyses: analysesReducer,
        notebook: notebookReducer,
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: false, // disabled due to performance issues during development. this is a test provided by redux toolkit
            immutableCheck: false // disabled due to performance issues during development. this is a test provided by redux toolkit
        })
        
})